import {
    put, call, getContext, select,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { checkRtnApi, verifyHappyCodeApi } from '../../../service/end-points/HappyCodeApiService';
import HappyCodeAction, {
    addDthCustomerIdAction, registeredTelephoneNumberErrorAction, happyCodeVerificationErrorAction, happyCodeVerificationSuccessAction,
} from '../actions/HappyCodeActions';
import { getHappyCodeDataFromReducer } from '@airtel-tv/redux/StoreListing';

function* checkCustomerRtn(action) {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const customerRtn = lodashGet(action, 'customerRtn', '');
        const response = yield call(checkRtnApi, {
            deviceUtil,
            customerRtn,
        });
        const dthCustomerId = lodashGet(response, 'vectorMasterDataResponse[0].acct_id', '');

        yield put(addDthCustomerIdAction(dthCustomerId));
    }
    catch (error) {
        yield put(registeredTelephoneNumberErrorAction(error));
    }
}


function* checkHappyCode(action) {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const happyCodeData = yield select(getHappyCodeDataFromReducer);
        const installationCode = lodashGet(action, 'payload.installationCode', '');
        const dthCustomerId = lodashGet(happyCodeData, 'dthCustomerId', '');
        const customerRtn = lodashGet(action, 'payload.customerRtn', '');
        const happyCodeVerificationStatus = yield call(verifyHappyCodeApi,
            {
                deviceUtil,
                dthCustomerId,
                installationCode,
                customerRtn,
            });

        const happyCodeVerificationSuccess = lodashGet(happyCodeVerificationStatus, 'success', '');
        yield put(happyCodeVerificationSuccessAction(happyCodeVerificationSuccess));
    }
    catch (error) {
        yield put(happyCodeVerificationErrorAction(error));
    }
}

export default [
    takeFirst(HappyCodeAction.CHECK_RTN, checkCustomerRtn),
    takeFirst(HappyCodeAction.CHECK_HAPPY_CODE, checkHappyCode),
];
