import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { getSourceName } from '@airtel-tv/utils/GlobalUtil';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
import useSetNavigation from '../lib/hooks/useSetNavigation';
import usePayLater  from '../lib/hooks/usePayLater';
import { connect } from 'react-redux';
import { updateLocationInfo } from '@airtel-feature/location/LocationActions';


const NavigationSetter = ({ navigationObj, updateLocationInfoDispatch, userPaymentModes, isAuthenticated, locationUpdateCallback  }) => {
    const location = useLocation();
    const { search } = location;
    const urlParams = new URLSearchParams(search);
    const source_page = urlParams.get('source_page');
    useSetNavigation({
        navigationObj,
        updateLocationInfoDispatch,
        location,
        SOSUtil,
        getSourceName,
        ...(source_page && {source_page})
    });

    usePayLater({
        userPaymentModes,
        isAuthenticated,
    });

    useEffect(() => {
        locationUpdateCallback && locationUpdateCallback();
    }, [location]);

    return null;
};

const mapStateToProps = ({
    addToBill: { paymentModes: userPaymentModes = {} } = {},
    authConfig: { isAuthenticated = false } = {},
    location: {
        navigationObj = {},
    } = {},
}) => ({
    navigationObj,
    userPaymentModes,
    isAuthenticated,
});
export default connect(mapStateToProps, {
    updateLocationInfoDispatch: updateLocationInfo,
})(NavigationSetter);
