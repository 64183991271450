export const BlogActions = {
    FETCH_BLOGS: 'FETCH_BLOGS',
    FETCH_BLOGS_SUCCESS: 'FETCH_BLOGS_SUCCESS',
    FETCH_BLOG_BY_ID_SUCCESS: 'FETCH_BLOG_BY_ID_SUCCESS',
    FETCH_BLOG_CATEGORIES: 'FETCH_BLOG_CATEGORIES',
    FETCH_BLOG_CATEGORIES_SUCCESS: 'FETCH_BLOG_CATEGORIES_SUCCESS',
};

export const fetchBlogs = payload => ({
    type: BlogActions.FETCH_BLOGS,
    ...payload,
});

export const fetchBlogsCategories = payload => ({
    type: BlogActions.FETCH_BLOG_CATEGORIES,
    ...payload,
});

export const fetchBlogsSuccess = payload => ({
    type: BlogActions.FETCH_BLOGS_SUCCESS,
    ...payload,
});

export const fetchBlogByIdSuccess = payload => ({
    type: BlogActions.FETCH_BLOG_BY_ID_SUCCESS,
    ...payload,
});

export const fetchBlogCategoriesSuccess = payload => ({
    type: BlogActions.FETCH_BLOG_CATEGORIES_SUCCESS,
    ...payload,
});

export default {
    fetchBlogs,
    fetchBlogsSuccess,
    fetchBlogsCategories,
    fetchBlogCategoriesSuccess,
    fetchBlogByIdSuccess,
};
