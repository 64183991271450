import React from 'react';
import PropTypes from 'prop-types';
import DropDownItem from './DropDownItem';

function DropDownNav({ menuList, menuSrc }) {
    if (!menuList || menuList.length === 0) {
        return null;
    }

    const menuItems = menuList.items.map((item, index) => (
        <DropDownItem
            item={item} // eslint-disable-next-line react/no-array-index-key
            key={index}
            index={index}
            menuSrc={menuSrc}
        />
    ));
    return (
        <div
            className="sub-menu-container slide-up common-drop-down"
        >
            <ul>
                {menuItems}
            </ul>
        </div>
    );
}

DropDownNav.defaultProps = {
    menuList: null,
};

DropDownNav.propTypes = {
    menuSrc: PropTypes.string.isRequired,
    menuList: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default DropDownNav;
