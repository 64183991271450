import { MARKUP_TYPES, MARKUP_EXTRA_CONSTS  } from '@airtel-tv/constants/MarkupConsts';
import { PROGRAM_TYPES  } from '@airtel-tv/constants/GlobalConst';

export const PartofSeasonParser = (id, seasonNumber) => ({
    '@type': MARKUP_TYPES.TVSEASON,
    '@id': id,
    seasonNumber,
});

export const PartofSeriesParser = (id, name) => ({
    '@type': MARKUP_TYPES.TVSERIES,
    '@id': id,
    name,
});

export const ContainsSeason = (seasons)=>{
    let containsSeason = []
    for(let season of seasons){
        containsSeason.push({
            "@type":PROGRAM_TYPES.TVSEASON,
            name:season.name,
            datePublished:'1970-01-01'
        })
    }
    return containsSeason
}

export const actorDirectorParser = (cast) => {
    const actorsCatalogue = [];
    const directorsCatalogue = [];

    if (cast && cast.length > 0) {
        cast.map((person) => {
            const personMarkup = {
                '@type': MARKUP_EXTRA_CONSTS.PERSON,
                name: person.characterName,
            };
            switch (person.roleType) {
                case MARKUP_EXTRA_CONSTS.ACTOR: actorsCatalogue.push(personMarkup);
                    break;
                case MARKUP_EXTRA_CONSTS.DIRECTOR: directorsCatalogue.push(personMarkup);
                    break;
                default: return null;
            }
            return null;
        });
    }

    return {
        actorsCatalogue,
        directorsCatalogue,
    };
};

export default {
    actorDirectorParser,
    PartofSeasonParser,
    PartofSeriesParser,
};
