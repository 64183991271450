import {
    select, getContext, call, put,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { DimensionPageActions, getDimensionPageDataSuccess } from '../actions/DimensionPageActions';
import { getAppId, isObjEmpty } from '../../../utilities/CommonUtil';
import { getDimensionPageData } from '../../../service/end-points/SearchApiService';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { getDimensionPageDataFromReducer, getUserConfigFromReducer } from '@airtel-tv/redux/StoreListing';


export function* fetchDimensionPageData(action) {
    const userConfig = yield select(getUserConfigFromReducer);
    const payload = lodashGet(action, 'payload', {});
    if (isObjEmpty(payload)) {
        return;
    }
    const {
        ct, genres, lang, cast, offset, count, dataId, recent, alphabetical, isParamChanged,
    } = payload;

    const deviceUtil = yield getContext('deviceUtil');

    const appId = getAppId();

    const queryParams = {
        userContentProperties: userConfig.userContentProperties,
        appId,
        deviceUtil,
        ct,
        genres,
        lang,
        offset,
        count,
        cast,
        recent,
        alphabetical,
    };

    const dimensionPageDataFromState = isParamChanged ? {} : yield (select(getDimensionPageDataFromReducer));
    const currentData = lodashGet(dimensionPageDataFromState[dataId], 'results', []);
    const currentCount = lodashGet(dimensionPageDataFromState[dataId], 'count', 0);


    try {
        const dimensionPageDataFromCall = yield call(getDimensionPageData, queryParams);
        const resultArray = lodashGet(dimensionPageDataFromCall, 'results', []);
        let dimensionPageData = {
        };

        if (!isObjEmpty(dimensionPageDataFromCall)) {
            dimensionPageData = {
                [dataId]: {
                    results: [
                        ...currentData,
                        ...resultArray,
                    ],
                    totalCount: lodashGet(dimensionPageDataFromCall, 'totalCount', 200),
                    count: currentCount + lodashGet(dimensionPageDataFromCall, 'count', 0),
                },
            };
            yield put(getDimensionPageDataSuccess({ payload: dimensionPageData }));
        }
    }
    catch (error) {
        yield put(getDimensionPageDataSuccess({ payload: {} }));
        console.error(error);
    }
}

export default [
    takeFirst(DimensionPageActions.FETCH_DIMENSION_PAGE_DATA, fetchDimensionPageData),
];
