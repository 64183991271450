import axios from 'axios';
import lodashGet from 'lodash/get';
import { REQUEST_METHODS } from '@airtel-tv/constants/GlobalConst';
import { apiCrash } from '@airtel-tv/crash-capture';
import { getApiResponseMeta } from '@airtel-tv/utils/ApiAnalytics';
import { apiLogEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ERROR_CODES } from '@airtel-tv/constants';
import { StoreUtil } from '@airtel-tv/utils/StoreUtil';
import { AppEnv } from './GetEnv';
import { getCurrentWindowSourceName } from './WindowUtil';
import browserStore from './BrowserStoreUtil';
import { getUuid } from './GlobalUtil';
import { logoutInit } from './TemporaryActions';


const env = AppEnv.getEnv();

// TODO: Do not Delete.Used in Server side rendering.Other wise response will be truncated in server side.
if (typeof window === 'undefined') {
    axios.defaults.headers['Accept-Encoding'] = 'gzip, deflate, br';
}

// axios.interceptors.response.use(response => {
//     console.log('Response:',  (response.headers))
//     return response
// })
/**
 * Request Wrapper with default successs/error actions
 */
const request = (options) => {
    if (options && options.headers) {
        if (env.orgId) {
            options.headers['x-org-id'] = env.orgId;
        }
        if (options.headers['request-init-time']) {
            options.headers['request-init-time'] = new Date().getTime();
        }
        options.headers['x-atv-traceid'] = getUuid();
        const store = StoreUtil?.store;
        const ab_test = store?.getState()?.userConfig?.experimentDetails;
        if (ab_test) {
            options.headers['x-atv-ab'] = ab_test;
        }

        if (options.headers['avoid-custom-headers']) {
            options.headers = {};
        }
    }

    const onSuccess = (response) => {
        const logTime = options && options.headers && options.headers['request-init-time'];
        const payload = response.data;
        if (options.showheader) {
            payload.headers = response.headers;
        }
        if (logTime) {
            payload.api_response_time = new Date().getTime() - options.headers['request-init-time'];
        }
        if (browserStore.get(`${options.url}_MOCK_DATA_`)) {
            return browserStore.get(`${options.url}_MOCK_DATA_`);
        }
        return payload;
    };
    const onError = (error) => {
        const logTime = options && options.headers && options.headers['request-init-time'];
        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            apiCrash({
                source_name: getCurrentWindowSourceName(),
                error: error.response,
                url: options?.url || '',
            });
            if (lodashGet(error.response, 'data.errorcode', '') === ERROR_CODES.ATV041) {
                const store = StoreUtil.getStore();
                store.dispatch(logoutInit({ isLoginTriggered: true }));
            }
            console.error(error.response);
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
            logTime && (error.response.api_response_time = new Date().getTime() - options.headers['request-init-time']);
            const errorEventMata = getApiResponseMeta(error.response);
            errorEventMata && apiLogEvent(errorEventMata);
        }
        else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('api crash url:', options?.url);
            console.error('api crash Error Message:', error?.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return axios(options)
        .then(onSuccess)
        .catch(onError);
};

export const requestParllel = async (collection) => {
    const requests = collection.map(options => request(options).catch(er => er));
    return axios.all(requests);
};

export default {
    ALL: axios.all,

    GET: ({
        url, params, headers, options,
    }) => request({
        url,
        method: REQUEST_METHODS.GET,
        params,
        headers,
        ...options,
    }),
    POST: ({
        url, params, headers, data, options, showheader,
    }) => request({
        url,
        method: REQUEST_METHODS.POST,
        params,
        headers,
        data,
        showheader,
        ...options,
    }),
    PATCH: ({
        url, params, headers, data, options,
    }) => request({
        url,
        method: REQUEST_METHODS.PATCH,
        params,
        headers,
        data,
        ...options,
    }),
    DELETE: ({
        url, params, headers, data, options,
    }) => request({
        url,
        method: REQUEST_METHODS.DELETE,
        params,
        headers,
        data,
        ...options,
    }),
    PUT: ({
        url, params, headers, data, options,
    }) => request({
        url,
        method: REQUEST_METHODS.PUT,
        params,
        headers,
        data,
        ...options,
    }),
};
