const QuickViewActions = {
    SAVE_TILE_INFO: 'homepage/actions/SAVE_TILE_INFO',
    RESET_TILE_INFO: 'homepage/actions/RESET_TILE_INFO',
    FOCUSED_TILE_INFO: 'homepage/actions/FOCUSED_TILE_INFO',

    PLAYBACK_TOGGLE: 'PLAYBACK_TOGGLE',
    HIDE_BANNER_DETAILS_TOGGLE: 'HIDE_BANNER_DETAILS_TOGGLE',
    TOGGLE_PLAYBACK_BACKGROUND: 'TOGGLE_PLAYBACK_BACKGROUND',
    TOGGLE_TRAILER_PLAYBACK: 'TOGGLE_TRAILER_PLAYBACK',
    TOGGLE_PLAYABLE_ID: 'TOGGLE_PLAYABLE_ID',
    RESET_PLAYBACK_DETAILS: 'RESET_PLAYBACK_DETAILS',
    TOGGLE_TRAILER_ENDED: 'TOGGLE_TRAILER_ENDED',
    TOGGLE_HAS_TRAILER: 'TOGGLE_HAS_TRAILER',
    SET_EXPLICIT_TRAILER_ID: 'SET_EXPLICIT_TRAILER_ID',
    SET_CDP_TAB_OPEN: 'SET_CDP_TAB_OPEN',
    SET_PLAYER_CONFIG: 'SET_PLAYER_CONFIG',
    SAVE_TILE_PERSIST_PLAYER: 'SAVE_TILE_PERSIST_PLAYER',
    FETCH_PLAYABLE_ID_CONTENT_DETAILS_AND_START_PLAYBAKC: 'FETCH_PLAYABLE_ID_CONTENT_DETAILS_AND_START_PLAYBAKC',
    MODIFY_INITIAL_STATE_PLAYBACK_DETAILS: 'MODIFY_INITIAL_STATE_PLAYBACK_DETAILS',
    FETCHING_TILE_DETAILS: 'FETCHING_TILE_DETAILS',
};

export default QuickViewActions;

export const saveTileInfoAction = payload => ({
    type: QuickViewActions.SAVE_TILE_INFO,
    ...payload,
});

export const fetchingTileDetails = () => ({
    type: QuickViewActions.FETCHING_TILE_DETAILS,
});

export const saveTilePersistPlayerData = payload => ({
    type: QuickViewActions.SAVE_TILE_PERSIST_PLAYER,
    ...payload,
});

export const resetTileInfoAction = payload => ({
    type: QuickViewActions.RESET_TILE_INFO,
    ...payload,
});

export const updateFocusedRailPosition = payload => ({
    type: QuickViewActions.FOCUSED_TILE_INFO,
    ...payload,
});


export const togglePlaybackLoaded = payload => ({
    type: QuickViewActions.PLAYBACK_TOGGLE,
    payload,
});

export const toggleHideBannerDetails = payload => ({
    type: QuickViewActions.HIDE_BANNER_DETAILS_TOGGLE,
    payload,
});

export const togglePlaybackOnBackground = payload => ({
    type: QuickViewActions.TOGGLE_PLAYBACK_BACKGROUND,
    payload,
});

export const toggleTrailerPlayback = payload => ({
    type: QuickViewActions.TOGGLE_TRAILER_PLAYBACK,
    payload,
});

export const togglePlayableId = payload => ({
    type: QuickViewActions.TOGGLE_PLAYABLE_ID,
    ...payload,
});

export const resetPlaybackDetails = () => ({
    type: QuickViewActions.RESET_PLAYBACK_DETAILS,
});

export const toggleTrailerEnded = payload => ({
    type: QuickViewActions.TOGGLE_TRAILER_ENDED,
    payload,
});

export const setExplicitTrailerId = payload => ({
    type: QuickViewActions.SET_EXPLICIT_TRAILER_ID,
    payload,
});

export const setCdpTabOpen = payload => ({
    type: QuickViewActions.SET_CDP_TAB_OPEN,
    payload,
});

export const setPlayerConfig = payload => ({
    type: QuickViewActions.SET_PLAYER_CONFIG,
    payload,
});

// export const fetchPlayabeIdContentDetailsAndStartPlayback = payload => ({
//     type: QuickViewActions.FETCH_PLAYABLE_ID_CONTENT_DETAILS_AND_START_PLAYBAKC,
//     payload,
// });

export const modifyInitialPlaybackDetails = payload => ({
    type: QuickViewActions.MODIFY_INITIAL_STATE_PLAYBACK_DETAILS,
    payload,
});