/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { getUuid, getTimeStamp, removeFalsy } from '@airtel-tv/utils/GlobalUtil';
import { DeviceTypeUtil } from '@airtel-tv/utils/DeviceTypeUtil';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { StoreUtil } from '@airtel-tv/utils/StoreUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import { AppEnv } from '@airtel-tv/utils/GetEnv';
import EventQueue from '@airtel-tv/constants/EventQueue';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import EventModel from '@airtel-tv/analytics/EventModel';
import { getMoengage } from '@airtel-tv/utils/moengageUtil';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
import { ANALYTICS_ASSETS } from '@airtel-tv/constants';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';

const env = AppEnv.getEnv();

let eventQTimer = null;
const onQueueFull = (events) => {
    const { eventsApi } = env.endpoints;

    const method = 'POST';
    const appIdFromLocal = AuthenticationUtil.getAppId();
    // const buildNo = AuthenticationUtil.
    const url = `${method}/${eventsApi.paths.postEvent}`;
    const utknData = {
        urlBody: url,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'Content-Type': 'application/json',
        // 'X-Session': `${AuthenticationUtil.getUid()}:${AuthenticationUtil.getAuthToken()}`,
        'x-atv-did': EventModel.did,
        'x-atv-utkn': utkn,
    };

    // for (let event of events){
    //      event.appid = appIdFromLocal || EventModel.appid;
    // }

    const eventBase = checkWindowExist() && (true || [
        'https://www.airtelxstream.in',
        'https://airtelxstream.in',
        'https://preprod.airtelxstream.in',
    ].includes(window.location.origin) || !DeviceTypeUtil.isWeb()) ? eventsApi.base : 'https://dummyevents.airtel.tv/';
    const request = {
        data: {
            events,
            id: getUuid(),
            ts: getTimeStamp(),
        },
        url: `${eventBase}${eventsApi.paths.postEvent}`,
        params: {
            appId: appIdFromLocal || EventModel.appid,
        },
        headers: removeFalsy(headers),
    };
    RequestUtil.POST(request);
};

const clearEventQTimer = () => {
    clearTimeout(eventQTimer);
    eventQTimer = null;
};

const flushQueueOnTimeout = () => {
    const isQEmpty = EventQueue.flush(onQueueFull);

    // clearing previous timer
    clearEventQTimer();

    if (isQEmpty) {
        eventQTimer = setTimeout(flushQueueOnTimeout, env.eventQueueTimeout * 1000);
    }
};
setTimeout(flushQueueOnTimeout, env.eventQueueTimeout * 1000);

const EventScheduler = {
    submit: (event, isCritical) => {
        if (!checkWindowExist()) {
            return;
        }

        const sourceOfStream = SOSUtil.runSos(event);
        if (event && event.meta && typeof event.meta === 'object') {
            const eventMeta = event.meta;

            for (const key in eventMeta) {
                if (typeof eventMeta[key] !== 'undefined') {
                    event.meta[key] = `${eventMeta[key]}`;
                }
                else {
                    event.meta[key] = '';
                }
            }

            event.meta.signal_strength_info = typeof window !== 'undefined' ? window?.navigator?.connection?.downlink || 0 : 0;

            if (sourceOfStream) {
                event.meta[ANALYTICS_ASSETS.SOURCE_OF_STREAM] = sourceOfStream;
            }

            if (event && event.sytemInfoMeta && typeof event.sytemInfoMeta === 'object') {
                event.meta = {
                    ...event.meta,
                    ...event.sytemInfoMeta,
                };
                delete event.sytemInfoMeta;
            }
        }

        if (DeviceTypeUtil.isWeb()) {
            const Moengage = getMoengage();
            Moengage?.track_event(event.event_type || '', {
                ...event.meta,
                Platform: 'web',
            });
        }
        if (event && event.sytemInfoMeta && typeof event.sytemInfoMeta === 'object') {
            event.meta = {
                ...event.meta,
                ...event.sytemInfoMeta,
            };
            delete event.sytemInfoMeta;
        }
        const deviceUtil = DeviceUtil.getDeviceUtil();
        if (deviceUtil.isScopedWebview()) {
            event.meta = {
                ...event.meta,
                ...ScopedWebviewUtil.getUtmParams(),
            };
        }
        // if(event?.meta?.gaMetaData){
        //     sendGEvent({...event.meta.gaMetaData,phoneNumber: '12345432'});
        //     delete event.meta.gaMetaData;
        // }
        if (isCritical) {
            EventQueue.addAndFlush(event, onQueueFull);
        }
        else {
            EventQueue.add(event, onQueueFull);
        }
    },
};

Object.freeze(EventScheduler);
export default EventScheduler;
