const ChannelPartnerActions = {
    SAVE_CHANNEL_PARTNER_ID: 'SAVE_CHANNEL_PARTNER_ID',
    REMOVE_CHANNEL_PARTNER_ID: 'REMOVE_CHANNEL_PARTNER_ID'
};

export default ChannelPartnerActions;

export const saveChannelPartnerId = (cpid) => 
    ({
    type: ChannelPartnerActions.SAVE_CHANNEL_PARTNER_ID,
    cpid
});

export const removeChannelPartnerId = (cpid) => 
    ({
    type: ChannelPartnerActions.REMOVE_CHANNEL_PARTNER_ID,
    cpid
});