import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
// import withStyles from 'isomorphic-style-loader/withStyles';
import useStyles from 'isomorphic-style-loader/useStyles';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import styles from '../DimensionPage.scss';
import LinkComponent from '../../shared/components/link/LinkComponent';
import {
    documentAddEventListener, documentRemoveEventListener, getElementByTagName,
} from '../../../utilities/WindowUtil';
import {
    DIMENSION_SORT_DISPLAY, IMAGE_PATHS, DROPDOWN_NAMES, DROPDOWN_NAMES_LIMIT, DROPDOWN_CONTENT_NAME_LIMIT, SCREEN_SIZES,
} from '../../../constants/AppConst';

export function DropDownComponent(props) {
    useStyles(styles);
    const {
        title, items, dropDownItemClicked, sortDropDownItemClicked, selectedItem, isMobile, isSortDropDown, windowWidth, history,
    } = props;
    const [
        showDropDownList,
        toggleShowDropDownList,
    ] = useState(false);

    const [
        showCloseButton,
        toggleShowCloseButton,
    ] = useState(false);
    const dropDownRef = useRef();
    const disableDropDown = (event) => {
        if (!dropDownRef.current || !dropDownRef.current.contains(event.target)) {
            toggleShowDropDownList(false);
        }
    };
    useEffect(() => {
        documentAddEventListener('click', disableDropDown, false);
        return function cleanUp() {
            documentRemoveEventListener('click', disableDropDown, false);

            if (showCloseButton) {
                toggleShowCloseButton(false);
            }
            if (showDropDownList && !isSortDropDown) {
                // toggleShowDropDownList(false);
                const bodyElement = getElementByTagName('body');
                if (bodyElement) {
                    bodyElement[0].classList.remove('stopOverscroll');
                }
            }
        };
    });
    let dropDownItems = [];
    const dropDownClicked = () => {
        toggleShowDropDownList(!showDropDownList);
        if (isMobile && !isSortDropDown && windowWidth < SCREEN_SIZES.IPAD_SIZE) {
            toggleShowCloseButton(true);
            const bodyElement = getElementByTagName('body');
            if (bodyElement) {
                bodyElement[0].classList.add('stopOverscroll');
            }
            const { location: { pathname, search } } = history;
            history.push({
                pathname,
                search,
            });
        }
    };
    const dropDownElementClicked = (item) => {
        toggleShowDropDownList(false);
        const bodyElement = getElementByTagName('body');
        if (bodyElement) {
            bodyElement[0].classList.remove('stopOverscroll');
        }
        if (isMobile && !isSortDropDown) {
            history.replace(lodashGet(item, 'redirectionLink', ''));
        }
        else if (isSortDropDown) {
            sortDropDownItemClicked(item.name);
        }
        else {
            dropDownItemClicked({ queryParams: lodashGet(item, 'queryParams', {}) });
        }
    };
    const closeButton = () => {
        toggleShowCloseButton(false);
        const bodyElement = getElementByTagName('body');
        if (bodyElement) {
            bodyElement[0].classList.remove('stopOverscroll');
        }
        if (isMobile) {
            history.go(-1);
        }
    };
    const { length } = items;
    if (items && length > 0) {
        dropDownItems = items.map((item) => {
            const itemName = lodashGet(item, 'name', '');
            const sizeLimit = isMobile ? itemName.length + 1 : DROPDOWN_CONTENT_NAME_LIMIT;
            return (
                <LinkComponent
                    to={lodashGet(item, 'redirectionLink', '')}
                    onClick={() => dropDownElementClicked(item)}
                    className={`${selectedItem === lodashGet(item, 'name', '') ? 'selectedItem' : ''}`}
                    key={item.name}
                >
                    {selectedItem === lodashGet(item, 'name', '') ? (
                        <ThumborImage
                            src={IMAGE_PATHS.TICK_MARK}
                            alt="tick"
                        />
                    ) : null}
                    <div>
                        {(itemName.length < sizeLimit) ? itemName : `${itemName.slice(0, sizeLimit)}..`}
                    </div>
                </LinkComponent>
            );
        });
    }
    // const isDropDownSelected = selectedItem !== DIMENSION_SORT_DISPLAY[DROPDOWN_NAMES.ALL];
    const titleSizeLimit = isMobile ? DROPDOWN_NAMES_LIMIT.MOBILE : DROPDOWN_NAMES_LIMIT.DESKTOP;
    return (
        <section
            className={`dropDownList ${(isSortDropDown && isMobile) ? 'sortButton' : ''}`}
            ref={dropDownRef}
        >
            { !(isMobile && isSortDropDown && windowWidth < SCREEN_SIZES.IPAD_SIZE) ? (
                <button
                    className={`dropDownButton ${showDropDownList ? 'dropDownClosedState' : ''}`}
                    type="button"
                    onClick={dropDownClicked}
                >
                    {(title.length > titleSizeLimit) ? `${title.slice(0, titleSizeLimit - 1)}..` : title}
                    {/* {isDropDownSelected ? (
                        <ThumborImage
                            src={IMAGE_PATHS.SELECTED_FIELD}
                            className="selectedField"
                            alt="selected-field"
                        />
                    ) : null} */}
                    <div
                        className={`dropDownChevron ${showDropDownList ? 'dropDownOpenState' : ''}`}
                    >
                        {/* <ThumborImage
                            src={IMAGE_PATHS.CEHVRON_DROPDOWN}
                            alt="chevron"
                        /> */}

                    </div>
                    {' '}
                </button>
            ) : null}
            {isSortDropDown && isMobile && windowWidth < SCREEN_SIZES.IPAD_SIZE ? (
                <button
                    type="button"
                    onClick={dropDownClicked}
                >
                    <ThumborImage
                        src={IMAGE_PATHS.SORT_BUTTON}
                        alt="sort-button"
                    />
                </button>

            ) : null }
            <div
                className={`${showDropDownList ? 'dropdown-content-open' : 'hideContent'} `}

            >
                <div className={!(isSortDropDown && isMobile && windowWidth < SCREEN_SIZES.IPAD_SIZE) ? 'mobile_dropdown_popups' : ''}>
                    {dropDownItems}
                </div>
                {showCloseButton && isMobile && !isSortDropDown ? (
                    <button
                        className="closePopup"
                        type="button"
                        onClick={closeButton}
                    >
                        <ThumborImage
                            src={IMAGE_PATHS.CLOSE_BUTTON_OVERLAY}
                            alt="close"
                        />
                    </button>
                ) : null}
            </div>
        </section>
    );
}
DropDownComponent.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    dropDownItemClicked: PropTypes.func.isRequired,
    sortDropDownItemClicked: PropTypes.func.isRequired,
    selectedItem: PropTypes.string,
    isMobile: PropTypes.bool,
    isSortDropDown: PropTypes.bool,
    windowWidth: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};
DropDownComponent.defaultProps = {
    title: '',
    items: [],
    selectedItem: DIMENSION_SORT_DISPLAY[DROPDOWN_NAMES.ALL],
    isMobile: false,
    isSortDropDown: false,
};
export default DropDownComponent;
