import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

export const Button = forwardRef(({
    classes,
    clickHandler, 
    label, 
    role, 
    children, 
    disabled,
    id,
    }, ref) => {
    return (
        <button 
            onClick={clickHandler ? clickHandler : undefined} 
            className={classes ? classes: ''}
            disabled={disabled}
            ref={ref}
            id={id || undefined}
            role={role || "button"}
        >
            {label || children}
        </button>
    );
});

Button.propTypes = {
    label: PropTypes.string,
    clickHandler: PropTypes.func,
    classes: PropTypes.string
}
