import React, { lazy, Suspense } from 'react';
import Loadable from 'react-loadable';
import PrivateRouteContainer from './PrivateRouteContainer';
import getAllRouters from './routes';
import LoaderComponent from '../modules/shared/components/loader/LoaderComponent';
import SubscriptionLoaderComponent from '../modules/shared/components/loader/SubscriptionLoaderComponent';
import LayoutLoaderComponent from '../modules/shared/components/loader/LayoutLoaderComponent';
import HoichoiLoaderComponent from '../modules/shared/components/loader/HoichoiLoaderComponent';
import LoginRouteContainer from './LoginRouteContainer';
import App from '../modules/app/App';
import { AsyncDTHLoginComponent, AsyncDTHPaymentComponent, AsyncHappyCodeGenerateComponent } from './AsyncLoader';
import ArtistPageLoader from '../modules/shared/components/loader/ArtistPageLoader';
import ContentDetailLayoutContainerWrapper from '../modules/content-details/containers/ContentDetailLayoutContainerWrapper';
import LayoutContainerWrapper from '../modules/layout/containers/LayoutContainerWrapper';
// import DimensionPageContainer from '../modules/dimension-pages/container/DimensionPageContainer';
import ListingContainer from '../modules/layout/containers/ListingContainer';
import ListingContainerWrapper from '../modules/layout/containers/ListingContainerWrapper';
// const AsyncLayoutContainerWrapper = Loadable({
//     loader: () => import('../modules/layout/containers/LayoutContainerWrapper' /* webpackChunkName: "LayoutContainerWrapper" */),
//     loading: LayoutLoaderComponent,
// });

const AsyncFaq = Loadable({
    loader: () => import('../modules/settings/components/Faq' /* webpackChunkName: "XmpMultipleClaimContainer" */),
    loading: LoaderComponent,
});

const AsyncStaticComponent = Loadable({
    loader: () => import('../modules/settings/components/StaticComponent' /* webpackChunkName: "XmpMultipleClaimContainer" */),
    loading: LoaderComponent,
});

const AsyncXstreamPremium = Loadable({
    loader: () => import('../modules/xstream-premium/XstreamPremium' /* webpackChunkName: "XstreamPremium" */),
    loading: LoaderComponent,
});

const AsyncXstreamPremium2 = Loadable({
    loader: () => import('../modules/xtream-premium-2/XtreamPremium2' /* webpackChunkName: "XstreamPremium2" */),
    loading: LoaderComponent,
});

const AsyncPromotionComponent = Loadable({
    loader: () => import('../modules/promotion/PromotionComponent' /* webpackChunkName: "PromotionComponent" */),
    loading: LoaderComponent,
});

const AsyncErrorPageComponent = Loadable({
    loader: () => import('../modules/shared/components/error-page/ErrorPageComponent'),
    loading: LoaderComponent,
});

const AsyncSubscriptionContainer = Loadable({
    loader: () => import('../modules/subscription-details/container/SubscriptionContainer' /* webpackChunkName: "SubscriptionContainer" */),
    loading: () => (<LoaderComponent pageType="subscription" />),
});

const AsyncXmpClaimContainer = Loadable({
    loader: () => import('../modules/subscription-details/container/XmpClaimContainer' /* webpackChunkName: "XmpClaimContainer" */),
    loading: SubscriptionLoaderComponent,
});

// const AsyncContentDetailLayoutContainer = Loadable({
//     loader: () => import('../modules/content-details/containers/ContentDetailLayoutContainer' /* webpackChunkName: "XmpClaimModal" */),
//     loading: () => (<LoaderComponent pageType="cdp" />),
// });

// const AsyncContentDetailLayoutContainerWrapper = Loadable({
//     loader: () => import('../modules/content-details/containers/ContentDetailLayoutContainerWrapper' /* webpackChunkName: "XmpClaimModal" */),
//     loading: () => (<LoaderComponent pageType="cdp" />),
// });

const AsyncXmpClaimModal = Loadable({
    loader: () => import('../modules/subscription-details/component/XmpClaimModal' /* webpackChunkName: "XmpClaimModal" */),
    loading: LoaderComponent,
});

const AsyncXmpMultipleClaimContainer = Loadable({
    loader: () => import('../modules/subscription-details/container/XmpMultipleClaimContainer' /* webpackChunkName: "XmpMultipleClaimContainer" */),
    loading: LoaderComponent,
});

const AsyncSingleClaimSuccessPopUp = Loadable({
    loader: () => import('../modules/subscription-details/component/SingleClaimSuccessPopUp' /* webpackChunkName: "SingleClaimSuccessPopUp" */),
    loading: LoaderComponent,
});

const AsyncMultipleClaimSuccessPopUp = Loadable({
    loader: () => import('../modules/subscription-details/component/MultipleClaimSuccessPopUp' /* webpackChunkName: "MultipleClaimSuccessPopUp" */),
    loading: LoaderComponent,
});

const AsyncNoSubscriptionComponent = Loadable({
    loader: () => import('../modules/subscription-details/component/NoSubscriptionComponent' /* webpackChunkName: "NoSubscriptionComponent" */),
    loading: SubscriptionLoaderComponent,
});

const AsyncPlansAndOffersContainer = Loadable({
    loader: () => import('../modules/subscription-details/container/PlansAndOffersContainer' /* webpackChunkName: "PlansAndOffersContainer" */),
    loading: SubscriptionLoaderComponent,
});


const AsyncPartnerListingComponent = Loadable({
    loader: () => import('../modules/subscription-details/component/AlaCartePartnerListing' /* webpackChunkName: "AlaCartePartnerListing" */),
    loading: SubscriptionLoaderComponent,
});


const AsyncActivePlansContainer = Loadable({
    loader: () => import('../modules/subscription-details/container/ActivePlansContainer' /* webpackChunkName: "ActivePlansContainer" */),
    loading: SubscriptionLoaderComponent,
});


const AsyncMyPlansContainer = Loadable({
    loader: () => import('../modules/subscription-details/container/MyPlansContainer' /* webpackChunkName: "MyPlansContainer" */),
    loading: SubscriptionLoaderComponent,
});

const AsyncCpPlanDetailPage = Loadable({
    loader: () => import('../modules/subscription-details/container/CpPlanDetailPage' /* webpackChunkName: "CpPlanDetailPage" */),
    loading: SubscriptionLoaderComponent,
});

const AsyncNoPlanContainer = Loadable({
    loader: () => import('../modules/subscription-details/container/NoPlanContainer' /* webpackChunkName: "NoPlanContainer" */),
    loading: SubscriptionLoaderComponent,
});

const AsyncSubscriptionSuccessFailurePopUp = (
    lazy(() => (
        import('../modules/subscription-details/component/SubscriptionSuccessFailurePopUp' /* webpackChunkName: "SubscriptionSuccessFailurePopUp" */)
    ))
);

const AsyncHappyCodeContainer = Loadable({
    loader: () => import('../modules/happycode/containers/HappyCodeContainer' /* webpackChunkName: "HappyCodeContainer" */),
    loading: LoaderComponent,
});

const AsyncTermsOfUse = Loadable({
    loader: () => import('../modules/settings/components/TermsOfUse' /* webpackChunkName: "TermsOfUse" */),
    loading: LoaderComponent,
});


const AsyncPrivacyPolicy = Loadable({
    loader: () => import('../modules/settings/components/PrivacyPolicy' /* webpackChunkName: "PrivacyPolicy" */),
    loading: LoaderComponent,
});


const AsyncContentDetailsContainer = Loadable({
    loader: () => import('@airtel-feature/content-details/content-details/ContentDetailsContainer' /* webpackChunkName: "ContentDetailsContainer" */),
    loading: () => <LoaderComponent pageType="live-tv" />,
});

const AsyncContentLayoutContainer = Loadable({
    loader: () => import('@airtel-feature/content-details/content-details/containers/ContentLayoutContainer' /* webpackChunkName: "ContentLayoutContainer" */),
    loading: () => LayoutLoaderComponent,
});

const AsyncOneHubWrapperWeb = Loadable({
    loader: () => import('../modules/onehub/OneHubWrapperWeb' /* webpackChunkName: "OneHubWrapperWeb" */),
    loading: () => LayoutLoaderComponent,
});

const AsyncHelpComponent = Loadable({
    loader: () => import('../modules/settings/components/HelpComponent' /* webpackChunkName: "HelpComponent" */),
    loading: LoaderComponent,
});


const AsyncWatchlistContainer = Loadable({
    loader: () => import('../modules/settings/containers/WatchlistContainer' /* webpackChunkName: "WatchlistContainer" */),
    loading: LoaderComponent,
});


const AsyncYoupagecomponent = Loadable({
    loader: () => import('../modules/settings/components/YoupageComponent' /* webpackChunkName: "YoupageComponent" */),
    loading: LoaderComponent,
});

const AsyncSettingsContainer = Loadable({
    loader: () => import('../modules/settings/containers/settings-container/SettingsContainer' /* webpackChunkName: "SettingsContainer" */),
    loading: LoaderComponent,
});

const AsyncSearchListContainer = Loadable({
    loader: () => import('../modules/search/containers/SearchListContainer' /* webpackChunkName: "SearchListContainer" */),
    loading: LoaderComponent,
});


const AsyncArtistContainer = Loadable({
    loader: () => import('../modules/artist/ArtistContainer' /* webpackChunkName: "ArtistContainer" */),
    loading: ArtistPageLoader,
});


const AsyncLanguageSettingsContainer = Loadable({
    loader: () => import('../modules/settings/containers/language-settings-container/LanguageSettingsContainer' /* webpackChunkName: "LanguageSettingsContainer" */),
    loading: LoaderComponent,
});


const AsyncChannelPartnerContainer = Loadable({
    loader: () => import('../modules/channel-partner/container/ChannelPartnerLayoutContainer' /* webpackChunkName: "ChannelPartnerLayoutContainer" */),
    loading: () => LayoutLoaderComponent,
});

const AsyncEventDetailContainer = Loadable({
    loader: () => import('@airtel-feature/content-details/content-details/containers/EventDetailContainer' /* webpackChunkName: "EventDetailContainer" */),
    loading: LoaderComponent,
});


const AsyncSearchContainer = Loadable({
    loader: () => import('../modules/search/containers/SearchContainer' /* webpackChunkName: "SearchContainer" */),
    loading: () => <LoaderComponent pageType="searchPage" />,
});

const AsyncRedirectRouteContainer = Loadable({
    loader: () => import('./RedirectRouteContainer' /* webpackChunkName: "RedirectRouteContainer" */),
    loading: LoaderComponent,
});


const AsyncSchedulePageContainer = Loadable({
    loader: () => import('../modules/schedule/containers/SchedulePageContainer' /* webpackChunkName: "SchedulePageContainer" */),
    loading: LoaderComponent,
});

const AsyncPreferredPartnerContainer = Loadable({
    loader: () => import('../modules/preferred-partner/container/PreferredPartnerContainer' /* webpackChunkName: "PreferredPartnerContainer" */),
    loading: LoaderComponent,
});


const AsyncAccountDeletionContainer = Loadable({
    loader: () => import('../modules/account-deletion/component/AccountDeletionComponent' /* webpackChunkName: "AccountDeletionComponent" */),
    loading: LoaderComponent,
});

const AsyncProfileContainer = Loadable({
    loader: () => import('../modules/profile/container/ProfileContainer' /* webpackChunkName: "ProfileContainer" */),
    loading: LoaderComponent,
});

const AsyncDeepLinkLandingPage = Loadable({
    loader: () => import('../modules/DeepLinkLandingPage/DeepLinkLandingPage' /* webpackChunkName: "DeepLinkLandingPage" */),
    loading: LoaderComponent,
});

const AsyncPartnerSubscriptionClaimContainer = Loadable({
    loader: () => import('../modules/partnerSubscriptionClaim/PartnerSubscriptionClaimContainer' /* webpackChunkName: "PartnerSubscriptionClaimContainer" */),
    loading: LoaderComponent,
});

const AsyncHoichoiCampaign = Loadable({
    loader: () => import('../modules/shared/components/loader/HoichoiLoaderComponent' /* webpackChunkName: "HoichoiCampaign" */),
    loading: HoichoiLoaderComponent,
});
const AsyncBlogComponent = Loadable({
    loader: () => import('../modules/blog/BlogComponent' /* webpackChunkName: "BlogComponent" */),
    loading: () => <LoaderComponent pageType="blogs" />,
});

const AsyncBlogDescriptionComponent = Loadable({
    loader: () => import('../modules/blog/BlogDescription/BlogDescription' /* webpackChunkName: "BlogDescriptionComponent" */),
    loading: () => <LoaderComponent pageType="blogs" />,
});

const AsyncOffersCheckComponent = Loadable({
    loader: () => import('../modules/OffersCheck/component/OffersCheckComponent' /* webpackChunkName: "AsyncOffersCheckComponent" */),
    loading: HoichoiLoaderComponent,
});
const AsyncSiteMapComponent = Loadable({
    loader: () => import('../modules/site-map/siteMap' /* webpackChunkName: "BlogDescriptionComponent" */),
    loading: LoaderComponent,
});
const AsyncRechargePlanDetail = Loadable({
    loader: () => import('../modules/subscription-details/component/RechargePlans/RechargePlansWrapper' /* webpackChunkName: "RechargePlanDetail" */),
    loading: SubscriptionLoaderComponent,
});
const AsyncPaymentsLandingPage = Loadable({
    loader: () => import('../modules/PaymentsLandingPage/PaymentsLandingPage' /* webpackChunkName: "DeepLinkLandingPage" */),
    loading: LoaderComponent,
});

const AyncSubscriptionOffersLanding = Loadable({
    loader: () => import('@airtel-feature/subscription-offers/SubscriptionOffersLanding.js' /* webpackChunkName: "SubscriptionOffersLanding" */),
    loading: SubscriptionLoaderComponent,
});

const AyncChatbotPage = Loadable({
    loader: () => import('../modules/chatbot/chatbotPage.js' /* webpackChunkName: "Chatbot Page" */),
    loading: SubscriptionLoaderComponent,
});

const AsyncFancodeRedirect = Loadable({
    loader: () => import('../modules/FancodeRedirection/FancodeRedirection.js' /* webpackChunkName: "FancodeRedirect" */),
    loading: LoaderComponent,
});

const AsyncQrCodeConfirmation = Loadable({
    loader: () => import('../modules/auth/components/QrCodeConfirmation' /* webpackChunkName: "QRCodeConfirmation" */),
    loading: LoaderComponent,
});

const AsyncDirectPlaybackContainer = (
    lazy(() => (
        import('@airtel-feature/playback/DirectPlaybackContainer') /* webpackChunkName: "DirectPlaybackContainer" */
    ))
);

const AsyncThanksSSORedirect = (
    lazy(() => (
        import('@airtel-feature/SSO/ThanksSSORedirect') /* webpackChunkName: "ThanksSSORedirect" */
    ))
);

const AsyncActivationPage = (
    lazy(() => import('@airtel-feature/onehub/components/ActivationPage/ActivationPage'))
);


const routes = getAllRouters({
    PrivateRouteContainer,
    LoginRouteContainer,
    App,
    LayoutContainerWrapper,
    // : props => (
    //     <Suspense fallback={<LayoutLoaderComponent />}>
    //         <AsyncLayoutContainerWrapper {...props} />
    //     </Suspense>
    // ),
    ErrorPageComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncErrorPageComponent {...props} />
        </Suspense>
    ),
    SearchContainer: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncSearchContainer {...props} />
        </Suspense>
    ),
    SearchListContainer: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncSearchListContainer {...props} />
        </Suspense>
    ),
    ListingContainer,
    ListingContainerWrapper,
    SettingsContainer: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncSettingsContainer {...props} />
        </Suspense>
    ),
    HelpComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncHelpComponent {...props} />
        </Suspense>
    ),
    WatchlistContainer: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncWatchlistContainer {...props} />
        </Suspense>
    ),
    YoupageComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncYoupagecomponent {...props} />
        </Suspense>
    ),
    Faq: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncFaq {...props} />
        </Suspense>
    ),
    StaticComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncStaticComponent {...props} />
        </Suspense>
    ),
    XstreamPremium: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncXstreamPremium {...props} />
        </Suspense>
    ),
    XstreamPremium2: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncXstreamPremium2 {...props} />
        </Suspense>
    ),
    // ContentLayoutContainers: props => (
    //     <Suspense fallback={<LoaderComponent />}>
    //         <AsyncContentLayoutContainers {...props} />
    //     </Suspense>
    // ),
    SubscriptionContainer: props => (
        <Suspense fallback={<SubscriptionLoaderComponent />}>
            <AsyncSubscriptionContainer {...props} />
        </Suspense>
    ),
    PromotionComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncPromotionComponent {...props} />
        </Suspense>
    ),
    XmpClaimContainer: props => (
        <Suspense fallback={<SubscriptionLoaderComponent />}>
            <AsyncXmpClaimContainer {...props} />
        </Suspense>
    ),
    XmpClaimModal: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncXmpClaimModal {...props} />
        </Suspense>
    ),
    XmpMultipleClaimContainer: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncXmpMultipleClaimContainer {...props} />
        </Suspense>
    ),
    SingleClaimSuccessPopUp: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncSingleClaimSuccessPopUp {...props} />
        </Suspense>
    ),
    MultipleClaimSuccessPopUp: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncMultipleClaimSuccessPopUp {...props} />
        </Suspense>
    ),

    CpPlanDetailPage: props => (
        <Suspense fallback={<SubscriptionLoaderComponent />}>
            <AsyncCpPlanDetailPage {...props} />
        </Suspense>
    ),

    NoSubscriptionComponent: props => (
        <Suspense fallback={<SubscriptionLoaderComponent />}>
            <AsyncNoSubscriptionComponent {...props} />
        </Suspense>
    ),
    PlansAndOffersContainer: props => (
        <Suspense fallback={<SubscriptionLoaderComponent />}>
            <AsyncPlansAndOffersContainer {...props} />
        </Suspense>
    ),
    PartnerListingComponent: props => (
        <Suspense fallback={<SubscriptionLoaderComponent />}>
            <AsyncPartnerListingComponent {...props} />
        </Suspense>
    ),
    ActivePlansContainer: props => (
        <Suspense fallback={<SubscriptionLoaderComponent />}>
            <AsyncActivePlansContainer {...props} />
        </Suspense>
    ),
    NoPlanContainer: props => (
        <Suspense fallback={<SubscriptionLoaderComponent />}>
            <AsyncNoPlanContainer {...props} />
        </Suspense>
    ),
    MyPlansContainer: props => (
        <Suspense fallback={<SubscriptionLoaderComponent />}>
            <AsyncMyPlansContainer {...props} />
        </Suspense>
    ),
    SubscriptionSuccessFailurePopUp: props => (
        <Suspense fallback={<div />}>
            <AsyncSubscriptionSuccessFailurePopUp {...props} />
        </Suspense>
    ),
    HappyCodeContainer: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncHappyCodeContainer {...props} />
        </Suspense>
    ),
    TermsOfUse: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncTermsOfUse {...props} />
        </Suspense>
    ),
    PrivacyPolicy: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncPrivacyPolicy {...props} />
        </Suspense>
    ),
    LanguageSettingsContainer: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncLanguageSettingsContainer {...props} />
        </Suspense>
    ),
    ArtistContainer: props => (
        <Suspense fallback={<LoaderComponent pageType="artistPage" />}>
            <AsyncArtistContainer {...props} />
        </Suspense>
    ),
    ContentDetailsContainer: props => (
        <Suspense fallback={<LoaderComponent pageType="live-tv" />}>
            <AsyncContentDetailsContainer {...props} />
        </Suspense>
    ),
    ContentLayoutContainer: props => (
        <Suspense fallback={<LayoutLoaderComponent />}>
            <AsyncContentLayoutContainer {...props} />
        </Suspense>
    ),
    ContentDetailLayoutContainerWrapper,
    // ContentDetailLayoutContainer: props => (
    //     <Suspense fallback={<LoaderComponent pageType="cdp" />}>
    //         <AsyncContentDetailLayoutContainer {...props} />
    //     </Suspense>
    // ),
    // DimensionPageContainer,
    RedirectRouteContainer: props => (
        <Suspense fallback={<LoaderComponent {...props} />}>
            <AsyncRedirectRouteContainer {...props} />
        </Suspense>
    ),
    PreferredPartnerContainer: props => (
        <Suspense fallback={<LoaderComponent {...props} />}>
            <AsyncPreferredPartnerContainer {...props} />
        </Suspense>
    ),

    SchedulePageContainer: props => (
        <Suspense fallback={<LoaderComponent {...props} />}>
            <AsyncSchedulePageContainer {...props} />
        </Suspense>
    ),

    DTHLoginComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncDTHLoginComponent {...props} />
        </Suspense>
    ),
    DTHPaymentComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncDTHPaymentComponent {...props} />
        </Suspense>
    ),
    ChannelPartnerLayoutContainer: props => (
        <Suspense fallback={<LayoutLoaderComponent />}>
            <AsyncChannelPartnerContainer {...props} />
        </Suspense>
    ),
    EventDetailContainer: props => (
        <Suspense fallback={<LoaderComponent {...props} />}>
            <AsyncEventDetailContainer {...props} />
        </Suspense>
    ),
    AccountDeletionComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncAccountDeletionContainer {...props} />
        </Suspense>
    ),
    HappyCodeGenerateComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncHappyCodeGenerateComponent {...props} />
        </Suspense>
    ),

    ProfileContainer: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncProfileContainer {...props} />
        </Suspense>
    ),
    PartnerSubscriptionClaimContainer: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncPartnerSubscriptionClaimContainer {...props} />
        </Suspense>
    ),

    HoichoiLandingPage: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncHoichoiCampaign {...props} />
        </Suspense>
    ),
    DeepLinkLandingPage: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncDeepLinkLandingPage {...props} />
        </Suspense>
    ),
    BlogComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncBlogComponent {...props} />
        </Suspense>
    ),
    BlogDescriptionComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncBlogDescriptionComponent {...props} />
        </Suspense>
    ),
    SiteMapComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncSiteMapComponent {...props} />
        </Suspense>
    ),
    OffersCheckComponent: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncOffersCheckComponent {...props} />
        </Suspense>
    ),
    RechargePlanDetail: props => (
        <Suspense fallback={<div />}>
            <AsyncRechargePlanDetail {...props} />
        </Suspense>
    ),
    PaymentsLandingPage: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncPaymentsLandingPage {...props} />
        </Suspense>
    ),
    SubscriptionOffersLanding: props => (
        <Suspense fallback={<SubscriptionLoaderComponent />}>
            <AyncSubscriptionOffersLanding {...props} />
        </Suspense>
    ),
    ChatbotPage: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AyncChatbotPage {...props} />
        </Suspense>
    ),
    FancodeRedirect: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncFancodeRedirect {...props} />
        </Suspense>
    ),
    QrCodeConfirmation: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncQrCodeConfirmation {...props} />
        </Suspense>
    ),
    DirectPlaybackContainer: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncDirectPlaybackContainer {...props} />
        </Suspense>
    ),
    ThanksSSORedirect: props => (
        <Suspense fallback={<LoaderComponent />}>
            <AsyncThanksSSORedirect {...props} />
        </Suspense>
    ),
    OneHubWrapperWeb: props => (
        <Suspense fallback={<LoaderComponent defaultShimmer={true} />}>
            <AsyncOneHubWrapperWeb {...props} />
        </Suspense>
    ),
    ActivationPage: props => (
        <Suspense fallback={<LoaderComponent {...props} />}>
            <AsyncActivationPage {...props} />
        </Suspense>
    ),

});

export default routes;
