import { fetchSearchSessionCode, generateSearchSessionCode, getTimeStamp } from '@airtel-tv/utils/GlobalUtil';
import { getCurrentUrl } from '@airtel-tv/utils/WindowUtil';
import { logFireBaseEvent } from '@airtel-tv/firebase';
import EventModel from './EventModel';
import EventType from './EventType';
import EventScheduler from './EventScheduler';

const eventStart = (eventModel, userConfig = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.APP_START.val;
    model.ts = getTimeStamp();
    const { userInfo: { circle = '' } = {} } = userConfig;
    model.meta = {
        ...model.meta,
        circle,
        source: getCurrentUrl(),
    };
    EventScheduler.submit(model, EventType.APP_START.isCritical);
    logFireBaseEvent({
        event: EventType.APP_START.val,
        params: model.meta,
    });
    return false;
};

const eventModel = EventModel;
const appStartEvent = ({ userConfig } = {}) => eventStart(eventModel, userConfig);


export const appInitEvent = () => {
    const model = { ...eventModel };
    model.event_type = EventType.APP_INIT.val;
    model.ts = getTimeStamp();
    model.meta = { source: getCurrentUrl() };
    EventScheduler.submit(model, EventType.APP_INIT.isCritical);
    logFireBaseEvent({
        ...model.meta,
        event: EventType.APP_INIT.val,
        params: model.meta,
    });
    return false;
};


export const screenVisibleEvent = (sourceName, isCritical = false, meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SCREEN_VISIBLE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        source_name: sourceName,
        ...meta,
    };
    EventScheduler.submit(model, isCritical || EventType.SCREEN_VISIBLE.isCritical);
};

export const contentVisibleEvent = (meta = {}, sourceName = '', isCritical = false) => {
    const model = { ...eventModel };
    model.event_type = EventType.CONTENT_VISIBLE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        source_name: sourceName,
        ...meta,
    };
    EventScheduler.submit(model, isCritical || EventType.CONTENT_VISIBLE.isCritical);
};

export const clickEvent = (meta = {}, playbackMeta) => {
    const model = { ...eventModel };
    model.event_type = EventType?.CLICK?.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    EventScheduler.submit(model, EventType.CLICK.isCritical);
};

export const autoSuggestClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AUTOSUGGEST_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AUTOSUGGEST_CLICK.isCritical);
};

export const itemSearchEvent = (meta = {}) => {
    const model = { ...eventModel };
    // model.event_type = EventType.ITEM_SEARCH.val;
    model.event_type = EventType.CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
        search_session_id: generateSearchSessionCode(),
    };
    EventScheduler.submit(model, EventType.ITEM_SEARCH.isCritical);
};

export const criticalClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.CRITICAL_CLICK.isCritical);
};

export const subscriptionClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SUBSCRIPTION_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SUBSCRIPTION_CLICK.isCritical);
};

export const adBreakStartEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_BREAK_START.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_BREAK_START.isCritical);
};

export const adLoadEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_LOAD.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_LOAD.isCritical);
};

export const adStartEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_START.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_START.isCritical);
};

export const adCompleteEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_COMPLETE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_COMPLETE.isCritical);
};

export const adClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_CLICK.isCritical);
};

export const adPauseEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_PAUSE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_PAUSE.isCritical);
};

export const adResumeEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_RESUME.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_RESUME.isCritical);
};

export const adBreakEndEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_BREAK_END.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_BREAK_END.isCritical);
};

export const adInitEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_INIT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_INIT.isCritical);
};

export const adStartedEvent = (meta = {}) => { // v2 event
    const model = { ...eventModel };
    model.event_type = EventType.AD_STARTED.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_STARTED.isCritical);
};

export const adEndedEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_ENDED.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_ENDED.isCritical);
};

export const adErrorEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.AD_ERROR.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.AD_ERROR.isCritical);
};


export const pageLoadEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PAGE_LOAD.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PAGE_LOAD.isCritical);
};

export const seePlansXppEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SEE_PLANS_XPP.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SEE_PLANS_XPP.isCritical);
};


export const seeMoreAlacarteEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SEE_MORE_ALACARTE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SEE_MORE_ALACARTE.isCritical);
};

export const appErrorEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.APP_ERROR_EVENT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.APP_ERROR_EVENT.isCritical);
};


export const offerSelectionEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.OFFER_SELECTION.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.OFFER_SELECTION.isCritical);
};

export const planSelectionEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAN_SELECTION.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PLAN_SELECTION.isCritical);
};

export const subscribeClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.CONTINUE_TO_PAY.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.CONTINUE_TO_PAY.isCritical);
};
export const renewSelectedClick = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SELECT_RENEW.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SELECT_RENEW.isCritical);
};

export const redirectToPayment = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.REDIRECT_TO_PAYMENT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.REDIRECT_TO_PAYMENT.isCritical);
};

export const continueToPayError = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.CONTINUE_TO_PAY_ERROR.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.CONTINUE_TO_PAY_ERROR.isCritical);
};

export const seeOtherPlansClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SEE_OTHER_PLANS_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SEE_OTHER_PLANS_CLICK.isCritical);
};

export const seePlansClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SEE_PLANS_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SEE_PLANS_CLICK.isCritical);
};

export const rechargeNowClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.RECHARGE_NOW_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.RECHARGE_NOW_CLICK.isCritical);
};

export const rechargeXmpClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.RECHARGE_XMP_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.RECHARGE_XMP_CLICK.isCritical);
};

export const claimNowClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.CLAIM_NOW_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.CLAIM_NOW_CLICK.isCritical);
};
export const changeCpClicked = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.CHANGED_CP_CLICKED.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.CHANGED_CP_CLICKED.isCritical);
};

export const watchForFreeClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.WATCH_FOR_FREE_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.WATCH_FOR_FREE_CLICK.isCritical);
};

export const exploreOtherChannelsClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.EXPLORE_OTHER_CHANNELS_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.EXPLORE_OTHER_CHANNELS_CLICK.isCritical);
};

export const unlockNowClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.UNLOCK_NOW_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.UNLOCK_NOW_CLICK.isCritical);
};

export const okGotItClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.OK_GOT_IT_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.OK_GOT_IT_CLICK.isCritical);
};
export const onCancelXmpClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.OK_CANCEL_XMP_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.OK_CANCEL_XMP_CLICK.isCritical);
};
export const onCancelClickXmpPage = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.ON_CANCEL_CLICK_XMP_PAGE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.ON_CANCEL_CLICK_XMP_PAGE.isCritical);
};

export const doLaterClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.DO_LATER_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.DO_LATER_CLICK.isCritical);
};

export const subscribeMoreClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SUBSCRIBE_MORE_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SUBSCRIBE_MORE_CLICK.isCritical);
};

export const seePlansClacarteClickedEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SEE_PLANS_ALACARTE_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SEE_PLANS_ALACARTE_CLICK.isCritical);
};

export const nonAirtelPageLoadEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.POPUP_VISIBLE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.POPUP_VISIBLE.isCritical);
};
export const swicthToAirtelClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SWITCH_TO_AIRTEL.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SWITCH_TO_AIRTEL.isCritical);
};

export const seeRechargeClickedEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SEE_PLANS_ALACARTE_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SEE_PLANS_ALACARTE_CLICK.isCritical);
};

// export const playStartEvent = (meta = {}) => {
//     const model = { ...eventModel };
//     model.event_type = EventType.PLAY_START.val;
//     model.ts = getTimeStamp();
//     model.meta = {...model.meta, ...meta};
//     EventScheduler.submit(model, EventType.PLAY_START.isCritical);
// };

export const playStatusEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAY_STATUS.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PLAY_STATUS.isCritical);
};

export const playerFullScreenEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.FULL_SCREEN.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.FULL_SCREEN.isCritical);
};

export const exitFullScreenEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.UNDO_FULLSCREEN.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.UNDO_FULLSCREEN.isCritical);
};

export const playPauseEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAY_PAUSE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PLAY_PAUSE.isCritical);
};

export const firstPlayEvent = (meta = {}, playbackMeta) => { // v2
    const model = { ...eventModel };
    model.event_type = EventType.FIRST_PLAY.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    EventScheduler.submit(model, EventType.FIRST_PLAY.isCritical);
};

export const playEndEvent = (meta = {}, playbackMeta) => { // v2
    const model = { ...eventModel };
    model.event_type = EventType.PLAY_SESSION_END.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    EventScheduler.submit(model, EventType.PLAY_SESSION_END.isCritical);
};

export const playStopEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAY_STOP.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PLAY_STOP.isCritical);
};

export const fullScreenEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.FULL_SCREEN.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.FULL_SCREEN.isCritical);
};

export const playbackErrorEvent = (meta = {}, playbackMeta) => {
    const model = { ...eventModel };
    const { is_play_start_error } = meta;
    const eventType = is_play_start_error === 'true' ? EventType.PLAYSTART_ERROR : EventType.ERROR_PLAYBACK;
    model.event_type = eventType.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    console.log('error====>', meta, playbackMeta);
    EventScheduler.submit(model, eventType.isCritical);
};

export const playerInitEventV2 = (meta = {}, playbackMeta) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAY_INIT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    console.log('==> play init', meta, playbackMeta);
    EventScheduler.submit(model, EventType.PLAY_INIT.isCritical);
};

export const playerInitEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAY_INIT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PLAY_INIT.isCritical);
};

export const playerReadyToPlay = (meta = {}, playbackMeta) => {
    const model = { ...eventModel };
    model.event_type = EventType.READY_TO_PLAY.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    console.log('==> play ready before api call', meta, playbackMeta);
    EventScheduler.submit(model, EventType.READY_TO_PLAY.isCritical);
};

export const playStartEvent = (meta = {}, playbackMeta) => { // NGTD: Check for older implementation
    const model = { ...eventModel };
    model.event_type = EventType.PLAY_START.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    console.log('==> play  before api call', meta, playbackMeta);
    EventScheduler.submit(model, EventType.PLAY_START.isCritical);
};

export const playerLogEvent = (meta = {}, playbackMeta) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAYER_LOG.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    console.log('==> play  log event before api call', meta, playbackMeta);
    EventScheduler.submit(model, EventType.PLAYER_LOG.isCritical);
};

export const pauseEvent = (meta = {}, playbackMeta) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAY_PAUSE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    EventScheduler.submit(model, EventType.PLAY_PAUSE.isCritical);
};

export const resumeEvent = (meta = {}, playbackMeta) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAY_RESUME.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    EventScheduler.submit(model, EventType.PLAY_RESUME.isCritical);
};

export const apiInitEvent = (meta = {}, playbackMeta) => {
    const model = { ...eventModel };
    model.event_type = EventType.API_INIT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    EventScheduler.submit(model, EventType.API_INIT.isCritical);
};

export const apiResponseEvent = (meta = {}, playbackMeta) => {
    const model = { ...eventModel };
    model.event_type = EventType.API_RESPONSE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    EventScheduler.submit(model, EventType.API_RESPONSE.isCritical);
};

export const apiLogEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.API_LOG.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.API_RESPONSE.isCritical);
};

export const playerSeekEvent = (meta = {}, playbackMeta) => { // v2
    const model = { ...eventModel };
    model.event_type = EventType.PLAYER_SEEK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    EventScheduler.submit(model, EventType.PLAYER_SEEK.isCritical);
};
// export const playerInitEvent = (meta = {}) => {
//     const model = { ...eventModel };
//     model.event_type = EventType.PLAY_INIT.val;
//     model.ts = getTimeStamp();
//     model.meta = {...model.meta, ...meta};
//     EventScheduler.submit(model, EventType.PLAY_INIT.isCritical);
// };

export const popUpVisibleEvent = (meta = {}, playbackMeta) => {
    const model = { ...eventModel };
    model.event_type = EventType.POPUP_VISIBLE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    playbackMeta && (model.core_playback_meta = playbackMeta);
    EventScheduler.submit(model, EventType.POPUP_VISIBLE.isCritical);
};

export const failureRetryClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.FAILURE_RETRY_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.FAILURE_RETRY_CLICK.isCritical);
};

export const planPurchaseRequest = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAN_PURCHASE_REQUEST.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PLAN_PURCHASE_REQUEST.isCritical);
};
export const planPurchaseUtkn = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAN_PURCHASE_REQUEST_UTKN.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PLAN_PURCHASE_REQUEST_UTKN.isCritical);
};

export const subscriptionSuccessOkClick = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SUBSCRIPTION_SUCCESS_OK_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SUBSCRIPTION_SUCCESS_OK_CLICK.isCritical);
};
export const subscriptionCancelClick = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SUBSCRIPTION_CANCEL_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SUBSCRIPTION_CANCEL_CLICK.isCritical);
};

export const addToBillSuccessOkClick = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.ATB_OK_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.ATB_OK_CLICK.isCritical);
};

export const addToBillCancelClick = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.ATB_CANCEL_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.ATB_CANCEL_CLICK.isCritical);
};

export const addToBillInprogressClick = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.ATB_INPROGRESS_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.ATB_INPROGRESS_CLICK.isCritical);
};

export const regOtpResponseEvent = (response) => {
    const model = { ...eventModel };
    model.event_type = EventType.REG_OTP_RESPONSE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        response,
    };
    EventScheduler.submit(model, EventType.REG_OTP_RESPONSE.isCritical);
};

export const playerRewindEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAYER_REWIND.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PLAYER_REWIND.isCritical);
};

export const playerForwardEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAYER_FORWARD.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PLAYER_FORWARD.isCritical);
};

export const seekBarEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SEEK_BAR.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SEEK_BAR.isCritical);
};

export const goLiveEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.LIVE_BUTTON_CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.LIVE_BUTTON_CLICK.isCritical);
};

export const railVisibleEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.RAIL_VISIBLE_EVENT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.RAIL_VISIBLE_EVENT.isCritical);
};

export const bannerVisibleEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.BANNER_VISIBLE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.BANNER_VISIBLE.isCritical);
};

export const railScrollEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.RAIL_SCROLL_EVENT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.RAIL_SCROLL_EVENT.isCritical);
};

export const tileScrollEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.TILE_SCROLL_EVENT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.TILE_SCROLL_EVENT.isCritical);
};

export const addToWatchlistClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.ADD_TO_WATCHLIST.isCritical);
};

export const pipOpenClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PIP_OPEN.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PIP_OPEN.isCritical);
};

export const pipClosedClickEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PIP_CLOSE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PIP_CLOSE.isCritical);
};

export const switchToAnotherTabEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.OUT_WEB.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.OUT_WEB.isCritical);
};

export const switchBackToXstreamTabEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.IN_WEB.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.IN_WEB.isCritical);
};

export const megaMenuHoverEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.MEGA_MENU_HOVER.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.MEGA_MENU_HOVER.isCritical);
};

export const happyCodeVerificationStatusEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.HAPPYCODE_VERIFICATION_STATUS.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.HAPPYCODE_VERIFICATION_STATUS.isCritical);
};

// apple account deletion

export const screenOpenedEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SCREEN_OPENED.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SCREEN_OPENED.isCritical);
};

export const screenClosedEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.SCREEN_CLOSED.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.SCREEN_CLOSED.isCritical);
};

export const dummyEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.DUMMY_EVENT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.DUMMY_EVENT.isCritical);
};
export const activateEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.ACTIVATE_EVENT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.ACTIVATE_EVENT.isCritical);
};
export const activateSuccessEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.ACTIVATE_SUCCESS_EVENT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.ACTIVATE_SUCCESS_EVENT.isCritical);
};
export const activateFailureEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.ACTIVATE_FAILURE_EVENT.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.ACTIVATE_FAILURE_EVENT.isCritical);
};

export const placeholdereEvent = (eventType, meta = {}, isCritical = false) => {
    const model = { ...eventModel };
    model.event_type = eventType;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, isCritical);
};

export const contentDetailsEvent = (meta = {}, eventDetails) => {
    const model = { ...eventModel };
    model.event_type = eventDetails.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, eventDetails.isCritical);
};

export const playbackEvent = (meta = {}, eventDetails) => {
    const model = { ...eventModel };
    model.event_type = eventDetails.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, eventDetails.isCritical);
};

export const planChangeEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PLAN_CHANGE_VALUE.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PLAN_CHANGE_VALUE.isCritical);
};

export const contentDetailVisible = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.PAGE_LOAD.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.PAGE_LOAD.isCritical);
};


export const appInstallEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.APP_INSTALL.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.APP_INSTALL.isCritical);
};

export const appInAppRedirectionEvent = (meta = {}) => {
    const model = { ...eventModel };
    model.event_type = EventType.APP_IN_APP_REDIRECTION.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, EventType.APP_IN_APP_REDIRECTION.isCritical);
};
export const searchEvents = (meta = {}, eventType = '') => {
    const model = { ...eventModel };
    model.event_type = eventType && eventType?.val ? eventType.val : EventType.CLICK.val;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
        search_session_id: generateSearchSessionCode(),
    };
    EventScheduler.submit(model, eventType?.isCritical || EventType.CLICK.isCritical);
};

export const backButtonEvents = (meta = {}, eventType = '') => {
    const model = { ...eventModel };
    model.event_type = eventType && eventType?.val ? eventType.val : EventType.CLICK.val;
    model.ts = getTimeStamp();
    const searchSessionCode = fetchSearchSessionCode();
    model.meta = {
        ...model.meta,
        ...meta,
        ...(searchSessionCode && { searchSessionCode }),
    };
    EventScheduler.submit(model, eventType?.isCritical || EventType.CLICK.isCritical);
};

export const sunnxtPlayerEvents = (meta = {}, eventType = '') => {
    const { isCritical = false, val: eventTypePrefix = '' } = EventType.SUNNXT_PLAYER_LOGS;
    const model = { ...eventModel };
    model.event_type = eventTypePrefix + eventType;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, isCritical);
};

export const adsSDKEvent = (data = {}, eventType = '') => {
    const model = { ...eventModel };
    model.event_type = eventType;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...data.meta,
    };
    model.core_playback_meta = data.playbackMeta;
    console.log(model, 'logging');
    EventScheduler.submit(model, true);
};

export const loginCompleteEvent = (meta = {}) => {
    const { isCritical = false, val: eventTypeVal = '' } = EventType.LOGIN_COMPLETE;
    const model = { ...eventModel };
    model.event_type = eventTypeVal;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, isCritical);
};


export const nudgeVisibleEvent = (meta = {}) => {
    const { isCritical = false, val: eventTypeVal = '' } = EventType.NUDGE_VISIBLE;
    const model = { ...eventModel };
    model.event_type = eventTypeVal;
    model.ts = getTimeStamp();
    model.meta = {
        ...model.meta,
        ...meta,
    };
    EventScheduler.submit(model, isCritical);
};

export const ssoFailedEvent = ({ meta = {} }) => {
    const { isCritical = false, val: eventTypeVal = '' } = EventType.SSO_FAILED;
    const model = { ...eventModel };
    model.event_type = eventTypeVal;
    model.ts = getTimeStamp();
    model.meta = meta;
    EventScheduler.submit(model, isCritical);
};

export const branchApiFailedEvent = ({ meta = {} }) => {
    const { isCritical = false, val: eventTypeVal = '' } = EventType.BRANCH_API_FAILED;
    const model = { ...eventModel };
    model.event_type = eventTypeVal;
    model.ts = getTimeStamp();
    model.meta = meta;
    EventScheduler.submit(model, isCritical);
};


export default appStartEvent;
