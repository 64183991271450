import { RoutingUtil, SubscriptionUtil } from '@airtel-tv/utils';
import { CUSTOMER_TYPE } from '@airtel-tv/constants/GlobalConst.js';

export const navigateToLSPayment = (params) => {
    const {
        tileProps: {
            planDetails: {
                packGroup,
                cpId: planCpId,
                planId,
                planCpTitle,
                planCpLogo,
            },
            planType,
            planDetails,
        },
        showNonAirtelUserActionDispatch,
        plansPaymentsFetchActionDispatch,
        navigate,
        circle,
        customerType,
        uid,
    } = params;
    RoutingUtil.subscribe({
        plan: { ...planDetails },
        circle,
        customerType,
        uid: 'ivB2XpSIUH9oMbyS_0',
        getSubscriptionUrls: SubscriptionUtil.getSubscriptionUrls,
        // RoutingUtil,
        showNonAirtelUserActionDispatch,
        navigate,
        // showBrowsePlansMeta,
        redirectToContent: false,
        plansPaymentsFetchActionDispatch,
        packGroup,
        planCpId,
        planCpTitle,
        planCpLogo,
        planId,
        CUSTOMER_TYPE,
        qrCodeQueryParams: {
            planType,
        },
    });
};

export const largescreenPlanPurchase = (params) => {
    navigateToLSPayment(params);
};
