const UserAction = {
    USER_CONFIG_INIT: 'USER_CONFIG_INIT',
    USER_CONFIG_SUCCESS: 'USER_CONFIG_SUCCESS',

    SHOW_EMAIL_CONFIRMATION: 'SHOW_EMAIL_CONFIRMATION',
    HIDE_EMAIL_CONFIRMATION: 'HIDE_EMAIL_CONFIRMATION',

    UPDATE_USER_EMAIL: 'UPDATE_USER_EMAIL',
    UPDATE_USER_EMAIL_ERROR: 'UPDATE_USER_EMAIL_ERROR',
    UPDATE_USER_EMAIL_SUCCESS: 'UPDATE_USER_EMAIL_SUCCESS',

    UPDATE_USER_LANGUAGE: 'UPDATE_USER_LANGUAGE',
    UPDATE_USER_LANGUAGE_ERROR: 'UPDATE_USER_LANGUAGE_ERROR',

    UPDATE_TEMP_USER_LANGUAGE: 'UPDATE_TEMP_USER_LANGUAGE',
    UPDATE_USER_AGE_CONSENT: 'UPDATE_USER_AGE_CONSENT',

    UPDATE_PREFERRED_PARTNER_SELECTIONS: 'UPDATE_PREFERRED_PARTNER_SELECTIONS',
    UPDATE_PREFERRED_PARTNER_ERROR: 'UPDATE_PREFERRED_PARTNER_ERROR',

    SHOW_PP_USER_MODAL: 'SHOW_PP_USER_MODAL',
    SHOW_REDEEM_XMP_ACTION_MODAL: 'SHOW_REDEEM_XMP_ACTION_MODAL',
    SHOW_NON_AIRTEL_USER_MODAL: 'SHOW_NON_AIRTEL_USER_MODAL',

    UPDATE_USER_PROFILE_DETAILS: 'UPDATE_USER_PROFILE_DETAILS',
    ACTIVATE_PARTNER_SUBSCRIPTION_INIT: 'ACTIVATE_PARTNER_SUBSCRIPTION_INIT',
    ACTIVATE_PARTNER_SUBSCRIPTION_ERROR: 'ACTIVATE_PARTNER_SUBSCRIPTION_ERROR',
    ACTIVATE_PARTNER_SUBSCRIPTION_SUCCESS: 'ACTIVATE_PARTNER_SUBSCRIPTION_SUCCESS',
    ACTIVATE_PARTNER_SUBSCRIPTION_CLEAR: 'ACTIVATE_PARTNER_SUBSCRIPTION_CLEAR',
    USER_LOCATION_SUCCESS: 'USER_LOCATION_SUCCESS',
    FETCH_USER_LOCATION: 'FETCH_USER_LOCATION',
    USER_CONFIG_WITH_LAYOUT_RESET: 'USER_CONFIG_WITH_LAYOUT_RESET',
    USER_CONFIG_WITH_ALL_RESET: 'USER_CONFIG_WITH_ALL_RESET',
};

export default UserAction;

export const userConfigSuccessAction = payload => ({
    type: UserAction.USER_CONFIG_SUCCESS,
    ...payload,
});

export const userLocationSuccessAction = payload => ({
    type: UserAction.USER_LOCATION_SUCCESS,
    ...payload,
});

export const showEmailConfirmation = payload => ({
    type: UserAction.SHOW_EMAIL_CONFIRMATION,
    ...payload,
});

export const hideEmailConfirmation = payload => ({
    type: UserAction.HIDE_EMAIL_CONFIRMATION,
    ...payload,
});

export const updateUserEmail = payload => ({
    type: UserAction.UPDATE_USER_EMAIL,
    ...payload,
});

export const updateUserEmaiLError = payload => ({
    type: UserAction.UPDATE_USER_EMAIL_ERROR,
    ...payload,
});

export const updateUserEmaiLSuccess = payload => ({
    type: UserAction.UPDATE_USER_EMAIL_SUCCESS,
    ...payload,
});

export const updateUserLanguageAction = payload => ({
    type: UserAction.UPDATE_USER_LANGUAGE,
    ...payload,
});

export const updateUserLanguageError = payload => ({
    type: UserAction.UPDATE_USER_LANGUAGE_ERROR,
    ...payload,
});

export const updateTempUserLang = payload => ({
    type: UserAction.UPDATE_TEMP_USER_LANGUAGE,
    ...payload,
});

export const updateUserAgeConsent = payload => ({
    type: UserAction.UPDATE_USER_AGE_CONSENT,
    ...payload,
});

export const updatePreferredPartnerSelections = payload => ({
    type: UserAction.UPDATE_PREFERRED_PARTNER_SELECTIONS,
    ...payload,
});

export const updatePreferredPartnerError = payload => ({
    type: UserAction.UPDATE_PREFERRED_PARTNER_ERROR,
    ...payload,
});

export const showPPModalAction = payload => ({
    type: UserAction.SHOW_PP_USER_MODAL,
    ...payload,
});


export const showRedeemXMPAction = payload => ({
    type: UserAction.SHOW_REDEEM_XMP_ACTION_MODAL,
    ...payload,
});

export const showNonAirtelUserAction = payload => ({
    type: UserAction.SHOW_NON_AIRTEL_USER_MODAL,
    payload,
});

export const updateUserConfig = payload => ({
    type: UserAction.USER_CONFIG_INIT,
    payload: { ...payload },
});


export const updateUserProfileDetails = payload => ({
    type: UserAction.UPDATE_USER_PROFILE_DETAILS,
    ...payload,
});

export const activatePartnerSubscription = payload => ({
    type: UserAction.ACTIVATE_PARTNER_SUBSCRIPTION_INIT,
    ...payload,
});

export const activatePartnerSubscriptionSuccess = payload => ({
    type: UserAction.ACTIVATE_PARTNER_SUBSCRIPTION_SUCCESS,
    ...payload,
});

export const activatePartnerSubscriptionError = payload => ({
    type: UserAction.ACTIVATE_PARTNER_SUBSCRIPTION_ERROR,
    ...payload,
});


export const activatePartnerSubscriptionClear = () => ({
    type: UserAction.ACTIVATE_PARTNER_SUBSCRIPTION_CLEAR,
});

export const fetchUserLocation = () => ({
    type: UserAction.FETCH_USER_LOCATION,
});


export const UserConfigWithLayoutReset = payload => ({
    type: UserAction.USER_CONFIG_WITH_LAYOUT_RESET,
    ...payload,
});

export const UserConfigWithAllReset = payload => ({
    type: UserAction.USER_CONFIG_WITH_ALL_RESET,
    ...payload,
});
