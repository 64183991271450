
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { getAppId, removeFalsy } from '@airtel-tv/utils/GlobalUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import env from '../../config';
// eslint-disable-next-line import/prefer-default-export
export const getChatbot = ({
    userContentProperties = {}, deviceUtil, token, uid, au,
}) => {
    let { base, paths, serverBase } = env.endpoints.userApi;
    base = checkWindowExist() ? base : serverBase;

    const params = {
        appId: getAppId(),
        ...userContentProperties,
        au,
    };


    const queryParamsString = LocationUtil.objectToQueryParams(params);
    const url = `${base}${paths.chatbot}?${queryParamsString}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${paths.chatbot}?${queryParamsString}`,
        token,
        uid,
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url,
        headers: removeFalsy(headers),
    });
};
