import {
    call, getContext, put, takeEvery,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { getOffers } from '../../service/end-points/ConfigApiService';
import { OffersCheckActions, offersFetchSuccess, offersFetchFailure } from './OffersCheckActions';
import ErrorHandler from '../error-handler/ErrorHandler';

function* onOffersFetchInit() {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const data = yield call(getOffers, {
            deviceUtil,
        });
        const response = {
            success: true,
            response: data,
        };

        // const response = {
        //     success: true,
        //     response:
        //     {
        //         planId: 6226,
        //         action: 'PAID_BUT_50PERCENT_OFF',
        //         title: 'title',
        //         subTitle: [
        //             'sub1',
        //             'sub2',
        //         ],
        //         cta: 'cta',
        //         img: 'https://image.airtel.tv/grandslam/content/2023_6_21/offercheck.png',
        //         appOpen: false,
        //     },

        // };
        yield put(offersFetchSuccess(response));
    }
    catch (e) {
        const payload = lodashGet(e, 'data', e);
        ErrorHandler({
            code: payload.errorcode,
            errortitle: payload.errortitle,
            showToastOnly: true,
        });
        yield put(offersFetchFailure({ error: payload }));
    }
}

export default [
    takeEvery(OffersCheckActions.OFFERS_FETCH_INIT, onOffersFetchInit),
];
