import {
    getAppId, getBuildNumber, getAppVersion, getDeviceType, getNetworkQuality,
} from '@airtel-tv/utils/GlobalUtil';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import { renderCrash, apiCrash } from './CrashEvents';
import CrashModel from './CrashModel';


// NOTE: THIS IS ONLY WEB COPIED, USE IN TV AFTER ANALYSIS AND DISCUSSION, TV HAS LESS KEYS THAN WEB
export const Crash = {
    initialize: (deviceUtil) => {
        CrashModel.appid = getAppId();
        CrashModel.bn = getBuildNumber();
        CrashModel.av = getAppVersion();
        CrashModel.did = deviceUtil.getDid();
        CrashModel.model = deviceUtil.getBrowserVersion();
        CrashModel.brand = deviceUtil.getBrowserName();
        CrashModel.dname = deviceUtil.getDeviceName();
        CrashModel.dt = getDeviceType();
        CrashModel.nq = getNetworkQuality();
        CrashModel.nt = deviceUtil.getNetworkType();
        CrashModel.os = deviceUtil.getOSName();
        CrashModel.ov = deviceUtil.getOSVersion();
        CrashModel.uid = AuthenticationUtil.getUid();
    },
    update: () => {
    },
};

export {
    renderCrash, apiCrash,
};
