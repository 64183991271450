import { ROUTE_PATHS, DEFAULT_NAV } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { LOCAL_STORE_KEYS, PROGRAM_TYPE_TITLE_MAPPING, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { LISTING_PAGE_ID_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { matchRoutes } from 'react-router';
import DeviceUtil from './DeviceUtil';
import { encodeForUrl, getWindowLocation } from './WindowUtil';
import { LocationUtil } from './LocationUtil';
import { trimCaptionsForUrl } from './GlobalUtil';
import { DateTime } from './DateTime';
import AppEnv from './GetEnv';
import browserStore from './BrowserStoreUtil';

export class RoutingUtil {
    static STATUS_CODES = {
        PERMANENT_REDIRECT: 301,
        TEMPORARY_REDIRECT: 302,
    };

    static get404Route() {
        return '/404';
    }

    static getPreferredPartnerRoute() {
        return '/select/partner';
    }

    static getEPGSchedulePage(id = '') {
        const queryParams = LocationUtil.objectToQueryParams({
            id,
        });
        return `/live-tv/channel?${queryParams}&&viewType=webview`;
    }

    static getXmpClaimRoute() {
        return ROUTE_PATHS.XMP_CLAIM_PAGE;
    }

    static getSearchListingUrl({
        category, genre, lang, title, sortBy, query,
    }) {
        const trimmedTitle = trimCaptionsForUrl(title);

        const queryParams = LocationUtil.objectToQueryParams({
            category: (category || '').toLowerCase(),
            genre,
            lang,
            sortBy,
            query,
        });

        return `/search/list/${encodeForUrl(trimmedTitle)}?${queryParams}`;
    }

    static getPackageListingUrl({
        title, pageUrlName, railId, packageId,
    }) {
        const pageTitle = pageUrlName || DEFAULT_NAV.HOME;
        const trimmedTitle = trimCaptionsForUrl(title);

        if (railId) {
            return `/${pageTitle}/${trimmedTitle}/list/${railId}`;
        }
        if (packageId) {
            return `/${pageTitle}/${trimmedTitle}/list/${packageId}?type=${LISTING_PAGE_ID_TYPES.PACKAGE}`;
        }

        return null;
    }

    static getPackageListingUrlV2({
        title, railId, packageId,
        titleL2,
        preferredArtwork,
    }) {
        const trimmedTitle = trimCaptionsForUrl(titleL2 || title);

        if (railId && !titleL2) {
            return `/${trimmedTitle}/list/${railId}`;
        }
        if (packageId) {
            return `/${trimmedTitle}/list/${packageId}?${preferredArtwork ? `pa=${preferredArtwork}` : ''}`;
        }

        return null;
    }

    static getSettingsURL({ settingPage }) {
        if (settingPage) {
            return `/settings/${settingPage}`;
        }
        return '/settings';
    }

    static getHomePage() {
        return '/';
    }

    static getBlogHomePage() {
        return '/blog';
    }

    static getXmpClaim() {
        return '/xmp-claim';
    }

    static getSearchPage(viewType = 'default') {
        return `/search?viewType=${viewType}`;
    }

    static getChannelPage() {
        return '/channel';
    }

    static getSubscriptionPage() {
        return ROUTE_PATHS?.SUBSCRIPTION_PAGE || '';
    }

    static getSubscriptionRouteSubscriptionPage() {
        return ROUTE_PATHS?.SUBSCRIPTION_PAGE_WEBVIEW || '';
    }

    static getSingleClaimSuccessPage() {
        return ROUTE_PATHS.SINGLE_CLAIM_SUCCESS_PAGE;
    }

    static getMultipleClaimSuccessPage() {
        return ROUTE_PATHS.MULTIPLE_CLAIM_SUCCESS_PAGE;
    }

    static getSubscriptionSuccessPage() {
        return ROUTE_PATHS.SUBSCRIPTION_SUCCESS;
    }

    static getOneHubSuccessPage() {
        const env = AppEnv.getEnv();
        const subscriptionWindowLocation = getWindowLocation();
        const appId = LocationUtil.getKeyFromURL(subscriptionWindowLocation?.search, 'appId') || browserStore.get(LOCAL_STORE_KEYS.APP_ID) || env.appId;
        if (appId === 'MOBILITY') {
            return '/subscriptions/subscribe/plans/payment/success';
        }
        return ROUTE_PATHS.ONEHUB_PAYMENT_STATUS.replace(':status?', 'success');
    }

    static getOneHubFailurePage() {
        const env = AppEnv.getEnv();
        const subscriptionWindowLocation = getWindowLocation();
        const appId = LocationUtil.getKeyFromURL(subscriptionWindowLocation?.search, 'appId') || browserStore.get(LOCAL_STORE_KEYS.APP_ID) || env.appId;
        if (appId === 'MOBILITY') {
            return '/subscriptions/subscribe/plans/payment/failure';
        }
        return ROUTE_PATHS.ONEHUB_PAYMENT_STATUS.replace(':status?', 'failure');
    }


    static getSubscriptionFailurePage() {
        return ROUTE_PATHS.SUBSCRIPTION_FAILURE;
    }

    static getUserPlanPage() {
        return ROUTE_PATHS.MY_PLANS;
    }

    static getAirtelThanksUrl() {
        return 'https://www.airtel.in/thanks';
    }

    static getPlansOffersPage({
        cpId = '', contentId = '', viewType = 'default', redirectToContent = false, id = '', source_tab = '', source_button = '', isTV = false,
    }) {
        return `${isTV ? ROUTE_PATHS?.SUBSCRIPTION_PAGE : '/plans/offers'}?viewType=${viewType}&&${cpId ? LocationUtil.objectToQueryParams({
            cpId,
            id,
            redirectToContent,
            contentId,
            source_tab,
            source_button,
        }) : LocationUtil.objectToQueryParams({
            source_tab,
            source_button,
        })}`;
    }

    static getCpSubscriptionPage({ cpId = '', viewType = 'default' }) {
        return `/plans/subscription?viewType=${viewType}&&${LocationUtil.objectToQueryParams({ cpId })}`;
    }

    static getPlansPaymentsPage({
        planId = '', redirectToContent = false, planCpId: cpId = '', source_button = '', source_tab = '', qrCodeQueryParams = {},
    }) {
        return `/plans/payments?viewType=webview&&${LocationUtil.objectToQueryParams({
            redirectToContent,
            planId,
            cpId,
            source_button,
            source_tab,
            ...qrCodeQueryParams,
        })}`;
    }

    static getCustomLayoutUrlWithViewType({ urlName, viewType = 'default' }) {
        const nav = urlName === DEFAULT_NAV.HOME ? '' : urlName;
        const navViewType = (viewType === 'default' || viewType === '') ? null : viewType;
        return `/${nav}${navViewType ? `?viewType=${navViewType}` : ''}`;
    }

    static getCustomLayoutUrl({ urlName }) {
        const nav = urlName === DEFAULT_NAV.HOME ? '' : urlName;
        return `/${nav}`;
    }

    static getTvEpisodePlaybackUrl({
        programType, title, episodeNumber, episodeName, seasonName, contentId, cpId = '',
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[programType] || programType;

        const pathParams = [
            // trimCaptionsForUrl('playback'),
            trimCaptionsForUrl(pageTitle),
            trimCaptionsForUrl(title),
            trimCaptionsForUrl(seasonName ? `${seasonName}` : ''),
            trimCaptionsForUrl(episodeNumber ? `episode-${episodeNumber}` : '') + (episodeName ? `-${trimCaptionsForUrl(episodeName)}` : ''),
            contentId,
            // encodeContentId(contentId),
        ];
        return `/playback/${pageTitle}/${cpId}/${title}/${contentId}?viewType=webview`;
    }

    static getDimensionPageUrlV2({
        pageTitle = 'movies',
        lang = '',
        genres = '',
        queryParams = {},
    }) {
        const trimmedPageTitle = trimCaptionsForUrl(pageTitle || '');

        const trimmedLang = lang && lang.length > 0 ? trimCaptionsForUrl(lang) : '';

        const trimmedGenres = genres && genres.length > 0 ? trimCaptionsForUrl(genres) : '';

        const pathParams = [
            trimmedPageTitle,
        ];
        let categoryArr = [];
        if (trimmedLang) {
            categoryArr.push(trimmedLang);
        }
        if (trimmedGenres) {
            categoryArr.push(trimmedGenres);
        }
        if (categoryArr.length) {
            categoryArr.push(trimmedPageTitle);
            categoryArr = categoryArr.join('-');
        }
        else {
            categoryArr.push('collection');
        }
        pathParams.push(categoryArr);
        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getSchedulePageUrl({ channelName = 'unknown', channelId }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.LIVETVSHOW;
        const trimmedTitle = trimCaptionsForUrl(channelName);
        const pathParams = [
            pageTitle,
            trimmedTitle,
            'schedule',
            channelId,
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getChannelPartnerPageUrl({ channelPartnerName = 'unknown', pageLink }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.CHANNEL_PARTNER_PAGE;
        const trimmedTitle = trimCaptionsForUrl(channelPartnerName);
        const pathParams = [
            pageTitle,
            trimmedTitle,
            pageLink,
            'detail',
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getEventsPageUrl({ eventName, eventId }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.PROMOTION_PAGE;
        const trimmedTitle = trimCaptionsForUrl(eventName);
        const pathParams = [
            pageTitle,
            trimmedTitle,
            eventId,
            'detail',
        ];
        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getPlaybackRoute(contentDetails) {
        const title = (contentDetails.title || '').replace('#', '');
        return `/playback/${contentDetails.programType}/${contentDetails.cpId}/${title} /${contentDetails.id}?viewType=webview`;
    }

    static getDeeplink({ deepLink }) {
        if (deepLink.includes('type=OTT_PURCHASE')) {
            return '/plans/offers?viewType=webview&&source_button=XPP_BANNER&&source_tab=HOME';
        }
        return null;
    }

    static getXstreamPremiumPage({ xstreamUrl = '', viewType = 'default' }) {
        return `/${xstreamUrl}?viewType=${viewType}`;
    }

    static getContentDetailsLandingPage({ cdpUrl = '', viewType = 'default' }) {
        return `/${cdpUrl}?viewType=${viewType}`;
    }

    static transformListingUrl(match) {
        const { title, id } = match.params;
        return `/${title}/list/${id}`;
    }

    static isCdpRoute(pathname, getAllRouters) {
        const matchedRoutes = matchRoutes(getAllRouters(), pathname);
        const isLinkToCdp = (matchedRoutes || []).find(r => (!!r?.route?.isCDP));
        return isLinkToCdp;
    }

    static isOnehubRoute(pathname, getAllRouters) {
        const matchedRoutes = matchRoutes(getAllRouters(), pathname);
        const isOnehub = (matchedRoutes || []).find(r => (!!r?.route?.isOnehubRoute));
        return isOnehub;
    }

    static getRouteParams(pathname, getAllRouters) {
        const matchedRoutes = matchRoutes(getAllRouters(), pathname);
        const routePresent = matchedRoutes?.length > 1 ? matchedRoutes[1] : matchedRoutes[0];
        return routePresent?.route || {};
    }

    static isWebViewRoute(pathname, getAllRouters) {
        const matchedRoutes = matchRoutes(getAllRouters(), pathname);
        const isWebView = (matchedRoutes || []).find(r => (!!r?.route?.isWebView));
        return isWebView;
    }

    static isSearchPage(pathname, getAllRouters) {
        const matchedRoutes = matchRoutes(getAllRouters(), pathname);
        const isSearchPage = (matchedRoutes || []).find(r => (!!r?.route?.isSearchPage));
        return isSearchPage;
    }

    static getSearchResultUrlTv({ title, query }) {
        const searchUrlWithViewType = this.getSearchPage('default');
        const queryParams = LocationUtil.objectToQueryParams({
            q: query,
        });
        return `${searchUrlWithViewType}&&${queryParams}`;
    }

    static getSearchResultUrlWeb({ title, query }) {
        const trimmedTitle = encodeForUrl(trimCaptionsForUrl(title));

        const queryParams = LocationUtil.objectToQueryParams({
            q: query,
        });

        return `/search/${trimmedTitle}?${queryParams}`;
    }

    static getContentPlaybackUrlWeb({
        category = 'VIDEO', contentTitle = 'unknown', contentId = '', seasonName = '', seasonId = '', seriesId = '', programType = '',
    }) {
        if (category === 'LOCAL_TILE') {
            return '';
        }
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[category] || category;

        const trimmedCategory = trimCaptionsForUrl(pageTitle);

        const trimmedTitle = trimCaptionsForUrl(contentTitle);
        let pathParams = [];
        if (programType.toLowerCase() === PROGRAM_TYPES.EPISODE.toLowerCase()) {
            pathParams = [
                PROGRAM_TYPES.EPISODE.toLowerCase(),
                trimCaptionsForUrl(contentTitle),
                trimCaptionsForUrl(seasonName),
                seriesId,
                seasonId,
                contentId,
            ];
        }
        else if (programType.toLowerCase() === PROGRAM_TYPES.SEASON.toLowerCase()) {
            pathParams = [
                PROGRAM_TYPES.SEASON.toLowerCase(),
                trimCaptionsForUrl(contentTitle),
                trimCaptionsForUrl(seasonName),
                seriesId,
                contentId,
            ];
        }
        else {
            pathParams = [
                trimmedCategory,
                trimmedTitle,
                contentId,
                // encodeContentId(contentId),
            ];
        }


        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getContentPlaybackUrlTv({
        category = 'VIDEO', contentTitle = 'unknown', contentId, sourceTab = '', addtionalQueryParams = '',
    }) {
        if (category === 'LOCAL_TILE') {
            return '';
        }
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[category] || category;

        const trimmedCategory = trimCaptionsForUrl(pageTitle);

        const trimmedTitle = trimCaptionsForUrl(contentTitle);

        const pathParams = [
            trimmedCategory,
            trimmedTitle,
            contentId,
            // encodeContentId(contentId),
        ];

        return `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview&&source_tab=${sourceTab}${addtionalQueryParams}`;
    }

    static getArtistUrlTv({ name = 'unknown', id }) {
        const trimmedName = trimCaptionsForUrl(name);
        return `/artist/${trimmedName}/${id}?viewType=webview`;
    }

    static getArtistUrl({ name = 'unknown', id, isWeb = true }) {
        const trimmedName = trimCaptionsForUrl(name);
        return `/artist/${trimmedName}/${id}${isWeb ? '' : '?viewType=webview'}`;
    }

    static getArtistUrlWeb({ name = 'unknown', id }) {
        const trimmedName = trimCaptionsForUrl(name);
        return `/artist/${trimmedName}/${id}`;
    }

    static getTvSeasonUrlTv({
        programType, title, seasonName, contentId,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[programType] || programType;

        const pathParams = [
            trimCaptionsForUrl(pageTitle || ''),
            trimCaptionsForUrl(title || ''),
            trimCaptionsForUrl(seasonName ? `${seasonName}` : ''),
            contentId,
        ];

        return `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview`;
    }

    static getTvSeasonUrlWeb({
        programType, title, seasonName, contentId,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[programType] || programType;

        const pathParams = [
            trimCaptionsForUrl(pageTitle || ''),
            trimCaptionsForUrl(title || ''),
            trimCaptionsForUrl(seasonName ? `${seasonName}` : ''),
            contentId,
        ];

        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getLiveTvShowUrl({ channelName, showName, contentId }) {
        // backend sending ProgramType : EPISODE instead of LIVETVEPISODE : with regrets, have to hard code
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.LIVETVSHOW;

        const pathParams = [
            trimCaptionsForUrl(pageTitle || ''),
            trimCaptionsForUrl(channelName || ''),
            trimCaptionsForUrl(showName ? `${showName}` : ''),
            contentId,
        ];

        return DeviceUtil.getDeviceUtil()?.isWeb() ? LocationUtil.paramArrayToUrlPath(pathParams) : `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview`;
    }

    static getLiveEpisodeShowUrl({
        channelName,
        showName,
        episodeReleaseDT,
        episodeNumber,
        contentId,
        tvShowId,
    }) {
        // backend sending ProgramType : EPISODE instead of LIVETVEPISODE : with regrets, have to hard code
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING.LIVETVSHOW;

        let episodeTitle = '';

        if (episodeReleaseDT) {
            episodeTitle = new DateTime(episodeReleaseDT).format('DD-MMMM-YYYY');
        }

        if (episodeNumber) {
            episodeTitle = `${episodeTitle}-episode-${episodeNumber}`;
        }

        const pathParams = [
            (pageTitle),
            trimCaptionsForUrl(channelName || ''),
            trimCaptionsForUrl(showName ? `${showName}` : 'default'),
            trimCaptionsForUrl(episodeTitle || ''),
            tvShowId,
            contentId,
        ];
        return DeviceUtil.getDeviceUtil()?.isWeb() ? LocationUtil.paramArrayToUrlPath(pathParams) : `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview`;
    }

    static getMovieCatchupPlaybackUrl({
        category = 'VIDEO', contentTitle = 'unknown', contentId, channelName,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[category] || category;
        const trimmedCategory = trimCaptionsForUrl(pageTitle || '');
        const trimmedTitle = trimCaptionsForUrl(contentTitle || '');
        const trimmedChannelName = trimCaptionsForUrl(channelName || '');

        const pathParams = [
            trimmedCategory,
            trimmedChannelName,
            trimmedTitle,
            contentId,
        ];


        return DeviceUtil.getDeviceUtil()?.isWeb() ? LocationUtil.paramArrayToUrlPath(pathParams) : `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview`;
    }

    static getDimensionPageUrlTv({
        pageTitle = 'movies',
        lang = '',
        genres = '',
        queryParams,
    }) {
        const trimmedPageTitle = trimCaptionsForUrl(pageTitle || '');

        const trimmedLang = lang && lang.length > 0 ? trimCaptionsForUrl(lang) : '';

        const trimmedGenres = genres && genres.length > 0 ? trimCaptionsForUrl(genres) : '';

        const pathParams = [
            trimmedPageTitle,
        ];
        if (trimmedLang) {
            pathParams.push(trimmedLang);
        }
        if (trimmedGenres) {
            pathParams.push(trimmedGenres);
        }
        pathParams.push(`collection${queryParams}`);
        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getDimensionPageUrl({
        pageTitle = 'movies',
        lang = '',
        genres = '',
        queryParams = {},
    }) {
        const trimmedPageTitle = trimCaptionsForUrl(pageTitle || '');

        const trimmedLang = lang && lang.length > 0 ? trimCaptionsForUrl(lang) : '';

        const trimmedGenres = genres && genres.length > 0 ? trimCaptionsForUrl(genres) : '';

        const pathParams = [
            trimmedPageTitle,
        ];
        if (trimmedLang) {
            pathParams.push(trimmedLang);
        }
        if (trimmedGenres) {
            pathParams.push(trimmedGenres);
        }
        pathParams.push('collection');
        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getTvEpisodeUrlWeb({
        programType, title, episodeNumber, episodeName, seasonName, contentId, seasonId, episodeId, seriesId,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[programType] || programType;
        let pathParams = [];
        if (programType.toLowerCase() === PROGRAM_TYPES.EPISODE.toLowerCase()) {
            pathParams = [
                PROGRAM_TYPES.EPISODE.toLowerCase(),
                trimCaptionsForUrl(title),
                trimCaptionsForUrl(episodeName),
                seriesId,
                seasonId,
                contentId,
            ];
        }
        else {
            pathParams = [
                trimCaptionsForUrl(pageTitle),
                trimCaptionsForUrl(title),
                trimCaptionsForUrl(seasonName ? `${seasonName}` : ''),
                trimCaptionsForUrl(episodeNumber ? `episode-${episodeNumber}` : '') + (episodeName ? `-${trimCaptionsForUrl(episodeName)}` : ''),
                contentId,
                // encodeContentId(contentId),
            ];
        }


        return LocationUtil.paramArrayToUrlPath(pathParams);
    }

    static getTvEpisodeUrlTv({
        programType, title, episodeNumber, episodeName, seasonName, contentId,
    }) {
        const pageTitle = PROGRAM_TYPE_TITLE_MAPPING[programType] || programType;

        const pathParams = [
            trimCaptionsForUrl(pageTitle),
            trimCaptionsForUrl(title),
            trimCaptionsForUrl(seasonName ? `${seasonName}` : ''),
            trimCaptionsForUrl(episodeNumber ? `episode-${episodeNumber}` : '') + (episodeName ? `-${trimCaptionsForUrl(episodeName)}` : ''),
            contentId,
            // encodeContentId(contentId),
        ];

        return `${LocationUtil.paramArrayToUrlPath(pathParams)}?viewType=webview&nextEpisode=true`;
    }

    static getDirectPlaybackRouteUrl({
        contentId, title = '',
    }) {
        const pathParams = [
            'player',
            trimCaptionsForUrl(title),
            contentId,
        ];
        const isWeb = DeviceUtil.getDeviceUtil()?.isWeb();
        const playbackUrl = LocationUtil.paramArrayToUrlPath(pathParams);
        return `${playbackUrl}${isWeb ? '' : '?viewType=webview&directPlaybackFromHome=true'}`;
    }

    static subscribe({
        plan = {},
        customerType = '',
        showNonAirtelUserActionDispatch = null,
        navigate,
        redirectToContent,
        showBrowsePlansMeta,
        plansPaymentsFetchActionDispatch,
        packGroup,
        planCpId,
        planCpTitle,
        planCpLogo,
        CUSTOMER_TYPE,
        qrCodeQueryParams = {},
    }) {
        if (customerType === CUSTOMER_TYPE.NON_AIRTEL) {
            if (showNonAirtelUserActionDispatch && typeof showNonAirtelUserActionDispatch === 'function') {
                showNonAirtelUserActionDispatch();
            }
            return;
        }

        plansPaymentsFetchActionDispatch({
            packGroup,
            planCpId,
            PriceCardInformation: plan,
            planCpTitle,
            planCpLogo,
            planId: plan?.planId,
        });
        if (navigate) {
            navigate(`${this.getPlansPaymentsPage({
                planId: plan?.planId,
                redirectToContent,
                cpId: plan?.cpId,
                source_button: showBrowsePlansMeta?.source_button || '',
                source_tab: showBrowsePlansMeta?.source_tab || '',
                qrCodeQueryParams,
            })}`);
        }
    }

    static getCPActivationPage({ cpId = '' }) {
        return (cpId ? ROUTE_PATHS.PLAN_ACTIVATION?.replace(':cpId', cpId) : RoutingUtil.getOneHubPageCDP({ ingressIntent: 'activate' }));
    }

    // static getOneHubPage({ ingressIntent, cpId = '' }) {
    //     if (!ingressIntent) {
    //         return ROUTE_PATHS.ONEHUB.replace('/:ingressIntent?', '').replace('/:cpId?', '');
    //     }
    //     const cp = cpId ? `/${cpId}` : '';
    //     return ROUTE_PATHS.ONEHUB.replace(':ingressIntent?', ingressIntent).replace('/:cpId?', cp);
    // }

    static getOneHubPageCDP({ ingressIntent, cpId, isSubscriptions }) {
        let urlPrefix = '';
        if(isSubscriptions) {
            urlPrefix = '/subscriptions';
        }
        if (!ingressIntent) {
            return urlPrefix + ROUTE_PATHS.ONEHUB.replace('/:ingressIntent?', '').replace('/:cpId?', '');
        }
        const cp = cpId ? `/${cpId}` : '';
        return urlPrefix + ROUTE_PATHS.ONEHUB.replace(':ingressIntent?', ingressIntent).replace('/:cpId?', cp);
    }

    static getMobilityChannelPartnerUrl({ channelPartnerName, pageLink}) { //pageLink ex 'PartnerChannelHoichoi'
        const trimmedTitle = trimCaptionsForUrl(channelPartnerName);
        return `wynkpremiere://Landing/page/${pageLink}/${trimmedTitle}/q`;
    }
}

export default RoutingUtil;
