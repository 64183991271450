import { AppEnv } from "@airtel-tv/utils/GetEnv";
import { AuthenticationUtil } from "@airtel-tv/utils/AuthenticationUtil";
import { CryptoUtil } from "@airtel-tv/utils/CryptoUtil";
import { removeFalsy } from "@airtel-tv/utils/GlobalUtil";
import RequestUtil from "@airtel-tv/utils/RequestUtil";
import { checkWindowExist } from '@airtel-tv/utils';

const env = AppEnv?.getEnv();
let { base, paths, serverBase } = env.endpoints.userApi;
base = checkWindowExist() ? base : serverBase;

export const xppClaimApi = ({
    deviceUtil, serviceId, userConfig, shortCode,
}) => {
    const url = `${paths.thanksClaim}?serviceId=${serviceId}`;

    const method = 'POST';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil?.getAuthToken(),
        uid: AuthenticationUtil?.getUid(),
    };
    const utkn = CryptoUtil?.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'Content-Type': 'application/json',
    };

    return RequestUtil?.POST({
        url: base + url,
        headers: removeFalsy(headers),
    });
};

export default {
    xppClaimApi,
};
