export const ANALYTICS_ACTIONS = {
    ICON_CLICK: 'icon_click',
    SEARCH_CLICK: 'search_click',
    RECENT_SEARCH: 'recent',
    SUBMIT_MOB: 'submit_mob',
    SUBMIT_RTN: 'submit_rtn',
    SUBMIT_HAPPYCODE: 'submit_happycode',
    CLICK: 'click',
    CHANNEL_CLICK: 'channel_click',
    APPLY_FILTER_CLICK: 'apply_filter_click',
    MORE_CLICK: 'more_click',
    COMMON_APP_DOWNLOAD: 'download_tool_tip',
    DOWNLOAD_APP_STORE: 'download_app_store',
    DOWNLOAD_PLAY_STORE: 'download_play_store',
    LIVE_BUTTON_CLICK: 'live_button_click',
    EXIT_FULL_SCREEN: 'exit_full_screen',
    FULL_SCREEN: 'full_screen',
    SEEK_FORWARD: 'seek_forward',
    SEEK_BACKWARD: 'seek_backward',
    TILE_CLICK: 'tile_click',
    ADD_TO_WATCHLIST_CLICK: 'add_to_watchlist',
    BUY_PLAN_CLICK: 'buy_plan_click',
    RIBBON_SUBSCRIBE: 'subscribe',
    RIBBON_UPGRADE: 'upgrade',
    CLOSE: 'close',
    ACTION_RESEND: 'action_resend',
    EXIT_BUTTON_CLICK: 'exit_button_click',
    PLAYBUTTON_CLICK: 'PLAYBUTTON_CLICK',
    START_STREAMING_CLICK: 'start_streaming_click',
    SEARCH_RESULT_CONSUMED: 'search_result_consumed',
    REMOVE_FROM_WATCHLIST: 'remove_from_watchlist',
    ITEM_SEARCH: 'item_search',
    TEXT_INPUT: 'text_input',
    AUTOSUGGEST_CLICK: 'autosuggest_click',
    SCREEN_VISIBLE: 'screen_visible',
    BLACK_HOLE: 'black_hole',
    RAIL_CTA_CLICK: 'rail_cta_click',
    TAB_CLICK: 'tab_click',
    SHARE_CLICK: 'share click',
    BANNER_CLICK: 'banner_click',
    BANNER_CTA_CLICK: 'banner_cta_click',
    BANNER_TILE_CLICK: 'banner_tile_click',
    SEARCH_KEYBOARD_INPUT: 'search_keyboard_input',
    SKIP_LOGIN_CLICKED: 'skip_login_clicked',
    GET_SMS_LINK: 'Get SMS Link',
    LOGIN_WITH_ANOTHER_NO: '’Login With Another Number',
    BACK_BUTTON_CLICK: 'back_button_click',
    GO_BACK: 'Go back',
    ADD_TO_BILL: 'add_to_bill',
    SEE_MORE_ACCOUNTS: 'see_more_options',
    GO_BACK_CLICK: 'go_back_click',
    BUTTON_CLICK: 'button_click',
    NUMBER_LOGIN: 'number_login',
    QR_LOGIN: 'qr_login',
    VERIFY_OTP: 'verify',
    RESEND_VIA_SMS: 'resend_via_sms',
    RESEND_VIA_CALL: 'resend_via_call',
    VERIFY: 'verify',
    FAIL: 'fail',
    GO_HOME: 'go_home',
    XPP_CLAIM_ATB_CONFIRM_POPUP: 'XPP_CLAIM_ATB_CONFIRM_POPUP',
    XPP_CLAIM_ATB_CONFIRM_POPUP_LS: 'XPP_CLAIM_ATB_CONFIRM_POPUP_LS',
    XPP_CLAIM_POPUP_ACTIVATED: 'XPP_CLAIM_POPUP_ACTIVATED',
    XPP_CLAIM_POPUP_PENDING: 'XPP_CLAIM_POPUP_PENDING',
    LATER: 'later',
    RECHARGE_NOW: 'recharge_now',
    LIVE_SHOW_CLICK: 'live_show_click',
    SEE_OTHER_PLANS: 'see_other_plans',
    SCAN_ACTIVATION_QR: 'scan',
    STOP_AUTO_RENEW: 'stop_auto_renew_click'
};

export const PLAY_STATUS_FIRE_TIME = 30;

export const ANALYTICS_ASSETS = {
    MORE_MENU_ICON: 'more_menu_icon',
    LANGUAGE_MENU_ICON: 'language_menu_icon',
    SEARCH_POPUP: 'search_popup',
    SEARCH_INPUT: 'search_input',
    AUTOSUGGEST: 'autosuggest',
    SEARCH_KEYPAD: 'search_keypad',
    AUTOSUGGEST_CLICK: 'autosuggest_click',
    SEARCH_TAB: 'search_tab',
    SEARCH_ICON: 'search_icon',
    MOBILE_INPUT_SOURCE_NAME: 'enter_mob',
    ENTER_OTP: 'enter_otp',
    FAQ: 'faq',
    PRIVACY_POLICY: 'privacy_policy',
    TERMS_OF_USE: 'terms_of_use',
    PREFERRED_PARTNER_POPUP: 'preferred_pc_popup',
    YOU_TAB: 'you_tab',
    UNLOCK: 'unlock',
    CLAIM_NOW: 'claim_now',
    CLAIM_YOU: 'claim_you',
    BACK: 'back',
    NOT_NOW: 'not_now',
    CONTINUE: 'continue',
    SUBSCRIBE: 'subscribe',
    I_WILL_DO_IT_LATER: 'i_will_do_it_later',
    START_WATCHING: 'start_watching',
    UNLCOK_MORE_OFFERS: 'unlock_other_offers',
    GO_TO_HOMEPAGE: 'go_to_homepage',
    PREFERRED_PARTNER_OFFERS_PAGE: 'ppc_offers_page',

    PREFERRED_PARTNER_CHANNEL_SELECTION_PAGE: 'channel_selection_page',
    PREFERRED_PARTNER_GRATIFICATION_SCREEN: 'ppc_gratification_screen',
    PREFERRED_PARTNER_CONFIRMATION_POPUP: 'ppc_confirmation_popup',
    PREFERRED_PARTNER_REWARDS_POPUP: 'ppc_rewards_popup',
    BROWSE_PLANS_PARTNER_CHANNELS: 'browse_plans_partner_channels',
    MY_PLANS: 'my_plans',
    CONTINUE_TO_PAYMENT: 'continue_to_payment',

    XSTREAM_PREMIUM_LANDING_PAGE: 'xstream_premium_landing_page',
    XSTREAM_PREMIUM_MARKETING_PAGE: 'xstream_premium_marketing_page',
    SUBSCRIBE_NOW: 'subscribe_now',
    SUBSCRIBE_NOW_LOGIN: 'subscribe_now_login',
    EXPLORE_NOW: 'explore_now',
    WEB: 'WEB',
    MID_ROLL: 'MID_ROLL',
    PRE_ROLL: 'PRE_ROLL',
    LIVE: 'LIVE',
    NULL: 'null',
    PARTNER_CHANNEL: 'Partner Channel',
    SUBSCRIBED_PACKS: 'Subscribed Packs',
    SUBSCRIPTION_DETAILS_PAGE: 'subscription_details_page',
    AIRTELTV: 'airteltv',

    ACTIVE_PLANS: 'my_plans_screen',
    NO_SUBSCRIPTION_CONTAINER: 'no_subscription_page',
    PLANS_AND_OFFERS: 'plans_and_offers',
    MY_PLANS_PAGE: 'my_plans_page',
    EXPLORE_PLANS_PAGE: 'explore_plans_page',
    ALA_CARTE_LISTING_PAGE: 'ala_carte_listing_page',
    XMP_CLAIM_PAGE: 'xmp_claim_page',
    OFFERS_LISTING_PAGE: 'offers_listing_page',
    XMP_CLAIM_SUCCESS: 'xmp_claim_success_page',
    NO_PLANS_AND_OFFERS_PAGE: 'no_plans_and_offers_page',
    SUBSCRIPTION_SUCCESS_PAGE: 'subscription_success_page',
    SUBSCRIPTION_FAILURE_PAGE: 'subscription_failure_page',
    PLAN_DETAIL_PAGE: 'plan_detail_page',
    SWITCH_TO_AIRTEL: 'switch_to_airtel',
    SUBSCRIPTION_ACTIVATED: 'subscription_activated',
    SUBSCRIPTION_FAILURE: 'subscription_failure',
    CONTENT_DETAIL_PAGE: 'content_detail_page',

    TRAILER_END_POPUP_EVENT: 'trailer_end_buy_plan',
    TRAILER_END_POPUP_CLOSED: 'trailer_end_popup_closed',
    SUBSCRIPTION_RIBBON: 'subscription_ribbon',
    OPEN_APP_DEEP_LINK: 'open_app_deeplink',
    SEE_ALL: 'see_all',
    SHARE: 'share',
    WATCHLIST: 'watchlist',
    SHARE_CLICK: 'share click',
    MORE: 'more',
    SOURCE_OF_STREAM: 'source_of_stream',
    ADD_TO_BILL: 'add_to_bill',
    SMS_LINK: 'SMS Link on Mobile',
    QR_CODE: 'scan_and_pay',
    SEE_MORE_ACCOUNTS: 'see_more_options',
    FMF_ATB_PAGE: 'FMF_ATB_PAGE',
    ADD_TO_BILL_PAGE: 'ADD_TO_BILL_PAGE',
    SUBSCRIPTION_SUCCESS_CLICK: 'subscription_success_click',
    CTA_CONTINUE: 'cta_continue',
    TV_LOGIN_SUCCESSFUL: 'tv_login_successfull',
    RESEND_OTP: 'resend_otp',
    ENTER_TV_CODE: 'enter_tv_code',
    RECHARGE_PACK_EXPIRY_POPUP: 'recharge_pack_expiry_popup',
    CONTENT_NAME_CLICK: 'content_name_click',
    SEE_OTHER_PLANS: 'See Other Plans',
    ONEHUB: 'onehub',
    ALREADY_HAVE_SUBSCRIPTION: 'already_have_subscription',
    SCAN_ACTIVATION_QR: 'scan',
};

export const PLAYER_ANALYTICS_ACTIONS = {
    PLAY_INIT_ACTION: 'play_init',
    PLAY_ACTION: 'play',
    PAUSE_ACTION: 'pause',
};

export const PIP_ANALYTICS_ACTIONS = {
    PLAY: 'pip_in',
    PIP_OUT: 'pip_out',
    PLAY_ACTION: 'pip_play',
    PAUSE_ACTION: 'pip_pause',
    IN_WEB: 'in_web',
    OUT_WEB: 'out_web',
};


export const PLAYER_CLICK_EVENTS = {
    MUTE: 'mute',
    UNMUTE: 'unmute',
    PLAYBACK_SETTINGS: 'player_playback_settings',
    FULL_SCREEN: 'fullscreen',
    HALF_SCREEN: 'halfscreen',
    NEXT_EPISODE: 'next_episode',
};

export const SOS_EVENTS = {
    [ANALYTICS_ACTIONS.ADD_TO_WATCHLIST_CLICK]: 'add_to_watchlist',
    [ANALYTICS_ACTIONS.SEARCH_RESULT_CONSUMED]: 'search_result_consumed',
    [ANALYTICS_ACTIONS.REMOVE_FROM_WATCHLIST]: 'remove_from_watchlist',
    [ANALYTICS_ACTIONS.ITEM_SEARCH]: 'item_search',
    [ANALYTICS_ACTIONS.AUTOSUGGEST_CLICK]: 'autosuggest_click',
    [ANALYTICS_ACTIONS.SCREEN_VISIBLE]: 'screen_visible',
    [ANALYTICS_ACTIONS.BLACK_HOLE]: 'black_hole',
    [ANALYTICS_ACTIONS.CLICK]: 'click',
};

// export const SOS_EVENTS_MAP = {
//     [ANALYTICS_ACTIONS.SCREEN_VISIBLE]: 'SV',
// };

export const SOS_SESSION_KEYS = {
    SOS_DICT: 'SOS_DICT',
    SOS_STRING: 'SOS_STRING',
    SOS_COUNTER: 'SOS_COUNTER',
};

export const DEFAULT_NONE = 'None';
export default {
    ANALYTICS_ACTIONS,
    ANALYTICS_ASSETS,
    PLAYER_ANALYTICS_ACTIONS,
    PLAY_STATUS_FIRE_TIME,
    PLAYER_CLICK_EVENTS,
    SOS_EVENTS,
    SOS_SESSION_KEYS,
};
