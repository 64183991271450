import React, { useState } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import { RoutingUtil } from '@airtel-tv/utils';
import DesktopNavigationComponent from './components/top-navigation/DesktopNavigationComponent';
import BottomNavigationComponent from './components/bottom-navigation/BottomNavigationComponent';
import { hidePreferredPartnerTooltip } from '../notify/NotifyActions';
import styles from './NavigationContainer.scss';
import getAllRouters from '../../routes/routes';

function NavigationContainer(props) {
    const {
        menuItems,
        megaMenuItems,
        selectedFeaturedNavigation,
        selectedBottomNavigation,
        // headerRouteData,
        // history,
        showPreferredPartnerTooltip,
        hidePreferredPartnerTooltipActionDispatch,
        tooltipText,
        shouldShowTooltip,
        isAuthenticated,
        location,
        route,
        deviceUtil,
    } = props;

    const [
        preferredPartnerToolipTimeoutId,
        setPreferredPartnerToolipTimeoutId,
    ] = useState(null);

    const closePreferredPartnerTooltip = () => {
        if (preferredPartnerToolipTimeoutId) {
            clearTimeout(preferredPartnerToolipTimeoutId);
        }
        setPreferredPartnerToolipTimeoutId(null);
        hidePreferredPartnerTooltipActionDispatch({
            showPreferredPartnerTooltip: false,
        });
    };

    const showPreferredPartnerTooltipForSeconds = (durationInMilliseconds) => {
        const pptooltipId = setTimeout(() => {
            closePreferredPartnerTooltip();
        }, durationInMilliseconds);
        setPreferredPartnerToolipTimeoutId(pptooltipId);
    };
    const isCdpRoute = RoutingUtil.isCdpRoute(location.pathname, getAllRouters);
    const isMobile = deviceUtil.isMobile();
    const isScopedWebview = deviceUtil.isScopedWebview();
    return (
        <>
            {!isScopedWebview ? <DesktopNavigationComponent
                isAuthenticated={isAuthenticated}
                menuItems={menuItems}
                selectedFeaturedNavigation={selectedFeaturedNavigation}
                location={location}
                route={route}
                selectedBottomNavigation={selectedBottomNavigation}
                megaMenuItems={megaMenuItems}
            /> : null}
            {/* {!(deviceUtil.isMobile() && isCdpRoute) && (
                <DesktopNavigationComponent
                    isAuthenticated={isAuthenticated}
                    menuItems={menuItems}
                    selectedFeaturedNavigation={selectedFeaturedNavigation}
                    location={location}
                    route={route}
                    selectedBottomNavigation={selectedBottomNavigation}
                    megaMenuItems={megaMenuItems}
                />
            )} */}
            {/* <MobileHeaderComponent
                isAuthenticated={isAuthenticated}
                headerRouteData={headerRouteData}
                history={history}
                route={route}
                isAppHookVisible={appHookVisiblity}
                changeAppHookVisibility={changeAppHookVisibility}
            /> */}
            {isMobile ? (
                <BottomNavigationComponent
                    location={location}
                    showPreferredPartnerTooltip={showPreferredPartnerTooltip}
                    showPreferredPartnerTooltipForSeconds={showPreferredPartnerTooltipForSeconds}
                    closePreferredPartnerTooltip={closePreferredPartnerTooltip}
                    tooltipText={tooltipText}
                    shouldShowTooltip={shouldShowTooltip}
                    route={route}
                    selectedBottomNavigation={selectedBottomNavigation}
                />
            ) : null}
        </>
    );
}

const mapStateToProps = (state) => {
    const { appConfig, notify, authConfig } = state;
    const { showPreferredPartnerTooltip = null } = notify;
    // const { should_show_tooltip: shouldShowTooltip = null, tooltip_text: tooltipText = null } = appConfig.ppc_meta_configs;
    const shouldShowTooltip = lodashGet(appConfig, 'ppc_meta_configs.should_show_tooltip', null);
    const tooltipText = lodashGet(appConfig, 'ppc_meta_configs.tooltip_text', null);
    return {
        isAuthenticated: authConfig.isAuthenticated,
        menuItems: appConfig.pages,
        megaMenuItems: appConfig.megaMenuList,
        selectedFeaturedNavigation: notify.selectedFeaturedNavigation,
        selectedBottomNavigation: notify.selectedNav,
        headerRouteData: notify.headerRouteData,
        showPreferredPartnerTooltip,
        tooltipText,
        shouldShowTooltip,
    };
};

NavigationContainer.defaultProps = {
    selectedFeaturedNavigation: '',
    // headerRouteData: {},
    location: '',
    selectedBottomNavigation: '',
    showPreferredPartnerTooltip: null,
    tooltipText: null,
    shouldShowTooltip: null,
};

NavigationContainer.propTypes = {
    route: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    isAuthenticated: PropTypes.bool.isRequired,
    menuItems: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    selectedFeaturedNavigation: PropTypes.string,
    selectedBottomNavigation: PropTypes.string,
    // headerRouteData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    // history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    megaMenuItems: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showPreferredPartnerTooltip: PropTypes.bool,
    hidePreferredPartnerTooltipActionDispatch: PropTypes.func.isRequired,
    tooltipText: PropTypes.string,
    shouldShowTooltip: PropTypes.bool,
    deviceUtil: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default withDeviceUtil(connect(mapStateToProps, {
    hidePreferredPartnerTooltipActionDispatch: hidePreferredPartnerTooltip,
})(withStyles(styles)(NavigationContainer)));
