const HappyCodeAction = {
    CHECK_RTN: 'CHECK_RTN',
    ADD_DTH_CUSTOMER_ID: 'ADD_DTH_CUSTOMER_ID',
    REGISTERED_TELEPHONE_NUMBER_ERROR: 'REGISTERED_TELEPHONE_NUMBER_ERROR',
    HAPPY_CODE_VERIFICATION_ERROR: 'HAPPY_CODE_VERIFICATION_ERROR',
    CHECK_HAPPY_CODE: 'CHECK_HAPPY_CODE',
    HAPPY_CODE_VERIFICATION_SUCCESS_ACTION: 'HAPPY_CODE_VERIFICATION_SUCCESS_ACTION',
};

export default HappyCodeAction;

export const checkRtnAction = payload => ({
    type: HappyCodeAction.CHECK_RTN,
    customerRtn: payload,
});

export const addDthCustomerIdAction = dthCustomerId => ({
    type: HappyCodeAction.ADD_DTH_CUSTOMER_ID,
    dthCustomerId,
});

export const checkHappyCodeAction = payload => ({
    type: HappyCodeAction.CHECK_HAPPY_CODE,
    payload,
});

export const happyCodeVerificationSuccessAction = happyCodeVerificationSuccess => ({
    type: HappyCodeAction.HAPPY_CODE_VERIFICATION_SUCCESS_ACTION,
    happyCodeVerificationSuccess,
});

export const registeredTelephoneNumberErrorAction = error => ({
    type: HappyCodeAction.REGISTERED_TELEPHONE_NUMBER_ERROR,
    error,
});

export const happyCodeVerificationErrorAction = error => ({
    type: HappyCodeAction.HAPPY_CODE_VERIFICATION_ERROR,
    error,
});
