import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';

function PrivateRouteContainer(props) {
    const { component: TargetComponent, isAuthenticated, ...rest } = props;
    if (isAuthenticated) {
        return (
            <TargetComponent {...rest} />
        );
    }
    return <Navigate to={ROUTE_PATHS.ROOT} />;
}

PrivateRouteContainer.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { authConfig } = state;
    const prop = {
        isAuthenticated: authConfig.isAuthenticated,
    };
    return prop;
};


export default connect(mapStateToProps)(PrivateRouteContainer);
