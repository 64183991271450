import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';

const IMAGE_SETTINGS = {
    1000: 600,
    300: 200
};
const PartnerImages = (props) => {
    const {
        xstreamPartnerImages = [],
        premiumPartnerImages = [],
        xstreamPlayOTTLabel,
        isLargeScreen = false,
        remainingOTTLabel = '',
        showDivider = false,
    } = props;
    const [
        showPrev,
        setShowPrev,
    ] = useState(false);
    const [
        showNext,
        setShowNext,
    ] = useState(false);
    const partnersElementRef = useRef();
    const imagesRef = useRef();
    const { clientWidth = 0 } = partnersElementRef?.current || {};
    const { clientWidth: imageContainerWidth = 0 } = imagesRef?.current || {};
    //const showPrev = scrollLeft > 0;
    //const showNext = xstreamPartnerImages?.length ? imageContainerWidth >= clientWidth && scrollLeft < (scrollWidth - clientWidth) : false;
    //console.log("kkk", showNext,xstreamPartnerImages?.length, imageContainerWidth,clientWidth, scrollLeft,  scrollWidth, scrollWidth - clientWidth, scrollRight, showNext);
    const [
        showScrollButtons,
        setShowScrollButtons,
    ] = useState(0);

    const scrollLeftSide = () => {
        if (partnersElementRef?.current) {
            const scrollBy = partnersElementRef.current.clientWidth;
            partnersElementRef.current.scrollLeft -= scrollBy;
            setShowScrollButtons(prev => prev + 1);
        }
    };

    const scrollRightSide = () => {
        if (partnersElementRef?.current) {
            const scrollBy = partnersElementRef.current.clientWidth;
            partnersElementRef.current.scrollLeft += scrollBy;
            setShowScrollButtons(prev => prev + 1);
        }
    };

    const onScroll = () => {
        const { scrollLeft = 0, clientWidth: scrollerWidth = 0, scrollWidth = 0 } = partnersElementRef?.current || {};
        const { clientWidth: imgContWidth = 0 }= imagesRef?.current || {};
        if (scrollLeft > 0 && !showPrev) {
            setShowPrev(true);
        }
        else if (showPrev && scrollLeft <= 0) {
            setShowPrev(false);
        }

        const sn = xstreamPartnerImages?.length ? imgContWidth >= scrollerWidth && scrollLeft < (scrollWidth - scrollerWidth) : false;
        if (sn && !showNext) {
            setShowNext(true);
        }
        else if (showNext && !sn) {
            setShowNext(false);
        }
    };

    useEffect(() => {
        onScroll();
    }, []);

    return (
        <div className="plan-partners">
            <div className="one-hub-scroller">

                {!isLargeScreen ? (
                    <>
                        {premiumPartnerImages?.length ? (
                            <div className="one-hub-inner-scroller additional-scrollable">
                                {premiumPartnerImages.map(partnerImg => (
                                    <ThumborImage
                                        src={partnerImg}
                                        className="partner-img"
                                        imageSettings={IMAGE_SETTINGS}
                                    />
                                ))}
                            </div>
                        ) : null}
                        {xstreamPlayOTTLabel ? (
                            <div className='play-ott-label text-16 text-bolder mobile-text-10 mobile-text-bolder mobile-line-height-16 '>{xstreamPlayOTTLabel}</div>
                        ) : null}
                        <div className='scroller-btn-wrap'>
                            {showPrev ? (
                                <AnalyticsButtonComponent
                                    className="onehub-scroller left"
                                    onClick={scrollLeftSide}
                                >
                                    <span className="chev-custom" />
                                </AnalyticsButtonComponent>
                            ) : null}
                            <div
                                className="one-hub-inner-scroller"
                                ref={partnersElementRef}
                                onScroll={() => onScroll()}
                            >
                                <div
                                    className="onehub-inner"
                                    style={{
                                        width: imageContainerWidth >= clientWidth ? '170%' : '100%',
                                    }}
                                >
                                    <div ref={imagesRef} className='d-flex'>
                                        {xstreamPartnerImages.map(partnerImg => (
                                            <ThumborImage
                                                src={partnerImg}
                                                className="partner-img"
                                                imageSettings={IMAGE_SETTINGS}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {' '}
                            {showNext ? (
                                <AnalyticsButtonComponent
                                    className="onehub-scroller right"
                                    onClick={scrollRightSide}
                                >
                                    <span className="chev-custom" />
                                </AnalyticsButtonComponent>
                            ) : null}
                        </div>
                    </>
                )

                    : (
                        <>
                            {premiumPartnerImages?.length ? (
                                <div className="one-hub-inner-scroller additional-mb">
                                    {premiumPartnerImages.map(partnerImg => (
                                        <ThumborImage
                                            src={partnerImg}
                                            className="partner-img"
                                            imageSettings={IMAGE_SETTINGS}
                                        />
                                    ))}
                                </div>
                            ) : null}
                            {xstreamPlayOTTLabel ? (
                                <div className='play-ott-lable text-ls-20 text-ls-bolder line-height-ls-30'>{xstreamPlayOTTLabel}</div>
                            ) : null}
                            {xstreamPartnerImages?.length ? (
                                <div className="one-hub-inner-scroller">
                                    {/* <div> */}
                                        {xstreamPartnerImages.map(partnerImg => (
                                            <ThumborImage
                                                src={partnerImg}
                                                className="partner-img"
                                                imageSettings={IMAGE_SETTINGS}
                                            />
                                        ))}
                                    {/* </div> */}
                                    {remainingOTTLabel ? (
                                        <div className="partner-img text text-ls-bolder text-ls-24 line-height-ls-31">
                                            {remainingOTTLabel}
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </>
                    )
                }
            </div>

            <div className={`web-only-inner-divider ${showDivider ? 'show-divider' : ''}`} />
        </div>
    );
};

PartnerImages.propTypes = {
    xstreamPartnerImages: PropTypes.arrayOf(PropTypes.string).isRequired,
    premiumPartnerImages: PropTypes.arrayOf(PropTypes.string).isRequired,
    xstreamPlayOTTLabel: PropTypes.string.isRequired,
    isLargeScreen: PropTypes.bool.isRequired,
    remainingOTTLabel: PropTypes.string.isRequired,
    showDivider: PropTypes.bool.isRequired,
};

export default PartnerImages;
