import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AnalyticsButtonComponent, AnalyticsLinkComponent, screenVisibleEvent } from '@airtel-tv/analytics';
import {
    ANALYTICS_ACTIONS,
    ELEMENT_ID, IMAGE_PATHS, MODAL_POPUP, ONEHUB_CTA_TYPES, ONEHUB_INGRESS_INTENT, TILE_TYPES,
} from '@airtel-tv/constants';
import {
    LanguageProviderUtil, RoutingUtil, withDeviceUtil,
} from '@airtel-tv/utils';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { showModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { FORMAT_TYPE } from '@airtel-tv/constants/DateTimeConst';
import { getDate } from '@airtel-tv/utils/GlobalUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import { setLayoutDimensionMap } from '@airtel-feature/layout/actions/LayoutActions';
import useOnehubPlanDetailsPopup from '@airtel-tv/lib/hooks/useOnehubPlanDetailsPopup';
import styles from './ChannelPartnerDetailTile.scss';
import RailEventHOC from '../../../../web/src/modules/shared/hoc/RailEventHOC';

const IMAGE_SETTINGS = {
    1920: 1920,
    1500: 1600,
    1000: 1000,
    300: 400,
};


const ChannelPartnerDetailTile = (props) => {
    const {
        isSubscribed,
        cpMetaData,
        validity,
        activePlan,
        showModalComponentActionDispatch,
        search,
        pageId,
        railContainerClass = 'banner_wrapper',
        railIndex,
        onFocus = null,
        railPosition = 0,
        tilePosition = 0,
        setLayoutDimensionMapDispatch,
        isLargeScreen,
        cpId,
        deviceUtil: { isMobile, isTabletViewPort },
        navigate,
        history,
        cpDetailsById,
        keyDownCallBack,
        onKeyDown,
        onKeyUp,
        freeCP,
        freePartners,
        // isPendingActivation,
        claimedCps,
        claimInProgressCps,
        pendingActivationCps,
        allPartners,
        navigationObj: { prevSource = '' } = {},
        tileType,
        railId,
        currentSource,
        railType,
    } = props;
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const {
        CP_DETAIL_MORE_CTA = 'More Details',
        CP_DETAIL_SUBSCRIPTION_CTA = 'See Subscription Plans',
        CP_DETAIL_VALID_TILL_LABEL = 'Valid till ${date}',
        CP_DETAIL_GET_CP_TITLE = 'Get {title} ',
        CP_DETAIL_GET_CP_SUBS = 'Subscription to Watch',
        ACTIVATE_CP_TITLE = 'Claim {title} ',
        ACTIVATE_CP_TO_WATCH = 'to Start Watching',
        ACTIVATE_AND_WATCH_CTA = 'Claim & Watch',
        CP_DETAIL_ACTIVATING_CTA = 'Claim in Progress',
        VALIDITY_PLACEHOLDER = 'Valid {validityPlaceholder}',
        CP_PLAN_DETAIL_POPUP_OTT_COUNT = '{OTTCount} OTT{s}',
        CP_PLAN_DETAIL_POPUP_VALIDITY_TEXT = 'Validity as per your plan',
        CP_DETAIL_POPUP_FREE_VALIDITY_TEXT = 'Subscribed for Free',
    } = LANGUAGE;
    const isMobileDevice = isMobile();
    const containerRef = useRef();
    const { showPlanDetailsPopup } = useOnehubPlanDetailsPopup();

    const {
        title = '', cpIconURL: logoUrl, tagLine: subTitle, bannerImageUrl = '', channelCoverImage = {}, coverImage = {},
    } = cpMetaData;

    let ctaText = CP_DETAIL_SUBSCRIPTION_CTA;
    let ctaAction = ONEHUB_CTA_TYPES.REDIRECT_TO_ONEHUB;
    let titleText = [
        CP_DETAIL_GET_CP_TITLE?.replace('{title}', title),
        CP_DETAIL_GET_CP_SUBS,
    ];
    let validityText = '';
    let className = '';
    let showSubtitle = false;
    if (claimedCps?.length || freeCP) {
        ctaText = CP_DETAIL_MORE_CTA;
        ctaAction = ONEHUB_CTA_TYPES.MORE_DETAILS;
        titleText = [
            title,
        ];
        // className = 'border-button';
        if (!freeCP) {
            if (claimedCps[0].validityPlaceholder) {
                // let placeholder = claimedCps[0].validityPlaceholder;
                // placeholder = placeholder ? placeholder.charAt(0).toLowerCase() + placeholder.slice(1) : placeholder;
                validityText = claimedCps[0].validityPlaceholder;
            }
            else if (claimedCps[0]?.validTill) {
                const cpValidityDateFormat = getDate(new Date(claimedCps[0].validTill), FORMAT_TYPE.DATE_FULL_MONTH_YEAR);
                validityText = CP_DETAIL_VALID_TILL_LABEL.replace('${date}', cpValidityDateFormat);
            }
        }
        else {
            showSubtitle = true;
        }
    }
    else if (claimInProgressCps?.length) {
        ctaText = CP_DETAIL_ACTIVATING_CTA;
        ctaAction = '';
        titleText = [
            title,
        ];
        className = 'border-button disable';
    }
    else if (pendingActivationCps?.length) {
        ctaText = ACTIVATE_AND_WATCH_CTA;
        ctaAction = ONEHUB_CTA_TYPES.REDIRECT_TO_ONEHUB;
        titleText = [
            ACTIVATE_CP_TITLE?.replace('{title}', title),
            ACTIVATE_CP_TO_WATCH,
        ];
    }
    // const activePlanValidityDateFormat = getDate(new Date(activePlan?.validTill), FORMAT_TYPE.DATE_WITH_ORDINAL_FORMAT);
    // const ctaText = isSubscribed ? (isPendingActivation ? ACTIVATE_AND_WATCH_CTA : CP_DETAIL_MORE_CTA) : CP_DETAIL_SUBSCRIPTION_CTA;

    const {
        mobile = '',
        largescreen = '',
        gradientColor = '#373737',
    } = channelCoverImage;
    const {
        SQUARE = '',
    } = coverImage;
    const {
        FOCUSABLE_NO_STYLE, BY_PASS_SCROLL, SCROLL_TO_TOP, UNFOCUSABLE,
    } = FocusManagementClassNames;
    const tileId = `${pageId}-tile-${railIndex}-${0}`;
    // const deepLink = '';
    const classes = `pos-relative ${ELEMENT_ID.CAN_SCROLL_LG} component-bottom-margin railContainer banner-rail-container ${tileId} ${SCROLL_TO_TOP} ${railContainerClass} ${FOCUSABLE_NO_STYLE} ${BY_PASS_SCROLL}`;
    const backgroundChannelImage = isMobileDevice ? mobile : largescreen;// || 'https://image.airtel.tv/grandslam/content/2024_10_4/Hotstar_(1).png';
    const cpLogoImage = SQUARE;
    // const titleText = isSubscribed ? (isPendingActivation ?  ACTIVATE_CP_TO_WATCH.replace('{title}', title) : title) : CP_DETAIL_TITLE_LABEL.replace('{title}', title);
    // const validityText = isSubscribed && !isPendingActivation && validity && !freeCP ? CP_DETAIL_VALID_TILL_LABEL.replace('${date}', cpValidityDateFormat) : '';

    const analyticsMeta = {
        cp_name: cpId,
        source_page: prevSource,
        page_id: pageId,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        source_page: prevSource,
        rail_type: railType,
        tileId,
    };

    useEffect(() => {
        if (isLargeScreen) {
            setLayoutDimensionMapDispatch({
                pageId,
                railPosition: railIndex,
                dimensions: {
                    lastFocusedTilePosition: 0,
                    focusSelectorleft: 0,
                    height: 0,
                    scrollTop: 0,
                    nonQuickViewRail: true,
                    focusOnSelection: true,
                    subtitleHeight: null,
                },
            });
        }
    }, []);

    const onCtaClick = (e) => {
        if (ctaAction === ONEHUB_CTA_TYPES.MORE_DETAILS) {
            e.preventDefault();
            // const planValidity = activePlan?.validityPlaceholder || CP_PLAN_DETAIL_POPUP_VALIDITY_TEXT;
            const partners = freeCP ? freePartners.filter(cp => cp?.cpId === cpId) : allPartners.map(item => item.partner);
            const planBenefits = partners?.length ? CP_PLAN_DETAIL_POPUP_OTT_COUNT.replace('{OTTCount}', partners?.length).replace('{s}', partners.length > 1 ? 's' : '') : '';
            showPlanDetailsPopup({
                partners: partners || [],
                preferredPartner: cpId,
                planBenefits: freeCP ? '' : planBenefits,
                cpDetailsById,
                planDescription: freeCP ? CP_DETAIL_POPUP_FREE_VALIDITY_TEXT : CP_PLAN_DETAIL_POPUP_VALIDITY_TEXT,
                meta: {
                    ...analyticsMeta,
                    action: ANALYTICS_ACTIONS.BUTTON_CLICK,
                    asset_name: ctaText,
                },
            });
            // showPlanDetailsPopup({
            //     partners: partners || [],
            //     preferredPartner: cpId,
            //     planDuration: freeCP ? '' : activePlan?.duration,
            //     planTitle: freeCP ? '' : activePlan?.title,
            //     planPrice: freeCP ? '' : (activePlan?.price || ''),
            //     cpDetailsById,
            //     planDescription: freeCP ? 'Subscribed for Free' : planValidity,
            // });
        }
        else if (ctaAction === ONEHUB_CTA_TYPES.REDIRECT_TO_ONEHUB) {
            let path;
            if (pendingActivationCps.length > 1) {
                path = RoutingUtil.getOneHubPageCDP({
                    ingressIntent: ONEHUB_INGRESS_INTENT.ACTIVATE,
                    cpId,
                });
            }
            else if (pendingActivationCps.length === 1) {
                path = RoutingUtil.getCPActivationPage({ cpId });
            }
            else {
                path = RoutingUtil.getOneHubPageCDP({
                    ingressIntent: ONEHUB_INGRESS_INTENT.CP,
                    cpId,
                });
            }
            navigate(path);
        }
    };

    return (
        <div
            // id={tileId}
            className={`channel-description-container description-gradient-channel ${classes}`}
            railPosition={0}
            tilePosition={0}
            // to={!isSubscribed ? RoutingUtil.getOneHubPageCDP({ ingressIntent: ONEHUB_INGRESS_INTENT.CP, cpId }) : '#'}
            meta={{}}
            ref={containerRef}
            onKeyDown={(e) => {
                if (typeof keyDownCallBack === 'function') {
                    keyDownCallBack({ event: e });
                }
            }}
            onFocus={(e) => {
                if (typeof onFocus === 'function') {
                    onFocus({
                        e,
                        railPosition,
                        tilePosition,
                        tileDetails: { nonQuickViewTile: true },
                        railOffsetTop: containerRef?.current?.offsetTop || 0,
                    });
                }
            }}
            // onKeyDown={(event) => {
            //     if (typeof onKeyDown === 'function') {
            //         onKeyDown({
            //             event,
            //             tilePosition,
            //         });
            //     }
            // }}
            onKeyUp={onKeyUp}
            // onClick={isLargeScreen ? onCtaClick}
        >
            {backgroundChannelImage ? (
                <ThumborImage
                    className="full-banner-img"
                    src={backgroundChannelImage || ''}
                    imageSettings={IMAGE_SETTINGS}
                />
            ) : (
                <div
                    className="fallback-gradient"
                    style={{ backgroundImage: `radial-gradient(${gradientColor}, transparent)` }}
                />
            )}
            <div className="description">
                <div className="description-holder">
                    <div className="tags-wrapper">
                        {freeCP ? (
                            <ThumborImage
                                className="cp-free-tag"
                                src={IMAGE_PATHS.FREE_TAG}
                                imageSettings={IMAGE_SETTINGS}
                            />
                        ) : null}
                        <ThumborImage
                            src={cpLogoImage || ''}
                            imageSettings={IMAGE_SETTINGS}
                            className="cp-logo"
                        />
                    </div>

                    <div className="cp-title text-24 text-bolder line-height-32 mobile-text-16 mobile-line-height-20 text-ls-28 text-ls-bolder">
                        {titleText?.map((title, idx) => (
                            <>
                                {title}
                                {idx !== titleText?.length - 1 && !isMobileDevice ? <br /> : null}
                            </>
                        ))}
                    </div>
                    <ul className="cp-list">
                        {validityText ? <li className="cp-list-item text-16 text-normal line-height-24 text-normal mobile-text-14 mobile-line-height-16 text-ls-22">{validityText}</li> : null}
                        {subTitle && showSubtitle ? <li className="cp-list-item text-16 line-height-32 text-normal mobile-text-14 mobile-line-height-16 text-ls-22">{subTitle}</li> : null}
                    </ul>
                    <AnalyticsButtonComponent
                        id={tileId}
                        onKeyDown={(e) => {
                            if (typeof keyDownCallBack === 'function') {
                                keyDownCallBack({ event: e });
                            }
                        }}
                        onFocus={(e) => {
                            if (typeof onFocus === 'function') {
                                onFocus({
                                    e,
                                    railPosition,
                                    tilePosition,
                                    tileDetails: { nonQuickViewTile: true },
                                    railOffsetTop: containerRef?.current?.offsetTop || 0,
                                });
                            }
                        }}
                        // onKeyDown={(event) => {
                        //     if (typeof onKeyDown === 'function') {
                        //         onKeyDown({
                        //             event,
                        //             tilePosition,
                        //         });
                        //     }
                        // }}
                        onKeyUp={onKeyUp}
                        onClick={onCtaClick}
                        className={`cp-action-btn text-16 text-normal mobile-text-14 mobile-text-semi-bold text-ls-24 ${UNFOCUSABLE} ${className}`}
                        meta={{
                            ...analyticsMeta,
                            action: ANALYTICS_ACTIONS.BUTTON_CLICK,
                            asset_name: ctaText,
                        }}
                    >
                        {ctaText}
                    </AnalyticsButtonComponent>
                </div>
            </div>
        </div>
    );
};

ChannelPartnerDetailTile.defaultProps = {
    railContainerClass: '',
    railIndex: 0,
};

ChannelPartnerDetailTile.propTypes = {
    isSubscribed: PropTypes.bool.isRequired,
    search: PropTypes.string.isRequired,
    cpMetaData: PropTypes.object.isRequired,
    validity: PropTypes.number.isRequired,
    activePlan: PropTypes.object.isRequired,
    showModalComponentActionDispatch: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    setLayoutDimensionMapDispatch: PropTypes.func.isRequired,
    railContainerClass: PropTypes.string,
    pageId: PropTypes.string.isRequired,
    railIndex: PropTypes.number,
    railPosition: PropTypes.number.isRequired,
    tilePosition: PropTypes.number.isRequired,
};

const mapStateToProps = (state, props) => {
    const {
        authConfig: { isAuthenticated },
        appConfig: { serverEpoch },
        plansAndSubscriptionData: {
            svodPlans: {
                all_plans: allPlans = {},
            } = {},
        } = {},
        userConfig: {
            partnerStatus: {
                // pendingActivationCps = [],
                // claimedCps = [],
            } = {},
        },
        location: {
            navigationObj = {},
        } = {},
    } = state;
    const { planClaimStatusClient = {} } = allPlans;
    const {
        pageId = '', location: { search = '' }, zionDisplay: { cpId = '' } = {}, cpDetailsById, subscriptionDataById,
    } = props;

    const subscriptionData = subscriptionDataById[cpId] || {};
    const validity = subscriptionData?.expiry;

    const cpMetaData = cpDetailsById[cpId.toUpperCase()] || {};
    const freeCP = !!cpMetaData?.freeCP;
    const activePlans = allPlans.activePlans?.filter(item => !item.upcoming) || [];
    const freePartners = allPlans?.freePartners || [];
    const activePlan = activePlans?.[0] || {};

    const claimedCps = planClaimStatusClient?.CLAIMED?.filter(item => (item?.partner?.cpId || '').toUpperCase() === cpId.toUpperCase()) || [];
    const claimInProgressCps = planClaimStatusClient?.CLAIM_IN_PROGRESS?.filter(item => (item?.partner?.cpId || '').toUpperCase() === cpId.toUpperCase()) || [];
    const pendingActivationCps = planClaimStatusClient?.PENDING_ACTIVATION?.filter(item => (item?.partner?.cpId || '').toUpperCase() === cpId.toUpperCase()) || [];
    const allPartners = planClaimStatusClient?.ALL_PARTNERS || [];
    const isSubscribed = false;

    // if in claimed || free cp ---- more details
    // else if in claim in progress --- disable state ( activating text )
    // else if pending activation --- activate & watch
    // else seee subscription plans

    // const pendingCp = planClaimStatusClient.PENDING_ACTIVATION.filter(item => item.cpId === cpId);


    // const isSubscribed = freeCP || (validity > (serverEpoch || new Date().getTime()) && activePlans?.length);
    // const isClaimed = claimedCps.filter(cp => cp === cpId)?.length;
    // const isPendingActivation = !isClaimed && pendingActivationCps.filter(cp => cp === cpId)?.length;

    return {
        isSubscribed,
        cpId,
        cpMetaData,
        validity,
        activePlan,
        search,
        pageId,
        cpDetailsById,
        freeCP,
        freePartners,
        // isPendingActivation,
        claimedCps,
        claimInProgressCps,
        pendingActivationCps,
        allPartners,
        navigationObj,
    };
};
export default withRouter(withDeviceUtil(connect(mapStateToProps, {
    showModalComponentActionDispatch: showModalComponentAction,
    setLayoutDimensionMapDispatch: setLayoutDimensionMap,
})(withStyles(styles)(RailEventHOC(ChannelPartnerDetailTile)))));
