import { AppEnv } from '@airtel-tv/utils/GetEnv';
import { getUuid, getTimeStamp } from '@airtel-tv/utils/GlobalUtil';
import CrashUtil from '@airtel-tv/utils/CrashUtil';
import { checkWindowExist } from '@airtel-tv/utils';
import CrashQueue from './CrashQueue';

const env = AppEnv.getEnv();
let crashQTimer = null;

const onQueueFull = (crashes) => {
    const { crashApi } = env.endpoints;
    const request = {
        data: {
            crashes,
            id: getUuid(),
            ts: getTimeStamp(),
        },
        url: crashApi.base,
    };
    CrashUtil.POST(request);
};

const clearCrashQTimer = () => {
    clearTimeout(crashQTimer);
    crashQTimer = null;
};

const flushQueueOnTimeout = () => {
    const isQEmpty = CrashQueue.flush(onQueueFull);

    // clearing previous timer
    clearCrashQTimer();

    if (isQEmpty) {
        crashQTimer = setTimeout(flushQueueOnTimeout, env.crashQueueTimeout * 1000);
    }
};
setTimeout(flushQueueOnTimeout, env.crashQueueTimeout * 1000);

const CrashScheduler = {
    submit: (crash, isCritical) => {
        if (!checkWindowExist()) {
            return;
        }
        if (isCritical) {
            CrashQueue.addAndFlush(crash, onQueueFull);
        }
        else {
            CrashQueue.add(crash, onQueueFull);
        }
    },
};

export default CrashScheduler;
