import RequestUtil from '@airtel-tv/utils/RequestUtil';
import env from '../../src/config';

const getBlogsFromServer = async (postId) => {
    const { databaseURL, apiPath } = env.endpoints.blogApi;
    const url = `${databaseURL}${apiPath.posts}/${postId || ''}`;
    return await RequestUtil.GET({
        url
    });
};

export default getBlogsFromServer;
