import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { ANALYTICS_CATEGORY } from '@airtel-tv/constants/GlobalConst';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import { getAppId, removeFalsy } from '@airtel-tv/utils/GlobalUtil';
import ApiAnalytics from '@airtel-feature/playback/helpers/ApiAnalytics';

import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';

import LanguageProvider from '../../providers/LanguageProvider';
import env from '../../config';

let { base, paths, serverBase } = env.endpoints.userApi;
base = checkWindowExist() ? base : serverBase;

export const generateOtpCall = (msisdn, token, uid, appId, xAtvDid, viaCall = false) => {
    const LANGUAGE = LanguageProvider();

    const method = 'POST';
    const body = JSON.stringify({
        msisdn,
        msgTxt: LANGUAGE.OTP_SMS_TEXT,
    });
    const queryParams = {
        appId,
    };
    const urlPathName = `${viaCall ? paths.generateOtpOncall : paths.generateOtp}?${LocationUtil.objectToQueryParams(queryParams)}`;

    const utknData = {
        urlBody: `${method}/${urlPathName}${body}`,
        token,
        uid,
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': xAtvDid,
        'Content-Type': 'application/json',
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        method,
        url: `${base}${urlPathName}`,
        data: body,
        headers: removeFalsy(headers),
    };
    return RequestUtil.POST(authOptions);
};
export const validateOtpCall = (args = {}) => {
    const {
        msisdn, otp, token, uid, appId, xAtvDid, isMultiDeviceLogin, qrLoginPayload = {},
    } = args;
    const method = 'POST';
    const body = JSON.stringify({
        msisdn,
        otp,
        ...qrLoginPayload,
    });
    const queryParams = {
        appId,
    };
    const endpoint = isMultiDeviceLogin ? paths.multiDevicelogin : paths.login;
    const utknData = {
        urlBody: `${method}/${endpoint}?${LocationUtil.objectToQueryParams(queryParams)}${body}`,
        token,
        uid,
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': xAtvDid,
        'Content-Type': 'application/json',
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        method,
        url: `${base}${endpoint}?${LocationUtil.objectToQueryParams(queryParams)}`,
        data: body,
        headers: removeFalsy(headers),
    };
    return RequestUtil.POST(authOptions);
};

export const validateDeleteAccountOtpCall = (username, otp, token, uid, appId, xAtvDid) => {
    const method = 'POST';
    const body = JSON.stringify({
        username,
        otp,
    });
    const queryParams = {
        appId,
    };

    const utknData = {
        urlBody: `${method}/v2/user/profile/verifyAccount?${LocationUtil.objectToQueryParams(queryParams)}${body}`,
        token,
        uid,
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': xAtvDid,
        'Content-Type': 'application/json',
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        method,
        url: `${base}v2/user/profile/verifyAccount?${LocationUtil.objectToQueryParams(queryParams)}`,
        data: body,
        headers: removeFalsy(headers),
    };
    return RequestUtil.POST(authOptions);
};

export const deleteAccountCall = (token, sessionToken, activeUid, appId, xAtvDid) => {
    const method = 'DELETE';
    const queryParams = {
        appId,
    };
    const utknData = {
        urlBody: `${method}/v2/user/profile/deleteAccount?${LocationUtil.objectToQueryParams(queryParams)}`,
        token,
        uid: activeUid,
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        sessionToken,
        'x-atv-did': xAtvDid,
        'Content-Type': 'application/json',
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        method,
        url: `${base}v2/user/profile/deleteAccount?${LocationUtil.objectToQueryParams(queryParams)}`,
        // data: body,
        headers: removeFalsy(headers),
    };
    return RequestUtil.DELETE(authOptions);
};

export const getTempLoginToken = (deviceUtilInstance, appId) => {
    const queryParams = {
        appId,
    };
    const method = 'POST';
    const url = `${paths.login}?${LocationUtil.objectToQueryParams(queryParams)}&caching=true`; // temp param added to cache it for some minutes :: identifier for temp call


    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': deviceUtilInstance.getXAtvDid(),
        'x-atv-utkn': utkn,
        'Content-Type': 'application/json',
    };
    const authOptions = {
        method,
        url: base + url,
        data: JSON.stringify({ requireOtp: 'false' }),
        headers: removeFalsy(headers),
    };

    return RequestUtil.POST(authOptions);
};


export const refreshToken = ({
    uid, token, accessToken, deviceUtil, contentId = '',
}) => {
    const method = 'POST';
    const queryParams = {
        appId: getAppId(),
    };
    const body = JSON.stringify({
        token: accessToken,
    });

    const url = `${paths.refreshToken}?${LocationUtil.objectToQueryParams(queryParams)}`;

    const utknData = {
        urlBody: `${method}/${url}${body}`,
        token,
        uid,
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const atvDid = deviceUtil.getXAtvDid();

    const headers = {
        'x-atv-utkn': utkn,
        'x-atv-did': atvDid,
        'content-type': 'application/json',
        'request-init-time': true,
    };

    let apiAnalytics;
    if (contentId) {
        apiAnalytics = new ApiAnalytics({ contentId }, {
            url: base + url,
            category: ANALYTICS_CATEGORY.REFRESH_TOKEN,
        });
        apiAnalytics.fireApiInit();
    }
    return RequestUtil.POST({
        url: base + url,
        headers: removeFalsy(headers),
        data: body,
    }).then((data) => {
        if (contentId) {
            apiAnalytics.setResponseTime(data.api_response_time);
            apiAnalytics.fireApiResponse();
        }
        return data;
    }).catch((e) => {
        console.error(e);
        const error = e.data ? e.data : e;
        const { headers: errReqHeaders, request } = e || {};
        if (contentId) {
            e.api_response_time && apiAnalytics.setResponseTime(data.api_response_time);
            apiAnalytics.fireApiResponse(error, errReqHeaders, request);
        }
        throw e;
    });
};


export const disposeAuthSession = ({
    uid, token, deviceUtil, deviceId,
}) => {
    const method = 'DELETE';

    const queryParams = {
        appId: getAppId(),
        deviceId: deviceId || deviceUtil.getDid(),
        uid,
    };

    const utknData = {
        urlBody: `${method}/${paths.logout}?${LocationUtil.objectToQueryParams(queryParams)}`,
        token,
        uid,
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const atvDid = deviceUtil.getXAtvDid();
    const url = `${base}${paths.logout}?${LocationUtil.objectToQueryParams(queryParams)}`;

    const headers = {
        'x-atv-utkn': utkn,
        'x-atv-did': atvDid,
        'content-type': 'application/json',
    };
    return RequestUtil.DELETE({
        url,
        headers: removeFalsy(headers),
    });
};

export const dthRechargeRedirection = ({
    uid, token, deviceUtil, key,
}) => {
    const method = 'GET';
    const params = {
        key,
        appId: getAppId(),
    };
    const utknData = {
        urlBody: `${method}/${paths.dthRecharge}?${LocationUtil.objectToQueryParams(params)}`,
        token,
        uid,
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const atvDid = deviceUtil.getXAtvDid();
    const url = `${base}${paths.dthRecharge}?${LocationUtil.objectToQueryParams(params)}`;

    const headers = {
        'x-atv-utkn': utkn,
        'x-atv-did': atvDid,
    };

    return RequestUtil.GET({
        url,
        headers: removeFalsy(headers),
    });
};

export const happyCodeGeneration = ({
    uid, token, deviceUtil,
}) => {
    const method = 'GET';
    const params = {
        appId: getAppId(),
    };
    const utknData = {
        urlBody: `${method}/${paths.generateHappyCode}?${LocationUtil.objectToQueryParams(params)}`,
        token,
        uid,
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const atvDid = deviceUtil.getXAtvDid();
    const url = `${base}${paths.generateHappyCode}?${LocationUtil.objectToQueryParams(params)}`;

    const headers = {
        'x-atv-utkn': utkn,
        'x-atv-did': atvDid,
    };

    return RequestUtil.GET({
        url,
        headers: removeFalsy(headers),
    });
};


export async function validateSSOToken({
    xAtvDid, ssoToken, utmSource, token, uid,
    appId,
}) {
    const method = 'GET';
    const queryParams = {
        appId,
        cache: false,
        utm_source: utmSource.toUpperCase(),
    };
    const url = `${paths.validateSSO}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const utknData = {
        urlBody: `${method}/${url}`,
        token,
        uid,
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': xAtvDid, // TODO: uncomment after anurag check
        'x-atv-platform': 'WEBOS',
        'x-atv-utkn': utkn,
        'sso-token': ssoToken,
    };
    const authOptions = {
        method,
        url: base + url,
        headers: removeFalsy(headers),
    };
    return RequestUtil.GET(authOptions);
}


export const autoLogin = (args = {}) => {
    const {
        appId, uid, token, xAtvDid, ssoToken = '',
        utmSource,
    } = args;
    const method = 'POST';
    const body = JSON.stringify({
        thanksSsoToken: ssoToken,
        utmSource,
    });
    const queryParams = {
        appId,
    };
    const endpoint = paths.autoLogin;
    const utknData = {
        urlBody: `${method}/${endpoint}?${LocationUtil.objectToQueryParams(queryParams)}${body}`,
        token,
        uid,
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': xAtvDid,
        'Content-Type': 'application/json',
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        method,
        url: `${base}${endpoint}?${LocationUtil.objectToQueryParams(queryParams)}`,
        data: body,
        headers: removeFalsy(headers),
    };
    return RequestUtil.POST(authOptions);
};
