import lodashGet from 'lodash/get';
import React, { Fragment } from 'react';
import { isObjEmpty } from '@airtel-tv/utils/GlobalUtil';
import { ELEMENT_ID, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import {
    ANALYTICS_ACTIONS, ANALYTICS_ASSETS, EVENT_SOURCE_NAME, RAIL_TYPES, TILE_TYPES,
} from '@airtel-tv/constants';
import { buildRail } from '../builders/RailBuilder';

export default class LayoutUtil {
    static getRailElement({
        paginatedData,
        themeConfig,
        channels,
        contentForLayoutPackages,
        subscriptionDataById,
        cpDetailsById,
        numberOfRails,
        pageId,
        webPageId = {},
        onFocus,
        tilesToshow,
        railLength,
        scrollRail,
        railFactory,
        scrollBelowQuickView,
        existingRailsLength = 0,
        keyUpCallBack,
        keyDownCallBack,
        layoutInnerWidth,
        focusNextRailTile,
        ...rest
    }) {
        if (isObjEmpty(paginatedData)) {
            return null;
        }
        const railToBeRendered = paginatedData.slice(existingRailsLength, paginatedData.length);
        const rails = railToBeRendered.map((layout = {}, index) => {
            const railIndex = existingRailsLength + index;
            const {
                contents: layoutContents = [],
                railPropsInitial = null,
            } = layout;
            const layoutContent = layoutContents.length > 0 ? layoutContents[0] : {};
            const { packageId, details: packageDetailsFromRail } = layoutContent;
            const packageDetails = packageId && contentForLayoutPackages && Object.keys(contentForLayoutPackages).length ? contentForLayoutPackages[packageId] : packageDetailsFromRail;
            let slicedContent = [];
            if (packageDetails && Object.keys(packageDetails).length > 0) {
                slicedContent = lodashGet(packageDetails, 'content', []);
            }
            else {
                slicedContent = packageDetails;
            }
            const totalContentCount = (packageDetails && packageDetails.totalContentCount) || (slicedContent && slicedContent.length) || 0;
            let sosAnalyticsMeta = {};
            if (rest?.isSearchDriven) {
                sosAnalyticsMeta = {
                    source_name: EVENT_SOURCE_NAME.SEARCH_PAGE,
                    action: ANALYTICS_ACTIONS.SEARCH_RESULT_CONSUMED,
                    asset_name: ANALYTICS_ASSETS.AUTOSUGGEST,
                    rail_title: layout?.zionDisplay?.title || '',
                };
            }
            const railProps = railPropsInitial || buildRail({
                layoutDetails: layout,
                items: slicedContent,
                railIndex,
                totalCount: totalContentCount,
                totalContentCount,
                channels,
                themeConfig,
                numberOfRails,
                cpDetailsById,
                subscriptionDataById,
                pageId,
                webPageId,
                sourceTab: 'LAYOUT_PAGE',
                sosAnalyticsMeta,
                isSearchEvent: rest?.isSearchDriven || false,
                ...rest,
            });
            const args = {
                railProps: {
                    ...railProps,
                    onFocus,
                    scrollRail,
                    tilesToshow,
                    railLength,
                    scrollBelowQuickView,
                    railIndex,
                    pageId,
                    webPageId,
                    sourceTab: 'LAYOUT_PAGE',
                    channels,
                    themeConfig,
                    numberOfRails,
                    cpDetailsById,
                    subscriptionDataById,
                    keyUpCallBack,
                    keyDownCallBack,
                    layoutInnerWidth,
                    focusNextRailTile,
                    ...rest,
                },
            };
            return <Fragment key={layout.id}>{railFactory(args)}</Fragment>;
        });

        const filteredRails = rails.filter(arr => arr !== null);
        return filteredRails.length < 1 ? null : filteredRails;
    }
}


export const getWatchListComputedTiles = ({
    watchList,
    cpDetailsById,
    subscriptionDataById,
    buildTile,
    RAIL_TYPES,
    RAIL_CTA_LIST,
    LOCAL_TILE_TYPES,
    preferredArtwork,
    showCatalogTag = false,
}) => {
    const railType = RAIL_TYPES.WATCHLIST_RAIL;
    const tileCtaType = RAIL_CTA_LIST.LISTING;
    let tiles = null;
    let content = [];
    const { length } = Object.keys(watchList);
    content = Object.keys(watchList).map((watchListContentId) => {
        const { contentResponse, lastUpdatedTimeStamp } = watchList[watchListContentId];
        return {
            ...contentResponse,
            lastUpdatedTimeStamp,
        };
    });
    content.sort((a, b) => (b.lastUpdatedTimeStamp - a.lastUpdatedTimeStamp));
    tiles = content.map(item => buildTile({
        item,
        tileCtaType,
        railType,
        tileType: LOCAL_TILE_TYPES.WATCHLIST,
        length,
        cpDetailsById,
        subscriptionDataById,
        sourceTab: 'WatchList_Rail',
        preferredArtwork,
        showCatalogTag,
    }));
    return tiles;
};

export const getCWComputedTiles = ({
    continueWatchingData,
    continueWatchingRail,
    cpDetailsById,
    subscriptionDataById,
    buildTile,
    RAIL_TYPES,
    RAIL_CTA_LIST,
    LOCAL_TILE_TYPES,
    preferredArtwork,
}) => {
    const railType = RAIL_TYPES.CONTINUE_WATCHING_RAIL;
    const tileCtaType = RAIL_CTA_LIST.LISTING;
    let tiles = null;
    let content = [];
    const { length } = Object.keys(continueWatchingRail);
    content = Object.keys(continueWatchingRail).map((continueWatchingContentId) => {
        const contentResponse = lodashGet(continueWatchingData, `${continueWatchingContentId}.contentResponse`, null);
        const lastUpdatedTimeStamp = lodashGet(continueWatchingData, `${continueWatchingContentId}.lastUpdatedTimeStamp`, null);

        // const { contentResponse, lastUpdatedTimeStamp } = continueWatchingData[continueWatchingContentId];
        return {
            ...contentResponse,
            lastUpdatedTimeStamp,
        };
    });
    content.sort((a, b) => (b.lastUpdatedTimeStamp - a.lastUpdatedTimeStamp));

    content = content.filter(item => item.programType && item.id && !item?.shouldHideInCW && item.programType !== PROGRAM_TYPES.TRAILER);

    tiles = content.map(item => buildTile({
        item,
        tileCtaType,
        railType,
        tileType: LOCAL_TILE_TYPES.CONTINUE_WATCHING_TILE,
        length,
        continueWatchingData,
        showPlayback: true,
        cpDetailsById,
        subscriptionDataById,
        sourceTab: 'Continue_Watching_Rail',
        preferredArtwork,
    }));
    return tiles;
};

export const getPywWatchListComputedTiles = ({
    watchList,
    cpDetailsById,
    subscriptionDataById,
    buildTile,
    RAIL_TYPES,
    RAIL_CTA_LIST,
    LOCAL_TILE_TYPES,
    preferredArtwork,
    railElementId,
    isWatchListTile,
}) => {
    const railType = RAIL_TYPES.X_PYW_RAIL;
    const tileCtaType = RAIL_CTA_LIST.LISTING;
    let tiles = null;
    let content = [];
    const { length } = Object.keys(watchList);
    content = Object.keys(watchList).filter(watchListContentId => watchList[watchListContentId].isFromPYW).map((watchListContentId) => {
        const { contentResponse, lastUpdatedTimeStamp } = watchList[watchListContentId];
        return {
            ...contentResponse,
            lastUpdatedTimeStamp,
        };
    });
    content.sort((a, b) => (b.lastUpdatedTimeStamp - a.lastUpdatedTimeStamp));
    tiles = content.map(item => buildTile({
        item,
        tileCtaType,
        railType,
        tileType: LOCAL_TILE_TYPES.X_PYW_RAIL,
        length,
        cpDetailsById,
        subscriptionDataById,
        sourceTab: 'PYW_WatchList_Rail',
        preferredArtwork,
        railElementId,
        isWatchListTile,
    }));
    return tiles;
};
export const getDistroFreeChannelTiles = ({
    cpId = 'DISTROTV',
    railType,
    distroData,
    buildTile,
    preferredArtwork = 'LOGO_HD',
    cpDetailsById,
    analyticsPageId,
}) => {
    let tiles = null;
    let content = [];
    const { length } = Object.keys(distroData);
    if (length) {
        const filteredContent = distroData.filter(item => item?.id.includes(cpId));
        content = filteredContent.map(item => ({
            ...item,
            // images: item?.channelImages,
            // title: item?.channelName,
            // id: item?.channelId,
            cpId,
        }));
        tiles = content.map(item => buildTile({
            item,
            railType,
            tileType: TILE_TYPES.X_FREE_CHANNELS,
            length,
            showPlayback: true,
            sourceTab: 'Layout_Tab',
            preferredArtwork,
            cpDetailsById,
            analyticsPageId,
        }));
    }
    return tiles;
};

export const getSubscriptionBasedTiles = ({
    allPlans = [],
    buildTile,
    planType,
    railProps,
    railType,
    tileType,
    msisdnNo,
    primaryActionHandler,
    secondaryActionHandler,
    preferredCp,
    cpDetailsById,
    ...rest
}) => {
    let content = allPlans;
    if ((railType === RAIL_TYPES.X_PLAN_COMING_SOON && preferredCp)) {
        content = [
            {},
        ];
    }
    if (!content || !content?.length) {
        return [];
    }
    let tiles = null;
    const { length } = Object.keys(content);
    content = content.filter(plan => !plan?.notVisible);
    tiles = content.map(plan => buildTile({
        planDetails: {
            ...plan,
        },
        length,
        planType,
        railProps,
        railType,
        tileType,
        primaryActionHandler,
        secondaryActionHandler,
        msisdnNo,
        cpDetailsById,
        ...rest,
    }));
    return tiles;
};


// let tiles = null;
// // let content = [];
//  // const { length } = Object.keys(subscriptionData);
//  // content = Object.keys(subscriptionData).map(plan => ({
//  //     ...plan,
//  // }));
//  // console.log("ffff", subscriptionData);
//  debugger;
//  tiles = subscriptionData.map(item => buildTile({
//      item,
//      planData: subscriptionData,
//      tileType,
//      railType,
