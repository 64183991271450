// eslint-disable-next-line no-unused-vars
import React from 'react';

const TileLoader = React.forwardRef((props, ref) => (
    <div className="rail-loader">
        <div
            className="inside-rail-loader"
            id="loader"
            {...props}
            ref={ref}
        />
    </div>
    // <div class="lds-ellipsis" id="loader" {...props} ref={ref}><div></div><div></div><div></div><div></div></div>
));

export default TileLoader;
