import HappyCodeAction from '../actions/HappyCodeActions';

const initialState = JSON.parse(`{
    "registeredTelephoneNumberVerification": false,
    "happyCodeVerificationCompleted": null,
    "dthCustomerId": "",
    "error": null
}`);

const HappyCodeReducer = (state = { initialState }, action) => {
    let changes = {};

    switch (action.type) {
        case HappyCodeAction.ADD_DTH_CUSTOMER_ID:
            changes = {
                dthCustomerId: action.dthCustomerId,
                registeredTelephoneNumberVerification: true,
                error: null,
            };
            break;

        case HappyCodeAction.REGISTERED_TELEPHONE_NUMBER_ERROR:

            changes = {
                error: action.error,
            };
            break;

        case HappyCodeAction.HAPPY_CODE_VERIFICATION_ERROR:
            changes = {
                happyCodeVerificationCompleted: false,
                error: action.error,
            };
            break;

        case HappyCodeAction.CHECK_RTN:
            changes = {
                error: null,
                happyCodeVerificationCompleted: false,
            };
            break;

        case HappyCodeAction.CHECK_HAPPY_CODE:
            changes = {
                error: null,
                happyCodeVerificationCompleted: null,
            };
            break;

        case HappyCodeAction.HAPPY_CODE_VERIFICATION_SUCCESS_ACTION:
            changes = {
                happyCodeVerificationCompleted: action.happyCodeVerificationSuccess,
                error: null,
            };
            break;

        default:
            return state;
    }
    return {
        ...state,
        ...changes,
    };
};

export default HappyCodeReducer;
