export const FocusManagementClassNames = {
    ONLYFOCUSABLE: 'onlyFocusable',
    FOCUSABLE: 'focusable',
    CAN_FOCUS: 'can-focus',
    CAN_FOCUS_ONLY: 'can-focus-only',
    EPISODE_FOCUSABLE: 'episode-focusable',
    SEASON_EPISODE_ITEM_FOCUSABLE: 'season-episode-item-focusable',
    FOCUSABLE_NO_STYLE: 'focusable-secondary',
    SIDEBAR_FOCUSABLE: 'sidebar-focusable',
    UNFOCUSABLE: 'unfocusable',
    SCROLLCONTAINER: 'scrollcontainer',
    ACTIVE_SIDEBAR: '.nav-link.active',
    SCROLL_TO_TOP: 'scrollToTop',
    NON_QUICK_VIEW: 'nonQuickview',
    RAIL_CONTAINER_CLASS: 'railContainer',
    BY_PASS_SCROLL: 'byPassScroll',
    RAIL_SCROLL_ID: 'railScrollContainer-{railIndex}',
    RAIL_ID: 'railPosition-{railIndex}',
    RAILPOSITION_ATTRIBUTE: 'railPosition',
    TILEPOSITION_ATTRIBUTE: 'tileposition',
    NO_TARGET: '#noTarget',
};


export const FocusManagementKeyAction = {
    NO_TARGET: '#noTarget',
};

export default {
    FocusManagementClassNames,
    FocusManagementKeyAction,
};
