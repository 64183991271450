import { ChatbotActions } from './ChatbotAction';

const initialState = JSON.parse('{}');

const ChatbotReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case ChatbotActions.FETCH_CHATBOT_SUCCESS:
            changes = {
                data: action.payload,
                isSuccess: true,
            };
            break;
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default ChatbotReducer;
