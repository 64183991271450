
// import { LAYOUT_SOURCE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import env from '../../config';
import { getAppId, removeFalsy } from '@airtel-tv/utils/GlobalUtil';
import {AuthenticationUtil} from '@airtel-tv/utils/AuthenticationUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';

export async function getPageLayout({
    pageId, userContentProperties = {}, deviceUtil,
}) {
    const { base, paths } = env.endpoints.layoutApi;

    const params = {
        appId: getAppId(),
        pageId,
        layoutExperimentId: 0,
        ...userContentProperties,
    };
    const url = `${base}${paths.getLayout}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        // data_source: LAYOUT_SOURCE_TYPES.BOTH, // aman.gupta asked to removed this to resolve CORS issue
    };
    return RequestUtil.GET({
        url,
        params,
        headers: removeFalsy(headers),
    });
}

export const getPaginatedPageLayout = ({
    pageId, userContentProperties = {}, deviceUtil, offset = 0, count = 10, token, uid, cpId, contentId, userLocation = {}, paramsFromUrl = {},
}) => {
    const { base, paths } = env.endpoints.packageApi;
    const { pageId: paramsPageId = '' } = paramsFromUrl || {};
    const params = {
        appId: getAppId(),
        cpId,
        count,
        offset,
        contentId,
        layoutExperimentId: 1,
        ...userContentProperties,
        ...userLocation,
        ...paramsFromUrl,
        pageId: paramsPageId || pageId,
    };

    const queryParamsString = LocationUtil.objectToQueryParams(params);
    const url = `${base}${paths.getPaginatedLayout}?${queryParamsString}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${paths.getPaginatedLayout}?${queryParamsString}`,
        token,
        uid,
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'request-init-time': true,
        // data_source: LAYOUT_SOURCE_TYPES.BOTH, // aman.gupta asked to removed this to resolve CORS issue
    };
    return RequestUtil.GET({
        url,
        headers: removeFalsy(headers),
    });
};

export const getRailDetails = ({
    railIds = [], deviceUtil, userContentProperties = {},
}) => {
    const { base, paths } = env.endpoints.layoutApi;

    const params = {
        railIds: railIds.join(','),
        appId: getAppId(),
        layoutExperimentId: 0,
        ...userContentProperties,
    };

    const url = `${base}${paths.getRailMeta}`;

    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url,
        params,
        headers: removeFalsy(headers),
    });
};

export default {
    getPageLayout,
    // getAllPageLayout,
    getRailDetails,
};
