import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { lowerCase } from 'lodash';
import { LanguageProviderUtil } from '@airtel-tv/utils';
import { IMAGE_PATHS } from '@airtel-tv/constants';
import { DeviceTypeUtil } from '@airtel-tv/utils/DeviceTypeUtil';
import { ThumborImage } from '../../atoms';
import LockSVGComponent from '../../svg/LockSVGComponent';

const LockOverlayComponent = (props) => {
    const {
        onBtnClick, contentDetails, showLockIconWebLargescreen,
    } = props;
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    if (DeviceTypeUtil.isWeb()) {
        <div className="locked-content-overlay">
            <LockSVGComponent />
            <div className="overlay-text">
                <button
                    className="transparent-btn sub-btn hover-underline"
                    onClick={(e) => {
                        onBtnClick(e, contentDetails);
                    }}
                    type="button"
                >
                    <span>{LANGUAGE.SUBSCRIBE_TO_WATCH}</span>
                    <ThumborImage
                        className="vertical-center subscribe-arrow"
                        src={IMAGE_PATHS.TILE_LINK_ARROW}
                    />
                </button>
            </div>

        </div>;
    }
    return (
        <div className="locked-content-overlay">
            {lowerCase(showLockIconWebLargescreen) === 'true' ? (
                <>
                    <LockSVGComponent />
                    <div className="overlay-text" />
                </>
            ) : null}
        </div>
    );
};

LockOverlayComponent.defaultProps = {
    onBtnClick: () => {},
    contentDetails: {},
};

LockOverlayComponent.propTypes = {
    onBtnClick: PropTypes.func,
    contentDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

function mapStateToProps(state) {
    const {
        appConfig,
    } = state;

    const { showLockIconWebLargescreen = 'true' } = appConfig;
    return { showLockIconWebLargescreen };
}
const lockOverlayComponent = connect(mapStateToProps, {})(LockOverlayComponent);
export { lockOverlayComponent as LockOverlayComponent } ;
