import { ARIA_LABEL, IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';
import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { hideBannerOnPlay, hidePlayback, showBannerOnPlay } from '../../../notify/NotifyActions';
import { CONTENT_PROVIDERS } from '@airtel-tv/constants';
import { checkDirectPlaybackContainer } from '@airtel-tv/utils/GlobalUtil';

const OverlayDescription = (props) => {
    const {
        fullScreen, videoMeta, toggleFullScreen, hideTitle, isMobile, contentAutoplay = false, cpId,
    } = props;
    const { videoTitle, subTitle } = videoMeta;
    const { GO_TO_PREV_PAGE = 'Go to previous page' } = ARIA_LABEL;

    const backButtonClicked = () => {
        const {
            history, hidePlaybackDispatch, location, showPlayback, playingOnBanner, showBannerOnPlayDispatch
        } = props;
        if (showPlayback || location.search.includes('showPlayback') || cpId === CONTENT_PROVIDERS.MWTV) {
            // !isMobile && history.go(-1);
            if (!isMobile) {
                history.replace({
                    pathname: `${location?.pathname}`,
                });
            }
            hidePlaybackDispatch();
        }
        if (checkDirectPlaybackContainer()) {
            history.go(-1);
        }
        if (!playingOnBanner) {
            if (fullScreen) {
                toggleFullScreen();
            }
            showBannerOnPlayDispatch();
        }
        // }
    };

    return (
        <div
            className="vdo-player__title video-player-heading disable-select align-items-start d-flex"
            style={{ outline: 'none' }}
        >
            {/* { !(isMobile && !fullScreen) &&  */}
            <AnalyticsButtonComponent
                className="vdo-back-btn"
                type="button"
                onClick={() => backButtonClicked()}
                aria-label={GO_TO_PREV_PAGE}
            >
                <ThumborImage
                    src={IMAGE_PATHS.BACK_ARROW}
                />

            </AnalyticsButtonComponent>
            {/* } */}
            {!hideTitle && (
                <div className="d-flex vdo_heading">
                    <h2 id="atm_content-title">{videoTitle}</h2>
                    <h3>{subTitle}</h3>
                </div>
            ) }
            {/* {
                fullScreen ? (
                    <div className="d-flex vdo_heading">
                        <h2>{videoTitle}</h2>
                        <h3>{subTitle}</h3>
                    </div>
                ) : null
            } */}
        </div>
    );
};

OverlayDescription.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    toggleFullScreen: PropTypes.func.isRequired,
    videoMeta: PropTypes.shape({
        videoTitle: PropTypes.string,
        subTitle: PropTypes.string,
    }).isRequired,
    history: PropTypes.object.isRequired,
    hidePlaybackDispatch: PropTypes.func.isRequired,
    hideTitle: PropTypes.bool.isRequired,
    contentAutoplay: PropTypes.bool,
    isMobile: PropTypes.bool,
    cpId: PropTypes.string,
};

OverlayDescription.defaultProps = {
    contentAutoplay: false,
    isMobile: false,
    cpId: '',
};

function mapStateToProps(state) {
    const {
        notify: {
            showPlayback,
            playingOnBanner,
        },
    } = state;
    return {
        showPlayback,
        playingOnBanner,
    };
}

export default connect(mapStateToProps, {
    hidePlaybackDispatch: hidePlayback,
    showBannerOnPlayDispatch: showBannerOnPlay,
})(withDeviceUtil(withRouter(OverlayDescription)));
