/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prefer-stateless-function */
import React, { Component, useEffect } from 'react';
import ContentDetailLayoutContainer from '@airtel-feature/content-details/content-details/containers/ContentDetailLayoutContainer';
import { packageReset, paginatedLayoutFetchAction } from '@airtel-feature/layout/actions/LayoutActions';
import { fetchAllChannels } from '@airtel-feature/layout/actions/ChannelAction';
import { fetchEpgChannelDetails } from '@airtel-feature/layout/actions/EpgAction';
import { emptyDetailAction, setPreviousPageTitleAction } from '@airtel-feature/content-details/content-details/actions/ContentDetailsAction';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { getTimeStamp } from '@airtel-tv/utils/GlobalUtil';
import lodashGet from 'lodash/get';
import { syncContentInit } from '@airtel-tv/sync/SyncActions';
import CrashCaptureHOC from '@airtel-tv/lib/hoc/CrashCaptureHOC';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { showModalComponentAction } from '../../modal-popup/ModalPopupActions';
import ErrorHandler from '../../error-handler/ErrorHandler';
import getRail from '../../rail/factories/RailFactory';
import { fetchBlogs } from '../../blog/BlogActions';
import LoaderComponent from '../../shared/components/loader/LoaderComponent';
import ErrorPageComponent from '../../shared/components/error-page/ErrorPageComponent';
import { isErrorPageShown } from '@airtel-feature/notify/NotifyActions';

const getPageIdFromProps = (match, appConfig) => {
    const {
        params: { pageTitle, contentId },
    } = match;
    const { pageIdTitleMap, web_pageId } = appConfig;

    let pageId = null;

    if (contentId) {
        pageId = web_pageId.CONTENT_DETAIL;
    }
    else if (pageTitle) {
        pageId = pageIdTitleMap[pageTitle];
    }
    else {
        pageId = appConfig.pages ? appConfig.pages[0].id : null; // first page is default page
    }

    return {
        contentId,
        pageId,
    };
};
const ContentDetailLayoutContainerWrapper = (props) => {
    const {
        fetchBlogsDispatch,
        appConfig: { cdpPostFooterMapping = {} },
        blogConfig,
        contentId = '',
        autoLoginInProgress,
    } = props;

    useEffect(() => {
        if (cdpPostFooterMapping && Object.keys(cdpPostFooterMapping).length > 0 && cdpPostFooterMapping[contentId] !== '') {
            const postId = cdpPostFooterMapping[contentId];
            if (postId) {
                if (!(blogConfig?.cdpOnPageData?.[postId])) {
                    fetchBlogsDispatch({ postId });
                }
            }
        }
    }, []);

    if (autoLoginInProgress) {
        return <LoaderComponent pageType="cdp" />;
    }
    return (
        <ContentDetailLayoutContainer
            railFactory={getRail}
            ErrorHandler={ErrorHandler}
            showModalComponentAction={showModalComponentAction}
            LoaderComponent={LoaderComponent}
            ErrorPageComponent={ErrorPageComponent}
            {...props}
        />
    );
};

function mapStateToProps(state, props) {
    const {
        userConfig,
        appConfig,
        appConfig: { web_pageId: webPageId },
        authConfig,
        layoutDetails,
        packageContents,
        epg,
        channel,
        contentDetails,
        authConfig: {
            isAuthenticated,
            uid,
            autoLoginInProgress,
        },
        themeConfig: {
            configs,
        },
        notify: { showPlayback },
        blogConfig,
    } = state;
    const hasEpgData = getTimeStamp() < epg.endTime;

    const { match } = props;
    // get page id
    const { pageId = '', contentId = '' } = props.pageId ? props : (match && Object.keys(match).length) ? getPageIdFromProps(match, appConfig) : {};
    const {
        [pageId]: pageLayoutData = null,
        layoutReset,
    } = layoutDetails;
    // layout data
    const isSSRData = pageLayoutData ? pageLayoutData.isSSRData : null;

    let layoutPackages = null;

    const isCdp = pageId === webPageId.CONTENT_DETAIL;

    if (isCdp || !(isSSRData && isAuthenticated)) {
        layoutPackages = pageLayoutData ? pageLayoutData.layoutPackages : null;
    }

    const totalRailsFetched = lodashGet(pageLayoutData, 'totalFetched', 0);

    const layoutError = pageLayoutData ? pageLayoutData.error : layoutDetails.error ? layoutDetails.error : null;
    return {
        userConfig,
        authConfig,
        appConfig,
        themeConfig: configs,
        layoutPackages,
        layoutError,
        packageContents,
        pageId,
        contentId,
        channels: channel.channels,
        layoutDetails,
        isAuthenticated,
        hasEpgData,
        totalRailsFetched,
        contentDetails,
        showPlayback,
        blogConfig,
        uid,
        layoutReset,
        autoLoginInProgress,
    };
}

export default withDeviceUtil(withRouter(connect(mapStateToProps, {
    layoutFetchActionDispatch: paginatedLayoutFetchAction,
    fetchAllChannelsDispatch: fetchAllChannels,
    fetchEpgChannelDetailsDispatch: fetchEpgChannelDetails,
    syncContentInitDispatch: syncContentInit,
    packageResetDispatch: packageReset,
    emptyDetailDispatch: emptyDetailAction,
    setPreviousPageTitleActionDispatch: setPreviousPageTitleAction,
    fetchBlogsDispatch: fetchBlogs,
    isErrorPageShownDispatch: isErrorPageShown,
})(CrashCaptureHOC((ContentDetailLayoutContainerWrapper)))));
