import { XppClaimOnThanksAction } from "../actions/XppClaimOnThanksAction";

const initialState = JSON.parse(`{
    "apiFailed": false,
    "success": false,
    "message": "",
    "rid": ""
}`);

const XppClaimOnThanksReducer = (state = initialState, action = {}) => {
    let changes = {};
    const { payload } = action;
    switch (action.type) {
        case XppClaimOnThanksAction.SINGLE_CLAIM_SUCCESS:
        case XppClaimOnThanksAction.STATUS_UPDATE:
            changes = {
                ...payload,
            };
            break;
        case XppClaimOnThanksAction.SINGLE_CLAIM_FAILURE:
            changes = {
                ...payload,
            };
            break;
        default: return state;
    }
    return {
        ...state,
        ...changes,
    };
};

export default XppClaimOnThanksReducer;
