
import React from 'react';
import SomeThingWentWrongSvgComponent from '@airtel-tv/ui-lib/svg/SomthingWentWrongSvgComponent';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { LocationUtil } from '@airtel-tv/utils';

const SomeThingWentWrongPopup = () => {
    const LANGUAGE = LanguageProviderUtil.getLanguage();

    const { someThingWrongPopupRetry = 'Retry', someThingWrongPopupLabel = 'Something Went Wrong', someThingWentWrongDesc = 'We apologize for the inconvenience. Our team is on it and will have it fixed shortly.' } = LANGUAGE;

    return (
        <div className="error-popup-bg">
            <div className="error-popup-wrapper">
                <div className="error-icon"><SomeThingWentWrongSvgComponent /></div>
                <p className="error-title mobile-text-18 mobile-text-bolder mobile-line-height-28">{someThingWrongPopupLabel}</p>
                <p className="error-subtitle mobile-text-14 mobile-text-normal mobile-line-height-20">{someThingWentWrongDesc}</p>
                <AnalyticsButtonComponent
                    className="error-btn mobile-text-14 mobile-text-bold mobile-line-height-22 common-white-btn"
                    onClick={() => {
                        LocationUtil.resetXstreamSessionOnThanks(0);
                        setTimeout(() => {
                            window.location.reload();
                        }, 1);
                    }}
                >
                    {someThingWrongPopupRetry}
                </AnalyticsButtonComponent>
            </div>
        </div>
    );
};


export default SomeThingWentWrongPopup;
