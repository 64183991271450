import RequestUtil from '@airtel-tv/utils/RequestUtil';
import env from '../../config';
import {AuthenticationUtil} from '@airtel-tv/utils/AuthenticationUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { removeFalsy } from '@airtel-tv/utils/GlobalUtil';

const { base, paths } = env.endpoints.epgApi;

export const getChannelsApi = async ({
    userContentProperties, mwTvPack, ln, deviceUtil,
}) => {
    const url = `${base}${paths.getChannels}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url,
        params: {
            ...userContentProperties,
            mwTvPack,
            ln,
            appId: env.appId,
        },
        headers: removeFalsy(headers),
    });
};

export const getChannelScheduleApi = async ({
    userContentProperties, mwTvPack, ln, channelId, startTime, endTime, deviceUtil,
}) => {
    const url = `${base}${paths.channelEpg}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url,

        params: {
            ...userContentProperties,
            mwTvPack,
            ln,
            channelId,
            startTime,
            endTime,
            appId: env.appId,
        },
        headers: removeFalsy(headers),
    });
};

export const getEpgContentApi = async ({
    userContentProperties, mwTvPack, ln, startTime, endTime, deviceUtil,
}) => {
    const url = `${base}${paths.getEpgDetails}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url,

        params: {
            ...userContentProperties,
            mwTvPack,
            ln,
            startTime,
            endTime,
            appId: env.appId,
        },
        headers: removeFalsy(headers),
    });
};


export default {
    getChannelsApi,
    getChannelScheduleApi,
    getEpgContentApi,
};
