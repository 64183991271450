import { DistroAction } from '../actions/DistroAction';

const initialState = JSON.parse(`{
    "fetching": false,
    "distroData": []
}`);

const DistroReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case DistroAction.FETCH_DISTRO_DETAILS:
            changes = {
                ...state,
                fetching: true,
            };
            break;

        case DistroAction.FETCH_DISTRO_DETAILS_SUCCESS:
            changes = {
                distroData: [
                    ...action.data,
                ],
                fetching: false,
            };
            break;

        case DistroAction.FETCH_DISTRO_DETAILS_ERROR:
            changes = {
                ...state,
                error: action.error,
                fetching: false,
            };
            break;

        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default DistroReducer;
