import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    addListenerToEvent, mediaForMaxTabScreen, mediaForMobile,
} from '@airtel-tv/utils';
import { RESIZE_OPTION } from '@airtel-tv/constants/GlobalConst';

function ResizeHOC(WrappedComponent) {
    class ResizeComponent extends PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                isMaxTabWidth: false,
            };
        }

        componentDidMount() {
            this.updateMediaCondition();
            addListenerToEvent('resize', this.updateMediaCondition);
        }

        checkMediaCondition = () => {
            const { resizeOption } = this.props;
            switch (resizeOption) {
                case RESIZE_OPTION.TAB_LARGE:
                    return mediaForMaxTabScreen();
                case RESIZE_OPTION.MOBILE:
                    return mediaForMobile();
                default:
                    return false;
            }
        }

        updateMediaCondition = () => {
            const { isMaxTabWidth } = this.state;

            if (this.checkMediaCondition() && !isMaxTabWidth) {
                this.setState({
                    isMaxTabWidth: true,
                });
            }
            else if (!this.checkMediaCondition()) {
                this.setState({
                    isMaxTabWidth: false,
                });
            }
        }

        render() {
            const { isMaxTabWidth } = this.state;
            return (
                <WrappedComponent
                    {...this.props}
                    isMaxTabWidth={isMaxTabWidth}
                />
            );
        }
    }

    ResizeComponent.defaultProps = {
        resizeOption: RESIZE_OPTION.MOBILE,
    };

    ResizeComponent.propTypes = {
        resizeOption: PropTypes.string,
    };

    return ResizeComponent;
}

export { ResizeHOC };
