import { PopupActions } from './PopupActions';


const initialState = {
};

const PopupReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case PopupActions.POPUP_CONFIG_SUCCESS:
            changes = {
                ...action.payload,
                fetching: false,
                error: null,
            };
            break;
        case PopupActions.POPUP_CONFIG_FETCH:
            changes = {
                fetching: true,
            };
            break;

        case PopupActions.POPUP_CONFIG_ERROR:
            changes = {
                ...action.payload,
                fetching: false,
            };
            break;

        default: return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default PopupReducer;
