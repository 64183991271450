import { element } from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { ELEMENT_ID } from '@airtel-tv/constants/GlobalConst';
import SeekBarComponent from '../player-controls/SeekBarComponent';
import { addListenerToEvent, getElementById } from '@airtel-tv/utils/WindowUtil';
import { removeTrailerData } from '../../../notify/NotifyActions';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';

const TileTrailerControlBarComponent = ({ onRef, getVideoTag, playTrailer , trailerLoaded, setCurrentTime, setMeta, title, subtitle, trailerRefId, trailersSync, removeTrailerDataDispatch}) => {
    const [playerFunc, setPlayerFunc] = useState(null);
    const [playerEvents, setEvents] = useState(null);
    const [isMute, setMute] = useState(true);
    const progressBarRef = useRef();
    // const [meta, setMeta ] = useState({});
    const videoRef = getVideoTag();
    // const [currentTime, setCurrentTime ] = useState(0);
    const [isEnded, videoEnded] = useState(false);

    const controls = {
        setPlayerFunctions(playerFunctions) {
            setPlayerFunc(playerFunctions);
        },
        setControlBarEventEmitter() {

        },
        initPlayerVolume() {

        },
        setPlayerEvents(events) {
            setEvents(events);
            events?.emitter.on(PLAYER_EVENTS_LIST.LOADED, onLoaded);
            events?.emitter.on(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, onSeekBarChange);
            events?.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, onTimeUpdate);
            events?.emitter.on(PLAYER_EVENTS_LIST.ENDEND, onEnded);
        },
        enableControlBar() {

        },
        setPlayerQuality() {

        },
        fadeOut() {

        },
        showCenterIcon() {

        },
        hideCenterIcon() {
        },
        fadeIn() {},
        reset() {},
    };

    onRef(controls);

    const onLoaded = (meta) => {
        trailerLoaded(true);
        setMeta(meta);
        if (trailersSync && trailersSync[trailerRefId]) {
            const { lastWatchedPosition = 0 } = trailersSync[trailerRefId];
            playerFunc?.forward(lastWatchedPosition);
        }
    }

    useEffect(() => {
        if(!playerFunc) {
            return
        }
        if(videoRef?.volume === 0) {
            setMute(videoRef?.volume === 0);
        } else {
            const userMutePref = browserStore.get(LOCAL_STORE_KEYS.TRAILER_AUTOPLAY_MUTE) === 'true';
            userMutePref ? playerFunc?.volumeChange(0, false, false) : playerFunc?.volumeChange(100, false, false);
            setMute(userMutePref);
        }
    }, [videoRef, playerFunc]);

    useEffect(() => {
        return () => {
            playerEvents?.emitter.off(PLAYER_EVENTS_LIST.LOADED, onLoaded);
            playerEvents?.emitter.off(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, onSeekBarChange);
            playerEvents?.emitter.off(PLAYER_EVENTS_LIST.TIMEUPDATE, onTimeUpdate);
            playerEvents?.emitter.off(PLAYER_EVENTS_LIST.ENDEND, onEnded);
        }
    }, []);

    const onEnded = () =>{
        videoEnded(true);
        removeTrailerDataDispatch({ payload: trailerRefId });
    }

    const onSeekBarChange = (data) => {
        // const { currentTime } = this.state;
        // if (currentTime !== data.newTime) {
        //     this.setState({
        //         currentTime: data.newTime,
        //     });
        // }
    }

    const handleVolume = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isMute) {
            playerFunc?.volumeChange(100, false, false);
            setPlayerMute(false);
        }
        else {
            playerFunc?.volumeChange(0, false, false);
            setPlayerMute(true)
        }  
    };

    const setPlayerMute = (flag) => {
        setMute(flag);
        browserStore.set(LOCAL_STORE_KEYS.TRAILER_AUTOPLAY_MUTE, flag);
    }

    useEffect(() => {
        setTimeout(() => {
            playTrailer ? !isEnded && playerFunc?.play() : !isEnded && playerFunc?.pause();
        })
    }, [playTrailer, isEnded, playerFunc]);

    const seekBarChange = (seekTime, time) => {
        console.log("called")
    }

    const setSeekPauseStatus = () => {
        playerFunc.emitEvent(PLAYER_EVENTS_LIST.SEEK_PAUSE);
    }

    const onTimeUpdate = (playbackCurrentTime) => {
        setCurrentTime(playbackCurrentTime);
    }

    // CODE FOR REPLAY
    // const replay = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     videoEnded(false);
    // }

    // const seekBarElement = (
    //     <SeekBarComponent
    //         max={meta.duration}
    //         currentTime={currentTime}
    //         progress={0}
    //         onChange={seekBarChange}
    //         pause={playerFunc?.pause}
    //         play={playerFunc?.play}
    //         isPaused={false}
    //         // previewImageInfo={previewImageInfo}
    //         getProgressBarRef={() => progressBarRef}
    //         setSeekPauseStatus={setSeekPauseStatus}
    //     />
    // );

    return (
        <>
            {/* <div
                className="vdo-player__progressbar"
                ref={progressBarRef}
            >
                <div className="slider">{seekBarElement}</div>
            </div> */}
            {/* { isEnded ? <button className='trailer-card-volume-btn' onClick={(e) => replay(e)}>
                <svg  className="volume-img" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.46826 8.25V15.75" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.34375 3.7489L21.9688 20.2489" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.5 14.4739V21L9.46875 15.75H4.78125C4.57405 15.75 4.37534 15.671 4.22882 15.5303C4.08231 15.3897 4 15.1989 4 15V9C4 8.80109 4.08231 8.61032 4.22882 8.46967C4.37534 8.32902 4.57405 8.25 4.78125 8.25H9.46875L10.135 7.7525" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.6089 5.90522L16.4999 3V10.0141" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button> : */}

            <div className="continue-tile-time-wrap trailer-tile-gradient">
                        <div className="cw-tile-progress-holder">
                            <p className="time">{ title }</p>
                            <p className="trailerTag">{subtitle}</p>

                            <button className='trailer-card-volume-btn' onClick={(e) => handleVolume(e)}>
                { isMute ? <svg  className="volume-img" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.46826 8.25V15.75" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.34375 3.7489L21.9688 20.2489" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.5 14.4739V21L9.46875 15.75H4.78125C4.57405 15.75 4.37534 15.671 4.22882 15.5303C4.08231 15.3897 4 15.1989 4 15V9C4 8.80109 4.08231 8.61032 4.22882 8.46967C4.37534 8.32902 4.57405 8.25 4.78125 8.25H9.46875L10.135 7.7525" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.6089 5.90522L16.4999 3V10.0141" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                : <svg className="volume-img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.72778 15.9709H3.81742C3.60037 15.9709 3.3922 15.8873 3.23873 15.7383C3.08525 15.5894 2.99902 15.3874 2.99902 15.1768V8.82324C2.99902 8.61261 3.08525 8.41061 3.23873 8.26167C3.3922 8.11273 3.60037 8.02905 3.81742 8.02905H8.72778L16.0933 2.46973V21.5303L8.72778 15.9709Z" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.72754 8.02905V15.9709" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.041 9.75394C20.345 10.0489 20.5861 10.3991 20.7506 10.7846C20.9151 11.17 20.9998 11.5831 20.9998 12.0002C20.9998 12.4174 20.9151 12.8305 20.7506 13.2159C20.5861 13.6014 20.345 13.9516 20.041 14.2466" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> }
            </button> 
                        </div>
                        
                    </div>
            
            {/* } */}
        </>
    );
};

TileTrailerControlBarComponent.defaultProps = {
    onRef: () => null,
    onPlayerFunctionsNotFound: () => null,
    logoUrl: '',
    enableBitrateSelector: true,
    playbackSessionId: null,
    previewImageInfo: null,
    videoMeta: {
        videoTitle: '',
        subTitle: '',
    },
    nextEpisodeDetails: null,
};
const mapStateToProps = state => {
    const {
        contentDetails,
        notify
    } = state;

    return {
        contentDetails,
        trailersSync: notify.trailerSync
    };
}

    
export default connect(mapStateToProps, {
    // dispatchPrerollAdsPlaying: getPrerollAdsPlaying,
    // removeContentDispatch: syncContentRemove,
    // contentDetailsFetchActionDispatch: contentDetailsFetchAction
    removeTrailerDataDispatch: removeTrailerData
})(withDeviceUtil(TileTrailerControlBarComponent));
