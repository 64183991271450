const NotifyActions = {
    ADD_TOAST: 'ADD_TOAST',
    REMOVE_TOAST: 'REMOVE_TOAST',

    SHOW_ACTION_MODAL: 'SHOW_ACTION_MODAL',
    HIDE_ACTION_MODAL: 'HIDE_ACTION_MODAL',

    SHOW_INTRODUCTION_MODAL: 'SHOW_INTRODUCTION_MODAL',
    HIDE_INTRODUCTION_MODAL: 'HIDE_INTRODUCTION_MODAL',

    SHOW_ERROR_PAGE: 'SHOW_ERROR_PAGE',
    ERROR_PAGE_SHOWN: 'ERROR_PAGE_SHOWN',

    SHOW_LOADING_BAR: 'SHOW_LOADING_BAR',
    HIDE_LOADING_BAR: 'HIDE_LOADING_BAR',

    META_DATA_CHANGE: 'META_DATA_CHANGE',

    ACTIVE_NAVBAR_SELECT: 'ACTIVE_NAVBAR_SELECT',

    HEADER_ROUTING_ADD: 'HEADER_ROUTING_ADD',
    HEADER_ROUTING_REMOVE: 'HEADER_ROUTING_REMOVE',

    SHOW_SEARCH_POPUP: 'SHOW_SEARCH_POPUP',
    HIDE_SEARCH_POPUP: 'HIDE_SEARCH_POPUP',

    REDIRECTION_INIT: 'REDIRECTION_INIT',
    REDIRECTION_DONE: 'REDIRECTION_DONE',
    SHOW_OVERLAY: 'SHOW_OVERLAY',
    HIDE_OVERLAY: 'HIDE_OVERLAY',

    SHOW_INLINE_SEARCH_NAV: 'SHOW_INLINE_SEARCH_NAV',

    SHOW_PREFERRED_PARTNER_TOOLTIP: 'SHOW_PREFERRED_PARTNER_TOOLTIP',
    HIDE_PREFERRED_PARTNER_TOOLTIP: 'HIDE_PREFERRED_PARTNER_TOOLTIP',

    API_FETCH_COMPLETE: 'API_FETCH_COMPLETE',

    UPDATE_KEYBOARD_CONTROLS: 'UPDATE_KEYBOARD_CONTROLS',
    SHOW_PLAYBACK: 'SHOW_PLAYBACK',
    HIDE_PLAYBACK: 'HIDE_PLAYBACK',
    HIDE_RENEW_BANNER: 'HIDE_RENEW_BANNER',
    SHOW_RENEW_BANNER: 'SHOW_RENEW_BANNER',
    ADD_TRAILER_DATA: 'ADD_TRAILER_DATA',
    REMOVE_TRAILER_DATA: 'REMOVE_TRAILER_DATA',
    HIDE_BANNER_ON_PLAY: 'HIDE_BANNER_ON_PLAY',
    SHOW_BANNER_ON_PLAY: 'SHOW_BANNER_ON_PLAY',
};

export default NotifyActions;

export const addToast = payload => ({
    type: NotifyActions.ADD_TOAST,
    ...payload,
});

export const removeToast = payload => ({
    type: NotifyActions.REMOVE_TOAST,
    ...payload,
});

export const showActionModal = payload => ({
    type: NotifyActions.SHOW_ACTION_MODAL,
    ...payload,
});

export const hideActionModal = payload => ({
    type: NotifyActions.HIDE_ACTION_MODAL,
    ...payload,
});

export const showIntroductionModal = payload => ({
    type: NotifyActions.SHOW_INTRODUCTION_MODAL,
    ...payload,
});

export const hideIntroductionModal = payload => ({
    type: NotifyActions.HIDE_INTRODUCTION_MODAL,
    ...payload,
});

export const showErrorPage = payload => ({
    type: NotifyActions.SHOW_ERROR_PAGE,
    ...payload,
});

export const errorPageShown = payload => ({
    type: NotifyActions.ERROR_PAGE_SHOWN,
    ...payload,
});

export const showLoadingBar = () => ({
    type: NotifyActions.SHOW_LOADING_BAR,
    val: true,
});

export const hideLoadingBar = () => ({
    type: NotifyActions.HIDE_LOADING_BAR,
    val: false,
});

export const changeMetaData = payload => ({
    type: NotifyActions.META_DATA_CHANGE,
    ...payload,
});

export const changeActiveNavbar = payload => ({
    type: NotifyActions.ACTIVE_NAVBAR_SELECT,
    ...payload,
});

export const headerAddNewRoute = payload => ({
    type: NotifyActions.HEADER_ROUTING_ADD,
    ...payload,
});

export const headerPopRoute = payload => ({
    type: NotifyActions.HEADER_ROUTING_REMOVE,
    ...payload,
});

export const hideSearchPopup = () => ({
    type: NotifyActions.HIDE_SEARCH_POPUP,
});

export const redirectTo = payload => ({
    type: NotifyActions.REDIRECTION_INIT,
    ...payload,
});

export const redirectionDone = () => ({
    type: NotifyActions.REDIRECTION_DONE,
});

export const showOverlay = () => ({
    type: NotifyActions.SHOW_OVERLAY,
});

export const hideOverlay = () => ({
    type: NotifyActions.HIDE_OVERLAY,
});

export const showPreferredPartnerTooltip = payload => ({
    type: NotifyActions.SHOW_PREFERRED_PARTNER_TOOLTIP,
    ...payload,
});

export const hidePreferredPartnerTooltip = payload => ({
    type: NotifyActions.HIDE_PREFERRED_PARTNER_TOOLTIP,
    ...payload,
});

export const apiFetchCompleteAction = payload => ({
    type: NotifyActions.API_FETCH_COMPLETE,
    ...payload,
});

export const updateKeyboardControlsAction = payload => ({
    type: NotifyActions.UPDATE_KEYBOARD_CONTROLS,
    ...payload,
});

export const showPlayback = () => ({
    type: NotifyActions.SHOW_PLAYBACK,
});

export const hidePlayback = () => ({
    type: NotifyActions.HIDE_PLAYBACK,
});

export const hideRenewBanner = () => ({
    type: NotifyActions.HIDE_RENEW_BANNER,
});

export const showRenewBanner = () => ({
    type: NotifyActions.SHOW_RENEW_BANNER,
});

export const addTrailerData = payload => ({
    type: NotifyActions.ADD_TRAILER_DATA,
    ...payload,
});

export const removeTrailerData = payload => ({
    type: NotifyActions.REMOVE_TRAILER_DATA,
    ...payload,
});

export const hideBannerOnPlay = () => ({
    type: NotifyActions.HIDE_BANNER_ON_PLAY,
});

export const showBannerOnPlay = () => ({
    type: NotifyActions.SHOW_BANNER_ON_PLAY,
});
