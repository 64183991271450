/* eslint-disable react/no-unknown-property */
import React, {
    useEffect, useRef, memo, useState,
} from 'react';
import PropTypes, { number, string } from 'prop-types';
import { getSourceName, matchClassSelector, scrollIfNeeded } from '@airtel-tv/utils/GlobalUtil';
import { getCurrentWindowSourceName } from '@airtel-tv/utils/WindowUtil';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { connect } from 'react-redux';
import { ELEMENT_ID, TIZEN_KEYCODES } from '@airtel-tv/constants';
import { clickEvent, searchEvents } from './FunctionalEvents';
import { setFocusedElement } from '../../tv/src/modules/navigation/NavigationActions';
import EventType from './EventType';

const onButtonClick = ({
    meta,
    corePlaybackMeta,
    onClick,
    e,
    isSearchEvent,
    currentSource,
}) => {
    // const location = getWindowLocation();
    // const { pathname } = location || { pathname: ROUTE_PATHS.ROOT };
    // const pathname = getCurrentWindowSourceName() || ROUTE_PATHS.ROOT;
    const sourceName = meta?.source_name || currentSource;
    if (meta) {
        const metaEvent = {
            ...meta,
            source_name: sourceName,
        };
        const playbackMeta = Object.keys(corePlaybackMeta || {}).length ? corePlaybackMeta : null;
        if (isSearchEvent) {
            searchEvents({
                ...metaEvent,
            }, EventType.CLICK);
        }
        else {
            clickEvent(
                metaEvent,
                playbackMeta,
            );
        }
    }
    if (onClick && typeof onClick === 'function') {
        onClick(e);
    }
};
const onButtonFocus = ({
    meta, onFocus, clickEventOnfocus, e,
    focusProps, corePlaybackMeta,
}) => {
    e?.stopPropagation();
    e?.nativeEvent.stopPropagation();
    e?.preventDefault();
    e?.nativeEvent?.stopImmediatePropagation();
    const pathname = getCurrentWindowSourceName() || ROUTE_PATHS.ROOT;
    const sourceName = SOSUtil.getRouteSourceNameMappedFromCMS(getSourceName(pathname));
    const playbackMeta = Object.keys(corePlaybackMeta || {}).length ? corePlaybackMeta : null;
    if (meta && clickEventOnfocus) {
        clickEvent({
            ...meta,
            source_name: sourceName,
        }, playbackMeta);
    }
    const {
        scrollPositionVertical,
        scrollPositionHorizontal,
        scrollBehaviour,
        scrollIntoViewIfNeeded,
        horizontalScrollIntoViewIfNeeded,
    } = focusProps;
    const currentElem = e.target;
    if (currentElem) {
        if (horizontalScrollIntoViewIfNeeded) {
            scrollIfNeeded(currentElem)
        }
        if (scrollIntoViewIfNeeded) {
            currentElem.scrollIntoView({
                behavior: scrollBehaviour,
                block: scrollPositionVertical,
                inline: scrollPositionHorizontal,
            });
        }
    }

    onFocus(e);
};


const AnalyticsButtonComponent = (props) => {
    const {
        className, value, meta, onClick, children, type, disabled, id, 'aria-label': ariaLabel, role = 'button', onMouseDown, title,
        'data-sn-down': dataSnDown,
        'data-sn-up': dataSnUp,
        'data-sn-left': dataSnLeft,
        'data-sn-right': dataSnRight,
        tilePosition,
        railPosition,
        onFocus,
        onKeyDown,
        onKeyUp,
        onMouseEnter = () => {},
        onMouseLeave = () => {},
        clickEventOnfocus,
        focusProps = {
            scrollPositionVertical: 'nearest',
            scrollPositionHorizontal: 'nearest',
            scrollBehaviour: 'smooth',
        },
        scrollIntoViewIfNeeded = false,
        'data-testid': dataTestId,
        focusUpElement,
        focusDownElement,
        focusLeftElement,
        focusRightElement,
        focusUpTargetByClass,
        focusDownTargetByClass,
        focusLeftTargetByClass,
        focusRightTargetByClass,
        focusLink = false,
        setFocusedElementDispatch,
        autoFocus,
        isSearchEvent = false,
        navigationObj: { currentSource = '' },
        onTransitionEnd,
        style = {},
        corePlaybackMeta,
        horizontalScrollIntoViewIfNeeded = false,
    } = props;
    const linkRef = useRef();

    const [
        isFocused,
        setIsfocused,
    ] = useState(false);

    // useEffect(() => {
    //     if (autoFocus && !selector) {
    //         setFocusedElementDispatch({
    //             selector: id,
    //             targetByClass: false,
    //         });
    //     }
    // }, [
    //     autoFocus,
    // ]);

    useEffect(() => {
        if ((autoFocus || focusLink) && !isFocused) {
            linkRef.current.focus();
        }
    }, [
        autoFocus,
        focusLink,
    ]);
    const onLinkKeyDown = (e) => {
        if (onKeyDown
            && typeof onKeyDown === 'function') {
            onKeyDown({ event: e });
        }
        const { keyCode = '' } = e;
        const {
            UP_ARROW, DOWN_ARROW, RIGHT_ARROW,
            LEFT_ARROW,
        } = TIZEN_KEYCODES;
        switch (keyCode) {
            case UP_ARROW:
                if (focusUpElement) {
                    setFocusedElementDispatch({
                        selector: focusUpElement,
                        targetByClass: focusUpTargetByClass,
                    });
                }
                break;
            case DOWN_ARROW:
                if (focusDownElement) {
                    setFocusedElementDispatch({
                        selector: focusDownElement,
                        targetByClass: focusDownTargetByClass,
                    });
                }
                break;
            case LEFT_ARROW:
                if (focusLeftElement) {
                    setFocusedElementDispatch({
                        selector: focusLeftElement,
                        targetByClass: focusLeftTargetByClass,
                    });
                }
                break;
            case RIGHT_ARROW:
                if (focusRightElement) {
                    setFocusedElementDispatch({
                        selector: focusRightElement,
                        targetByClass: focusRightTargetByClass,
                    });
                }
                break;
            default:
        }
    };
    return (
        // eslint-disable-next-line react/button-has-type
        <button
            style={style}
            id={id}
            type={type}
            value={value}
            ref={linkRef}
            className={className}
            data-sn-up={dataSnUp}
            data-sn-down={dataSnDown}
            data-sn-left={dataSnLeft}
            data-sn-right={dataSnRight}
            focusUpElement={focusUpElement}
            focusDownElement={focusDownElement}
            focusLeftElement={focusLeftElement}
            focusRightElement={focusRightElement}
            tilePosition={tilePosition}
            railPosition={railPosition}
            disabled={disabled}
            onBlur={() => {
                setIsfocused(false);
            }}
            onFocus={(e) => {
                setIsfocused(true);
                onButtonFocus({
                    meta,
                    corePlaybackMeta,
                    onFocus,
                    clickEventOnfocus,
                    e,
                    focusProps: {
                        ...focusProps,
                        scrollIntoViewIfNeeded,
                        horizontalScrollIntoViewIfNeeded,
                    },
                });
            }}
            onClick={e => onButtonClick({
                meta,
                onClick,
                e,
                isSearchEvent,
                currentSource,
                corePlaybackMeta,
            })}
            onKeyDown={onLinkKeyDown}
            onKeyUp={(e) => {
                if (onKeyUp && typeof onKeyUp === 'function') {
                    onKeyUp({ event: e });
                }
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            title={title}
            role={role}
            aria-label={ariaLabel}
            onMouseDown={onMouseDown}
            tabIndex="0"
            data-testid={dataTestId}
            {...(onTransitionEnd ? { onTransitionEnd } : {})}
        >
            {children}
        </button>
    );
};

AnalyticsButtonComponent.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    meta: PropTypes.instanceOf(Object).isRequired,
    onClick: PropTypes.func,
    children: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    title: PropTypes.string,
    role: PropTypes.string,
    onMouseDown: PropTypes.func,
    'aria-label': PropTypes.string,
    clickEventOnfocus: PropTypes.bool,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    scrollIntoViewIfNeeded: PropTypes.bool,
    focusProps: PropTypes.objectOf(string),
    'data-testid': PropTypes.string,
    railPosition: number,
    tilePosition: number,
    corePlaybackMeta: PropTypes.object,
};

AnalyticsButtonComponent.defaultProps = {
    value: '',
    className: '',
    onClick: undefined,
    children: '',
    type: 'button',
    disabled: false,
    id: null,
    title: '',
    onMouseDown: undefined,
    role: 'button',
    'aria-label': '',
    clickEventOnfocus: false,
    onFocus: () => {},
    onKeyDown: () => {},
    onKeyUp: () => {},
    focusProps: {},
    scrollIntoViewIfNeeded: false,
    'data-testid': '',
    railPosition: null,
    tilePosition: null,
    corePlaybackMeta: {},
};

function mapStateToProps(state, props) {
    const {
        navigation: { selector = '', targetByClass = false } = {}, location: {
            navigationObj = {},
        } = {},
    } = state;
    const { id = '', className = '' } = props;
    let isFocused = false;
    if (selector) {
        if (!targetByClass) {
            isFocused = selector === id;
        }
        else {
            isFocused = matchClassSelector({
                classSelector: selector,
                className,
            });
        }
    }
    return {
        focusLink: isFocused,
        selector,
        navigationObj,
    };
}

export default connect(mapStateToProps, {
    setFocusedElementDispatch: setFocusedElement,
})(memo(AnalyticsButtonComponent));
