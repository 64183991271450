import React, {
    useEffect, useState,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSubscriptionBasedTiles } from '@airtel-feature/layout/utils/LayoutUtil';
import { buildTile } from '@airtel-feature/layout/builders/PlanTileBuilder';
import {
    CryptoUtil, DeviceTypeUtil, LanguageProviderUtil, LocationUtil, RoutingUtil, withDeviceUtil,
} from '@airtel-tv/utils';
import { useNavigate } from 'react-router';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import {
    IMAGE_PATHS,
    MODAL_POPUP, ONEHUB_CTA_TYPES, ONEHUB_INGRESS_INTENT, PLAN_TILE_LIMIT, QUERY_PARAMS, RAIL_TYPES,
    AIRTEL_USER_TYPES, ANALYTICS_ACTIONS, ANALYTICS_ASSETS,
} from '@airtel-tv/constants';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { OnehubShowAllPlansTiles } from '@airtel-feature/plansAndSubscription/PlansAndSubscriptionAction';
import { addParameterToURL, getMatchedRoute, getSourceNameFromStorage } from '@airtel-tv/utils/GlobalUtil';
import { hideModalComponentAction, showModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import useOnehubPlanDetailsPopup from '@airtel-tv/lib/hooks/useOnehubPlanDetailsPopup';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import { largescreenPlanPurchase } from '../../utils/PaymentsLSUtil';
import { webPlanPurchase } from '../../utils/PaymentsWebUtil';
import { showNonAirtelUserAction } from '../../../../tv/src/modules/user/UserAction';
import { PlansPaymentsFetchAction } from '../../../../tv/src/modules/plans-offers/PlansAndOffersAction';
import { purchaseSubscriptionInit } from '../../../../web/src/modules/subscription-details/action/SubscriptionAction';
import { showNonAirtelUserAction as showNonAirtelUserActionWeb } from '../../../../web/src/modules/user/UserAction';
import { setFocusedElement } from '../../../../tv/src/modules/navigation/NavigationActions';
import { APP_INSTALL_META, CONTENT_PROVIDERS } from '@airtel-tv/constants/ContentProviderConst';

const CP_ICON_IMAGE_SETTINGS = { 300: 50 };

const OneHubRail = (props) => {
    const {
        showModalComponentActionDispatch = () => {},
        svodPlans = {}, railComponent: RailComponent, railProps: railPropsFromParent, expandRailProps, isLargeScreen,
        customerType, circle, uid, msisdnNo, showNonAirtelUserActionDispatch, plansPaymentsFetchActionDispatch, mockPlan, useNativeRecharge, purchaseSubscriptionInitDispatch, showNonAirtelUserActionDispatchWeb, showAllPlansTiles,
        showMorePlansPage, morePlansPageState,
        search,
        isSubscriptions, planListingExist, setPartnerActivationDataDispatch,
        hideModalComponentActionDispatch, setFocusedElementDispatch, cpDetailsById, deviceUtil,
        isPlanListingRail,
        MAX_COLLAPSED_CPS,
        location: { search: urlSearchParams = '', hash = '' } = {},
        OnehubShowAllPlansTilesDispatch,
        allPlansHash,
        nonTrimmedPlanKey,
        AppInstallDescription,
    } = props;
    const { currentSource, prevSource } = getSourceNameFromStorage() || {};

    const {
        isActivationRail,
        railCollapsible,
        zionDisplay: { planKey = '' } = {},
        pageId,
        railSubtitle,
        railTagLine,
        railTitle,
    } = railPropsFromParent || {};
    const isTv = DeviceTypeUtil?.isTV();
    const queryParams = new URLSearchParams(urlSearchParams);
    const pathParams = LocationUtil.getPathParams(props);
    const { cpId } = pathParams;
    const preferredCp = cpId || queryParams.get(QUERY_PARAMS.PREFERRED_CP);
    const LANGUAGE = LanguageProviderUtil?.getLanguage() || {};
    const {
        INDIAN_RUPEE_SYMBOL = '₹',
        LATER = 'Later', PROCEED_TO_RECHARGE = 'Proceed To Recharge', ONEHUB_CUNDLE_RECHARGE_TEXT = 'Your new pack will begin automatically after your current subscription ends',
        SEE_MORE_PLANS = 'See More Plans',
    } = LANGUAGE;
    const collapsedComponentRef = useRef(null);
    const hasPremiumPartnerRef = useRef(false);

    const [
        computedTiles,
        setComputedTiles,
    ] = useState([]);
    const [
        showRail,
        setShowRail,
    ] = useState(false);

    // const [] = useState(railPropsFromParent);

    const navigate = useNavigate();
    const { showPlanDetailsPopup } = useOnehubPlanDetailsPopup();

    const purchaseMeta = {
        customerType,
        circle,
        uid,
        msisdnNo,
        navigate,
    };

    const partnerActivationRedirection = (params) => {
        const {
            tileProps: {
                planDetails: {
                    referenceId,
                    serviceId,
                    partner: {
                        cpId,
                    } = {},
                } = {},
            } = {},
        } = params;
        let activationRoute = `${isSubscriptions ? '/subscriptions' : ''}${ROUTE_PATHS.PLAN_ACTIVATION.replace(':cpId', cpId)}`;
        const activationParams = `referenceId=${referenceId}&serviceId=${serviceId}`;
        activationRoute += `${location?.search}${location?.search ? '&' : '?'}${activationParams}`;
        navigate(activationRoute);
    };

    const planPurchase = (params) => {
        if (isLargeScreen) {
            largescreenPlanPurchase({
                ...params,
                ...purchaseMeta,
                plansPaymentsFetchActionDispatch,
                showNonAirtelUserActionDispatch,
            });
            return;
        }
        webPlanPurchase({
            ...params,
            ...purchaseMeta,
            mockPlan,
            useNativeRecharge,
            showNonAirtelUserActionDispatch: showNonAirtelUserActionDispatchWeb,
            purchaseSubscriptionInitDispatch,
            cpIdParam: cpId,
        });
    };

    const seePlanDetails = (params) => {
        const {
            tileProps: {
                planDetails: {
                    partners = [],
                    duration = '',
                    // planTitle = '',
                    price = '',
                    plan = {},
                    discountedPrice = '',
                    validityPlaceholder = '',
                } = {},
                planTitle,
                seePlanCtaText,
            } = {},
        } = params || {};
        showPlanDetailsPopup({
            partners: partners || [],
            planDuration: duration || plan?.duration,
            planTitle: planTitle || plan?.title,
            planPrice: discountedPrice || price || plan?.price,
            overrideCrossButtonValue: false,
            cpDetailsById,
            ...(validityPlaceholder ? {planDescription: validityPlaceholder} : {}),
            meta: {
                asset_name: seePlanCtaText,
            },
        });
    };

    const displayMorePlansPage = (params) => {
        const url = addParameterToURL(`${RoutingUtil.getOneHubPageCDP({ ingressIntent: ONEHUB_INGRESS_INTENT.RENEW, cpId: preferredCp, isSubscriptions })}${allPlansHash}${search}`);
        navigate(url, { state: { origin: true } });
    };

    const hideOnehubModal = () => {
        hideModalComponentActionDispatch({
            showModalValue: false,
            componentNameValue: null,
            overrideCrossButtonValue: null,
        });
    };
 
    const showRechargeNotification = (params) => {
        showModalComponentActionDispatch({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.COMMON_MODAL,
            overrideCrossButtonValue: false,
            payload: {
                removeInnerPopupClass: true,
                primaryCTA: PROCEED_TO_RECHARGE,
                secondaryCTA: LATER,
                primaryAction: () => {
                    planPurchase(params);
                    hideOnehubModal();
                },
                secondaryAction: hideOnehubModal,
                title: ONEHUB_CUNDLE_RECHARGE_TEXT,
                subTitle: '',
                imageUrl: IMAGE_PATHS.ONEHUB_INFO_ICON,
                setFocusedElementDispatch,
                backGroundCustomClass: 'popup-modal-aligner',
                containerClassName: 'subscription-end',
                backdropClose: true,
            },
        });
    };

    const showAppInstallPopUp = ({ cpId = '', ctaText = '' }) => {
        if (!cpId) {
            return;
        }
        const {
            title = '',
            primaryCTA = '',
            ctaPretext = '',
            imageSettings = {},
            stepTitle = '',
            steps = [],
            popupId = '',
            imageUrl,
            popupMeta = {},
        } = AppInstallDescription[cpId] || APP_INSTALL_META[cpId] || {};
        const description = (
            <div className="common-modal-description-container margin-web-description">
                <div className="common-description-title text-ls-32 text-ls-bold text-16 mobile-text-16 line-height-36 mobile-line-height-30 text-bolder">{stepTitle}</div>
                {steps && steps.length ? (
                    <ul className="steps-container">
                        {
                            steps.map((step, index) => (
                                <li className="steps-wrapper text-ls-24 text-14 mobile-text-14 text-bold mobile-line-height-30 line-height-ls-36 line-height-30">
                                    <span className="steps-count text-ls-22 text-14 mobile-text-14 text-bold mobile-line-height-30 line-height-ls-36 line-height-30">
                                        {`STEP ${index + 1}`}
                                    </span>
                                    {' '}
                                    <div>{step}</div>
                                </li>
                            ))
                        }
                    </ul>
                ) : null}
            </div>
        );
        const assetName = `${ctaText?.toLowerCase()?.split(' ')?.join('_')}_${cpId?.toLowerCase()}_popup`;
        showModalComponentActionDispatch({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.COMMON_MODAL,
            overrideCrossButtonValue: false,
            payload: {
                title,
                description,
                primaryCTA,
                ctaPretext,
                imageUrl,
                imageSettings,
                primaryAction: () => {
                    hideModalComponentActionDispatch();
                },
                popupId,
                popupVisibleMeta: {
                    // asset_name: 'install_netflix_app',
                    action: ctaText,
                    asset_name: assetName,
                    ...popupMeta,
                },
                primaryActionMeta: {
                    action: primaryCTA,
                    asset_name: assetName,
                    ...popupMeta,
                },
                backGroundCustomClass: 'popup-modal-aligner',
                containerClassName: 'netflix_app_install_popup installation-style',
                setFocusedElementDispatch,
                backdropClose: true,
            },
        });
    };

    const primaryActionHandler = (params) => {
        const {
            tileProps: {
                primaryActionType,
                purchaseCtaText,
                planDetails: {
                    partner = {},
                } = {},
            } = {},
        } = params;
        if (primaryActionType === ONEHUB_CTA_TYPES.PARTNER_ACTIVATION) {
            partnerActivationRedirection(params);
        }
        else if (primaryActionType === ONEHUB_CTA_TYPES.BROWSE_CONTENT) {
            let channelUrl = ROUTE_PATHS.ROOT;
            const { cpId = '' } = partner || {};
            if (!cpId) {
                return;
            }
            if (cpId === CONTENT_PROVIDERS.NETFLIX) {
                showAppInstallPopUp({ cpId: CONTENT_PROVIDERS.NETFLIX, ctaText: purchaseCtaText });
                return;
            }
            const cpDetails = cpDetailsById?.[cpId] || {};
            // const cpDetails = cpDetailsById?.["EROSNOW"];
            const { title: channelPartnerName = '', pageLink = '' } = cpDetails;
            if (isSubscriptions) {
                channelUrl = RoutingUtil.getMobilityChannelPartnerUrl({
                    channelPartnerName,
                    pageLink,
                });
                LocationUtil.redirectToAppViaDeeplink({
                    deeplink: channelUrl,
                    disableSplash: true,
                });
            }
            else {
                channelUrl = RoutingUtil.getChannelPartnerPageUrl({
                    channelPartnerName,
                    pageLink,
                });
                navigate(channelUrl);
            }
        }
        else if (primaryActionType === ONEHUB_CTA_TYPES.PLAN_PURCHASE) {
            planPurchase(params);
        }
        else if (primaryActionType === ONEHUB_CTA_TYPES.RECHARGE_NOTIFICATION) {
            showRechargeNotification(params);
        }
    };

    const secondaryActionHandler = (params) => {
        const {
            tileProps: {
                secondaryActionType,
            } = {},
        } = params;
        if (secondaryActionType === ONEHUB_CTA_TYPES.SEE_PLAN_DETAILS) {
            seePlanDetails(params);
        }
        else if (secondaryActionType === ONEHUB_CTA_TYPES.SHOW_MORE_PLANS) {
            displayMorePlansPage(params);
        }
    };

    const getComputedTiles = () => {
        const seeAllPlansParams = hash.includes(allPlansHash);
        let planType = planKey;
        const { currentRouteList = [] } = railPropsFromParent || {};
        const currentRoute = currentRouteList?.length > 1 ? currentRouteList[1] : currentRouteList[0];
        const { isOnehubRoute = false } = currentRoute?.route || {};
        const isPreferredCpPlan = isOnehubRoute && preferredCp;
        const dataId = isPreferredCpPlan ? `${preferredCp}_plans` : 'all_plans';
        let allPlans = svodPlans?.[dataId]?.[planType];
        if (isPlanListingRail && !seeAllPlansParams && allPlans?.length && !isTv) {
            if (planKey === nonTrimmedPlanKey) {
                allPlans = allPlans.slice(0, 2);
            }
            else {
                allPlans = [];
            }
        }
        const activePlans = svodPlans?.[dataId]?.activePlans;
        const activePlanAmount = activePlans?.length ? `${INDIAN_RUPEE_SYMBOL}${activePlans[0]?.price}` : '';
        if (allPlans) {
            const tiles = getSubscriptionBasedTiles({
                allPlans,
                buildTile,
                tileType: railPropsFromParent?.railType,
                railType: railPropsFromParent?.railType,
                railProps: railPropsFromParent,
                planType,
                primaryActionHandler,
                secondaryActionHandler,
                msisdnNo,
                planListingExist,
                preferredCp,
                activePlanAmount,
                activePlans,
                cpDetailsById,
                deviceUtil,
            });
            if (tiles && tiles.length && tiles.filter(tile => tile.hasPremiumPartner).length > 0) {
                hasPremiumPartnerRef.current = true;
            }
            setShowRail(() => !!tiles?.length);
            setComputedTiles(tiles);
            if (!collapsedComponentRef.current && tiles.length && isActivationRail && railCollapsible) {
                collapsedComponentRef.current = (
                    <div className="collapsed-cps">
                        {
                            tiles.slice(0, MAX_COLLAPSED_CPS).map((tile) => {
                                const {
                                    planDetails: {
                                        partner: { id = '', partnerIcon = '' },
                                    },
                                } = tile;
                                const { coverImage: { SQUARE = '' } = {}} = cpDetailsById[id] || {};
                                return (
                                    <ThumborImage
                                        className="partner-img"
                                        src={SQUARE || partnerIcon}
                                        imageSettimgs={CP_ICON_IMAGE_SETTINGS}
                                        imageChanged
                                    />
                                );
                            })
                        }
                        <div className="partner-count text-10 text-bolder line-height-14 ">{`+${tiles.length - MAX_COLLAPSED_CPS} OTT'S`}</div>
                    </div>
                );
            }
        }
    };

    useEffect(() => {
        getComputedTiles();
    }, [
        hash,
        svodPlans,
        railPropsFromParent,
        nonTrimmedPlanKey,
        planListingExist
    ]);



    if (!showRail || (showAllPlansTiles && !isPlanListingRail && !isTv)) {
        return null;
    }
    return (
        <>
            <RailComponent
                {
                    ...{
                        ...props,
                        railProps: {
                            ...railPropsFromParent,
                            tiles: computedTiles,
                            collapsedComponent: collapsedComponentRef.current,
                            style: isPlanListingRail ? {
                                width: '135rem',
                                height: hasPremiumPartnerRef.current ? '70rem' : '58rem',
                            } : railPropsFromParent.style,
                            hideRailTitleExtraSpace: !(railTitle || railSubtitle || railTagLine),
                        },
                    }
                }
                {...(expandRailProps ? {
                    ...railPropsFromParent,
                    tiles: computedTiles,
                    collapsedComponent: collapsedComponentRef.current,
                    hideRailTitleExtraSpace: !(railTitle || railSubtitle || railTagLine),
                } : {})}
            />
        </>
    );
};

OneHubRail.defaultProps = {
    morePlansPageState: '',
    isPlanListingRail: false,
};

OneHubRail.propTypes = {
    railComponent: PropTypes.element.isRequired,
    railProps: PropTypes.object.isRequired,
    expandRailProps: PropTypes.bool.isRequired,
    showModalComponentActionDispatch: PropTypes.func.isRequired,
    OnehubShowAllPlansTilesDispatch: PropTypes.func.isRequired,
    showMorePlansPage: PropTypes.bool.isRequired,
    morePlansPageState: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired,
    morePlansPageState: PropTypes.string,
    hideModalComponentActionDispatch: PropTypes.func.isRequired,
    OnehubShowAllPlansTilesDispatch: PropTypes.func.isRequired,
    setFocusedElementDispatch: PropTypes.func.isRequired,
    isPlanListingRail: PropTypes.bool,
    AppInstallDescription: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
    const {
        plansAndSubscriptionData: {
            svodPlans = {},
            showAllPlansTiles,
            planListingExist,
            nonTrimmedPlanKey = '',
        } = {},
        userConfig: {
            userInfo: {
                msisdn = '',
                uid = '',
            } = {},
            customerType = '',
        },
        plansOffers: {
            userDetails: { circle = '' } = {},
        } = {},
        authConfig: { token = '' } = {},
        appConfig: {
            MAX_COLLAPSED_CPS = 3,
            AppInstallDescription = {},
            rechargePlans: rechargePlansConfig = {}, useNativeRecharge: isNativeRecharge, nativeRechargesUid = [], cpDetailsById,
        } = {},
    } = state;
    let useNativeRecharge = isNativeRecharge;
    if (nativeRechargesUid.length > 0 && !nativeRechargesUid.includes(uid)) {
        useNativeRecharge = false;
    }

    const search = props?.location?.search || '';
    const pathParams = LocationUtil.getPathParams(props);
    const queryParams = LocationUtil.getQueryParams(props);
    const ingressIntent = pathParams?.ingressIntent || queryParams?.ingressIntent;
    const showMorePlansPage = ingressIntent === ONEHUB_INGRESS_INTENT.RENEW;


    const {
        mockPlan = '',
    } = rechargePlansConfig;
    let msisdnNo = '';

    if (msisdn && token) {
        msisdnNo = CryptoUtil.AESDecryptMsdin({
            key: token,
            value: msisdn,
        });
        msisdnNo = msisdnNo.replace('+91', '');
    }
    return {
        svodPlans,
        customerType,
        uid,
        msisdnNo,
        circle,
        mockPlan,
        useNativeRecharge,
        showAllPlansTiles,
        showMorePlansPage,
        search,
        planListingExist,
        cpDetailsById,
        MAX_COLLAPSED_CPS,
        allPlansHash: '#ALL_PLANS',
        nonTrimmedPlanKey,
        AppInstallDescription,
    };
};

export default withRouter(withDeviceUtil(connect(mapStateToProps, {
    plansPaymentsFetchActionDispatch: PlansPaymentsFetchAction,
    showNonAirtelUserActionDispatch: showNonAirtelUserAction,
    purchaseSubscriptionInitDispatch: purchaseSubscriptionInit,
    showNonAirtelUserActionDispatchWeb: showNonAirtelUserActionWeb,
    showModalComponentActionDispatch: showModalComponentAction,
    hideModalComponentActionDispatch: hideModalComponentAction,
    OnehubShowAllPlansTilesDispatch: OnehubShowAllPlansTiles,
    setFocusedElementDispatch: setFocusedElement,
})(OneHubRail)));
