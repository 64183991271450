import { CONTENT_PROVIDERS } from '@airtel-tv/constants/ContentProviderConst';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import SubscriptionConst, { EVENT_NAME } from '@airtel-tv/constants/SubscriptionConst';
import browserStore from './BrowserStoreUtil';

import {
    addParameterToURL,
    filterPlans, filterSubscribedPlans, findSelectedCP, findSelectedPlan, getDate,
} from './GlobalUtil';
import { AuthenticationUtil } from './AuthenticationUtil';
import { LocationUtil } from './LocationUtil';
import { getWindowLocation, checkWindowExist } from './WindowUtil';
// import env from '../config';
import { AppEnv } from './GetEnv';
import { FORMAT_TYPE } from '@airtel-tv/constants/DateTimeConst';


export class SubscriptionUtil {
    /**
     * Find if user can view the content
     * @param {{ cpDetailsById: Object, cpId: String
     * }} props - props to be passed
     *  - cpDetails List from AppConfig
     *  - cpId of the cp to check for subscription
     * @returns {String | null} channelId
     */
    static getChannelId(props) {
        const { cpId, cpDetailsById } = props;
        const cpDetails = cpDetailsById[cpId];
        if (cpDetails) {
            return cpDetails.parentCp || cpId;
        }
        return null;
    }

    /**
     * Find if user can view the content
     * @param {{ cpDetails: Object, subscriptionList: Object, subsHierarchy: Number
     * }} props - props to be passed
     *  - details of the cp
     *  - subscription array of user
     *  - subsHierarchy related hierarchy of cp in subscription list
     * @returns {Boolean} if user can view the content then true, else false
     */
    static isLocked(props) {
        const {
            cpDetails = {}, subscriptionList = [], requiredSubscription = [], checkEligibleSubscription = false,
        } = props;
        const subsHierarchy = props.subsHierarchy || 0;
        if (checkEligibleSubscription) {
            const cpProductIds = subscriptionList.map(item => parseInt(item.productId, 10));
            const isProductIdFound = cpProductIds.some(r => requiredSubscription.includes(r));
            return !isProductIdFound;
        }
        if (!cpDetails || !subscriptionList) {
            return false;
        }
        const { cpId } = cpDetails;
        if (subscriptionList[cpId]) {
            return subscriptionList[cpId].hierarchy < subsHierarchy;
        }
        return true;
    }

    /**
     * @param {Object} channelDetails details of current channel
     * @param {Number} targetHierarchy max Hierarchy a user can have for the channel
     * @returns {Boolean}
     */
    static isSubscribed(channelDetails, targetHierarchy) {
        return !!channelDetails && channelDetails.hierarchy >= targetHierarchy;
    }

    /**
     * @param {Object} cpDetails details of current cp
     */
    static isPartnerChannel(cpDetails) {
        if (!cpDetails || cpDetails.parentCp || cpDetails.cpId === CONTENT_PROVIDERS.MWTV) {
            return false;
        }
        return true;
    }

    static saveCpId(cpid) {
        browserStore.set(LOCAL_STORE_KEYS.CHANNEL_PARTNER_ID, cpid);
    }

    static getCpId() {
        browserStore.get(LOCAL_STORE_KEYS.CHANNEL_PARTNER_ID);
    }

    static removeCpId() {
        browserStore.remove(LOCAL_STORE_KEYS.CHANNEL_PARTNER_ID);
    }


    static getSubscriptionStatus(status) {
        const SUBSCRIPTION_STATUS = {
            success: 'SUCCESS',
            failure: 'FAILURE',
            cancel: 'CANCEL',
        };
        return SUBSCRIPTION_STATUS[status];
    }

    static PLAN_TYPE = {
        FREE: 'FREE',
        PAID: 'ONE_TIME_SUBSCRIPTION',
        SUBSCRIPTION: 'SUBSCRIPTION',
        FREE_TRIAL: 'FREE_TRIAL',
    };

    static PACK_TYPE = {
        CHANNEL: 'channel',
        BUNDLE: 'bundle',
    };

    static PLAN_STATE = {
        ACTIVE: 'ACTIVE',
        DEPRECATED: 'DEPRECATED',
    };

    static PLATFORM = {
        ANDROID: 'android',
        IOS: 'ios',
        WEB: 'webos',
        MWEBOS: 'mwebos',
    };

    static paramsToObject = (entries) => {
        const result = {};
        for (const [
            key,
            value,
        ] of entries) { // each 'entry' is a [key, value] tupple
            result[key] = value;
        }
        return result;
    };

    static sendHandleAppState(subscribedPlans, subscribedCpId, status, planID, subscriptionType, eventName) {
        const appId = AuthenticationUtil.getAppId();
        if (appId !== 'MOBILITY') {
            return;
        }
        const filteredPlans = filterSubscribedPlans(subscribedPlans);
        const plans = filterPlans(filteredPlans)[subscriptionType];
        const subscribedPlan = findSelectedCP(plans, subscribedCpId, subscriptionType, planID);
        if (subscribedPlan) {
            const branchEvent = SubscriptionUtil.getBranchDetail(subscribedPlan, eventName, planID, status);
            const appVersion = AuthenticationUtil.getAppVersion();
            const os = AuthenticationUtil.getOS();
            const branchVersion = SubscriptionConst.BRANCH_IO_MIN_VERSION;
            const branchEventMinVersion = branchVersion[os];
            if (branchEventMinVersion && branchEventMinVersion && appVersion >= branchEventMinVersion) {
                if (eventName === EVENT_NAME.SUBSCRIPTION_COMPLETE) {
                    LocationUtil.sendSubscriptionEventToMobility(branchEvent);
                }
                else {
                    LocationUtil.sendXMPClaimEventToMobility(branchEvent);
                }
            }
            else {
                LocationUtil.handleAppstate(status);
            }
            return true;
        }
        return false;
    }

    static sendHandleAppStateSvod(subscribedPlans, subscribedCpId, status, planID, subscriptionType, eventName) {
        const appId = AuthenticationUtil.getAppId();
        if (appId !== 'MOBILITY') {
            return;
        }
        const filteredPlans = filterSubscribedPlans(subscribedPlans);
        const plans = filterPlans(filteredPlans)[subscriptionType];
        const subscribedPlan = findSelectedPlan(plans, subscribedCpId, subscriptionType, planID);
        if (subscribedPlan) {
            const branchEvent = SubscriptionUtil.getSvodBranchDetail(subscribedPlan, eventName, planID, status);
            const appVersion = AuthenticationUtil.getAppVersion();
            const os = AuthenticationUtil.getOS();
            const branchVersion = SubscriptionConst.BRANCH_IO_MIN_VERSION;
            const branchEventMinVersion = branchVersion[os];
            if (branchEventMinVersion && branchEventMinVersion && appVersion >= branchEventMinVersion) {
                if (eventName === EVENT_NAME.SUBSCRIPTION_COMPLETE) {
                    LocationUtil.sendSubscriptionEventToMobility(branchEvent);
                }
                else {
                    LocationUtil.sendXMPClaimEventToMobility(branchEvent);
                }
            }
            else {
                LocationUtil.handleAppstate(status);
            }
            return true;
        }
        return false;
    }

    static getSvodSubscriptionUrl = ({
        plan, successPage, failurePage, isMobility = false, pathname, rechargePlan = false, cpIdParam,
    }) => {
        const subscriptionWindowLocation = getWindowLocation();
        let journeyStart = pathname || subscriptionWindowLocation.pathname;
        const env = AppEnv.getEnv();
        if (!plan.planId) {
            const queryParams = LocationUtil.parse(window.location.search);
            plan = {
                ...plan,
                ...queryParams,
                planId: queryParams.planId || queryParams.planID,
            };
            journeyStart = queryParams.journeyStart ? queryParams.journeyStart : journeyStart;
        }
        const { planId, duration, partners, cpId, contentUrl, skuId, autoPayEnabled, price } = plan || {};
        const appId = LocationUtil.getKeyFromURL(window.location.search, 'appId') || browserStore.get(LOCAL_STORE_KEYS.APP_ID) || env.appId;
        const durDays = duration ? duration.replace(/ days/g, 'days') : '';
        const paramObject = LocationUtil.parse(subscriptionWindowLocation?.search || '');
        const queryParamsObject = {
            ...paramObject,
            planId,
            cpId: cpId || cpIdParam,
            ottCount: (partners || []).length,
            price,
            duration: durDays,
            contentUrl,
            appId,
            ingressIntent: 'paymentstatus',
            preferredCp: '',
        };
        let successUrl = LocationUtil.appendQueryParamsInUrl(`${subscriptionWindowLocation.origin + successPage}`, queryParamsObject);
        let failureUrl = LocationUtil.appendQueryParamsInUrl(`${subscriptionWindowLocation.origin + failurePage}`, {
            ...queryParamsObject,
            autoPayEnabled,
            journeyStart: subscriptionWindowLocation.pathname,
            skuId,
        });
        // let successUrl = `${subscriptionWindowLocation.origin + successPage}?planID=${planId}&cpId=${cpId}&ottCount=${(partners || []).length}&price=${price}&duration=${durDays}&contentUrl=${contentUrl}&appId=${appId}`;
        // let failureUrl = `${subscriptionWindowLocation.origin + failurePage}?planID=${planId}&cpId=${cpId}&contentUrl=${contentUrl}&skuId=${skuId}&journeyStart=${subscriptionWindowLocation.pathname}&appId=${appId}${autoPayEnabled ? `&autoPayEnabled=${autoPayEnabled}` : ''}`;
        if (plan.origin) {
            // PlanDetailComponent
            failureUrl += `&origin=${plan.origin}`;
        }
        if (appId === 'MOBILITY' || isMobility) {
            successUrl += '&viewType=webview';
            failureUrl += '&viewType=webview';
        }

        return {
            successUrl: successUrl.replaceAll('=undefined', '='),
            failureUrl: failureUrl.replaceAll('=undefined', '='),
        };
    };

    static getSubscriptionUrls = ({
        plan, successPage, failurePage, isMobility = false, pathname,
    }) => {
        const subscriptionWindowLocation = getWindowLocation();
        let journeyStart = pathname || subscriptionWindowLocation.pathname;
        const env = AppEnv.getEnv();
        if (!plan.planId) {
            const queryParams = LocationUtil.parse(window.location.search);
            plan = {
                ...plan,
                ...queryParams,
                planId: queryParams.planId || queryParams.planID,
            };
            journeyStart = queryParams.journeyStart ? queryParams.journeyStart : journeyStart;
        }
        const appId = LocationUtil.getKeyFromURL(window.location.search, 'appId') || browserStore.get(LOCAL_STORE_KEYS.APP_ID) || env.appId;
        let successUrl = `${subscriptionWindowLocation.origin + successPage}?planID=${plan.planId}&cpId=${plan.cpId}&contentUrl=${plan.contentUrl}&appId=${appId}`;
        let failureUrl = `${subscriptionWindowLocation.origin + failurePage}?planID=${plan.planId}&cpId=${plan.cpId}&contentUrl=${plan.contentUrl}&skuId=${plan.skuId}&journeyStart=${subscriptionWindowLocation.pathname}&appId=${appId}${plan.autoPayEnabled ? `&autoPayEnabled=${plan.autoPayEnabled}` : ''}`;
        if (plan.origin) {
            // PlanDetailComponent
            failureUrl += `&origin=${plan.origin}`;
        }
        if (appId === 'MOBILITY' || isMobility) {
            successUrl += '&viewType=webview';
            failureUrl += '&viewType=webview';
        }

        return {
            successUrl: successUrl.replaceAll('=undefined', '='),
            failureUrl: failureUrl.replaceAll('=undefined', '='),
        };
    };

    static getXStreamDetail = subscribedPlans => subscribedPlans.find(plan => plan.meta && plan.meta.offerType === 'PREMIUM');

    static getBestValuePlanId = cpObject => cpObject && cpObject.length && cpObject[0].plans.filter(plan => plan.bestValue === true)?.[0]?.planId;

    static getSvodBranchDetail = (subscribedPlan, name, planID, status) => {
        const endDate = getDate(new Date(subscribedPlan.validTill), FORMAT_TYPE.YEAR_MONTH_DATE_FORMAT);
        return {
            events: [
                {
                    name,
                    meta: {
                        selected_plan_id: planID,
                        plan_price: subscribedPlan?.price,
                        start_date: getDate(new Date(), FORMAT_TYPE.YEAR_MONTH_DATE_FORMAT),
                        end_date: endDate,
                        selected_plan_name: subscribedPlan?.title,
                        plan_duration: subscribedPlan?.duration,
                        partner_selected: subscribedPlan?.cpId,
                    },
                },
            ],
            status: SubscriptionUtil.getSubscriptionStatus(status),
        };
    };
    static getBranchDetail = (subscribedPlan, name, planID, status) => {
        const endDate = getDate(new Date(subscribedPlan.validTill), FORMAT_TYPE.YEAR_MONTH_DATE_FORMAT);
        return {
            events: [
                {
                    name,
                    meta: {
                        selected_plan_id: planID,
                        plan_price: subscribedPlan.plan.price,
                        start_date: getDate(new Date(), FORMAT_TYPE.YEAR_MONTH_DATE_FORMAT),
                        end_date: endDate,
                        selected_plan_name: subscribedPlan.plan.title,
                        plan_duration: subscribedPlan.plan.duration,
                        partner_selected: subscribedPlan.cpId,
                    },
                },
            ],
            status: SubscriptionUtil.getSubscriptionStatus(status),
        };
    };
    static shouldDisplay = (plan) => {
        if (
            plan.meta
          && typeof plan.meta.displayUnderSubscribedPack !== 'undefined'
          && plan.meta.displayUnderSubscribedPack === false
        ) {
            return false;
        }
        return true;
    };

    static isXstreamPremiumPlan = ({ subscribedPlans = [], xstreamCpId = 'XSTREAMPREMIUM' }) => {
        const filteredPlans = subscribedPlans.filter(plan => this.shouldDisplay(plan) && !plan.notVisible);

        const xstreamPremiumPlan = filteredPlans.find(plan => plan.cpId === xstreamCpId && plan.meta.offerType === 'PREMIUM');
        if (xstreamPremiumPlan) {
            return xstreamPremiumPlan;
        }
        return false;
    };
}

export default SubscriptionUtil;

export const filterRechargePlans = ({ allPlans }) => {
    let rechargePlans = [];
    const { browsePlans = [] } = allPlans;


    allPlans.browsePlans = browsePlans.map((offer) => {
        let { plans = [] } = offer;
        if (offer?.meta?.payment?.mode === 'AIRTEL_PARTNER') {
            rechargePlans = rechargePlans.concat(plans);
        }
        if (plans && plans.length > 1) {
            offer.plans = plans.filter(plan => plan?.meta?.payment?.mode !== 'AIRTEL_PARTNER');
            const recharges = plans = plans.filter(plan => plan?.meta?.payment?.mode === 'AIRTEL_PARTNER');
            rechargePlans = rechargePlans.concat(recharges);
        }
        return offer;
    });
    allPlans.browsePlans = browsePlans.filter(offer => offer?.meta?.payment?.mode !== 'AIRTEL_PARTNER');

    allPlans.rechargePlans = rechargePlans;
    allPlans.xppPlans = browsePlans.find(plans => plans.meta.offerType === 'PREMIUM');
};

export const airtelRechargeRedirection = ({
    selectedPlan = {}, msisdnNo = '', statusUrl = '', XSTREAM_HOME_CTA_TEXT = 'Move to Xstream Play',
}) => {
    const windowObj = typeof window !== 'undefined' ? window : {};
    const LOB_NAME = 'PREPAID';
    const currentPageUrl = windowObj?.location?.href;
    const utm_source = 'xstream';
    const AIRTEL_RECHARGE_URL = 'https://www.airtel.in/pay';
    const redirectionUrl = windowObj?.location?.origin + statusUrl;
    const payload = {
        order: {
            lobName: LOB_NAME,
            paymentAmount: selectedPlan.total,
            benefitAmount: selectedPlan.total,
            serviceInstance: msisdnNo,
        },
        billSummary: {
            transactionText: `Prepaid Recharge | ${msisdnNo}`,
            primaryHeading: '',
            detailSection: [
                {
                    key: selectedPlan.title,
                    value: `₹ ${selectedPlan.total}`,
                },
            ],
        },
        callBack: {
            redirectionUrl,
            cancelUrl: currentPageUrl,
        },
        flow: {
            trackingObj: { utm_source },
        },
        result: {
            resultDetails: {
                redirectionUrl: currentPageUrl,
                redirectionButtonLabel: XSTREAM_HOME_CTA_TEXT,
            },
            resultSummary: [
                {
                    heading: selectedPlan.title,
                    value: `₹ ${selectedPlan.total}`,
                },
            ],
        },
    };
    const encodedPaylaod = btoa(encodeURIComponent(JSON.stringify(payload)));
    if (checkWindowExist()) {
        window.location = `${AIRTEL_RECHARGE_URL}?payloadData=${encodedPaylaod}`;
        return;
    }

    return `${AIRTEL_RECHARGE_URL}?payloadData=${encodedPaylaod}`;
};
