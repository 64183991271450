import {
    call,
    put,
    select,
    getContext,
    delay,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { takeFirst } from '@airtel-tv/utils/ReduxSagaUtil';
import { getAppId, objectToArray } from '@airtel-tv/utils/GlobalUtil';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import { PROGRAM_TYPES, CONTINUE_WATCHING_CONSTANTS } from '@airtel-tv/constants/GlobalConst';
import { ADD_TO_CW_THRESHOLD_TIMER } from '@airtel-tv/constants/PlaybackConsts';
import { syncContentApi } from '@airtel-tv/services/SyncApiService';
import {
    getAuthConfigFromReducer, getSyncContentFromReducer, getAppConfigFromReducer, getUserConfigFromReducer,
} from '@airtel-tv/redux/StoreListing';
import {
    SYNC_ACTIONS, syncContentCalculatedOutput, syncFromLocalStore, syncContentCleared,
} from './SyncActions';
import SyncStorageUtil from './SyncStorageUtil';
// import { packageFetchSyncContent } from '../layout/actions/PackageActions';
import { updateSyncContentUtil, removeAllEpisodesFromShow, getParentContentId } from './SyncHelper';


export function* syncContentSaga({
    forceSync = false,
    isDelay = false,
    playbackForced = false,
    uid: uidFromParam = null,
    token: tokenFromParam = null,
}) {
    let uid = uidFromParam;
    let token = tokenFromParam;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        if (!playbackForced) {
            ({ uid, token } = yield select(getAuthConfigFromReducer));
        }

        // TODO: Check is authenticated
        if (!AuthenticationUtil.isAuthenticated() && !playbackForced) {
            return;
        }

        // Local Store key unique for each user
        const continueWatchingBrowserStorage = SyncStorageUtil.getSyncData(uid);
        // check if lastSync happened at least 5 minutes ago
        if (continueWatchingBrowserStorage) {
            const currentTime = new Date().getTime();
            yield put(syncFromLocalStore(continueWatchingBrowserStorage));
            const { lastSyncTime: lastSyncTimeFromUserconfig } = yield select(getUserConfigFromReducer);
            const { lastSyncUpdated: lastSyncUpdatedFromStroge } = continueWatchingBrowserStorage;
            if (
                !forceSync
                && currentTime - lastSyncUpdatedFromStroge < CONTINUE_WATCHING_CONSTANTS.MIN_SYNC_TIME
                && lastSyncTimeFromUserconfig < lastSyncUpdatedFromStroge
            ) {
                return;
            }
        }
        // delay during routing so that routing is done instantly
        if (isDelay) {
            yield delay(CONTINUE_WATCHING_CONSTANTS.DELAY_TIME);
        }
        const syncList = yield select(getSyncContentFromReducer);
        let {
            continueWatchingData,
        } = syncList;
        const {
            continueWatchingRail,
            toBeAddedFromRecent,
            toBeRemovedFromRecent,
            addToWatchList,
            removeFromWatchList,
        } = syncList;

        // get all content to add in sync and watch list
        // // get all content to removed in sync and watch list
        // const { toBeAdded, toBeRemoved } = updateSyncDataUtil(continueWatchingData);

        // const mergedAddToWatchList = {...addToWatchList, ...pywAddToWatchList};
        // const mergedRemoveFromWatchList = {...removeFromWatchList, ...removeFromPywWatchList}

        const body = {
            recents: {
                add: objectToArray(toBeAddedFromRecent),
                remove: objectToArray(toBeRemovedFromRecent),
            },
            favourites: {
                add: objectToArray(addToWatchList),
                remove: objectToArray(removeFromWatchList),
            },
        };

        const atvDid = deviceUtil.getXAtvDid();
        const appId = getAppId();
        const apiSyncResponseList = yield call(syncContentApi, {
            body,
            token,
            uid,
            appId,
            atvDid,
        });

        // yield put(syncContentSuccess({
        //     serverSyncedList: apiSyncResponseList,
        // }));

        // calculate output and call syncContentCalculatedOutput
        const {
            addFromSyncToRecent,
            removeFromSyncToRecent,
            watchList,
        } = updateSyncContentUtil({
            continueWatchingData,
            apiSyncResponseList,
            toBeAddedFromRecent,
            toBeRemovedFromRecent,
            continueWatchingRail,
        });

        // remove data
        const removeFromSyncLength = removeFromSyncToRecent.length;
        for (let index = 0; index < removeFromSyncLength; index++) {
            delete continueWatchingData[removeFromSyncToRecent[index]];
        }

        // add data
        continueWatchingData = {
            ...continueWatchingData,
            ...addFromSyncToRecent,
        };

        const lastSyncUpdated = new Date().getTime();
        const payload = {
            continueWatchingData,
            continueWatchingRail,
            lastSyncUpdated,
            toBeAddedFromRecent: {},
            toBeRemovedFromRecent: {},
            watchList,
            addToWatchList: {},
            removeFromWatchList: {},

        };

        yield put(syncContentCalculatedOutput(payload));
        const browserStoreContinueWatchData = {
            ...syncList,
            ...payload,
        };
        SyncStorageUtil.updateSyncData(browserStoreContinueWatchData, uid);
        // yield put(packageFetchSyncContent());
    }
    catch (e) {
        console.error(e);
    }
}

export function* addContentToSync({
    contentDetails,
    lastWatchedPosition,
    playSessionId,
    playDuration,
    changedAudio,
    lastUpdatedTimeStampMock = null,
    bypassCWThreshold = false,
}) {
    try {
        const {
            programType, id, langId, subtitle,
        } = contentDetails;
        const { uid } = yield select(getAuthConfigFromReducer);
        // If the user has watched less than initial 30 second of content do not add to CW list
        const { cwThresholdTimer = '' } = yield select(getAppConfigFromReducer);
        if (programType === PROGRAM_TYPES.PROGRAM || programType === PROGRAM_TYPES.LIVETVCHANNEL || (!bypassCWThreshold && lastWatchedPosition < (cwThresholdTimer || ADD_TO_CW_THRESHOLD_TIMER))) {
            return;
        }
        const syncList = yield select(getSyncContentFromReducer);
        let {
            continueWatchingData, continueWatchingRail, toBeAddedFromRecent,
        } = syncList;
        // const { toBeRemovedFromRecent } = syncList;

        // calculate output and call syncContentCalculatedOutput
        const playSessionData = {
            ...(playSessionId && { playSessionId }),
            ...((playDuration || playDuration === 0) && { durationWatched: playDuration }),
        };
        const lastUpdatedTimeStamp = lastUpdatedTimeStampMock || new Date().getTime();
        const contentId = getParentContentId(contentDetails);
        const idForAudio = contentDetails.programType === PROGRAM_TYPES.EPISODE ? contentDetails.seriesId : contentId;
        let continueWatchingDataChanges = {
            [contentId]: {
                contentResponse: { ...contentDetails },
                lastWatchedPosition,
                lastUpdatedTimeStamp,
                ...playSessionData,
                langId,
            },
        };

        toBeAddedFromRecent = {
            ...toBeAddedFromRecent,
            [contentId]: {
                contentId: id,
                lastWatchedPosition,
                lastUpdatedTimeStamp,
                ...playSessionData,
                langId,
            },
        };
        // if (toBeRemovedFromRecent[id]) {
        //     delete toBeRemovedFromRecent[id];
        // }

        let railChanges = {};
        // change rail data for tv show
        if (programType === PROGRAM_TYPES.EPISODE) {
            const tvShowId = lodashGet(contentDetails, 'seriesId', '');
            continueWatchingDataChanges = {
                ...continueWatchingDataChanges,
                [tvShowId]: {
                    contentResponse: { ...contentDetails },
                    lastWatchedPosition,
                    lastUpdatedTimeStamp,
                    ...playSessionData,
                    langId,
                    subtitle,
                },
            };

            railChanges = {
                [tvShowId]: true,
            };

            const seasonId = lodashGet(contentDetails, 'seasonId', '');
            // false: for catchup show
            if (seasonId) {
                continueWatchingDataChanges = {
                    ...continueWatchingDataChanges,
                    [seasonId]: {
                        contentResponse: { ...contentDetails },
                        lastWatchedPosition,
                        lastUpdatedTimeStamp,
                        ...playSessionData,
                        langId,
                        subtitle,
                    },
                };
            }
        }
        // change rail data for movie
        else {
            railChanges = {
                [contentId]: true,
            };
        }

        continueWatchingRail = {
            ...continueWatchingRail,
            ...railChanges,
        };
        continueWatchingData = {
            ...continueWatchingData,
            ...continueWatchingDataChanges,
        };
        const payload = {
            continueWatchingData,
            continueWatchingRail,
            toBeAddedFromRecent,
        // toBeRemovedFromRecent,
        };
        yield put(syncContentCalculatedOutput(payload));
        const browserStoreContinueWatchData = {
            ...syncList,
            ...payload,
        };
        SyncStorageUtil.updateSyncData(browserStoreContinueWatchData, uid);
    // yield put(packageFetchSyncContent());
    }
    catch (e) {
        console.log(e);
    }
}


export function* removeContentFromSync({ contentDetails, nextEpisodeDetails, lastWatchedPosition, manuallyRemovedFromCW }) {
    const syncList = yield select(getSyncContentFromReducer);
    const { uid } = yield select(getAuthConfigFromReducer);
    let { toBeRemovedFromRecent, toBeAddedFromRecent } = syncList;
    const { continueWatchingRail, continueWatchingData } = syncList;
    let addNextEpisode = false;

    const lastUpdatedTimeStamp = new Date().getTime();
    const contentId = getParentContentId(contentDetails);
    // delete season and tv show id's if it is not a movie
    if (lodashGet(continueWatchingData[contentId], 'contentResponse.programType', '') === PROGRAM_TYPES.EPISODE) {
        if (!nextEpisodeDetails) {
            const tvShowId = lodashGet(continueWatchingData[contentId], 'contentResponse.seriesId', '');
            const toBeRemovedFromRecentChanges = removeAllEpisodesFromShow({
                tvShowId,
                continueWatchingData,
                manuallyRemovedFromCW,
            });
            toBeRemovedFromRecent = {
                ...toBeRemovedFromRecent,
                ...toBeRemovedFromRecentChanges,
            };
            delete continueWatchingRail[tvShowId];
        }
        else {
            const continueWatchingContentData = continueWatchingData[contentId];
            toBeRemovedFromRecent = {
                ...toBeRemovedFromRecent,
                [contentId]: {
                    contentId,
                    lastWatchedPosition: continueWatchingContentData?.lastWatchedPosition ? continueWatchingContentData?.lastWatchedPosition : continueWatchingContentData?.contentResponse?.durSec,
                    lastUpdatedTimeStamp,
                    ...(manuallyRemovedFromCW && { manuallyRemovedFromCW: true }),
                },
            };
            delete continueWatchingRail[contentId];
            delete continueWatchingData[contentId];
            delete toBeAddedFromRecent[contentId];
            const nextEpisodeId = lodashGet(nextEpisodeDetails, 'details.refId', '');
            toBeAddedFromRecent = {
                ...toBeAddedFromRecent,
                [nextEpisodeId]: {
                    contentId: nextEpisodeId,
                    lastWatchedPosition: 0,
                    lastUpdatedTimeStamp,
                },
            };
            addNextEpisode = true;
        }
    }
    else {
        toBeRemovedFromRecent = {
            ...toBeRemovedFromRecent,
            [contentId]: {
                contentId,
                lastWatchedPosition: lastWatchedPosition ? lastWatchedPosition : contentDetails?.durSec,
                lastUpdatedTimeStamp,
                ...(manuallyRemovedFromCW && { manuallyRemovedFromCW: true }),
            },
        };
        delete continueWatchingRail[contentId];
        delete continueWatchingData[contentId];
    }
    // delete continueWatchingData[contentId];
    const payload = {
        continueWatchingData: { ...continueWatchingData },
        continueWatchingRail: { ...continueWatchingRail },
        toBeRemovedFromRecent,
        toBeAddedFromRecent,
    };
    yield put(syncContentCalculatedOutput(payload));
    const browserStoreContinueWatchData = {
        ...syncList,
        ...payload,
    };
    // Use below code to test for cases where 0 is getting passed in toBeRemovedFromRecent
    // Ideally BE requires for content to be removed should not have 0 as value of lastWatchedPosition
    // const zeroItem = Object.keys(toBeRemovedFromRecent).filter(item => {
    //     const k = toBeRemovedFromRecent[item];
    //     if (k.lastWatchedPosition === 0) {
    //         return true;
    //     }
    // });
    // console.log("kkk",zeroItem, toBeRemovedFromRecent);
    // if (zeroItem.length) {
    //     debugger;
    //     console.log(zeroItem);
    // }
    SyncStorageUtil.updateSyncData(browserStoreContinueWatchData, uid);
    // yield put(packageFetchSyncContent());
    if (addNextEpisode) {
        yield call(syncContentSaga, {
            forceSync: true,
            isDelay: false,
            playbackForced: false,
        });
    }
}

export function* addAndSyncContent({
    contentDetails,
    lastWatchedPosition,
    playSessionId,
    playDuration,
    changedAudio,
}) {
    yield call(addContentToSync, {
        contentDetails,
        lastWatchedPosition,
        playSessionId,
        playDuration,
        changedAudio,
    });
    yield call(syncContentSaga, {
        forceSync: true,
        isDelay: false,
        playbackForced: false,
    });
}

export function* addContentToWatchList({ contentDetails, watchlistMeta }) {
    const syncList = yield select(getSyncContentFromReducer);
    const { uid } = yield select(getAuthConfigFromReducer);
    const { id: contentId } = contentDetails;
    let { watchList, addToWatchList,  } = syncList;
    const { langId = '', subtitle = '',  } = syncList;
    const lastUpdatedTimeStamp = new Date().getTime();

    watchList = {
        ...watchList,
        [contentId]: {
            contentResponse: { ...contentDetails },
            lastUpdatedTimeStamp,
            langId,
            subtitle,
            ...(watchlistMeta ? watchlistMeta: {}),
        },
    };
    addToWatchList = {
        ...addToWatchList,
        [contentId]: {
            contentId,
            lastUpdatedTimeStamp,
            lastWatchedPosition: 0,
            langId,
            subtitle,
            ...(watchlistMeta ? watchlistMeta: {}),
        },
    };

    const payload = {
        watchList,
        addToWatchList,
    };
    const browserStoreContinueWatchData = {
        ...syncList,
        ...payload,
    };
    SyncStorageUtil.updateSyncData(browserStoreContinueWatchData, uid);
    yield put(syncContentCalculatedOutput(payload));
}

// export function* addContentToPYWWatchList(action) {
//     const {contentDetails = {}} = action?.payload || {};
//     const syncList = yield select(getSyncContentFromReducer);
//     const { uid } = yield select(getAuthConfigFromReducer);
//     const { id: contentId } = contentDetails;
//     let { pywWatchList, pywAddToWatchList } = syncList;
//     const { langId = '', subtitle = '',  } = syncList;
//     const lastUpdatedTimeStamp = new Date().getTime();

//     pywWatchList = {
//         ...pywWatchList,
//         [contentId]: {
//             contentResponse: { ...contentDetails },
//             lastUpdatedTimeStamp,
//             langId,
//             subtitle,
//             isFromPYW: true,
//         },
//     }

//     pywAddToWatchList = {
//         ...pywAddToWatchList,
//         [contentId]: {
//             contentId,
//             lastUpdatedTimeStamp,
//             lastWatchedPosition: 0,
//             langId,
//             subtitle,
//             isFromPYW: true,
//         },
//     };

//     const payload = {
//         pywWatchList,
//         pywAddToWatchList,
//     };
//     const browserStoreContinueWatchData = {
//         ...syncList,
//         ...payload,
//     };
//     SyncStorageUtil.updateSyncData(browserStoreContinueWatchData, uid);
//     yield put(syncContentCalculatedOutput(payload));
// }

export function* removeContentFromWatchList({ contentDetails, watchlistMeta }) {
    const { id: contentId } = contentDetails;
    const syncList = yield select(getSyncContentFromReducer);
    const { uid } = yield select(getAuthConfigFromReducer);
    const { watchList } = syncList;
    let { removeFromWatchList } = syncList;
    const lastUpdatedTimeStamp = new Date().getTime();

    delete watchList[contentId];
    removeFromWatchList = {
        ...removeFromWatchList,
        [contentId]: {
            contentId,
            lastUpdatedTimeStamp,
            lastWatchedPosition: 0,
            ...(watchlistMeta ? watchlistMeta: {}),
        },
    };
    const payload = {
        watchList: { ...watchList },
        removeFromWatchList,
    };
    const browserStoreContinueWatchData = {
        ...syncList,
        ...payload,
    };
    SyncStorageUtil.updateSyncData(browserStoreContinueWatchData, uid);
    yield put(syncContentCalculatedOutput(payload));
}

// export function* removeContentFromPYWWatchList(action) {
//     const {contentDetails = {}} = action?.payload || {};

//     const { id: contentId } = contentDetails;
//     const syncList = yield select(getSyncContentFromReducer);
//     const { uid } = yield select(getAuthConfigFromReducer);
//     const { pywWatchList } = syncList;
//     let { removeFromPywWatchList } = syncList;
//     const lastUpdatedTimeStamp = new Date().getTime();

//     delete pywWatchList[contentId];
//     removeFromPywWatchList = {
//         ...removeFromPywWatchList,
//         [contentId]: {
//             contentId,
//             lastUpdatedTimeStamp,
//             lastWatchedPosition: 0,
//             isFromPYW: true,
//         },
//     };
//     const payload = {
//         pywWatchList: { ...pywWatchList },
//         removeFromPywWatchList,
//     };
//     const browserStoreContinueWatchData = {
//         ...syncList,
//         ...payload,
//     };
//     SyncStorageUtil.updateSyncData(browserStoreContinueWatchData, uid);
//     yield put(syncContentCalculatedOutput(payload));
// }

export function* syncContentClearSaga({ clearLocal = false }) {
    const { uid } = yield select(getAuthConfigFromReducer);
    if (clearLocal) {
        SyncStorageUtil.clearSyncData(uid);
    }
    yield put(syncContentCleared());
}

export default [
    takeFirst(SYNC_ACTIONS.SYNC_CONTENT, syncContentSaga),
    takeFirst(SYNC_ACTIONS.SYNC_CONTENT_ADD, addContentToSync),
    takeFirst(SYNC_ACTIONS.SYNC_CONTENT_REMOVE, removeContentFromSync),
    takeFirst(SYNC_ACTIONS.SYNC_CONTENT_CLEAR, syncContentClearSaga),
    takeFirst(SYNC_ACTIONS.WATCHLIST_CONTENT_ADD, addContentToWatchList),
    takeFirst(SYNC_ACTIONS.WATCHLIST_CONTENT_REMOVE, removeContentFromWatchList),
    takeFirst(SYNC_ACTIONS.ADD_AND_SYNC_CONTENT, addAndSyncContent),
    // takeFirst(SYNC_ACTIONS.PYW_WATCHLIST_CONTENT_ADD, addContentToPYWWatchList),
    // takeFirst(SYNC_ACTIONS.PYW_WATCHLIST_CONTENT_REMOVE, removeContentFromPYWWatchList),
];
