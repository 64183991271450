export const PlanActions = {
    PLAN_CONFIG_SUCCESS: 'PLAN_CONFIG_SUCCESS',
    PLAN_CONFIG_INIT: 'PLAN_CONFIG_INIT',
    PLAN_CONFIG_FAILURE: 'PLAN_CONFIG_FAILURE',
    PLAN_CONFIG_CLEAR_ALL: 'PLAN_CONFIG_CLEAR_ALL',
};

export const planConfigInit = () => (
    {
        type: PlanActions.PLAN_CONFIG_INIT,
    });

export const planConfigSuccess = payload => ({
    type: PlanActions.PLAN_CONFIG_SUCCESS,
    ...payload,
});

export const planConfigClearAll = () => ({
    type: PlanActions.PLAN_CONFIG_CLEAR_ALL,
});

export const planConfigFailure = payload => ({
    type: PlanActions.PLAN_CONFIG_FAILURE,
    ...payload,
});
