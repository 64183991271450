export const BreadCrumbsActions = {
    UPDATE_BREADCRUMBS: 'UPDATE_BREADCRUMBS',
    UPDATE_BREADCRUMBS_COMPLETED: 'UPDATE_BREADCRUMBS_COMPLETED',
};

export const getPageBreadCrumbs = payload => ({
    type: BreadCrumbsActions.UPDATE_BREADCRUMBS,
    ...payload,
});

export const updatePageBreadCrumbs = payload => ({
    type: BreadCrumbsActions.UPDATE_BREADCRUMBS_COMPLETED,
    payload,
});

export default {
    getPageBreadCrumbs,
    updatePageBreadCrumbs,
};
