import PlansAndSubscriptionAction from './PlansAndSubscriptionAction';

const initialState = {
    svodPlans: {},
    showAllPlansTiles: false,
    showMorePlansPage: false,
    nonTrimmedPlanKey: '',
    partnerActivationData: {},
    svodReset: false,
};

const PlansAndSubscriptionReducer = (state = initialState, action = {}) => {
    let changes = {};
    switch (action.type) {
        case PlansAndSubscriptionAction.SVOD_ALL_PLANS_SUCCESS:
            changes = {
                svodPlans: {
                    ...state.svodPlans,
                    ...action.payload,
                    fetching: false,
                    svodError: false,
                },
            };
            break;
        case PlansAndSubscriptionAction.SVOD_ALL_PLANS_ERROR:
            changes = {
                svodPlans: {
                    ...state.svodPlans,
                    ...action.payload,
                    fetching: false,
                    svodError: true,
                },
            };
            break;
        case PlansAndSubscriptionAction.SVOD_ALL_PLANS: {
            const { dataId } = action?.payload || {};
            changes = {
                svodPlans: {
                    ...state.svodPlans,
                    [dataId]: {},
                    fetching: true,
                    svodError: false,
                },
            };
            break;
        }
        case PlansAndSubscriptionAction.ONEHUB_SHOW_ALL_PLANS_TILES:
            changes = {
                showAllPlansTiles: !!action.payload,
            };
            break;
        case PlansAndSubscriptionAction.ONEHUB_SHOW_MORE_PLANS_PAGE:
            changes = {
                showMorePlansPage: !!action.payload,
            };
            break;
        case PlansAndSubscriptionAction.RESET_ONEHUB_PLANS_PAGE_FLAGS:
            changes = {
                showAllPlansTiles: false,
                showMorePlansPage: false,
            };
            break;
        case PlansAndSubscriptionAction.SET_PARTNER_ACTIVATION_DATA: {
            const { referenceId, serviceId } = action?.payload || {};
            changes = {
                partnerActivationData: {
                    ...state.partnerActivationData,
                    [`${referenceId}_${serviceId}`]: action?.payload,
                },
            };
            break;
        }
        case PlansAndSubscriptionAction.SET_PARTNER_ACTIVATION_ERROR: {
            const { referenceId, serviceId } = action?.payload || {};
            changes = {
                partnerActivationData: {
                    ...state.partnerActivationData,
                    [`${referenceId}_${serviceId}`]: action?.payload,
                },
            };
            break;
        }

        case PlansAndSubscriptionAction.PURGE_ACTIVATION_DATA: {
            changes = {
                partnerActivationData: {},
            };
            break;
        }
        case PlansAndSubscriptionAction.PURGE_SVOD_SANS_ACTIVATION: {
            const { partnerActivationData = {} } = state;
            changes = {
                ...initialState,
                partnerActivationData,
            };
            break;
        }
        case PlansAndSubscriptionAction.PURGE_SVOD_ALL_PLANS: {
            return initialState;
        }
        case PlansAndSubscriptionAction.ONEHUB_META_INFO:
            changes = {
                ...action.payload,
            };
            break;
        case PlansAndSubscriptionAction.SVOD_RESET:
            changes = {
                svodReset: !state.svodReset,
            };
            break;
        default: return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default PlansAndSubscriptionReducer;
