import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import DownloadAirtelNudge from '@airtel-tv/ui-lib/atoms/nudges/DownloadAirtelNudge';

import NavigationContainer from '../../navigation/NavigationContainer';
import { DEEPLINK_INGRESS_KEYS } from '@airtel-tv/constants';
// import NotifyContainer from '../../notify/NotifyContainer';
// import FooterComponent from '../../footer/FooterComponent';
// // import BreadCrumbsContainer from '../../breadcrumbs/containers/BreadCrumbsContainer';
// import DownloadAppComponent from  '../../footer/DownloadAppComponent';
// import ModalPopupContainer from '../../modal-popup/ModalPopupContainer';
// import StaticFooterComponent from '../../footer/components/StaticFooterComponent';
const LoaderComponent = () => <div />;
const WebviewDebugger = Loadable({
    loader: () => import('./WebviewDebugger' /* webpackChunkName: "WebviewDebugger" */),
    loading: LoaderComponent,
});
// const NavigationContainer = Loadable({
//     loader: () => import('../../navigation/NavigationContainer' /* webpackChunkName: "NavigationContainer" */),
//     loading: LoaderComponent,
// });
const BreadCrumbsContainer = Loadable({
    loader: () => import('../../breadcrumbs/containers/BreadCrumbsContainer' /* webpackChunkName: "BreadCrumbsContainer" */),
    loading: LoaderComponent,
});
const NotifyContainer = Loadable({
    loader: () => import('../../notify/NotifyContainer' /* webpackChunkName: "NotifyContainer" */),
    loading: LoaderComponent,
});
const FooterComponent = Loadable({
    loader: () => import('../../footer/FooterComponent' /* webpackChunkName: "FooterComponent" */),
    loading: LoaderComponent,
});
const ModalPopupContainer = Loadable({
    loader: () => import('../../modal-popup/ModalPopupContainer' /* webpackChunkName: "ModalPopupContainer" */),
    loading: LoaderComponent,
});
const StaticFooterComponent = Loadable({
    loader: () => import('../../footer/components/StaticFooterComponent' /* webpackChunkName: "StaticFooterComponent" */),
    loading: LoaderComponent,
});
const DownloadAppComponent = Loadable({
    loader: () => import('../../footer/DownloadAppComponent' /* webpackChunkName: "DownloadAppComponent" */),
    loading: LoaderComponent,
});

function DefaultView(props) {
    const {
        route, history, location, renderElement, downloadApp, appConfigScopedWebview, pageId
    } = props;
    const deviceUtil = DeviceUtil.getDeviceUtil();
    const isScopedWebview = deviceUtil.isScopedWebview(appConfigScopedWebview);

    const isMobile = deviceUtil.isMobile();
    const scopedView = (
        <React.Fragment>
            <div className="scoped-webview-content-wrapper">
                {renderElement}
            </div>
            <WebviewDebugger />
            <NotifyContainer route={route[0].children} />
            <ModalPopupContainer />
            <DownloadAirtelNudge
                ingressName={DEEPLINK_INGRESS_KEYS.BOTTOM_NUDGE}
                pageId={pageId}
                showLottie
            />
            {/* <NoInternetNudge /> */}
        </React.Fragment>
    );


    return !isScopedWebview ? (
        <React.Fragment>
            {!isMobile ? (
                <a
                    href="javascript:void(0)"
                    className="skip-content-link"
                    key="skip-content-link"
                    onClick={function (ev) {
                        ev.target.blur();
                        document.getElementById('airtel-content')?.scrollIntoView();
                        ev.preventDefault();
                    }}
                >
                    Skip to Content
                </a>
            ) : null }

            <NavigationContainer
                route={route[0].children}
                history={history}
                location={location}
            />


            {!isMobile ? (
                <div
                    id="airtel-content"
                    key="airtel-content"
                />
            ) : null}

            {renderElement}

            {downloadApp
                && <DownloadAppComponent />
            }

            <>
                <BreadCrumbsContainer />
                <FooterComponent />
                {/* <footer className="static-footer"> */}
                <StaticFooterComponent />
                {/* </footer> */}
            </>


            <NotifyContainer route={route[0].children} />
            <ModalPopupContainer />
        </React.Fragment>
    ) : scopedView;
}

function mapStateToProps(state) {
    const {
        appConfig: {
            isScopedWebview: appConfigScopedWebview,
            componentVisibility: { downloadApp = true } = {},
        },
    } = state;
    return {
        appConfigScopedWebview,
        downloadApp,
    };
}

DefaultView.propTypes = {
    renderElement: PropTypes.node.isRequired,
    route: PropTypes.objectOf(PropTypes.object).isRequired,
    history: PropTypes.objectOf(PropTypes.object).isRequired,
    location: PropTypes.objectOf(PropTypes.object).isRequired,
    downloadApp: PropTypes.bool.isRequired,
    deviceUtil: PropTypes.objectOf(PropTypes.object).isRequired,
    isScopedWebview: PropTypes.bool.isRequired,
};

export default withRouter(connect(mapStateToProps, null)(DefaultView));
