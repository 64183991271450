import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { CryptoUtil, LocationUtil } from '@airtel-tv/utils';
import { AppEnv } from '@airtel-tv/utils/GetEnv';
import {
    getAppId, getBuildNumber, getDeviceType, removeFalsy,
} from '@airtel-tv/utils/GlobalUtil';

const env = AppEnv.getEnv();
const { base, paths } = env.endpoints.userApi;

export const getSvodAllPlans = async ({
    deviceUtil,
    preferredCp,
    isSubscribed = false,
    isMobility = false,
}) => {
    let queryParams = {
        appId: getAppId(),
        dt: getDeviceType(),
        os: deviceUtil.getBrowserType(),
        bn: getBuildNumber(),
        ...(preferredCp ? { preferredCp } : {}),
    };

    if (isMobility) {
        const appIdFromLocal = AuthenticationUtil.getAppId();
        const deviceTypeFromLocal = deviceUtil?.getDeviceTypeFromLocal();
        const os = deviceUtil?.getOsFromLocal();
        const buildNo = deviceUtil?.getBuildNumberFromLocal();

        queryParams = {
            ...queryParams,
            appId: appIdFromLocal || queryParams.appId,
            dt: deviceTypeFromLocal || queryParams.dt,
            os: os || queryParams.os,
            bn: buildNo || queryParams.bn,
        };
    }
    const url = `${paths.svodAllPlans}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const xAtvDid = isMobility ? deviceUtil.getXAtvDidFromLocal() || deviceUtil.getXAtvDid() : deviceUtil.getXAtvDid();
    const header = {
        'x-atv-did': xAtvDid,
        'x-atv-utkn': utkn,
    };

    const demoUrl = isSubscribed ? 'https://demo4602297.mockable.io/plans/subscribed' : 'https://demo4602297.mockable.io/plans/unsubscribed';

    //   return RequestUtil.GET({
    //       url: demoUrl,
    //   });

    return RequestUtil.GET({
        url: base + url,
        headers: removeFalsy(header),
    });
};

export const activateOneHubPartnerService = async ({
    data,
    deviceUtil,
}) => {
    // const { base, paths } = env.endpoints.userApi;
    const method = 'POST';
    const queryParams = {
        appId: getAppId(),
        serviceId: data.serviceId,
        referenceId: data.referenceId,
    };

    //const url = `https://demo4602297.mockable.io/partner/activate?${LocationUtil.objectToQueryParams(queryParams)}`;
    const url = `${paths.svodActivate}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const utknData = {
        urlBody: `${method}/${url}${JSON.stringify(data)}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(), // TODO: uncomment after anurag check
        'x-atv-platform': AuthenticationUtil.getOS(),
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        method,
        url: `${base}${url}`,
        //url,
        data,
        headers: removeFalsy(headers),
    };
    return RequestUtil.POST(authOptions);
};


export const svodPartnerStatusService = ({
    data,
    deviceUtil,
}) => {
    const method = 'POST';
    const queryParams = {
        appId: getAppId(),
        serviceId: data.serviceId,
        referenceId: data.referenceId,
        cp: data.cpId,
    };

    const url = `${paths.svodPartnerStatus}?${LocationUtil.objectToQueryParams(queryParams)}`;

    const postBodyData = {};

    const utknData = {
        urlBody: `${method}/${url}${JSON.stringify(postBodyData)}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(), // TODO: uncomment after anurag check
        'x-atv-platform': AuthenticationUtil.getOS(),
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        method,
        url: `${base}${url}`,
        data: postBodyData,
        headers: removeFalsy(headers),
    };
    return RequestUtil.POST(authOptions);
};