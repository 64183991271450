export const getUserSubscriptionById = ({ userConfig }) => {
    const { subscriptions } = userConfig;
    const subscriptionDataById = {};
    if (subscriptions) {
        subscriptions.forEach((channel) => {
            subscriptionDataById[channel.cp] = channel;
        });
    }

    return subscriptionDataById;
};

export default {
    getUserSubscriptionById,
};
