const AddToBillAction = {
    GET_PAYMENT_MODES: 'GET_PAYMENT_MODES',
    GET_PAYMENT_MODES_SUCCESS: 'GET_PAYMENT_MODES_SUCCESS',
    GET_BILL_ACCOUNT_DETAILS: 'GET_BILL_ACCOUNT_DETAILS',
    GET_BILL_ACCOUNT_DETAILS_SUCCESS: 'GET_BILL_ACCOUNT_DETAILS_SUCCESS',
    BILL_RECHARGE: 'BILL_RECHARGE',
    BILL_RECHARGE_RESPONSE: 'BILL_RECHARGE_RESPONSE',
    GET_RECHARGE_STATUS: 'GET_RECHARGE_STATUS',
    CLEAR_ADD_TO_BILL_DETAILS: 'CLEAR_ADD_TO_BILL_DETAILS',
    SET_BILL_RECHARGE_STATUS_RESPONSE: 'SET_BILL_RECHARGE_STATUS_RESPONSE',
    IS_REACHARGE_IN_PROGRESS: 'IS_REACHARGE_IN_PROGRESS',
};

export default AddToBillAction;

export const getBillPaymentModes = payload => ({
    type: AddToBillAction.GET_PAYMENT_MODES,
    ...payload,
});

export const getBillPaymentModesSuccess = payload => ({
    type: AddToBillAction.GET_PAYMENT_MODES_SUCCESS,
    payload,
});

export const getBillAccountDetails = payload => ({
    type: AddToBillAction.GET_BILL_ACCOUNT_DETAILS,
    ...payload,
});


export const getBillAccountDetailsSuccess = payload => ({
    type: AddToBillAction.GET_BILL_ACCOUNT_DETAILS_SUCCESS,
    payload,
});

export const billRecharge = payload => ({
    type: AddToBillAction.BILL_RECHARGE,
    ...payload,
});

export const setBillRechargeResponse = payload => ({
    type: AddToBillAction.BILL_RECHARGE_RESPONSE,
    payload,
});

export const setBillPaymentStatusResponse = payload => ({
    type: AddToBillAction.SET_BILL_RECHARGE_STATUS_RESPONSE,
    payload,
});

export const getBillStatus = payload => ({
    type: AddToBillAction.GET_RECHARGE_STATUS,
    ...payload,
});

export const clearAddToBillDetails = () => ({
    type: AddToBillAction.CLEAR_ADD_TO_BILL_DETAILS,
});

export const rechargeInProgress = payload => ({
    type: AddToBillAction.IS_REACHARGE_IN_PROGRESS,
    payload,
});
