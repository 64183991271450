import { removeFromArray } from '@airtel-tv/utils/GlobalUtil';
import NotifyActions from './NotifyActions';

const initialState = JSON.parse(`{
    "toasts": [],
    "showPreferredPartnerTooltip": null,
    "actionModalData": null,
    "introductionData": null,
    "errorPageData": null,
    "showLoadingBar": false,
    "headMetaData": null,
    "selectedFeaturedNavigation": null,
    "selectedNav": null,
    "headerRouteData": {
        "currentRoute": null
    },
    "redirectUrl": null,
    "overlay": false,
    "showSearchNav": false,
    "showPlayback": false,
    "hideRenewBanner": false,
    "playingOnBanner": false,
    "railTileMap" :{},
    "showNonAirtel": {
        "show": false
    },
    "showForwardButton": true,
    "removeFromCW": false,
    "showPillsSidebar": {},
    "cursorShowing": false,
    "isEpgPageVisible": false,
    "isErrorPageShown": false,
    "isInternetConnected": true,
    "appTimers": {}
}`);

const NotifyReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        // TOASTR
        case NotifyActions.ADD_TOAST:
            changes = {
                ...state,
                toasts: [
                    ...state.toasts,
                    action.toast,
                ],
            };
            break;
        case NotifyActions.REMOVE_TOAST:
            // remove toasts
            removeFromArray(state.toasts, action.toast);
            changes = {
                ...state,
                toasts: [
                    ...state.toasts,
                ],
            };
            break;


        // MODALS
        case NotifyActions.SHOW_ACTION_MODAL:
            changes = {
                ...state,
                actionModalData: action.actionModalData,
            };
            break;
        case NotifyActions.HIDE_ACTION_MODAL:
            changes = {
                ...state,
                actionModalData: null,
            };
            break;


        // INTRODUCTION
        case NotifyActions.SHOW_INTRODUCTION_MODAL:
            changes = {
                ...state,
                introductionData: action.introductionData,
            };
            break;
        case NotifyActions.HIDE_INTRODUCTION_MODAL:
            changes = {
                ...state,
                introductionData: null,
            };
            break;

        // ERROR PAGE
        case NotifyActions.SHOW_ERROR_PAGE:
            changes = {
                ...state,
                errorPageData: action.errorPageData,
            };
            break;
        case NotifyActions.ERROR_PAGE_SHOWN:
            changes = {
                ...state,
                errorPageData: null,
            };
            break;

        // LOADING BAR
        case NotifyActions.SHOW_LOADING_BAR:
            changes = {
                showLoadingBar: action.val,
            };
            break;

        case NotifyActions.HIDE_LOADING_BAR:
            changes = {
                showLoadingBar: action.val,
            };
            break;

        // PAGE META DATA
        case NotifyActions.META_DATA_CHANGE:
            changes = {
                headMetaData: action.headMetaData,
            };
            break;
        case NotifyActions.ACTIVE_NAVBAR_SELECT:
            changes = {
                selectedFeaturedNavigation: action.navLinkId,
                selectedNav: action.bottomNavId,
            };
            break;

        // HEADER WITH ROUTING
        case NotifyActions.HEADER_ROUTING_ADD:
            changes = {
                headerRouteData: {
                    currentRoute: action.headerRouteData,
                },
            };
            break;

        case NotifyActions.HEADER_ROUTING_REMOVE:
            changes = {
                headerRouteData: {
                    currentRoute: null,
                },
            };
            break;

        case NotifyActions.REDIRECTION_INIT:
            changes = {
                redirectUrl: action.redirectUrl,
            };
            break;

        case NotifyActions.REDIRECTION_DONE:
            changes = {
                redirectUrl: null,
            };
            break;

        case NotifyActions.SHOW_OVERLAY:
            changes = {
                overlay: true,
            };
            break;

        case NotifyActions.HIDE_OVERLAY:
            changes = {
                overlay: false,
            };
            break;

        case NotifyActions.SHOW_PREFERRED_PARTNER_TOOLTIP:
            changes = {
                showPreferredPartnerTooltip: true,
            };
            break;

        case NotifyActions.HIDE_PREFERRED_PARTNER_TOOLTIP:
            changes = {
                showPreferredPartnerTooltip: false,
            };
            break;

        case NotifyActions.API_FETCH_COMPLETE:
            changes = {
                isFetchingDone: action.isFetchingDone,
            };
            break;

        case NotifyActions.UPDATE_KEYBOARD_CONTROLS:
            changes = {
                enableKeyboardControls: action.enableKeyboardControls,
            };
            break;

        case NotifyActions.UPDATE_NEXT_CONTENT_PLAYED_COUNT:
            changes = {
                nextPlayedContentCount: action.nextPlayedContentCount,
            };
            break;

        case NotifyActions.UPDATE_LAST_CONTENT_DETAIL_PAGE_VISITED:
            changes = {
                LastcontentDetailPathname: action.LastcontentDetailPathname,
            };
            break;

        case NotifyActions.LAST_RAIL_TILE_MAPPING:
            changes = {
                railTileMap: {
                    ...state.railTileMap,
                    [action.railPosition]: action.tilePosition,
                },
            };
            break;

        case NotifyActions.LAST_RAIL_TILE_CLEAR:
            changes = {
                railTileMap: {
                },
            };
            break;

        case NotifyActions.TOGGLE_PLAYBACK:
            changes = {
                showPlayback: action.payload,
            };
            break;

        case NotifyActions.TOGGLE_NON_AIRTEL:
            changes = {
                showNonAirtel: action.payload,
            };

            break;

        case NotifyActions.TOGGLE_FORWARD_BUTTON_VISIBLE:
            changes = {
                showForwardButton: action.payload,
            };

            break;
        case NotifyActions.TOGGLE_REMOVE_CW:
            changes = {
                removeFromCW: action.payload.removeFromCW,
            };
            break;


        case NotifyActions.SHOW_PLAYBACK:
            changes = {
                showPlayback: true,
            };
            break;

        case NotifyActions.HIDE_PLAYBACK:
            changes = {
                showPlayback: false,
            };
            break;
        case NotifyActions.SHOW_BANNER_ON_PLAY:
            changes = {
                playingOnBanner: false,
            };
            break;

        case NotifyActions.HIDE_BANNER_ON_PLAY:
            changes = {
                playingOnBanner: true,
            };
            break;

        case NotifyActions.HIDE_RENEW_BANNER:
            changes = {
                hideRenewBanner: true,
            };
            break;
        case NotifyActions.SHOW_RENEW_BANNER:
            changes = {
                hideRenewBanner: false,
            };
            break;
        case NotifyActions.ADD_TRAILER_DATA:
            changes = {
                trailerSync: {
                    ...state.trailerSync,
                    ...action.payload,
                },
            };
            break;
        case NotifyActions.REMOVE_TRAILER_DATA: {
            const trailerRefId = action.payload;
            const { trailerSync } = state;
            if (trailerRefId !== '') {
                delete trailerSync[trailerRefId];
            }
            changes = {
                trailerSync: {
                    ...trailerSync,
                },
            };
            break;
        }
        case NotifyActions.EPG_VIEW_STATUS: {
            changes = {
                isEpgPageVisible: action?.isEpgPageVisible,
            };
            break;
        }
        case NotifyActions.SHOW_PILLS_SIDE_BAR:
            changes = {
                showPillsSidebar: action.payload,
            };
            break;
        case NotifyActions.TOGGLE_CURSOR_SHOWING:
            changes = {
                cursorShowing: action.payload,
            };
            break;
        case NotifyActions.IS_ERROR_PAGE_SHOWN:
            changes = {
                isErrorPageShown: action.isErrorPageShown,
            };
            break;
        case NotifyActions.IS_INTERNET_CONNECTED:
            changes = {
                isInternetConnected: action.isInternetConnected,
            };
            break;
        case NotifyActions.SET_APP_TIMER_STATE:
            changes = {
                appTimers: {
                    ...state.appTimers,
                    ...action.payload,
                },
            };
            break;
        case NotifyActions.RESET_APP_TIMER_STATE:
            changes = {
                appTimers: {
                },
            };
            break;
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default NotifyReducer;
