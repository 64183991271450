import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { contentNotFoundGaEvent, contentOtherGaEvent } from '../../utilities/GaEvents';

export const ErrorAnalyticsHelper = ({
    errorCode,
    contentId,
    programTypeTitle,
}) => {
    // content not found
    if ([
        ERROR_CODES.ATV012,
        ERROR_CODES.ATV014,
    ].indexOf(errorCode) > -1) {
        contentNotFoundGaEvent({
            contentId,
            programTypeTitle,
        });
    }
    else {
        contentOtherGaEvent({
            contentId,
            programTypeTitle,
        });
    }
};

export default { ErrorAnalyticsHelper };
