export const EpgAction = {
    FETCH_EPG_DETAILS: 'FETCH_EPG_DETAILS',
    FETCH_EPG_DETAILS_SUCCESS: 'FETCH_EPG_DETAILS_SUCCESS',
    FETCH_EPG_DETAILS_ERROR: 'FETCH_EPG_DETAILS_ERROR',
};

export const fetchEpgChannelDetails = payload => ({
    type: EpgAction.FETCH_EPG_DETAILS,
    ...payload,
});

export const fetchEpgChannelDetailsSuccess = payload => ({
    type: EpgAction.FETCH_EPG_DETAILS_SUCCESS,
    ...payload,
});

export const fetchEpgChannelDetailsError = payload => ({
    type: EpgAction.FETCH_EPG_DETAILS_ERROR,
    ...payload,
});

export default {
    EpgAction,
};
