// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media screen and (max-width:767px),(max-height:500px)and (max-width:990px){.navbar-bottom .nav-bottom-list a{display:flex;width:88px;height:56px;justify-content:center;align-items:center}.navbar-bottom .nav-bottom-list img{width:auto;height:auto}.navbar-bottom .nav-bottom-list img.active{opacity:1}.navbar-bottom img{position:relative;width:88px;height:56px;opacity:.5}.navbar-bottom .active img{opacity:1}.navbar-bottom .tool-tip-img{opacity:1;width:40px;height:40px;margin-top:12px;margin-left:20px}.navbar-bottom .tool-tip-cross{width:12px;height:12px}.navbar-bottom .text-tool-tip{font-size:1.75rem;line-height:125%;font-weight:bold;color:#fff;margin-top:auto;margin-bottom:auto;margin-left:24px;width:181px}}.tooltipholder{background-image:url(/static/bottomnav_icons/Background.svg);display:flex;transform:translatex(-36%);visibility:visible;width:90vw;height:79px;max-width:328px;background-color:#000;color:#fff;text-align:center;border-radius:6px;padding:5px 0;position:absolute;z-index:99;bottom:142%;left:50%;border:1px solid #393939;box-sizing:border-box;box-shadow:0px 0px 8px rgba(0,0,0,.5)}.tooltipholder .tool-tip-close{background-color:rgba(0,0,0,0);height:40px;width:40px;display:flex;align-items:center;justify-content:center;position:absolute;top:0px;right:0px;border:none}.tooltipholder::after{content:\"\";position:absolute;bottom:-15px;left:109px;margin-left:-5px;border-width:5px;width:30px;height:30px;background:#000;transform:rotate(45deg);border-right:1px solid #393939;border-bottom:1px solid #393939}", ""]);
// Exports
exports.locals = {
	"mobileWidth": "767px",
	"mediaLandscapeHeight": "500px",
	"mediaLandscapeWidth": "990px",
	"tabletLargeWidth": "1200px"
};
module.exports = exports;
