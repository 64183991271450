import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import lodashGet from 'lodash/get';
import { LanguageProviderUtil, LocationUtil, SubscriptionUtil } from '@airtel-tv/utils';
import { continueToPayError, redirectToPayment, subscribeClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import RoutingUtil from '../../utilities/RoutingUtil';
import { purchaseSubscriptionInit } from '../subscription-details/action/SubscriptionAction';
import { showToastr } from '../../utilities/NotifyUtil';
import { getCurrentUrl } from '../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../constants/AnalyticsConst';
import { getSid } from '../../utilities/CommonUtil';

const PaymentsLandingPage = (props) => {
    const { isAuthenticated, params } = props;
    // const params = new URLSearchParams(window.location.search);
    const planId = params?.planId || '';
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const { PLAN_NOT_ELIGIBLE = 'You are not eligible for this plan or has already subscribed.' } = LANGUAGE;
    const navigate = useNavigate();
    const allPlans = useSelector(state => state.allPlans);
    const planConfig = useSelector(state => state.planConfig);
    const userConfig = useSelector(state => state.userConfig);

    const dispatch = useDispatch();

    const continueToPayMethod = (sid, redirectionUrl, iserror, response) => {
        const circle = lodashGet(planConfig, 'userDetails.circle', '');
        const uid = lodashGet(userConfig, 'userInfo.uid', '');

        const object = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid: getSid(sid),
            customer_type: userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.DTH_SUBSCRIBE,
            source: ANALYTICS_ASSETS.DTH_SUBSCRIBE,
            pageName: ANALYTICS_ASSETS.DTH_SUBSCRIBE,
            ingressIntent: ANALYTICS_ASSETS.DTH_SUBSCRIBE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            planId,
            uid,
        };
        if (iserror) {
            continueToPayError({
                ...object,
                pageUrl: redirectionUrl,
                response: {
                    response,
                    object,
                },
            });
        }
        else {
            subscribeClickEvent({
                ...object,
                response: {
                    response,
                    object,
                },
            });
            //redirectToPayment(object);
        }
    };

    const navigateToPaymentPage = (offerPlans) => {
        const browseOffer = lodashGet(offerPlans, 'browsePlans', []);
        if (browseOffer && browseOffer.length) {
            const eligibleOffer = browseOffer.filter(
                offer => offer.plans.filter(plan => plan.planId == planId).length !== 0,
            );
            if (eligibleOffer && eligibleOffer.length) {
                const subscriptionWindowLocation = window.location.origin;
                const queryParams = LocationUtil.getQueryParams(props);
                const { ingressIntent = '', autoPayEnabled = '' } = queryParams || {};
                const channelpartner = eligibleOffer[0].cpId;
                const { successUrl, failureUrl } = SubscriptionUtil.getSubscriptionUrls({
                    plan: {
                        planId,
                        cpId: channelpartner,
                    },
                    successPage: RoutingUtil.getSubscriptionSuccessPage(),
                    failurePage: RoutingUtil.getSubscriptionFailurePage(),
                    pathname: subscriptionWindowLocation,
                });
                window.history.pushState(
                    { prevUrl: ROUTE_PATHS.DTH_SUBSCRIBE },
                    null,
                    window.location.href,
                );
                dispatch(
                    purchaseSubscriptionInit({
                        successUrl,
                        failureUrl,
                        pendingUrl: failureUrl,
                        unknownUrl: failureUrl,
                        planId,
                        ingressIntent,
                        autoPayEnabled,
                        eventMethod: continueToPayMethod,
                    }),
                );
            }
            else {
                showToastr(PLAN_NOT_ELIGIBLE);
                navigate(ROUTE_PATHS.PLANS_AND_OFFERS);
            }
        }
    };

    const isPrevUrlDth = () => window?.history?.state?.prevUrl == ROUTE_PATHS.DTH_SUBSCRIBE;

    const handleNavigation = () => {
        if (isAuthenticated && planConfig.isSuccess && userConfig) {
            const isAllPlansPopulated = allPlans.sid;
            const isBrowsePlansAvailable = allPlans && allPlans.browsePlans && allPlans.browsePlans.length;
            const { customerType } = userConfig;

            if (isPrevUrlDth()) {
                navigate(RoutingUtil.getHomePage());
                return;
            }

            if (isAllPlansPopulated) {
                window.history.pushState(
                    { prevUrl: ROUTE_PATHS.DTH_SUBSCRIBE },
                    null,
                    window.location.href,
                );
                if (isBrowsePlansAvailable) {
                    if (customerType === 'NON_AIRTEL') {
                        showToastr(PLAN_NOT_ELIGIBLE);
                        navigate(ROUTE_PATHS.PLANS_AND_OFFERS);
                    }
                    else if (!planId || !Number(planId)) {
                        navigate(ROUTE_PATHS.PLANS_AND_OFFERS);
                    }
                    else {
                        navigateToPaymentPage(allPlans);
                    }
                }
                else {
                    navigate(ROUTE_PATHS.MY_PLANS);
                }
            }
        }
    };

    useEffect(() => {
        handleNavigation();
    }, [
        isAuthenticated,
        allPlans,
        planConfig,
        userConfig,
    ]);

    return null;
};

PaymentsLandingPage.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
};

export default PaymentsLandingPage;
