export const GenreListActions = {
    FETCH_GENRE_LIST: 'FETCH_GENRE_LIST',
    FETCH_GENRE_LIST_SUCCESS: 'FETCH_GENRE_LIST_SUCCESS',
};

export default GenreListActions;

export const getGenreList = payload => ({
    type: GenreListActions.FETCH_GENRE_LIST,
    ...payload,
});

export const genreListSuccess = payload => ({
    type: GenreListActions.FETCH_GENRE_LIST_SUCCESS,
    ...payload,
});
