import { element } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { CDP_EVENT_CONST, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';
import LanguageProvider from '../../../../providers/LanguageProvider';
import { getElementById } from '@airtel-tv/utils/WindowUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import EventType from '@airtel-tv/analytics/EventType';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { removeTrailerData } from '../../../notify/NotifyActions';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';

const TrailerControlBarComponent = ({ onRef, getVideoTag, videoWrapperRef, shareButton, playTrailer, playbackDetails, contentDetails, trailersSync, removeTrailerDataDispatch }) => {
    const [playerFunc, setPlayerFunc] = useState(null);
    const [playerEvents, setEvents] = useState(null);
    const [isMute, setMute] = useState(false);
    const [isEnded, videoEnded] = useState(false);
    const [playing, setPlaying] = useState(false);
    const videoRef = getVideoTag();
    const initaillyMuted = videoRef?.volume === 0;
    // write apis in controls which need interaction with abstractPlayer component
    const { CDP_MUTE = "Mute", CDP_UNMUTE = "Unmute", RELOAD = "Reload" } = LanguageProvider();
    const controls = {
        setPlayerFunctions(playerFunctions) {
            setPlayerFunc(playerFunctions);
        },
        setControlBarEventEmitter() {

        },
        initPlayerVolume() {

        },
        setPlayerEvents(events) {
            setEvents(events);
            events?.emitter.on(PLAYER_EVENTS_LIST.FIRST_PLAY_V1, onFirstPlay);
            events?.emitter.on(PLAYER_EVENTS_LIST.ENDEND, onEnded);
            events?.emitter.on(PLAYER_EVENTS_LIST.PLAY, onPlay);
            events?.emitter.on(PLAYER_EVENTS_LIST.PAUSE, onPause);
            // events?.emitter.on(PLAYER_EVENTS_LIST.PLAYING, onPlaying);
            events?.emitter.on(PLAYER_EVENTS_LIST.LOADED, onLoaded);
            // events?.emitter.on(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, pauseOnInitialLoad);
        },
        enableControlBar() {

        },
        setPlayerQuality() {

        },
        fadeOut() {

        },
        showCenterIcon() {

        },
        hideCenterIcon() {
        },
        fadeIn() {},
        reset() {},
    };

    useEffect(() => {
        return () => {
            playerEvents?.emitter.off(PLAYER_EVENTS_LIST.FIRST_PLAY_V1, onFirstPlay);
            playerEvents?.emitter.off(PLAYER_EVENTS_LIST.ENDEND, onEnded);
            playerEvents?.emitter.off(PLAYER_EVENTS_LIST.PLAY, onPlay);
            playerEvents?.emitter.off(PLAYER_EVENTS_LIST.PAUSE, onPause);
            playerEvents?.emitter.off(PLAYER_EVENTS_LIST.PLAYING, onPlaying);
            playerEvents?.emitter.off(PLAYER_EVENTS_LIST.LOADED, onLoaded);
        }
    }, [])

    onRef(controls);

    const onVideoEnd= () => {
        const videoElem = document.getElementsByTagName("video")[0];
        if(videoElem) {
            videoElem.style.opacity = 0;
        }
        const fullimgContainer = document.getElementById("full-image-container");
        fullimgContainer.classList.add('opaque');
        fullimgContainer.style.zIndex = 'auto';
    }

    const onVideostart= () => {
        const videoElem = document.getElementsByTagName("video")[0];
        if(videoElem) {
            videoElem.style.opacity = 1;
        }
        const fullimgContainer = document.getElementById("full-image-container");
        fullimgContainer.classList.remove('opaque');
    }

    const onEnded = () =>{
        videoEnded(true);
        onVideoEnd();

        const { contentId } = contentDetails;
        if (contentId) {
            const { trailers = [] } = contentDetails && contentDetails[contentId];
            const trailerRefId = trailers?.length > 0 && trailers[0]?.refId;

            if (trailersSync && trailersSync[trailerRefId]) {
                removeTrailerDataDispatch({ payload: trailerRefId });
            }   
        }
    }

    const onPlay = () => {
        if (!playerFunc || playerFunc === null) {
            return;
        }
        setPlaying(() => !playerFunc?.paused());
        videoEnded(false);
        const ele = getElementById("trailer-volume-btn");
        if(ele){
            setTimeout(() => ele.classList.remove("opacity-zero"), 100);
        }
    }

    const onFirstPlay = () => {
        const ele = getElementById("trailer-volume-btn");
        if(ele){
            setTimeout(() => ele.classList.remove("opacity-zero"), 100);
            
        }
    }

    const onPause = () => {
        if (!playerFunc || playerFunc === null) {
            return;
        }
        setPlaying(() => !playerFunc.paused());
    }

    const onLoaded = () => {
        const { contentId } = contentDetails;
        if (contentId) {
            const { trailers } = contentDetails && contentDetails[contentId];
            const trailerRefId = trailers?.length > 0 && trailers[0]?.refId;

            if (trailersSync && trailersSync[trailerRefId]) {
                const { lastWatchedPosition = 0 } = trailersSync[trailerRefId];
                playerFunc.forward(lastWatchedPosition);
            }   
        }
    }

    const onPlaying = () => {
        if (!playerFunc || playerFunc === null) {
            return;
        }
        setPlaying(() => !playerFunc.paused())
    }  

    const handleVolume = () => {
        if (isMute) {
            playerFunc?.volumeChange(100, false, false);
            setPlayerMute(false);
        }
        else {
            playerFunc?.volumeChange(0, false, false);
            setPlayerMute(true)
        }  
    };

    const setPlayerMute = (flag) => {
        setMute(flag);
        browserStore.set(LOCAL_STORE_KEYS.TRAILER_AUTOPLAY_MUTE, flag);
    }

    const reloadEvent = () => {
        const { playableId } = contentDetails;
        const { programType, id, cpId, tvShowName, title } = contentDetails[playableId] || {};
        const meta = {
            action: CDP_EVENT_CONST.TRAILER_RELOAD,
            source_name: 'content_detail_page_'.concat(id),
            content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
            content_id: id,
            cp_name: cpId,
        }
        if (programType === PROGRAM_TYPES.EPISODE) {
            meta['episode_name'] = title;
        }
        contentDetailsEvent(meta, EventType.TRAILER_RELOAD);
    }

    const replay = () => {
        videoEnded(false);
        onVideostart();
        reloadEvent();
    }

    useEffect(() => {
        setTimeout(() => {
            playTrailer ? !isEnded && playerFunc?.play() : !isEnded && playerFunc?.pause();
        })
    }, [playTrailer, isEnded, playerFunc]);

    useEffect(() => {
        if(!playerFunc) {
            return
        }
        if(videoRef?.volume === 0) {
            setMute(videoRef?.volume === 0);
        } else {
            const userMutePref = browserStore.get(LOCAL_STORE_KEYS.TRAILER_AUTOPLAY_MUTE) === 'true';
            userMutePref ? playerFunc?.volumeChange(0, false, false) : playerFunc?.volumeChange(100, false, false);
            setMute(userMutePref);
        }
    }, [videoRef, playerFunc]);
    

    return (
        <>
            <div className="cdp-right-bottom-controls cdp-right-bottom-controls-volume ">
                { isEnded ? <AnalyticsButtonComponent
                    className="cta-cdp-banner over-flow-visible button-default-remove "
                    onClick={replay}
                    aria-label="Replay"
                >
                    <div className="tool-tip-cdp-banner">{ RELOAD }</div>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" fill="#262B33"/>
                        <path d="M24.5156 17.3486H29.0156V12.8486" stroke="#CFD3DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25.8336 25.8336C24.6798 26.9874 23.2098 27.7732 21.6095 28.0915C20.0092 28.4098 18.3504 28.2464 16.8429 27.622C15.3354 26.9976 14.0469 25.9402 13.1404 24.5835C12.2339 23.2268 11.75 21.6317 11.75 20C11.75 18.3683 12.2339 16.7733 13.1404 15.4165C14.0469 14.0598 15.3354 13.0024 16.8429 12.378C18.3504 11.7536 20.0092 11.5902 21.6095 11.9085C23.2098 12.2269 24.6798 13.0126 25.8336 14.1664L29.0156 17.3484" stroke="#CFD3DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </AnalyticsButtonComponent> : <AnalyticsButtonComponent
                    className="cta-cdp-banner over-flow-visible button-default-remove opacity-zero"
                    onClick={handleVolume}
                    id="trailer-volume-btn"
                    aria-label="Volume"
                >
                    <div className="tool-tip-cdp-banner">{isMute ? CDP_UNMUTE : CDP_MUTE }</div>
                    { isMute ? (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" fill="#262B33"/>
                        <path d="M17.8115 16.251V23.751" stroke="#CFD3DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.6875 11.75L30.3125 28.25" stroke="#CFD3DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M24.8438 22.4753V29.0014L17.8125 23.7514H13.125C12.9178 23.7514 12.7191 23.6724 12.5726 23.5317C12.4261 23.3911 12.3438 23.2003 12.3438 23.0014V17.0014C12.3438 16.8025 12.4261 16.6117 12.5726 16.4711C12.7191 16.3304 12.9178 16.2514 13.125 16.2514H17.8125L18.4788 15.7539" stroke="#CFD3DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M20.9531 13.9062L24.8441 11.001V18.0151" stroke="#CFD3DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>)
                        : (
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g filter="url(#filter0_b_0_313)">
                                <circle
                                    cx="20"
                                    cy="20"
                                    r="20"
                                    fill="#262B33"
                                />
                            </g>
                            <path
                                d="M16.7278 23.9709H11.8174C11.6004 23.9709 11.3922 23.8873 11.2387 23.7383C11.0852 23.5894 10.999 23.3874 10.999 23.1768V16.8232C10.999 16.6126 11.0852 16.4106 11.2387 16.2617C11.3922 16.1127 11.6004 16.0291 11.8174 16.0291H16.7278L24.0933 10.4697V29.5303L16.7278 23.9709Z"
                                stroke="#CFD3DC"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M16.7275 16.0293V23.9712"
                                stroke="#CFD3DC"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M28.041 17.7539C28.345 18.0489 28.5861 18.3991 28.7506 18.7845C28.9151 19.1699 28.9998 19.583 28.9998 20.0002C28.9998 20.4174 28.9151 20.8305 28.7506 21.2159C28.5861 21.6013 28.345 21.9515 28.041 22.2465"
                                stroke="#CFD3DC"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <defs>
                                <filter
                                    id="filter0_b_0_313"
                                    x="-4"
                                    y="-4"
                                    width="48"
                                    height="48"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB"
                                >
                                    <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                    />
                                    <feGaussianBlur
                                        in="BackgroundImageFix"
                                        stdDeviation="2"
                                    />
                                    <feComposite
                                        in2="SourceAlpha"
                                        operator="in"
                                        result="effect1_backgroundBlur_0_313"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_backgroundBlur_0_313"
                                        result="shape"
                                    />
                                </filter>
                            </defs>
                        </svg>
                    )}
                </AnalyticsButtonComponent>}
            </div>
        </>
    );
};

TrailerControlBarComponent.defaultProps = {
    onRef: () => null,
    onPlayerFunctionsNotFound: () => null,
    logoUrl: '',
    enableBitrateSelector: true,
    playbackSessionId: null,
    previewImageInfo: null,
    videoMeta: {
        videoTitle: '',
        subTitle: '',
    },
    nextEpisodeDetails: null,
};
const mapStateToProps = state => {
    const {
        contentDetails,
        notify
    } = state;

    return {
        contentDetails,
        trailersSync: notify.trailerSync
    };
}

export default connect(mapStateToProps, {
    // dispatchPrerollAdsPlaying: getPrerollAdsPlaying,
    // removeContentDispatch: syncContentRemove,
    // contentDetailsFetchActionDispatch: contentDetailsFetchAction
    removeTrailerDataDispatch: removeTrailerData
})(withDeviceUtil(TrailerControlBarComponent));
