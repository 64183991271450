import React, { memo } from 'react';
import { HYDRATION_TYPE } from '@airtel-tv/constants/LayoutConstants';
import PropTypes, { object, string } from 'prop-types';

const MultiListTabContainer = ({
    hydrationType,
    pageId = '',
    contentId = '',
    props = {},
    LayoutContainerWrapper,
    EpisodeRailComponent,
    focusClassPrefix,
    renderLayoutTimeout,
    underMultiListRail,
    analyticsPageId = ''
}) => {
    const { XSTREAM_LAYOUT, XSTREAM_EPISODE } = HYDRATION_TYPE;
    if (hydrationType === XSTREAM_LAYOUT) {
        const { webPageId } = props;

        return (
            <LayoutContainerWrapper
                {...props}
                ignoreQuickView
                underMultiListRail={underMultiListRail}
                pageId={pageId}
                contentId={contentId}
                webPageId={webPageId}
                containerClass="pt-md-4 pt-4"
                renderLayoutTimeout={renderLayoutTimeout}
            />
        );
    }
    if (hydrationType === XSTREAM_EPISODE) {
        return (
            <EpisodeRailComponent
                {...props}
                focusClassPrefix={focusClassPrefix}
                analyticsPageId={analyticsPageId}
            />
        );
    }
    return null;
};

MultiListTabContainer.propTypes = {
    hydrationType: PropTypes.string,
    pageId: PropTypes.string,
    contentId: PropTypes.string.isRequired,
    props: PropTypes.objectOf(object).isRequired,
    LayoutContainerWrapper: PropTypes.element.isRequired,
    EpisodeRailComponent: PropTypes.element.isRequired,
    webPageId: PropTypes.objectOf(string).isRequired,
    focusClassPrefix: PropTypes.string.isRequired,
    renderLayoutTimeout: PropTypes.number.isRequired,
};
MultiListTabContainer.defaultProps = {
    hydrationType: '',
    pageId: '',
};

export default memo(MultiListTabContainer);
