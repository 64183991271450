import React from 'react';
import lodashGet from 'lodash/get';
import { FocusManagementClassNames, FocusManagementKeyAction } from '@airtel-tv/constants/FocusManagmentConst';
import { DeviceTypeUtil, LanguageProviderUtil } from '@airtel-tv/utils';
import { BUTTON_IMAGE_TITLE, IMAGE_PATHS } from '@airtel-tv/constants';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';

const DescriptionWatchListButtonComponent = (props) => {
    const {
        inWatchList, watchListToggleButtonClick, contentDetails,
    } = props;
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    if (DeviceTypeUtil.isWeb()) {
        const toggleButton = !inWatchList
            ? (
                <button
                    type="button"
                    className="video-watchlist-icon"
                    onClick={() => {
                        watchListToggleButtonClick(contentDetails);
                    }}
                >
                    <img
                        src={IMAGE_PATHS.WATCHLIST_ICONS_ADD}
                        title={BUTTON_IMAGE_TITLE.ADD_TO_WATCHLIST}
                        alt={BUTTON_IMAGE_TITLE.ADD_TO_WATCHLIST}
                        loading="lazy"
                    />
                    <span>{`${LANGUAGE.WATCHLIST}`}</span>
                </button>
            )
            : (
                <button
                    type="button"
                    className="video-watchlist-icon"
                    onClick={() => {
                        watchListToggleButtonClick(contentDetails);
                    }}
                >
                    <img
                        src={IMAGE_PATHS.WATCHLIST_ICONS_REMOVE}
                        title={BUTTON_IMAGE_TITLE.REMOVE_FROM_WATCHLIST}
                        alt={BUTTON_IMAGE_TITLE.REMOVE_FROM_WATCHLIST}
                        loading="lazy"
                    />
                    <span>{`${LANGUAGE.WATCHLIST}`}</span>
                </button>
            );
        return toggleButton;
    }
    return (
        <AnalyticsLinkComponent
            type="button"
            scrollToTop
            scrollToWindowTopLeft={false}
            className={`${FocusManagementClassNames.FOCUSABLE}  description-cta  video-watchlist-icon web-tv-play-btn primary-cta nostyling`}
            data-sn-right={`${FocusManagementKeyAction.NO_TARGET}`}
            data-sn-down=".railPosition-0"
            onClick={(e) => {
                watchListToggleButtonClick(contentDetails);
                e.preventDefault();
            }}
            meta={{}}
        >
            <p className="play-text">
                <svg
                    className="webtv-play-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 25 24"
                    fill="none"
                >
                    <path
                        d="M14.5 10H2.5V12H14.5V10ZM14.5 6H2.5V8H14.5V6ZM18.5 14V10H16.5V14H12.5V16H16.5V20H18.5V16H22.5V14H18.5ZM2.5 16H10.5V14H2.5V16Z"
                        fill="#FFF"
                    />
                </svg>
                {`${inWatchList ? lodashGet(LANGUAGE, 'REMOVE_FROM_WATCHLIST', 'REMOVE_FROM_WATCHLIST') : lodashGet(LANGUAGE, 'ADD_TO_WATCHLIST', 'ADD_TO_WATCHLIST')}`}
            </p>
        </AnalyticsLinkComponent>
    );
};

export { DescriptionWatchListButtonComponent };
