import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import {
    SLIDE_MENU_IDENTIFIERS, DEFAULT_SUBTITLE_LANGUAGE, PLAYBACK_RENDITIONS, PLAYBACK_RATES, SLIDE_MENU_EVENTS_ID, PLAYER_EVENTS_LIST, PLAYER_RENDITIONS_FOR_PERSISTANCE, SUBTITLE_TYPE
} from '@airtel-tv/constants/PlayerConsts';
import { CDP_EVENT_CONST, ELEMENT_ID, IMAGE_PATHS, LOCAL_STORE_KEYS, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import LanguageCodesProvider from '../../../../providers/LanguageCodesProvider';
import { EVENT_SOURCE_NAME } from '@airtel-tv/constants/EventsConst';
import { clickEvent } from '@airtel-tv/analytics';
import LanguageProvider from '../../../../providers/LanguageProvider';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import EventType from '@airtel-tv/analytics/EventType';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { AuthenticationUtil } from '@airtel-tv/utils';
import { publish } from '../../../../../../tv/src/utilities/Pubsub';
import SyncStorageUtil from '@airtel-tv/sync/SyncStorageUtil';

class PlaybackSettingsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            identifier: '',
            showSelection: false,
            showBackIcon: false,
            title: '',
            defaultSelection: {},
            qualityOptions: {},
        };

        this.LANGUAGES_CODES = LanguageCodesProvider();

        this.selectedRendition = null;
        this.selectedPlaySpeed = null;
        this.selectedAudio = { value: props.lang };
        this.selectedCaption = null;

        this.LANGUAGE = LanguageProvider();
        this.deviceUtil = props.deviceUtil;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let changes = {};
        const {
            show,
            enableBitrateSelector,
        } = nextProps;

        if (show !== prevState.show
            || enableBitrateSelector !== prevState.enableBitrateSelector) {
            changes = {
                show,
                enableBitrateSelector,
            };
        }
        return changes;
    }

    componentDidMount() {
        const { renditionFromLocalStorage, enableBitrateSelector, contentDetails: { id = "", seriesId = "", programType = "" } = {}, playerFunctions } = this.props;
        const selectedSub = playerFunctions.getSelectedSubtitle();
        let { options } = this.state;
        let qualityOptions = playerFunctions.getRepresentationMeta();
            const meta = playerFunctions.getMeta();
            const lang = meta.defaultAudioLanguage;
            if (qualityOptions && qualityOptions.length > 0) {
                qualityOptions = qualityOptions.sort((a,b) => b.height - a.height);
                if (lang) {
                    qualityOptions = qualityOptions.filter((track, idx) => lang === track.language);
                }
                qualityOptions = qualityOptions.filter((track, idx) => (idx === qualityOptions.findIndex((t) => t.height == track.height)));
                options = qualityOptions.map((item, index) => ({
                    id: index + 1,
                    title: `${item.height}p`,
                    des: `${item.height}p`,
                    value: item.bandwidth,
                }));

                options.unshift({
                    id: 0,
                    title: 'Auto',
                    des: 'Auto',
                    value: 0,
                });
                this.setState({ qualityOptions: options });
            }
        if (renditionFromLocalStorage && enableBitrateSelector && options?.length) {
            const { persistAs = "", des = "" } = renditionFromLocalStorage;
            if (persistAs !== '' && persistAs === PLAYER_RENDITIONS_FOR_PERSISTANCE.HIGHEST) {
                this.changeBitrate(options[1]);
                this.selectedRendition = options[1];
            } else if (persistAs !== '' && persistAs === PLAYER_RENDITIONS_FOR_PERSISTANCE.LOWEST) {
                this.changeBitrate(options[options.length - 1]);
                this.selectedRendition = options[options.length - 1];
            } else if (options?.filter(option => option.des === des).length > 0) {
                this.changeBitrate(renditionFromLocalStorage);
                this.selectedRendition = renditionFromLocalStorage;
            } else {
                // SET TO AUTO
                this.changeBitrate(options[0]);
                this.selectedRendition = options[0];
            }
        }
        this.defaultMenu();
        const uid = AuthenticationUtil.getUid();
        if (uid !== '') {
            const syncData = SyncStorageUtil.getSyncData(uid);
            const { continueWatchingData = {} } = syncData ? syncData : {};
            if (continueWatchingData && Object.keys(continueWatchingData).length > 0) {
                const idForAudio = programType === PROGRAM_TYPES.EPISODE ? seriesId : id;
                const langId = idForAudio && continueWatchingData?.[idForAudio]?.langId || "";
                if (langId !== '') {
                    this.selectedAudio = { value: langId };
                }
            }
        }

        if (selectedSub && selectedSub !== 'OFF' && meta?.textTracks && meta?.textTracks.length) {
            const defaultSelection = meta.textTracks.find(track => track === selectedSub);
            this.selectedCaption = {
                value: defaultSelection,
                des: this.LANGUAGES_CODES[defaultSelection],
            };
        }
    }

    componentDidUpdate(prevProps) {
        const { show } = this.props;
        if (show !== prevProps.show && show) {
            this.defaultMenu();
        }
    }

    defaultMenu = (event) => {
        if (event) {
            event.stopPropagation();
        }

        // playerSettingButtonClickGaEvent();

        const { enableBitrateSelector, playerFunctions } = this.props;
        const meta = playerFunctions.getMeta();
        const options = [];
        // if browser is safari and is drm then show auto only
        if (!enableBitrateSelector) {
            options.push({
                id: 1,
                title: SLIDE_MENU_IDENTIFIERS.RENDITIONS.title,
                value: SLIDE_MENU_IDENTIFIERS.RENDITIONS.defaultValue,
            });
        }
        else {
            options.push({
                id: 1,
                title: SLIDE_MENU_IDENTIFIERS.RENDITIONS.title,
                value: this.selectedRendition ? this.selectedRendition.des : SLIDE_MENU_IDENTIFIERS.RENDITIONS.defaultValue,
            });
        }

        // SUBTITLES
        if (meta.textTracks && meta.textTracks.length) {
            const isTextTrackActive = playerFunctions.isTextTrackVisible();
            if (isTextTrackActive && !this.selectedCaption && playerFunctions.languageExistsInTextTrack(DEFAULT_SUBTITLE_LANGUAGE)) {
                this.selectedCaption = {
                    value: DEFAULT_SUBTITLE_LANGUAGE,
                    des: this.LANGUAGES_CODES[DEFAULT_SUBTITLE_LANGUAGE],
                };
            }

            const value = !isTextTrackActive || !this.selectedCaption ? SLIDE_MENU_IDENTIFIERS.CAPTIONS.defaultValue : this.selectedCaption.des;

            options.push({
                id: 3,
                title: SLIDE_MENU_IDENTIFIERS.CAPTIONS.title,
                value,
            });
        }

        // TODO: commented for now
        // {
        //     id: 2,
        //     title: SLIDE_MENU_IDENTIFIERS.PLAYBACK_SPEED.title,
        //     value: this.selectedPlaySpeed ? this.selectedPlaySpeed.title : SLIDE_MENU_IDENTIFIERS.PLAYBACK_SPEED.defaultValue,
        // },


        // TODO: commented for now
        if (meta.audioTracks && meta.audioTracks.length && meta.audioTracks.length > 1) {
            const selectedLanguage = this.selectedAudio ? this.selectedAudio.value : meta.selectedLang;
            if (selectedLanguage) {
                options.push({
                    id: 4,
                    title: SLIDE_MENU_IDENTIFIERS.AUDIO_LANGUAGES.title,
                    value: this.LANGUAGES_CODES[selectedLanguage] ? this.LANGUAGES_CODES[selectedLanguage] : selectedLanguage,
                });
            }
        }

        const defaultSelection = options[0];

        this.setState({
            options,
            defaultSelection,
            title: SLIDE_MENU_IDENTIFIERS.SETTINGS.title,
            identifier: SLIDE_MENU_IDENTIFIERS.SETTINGS.id,
            showSelection: false,
        });
    };

    showVideoRenditions = (event) => {
        if (event) {
            event.stopPropagation();
        }

        const { enableBitrateSelector, playerFunctions } = this.props;
        const { qualityOptions = {}} = this.state;
        let options = null;
        // safari does not support changing content bitrate for drm content
        if (!enableBitrateSelector) {
            options = [
                {
                    id: 0,
                    // title: item.title,
                    des: PLAYBACK_RENDITIONS[0].title,
                    value: PLAYBACK_RENDITIONS[0].value,
                },
            ];
        }
        // support rendition change
        else {
            if (Object.keys(qualityOptions).length > 0) {
                options = qualityOptions;
            } else {
                options = PLAYBACK_RENDITIONS.map((item, index) => ({
                    id: index,
                    // title: item.title,
                    des: item.title,
                    value: item.value,
                }));
            }
        }

        const defaultSelection = this.selectedRendition || options[0];

        this.setState({
            options,
            defaultSelection,
            title: SLIDE_MENU_IDENTIFIERS.RENDITIONS.title,
            identifier: SLIDE_MENU_IDENTIFIERS.RENDITIONS.id,
            showBackIcon: true,
        });
    };

    showCaptions = (event) => {
        if (event) {
            event.stopPropagation();
        }

        const { playerFunctions  = {}} = this.props;
        const meta = playerFunctions.getMeta();

        const options = [
            {
                id: -1,
                value: SLIDE_MENU_IDENTIFIERS.CAPTIONS.defaultValue,
                des: 'OFF',
            },
        ];


        if (meta.textTracks) {
            meta.textTracks.forEach((langCode, index) => {
                const option = {
                    id: index,
                    value: langCode,
                    des: this.LANGUAGES_CODES[langCode],
                };
                options.push(option);
            });
        }

        const defaultSelection = this.selectedCaption || options[0];
        this.setState({
            options,
            defaultSelection,
            title: SLIDE_MENU_IDENTIFIERS.CAPTIONS.title,
            identifier: SLIDE_MENU_IDENTIFIERS.CAPTIONS.id,
            showBackIcon: !event,
        });
    };

    showAudioLanguages = (event) => {
        if (event) {
            event.stopPropagation();
        }

        const { playerFunctions } = this.props;
        const meta = playerFunctions.getMeta();
        const { defaultAudioLanguage } = this.state;
        const defaultLanguage = meta.selectedLang ? meta.selectedLang : defaultAudioLanguage;
        let langOptions = playerFunctions.getRepresentationMeta();
        langOptions = langOptions.filter((option, index, self) => index === self.findIndex((sel) => option.language === sel.language));
        const haveLabels = langOptions.every(lan => !!lan.displayLang);
        const options = [];

        let defaultOption = null;

        if (langOptions.length > 0 && haveLabels) {
            langOptions.forEach((lang, index) => {
                const option = {
                    id: index,
                    value: lang.language,
                    title: lang.displayLang,
                };

                if (lang.language === defaultLanguage) {
                    defaultOption = option;
                }

                options.push(option);
            });
        } else if (meta.audioTracks) {
            meta.audioTracks.forEach((langCode, index) => {
                const option = {
                    id: index,
                    value: langCode,
                    title: this.LANGUAGES_CODES[langCode],
                };

                if (langCode === defaultLanguage) {
                    defaultOption = option;
                }

                options.push(option);
            });
        }

        if (!defaultOption) {
            [
                defaultOption,
            ] = options;
        }

        const defaultSelection = this.selectedAudio || defaultOption;

        this.setState({
            options,
            defaultSelection,
            title: SLIDE_MENU_IDENTIFIERS.AUDIO_LANGUAGES.title,
            identifier: SLIDE_MENU_IDENTIFIERS.AUDIO_LANGUAGES.id,
            showBackIcon: true,
        });
    };

    showPlaybackSpeeds = (event) => {
        if (event) {
            event.stopPropagation();
        }

        const options = PLAYBACK_RATES.map((item, index) => ({
            id: index,
            title: item.title,
            des: item.des,
            value: item.value,
        }));

        const defaultSelection = this.selectedPlaySpeed || options[1];

        this.showSideMenu({
            options,
            defaultSelection,
            title: SLIDE_MENU_IDENTIFIERS.PLAYBACK_SPEED.title,
            identifier: SLIDE_MENU_IDENTIFIERS.PLAYBACK_SPEED.id,
            showBackIcon: true,
        });
    };

    changeCaptions = (value) => {
        const { enabled, playerFunctions, playbackSessionId, subtitleType } = this.props;
        if (!enabled) {
            return;
        }
        if (!playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        if (value === SLIDE_MENU_IDENTIFIERS.CAPTIONS.defaultValue) {
            playerFunctions.showCaptions();
        }
        else {
            playerFunctions.showCaptions(value);
        }

        clickEvent({
            source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE,
            asset_name: value,
            action: PLAYER_EVENTS_LIST.SUBTITLE_CHANGE,
            play_session_id: playbackSessionId,
            subtitles_language: value,
            subtitles_loader: subtitleType,
        });
        const subtitleKey = LOCAL_STORE_KEYS.SELECTED_SUBTITLE_FOR_USER.replace('${uid}', AuthenticationUtil.getUid());
        browserStore.set(subtitleKey, value);
    };

    changeBitrate = (option) => {
        const {
            enabled, enableBitrateSelector, playerFunctions, onPlayerFunctionsNotFound,
        } = this.props;
        const { options } = this.state;
        if (option.id === 1) {
            option = { ...option, persistAs: PLAYER_RENDITIONS_FOR_PERSISTANCE.HIGHEST }
        } else if (option.id === options.length - 1) {
            option = { ...option, persistAs: PLAYER_RENDITIONS_FOR_PERSISTANCE.LOWEST }
        }
        if (!enabled || !enableBitrateSelector) {
            return;
        }

        if (!playerFunctions) {
            onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        const { browser } = this.props;

        playerFunctions.changeBitrate(option, browser);
    };

    changePlaybackRate = (value) => {
        const { enabled, onPlayerFunctionsNotFound, playerFunctions } = this.props;
        if (!enabled) {
            return;
        }

        if (!playerFunctions) {
            onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        playerFunctions.changePlaybackRate(value);
    };

    changeAudio = (value) => {
        const { enabled, onPlayerFunctionsNotFound, playerFunctions } = this.props;
        if (!enabled) {
            return;
        }
        if (!playerFunctions) {
            onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        publish(PLAYER_EVENTS_LIST.AUDIO_CHANGE, value);
        playerFunctions.changeAudio(value);
    };

    onOptionClicked = (option, e) => {
        const { identifier } = this.state;
        const {
            playbackSessionId, toggleOption, hideOptions, hoverOptionEl, playerFunctions, subtitleType, contentDetails: { id = "" } = {}
        } = this.props;

        if (identifier === SLIDE_MENU_IDENTIFIERS.RENDITIONS.id) {
            clickEvent({
                source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE,
                asset_name: option.des,
                action: SLIDE_MENU_EVENTS_ID.PLAYBACK_QUALITY_SELECTION,
                play_session_id: playbackSessionId,
            });
        }
        else {
            clickEvent({
                source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE,
                asset_name: SLIDE_MENU_EVENTS_ID[option.title],
                action: SLIDE_MENU_EVENTS_ID[option.title],
                play_session_id: playbackSessionId,
            });
        }


        if (identifier === SLIDE_MENU_IDENTIFIERS.SETTINGS.id) {
            switch (option.id) {
                case 1:
                    this.showVideoRenditions();
                    break;
                case 2:
                    this.showPlaybackSpeeds();
                    break;
                case 3:
                    this.showCaptions();
                    break;
                case 4:
                    this.showAudioLanguages();
                    break;
                default:
                    break;
            }
        }
        else {
            if (identifier === SLIDE_MENU_IDENTIFIERS.RENDITIONS.id) {
                playerFunctions.emitEvent(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE_V2, option.des);
                this.selectedRendition = option;
                this.changeBitrate(option);
            }
            else if (identifier === SLIDE_MENU_IDENTIFIERS.AUDIO_LANGUAGES.id) {
                playerFunctions.emitEvent(PLAYER_EVENTS_LIST.CHANGE_AUDIO_LANGUAGE, option.title);
                this.selectedAudio = option;
                this.changeAudio(option.value);
            }
            else if (identifier === SLIDE_MENU_IDENTIFIERS.CAPTIONS.id) {
                playerFunctions.emitEvent(PLAYER_EVENTS_LIST.CHANGE_SUBTITLES, option.des);
                this.selectedCaption = option;
                this.changeCaptions(option.value);
                const playerMeta = playerFunctions.getMeta();
                const { textTracks = {}} = playerMeta;
                const meta = { 
                    action: CDP_EVENT_CONST.SUBTITLE_CLICK,
                    content_id: id,
                    selected_state: option.value,
                    subtitles_loader: subtitleType,
                }
                contentDetailsEvent(meta, EventType.SUBTITLE_CLICK)
            }
            else if (identifier === SLIDE_MENU_IDENTIFIERS.PLAYBACK_SPEED.id) {
                playerFunctions.emitEvent(PLAYER_EVENTS_LIST.CHANGE_PLAYBACK_SPEED, option.des);
                this.selectedPlaySpeed = option;
                this.changePlaybackRate(option.value);
            }

            const newState = {
                ...this.state,
                defaultSelection: option,
            };

            this.setState(newState);
            if (this.deviceUtil.isMobile()) {
                toggleOption();
            }
            else {
                hideOptions(e, hoverOptionEl);
            }
        }
    };

    buildSettingsHTML = () => {
        const { options, identifier } = this.state;

        const optionHTML = options.map((option, index) => (
            /* eslint-disable  */
            <li
                key={option.title}
                onClick={() => this.onOptionClicked(option)}
                id={`${SLIDE_MENU_IDENTIFIERS[identifier].id}-${option.title}-${index+1}`}
                value={option.value}
            >
                {/* eslint-enable */}
                <div className="btm-setting-option d-flex justify-content-between">
                    <div className="d-flex options-title-value">
                        <p className="option">{option.title}</p>
                        <p className="selected-option">{option.value}</p>
                    </div>
                    <div>
                        <ThumborImage
                            src={IMAGE_PATHS.SETTIINGS_NEW_CHEV}
                        />
                    </div>
                </div>
            </li>
        ));
        return (
            <div
                className="settings-popup-container"
                id="player-quality-settings"
            >
                <div className="mobile-setting-title-container for-mobile-only">
                    <ThumborImage
                        src={IMAGE_PATHS.SETTINGS}
                        class="mobile-setting-icon"
                    />
                    <span className="name">{this.LANGUAGE.PLAYER_SETTINGS}</span>
                </div>
                <ul id="player-quality-resolution-options">
                    {optionHTML}
                </ul>
            </div>
        );
    }

    buildSelectionHTML = () => {
        const { options, title, defaultSelection, identifier } = this.state;
        const optionHTML = options.map((option, index) => {
            const isSelected = option.value === defaultSelection.value;
            return (
                /* eslint-disable  */
                <li
                    className="d-flex sub-option-list"
                    key={option.value}
                    onClick={(e) => this.onOptionClicked(option, e)}
                    id={`${SLIDE_MENU_IDENTIFIERS[identifier].id}-${index+1}`}
                    isSelected={`${isSelected}`}
                    value={option.des || option.title}
                >
                {/* eslint-enable */}
                    <div className="selection-btn selected-btn">
                        <div className={`selected${isSelected ? ' active' : ''}`} />
                    </div>
                    <div className={`select-option-setting${isSelected ? ' active' : ''}`}>
                        {option.des || option.title}
                    </div>
                </li>
            );
        });

        return (
            <div
                id="atm_sub-title-popup-container"
                className="sub-title-popup-container"
                style={{ display: 'block' }}
            >
                <button
                    className="d-flex setting-option-name"
                    onClick={this.defaultMenu}
                    type="button"
                >
                    <div className="setting-back-arrow">
                        <ThumborImage
                            src={IMAGE_PATHS.SETTIINGS_NEW_CHEV}
                        />
                    </div>
                    <p className="option-name">{title}</p>

                </button>

                <div className="setting-item-scroll">
                    <ul className="subtitle-options-selector">
                        {optionHTML}
                    </ul>
                </div>
            </div>
        );
    }

    buildOptionHTML = () => {
        const {
            identifier,
        } = this.state;

        let html = null;
        switch (identifier) {
            case SLIDE_MENU_IDENTIFIERS.SETTINGS.id:
                html = this.buildSettingsHTML();
                break;
            default:
                html = this.buildSelectionHTML();
        }
        return html;
    }

    render() {
        const {
            show,
        } = this.props;
        if (!show) {
            return null;
        }

        const optionHTML = this.buildOptionHTML();
        return (
            <>
                {optionHTML}
            </>
        );
    }
}

PlaybackSettingsContainer.propTypes = {
    meta: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    enableBitrateSelector: PropTypes.bool.isRequired,
    playerFunctions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    playbackSessionId: PropTypes.string.isRequired,
    lang: PropTypes.string,
    show: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    browser: PropTypes.string.isRequired,
    onPlayerFunctionsNotFound: PropTypes.func.isRequired,
    toggleOption: PropTypes.func.isRequired,
    hideOptions: PropTypes.func.isRequired,
    hoverOptionEl: PropTypes.string.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    renditionFromLocalStorage: PropTypes.shape({
        id: PropTypes.string.isRequired,
        des: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    }),
};

PlaybackSettingsContainer.defaultProps = {
    renditionFromLocalStorage: null,
};

export default withDeviceUtil(PlaybackSettingsContainer);
