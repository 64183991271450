import { AppEnv } from '@airtel-tv/utils/GetEnv';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { removeFalsy } from '@airtel-tv/utils/GlobalUtil';


export const syncContentApi = ({
    body,
    token,
    uid,
    appId,
    atvDid,
}) => {
    const env = AppEnv.getEnv();
    const { base, paths } = env.endpoints.syncApi;
    const method = 'POST';

    const queryParams = {
        appId,
        diff: true,
    };

    const url = `${paths.contentSync}?${LocationUtil.objectToQueryParams(queryParams)}`;

    const jsonBody = JSON.stringify(body);

    const utknData = {
        urlBody: `${method}/${url}${jsonBody}`,
        token,
        uid,
    };

    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'Content-Type': 'application/json',
        'x-atv-did': atvDid,
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        method,
        url: base + url,
        data: body,
        headers: removeFalsy(headers),
    };
    return RequestUtil.POST(authOptions);
};

export default {
    syncContentApi,
};
