import {
    ROUTE_PATHS_SETTINGS,
    _ROUTE_PATHS,
    PAGE_TITLE_LIST,
    APP_ROOT_ID,
    _ROUTE_SOURCE_NAMES,
    ROUTE_STATIC_IDS,
    TAB_TITLE_MAP,
    ROUTE_ACTIONS,
    DEFAULT_NAV,
    WEBVIEW_PATHS,
    _CDP_ROUTES,
} from '@airtel-tv/constants/RouteConsts';
import { DeviceTypeUtil } from '../DeviceTypeUtil';

export const ROUTE_PATHS = _ROUTE_PATHS[DeviceTypeUtil.getDevice()];
export const ROUTE_SOURCE_NAMES = _ROUTE_SOURCE_NAMES[DeviceTypeUtil.getDevice()];
export const CDP_ROUTES = _CDP_ROUTES[DeviceTypeUtil.getDevice()];

export {
    ROUTE_STATIC_IDS,
    TAB_TITLE_MAP,
    ROUTE_ACTIONS,
    DEFAULT_NAV,
    WEBVIEW_PATHS,
    PAGE_TITLE_LIST,
    APP_ROOT_ID,
    ROUTE_PATHS_SETTINGS,
};
