export const ThemeActions = {
    THEME_CONFIG_FETCH: 'THEME_CONFIG_FETCH',
    THEME_CONFIG_SUCCESS: 'THEME_CONFIG_SUCCESS',
    THEME_CONFIG_ERROR: 'THEME_CONFIG_ERROR',
};

export const themeConfigSuccessAction = payload => ({
    type: ThemeActions.THEME_CONFIG_SUCCESS,
    payload,
});

export const themeConfigFetchAction = payload => ({
    type: ThemeActions.THEME_CONFIG_FETCH,
    payload,
});

export const themeConfigErrorAction = payload => ({
    type: ThemeActions.THEME_CONFIG_ERROR,
    payload,
});

export default {
    ThemeActions,
    themeConfigSuccessAction,
    themeConfigFetchAction,
    themeConfigErrorAction,
};
