import lodashCloneDeep from 'lodash/cloneDeep';
import lodashIsEqual from 'lodash/isEqual';
import lodashGet from 'lodash/get';
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {
    PLAYER_EVENTS_LIST,
    SLIDE_MENU_IDENTIFIERS,
    CONTROL_BAR_EVENTS,
    CENTER_ICONS,
    PLAYER_STATE,
} from '@airtel-tv/constants/PlayerConsts';
import {
    getFullScreen, getSourceName,
} from '@airtel-tv/utils/GlobalUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import { HARDWARE_LIST } from '@airtel-tv/constants/BrowserConst';

import {
    checkFullScreen, exitFullScreen, screenOrientationToLandscape, screenOrientationToNeutral, addListenerToEvent, removeListenerFromEvent, checkIfPlayInPip, closePip,
} from '@airtel-tv/utils/WindowUtil';
// import { playerSettingButtonClickGaEvent, playbackQualityButtonClickGaEvent } from '@airtel-tv/utils/GaEvents';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import PlaybackStorageUtil from '@airtel-feature/playback/PlaybackStorageUtil';
import { NEXT_EPISODE_TIMER } from '@airtel-tv/constants/PlaybackConsts';
import { clickEvent, contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { CDP_EVENT_CONST , ELEMENT_ID } from '@airtel-tv/constants/GlobalConst';
import EventType from '@airtel-tv/analytics/EventType';
import { contentDetailsFetchAction } from '@airtel-feature/content-details/content-details/actions/ContentDetailsAction';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { getPrerollAdsPlaying } from '@airtel-feature/playback/actions/PlaybackActions';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { syncContentRemove } from '@airtel-tv/sync/SyncActions';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { EVENT_SOURCE_NAME } from '@airtel-tv/constants';
import CenterIconComponent from '../overlays/CenterIconComponent';
import LeftPlayerControlsContainer from '../player-toolbars/left-toolbar/LeftPlayerControlsContainer';
import RightPlayerControlsContainer from '../player-toolbars/right-toolbar/RightPlayerControlsContainer';
import SkipIntroButtonComponent from '../overlays/cta/SkipIntroButtonComponent';
import NextEpisodeCtaComponent from '../overlays/cta/NextEpisodeCtaComponent';
import LanguageProvider from '../../../../providers/LanguageProvider';
import OverlayDescription from '../overlays/OverlayDescription';
import styles from './ControlBarComponent.scss';
import CenterToolbarComponent from '../player-toolbars/CenterToolbarComponent';
import BottomToolbarComponent from '../player-toolbars/BottomToolbarComponent';
import { checkSdkAdsAllowed } from '@airtel-tv/utils/AdSdkUtil';

class BasicControlBar extends Component {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;

        const { onRef, onPlayerFunctionsNotFound } = this.props;

        onRef(this);

        this.playerEvents = null;

        this.overlayDivRef = React.createRef();
        this.playerCtaDivRef = React.createRef();

        this.centerIconRef = React.createRef();

        this.optionLayerRef = React.createRef();
        this.controlBarRef = React.createRef();

        this.onPlayerFunctionsNotFound = onPlayerFunctionsNotFound;
        // will be provided by player after init
        this.playerFunctions = null;

        this.defaultState = {
            enabled: false,
            playing: false,
            currentTime: 0,
            bufferedTime: 0,
            waiting: false,
            fullScreen: false,
            showNextEp: false,
            showSkipIntro: false,
            showSkipCredit: false,
            volumeLevel: 0,
            isMetaLoaded: false,
            centerIcon: null,
            meta: {
                duration: 0,
                playbackRate: 0,
                audioTracks: [],
                textTracks: [],
                qualityLevels: [],
            },
            settingsOption: {
                defaultMenu: [],
                selectedOptions: [],
                showBackIcon: false,
            },
            videoEnded: false,
            isAdBreak: false,
            prerollval: true,
            isFadeIn: false,
            isMute: false,
            isSettingsVisible: false,
            adCuePoints: [],
        };

        this.state = lodashCloneDeep(this.defaultState);

        this.renditionFromLocalStorage = null;
        this.selectedPlaySpeed = null;
        this.selectedAudio = null;
        this.selectedCaption = null;

        addListenerToEvent('blur', this.pauseOnFocusLoss);
        addListenerToEvent('focus', this.fadeOut);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let changes = {};

        const {
            onRef,
            onPlayerFunctionsNotFound,
            videoWrapperRef,
            getVideoTag,
        } = nextProps;

        if (
            onRef !== prevState.onRef
            || onPlayerFunctionsNotFound !== prevState.onPlayerFunctionsNotFound
            || videoWrapperRef !== prevState.videoWrapperRef
            || getVideoTag !== prevState.getVideoTag
        ) {
            changes = {
                onRef,
                onPlayerFunctionsNotFound,
                videoWrapperRef,
                getVideoTag,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { playTrailer } = this.props;
        return !lodashIsEqual(this.state, nextState) || nextProps.playTrailer !== playTrailer || nextProps.sdkAdsPlayingFlag !== this.props.sdkAdsPlayingFlag;
    }

    componentDidUpdate(prevProps, prevState) {
        const { fullScreen } = this.state;
        const { navigate, playTrailer, sdkAdsPlayingFlag, adsPlayingFlag} = this.props;
        const { prevPlayState } = prevProps;
        if (fullScreen !== prevState.fullScreen && !fullScreen) {
            const volumeLevel = PlaybackStorageUtil.getPlayerVolumeLevel();
            if (volumeLevel === 0) this.setState({ isMute: true });
            else this.setState({ isMute: false });
            if (this.deviceUtil.isMobile() && !sdkAdsPlayingFlag && !adsPlayingFlag) {
                navigate(-1);
            }
        }
        if (prevPlayState === PLAYER_STATE.LOGIN_PAUSE) {
            playTrailer ? !this.state.videoEnded && this.play() : !this.state.videoEnded && this.pause();
        }
    }

    componentDidMount() {
        const { playingOnBanner } = this.props;
        if (!playingOnBanner) {
            this.playerFunctions?.play();
            const volumeLevel = PlaybackStorageUtil.getPlayerVolumeLevel();
            if (volumeLevel === 0) {
                this.playerFunctions?.volumeChange(0, false, false);
                this.setState({ isMute: true });
            }
            else {
                this.playerFunctions?.volumeChange(100, false, false);
                this.setState({ isMute: false });
            }
        }
    }

    componentWillUnmount() {
        const { getVideoTag } = this.props;
        const videoTag = getVideoTag();
        const browser = this.deviceUtil.getBrowserName();
        if (checkIfPlayInPip(videoTag)) {
            closePip(browser, videoTag);
        }
        removeListenerFromEvent('blur', this.pauseOnFocusLoss);
        removeListenerFromEvent('focus', this.fadeOut);

        this.disposeEvents();
    }

    disposeEvents = () => {
        if (this.playerEvents) {
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.WAITING, this.onWaiting);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, this.onPlayThrough);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.PLAY, this.onPlay);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.PAUSE, this.onPause);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.LOADED, this.onLoaded);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.TIMEUPDATE, this.onTimeUpdate);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, this.onSeekBarChange);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.PROGRESS, this.onProgress);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.PLAYING, this.onPlaying);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.DURATION_CHANGE, this.onDurationChange);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.ENDEND, this.onEnded);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.VOLUME_CHANGE, this.setPlayerVolume);
            this.playerEvents = null;
        }
    };

    reset = () => {
        this.playerFunctions = null;
        this.setState(lodashCloneDeep(this.defaultState));
    };

    setFullScreenState = (value) => {
        this.setState({
            fullScreen: value,
        });
    };

    setShowPlayerCta = (currentTime) => {
        const { meta } = this.state;
        const {
            nextEpisodeDetails, skipIntroDur, web_next_episode_timer, skipCreditDur,
        } = this.props;
        let setNextEp = false;
        let setSkipIn = false;
        let setskipCredit = false;
        // show player if its not a iPhone
        if (!(this.deviceUtil.getDeviceName() === HARDWARE_LIST.IPHONE) && currentTime) {
            // show next episode cta if last {NEXT_EPISODE_TIMER} seconds remain
            // or check if can skip intro
            if (nextEpisodeDetails != null && meta.duration > 0 && meta.duration - currentTime <= (skipCreditDur || web_next_episode_timer || NEXT_EPISODE_TIMER)) {
                setNextEp = true;
            }
            else if (skipIntroDur && currentTime < skipIntroDur) {
                setSkipIn = true;
            }
            else if (!nextEpisodeDetails && skipCreditDur && currentTime > meta.duration - skipCreditDur) {
                setskipCredit = true;
            }
        }

        this.setState({
            showNextEp: setNextEp,
            showSkipIntro: setSkipIn,
            showSkipCredit: setskipCredit,
        });
    };

    pauseOnFocusLoss = () => {
        // pause on tab change in mobile since user could have pressed home
        // but do not pause if user has gone to pip mode.
        if (this.deviceUtil.isMobile()
            && this.playerFunctions
            && !this.playerFunctions.paused()
            && !checkFullScreen()) {
            this.playerFunctions.pause();
        }
    };

    enableControlBar = () => {
        this.setState({
            enabled: true,
        });
    };

    // enableAdBreak = () => {
    //     this.setState({
    //         isAdBreak: true,
    //     });
    // };

    // disableAdBreak = () => {
    //     this.setState({
    //         isAdBreak: false,
    //     });
    // };

    setControlBarEventEmitter = (emitter) => {
        this.controlBarEventEmitter = emitter;
    };

    disableControlBar = () => {
        this.setState({
            enabled: false,
        });
    };

    setPlayerFunctions = (playerFunctions) => {
        this.playerFunctions = playerFunctions;
    };

    initPlayerVolume = () => {
        // volume Level
        this.playerFunctions.volumeChange(PlaybackStorageUtil.getPlayerVolumeLevel());
    };

    setPlayerVolume = (event) => {
        if (event && event.data) {
            this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                { option: CENTER_ICONS.VOLUME });
        }
        const volumeLevel = this.playerFunctions ? this.playerFunctions.getVolumeLevel() : 0;
        this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.UPDATE_VOLUME_BAR, volumeLevel);
        this.setState({
            volumeLevel,
        });
    };

    setPlayerQuality = () => {
        // quality Level
        this.renditionFromLocalStorage = PlaybackStorageUtil.getPlayerQuality();
    };

    onWaiting = () => {
        this.setState({
            waiting: true,
            playing: !this.playerFunctions.paused(),
        });
    };

    onPlayThrough = () => {
        this.setState({
            waiting: false,
            playing: !this.playerFunctions.paused(),
        });
    };

    onPlay = () => {
        this.setState({
            playing: !this.playerFunctions.paused(),
            videoEnded: false,
        });
        contentDetailsEvent({ action: CDP_EVENT_CONST.PLAY_CLICK }, EventType.PLAY_CLICK);
    };

    onPause = () => {
        this.setState({
            playing: !this.playerFunctions.paused(),
        });
        contentDetailsEvent({ action: CDP_EVENT_CONST.PAUSE_CLICK }, EventType.PAUSE_CLICK);
    };

    onLoaded = (meta) => {
        this.setState({
            meta,
            isMetaLoaded: true,
        });
    };

    onTimeUpdate = (playbackCurrentTime) => {
        const { currentTime } = this.state;

        if (currentTime !== playbackCurrentTime) {
            this.setState({
                playing: !this.playerFunctions.paused(),
                currentTime: playbackCurrentTime,
            });
        }

        this.setShowPlayerCta(playbackCurrentTime);
    };

    onSeekBarChange = (data) => {
        const { currentTime } = this.state;
        if (currentTime !== data.newTime) {
            this.setState({
                currentTime: data.newTime,
            });
        }
    };

    onProgress = (bufferedTime) => {
        this.setState({
            bufferedTime: bufferedTime || 0,
        });
    };

    onPlaying = () => {
        this.setState({
            playing: !this.playerFunctions.paused(),
        });
    };

    onDurationChange = (duration) => {
        const { meta } = this.state;
        meta.duration = duration;
        this.setState({
            meta,
        });
    };

    onEnded = () => {
        const { contentDetails, removeContentDispatch, nextEpisodeDetails } = this.props;
        this.setState({
            videoEnded: true,
        });

        removeContentDispatch({
            contentDetails,
            nextEpisodeDetails,
            lastWatchedPosition: 0,
        });
    };

    handleCuepointsChange = (payload) => {
        const { adCuePoints } = payload;
        this.setState({ adCuePoints });
    };

    setPlayerEvents = (playerEvents) => {
        if (!playerEvents) {
            return;
        }
        this.disposeEvents();

        // subscribe events
        this.playerEvents = playerEvents;

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.WAITING, this.onWaiting);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, this.onPlayThrough);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PLAY, this.onPlay);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PAUSE, this.onPause);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.LOADED, this.onLoaded);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, this.onTimeUpdate);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, this.onSeekBarChange);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PROGRESS, this.onProgress);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PLAYING, this.onPlaying);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.DURATION_CHANGE, this.onDurationChange);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ENDEND, this.onEnded);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.VOLUME_CHANGE, this.setPlayerVolume);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ERROR, this.undoFullScreenOnError);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.AD_CUEPOINTS_CHANGED, this.handleCuepointsChange);
    };

    play = () => {
        const { enabled, prerollval } = this.state;
        const {
            adsManager, showPreRollAds, dispatchPrerollAdsPlaying,
        } = this.props;
        if (!enabled) {
            return;
        }
        if (adsManager && showPreRollAds) {
            if (prerollval) {
                // adsManager.pause();
                // const vid = getVideoTag();
                // vid.pause();
                // const ads = adsManager.release();
                this.setState({
                    prerollval: false,
                });

                dispatchPrerollAdsPlaying({ preRollAdsPlaying: false });
                return;
            }
            if (!prerollval) {
                // adsManager.play();
                this.playerFunctions.play();
                this.setState({
                    prerollval: true,
                });
                dispatchPrerollAdsPlaying({ preRollAdsPlaying: true });
                return;
            }
        }
        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound({ event: PLAYER_EVENTS_LIST.PLAY });
            return;
        }

        this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
            { option: CENTER_ICONS.PLAY });

        this.playerFunctions.play();
    };

    pause = () => {
        const { enabled } = this.state;
        const {
            adsManager, showPreRollAds,
        } = this.props;
        if (!enabled) {
            return;
        }
        if (adsManager && showPreRollAds) {
            this.playerFunctions.pause();
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound({ event: PLAYER_EVENTS_LIST.PAUSE });
            return;
        }
        this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
            { option: CENTER_ICONS.PAUSE });
        this.playerFunctions.pause();
    };

    setSeekPauseStatus = () => {
        this.playerFunctions.emitEvent(PLAYER_EVENTS_LIST.SEEK_PAUSE);
    };

    undoFullScreenOnError = () => {
        // When error comes on fullscreen, popup is not visible.
        // Hence we toggle to normal mode
        const { videoWrapperRef } = this.props;
        const fullScreenTargetElement = videoWrapperRef();
        if (checkFullScreen()) {
            this.setFullScreenState(false);
            exitFullScreen(fullScreenTargetElement);
            screenOrientationToNeutral();
        }
    };


    toggleFullScreen = (checkPip = true) => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }
        const { videoWrapperRef, getVideoTag, videoWrapper } = this.props;
        const fullScreenTargetElement = videoWrapperRef() || videoWrapper;
        const videoTag = getVideoTag();
        const browser = this.deviceUtil.getBrowserName();

        if (!checkFullScreen()) {
            console.log('asking for full screen ==>', fullScreenTargetElement);
            this.setFullScreenState(true);
            getFullScreen(fullScreenTargetElement);
            screenOrientationToLandscape();
            this.playerFunctions?.play();
        }
        else {
            console.log('screen is full screen ==>', fullScreenTargetElement);
            this.setFullScreenState(false);
            exitFullScreen(fullScreenTargetElement);
            screenOrientationToNeutral();
        }

        if (checkPip && checkIfPlayInPip(videoTag)) {
            closePip(browser, videoTag);
        }
    };

    toggleMute = () => {
        const { isMute } = this.state;
        if (isMute) {
            this.playerFunctions?.volumeChange(100, false, false);
            this.setState({ isMute: false });
        }
        else {
            this.playerFunctions?.volumeChange(0, false, false);
            this.setState({ isMute: true });
        }
    };

    seekBarChange = (seekTime) => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        this.setState({
            currentTime: seekTime,
        });
        this.playerFunctions.seekBarChange(seekTime);
    };

    seekForward = (skipTime) => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }
        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }
        this.playerFunctions.forward(skipTime);
    };

    seekBackward = (skipTime) => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }
        this.playerFunctions.backward(skipTime);
    };

    replay = () => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        this.playerFunctions.replay();
    };


    changeBitrate = (option, init = false) => {
        const { enabled } = this.state;
        const { enableBitrateSelector } = this.props;
        if ((!init && !enabled) || !enableBitrateSelector) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        const { browser } = this.props;

        this.playerFunctions.changeBitrate(option, browser);
    };

    changePlaybackRate = (value) => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        this.playerFunctions.changePlaybackRate(value);
    };

    handleOverlayClick = (e) => {
        const { playing } = this.state;
        if (e.target === e.currentTarget && !this.deviceUtil.isMobile()) {
            if (playing) {
                this.pause();
            }
            else {
                this.play();
                this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                    { option: CENTER_ICONS.PLAY });
            }
        }
    };

    changeCaptions = (value) => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }
        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        if (value === SLIDE_MENU_IDENTIFIERS.CAPTIONS.defaultValue) {
            this.playerFunctions.showCaptions();
        }
        else {
            this.playerFunctions.showCaptions(value);
        }
    };

    onSlideMenuBack = ({ identifier }) => {
        if (identifier !== SLIDE_MENU_IDENTIFIERS.SETTINGS.id) {
            this.showSettings();
        }
    };

    getSkipDurationMeta = (action) => {
        const {
            playbackSessionId, currentTime, contentDetails: { id, cpId }, location: { pathname },
        } = this.props;

        const meta = {
            content_id: id,
            action,
            source_name: getSourceName(pathname),
            cp_name: cpId,
            seek_bar_time: currentTime,
            ...(playbackSessionId && { play_session_id: playbackSessionId }),
        };

        return meta;
    };

    skipIntro = (duration, isSkipCredit) => {
        const action = isSkipCredit ? PLAYER_EVENTS_LIST.SKIP_CREDIT : PLAYER_EVENTS_LIST.SKIP_INTRO;
        const meta = this.getSkipDurationMeta(action);
        clickEvent(meta);
        this.seekBarChange(duration);
    };

    setSeekBarVisibility = (event) => {
        this.setState({
            seekBarVisibility: event,
        });
    };

    onControlHover = (e, addLayer = true) => {
        this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.UPDATE_SEEK_BAR_VISIBILITY, false);
        if (addLayer && this.optionLayerRef && this.optionLayerRef.current) {
            this.optionLayerRef.current.classList.remove('d-none');
            this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.SETTINGS_OVERLAY_TOGGLE, true);
        }
        if (e) {
            e.stopPropagation();
        }
    };

    onControlLeave = (e, removeLayer = true) => {
        this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.UPDATE_SEEK_BAR_VISIBILITY, true);
        if (removeLayer && this.optionLayerRef && this.optionLayerRef.current) {
            this.optionLayerRef.current.classList.add('d-none');
            this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.SETTINGS_OVERLAY_TOGGLE, false);
        }
        if (e) {
            e.stopPropagation();
        }
    };

    onNextEpisodeClick = () => {
        const {
            nextEpisodeDetails, contentDetailsFetchActionDispatch, adConfig, contentDetails
        } = this.props;

        if (checkSdkAdsAllowed(contentDetails, adConfig)) {
            this.playerEvents.emitter.emit(PLAYER_EVENTS_LIST.TRIGGER_POST_ROLL_AD);
            return;
        }
        contentDetailsFetchActionDispatch(nextEpisodeDetails.details.refId);
    };

    firePlayerEvent = () => {
        this.playerEvents.emitter.emit(PLAYER_EVENTS_LIST.TRIGGER_POST_ROLL_AD);
    };

    touchControlBarOverlay = () => {
        this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.HIDE_SETTINGS_MENU, true);
    };

    hideControlBar() {
        if (!this.controlBarRef || !this.controlBarRef.current) {
            return;
        }
        this.controlBarRef.current.classList.add('d-none');
    }

    showControlBar() {
        if (!this.controlBarRef || !this.controlBarRef.current) {
            return;
        }
        this.controlBarRef.current.classList.remove('d-none');
    }

    showCenterIcon(option) {
        if (!this.centerIconRef || !this.centerIconRef.current) {
            return;
        }
        this.setState({
            centerIcon: option,
        });
        this.centerIconRef.current.classList.remove('d-none');
    }

    hideCenterIcon() {
        if (!this.centerIconRef || !this.centerIconRef.current) {
            return;
        }
        this.setState({
            centerIcon: null,
        });
        this.centerIconRef.current.classList.add('d-none');
    }

    fadeIn() {
        if (!this.overlayDivRef || !this.overlayDivRef.current) {
            return;
        }

        const { videoWrapperRef } = this.props;
        this.overlayDivRef.current.classList.remove('fade-out');
        this.overlayDivRef.current.classList.add('fade-in');
        this.setState({ isFadeIn: true });
        videoWrapperRef().style.cursor = '';
        if (this.playerCtaDivRef && this.playerCtaDivRef.current) {
            this.playerCtaDivRef.current.classList.add('cta-fade-in');
        }
    }


    fadeOut() {
        if (!this.overlayDivRef || !this.overlayDivRef.current) {
            return;
        }
        const { videoWrapperRef } = this.props;
        this.overlayDivRef.current.classList.remove('fade-in');
        this.overlayDivRef.current.classList.add('fade-out');
        this.setState({ isFadeIn: false });
        videoWrapperRef().style.cursor = 'none';

        if (this.playerCtaDivRef && this.playerCtaDivRef.current) {
            this.playerCtaDivRef.current.classList.remove('cta-fade-in');
        }
        this.touchControlBarOverlay();
    }

    // slide menus functions end

    render() {
        const {
            playing, meta, currentTime, waiting, bufferedTime,
            fullScreen, videoEnded, showNextEp, showSkipIntro, showSkipCredit, volumeLevel, isMetaLoaded,
            centerIcon, isAdBreak, isFadeIn, isMute, adCuePoints,
        } = this.state;
        const {
            getVideoTag, logoUrl, forwardSkipDuration, backwardSkipDuration,
            playbackSessionId, nextEpisodeDetails, skipIntroDur, enableBitrateSelector, skipCreditDur,
            browser, previewImageInfo, setSettingsVisibility, videoMeta, showPreRollAds, playbackDetails, adsPlayingFlag, contentDetails,
            contentDetailsFetchActionDispatch, location, playingOnBanner, showPlayback, contentDetails: { contentAutoplay = false, durSec }, isTrailer = false, adConfig,
            sdkAdsPlayingFlag,
        } = this.props;

        const { enabled } = this.state;
        // show corner controls if functions are initiated and its not a iPhone
        const cornerControls = (isMetaLoaded && enabled && this.playerFunctions && !(this.deviceUtil.getDeviceName() === HARDWARE_LIST.IPHONE))
            ? (
                <>
                    <div
                        className="bottom-player-controls-options"
                        ref={this.controlBarRef}
                    >
                        <BottomToolbarComponent
                            duration={meta.duration}
                            currentTime={currentTime}
                            bufferedTime={bufferedTime}
                            seekBarChange={this.seekBarChange}
                            adCuePoints={adCuePoints}
                            pause={this.pause}
                            play={this.play}
                            isPaused={this.playerFunctions.paused}
                            controlBarEventEmitter={this.controlBarEventEmitter}
                            previewImageInfo={previewImageInfo}
                            fullScreen={fullScreen}
                            setSeekPauseStatus={this.setSeekPauseStatus}
                        />

                        <div className="bottom-control-wrapper">
                            <LeftPlayerControlsContainer
                                play={this.play}
                                pause={this.pause}
                                seekBackward={this.seekBackward}
                                seekForward={this.seekForward}
                                playing={playing}
                                waiting={waiting}
                                forwardSkipDuration={forwardSkipDuration}
                                backwardSkipDuration={backwardSkipDuration}
                                playerFunctions={this.playerFunctions}
                                onPlayerFunctionsNotFound={this.onPlayerFunctionsNotFound}
                                controlBarEventEmitter={this.controlBarEventEmitter}
                                enabled={enabled}
                                videoEnded={videoEnded}
                            />
                            <div className="center-control-container" />
                            <RightPlayerControlsContainer
                                toggleFullScreen={this.toggleFullScreen}
                                getVideoTag={getVideoTag}
                                setFullScreenState={this.setFullScreenState}
                                fullScreen={fullScreen}
                                videoEnded={videoEnded}
                                lang={Object.values(playbackDetails)[0]?.plg}
                                nextEpisodeDetails={nextEpisodeDetails}
                                onHover={this.onControlHover}
                                onLeaveHover={this.onControlLeave}
                                onPlayerFunctionsNotFound={this.onPlayerFunctionsNotFound}
                                controlBarEventEmitter={this.controlBarEventEmitter}
                                meta={meta}
                                enableBitrateSelector={enableBitrateSelector}
                                playerFunctions={this.playerFunctions}
                                playbackSessionId={playbackSessionId}
                                enabled={enabled}
                                browser={browser}
                                renditionFromLocalStorage={this.renditionFromLocalStorage}
                                hideSettingsButton={contentAutoplay && !fullScreen}
                                contentDetails={contentDetails}
                                autoFullScreen={!contentAutoplay && !isTrailer}
                                setSettingsVisibility={setSettingsVisibility}
                                adConfig={adConfig}
                                firePlayerEvent={this.firePlayerEvent}
                            />
                        </div>
                    </div>

                </>
            ) : null;
        let playerCtaElement = null;
        if (this.playerFunctions) {
            const LANGUAGE = LanguageProvider();
            const { SKIP_CREDIT = 'Skip Credit', SKIP_INTRO = 'Skip Intro' } = LANGUAGE;
            if (showNextEp && !adsPlayingFlag && !sdkAdsPlayingFlag) {
                playerCtaElement = (
                    <NextEpisodeCtaComponent
                        link={nextEpisodeDetails.url}
                        title={nextEpisodeDetails.nextEpLinkTitle}
                        ctaText={LANGUAGE.NEXT_EPISODE}
                        nextEpisodeDetails={nextEpisodeDetails}
                        contentDetailsFetchActionDispatch={this.onNextEpisodeClick}
                    />

                );
            }
            else if (!sdkAdsPlayingFlag && (showSkipIntro || showSkipCredit)) {
                playerCtaElement = (
                    <SkipIntroButtonComponent
                        skipDur={showSkipCredit ? durSec : skipIntroDur}
                        onClickFn={this.skipIntro}
                        ctaText={showSkipCredit ? SKIP_CREDIT : showSkipIntro ? SKIP_INTRO : ''}
                        showSkipCredit={showSkipCredit}
                        currentTime={currentTime}
                        contentDetails={contentDetails}
                        getSkipDurationMeta={this.getSkipDurationMeta}
                    />
                );
            }
        }

        if (!this.deviceUtil.isMobile() && !playingOnBanner && !fullScreen && !showPlayback) {
            const LANGUAGE = LanguageProvider();
            const fullScreenTogglerSrc = fullScreen ? '/static/fulllscreen-new.svg' : '/static/fullscreen-exit-new.svg';
            return (
                <>
                    <div className="banner-control volume-button">
                        { videoEnded ? (
                            <AnalyticsButtonComponent
                                onClick={this.playerFunctions.play}
                                className="bottom-control-buttons  btm-control-fullscreen scale-hover"
                                type="button"
                                title="Replay"
                                aria-label="Replay"
                            >
                                <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="20"
                                        cy="20"
                                        r="20"
                                        fill="#262B33"
                                    />
                                    <path
                                        d="M24.5156 17.3486H29.0156V12.8486"
                                        stroke="#CFD3DC"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M25.8336 25.8336C24.6798 26.9874 23.2098 27.7732 21.6095 28.0915C20.0092 28.4098 18.3504 28.2464 16.8429 27.622C15.3354 26.9976 14.0469 25.9402 13.1404 24.5835C12.2339 23.2268 11.75 21.6317 11.75 20C11.75 18.3683 12.2339 16.7733 13.1404 15.4165C14.0469 14.0598 15.3354 13.0024 16.8429 12.378C18.3504 11.7536 20.0092 11.5902 21.6095 11.9085C23.2098 12.2269 24.6798 13.0126 25.8336 14.1664L29.0156 17.3484"
                                        stroke="#CFD3DC"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </AnalyticsButtonComponent>
                        ) : (
                            <AnalyticsButtonComponent
                                onClick={this.toggleMute}
                                className="bottom-control-buttons  btm-control-fullscreen scale-hover"
                                type="button"
                                title="Toggle volume"
                                aria-label="Toggle volume"
                            >
                                { isMute ? (
                                    <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="20"
                                            cy="20"
                                            r="20"
                                            fill="#262B33"
                                        />
                                        <path
                                            d="M17.8115 16.251V23.751"
                                            stroke="#CFD3DC"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M14.6875 11.75L30.3125 28.25"
                                            stroke="#CFD3DC"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M24.8438 22.4753V29.0014L17.8125 23.7514H13.125C12.9178 23.7514 12.7191 23.6724 12.5726 23.5317C12.4261 23.3911 12.3438 23.2003 12.3438 23.0014V17.0014C12.3438 16.8025 12.4261 16.6117 12.5726 16.4711C12.7191 16.3304 12.9178 16.2514 13.125 16.2514H17.8125L18.4788 15.7539"
                                            stroke="#CFD3DC"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M20.9531 13.9062L24.8441 11.001V18.0151"
                                            stroke="#CFD3DC"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )
                                    : (
                                        <svg
                                            width="40"
                                            height="40"
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g filter="url(#filter0_b_0_313)">
                                                <circle
                                                    cx="20"
                                                    cy="20"
                                                    r="20"
                                                    fill="#262B33"
                                                />
                                            </g>
                                            <path
                                                d="M16.7278 23.9709H11.8174C11.6004 23.9709 11.3922 23.8873 11.2387 23.7383C11.0852 23.5894 10.999 23.3874 10.999 23.1768V16.8232C10.999 16.6126 11.0852 16.4106 11.2387 16.2617C11.3922 16.1127 11.6004 16.0291 11.8174 16.0291H16.7278L24.0933 10.4697V29.5303L16.7278 23.9709Z"
                                                stroke="#CFD3DC"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M16.7275 16.0293V23.9712"
                                                stroke="#CFD3DC"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M28.041 17.7539C28.345 18.0489 28.5861 18.3991 28.7506 18.7845C28.9151 19.1699 28.9998 19.583 28.9998 20.0002C28.9998 20.4174 28.9151 20.8305 28.7506 21.2159C28.5861 21.6013 28.345 21.9515 28.041 22.2465"
                                                stroke="#CFD3DC"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <defs>
                                                <filter
                                                    id="filter0_b_0_313"
                                                    x="-4"
                                                    y="-4"
                                                    width="48"
                                                    height="48"
                                                    filterUnits="userSpaceOnUse"
                                                    colorInterpolationFilters="sRGB"
                                                >
                                                    <feFlood
                                                        floodOpacity="0"
                                                        result="BackgroundImageFix"
                                                    />
                                                    <feGaussianBlur
                                                        in="BackgroundImageFix"
                                                        stdDeviation="2"
                                                    />
                                                    <feComposite
                                                        in2="SourceAlpha"
                                                        operator="in"
                                                        result="effect1_backgroundBlur_0_313"
                                                    />
                                                    <feBlend
                                                        mode="normal"
                                                        in="SourceGraphic"
                                                        in2="effect1_backgroundBlur_0_313"
                                                        result="shape"
                                                    />
                                                </filter>
                                            </defs>
                                        </svg>
                                    )}
                            </AnalyticsButtonComponent>
                        ) }
                    </div>
                    <div className="banner-control toggle-fullscreen d-none">
                        <AnalyticsButtonComponent
                            id={ELEMENT_ID.TOGGLE_FULLSCREEN}
                            onClick={this.toggleFullScreen}
                            className="bottom-control-buttons  btm-control-fullscreen scale-hover"
                            type="button"
                            title={LANGUAGE.PLAYER_TOGGLE_FULL_SCREEN}
                            aria-label={LANGUAGE.PLAYER_TOGGLE_FULL_SCREEN}
                        >
                            <ThumborImage
                                src={fullScreenTogglerSrc}
                                imageChanged
                            />
                        </AnalyticsButtonComponent>
                    </div>
                </>
            );
        }

        return (
            <>
                <div
                    className="vdo-player__overlay"
                    ref={this.overlayDivRef}
                    role="presentation"
                    onClick={this.handleOverlayClick}
                    onKeyUp={this.handleOverlayClick}
                    fade-in={`${isFadeIn}`}
                >
                    <div
                        className="settings-layer d-none"
                        ref={this.optionLayerRef}
                        onTouchEnd={this.touchControlBarOverlay}
                    />
                    <OverlayDescription
                        fullScreen={fullScreen}
                        logoUrl={logoUrl}
                        videoMeta={videoMeta}
                        toggleFullScreen={this.toggleFullScreen}
                        hideTitle={contentAutoplay && !fullScreen}
                        isMobile={this.deviceUtil.isMobile()}
                        contentAutoplay={contentAutoplay}
                        cpId={contentDetails.cpId}
                    />
                    <div className="vdo-player__center-controls d-flex justify-content-between align-items-center">
                        <CenterToolbarComponent
                            play={this.play}
                            pause={this.pause}
                            backward={this.seekBackward}
                            forward={this.seekForward}
                            playing={playing}
                            showPreRollAds={showPreRollAds}
                            waiting={waiting}
                            forwardSkipDuration={forwardSkipDuration}
                            backwardSkipDuration={backwardSkipDuration}
                            isMetaLoaded={isMetaLoaded}
                        />
                    </div>
                    {cornerControls}
                </div>
                {
                    playerCtaElement && !sdkAdsPlayingFlag && !adsPlayingFlag ? (
                        <div
                            className="vdo_player-cta"
                            ref={this.playerCtaDivRef}
                            id={`${showNextEp ? 'atm_next-episode-cta' : ''}`}
                        >
                            {playerCtaElement}
                        </div>
                    ) : null
                }
                <CenterIconComponent
                    ref={{
                        centerIconRef: this.centerIconRef,
                    }}
                    level={volumeLevel}
                    option={centerIcon}
                    fullScreen={fullScreen}
                />
            </>
        );
    }
}

BasicControlBar.propTypes = {
    forwardSkipDuration: PropTypes.number.isRequired,
    backwardSkipDuration: PropTypes.number.isRequired,
    onRef: PropTypes.func,
    onPlayerFunctionsNotFound: PropTypes.func,
    videoWrapperRef: PropTypes.func.isRequired,
    getVideoTag: PropTypes.func.isRequired,
    playbackDetails: PropTypes.object.isRequired,
    logoUrl: PropTypes.string,
    videoMeta: PropTypes.shape({
        videoTitle: PropTypes.string,
        subTitle: PropTypes.string,
    }),
    browser: PropTypes.string.isRequired,
    adsManager: PropTypes.func.isRequired,
    showPreRollAds: PropTypes.bool.isRequired,
    dispatchPrerollAdsPlaying: PropTypes.func.isRequired,
    enableBitrateSelector: PropTypes.bool,
    playbackSessionId: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    nextEpisodeDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    skipIntroDur: PropTypes.number.isRequired,
    previewImageInfo: PropTypes.shape({
        imageUrl: PropTypes.string,
        imageInterval: PropTypes.string,
    }),
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    removeContentDispatch: PropTypes.func.isRequired,
    playTrailer: PropTypes.bool,
    contentDetailsFetchActionDispatch: PropTypes.func.isRequired,
    adConfig: PropTypes.object.isRequired,
    sdkAdsPlayingFlag: PropTypes.bool,
};

BasicControlBar.defaultProps = {
    onRef: () => null,
    onPlayerFunctionsNotFound: () => null,
    logoUrl: '',
    enableBitrateSelector: true,
    playbackSessionId: null,
    previewImageInfo: null,
    videoMeta: {
        videoTitle: '',
        subTitle: '',
    },
    nextEpisodeDetails: null,
    playTrailer: true,
    sdkAdsPlayingFlag: false,
};
const mapStateToProps = (state) => {
    const {
        playbackDetails,
        layoutDetails,
        appConfig,
        notify: {
            showPlayback,
            playingOnBanner,
        },
    } = state;
    const { adsManager = null, showPreRollAds = null, adsPlayingFlag = false, sdkAdsPlayingFlag = false } = playbackDetails;
    const {
        web_pageId = {},
    } = appConfig;
    const { SDK_AD_CONFIG = 'adsConfig' } = web_pageId;
    const adConfig = layoutDetails[SDK_AD_CONFIG];
    // const preRollAdsPlaying = lodashGet(playbackDetails, 'preRollAdsPlaying', null);
    return {
        adsManager,
        adConfig,
        showPreRollAds,
        playbackDetails,
        adsPlayingFlag,
        web_next_episode_timer: appConfig?.web_next_episode_timer,
        showPlayback,
        playingOnBanner,
        sdkAdsPlayingFlag,
    };
};

export default connect(mapStateToProps, {
    dispatchPrerollAdsPlaying: getPrerollAdsPlaying,
    removeContentDispatch: syncContentRemove,
    contentDetailsFetchActionDispatch: contentDetailsFetchAction,
})(withRouter(withDeviceUtil(withStyles(styles)(BasicControlBar))));
