import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';

export const ContentImageProvider = ({
    imgType = CONTENT_IMAGE_TYPES.PORTRAIT,
    imageMap = {},
}) => {
    if (!imageMap) {
        return '';
    }

    if (Array.isArray(imgType)) {
        for (let index = 0; index < imgType.length; index++) {
            const item = imgType[index];
            if (imageMap[item]) {
                return imageMap[item];
            }
        }
    }
    else if (imageMap[imgType]) {
        return imageMap[imgType];
    }

    return (
        imageMap[CONTENT_IMAGE_TYPES.PORTRAIT]
        || imageMap[CONTENT_IMAGE_TYPES.LANDSCAPE_169]
        || imageMap[CONTENT_IMAGE_TYPES.LANDSCAPE_43]
        || imageMap[CONTENT_IMAGE_TYPES.LOGO]
        || imageMap[CONTENT_IMAGE_TYPES.FEATURE_BANNER]
        || imageMap[CONTENT_IMAGE_TYPES.RECTANGULAR]
        || imageMap[CONTENT_IMAGE_TYPES.CIRCULAR]
        || imageMap[CONTENT_IMAGE_TYPES.LOGOS_TNPT]
        || ''
    );
};

export const getPosterUrlFromContentDetails = contentDetails => ContentImageProvider({
    imageMap: contentDetails && contentDetails.images,
    imgType: [
        CONTENT_IMAGE_TYPES.LANDSCAPE_169,
        CONTENT_IMAGE_TYPES.LANDSCAPE_43,
        CONTENT_IMAGE_TYPES.PORTRAIT,
    ],
});

export const getImageFromChannelDetails = channel => ContentImageProvider({
    imageMap: channel && channel.imgs,
    imgType: [
        CONTENT_IMAGE_TYPES.LOGOS_TNPT,
        CONTENT_IMAGE_TYPES.PORTRAIT,
        CONTENT_IMAGE_TYPES.LANDSCAPE_169,
        CONTENT_IMAGE_TYPES.LOGO,
        CONTENT_IMAGE_TYPES.FEATURE_BANNER,
    ],
});

export default {
    ContentImageProvider,
    getPosterUrlFromContentDetails,
};
