import { CONTENT_PROVIDERS } from './ContentProviderConst';

export const PLAYER_VERSION = {
    VIDEOJS_PLAYER: '7.2.4',
};
export const PLAYER_TYPES = {
    SHAKA_PLAYER: 'SHAKA_PLAYER',
    VIDEOJS_PLAYER: 'VIDEOJS_PLAYER',
    HLS_PLAYER: 'HLS_PLAYER',
    MINI_PLAYER: 'MINI_PLAYER',
    SHAKA_HLS_PLAYER: 'SHAKA_HLS_PLAYER',
};

export const PLAYER_EVENTS_LIST = {
    PLAY: 'PLAY',
    PAUSE: 'PAUSE',
    FIRST_PLAY: 'FIRST_PLAY',
    FIRST_PLAY_V1: 'FIRST_PLAY_V1',
    READY_TO_PLAY: 'READY_TO_PLAY',
    FULL_SCREEN_CHANGE: 'FULL_SCREEN_CHANGE',
    ENDEND: 'ENDEND',
    DISPOSE: 'DISPOSE',
    ERROR: 'ERROR',
    LOADED: 'LOADED',
    LOADED_V1: 'LOADED_V1',
    TIMEUPDATE: 'TIMEUPDATE',
    WAITING: 'WAITING',
    PLAYING: 'PLAYING',
    DURATION_CHANGE: 'DURATION_CHANGE',
    PROGRESS: 'PROGRESS',
    LOADSTART: 'LOADSTART',
    LOADSTART_V1: 'LOADSTART_V1',
    SEEK_BAR_CHANGE_V1: 'SEEK_BAR_CHANGE_V1',
    SEEK_BAR_CHANGE: 'SEEK_BAR_CHANGE',
    SEEKED: 'SEEKED',
    LIVE_TIME_UPDATE: 'LIVE_TIME_UPDATE',
    SEEKA_BLEEND_CHANGE: 'SEEKA_BLEEND_CHANGE',
    LIVE_BUTTON_CLICK: 'LIVE_BUTTON_CLICK',
    SEEK_FORWARD: 'SKIP_FORWARD',
    SEEK_BACKWARD: 'SEEK_BACKWARD',
    RETRY_PLAYLIST: 'RETRY_PLAYLIST',
    CHANGE_BIT_RATE: 'CHANGE_BIT_RATE',
    CAN_PLAY_THROUGH: 'CAN_PLAY_THROUGH',
    VOLUME_CHANGE: 'VOLUME_CHANGE',
    OPEN_PIP: 'OPEN_PIP',
    CLOSE_PIP: 'CLOSE_PIP',
    AD_STREAM_LOADED: 'AD_STREAM_LOADED',
    AD_STREAM_ERROR: 'AD_STREAM_ERROR',
    AD_BREAK_STARTED: 'AD_BREAK_STARTED',
    AD_BREAK_ENDED: 'AD_BREAK_ENDED',
    AD_CLICK: 'AD_CLICK',
    AD_PAUSE: 'AD_PAUSE',
    AD_RESUME: 'AD_RESUME',
    PLAY_START: 'PLAY_START',
    PLAYER_SEEK: 'PLAYER_SEEK',
    SEEK_PAUSE: 'SEEK_PAUSE',
    // MANIFEST_API_INIT: 'MANIFEST_API_INIT',
    // MANIFEST_API_RESPONSE: 'MANIFEST_API_RESPONSE',
    LICENSE_API_INIT: 'LICENSE_API_INIT',
    LICENSE_API_RESPONSE: 'LICENSE_API_RESPONSE',
    AUDIO_CHANGE: 'AUDIO_CHANGE',
    SEEKTIME: 'SEEKTIME',
    SEEK_TO_CURRENT_TIME: 'SEEK_TO_CURRENT_TIME',
    SUBTITLE_CHANGE: 'SUBTITLE_CHANGE',
    SUBTITLE_ADDED: 'SUBTITLE_ADDED',
    TRACK_CHANGED: 'TRACK_CHANGED',
    WAITING_V2: 'WAITING_V2',
    CAN_PLAY_THROUGH_V2: 'CAN_PLAY_THROUGH_V2',
    RATECHANGE: 'RATECHANGE',
    UNMUTE_V2: 'UNMUTE_V2',
    MUTE_V2: 'MUTE_V2',
    CHANGE_BIT_RATE_V2: 'CHANGE_BIT_RATE_V2',
    CHANGE_SUBTITLES: 'CHANGE_SUBTITLES',
    CHANGE_PLAYBACK_SPEED: 'CHANGE_PLAYBACK_SPEED',
    NEXT_EPISODE_CLICK: 'NEXT_EPISODE_CLICK',
    CHANGE_AUDIO_LANGUAGE: 'CHANGE_AUDIO_LANGUAGE',
    RETRY: 'RETRY',
    SUBTITLE_TOGGLE: 'SUBTITLE_TOGGLE',
    OVERLAY_VISIBLE: 'overlay_visible',
    OVERLAY_INVISIBLE: 'overlay_invisible',
    SKIP_INTRO: 'skip_intro',
    SKIP_CREDIT: 'skip_credit',
    ERROR_CDN_RETRY: 'ERROR_CDN_RETRY',
    BUFFERING: 'BUFFERING',
    AD_PROGRESS: 'AD_PROGRESS',
    ADS_SDK_EVENT: 'ADS_SDK_EVENT',
    AD_CUEPOINTS_CHANGED: 'AD_CUEPOINTS_CHANGED',
    TRIGGER_POST_ROLL_AD: 'TRIGGER_POST_ROLL_AD',
    CONTENT_WITH_POSTROLL_COMPLETION: 'CONTENT_WITH_POSTROLL_COMPLETION',
    PLAYER_EVENTS_EMITTER_ENABLED: 'PLAYER_EVENTS_EMITTER_ENABLED',
};

export const SHAKA_PLAYER_EVENTS = {
    [PLAYER_EVENTS_LIST.PLAY]: 'playing',
    [PLAYER_EVENTS_LIST.PAUSE]: 'pause',
    [PLAYER_EVENTS_LIST.FIRST_PLAY]: 'firstplay',
    [PLAYER_EVENTS_LIST.FULL_SCREEN_CHANGE]: 'fullscreenchange',
    [PLAYER_EVENTS_LIST.ENDEND]: 'ended',
    [PLAYER_EVENTS_LIST.ERROR]: 'error',
    [PLAYER_EVENTS_LIST.LOADSTART]: 'loadstart',
    [PLAYER_EVENTS_LIST.SEEKED]: 'seeked', // Native seek for videotag, use after debounce
    [PLAYER_EVENTS_LIST.LOADSTART_V1]: 'loadeddata', // V1 event
    [PLAYER_EVENTS_LIST.LOADED]: 'loadeddata',
    [PLAYER_EVENTS_LIST.TIMEUPDATE]: 'timeupdate',
    [PLAYER_EVENTS_LIST.WAITING]: 'waiting',
    [PLAYER_EVENTS_LIST.PLAYING]: 'playing',
    [PLAYER_EVENTS_LIST.PROGRESS]: 'progress',
    [PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE]: 'seek_bar_change',
    [PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE]: 'live_time_update',
    [PLAYER_EVENTS_LIST.SEEKA_BLEEND_CHANGE]: 'timeandseekrangeupdated',
    [PLAYER_EVENTS_LIST.LIVE_BUTTON_CLICK]: 'live_button_click',
    [PLAYER_EVENTS_LIST.RETRY_PLAYLIST]: '',
    [PLAYER_EVENTS_LIST.CHANGE_BIT_RATE]: 'adaptation',
    [PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH]: 'canplaythrough',
    [PLAYER_EVENTS_LIST.VOLUME_CHANGE]: 'volumechange',
    [PLAYER_EVENTS_LIST.OPEN_PIP]: 'enterpictureinpicture',
    [PLAYER_EVENTS_LIST.CLOSE_PIP]: 'leavepictureinpicture',
    [PLAYER_EVENTS_LIST.TRACK_CHANGED]: 'trackschanged',
    [PLAYER_EVENTS_LIST.RATECHANGE]: 'ratechange',
    [PLAYER_EVENTS_LIST.MUTE_V2]: 'mute',
    [PLAYER_EVENTS_LIST.UNMUTE_V2]: 'unmute',
    [PLAYER_EVENTS_LIST.BUFFERING]: 'buffering',
};

export const LITE_PLAYER_EVENTS = {
    [PLAYER_EVENTS_LIST.PLAY]: 'playing',
    [PLAYER_EVENTS_LIST.FIRST_PLAY]: 'firstplay',
    [PLAYER_EVENTS_LIST.ENDEND]: 'ended',
    [PLAYER_EVENTS_LIST.ERROR]: 'error',
    [PLAYER_EVENTS_LIST.LOADED]: 'loadeddata',
    [PLAYER_EVENTS_LIST.WAITING]: 'waiting',
    [PLAYER_EVENTS_LIST.PLAYING]: 'playing',
    [PLAYER_EVENTS_LIST.PROGRESS]: 'progress',
    [PLAYER_EVENTS_LIST.RETRY_PLAYLIST]: '',
    [PLAYER_EVENTS_LIST.CHANGE_BIT_RATE]: 'adaptation',
    [PLAYER_EVENTS_LIST.VOLUME_CHANGE]: 'volumechange',
};

export const VIDEOJS_PLAYER_EVENTS = {
    [PLAYER_EVENTS_LIST.PLAY]: 'play',
    [PLAYER_EVENTS_LIST.PAUSE]: 'pause',
    [PLAYER_EVENTS_LIST.FIRST_PLAY]: 'firstplay',
    [PLAYER_EVENTS_LIST.FULL_SCREEN_CHANGE]: 'fullscreenchange',
    [PLAYER_EVENTS_LIST.ENDEND]: 'ended',
    [PLAYER_EVENTS_LIST.ERROR]: 'error',
    [PLAYER_EVENTS_LIST.LOADSTART]: 'loadstart',
    [PLAYER_EVENTS_LIST.LOADED]: 'loadeddata',
    [PLAYER_EVENTS_LIST.SEEKED]: 'seeked', // Native seek for videotag, use after debounce
    [PLAYER_EVENTS_LIST.LOADEDMETADATA]: 'loadedmetadata',
    [PLAYER_EVENTS_LIST.LOADSTART_V1]: 'loadedmetadata', // v1
    [PLAYER_EVENTS_LIST.LOADED_V1]: 'loadedmetadata', // v1
    [PLAYER_EVENTS_LIST.TIMEUPDATE]: 'timeupdate',
    [PLAYER_EVENTS_LIST.WAITING]: 'waiting',
    [PLAYER_EVENTS_LIST.PLAYING]: 'playing',
    [PLAYER_EVENTS_LIST.DURATION_CHANGE]: 'durationchange',
    [PLAYER_EVENTS_LIST.PROGRESS]: 'progress',
    [PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE]: 'seek_bar_change',
    [PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE]: 'live_time_update',
    [PLAYER_EVENTS_LIST.SEEKA_BLEEND_CHANGE]: 'seekableendchange',
    [PLAYER_EVENTS_LIST.LIVE_BUTTON_CLICK]: 'live_button_click',
    [PLAYER_EVENTS_LIST.RETRY_PLAYLIST]: 'retryplaylist',
    [PLAYER_EVENTS_LIST.CHANGE_BIT_RATE]: 'ratechange',
    [PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH]: 'canplaythrough',
    [PLAYER_EVENTS_LIST.VOLUME_CHANGE]: 'volumechange',
    [PLAYER_EVENTS_LIST.OPEN_PIP]: 'enterpictureinpicture',
    [PLAYER_EVENTS_LIST.CLOSE_PIP]: 'leavepictureinpicture',
    [PLAYER_EVENTS_LIST.MUTE_V2]: 'mute',
    [PLAYER_EVENTS_LIST.UNMUTE_V2]: 'unmute',
};

export const HLS_PLAYER_EVENTS = {
    [PLAYER_EVENTS_LIST.PLAY]: 'playing',
    [PLAYER_EVENTS_LIST.PAUSE]: 'pause',
    [PLAYER_EVENTS_LIST.FIRST_PLAY]: 'firstplay',
    [PLAYER_EVENTS_LIST.FULL_SCREEN_CHANGE]: 'fullscreenchange',
    [PLAYER_EVENTS_LIST.ENDEND]: 'ended',
    [PLAYER_EVENTS_LIST.ERROR]: 'error',
    [PLAYER_EVENTS_LIST.LOADSTART]: 'loadeddata',
    [PLAYER_EVENTS_LIST.LOADED]: 'loadeddata',
    [PLAYER_EVENTS_LIST.TIMEUPDATE]: 'timeupdate',
    [PLAYER_EVENTS_LIST.WAITING]: 'waiting',
    [PLAYER_EVENTS_LIST.PLAYING]: 'playing',
    [PLAYER_EVENTS_LIST.PROGRESS]: 'progress',
    [PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE]: 'seek_bar_change',
    [PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE]: 'live_time_update',
    [PLAYER_EVENTS_LIST.SEEKA_BLEEND_CHANGE]: 'timeandseekrangeupdated',
    [PLAYER_EVENTS_LIST.LIVE_BUTTON_CLICK]: 'live_button_click',
    [PLAYER_EVENTS_LIST.RETRY_PLAYLIST]: '',
    [PLAYER_EVENTS_LIST.CHANGE_BIT_RATE]: 'adaptation',
    [PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH]: 'canplaythrough',
    [PLAYER_EVENTS_LIST.VOLUME_CHANGE]: 'volumechange',
    [PLAYER_EVENTS_LIST.OPEN_PIP]: 'enterpictureinpicture',
    [PLAYER_EVENTS_LIST.CLOSE_PIP]: 'leavepictureinpicture',
};

export const CONTROL_BAR_EVENTS = {
    UPDATE_SEEK_BAR_VISIBILITY: 'update_seek_bar_visibility',
    UPDATE_VOLUME_BAR: 'update_volume_bar',
    SETTINGS_OVERLAY_TOGGLE: 'settings_overlay_toggle',
    HIDE_SETTINGS_MENU: 'hide_settings_menu',
    CENTER_ICON_DISPLAY: 'center_icon_display',
};

export const CENTER_ICONS = {
    VOLUME: 'volume',
    PAUSE: 'pause',
    PLAY: 'play',
};


export const PLAYBACK_TYPES = {
    MPD: 'application/dash+xml',
    M3U8: 'application/x-mpegURL',
    MP4: 'video/mp4',
    WEBM: 'video/webm',
    OGG: 'video/ogg',
    'M3U8-AAPL': 'application/x-mpegURL',
};

export const PLAYBACK_TYPES_MINI_PLAYER = {
    CHANNEL_PARTNER: 'CHANNEL_PARTNER',
    TRAILER: 'TRAILER',
    DEFAULT: 'DEFAULT',
};

export const PLAYBACK_MODES = {
    PLAY_URL: 'PLAY_URL',
    DAI: 'DAI',
};

export const PLAYBACK_DEFAULT_CONFIGS_MINI_PLAYER = {
    [PLAYBACK_TYPES_MINI_PLAYER.CHANNEL_PARTNER]: {
        autoPlay: true,
        muteAtStart: true,
        allowtoggleMute: true,
        playButton: true,
        watchAgain: true,
        playsinline: true,
    },
    [PLAYBACK_TYPES_MINI_PLAYER.DEFAULT]: {
        autoPlay: true,
        muteAtStart: true,
        seekbar: true,
        volumeChange: true,
        playButton: true,
        pauseButtone: true,
        qualityControl: true,
        playsinline: true,
        allowtoggleMute: true,
        watchAgain: true,
    },
};

export const PLAYBACK_RATES = [
    {
        title: 'Slow',
        des: '0.5',
        value: 0.5,
    },
    {
        title: 'Normal',
        des: '1',
        value: 1,
    },
    {
        title: 'Fast',
        des: '1.5',
        value: 1.5,
    },
];

export const PLAYBACK_RENDITIONS = [
    {
        title: 'Auto',
        des: 'Adaptive',
        value: 0,
    },
    {
        title: 'High',
        des: '8 mbps',
        value: Infinity,
    },
    {
        title: 'Medium',
        des: '5 mbps',
        value: 868352,
    },
    {
        title: 'Low',
        des: '500 kbps',
        value: -Infinity,
    },
];

export const PLAYER_RENDITIONS_FOR_PERSISTANCE = {
    HIGHEST: 'highest',
    LOWEST: 'lowest',
};

export const SUBTITLES_MODES = {
    HIDDEN: 'hidden',
    SHOWING: 'showing',
};

export const PLAYER_CONFIG = {
    GOAL_BUFFER_LENGTH: 120,
    TRAILER_GOAL_BUFFER_LENGTH: 20,
    OVERLAY_TIMEOUT: 5000,
    ENABLE_AUTO_PLAY: false,
    FORWARD_SKIP_DURATION: 10,
    BACKWARD_SKIP_DURATION: 10,
    VOLUME_INCREASE_GAP: 0.10,
    VOLUME_DECREASE_GAP: -0.10,
};

export const SLIDE_MENU_IDENTIFIERS = {
    SETTINGS: {
        id: 'SETTINGS',
        title: 'Settings',
        defaultValue: '',
    },
    CAPTIONS: {
        id: 'CAPTIONS',
        title: 'Subtitles',
        defaultValue: 'OFF',
    },
    RENDITIONS: {
        id: 'RENDITIONS',
        title: 'Quality',
        defaultValue: 'Auto',
    },
    AUDIO_LANGUAGES: {
        id: 'AUDIO_LANGUAGES',
        title: 'Audio Languages',
        defaultValue: '',
    },
    PLAYBACK_SPEED: {
        id: 'PLAYBACK_SPEED',
        title: 'Playback Speed',
        defaultValue: 'Normal',
    },
};

export const SLIDE_MENU_EVENTS_ID = {
    [SLIDE_MENU_IDENTIFIERS.RENDITIONS.title]: 'quality_icon',
    PLAYBACK_QUALITY_SELECTION: 'playback_quality_selection',
    SUBTITLE_SELECTION: 'subtitle_selection',
};

export const PLAYER_READY_STATES = {
    HAVE_NOTHING: 0,
    HAVE_METADATA: 1,
    HAVE_CURRENT_DATA: 2,
    HAVE_FUTURE_DATA: 3,
    HAVE_ENOUGH_DATA: 4,
};

export const MAX_AUTO_RETRY_LIMIT = 2;

export const DEFAULT_SUBTITLE_LANGUAGE = 'en';

export const PLAYBACK_SECURITY_LABEL = {
    [CONTENT_PROVIDERS.ZEE5]: {
        durationInSeconds: 5,
        intervalsInMinutes: [
            6,
            10,
            14,
        ],
    },
};

export const VOLUME_BUTTONS = {
    MUTE: '/static/SpeakerSlash.svg',
    LOW: '/static/SpeakerLow.svg',
    HIGH: '/static/SpeakerHigh.svg',
};


export const PREVIEW_IMAGE_GAP = 3;

export const SUBTITLE_TYPE = {
    SIDE_LOADED: 'side_loaded',
    INLINE: 'inline',
};

export const DEFAULT_CP_CONTROL_CONFIG = {
    HLS_DAI_SUPPORTED_PLATFORMS: [
        'SONYLIV_LIVE',
        'SONYLIV_VOD',
    ],
    DISTROTV: {
        appCategory: 'Entertainment',
        appName: 'Airtel Xstream Play',
        uaAppName: 'Airtel Xstream Play',
        dpName: 'airtel',
        SAMSUNG: {
            deviceMake: 'Samsung',
            manufacturer: 'Samsung',
        },
        LG: {
            deviceMake: 'LG',
            manufacturer: 'LG',
        },
        WEB: {
            deviceMake: 'WebDesktop',
            manufacturer: 'Browser',
        },
        MWEB: {
            deviceMake: 'WebMobile',
            manufacturer: 'Browser',
        },
        logTime: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        logMultiple: 60,
    },
    MINITV: {
        LG: {
            deviceType: 'A3RY7OJ8WLWPBL',
        },
        SAMSUNG: {
            deviceType: 'A3RY7OJ8WLWPBL',
        },
        WEB: {
            deviceType: 'A3HUS7ZRFC61XH',
        },
        MWEB: {
            deviceType: 'A2VRQRKILYTDP9',
        },
    },
};

export const DEFAULT_CONTENT_DESCRIPTOR_CONFIG = {
    MINITV: {
        setupTime: 700,
        descriptorDisplayDuration: 5000,
    },
}

export const PLAYER_STATE = {
    LOGIN_PAUSE: 'login_pause',
};

export const VIDEO_TAG_ID = 'video-box';

export default {
    SUBTITLE_TYPE,
    PLAYER_TYPES,
    PLAYER_EVENTS_LIST,
    PLAYBACK_TYPES,
    VIDEOJS_PLAYER_EVENTS,
    SHAKA_PLAYER_EVENTS,
    PLAYBACK_RENDITIONS,
    SUBTITLES_MODES,
    PLAYER_CONFIG,
    SLIDE_MENU_IDENTIFIERS,
    PLAYER_READY_STATES,
    MAX_AUTO_RETRY_LIMIT,
    DEFAULT_SUBTITLE_LANGUAGE,
    PLAYBACK_SECURITY_LABEL,
    PREVIEW_IMAGE_GAP,
    CONTROL_BAR_EVENTS,
    CENTER_ICONS,
    PLAYBACK_TYPES_MINI_PLAYER,
    PLAYBACK_DEFAULT_CONFIGS_MINI_PLAYER,
    LITE_PLAYER_EVENTS,
    PLAYER_RENDITIONS_FOR_PERSISTANCE,
    DEFAULT_CP_CONTROL_CONFIG,
    PLAYER_STATE,
    VIDEO_TAG_ID,
};
