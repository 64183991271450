import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import Lottie from 'lottie-react';


export const LOTTIE_MAP = {
    FMF_LOTTIE: 'FMF_LOTTIE',
    STARS_LOTTIE: 'STARS_LOTTIE',
    FMF_RENEW_LOTTIE: 'FMF_RENEW_LOTTIE',
    CONFETTI: 'CONFETTI',
    SUCCESS_CHECK: 'SUCCESS_CHECK',
    DOWNLOAD_POPUP_LOTTIE: 'DOWNLOAD_POPUP_LOTTIE',
    ONEHUB_ACTIVATION_CONFETTI: 'ONEHUB_ACTIVATION_CONFETTI',
};


const LazyLottieFactory = ({
    lottieName, loop, play, customStyleObj, containerClassName, containerStyleObj,
}) => {
    const [
        animationData,
        setAnimationData,
    ] = useState(null);

    useEffect(() => {
        const fetchLottie = async () => {
            let lottieData;
            switch (lottieName) {
                case LOTTIE_MAP.FMF_LOTTIE:
                    lottieData = await import('./components/FMFLottie.json');
                    setAnimationData(lottieData);
                    break;
                case LOTTIE_MAP.STARS_LOTTIE:
                    lottieData = await import('./components/StarsLottie.json');
                    setAnimationData(lottieData);
                    break;
                case LOTTIE_MAP.FMF_RENEW_LOTTIE:
                    lottieData = await import('./components/FMFRenewLottie.json');
                    setAnimationData(lottieData);
                    break;
                case LOTTIE_MAP.CONFETTI:
                    lottieData = await import('./components/Confetti.json');
                    setAnimationData(lottieData);
                    break;
                case LOTTIE_MAP.ONEHUB_ACTIVATION_CONFETTI:
                    lottieData = await import('./components/OnehubActivationConfetti.json');
                    setAnimationData(lottieData);
                    break;
                case LOTTIE_MAP.SUCCESS_CHECK:
                    lottieData = await import('./components/SuccessCheck.json');
                    setAnimationData(lottieData);
                    break;
                case LOTTIE_MAP.DOWNLOAD_POPUP_LOTTIE:
                    lottieData = await import('./components/DownloadXstreamLottie.json');
                    setAnimationData(lottieData);
                    break;
                default:
                    setAnimationData(null);
            }
        };

        fetchLottie();
    },
    [
        lottieName,
    ]);

    // if (!animationData) {
    //     return null;
    // }

    return (
        <div
            className={containerClassName}
            {...(Object.keys(containerStyleObj || {}).length && {
                style: containerStyleObj,
            })}
        >
            {animationData ? (
                <Lottie
                    loop={loop}
                    animationData={animationData}
                    play={play}
                    {...(Object.keys(customStyleObj || {}).length && {
                        style: customStyleObj,
                    })}
                />
            ) : null}
        </div>
    );
};

export default LazyLottieFactory;

LazyLottieFactory.propTypes = {
    lottieName: PropTypes.string.isRequired,
    loop: PropTypes.number,
    play: PropTypes.bool,
    customStyleObj: PropTypes.object,
    containerClassName: PropTypes.string,
    containerStyleObj: {},
};

LazyLottieFactory.defaultProps = {
    loop: 1,
    play: true,
    customStyleObj: {},
    containerClassName: '',
    containerStyleObj: PropTypes.object,
};
