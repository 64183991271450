import lodashGet from 'lodash/get';
import { MARKUP_TYPES } from '../../../../constants/MarkupConsts';
import MovieMarkupParser from './MovieMarkupParser';
import { PROGRAM_TYPES } from '../../../../constants/AppConst';
import TvShowMarkupParser from './TvShowMarkupParser';
import LiveTVMarkupParser from './LiveTVMarkupParser';

export default function ({
    contentDetails, baseUrl, position,
}) {
    let item = {};
    const programType = lodashGet(contentDetails, 'programType', '');
    switch (programType) {
        case PROGRAM_TYPES.MOVIE:
            item = MovieMarkupParser({
                contentDetails,
                baseUrl,
            });
            break;
        case PROGRAM_TYPES.LIVETVCHANNEL:
            item = LiveTVMarkupParser({
                contentDetails,
                baseUrl,
            });
            break;

        case PROGRAM_TYPES.TVSHOW:
            item = TvShowMarkupParser({
                contentDetails,
                baseUrl,
            });
            break;
        default:
            return null;
    }
    return {
        '@type': MARKUP_TYPES.LIST_ITEM,
        position,
        item,
    };
}
