import {
    select,
    call,
    put,
    getContext,
    takeEvery
} from 'redux-saga/effects';
import {
    getAuthConfigFromReducer, getUserConfigFromReducer,
} from '@airtel-tv/redux/StoreListing';
import { getChatbot } from '../../service/end-points/ChatbotApiService';
import { ChatbotActions, fetchChatbotSuccess } from './ChatbotAction';

function* fetchChatbot() {
    const deviceUtil = yield getContext('deviceUtil');
    const { userContentProperties } = yield select(getUserConfigFromReducer);
    const authConfig = yield select(getAuthConfigFromReducer);
    const chatbotData = yield call(getChatbot, {
        userContentProperties,
        deviceUtil,
        token: authConfig.token,
        uid: authConfig.uid,
        au: authConfig.isAuthenticated,
    });

    yield put(fetchChatbotSuccess({ payload: chatbotData }));
}


export default [
    takeEvery(ChatbotActions.FETCH_CHATBOT, fetchChatbot),
];
