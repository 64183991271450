import { DeviceTypeUtil } from '@airtel-tv/utils/DeviceTypeUtil';

export const LAYOUT_SOURCE_TYPES = {
    BE: 'BE',
    MW: 'MW',
    HW: 'HW',
    MUSIC: 'MUSIC',
    BOTH: 'BOTH',
};

export const LAYOUT_TYPES = {
    DEFAULT: 'DEFAULT',
    BRAINBAAZI: 'BRAINBAAZI',
    GOAL_SPORTS: 'GOAL_SPORTS',
    CRICKET: 'CRICKET',
    DISMISSABLE: 'DISMISSABLE',
    GRID: 'GRID',
    EXPLORE: 'EXPLORE',
};

// ty

export const TILE_TYPES = {
    WATCHLIST: 'WATCHLIST',
    CONTINUE_WATCHING: 'CONTINUE_WATCHING',
    PARALLAX: 'PARALLAX',
    CONTENT_PARTNER_RAIL: 'CONTENT_PARTNER_RAIL',
    X_CONTENT_PARTNER_RAIL_V2: 'X_CONTENT_PARTNER_RAIL_V2',
    TVSHOW_LOGO_43: 'TVSHOW_LOGO_43',
    TVSHOW_NOLOGO_43: 'TVSHOW_NOLOGO_43',
    TVSHOW_LOGO_169: 'TVSHOW_LOGO_169',
    TVSHOW_NOLOGO_169: 'TVSHOW_NOLOGO_169',
    DEFAULT_169: 'DEFAULT_169',
    TVSHOW_BIG_43: 'TVSHOW_BIG_43',
    CHANNEL: 'CHANNEL',
    CHANNEL_LIST: 'CHANNEL_LIST',
    EMPTY_STATE: 'EMPTY_STATE',
    HEADER: 'HEADER',
    FOOTER: 'FOOTER',
    MOVIE_LOGO: 'MOVIE_LOGO',
    MOVIE_NOLOGO: 'MOVIE_NOLOGO',
    MOVIE_NOLOGO_V2: 'MOVIE_NOLOGO_V2',
    BANNER: 'BANNER',
    X_INFINITY_BANNER: 'X_INFINITY_BANNER',
    X_MASTER_RAIL: 'X_MASTER_RAIL',
    LEAGUE_BANNER: 'LEAGUE_BANNER',
    CARD_TITLE_169: 'CARD_TITLE_169',
    CARD_NOTITILE_169: 'CARD_NOTITILE_169',
    SCHEDULE: 'SCHEDULE',
    LEADER_BOARD: 'LEADER_BOARD',
    UNKNOWN: 'UNKNOWN',
    MOVIE: 'MOVIE',
    TV_SHOWS: 'TV_SHOWS',
    LIVE_TV_SHOW: 'LIVE_TV_SHOW',
    LIVE_TV_MOVIE: 'LIVE_TV_MOVIE',
    VIDEO: 'VIDEO',
    PEOPLE: 'PEOPLE',
    SPORTS: 'SPORTS',
    HIGHLIGHTED: 'HIGHLIGHTED',
    NATIVE_BANNER_AD: 'NATIVE_BANNER_AD',
    NATIVE_VIDEO_AD: 'NATIVE_VIDEO_AD',
    NATIVE_MASTHEAD_AD: 'NATIVE_MASTHEAD_AD',
    NATIVE_EPG_AD: 'NATIVE_EPG_AD',
    FOLLOW_TEAM: 'FOLLOW_TEAM',
    CUSTOM: 'CUSTOM',
    EXPLORE: 'EXPLORE',
    GRID: 'GRID',
    PLAYALONG: 'PLAYALONG',
    USER_TYPE_CARD: 'USER_TYPE_CARD',
    SUBSCRIBED_CP_RAIL: 'SUBSCRIBED_CP_RAIL',
    MULTILIST_RAIL: 'MULTILIST_RAIL',
    CONTENT_PLAY_RAIL: 'CONTENT_PLAY_RAIL',
    BANNER_CONTENT_RAIL: 'BANNER_CONTENT_RAIL',
    RELATED_CONTENT_RAIL: 'RELATED_CONTENT_RAIL',
    CONTENT_DESCRIPTION_DETAIL_RAIL: 'CONTENT_DESCRIPTION_DETAIL_RAIL',
    PEOPLE_RAIL: 'PEOPLE_RAIL',
    TRAILERS_RAIL: 'X_TRAILERS_RAIL',
    CONTINUE_WATCHING_V2: 'X_CONTINUE_WATCHING_V2',
    WATCHLIST_V2: 'X_WATCHLIST_V2',
    X_FULL_BANNER: 'X_FULL_BANNER',
    X_HIGHLIGHTED_PORTRAIT: 'X_HIGHLIGHTED_PORTRAIT',
    X_PILLS_RAIL: 'X_PILLS_RAIL',
    X_EXPLORE_V2: 'X_EXPLORE_V2',
    X_TOP_NUMBER_RAIL_V2: 'X_TOP_NUMBER_RAIL_V2',
    PLAYER_WIDGET: 'PLAYER_WIDGET',
    X_CDP_TRAILER_RAIL: 'X_CDP_TRAILER_RAIL',
    X_PYW_RAIL: 'X_PYW_RAIL',
    X_VERTICAL_TRAILER_RAIL: 'X_VERTICAL_TRAILER_RAIL',
    X_FREE_CHANNELS: 'X_FREE_CHANNELS',
    X_WARNING_RAIL: 'X_WARNING_RAIL',
    X_TOP_NAVIGATION_BAR: 'X_TOP_NAVIGATION_BAR',
    X_SUBSCRIPTION_RAIL: 'X_SUBSCRIPTION_RAIL',
    X_ACTIVE_PLAN: 'X_ACTIVE_PLAN',
    X_PARTNER_ACTIVATION_RAIL: 'X_PARTNER_ACTIVATION_RAIL',
    X_PLAN_LISTING_RAIL: 'X_PLAN_LISTING_RAIL',
    X_PARTNER_LANDSCAPE_RAIL: 'X_PARTNER_LANDSCAPE_RAIL',
    X_CONTENT_REDIRECTION_RAIL: 'X_CONTENT_REDIRECTION_RAIL',
    PARTNER_CHANNEL_DETAILED_RAIL: 'PARTNER_CHANNEL_DETAILED_RAIL',
    X_PLAN_COMING_SOON: 'X_PLAN_COMING_SOON',
    X_PLAN_TITLE_RAIL: 'X_PLAN_TITLE_RAIL',
};


export const RAIL_TYPES = {

    // copied from android
    RAIL: 'RAIL',
    BANNER: 'BANNER',
    LEAGUE_BANNER: 'LEAGUE_BANNER',
    CARD: 'CARD',
    SCHEDULE: 'SCHEDULE',
    MASTHEAD: 'MASTHEAD',
    UNKNOWN: 'UNKNOWN',
    FOLLOW_TEAM: 'FOLLOW_TEAM',
    CUSTOM: 'CUSTOM',
    PLAYALONG: 'PLAYALONG',
    USER_TYPE_CARD: 'USER_TYPE_CARD',
    X_TOP_NAVIGATION_BAR: 'X_TOP_NAVIGATION_BAR',

    // made for web
    HEADING_SECTION_RAIL: 'HEADING_SECTION_RAIL',
    LARGE_SLIDER_SECTION_RAIL: 'LARGE_SLIDER_SECTION_RAIL',
    NORMAL_SLIDER_SECTION_RAIL_PORTRAIT: 'NORMAL_SLIDER_SECTION_RAIL_PORTRAIT',
    NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE: 'NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE',
    ADD_SECTION_RAIL: 'ADD_SECTION_RAIL',
    DEFAULT_169_RAIL: 'DEFAULT_169_RAIL',
    TVSHOW_LOGO_43_RAIL: 'TVSHOW_LOGO_43_RAIL',
    GRID_SQUARE_EXPLORE_RAIL: 'GRID_SQUARE_EXPLORE_RAIL',
    GRID_ROUND_SECTION_RAIL: 'GRID_ROUND_SECTION_RAIL',
    GRID_RECTANGLE_CHANNEL_RAIL: 'GRID_RECTANGLE_CHANNEL_RAIL',
    CONTINUE_WATCHING_RAIL: 'CONTINUE_WATCHING_RAIL',
    WATCHLIST_RAIL: 'WATCHLIST_RAIL',
    YOUR_CHANNELS_RAIL: 'YOUR_CHANNELS_RAIL',
    BORDER_SECTION_RAIL: 'BORDER_SECTION_RAIL',
    EXPLORE_CHANNEL_PARTNER: 'EXPLORE_CHANNEL_PARTNER',
    MULTILIST_RAIL: 'MULTILIST_RAIL',
    CONTENT_PLAY_RAIL: 'CONTENT_PLAY_RAIL',
    BANNER_CONTENT_RAIL: 'BANNER_CONTENT_RAIL',
    RELATED_CONTENT_RAIL: 'RELATED_CONTENT_RAIL',
    CONTENT_DESCRIPTION_DETAIL_RAIL: 'CONTENT_DESCRIPTION_DETAIL_RAIL',
    PEOPLE_RAIL: 'PEOPLE_RAIL',
    TRAILERS_RAIL: 'X_TRAILERS_RAIL',
    CONTINUE_WATCHING_RAIL_V2: 'X_CONTINUE_WATCHING_V2',
    WATCHLIST_RAIL_V2: 'X_WATCHLIST_V2',
    X_FULL_BANNER: 'X_FULL_BANNER',
    X_HIGHLIGHTED_PORTRAIT: 'X_HIGHLIGHTED_PORTRAIT',
    X_PILLS_RAIL: 'X_PILLS_RAIL',
    X_EXPLORE_V2: 'X_EXPLORE_V2',
    X_CDP_TRAILER_RAIL: 'X_CDP_TRAILER_RAIL',
    X_PYW_RAIL: 'X_PYW_RAIL',
    X_VERTICAL_TRAILER_RAIL: 'X_VERTICAL_TRAILER_RAIL',
    X_FREE_CHANNELS: 'X_FREE_CHANNELS',
    X_WARNING_RAIL: 'X_WARNING_RAIL',
    X_SUBSCRIPTION_RAIL: 'X_SUBSCRIPTION_RAIL',
    X_ACTIVE_PLAN: 'X_ACTIVE_PLAN',
    X_PARTNER_ACTIVATION_RAIL: 'X_PARTNER_ACTIVATION_RAIL',
    X_PLAN_LISTING_RAIL: 'X_PLAN_LISTING_RAIL',
    X_PARTNER_LANDSCAPE_RAIL: 'X_PARTNER_LANDSCAPE_RAIL',
    X_CONTENT_REDIRECTION_RAIL: 'X_CONTENT_REDIRECTION_RAIL',
    PARTNER_CHANNEL_DETAILED_RAIL: 'PARTNER_CHANNEL_DETAILED_RAIL',
    X_PLAN_COMING_SOON: 'X_PLAN_COMING_SOON',
    X_PLAN_TITLE_RAIL: 'X_PLAN_TITLE_RAIL',
    X_PENDING_ACTIVATION_RAIL: 'X_PENDING_ACTIVATION_RAIL',
};

export const LOCAL_RAIL_TYPES = {
    GRID: 'GRID',
    LIST: 'LIST',
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE: 'LANDSCAPE',
    CATCHUP_EPISODE_RAIL: 'CATCHUP_EPISODE_RAIL',
    DIMENSION_LIST: 'DIMENSION_LIST',
    CHANNEL_SCHEDULE: 'CHANNEL_SCHEDULE',
    TRENDING_RAIL: 'TRENDING_RAIL',
    NO_RAIL: 'NO_RAIL',
    CHANNEL_PARTNER_WIDGET: 'CHANNEL_PARTNER_WIDGET',
};

export const LOCAL_PACKAGE_ID = {
    CONTINUE_WATCHING: 'CONTINUE_WATCHING',
    WATCHLIST: 'WATCHLIST',
    YOUR_CHANNELS: 'Your Channel',
    CHANNEL_PROMOTION_WIDGET: 'CHANNEL_PROMOTION_WIDGET',
};

export const LOCAL_PACKAGE_NAME = {
    CONTINUE_WATCHING: 'Continue Watching',
    WATCHLIST: 'My Watchlist',
    YOUR_CHANNELS: 'Your Channel',
    CHANNEL_PROMOTION_WIDGET: 'Channel Promotion Widget',
};

export const LOCAL_TILE_TYPES = {
    PORTRAIT_SMALL: 'PORTRAIT_SMALL',
    PORTRAIT_LARGE: 'PORTRAIT_LARGE',
    CHANNEL: 'CHANNEL',
    CATCHUP_EPISODE_TILE: 'CATCHUP_EPISODE_TILE',
    LANDSCAPE_CARD_GRID: 'LANDSCAPE_CARD_GRID',
    LANDSCAPE_CARD_TILE_GRID: 'LANDSCAPE_CARD_TILE_GRID',
    CHANNEL_CARD_TILE_GRID: 'CHANNEL_CARD_TILE_GRID',
    CONTINUE_WATCHING_TILE: 'CONTINUE_WATCHING',
    SUBSCRIBED_CP_RAIL: 'SUBSCRIBED_CP_RAIL',
    WATCHLIST: 'WATCHLIST',
    CHANNEL_SCHEDULE: 'CHANNEL_SCHEDULE',
    TRENDING_CARD: 'TRENDING_CARD',
    NEXT_EPISODE_TILE: 'NEXT_EPISODE_TILE',
    CUSTOM_EXPLORE: 'CUSTOM_EXPLORE',
    CHANNEL_PARTNER_RAIL: 'CHANNEL_PARTNER_RAIL',
    CHANNEL_LOGO: 'CHANNEL_LOGO',
    PLAYER_WIDGET: 'PLAYER_WIDGET',
    LARGE_SCREEN_SEARCH_TILE: 'LARGE_SCREEN_SEARCH_TILE',
    LOCAL_TILE: 'LOCAL_TILE',
    X_PYW_RAIL: 'X_PYW_RAIL',
};


const TILE_RAIL_TYPE_WEB = {
    [TILE_TYPES.CHANNEL]: RAIL_TYPES.GRID_RECTANGLE_CHANNEL_RAIL,
    [TILE_TYPES.CHANNEL_LIST]: RAIL_TYPES.GRID_RECTANGLE_CHANNEL_RAIL,
    [TILE_TYPES.TVSHOW_LOGO_43]: RAIL_TYPES.TVSHOW_LOGO_43_RAIL,
    [TILE_TYPES.TVSHOW_NOLOGO_43]: RAIL_TYPES.RAIL,
    [TILE_TYPES.TVSHOW_LOGO_169]: RAIL_TYPES.RAIL,
    [TILE_TYPES.TVSHOW_NOLOGO_169]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE,
    [TILE_TYPES.DEFAULT_169]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE,
    [TILE_TYPES.TVSHOW_BIG_43]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE,
    // [TILE_TYPES.MOVIE_LOGO]: RAIL_TYPES.GRID_ROUND_SECTION_RAIL,
    [TILE_TYPES.MOVIE_LOGO]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_PORTRAIT,
    [TILE_TYPES.X_MASTER_RAIL]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_PORTRAIT,
    [TILE_TYPES.EMPTY_STATE]: RAIL_TYPES.RAIL,
    [TILE_TYPES.HEADER]: RAIL_TYPES.HEADING_SECTION_RAIL,
    [TILE_TYPES.FOOTER]: RAIL_TYPES.BORDER_SECTION_RAIL,
    // [TILE_TYPES.MOVIE_LOGO]: RAIL_TYPES.RAIL,
    [TILE_TYPES.MOVIE_NOLOGO]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_PORTRAIT,
    [TILE_TYPES.RELATED_CONTENT]: RAIL_TYPES.RELATED_CONTENT_RAIL,
    [TILE_TYPES.BANNER]: RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL,
    [TILE_TYPES.X_INFINITY_BANNER]: RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL,
    [TILE_TYPES.LEAGUE_BANNER]: RAIL_TYPES.LEAGUE_BANNER,
    [TILE_TYPES.CARD_TITLE_169]: RAIL_TYPES.CARD,
    [TILE_TYPES.CARD_NOTITILE_169]: RAIL_TYPES.CARD,
    [TILE_TYPES.SCHEDULE]: RAIL_TYPES.SCHEDULE,
    [TILE_TYPES.LEADER_BOARD]: RAIL_TYPES.LEADER_BOARD,
    [TILE_TYPES.UNKNOWN]: RAIL_TYPES.UNKNOWN,
    [TILE_TYPES.MOVIE]: RAIL_TYPES.RAIL,
    [TILE_TYPES.TV_SHOWS]: RAIL_TYPES.RAIL,
    [TILE_TYPES.LIVE_TV_SHOW]: RAIL_TYPES.GRID_RECTANGLE_CHANNEL_RAIL,
    [TILE_TYPES.LIVE_TV_MOVIE]: RAIL_TYPES.RAIL,
    [TILE_TYPES.VIDEO]: RAIL_TYPES.RAIL,
    [TILE_TYPES.PEOPLE]: RAIL_TYPES.RAIL,
    [TILE_TYPES.SPORTS]: RAIL_TYPES.RAIL,
    [TILE_TYPES.HIGHLIGHTED]: RAIL_TYPES.RAIL,
    [TILE_TYPES.NATIVE_BANNER_AD]: RAIL_TYPES.MASTHEAD,
    [TILE_TYPES.NATIVE_VIDEO_AD]: RAIL_TYPES.MASTHEAD,
    [TILE_TYPES.NATIVE_MASTHEAD_AD]: RAIL_TYPES.MASTHEAD,
    [TILE_TYPES.NATIVE_EPG_AD]: RAIL_TYPES.MASTHEAD,
    [TILE_TYPES.FOLLOW_TEAM]: RAIL_TYPES.FOLLOW_TEAM,
    [TILE_TYPES.CUSTOM]: RAIL_TYPES.GRID_SQUARE_EXPLORE_RAIL,
    [TILE_TYPES.EXPLORE]: RAIL_TYPES.CUSTOM,
    [TILE_TYPES.GRID]: RAIL_TYPES.CUSTOM,
    [TILE_TYPES.PLAYALONG]: RAIL_TYPES.PLAYALONG,
    [TILE_TYPES.USER_TYPE_CARD]: RAIL_TYPES.USER_TYPE_CARD,
    [TILE_TYPES.CONTINUE_WATCHING]: RAIL_TYPES.CONTINUE_WATCHING_RAIL,
    [TILE_TYPES.SUBSCRIBED_CP_RAIL]: RAIL_TYPES.YOUR_CHANNELS_RAIL,
    [TILE_TYPES.MULTILIST_RAIL]: RAIL_TYPES.MULTILIST_RAIL,
    [TILE_TYPES.CONTENT_PLAY_RAIL]: RAIL_TYPES.CONTENT_PLAY_RAIL,
    [TILE_TYPES.BANNER_CONTENT_RAIL]: RAIL_TYPES.BANNER_CONTENT_RAIL,
    [TILE_TYPES.RELATED_CONTENT_RAIL]: RAIL_TYPES.RELATED_CONTENT_RAIL,
    [TILE_TYPES.CONTENT_DESCRIPTION_DETAIL_RAIL]: RAIL_TYPES.CONTENT_DESCRIPTION_DETAIL_RAIL,
    [TILE_TYPES.PEOPLE_RAIL]: RAIL_TYPES.PEOPLE_RAIL,
    [TILE_TYPES.WATCHLIST]: RAIL_TYPES.WATCHLIST_RAIL,
    [TILE_TYPES.CONTENT_PARTNER_RAIL]: RAIL_TYPES.EXPLORE_CHANNEL_PARTNER,
    [TILE_TYPES.X_CONTENT_PARTNER_RAIL_V2]: RAIL_TYPES.EXPLORE_CHANNEL_PARTNER,
    [TILE_TYPES.TRAILERS_RAIL]: RAIL_TYPES.TRAILERS_RAIL,
    [TILE_TYPES.CONTINUE_WATCHING_V2]: RAIL_TYPES.CONTINUE_WATCHING_RAIL_V2,
    [TILE_TYPES.WATCHLIST_V2]: RAIL_TYPES.WATCHLIST_RAIL_V2,
    [TILE_TYPES.X_FULL_BANNER]: RAIL_TYPES.X_FULL_BANNER,
    [TILE_TYPES.X_PILLS_RAIL]: RAIL_TYPES.X_PILLS_RAIL,
    [TILE_TYPES.X_EXPLORE_V2]: RAIL_TYPES.X_EXPLORE_V2,
    [TILE_TYPES.X_PYW_RAIL]: RAIL_TYPES.X_PYW_RAIL,
    [TILE_TYPES.X_VERTICAL_TRAILER_RAIL]: RAIL_TYPES.X_VERTICAL_TRAILER_RAIL,
    [TILE_TYPES.X_WARNING_RAIL]: RAIL_TYPES.X_WARNING_RAIL,
    [TILE_TYPES.X_TOP_NAVIGATION_BAR]: RAIL_TYPES.X_TOP_NAVIGATION_BAR,

    // LOCAL TYPES
    [LOCAL_TILE_TYPES.PORTRAIT_SMALL]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.PORTRAIT_LARGE]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.CATCHUP_EPISODE_TILE]: LOCAL_RAIL_TYPES.CATCHUP_EPISODE_RAIL,
    [LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.LANDSCAPE_CARD_TILE_GRID]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.CHANNEL_CARD_TILE_GRID]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.CHANNEL_SCHEDULE]: LOCAL_RAIL_TYPES.CHANNEL_SCHEDULE,
    [LOCAL_TILE_TYPES.TRENDING_CARD]: LOCAL_RAIL_TYPES.TRENDING_RAIL,
    [LOCAL_TILE_TYPES.CUSTOM_EXPLORE]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.CHANNEL_PARTNER_RAIL]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.PLAYER_WIDGET]: LOCAL_RAIL_TYPES.CHANNEL_PARTNER_WIDGET,
    [LOCAL_TILE_TYPES.LOCAL_TILE]: LOCAL_RAIL_TYPES.LIST,
    [TILE_TYPES.X_FREE_CHANNELS]: RAIL_TYPES.X_FREE_CHANNELS,
    [TILE_TYPES.X_SUBSCRIPTION_RAIL]: RAIL_TYPES.X_SUBSCRIPTION_RAIL,
    [TILE_TYPES.X_ACTIVE_PLAN]: RAIL_TYPES.X_ACTIVE_PLAN,
    [TILE_TYPES.X_PARTNER_ACTIVATION_RAIL]: RAIL_TYPES.X_PARTNER_ACTIVATION_RAIL,
    [TILE_TYPES.X_PLAN_LISTING_RAIL]: RAIL_TYPES.X_PLAN_LISTING_RAIL,
    [TILE_TYPES.X_CONTENT_REDIRECTION_RAIL]: RAIL_TYPES.X_CONTENT_REDIRECTION_RAIL,
    [TILE_TYPES.PARTNER_CHANNEL_DETAILED_RAIL]: RAIL_TYPES.PARTNER_CHANNEL_DETAILED_RAIL,
    [TILE_TYPES.X_PLAN_COMING_SOON]: RAIL_TYPES.X_PLAN_COMING_SOON,
    [TILE_TYPES.X_PLAN_TITLE_RAIL]: RAIL_TYPES.X_PLAN_TITLE_RAIL,
    [RAIL_TYPES.X_PENDING_ACTIVATION_RAIL]: RAIL_TYPES.X_PENDING_ACTIVATION_RAIL,
    [RAIL_TYPES.X_PARTNER_LANDSCAPE_RAIL]: RAIL_TYPES.X_PARTNER_LANDSCAPE_RAIL,
};

const TILE_RAIL_TYPE_LS = {
    [TILE_TYPES.CHANNEL]: RAIL_TYPES.GRID_RECTANGLE_CHANNEL_RAIL,
    [TILE_TYPES.CHANNEL_LIST]: RAIL_TYPES.GRID_RECTANGLE_CHANNEL_RAIL,
    [TILE_TYPES.TVSHOW_LOGO_43]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE,
    [TILE_TYPES.TVSHOW_NOLOGO_43]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE,
    [TILE_TYPES.TVSHOW_LOGO_169]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE,
    [TILE_TYPES.TVSHOW_NOLOGO_169]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE,
    [TILE_TYPES.DEFAULT_169]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE,
    [TILE_TYPES.TVSHOW_BIG_43]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE,
    [TILE_TYPES.CONTENT_PARTNER_RAIL]: RAIL_TYPES.EXPLORE_CHANNEL_PARTNER,
    [TILE_TYPES.X_CONTENT_PARTNER_RAIL_V2]: RAIL_TYPES.EXPLORE_CHANNEL_PARTNER,
    [TILE_TYPES.X_FULL_BANNER]: RAIL_TYPES.X_FULL_BANNER,
    // [TILE_TYPES.MOVIE_LOGO]: RAIL_TYPES.GRID_ROUND_SECTION_RAIL,
    [TILE_TYPES.MOVIE_LOGO]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_PORTRAIT,
    [TILE_TYPES.EMPTY_STATE]: RAIL_TYPES.RAIL,
    [TILE_TYPES.HEADER]: RAIL_TYPES.HEADING_SECTION_RAIL,
    [TILE_TYPES.FOOTER]: RAIL_TYPES.BORDER_SECTION_RAIL,
    // [TILE_TYPES.MOVIE_LOGO]: RAIL_TYPES.RAIL,
    [TILE_TYPES.MOVIE_NOLOGO]: RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_PORTRAIT,
    [TILE_TYPES.BANNER]: RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL,
    [TILE_TYPES.LEAGUE_BANNER]: RAIL_TYPES.LEAGUE_BANNER,
    [TILE_TYPES.CARD_TITLE_169]: RAIL_TYPES.CARD,
    [TILE_TYPES.CARD_NOTITILE_169]: RAIL_TYPES.CARD,
    [TILE_TYPES.SCHEDULE]: RAIL_TYPES.SCHEDULE,
    [TILE_TYPES.LEADER_BOARD]: RAIL_TYPES.LEADER_BOARD,
    [TILE_TYPES.UNKNOWN]: RAIL_TYPES.UNKNOWN,
    [TILE_TYPES.MOVIE]: RAIL_TYPES.RAIL,
    [TILE_TYPES.TV_SHOWS]: RAIL_TYPES.RAIL,
    [TILE_TYPES.LIVE_TV_SHOW]: RAIL_TYPES.GRID_RECTANGLE_CHANNEL_RAIL,
    [TILE_TYPES.LIVE_TV_MOVIE]: RAIL_TYPES.RAIL,
    [TILE_TYPES.VIDEO]: RAIL_TYPES.RAIL,
    [TILE_TYPES.PEOPLE]: RAIL_TYPES.RAIL,
    [TILE_TYPES.SPORTS]: RAIL_TYPES.RAIL,
    [TILE_TYPES.HIGHLIGHTED]: RAIL_TYPES.RAIL,
    [TILE_TYPES.NATIVE_BANNER_AD]: RAIL_TYPES.MASTHEAD,
    [TILE_TYPES.NATIVE_VIDEO_AD]: RAIL_TYPES.MASTHEAD,
    [TILE_TYPES.NATIVE_MASTHEAD_AD]: RAIL_TYPES.MASTHEAD,
    [TILE_TYPES.NATIVE_EPG_AD]: RAIL_TYPES.MASTHEAD,
    [TILE_TYPES.FOLLOW_TEAM]: RAIL_TYPES.FOLLOW_TEAM,
    [TILE_TYPES.CUSTOM]: RAIL_TYPES.GRID_SQUARE_EXPLORE_RAIL,
    [TILE_TYPES.EXPLORE]: RAIL_TYPES.CUSTOM,
    [TILE_TYPES.GRID]: RAIL_TYPES.CUSTOM,
    [TILE_TYPES.PLAYALONG]: RAIL_TYPES.PLAYALONG,
    [TILE_TYPES.USER_TYPE_CARD]: RAIL_TYPES.USER_TYPE_CARD,
    [TILE_TYPES.CONTINUE_WATCHING]: RAIL_TYPES.CONTINUE_WATCHING_RAIL,
    [TILE_TYPES.CONTINUE_WATCHING_V2]: RAIL_TYPES.CONTINUE_WATCHING_RAIL_V2,
    [TILE_TYPES.MULTILIST_RAIL]: RAIL_TYPES.MULTILIST_RAIL,
    [TILE_TYPES.CONTENT_PLAY_RAIL]: RAIL_TYPES.CONTENT_PLAY_RAIL,
    [TILE_TYPES.BANNER_CONTENT_RAIL]: RAIL_TYPES.BANNER_CONTENT_RAIL,
    // [TILE_TYPES.SUBSCRIBED_CP_RAIL]: RAIL_TYPES.YOUR_CHANNELS_RAIL,

    [TILE_TYPES.WATCHLIST]: RAIL_TYPES.WATCHLIST_RAIL,
    [TILE_TYPES.WATCHLIST_V2]: RAIL_TYPES.WATCHLIST_RAIL_V2,
    // [TILE_TYPES.CONTENT_PARTNER_RAIL]: RAIL_TYPES.EXPLORE_CHANNEL_PARTNER,
    [TILE_TYPES.X_EXPLORE_V2]: RAIL_TYPES.X_EXPLORE_V2,
    [TILE_TYPES.X_PYW_RAIL]: RAIL_TYPES.X_PYW_RAIL,
    [TILE_TYPES.X_VERTICAL_TRAILER_RAIL]: RAIL_TYPES.X_VERTICAL_TRAILER_RAIL,
    // LOCAL TYPES
    [LOCAL_TILE_TYPES.PORTRAIT_SMALL]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.PORTRAIT_LARGE]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.CATCHUP_EPISODE_TILE]: LOCAL_RAIL_TYPES.CATCHUP_EPISODE_RAIL,
    [LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.LANDSCAPE_CARD_TILE_GRID]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.CHANNEL_CARD_TILE_GRID]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.CHANNEL_SCHEDULE]: LOCAL_RAIL_TYPES.CHANNEL_SCHEDULE,
    [LOCAL_TILE_TYPES.TRENDING_CARD]: LOCAL_RAIL_TYPES.TRENDING_RAIL,
    [LOCAL_TILE_TYPES.CUSTOM_EXPLORE]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.CHANNEL_PARTNER_RAIL]: LOCAL_RAIL_TYPES.LIST,
    [LOCAL_TILE_TYPES.PLAYER_WIDGET]: LOCAL_RAIL_TYPES.CHANNEL_PARTNER_WIDGET,
    [LOCAL_TILE_TYPES.LARGE_SCREEN_SEARCH_TILE]: LOCAL_RAIL_TYPES.LIST,
    [TILE_TYPES.X_INFINITY_BANNER]: RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL,
    [TILE_TYPES.X_PILLS_RAIL]: RAIL_TYPES.X_PILLS_RAIL,
    [TILE_TYPES.PEOPLE_RAIL]: RAIL_TYPES.PEOPLE_RAIL,
    [TILE_TYPES.CONTENT_DESCRIPTION_DETAIL_RAIL]: RAIL_TYPES.CONTENT_DESCRIPTION_DETAIL_RAIL,
    [TILE_TYPES.X_CDP_TRAILER_RAIL]: RAIL_TYPES.X_CDP_TRAILER_RAIL,
    [TILE_TYPES.TRAILERS_RAIL]: RAIL_TYPES.TRAILERS_RAIL,
    [TILE_TYPES.X_FREE_CHANNELS]: RAIL_TYPES.X_FREE_CHANNELS,
    [TILE_TYPES.X_TOP_NAVIGATION_BAR]: RAIL_TYPES.X_TOP_NAVIGATION_BAR,
    [TILE_TYPES.X_SUBSCRIPTION_RAIL]: RAIL_TYPES.X_SUBSCRIPTION_RAIL,
    [TILE_TYPES.X_ACTIVE_PLAN]: RAIL_TYPES.X_ACTIVE_PLAN,
    [TILE_TYPES.X_PARTNER_ACTIVATION_RAIL]: RAIL_TYPES.X_PARTNER_ACTIVATION_RAIL,
    [TILE_TYPES.X_PLAN_LISTING_RAIL]: RAIL_TYPES.X_PLAN_LISTING_RAIL,
    [TILE_TYPES.X_CONTENT_REDIRECTION_RAIL]: RAIL_TYPES.X_CONTENT_REDIRECTION_RAIL,
    [TILE_TYPES.PARTNER_CHANNEL_DETAILED_RAIL]: RAIL_TYPES.PARTNER_CHANNEL_DETAILED_RAIL,
    [TILE_TYPES.X_PLAN_COMING_SOON]: RAIL_TYPES.X_PLAN_COMING_SOON,
    [TILE_TYPES.X_PLAN_TITLE_RAIL]: RAIL_TYPES.X_PLAN_TITLE_RAIL,
    [RAIL_TYPES.X_PENDING_ACTIVATION_RAIL]: RAIL_TYPES.X_PENDING_ACTIVATION_RAIL,
    [RAIL_TYPES.X_PARTNER_LANDSCAPE_RAIL]: RAIL_TYPES.X_PARTNER_LANDSCAPE_RAIL,
};

export const TILE_RAIL_TYPE = (DeviceTypeUtil.isWeb() ? TILE_RAIL_TYPE_WEB : TILE_RAIL_TYPE_LS);

export const RELATION_TYPES = {
    VIDEO: 'VIDEO',
    PEOPLE: 'PEOPLE',
    CHANNEL: 'CHANNEL',
    TVSHOW: 'TVSHOW',
};

export const RAIL_CTA_LIST = {
    LISTING: 'LISTING',
    SEARCH: 'SEARCH',
    SCHEDULE: 'SCHEDULE',
    DISTRO_LIVETV_LISTING: 'DISTRO_LIVETV_LISTING',
};

export const RAIL_CTA_TEXT_LIST = {
    MORE: 'MORE',
    SHOW_ALL: 'Show All',
};

export const TILE_CTA_LIST = {
    PLAYBACK: 'PLAYBACK',
    SEARCH: 'SEARCH',
    PARTNER_CHANNEL: 'PARTNER_CHANNEL',
};

export const DEFAULT_THEME_TYPE = 'default';

export const RESPONSIVE_CAROUSEL_SETTINGS = {
    WIDTH_0: {
        TILE_LENGTH: 1,
        UPPER_WIDTH: 600,
        LOWER_WIDTH: 0,
    },
    WIDTH_600: {
        TILE_LENGTH: 4,
        UPPER_WIDTH: 960,
        LOWER_WIDTH: 600,
    },
    WIDTH_1000: {
        TILE_LENGTH: 5,
        UPPER_WIDTH: 1007,
        LOWER_WIDTH: 960,
    },
    WIDTH_1100: {
        TILE_LENGTH: 7,
        UPPER_WIDTH: 1366,
        LOWER_WIDTH: 1007,
    },
    WIDTH_1300: {
        TILE_LENGTH: 8,
        UPPER_WIDTH: 1920,
        LOWER_WIDTH: 1366,
    },
    WIDTH_1900: {
        TILE_LENGTH: 9,
        UPPER_WIDTH: 2048,
        LOWER_WIDTH: 1920,
    },
    WIDTH_2400: {
        TILE_LENGTH: 12,
        UPPER_WIDTH: 4096,
        LOWER_WIDTH: 2048,
    },
};


export const RESPONSIVE_CAROUSEL_SETTINGS_SMALL_LANDSACPE_RAIL = {
    WIDTH_0: {
        TILE_LENGTH: 1,
        UPPER_WIDTH: 600,
        LOWER_WIDTH: 0,
    },
    WIDTH_600: {
        TILE_LENGTH: 4,
        UPPER_WIDTH: 1000,
        LOWER_WIDTH: 600,
    },
    WIDTH_1000: {
        TILE_LENGTH: 7,
        UPPER_WIDTH: 1366,
        LOWER_WIDTH: 1000,
    },
    WIDTH_1300: {
        TILE_LENGTH: 8,
        UPPER_WIDTH: 1820,
        LOWER_WIDTH: 1366,
    },
    WIDTH_1800: {
        TILE_LENGTH: 10,
        UPPER_WIDTH: 1920,
        LOWER_WIDTH: 1820,
    },
    WIDTH_1900: {
        TILE_LENGTH: 10,
        UPPER_WIDTH: 2048,
        LOWER_WIDTH: 1920,
    },
    WIDTH_2400: {
        TILE_LENGTH: 12,
        UPPER_WIDTH: 4096,
        LOWER_WIDTH: 2048,
    },
};

export const MAX_TILE_LIMIT_MWEB = {
    PORTRAIT: 7,
    LANDSCAPE: 5,
    CHANNEL: 7,
};

export const MAX_TILE_LIMIT_WEB = {
    PORTRAIT: 15,
    LANDSCAPE: 15,
    CHANNEL: 15,
};

export const LAZY_TILE_LIMIT_PORTRAIT_LS = {
    2000: 10,
    1900: 10,
    1700: 10,
    1500: 8,
    1300: 7,
    1100: 6,
    600: 5,
    450: 4,
    350: 3,
    250: 2,
};

export const LAZY_TILE_LIMIT_PORTRAIT = {
    2000: 11,
    1900: 10,
    1700: 9,
    1500: 10,
    1300: 7,
    1100: 6,
    600: 5,
    450: 4,
    350: 3,
    250: 2,
};

export const LAZY_TILE_LIMIT_LANDSCAPE = {
    2000: 6,
    1600: 5,
    1200: 5,
    1024: 4,
    450: 3,
    250: 2,
};

export const LAZY_TILE_LIMIT_EXPLORE = {
    1600: 10,
    1500: 8,
    1280: 7,
    991: 6,
    576: 5,
    320: 4,
};

export const LAZY_TILE_LIMIT_CIRCULAR = {
    2000: 12,
    1600: 11,
    1200: 10,
    1024: 9,
    768: 8,
    576: 5,
    450: 4,
    250: 4,
};


const TILE_LAZY_TILE_LIMIT_TYPE_MAP_WEB = {
    [TILE_TYPES.MOVIE_NOLOGO]: LAZY_TILE_LIMIT_PORTRAIT,
    [TILE_TYPES.MOVIE_LOGO]: LAZY_TILE_LIMIT_PORTRAIT,
    [TILE_TYPES.CHANNEL]: LAZY_TILE_LIMIT_PORTRAIT,
    [TILE_TYPES.CHANNEL_LIST]: LAZY_TILE_LIMIT_PORTRAIT,
    [TILE_TYPES.CUSTOM]: LAZY_TILE_LIMIT_EXPLORE,
    [LOCAL_TILE_TYPES.PORTRAIT_SMALL]: LAZY_TILE_LIMIT_PORTRAIT,
    [LOCAL_TILE_TYPES.PORTRAIT_LARGE]: LAZY_TILE_LIMIT_PORTRAIT,
    [LOCAL_TILE_TYPES.CATCHUP_EPISODE_TILE]: LAZY_TILE_LIMIT_PORTRAIT,
    [LOCAL_TILE_TYPES.CHANNEL_SCHEDULE]: LAZY_TILE_LIMIT_LANDSCAPE,

    [TILE_TYPES.TVSHOW_LOGO_43]: LAZY_TILE_LIMIT_LANDSCAPE,
    [TILE_TYPES.TVSHOW_BIG_43]: LAZY_TILE_LIMIT_LANDSCAPE,
    [TILE_TYPES.TVSHOW_NOLOGO_169]: LAZY_TILE_LIMIT_LANDSCAPE,
    [TILE_TYPES.DEFAULT_169]: LAZY_TILE_LIMIT_LANDSCAPE,
    [LOCAL_TILE_TYPES.WATCHLIST]: LAZY_TILE_LIMIT_LANDSCAPE,
    [LOCAL_TILE_TYPES.CONTINUE_WATCHING_TILE]: LAZY_TILE_LIMIT_LANDSCAPE,
    [TILE_TYPES.SUBSCRIBED_CP_RAIL]: LAZY_TILE_LIMIT_CIRCULAR,
};

const TILE_LAZY_TILE_LIMIT_TYPE_MAP_LS = {
    [TILE_TYPES.MOVIE_NOLOGO]: LAZY_TILE_LIMIT_PORTRAIT_LS,
    [TILE_TYPES.MOVIE_LOGO]: LAZY_TILE_LIMIT_PORTRAIT_LS,
    [TILE_TYPES.CHANNEL]: LAZY_TILE_LIMIT_PORTRAIT_LS,
    [TILE_TYPES.CHANNEL_LIST]: LAZY_TILE_LIMIT_PORTRAIT_LS,
    [TILE_TYPES.CUSTOM]: LAZY_TILE_LIMIT_EXPLORE,
    [LOCAL_TILE_TYPES.PORTRAIT_SMALL]: LAZY_TILE_LIMIT_PORTRAIT_LS,
    [LOCAL_TILE_TYPES.PORTRAIT_LARGE]: LAZY_TILE_LIMIT_PORTRAIT_LS,
    [LOCAL_TILE_TYPES.CATCHUP_EPISODE_TILE]: LAZY_TILE_LIMIT_PORTRAIT_LS,
    [LOCAL_TILE_TYPES.CHANNEL_SCHEDULE]: LAZY_TILE_LIMIT_LANDSCAPE,

    [TILE_TYPES.TVSHOW_LOGO_43]: LAZY_TILE_LIMIT_LANDSCAPE,
    [TILE_TYPES.TVSHOW_BIG_43]: LAZY_TILE_LIMIT_LANDSCAPE,
    [TILE_TYPES.TVSHOW_NOLOGO_169]: LAZY_TILE_LIMIT_LANDSCAPE,
    [TILE_TYPES.DEFAULT_169]: LAZY_TILE_LIMIT_LANDSCAPE,
    [LOCAL_TILE_TYPES.WATCHLIST]: LAZY_TILE_LIMIT_LANDSCAPE,
    [LOCAL_TILE_TYPES.CONTINUE_WATCHING_TILE]: LAZY_TILE_LIMIT_LANDSCAPE,
    [TILE_TYPES.SUBSCRIBED_CP_RAIL]: LAZY_TILE_LIMIT_CIRCULAR,
};

export const TILE_LAZY_TILE_LIMIT_TYPE_MAP = DeviceTypeUtil.isWeb() ? TILE_LAZY_TILE_LIMIT_TYPE_MAP_WEB : TILE_LAZY_TILE_LIMIT_TYPE_MAP_LS;


export const DESCRIPTION_LIMIT = 200;

export const RAIL_LIMIT = {
    BOT: 10,
    USER: 12,
};

export const TILES_LIMIT = 15;

export const PLAN_TILE_LIMIT = 2;

export const XPARTNERS_LIMIT_LS = 19;
export const XPARTNERS_WITH_PREMIUM_LIMIT_LS = 14;

export const TITLE_LIMIT = {
    PORTRAIT: 18,
    LANDSCAPE: 30,
};
export const TILE_THROTTLE_TIME = {
    HORIZONTAL: 300,
    VERTICAL: 600,
};

export const LISTING_PAGE_ID_TYPES = {
    PACKAGE: 'p',
    RAIL: 'r',
};

export const BUTTON_IMAGE_TITLE = {
    CLOSE: 'Close',
    ADD_TO_WATCHLIST: 'Add to Watchlist',
    REMOVE_FROM_WATCHLIST: 'Remove From Watchlist',
};

export const ALL_PAGE_DATA_FETCH_DELAY = 3000;

export const BANNER_TILE_SIZE = {
    MOBILE: 100,
    DESKTOP: 100,
};

export const BANNER_TILE_SIZE_LS = {
    MOBILE: 80,
    DESKTOP: 50,
};

export const BANNER_INTERVAL_LS = {
    NEXT_TILE: 8000,
    TRANSITION_DURATION: 1000,
    WHEEL_EVENT: 1200, // for smoothness
};

export const BANNER_INTERVAL = {
    NEXT_TILE: 3000,
    TRANSITION_DURATION: 500,
    WHEEL_EVENT: 1200, // for smoothness
};

export const LISTING_PAGE = {
    COUNT: 30,
    BOT_COUNT: 200,
};

export const HYDRATION_TYPE = {
    XSTREAM_LAYOUT: 'XSTREAM_LAYOUT',
    XSTREAM_EPISODE: 'XSTREAM_EPISODE',
};

export const TITLE_TYPE = {
    TITLE: 'TITLE',
};
export const INFO_TYPES = {
    DESCRIPTION: 'DESCRIPTION',
    AUDIO_SUBTITLES: 'AUDIO_SUBTITLES',
};

export const WHITELIST_PAGEIDS = ['pywPage', 'languagePage']; //Whitelsited from 404 in case of DataLaoder
export const LOCAL_PAGE_IDS = {
    ARTIST_CONTAINER: 'ARTIST_CONTAINER',
};

export default {
    ALL_PAGE_DATA_FETCH_DELAY,
    LISTING_PAGE_ID_TYPES,
    LAYOUT_SOURCE_TYPES,
    LAYOUT_TYPES,
    TILE_TYPES,
    RAIL_TYPES,
    TILE_RAIL_TYPE,
    LOCAL_RAIL_TYPES,
    LOCAL_TILE_TYPES,
    RELATION_TYPES,
    RAIL_CTA_LIST,
    TILE_CTA_LIST,
    RAIL_CTA_TEXT_LIST,
    RESPONSIVE_CAROUSEL_SETTINGS,
    RESPONSIVE_CAROUSEL_SETTINGS_SMALL_LANDSACPE_RAIL,
    MAX_TILE_LIMIT_MWEB,
    DESCRIPTION_LIMIT,
    MAX_TILE_LIMIT_WEB,
    TILES_LIMIT,
    BUTTON_IMAGE_TITLE,
    LAZY_TILE_LIMIT_LANDSCAPE,
    LAZY_TILE_LIMIT_PORTRAIT,
    TILE_LAZY_TILE_LIMIT_TYPE_MAP,
    BANNER_TILE_SIZE,
    BANNER_INTERVAL,
    DEFAULT_THEME_TYPE,
    TITLE_TYPE,
};
