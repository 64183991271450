import { call, put, takeEvery } from 'redux-saga/effects';
import { getBlogCategories, getBlogPosts } from '../../service/end-points/BlogApiService';
import { isOnline } from '../../utilities/WindowUtil';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import ErrorHandler from '../error-handler/ErrorHandler';
import { BlogActions, fetchBlogByIdSuccess, fetchBlogCategoriesSuccess, fetchBlogsSuccess } from './BlogActions';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import getBlogsFromServer from '../../../server/services/ServerSideApiService';

function* fetchBlogPosts({ postId }) {
    let posts;
    try {
        if (checkWindowExist()) {
            posts = yield call(getBlogPosts, { postId });
        } else {
            posts = yield call(getBlogsFromServer, { postId });
        }
        if (posts?.error) {
            throw posts.error;
        }
        posts = posts && posts.length > 0 ? JSON.parse(posts) : [];
        if (postId && postId !== '') {
            yield put(fetchBlogByIdSuccess({ payload: {
                postId,
                posts 
        }}));
        } else {
            yield put(fetchBlogsSuccess({ payload: posts }));
        }
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        // const payload = {
        //     [contentId]: {
        //         error,
        //         fetching: false,
        //     },
        // };
    }
}

function* fetchBlogCategories() {
    try {
        let categories = yield call(getBlogCategories);
        if (categories?.error) {
            throw categories.error;
        }
        categories = categories && categories.length > 0 ? JSON.parse(categories) : [];
        yield put(fetchBlogCategoriesSuccess({ payload: categories }));
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };

            ErrorHandler({
                error,
                code: ERROR_CODES.LOCAL1002,
            });
        }
    }
}

export default [
    takeEvery(BlogActions.FETCH_BLOGS, fetchBlogPosts),
    takeEvery(BlogActions.FETCH_BLOG_CATEGORIES, fetchBlogCategories),
];
