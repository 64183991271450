import { BrowserHistoryAction } from '../actions/BrowserHistoryAction';

const initialState = JSON.parse(`{
    "paths": []
}`);

const BrowseHistoryReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case BrowserHistoryAction.ADD_PATH:
            changes = {
                paths: [
                    ...state.paths,
                    action.payload,
                ],
            };
            break;
        default:
            break;
    }

    return {
        ...state,
        ...changes,
    };
};

export default BrowseHistoryReducer;
