import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { QUERY_PARAMS } from '@airtel-tv/constants/GlobalConst';
import { LocationUtil }from '@airtel-tv/utils/LocationUtil';
import env from '../../config';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import {
    getAppVersion, getBuildNumber, getDeviceType, getAppId, removeFalsy,
} from '@airtel-tv/utils/GlobalUtil';
import {AuthenticationUtil} from '@airtel-tv/utils/AuthenticationUtil';

export default async function getLanguageFile({ languageCode }) {
    // TODO: ask how to get user languae :: till then hard code it
    languageCode = 'en'; // eslint-disable-line no-param-reassign

    if (!languageCode) {
        return Promise.reject(new Error({
            errorCode: 'CLIENT_VALIDATION',
            message: 'Code not provided',
        }));
    }

    const url = `/languages/${languageCode}.json`;
    return RequestUtil.GET({ url });
}

export async function getAppConfig(deviceUtilInstance) {
    const { base, paths } = env.endpoints.contentApi;

    const queryParams = {
        platform: deviceUtilInstance.getOSName(),
        appVersion: getAppVersion(),
        dt: getDeviceType(),
        bn: getBuildNumber(),
        appId: getAppId(),
        os: deviceUtilInstance.getBrowserType(),
    };
    const url = `${base}${paths.appConfig}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const xtvDidFromLocal = deviceUtilInstance.getXAtvDidFromLocal();

    const header = {
        'x-atv-did': xtvDidFromLocal || deviceUtilInstance.getXAtvDid(),
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url,
        headers: removeFalsy(header),
    });
}

export async function getUserLocation(deviceUtilInstance) {
    const { paths, base } = env.endpoints.userApi;
    const method = 'GET';

    const url = `${paths.userLocation}`;
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const header = {
        'x-atv-did': deviceUtilInstance.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url: base + url,
        headers: removeFalsy(header),
    });
}

export async function getAdsAuthToken(deviceUtilInstance) {
    const { base, paths } = env.endpoints.adsContentApi;
    const url = `${paths.getAdsAuthToken}`;
    const tempurl = `${paths.getAdsAuthToken}`;
    const method = 'POST';
    const utknData = {
        urlBody: `${method}/${tempurl}`,
        token: deviceUtilInstance.getXAtvDid().split('|')[0],
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const header = {
        'x-wynk-did': deviceUtilInstance.getXAtvDid(),
        'x-wynk-utkn': utkn,
        'cache-control': 'no-cache',
        // eslint-disable-next-line quote-props
        'pragma': 'no-cache',
    };

    return RequestUtil.POST({
        showheader: true,
        url: base + url,
        headers: removeFalsy(header),
    });
}

export async function getAdsData(payload) {
    const { base, paths } = env.endpoints.adsContentApi;
    const { deviceUtil, authToken } = payload;

    const url = `${base}${paths.getAdsData}`;

    const header = {
        'x-wynk-did': deviceUtil.getXAtvDid(),
        'x-client-id': 'XSTREAM_APP',
        'x-auth-token': authToken,
    };

    return RequestUtil.GET({
        url,
        headers: removeFalsy(header),
    });
}

export async function getUserConfig(deviceUtilInstance) {
    const { base, paths } = env.endpoints.userApi;
    const method = 'POST';


    const queryParams = {
        appId: getAppId(),
        cache: false,
    };
    const url = `${paths.userConfig}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': deviceUtilInstance.getXAtvDid(),
        'x-atv-platform': 'WEBOS',
        'x-atv-utkn': utkn,
    };
    const authOptions = {
        showheader: true,
        method,
        url: base + url,
        headers: removeFalsy(headers),
    };
    return RequestUtil.POST(authOptions);
}

export async function updateUserEmail({ email, deviceUtil }) {
    const { base, paths } = env.endpoints.userApi;
    const method = 'POST';
    const queryParams = {
        appId: getAppId(),
    };
    const data = { email };

    const url = `${paths.userProfile}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const utknData = {
        urlBody: `${method}/${url}${JSON.stringify(data)}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(), // TODO: uncomment after anurag check
        'x-atv-platform': 'WEBOS',
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        method,
        url: base + url,
        data,
        headers: removeFalsy(headers),
    };
    return RequestUtil.POST(authOptions);
}

export async function updateUserInformation({ data, deviceUtil }) {
    const { base, paths } = env.endpoints.userApi;
    const method = 'PATCH';
    const queryParams = {
        appId: getAppId(),
    };


    const url = `${paths.userProfile}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const utknData = {
        urlBody: `${method}/${url}${JSON.stringify(data)}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(), // TODO: uncomment after anurag check
        'x-atv-platform': 'WEBOS',
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        method,
        url: base + url,
        data,
        headers: removeFalsy(headers),
    };
    return RequestUtil.PATCH(authOptions);
}

export async function getFaqFile() {
    const url = '/static/files/staticInformationText.json';
    return RequestUtil.GET({ url });
}

export async function getThemeConfig({ deviceUtil }) {
    const { base, paths } = env.endpoints.contentApi;

    const queryParams = {
        os: deviceUtil.getBrowserType(),
        bn: getBuildNumber(),
        appId: getAppId(),
        dt: getDeviceType(),
    };
    const url = `${base}${paths.theme}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const header = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url,
        headers: removeFalsy(header),
    });
}

export async function getPopupConfig({ deviceUtil }) {
    const { base, paths } = env.endpoints.userApi;

    const queryParams = {
        appId: getAppId(),
    };
    const url = `${paths.popup}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const method = 'POST';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const header = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'x-atv-segment': 'ATVPLUS',
        'x-atv-platform': 'WEBOS',
    };
    return RequestUtil.POST({
        url: base + url,
        headers: removeFalsy(header),
    });
}

export async function activatePartnerSubscription({ data, deviceUtil, atvCustomer }) {
    const { base, paths } = env.endpoints.userApi;

    const queryParams = {
        os: deviceUtil.getBrowserType(),
        bn: getBuildNumber(),
        appId: getAppId(),
        dt: getDeviceType(),
    };
    const url = `${paths.activatePartnerSubscription}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const method = 'POST';
    const utknData = {
        urlBody: `${method}/${url}${JSON.stringify(data)}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const header = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'x-atv-customer': atvCustomer,
    };
    return RequestUtil.POST({
        url: base + url,
        headers: removeFalsy(header),
        data,
    });
}


export async function getOffers({ deviceUtil }) {
    const { base, paths } = env.endpoints.userApi;
    const searchParams = LocationUtil.parse(window?.location?.search);
    const queryParams = {
        appId: searchParams[QUERY_PARAMS.APP_ID] || getAppId(),
        dt: searchParams[QUERY_PARAMS.DEVICE_TYPE] || getDeviceType(),
        os: searchParams[QUERY_PARAMS.OS] || deviceUtil.getBrowserType(),
        bn: searchParams[QUERY_PARAMS.BUILD_NUMBER] || getBuildNumber(),
    };
    const url = `${paths.getOffers}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: searchParams.token || AuthenticationUtil.getAuthToken(),
        uid: searchParams.uid || AuthenticationUtil.getUid(),
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const xtvDidFromLocal = deviceUtil.getXAtvDidFromLocal();

    const header = {
        'x-atv-did': xtvDidFromLocal || deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url: base + url,
        headers: removeFalsy(header),
    });
}

export async function getEpoch() {
    const { base, paths } = env.endpoints.epochApi;
    const url = `${paths.getEpoch}?currentTime=${new Date().getTime()}`;
    return RequestUtil.GET({
        url: base + url,
        headers: { 'avoid-custom-headers': true },
    });
}
