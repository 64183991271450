import { PropTypes } from 'prop-types';
import React from 'react';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';

const CenterPauseComponent = ({
    backward, pause, forward, backwardSkipDuration, forwardSkipDuration,
}) => {
    const skipBackward = () => {
        backward(forwardSkipDuration);
    };

    const skipForward = () => {
        forward(backwardSkipDuration);
    };

    return (
        <div className="vdo-player__play-options">
            <div className="d-flex justify-content-center align-items-center disable-select">
                {backwardSkipDuration > 0 ? (
                    <div className="vdo-player__play-icon for-mobile-only">
                        <button
                            type="button"
                            onClick={skipBackward}
                            className="button button-icon button-icon--backword"
                        >
                            {/* <i className="center-control-icon-size icon-player-forward" /> */}
                            <ThumborImage
                                src={IMAGE_PATHS.BACKWARD_SEEK}
                                className="player-center-icon"
                            />
                            <span className="time-duration time-duration--prev">{backwardSkipDuration}</span>
                        </button>
                    </div>
                ) : null}
                <div className="vdo-player__play-icon for-mobile-only">
                    <button
                        type="button"
                        onClick={pause}
                        className="button button-icon button-icon-pp"
                    >
                        {/* <i className="center-control-icon-size icon-player-play icon-player-play-toggle" /> */}
                        <ThumborImage
                            className="player-center-icon"
                            src={IMAGE_PATHS.PAUSE}
                        />
                    </button>
                </div>
                {forwardSkipDuration > 0 ? (
                    <div className="vdo-player__play-icon for-mobile-only">
                        <button
                            type="button"
                            onClick={skipForward}
                            className="button button-icon button-icon--backword"
                        >
                            {/* <i className="center-control-icon-size icon-player-backward" /> */}
                            <ThumborImage
                                src={IMAGE_PATHS.FORWARD_SEEK}
                                className="player-center-icon"
                            />
                            <span className="time-duration time-duration--next">{forwardSkipDuration}</span>
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    );
};


CenterPauseComponent.propTypes = {
    backward: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    forward: PropTypes.func.isRequired,
    forwardSkipDuration: PropTypes.number.isRequired,
    backwardSkipDuration: PropTypes.number.isRequired,
};

export default CenterPauseComponent;
