const ArtistAction = {
    ARTIST_DETAIL_FETCH: 'ARTIST_DETAIL_FETCH',
    ARTIST_DETAIL_FETCH_SUCCESS: 'ARTIST_DETAIL_FETCH_SUCCESS',
    ARTIST_DETAIL_FETCH_ERROR: 'ARTIST_DETAIL_FETCH_ERROR',
};

export default ArtistAction;

export const artistDetailFetchSuccess = payload => ({
    type: ArtistAction.ARTIST_DETAIL_FETCH_SUCCESS,
    ...payload,
});

export const artistDetailFetchError = payload => ({
    type: ArtistAction.ARTIST_DETAIL_FETCH_ERROR,
    ...payload,
});

export const artistDetailFetchAction = artistId => ({
    type: ArtistAction.ARTIST_DETAIL_FETCH,
    artistId,
});
