
import React from 'react';
import { StoreUtil } from '@airtel-tv/utils/StoreUtil';
// import { DeviceTypeUtil } from '@airtel-tv/utils/DeviceTypeUtil';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import {
    isOnline, getCurrentWindowSourceName,
} from '@airtel-tv/utils/WindowUtil';
import { ERROR_PAGE_ACTIONS, IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';
import { renderCrash } from '@airtel-tv/crash-capture';

const showErrorPage = payload => ({
    type: 'SHOW_ERROR_PAGE',
    ...payload,
});

function CrashCaptureHOC(WrappedComponent) {
    class CrashCapture extends React.PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                hideWrapperComponent: false,
            };
        }

        componentDidCatch(error, errorInfo) {
            // const moduleName = DeviceTypeUtil.isWeb() ? 'web' : 'tv';
            // // A API Service to be created
            // const moduleMap = {
            //     web: () => import('@airtel-tv/crash-capture'),
            //     tv: () => import('@airtel-tv/crash-capture')
            // }
            let errorStackTraceString = '';
            let errorString = '';
            let hasInternet = true;
            const {
                railProps = null, railId = '', railIndex = 0, railType = '',
                tileType = '',
            } = this.props;
            try {
                hasInternet = isOnline();
                errorString = new Error(error).toString();
                errorStackTraceString = JSON.stringify(errorInfo).substring(0, 500);
            }
            catch (e) {
                console.log('error in capture stringify');
            }

            renderCrash({
                source_name: getCurrentWindowSourceName(),
                errorStackTraceString,
                errorString,
                hasInternet,

            });
            if (railProps || railId) {
                const {
                    railIndex: railIndexInProps = '', railId: railIdInProps = '', railType: railTypeInProps = '',
                    tileType: tileTypeInProps = '',
                } = railProps || {};
                console.log(`error in railIndex-${railIndexInProps || railIndex} -raiId${railIdInProps || railId}-railType-${railTypeInProps || railType}-tiletype-${tileTypeInProps||tileType}`);
                this.setState({ hideWrapperComponent: true });
            }
            else {
                const LANGUAGES = LanguageProviderUtil.getLanguage();
                const {
                    ERROR_NETWORK = 'NerWork Error',
                    BUTTON_RETRY = 'Retry',
                    BUTTON_RELOAD = 'Reload',
                    APP_ISSUE = 'Something Went Wrong. Please Reload',
                    BUTTON_GOTO_HOME = 'Go To Home',
                } = LANGUAGES;
                const { dispatch } = StoreUtil.getStore();
                dispatch(showErrorPage({
                    errorPageData: {
                        message: !hasInternet ? ERROR_NETWORK : APP_ISSUE,
                        imageUrl: IMAGE_PATHS.BLACK_HOLE,
                        errorAction: ERROR_PAGE_ACTIONS.RELOAD,
                        buttonText: !hasInternet ? BUTTON_RETRY : BUTTON_RELOAD,
                        errorActionSecondary: ERROR_PAGE_ACTIONS.GOTO_HOME,
                        buttonTextSecondary: BUTTON_GOTO_HOME,
                    },
                }));
            }
        }

        render() {
            const { hideWrapperComponent } = this.state;
            return (hideWrapperComponent ? null : <WrappedComponent {...this.props} />);
        }
    }
    return CrashCapture;
}


export default CrashCaptureHOC;
