import {
    call, put, takeEvery, getContext, select, delay,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { showToastr } from '@airtel-tv/utils';
import { ErrorHandler } from '@airtel-feature/playback/factories/PlaybackUiComponentFactory';
import {
    XppClaimOnThanksAction, claimStatusUpdate, singleClaimFailure, singleClaimSuccess,
    updateUserConfiguration,
} from '../actions/XppClaimOnThanksAction';
import { xppClaimApi } from '../apiService';

function* xppClaimClick(action) {
    const { serviceId = '' } = action || {};

    yield put(claimStatusUpdate({
        success: false,
        apiFailed: false,
    }));

    try {
        const deviceUtil = yield getContext('deviceUtil');
        // const response = {
        //     success: true,
        //     message: 'Claim request processed successfully',
        //     rid: '5.635619E-4-1712230146341',
        // };
        const response = yield call(xppClaimApi, {
            serviceId,
            deviceUtil,
        });
        // throw new Error({ ...response });
        const { success = '' } = response;
        // yield delay(5000);
        yield put(singleClaimSuccess({
            ...response,
            apiFailed: !success,
        }));
        // yield put(updateUserConfiguration({}));
    }
    catch (e) {
        const data = lodashGet(e, 'data', e);
        const code = data?.errorcode || '';
        const { errortitle = '', message = '' } = data || {};
        yield put(singleClaimFailure({
            success: false,
            apiFailed: true,
            ...data,
        }));
        ErrorHandler({
            code,
            errortitle: errortitle || message,
        });
        // showToastr(`${code}: ${errortitle}`, '');
    }
}

export default [
    takeEvery(XppClaimOnThanksAction.SINGLE_CLAIM_CLICKED, xppClaimClick),
];
