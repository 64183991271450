import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { AppEnv } from '@airtel-tv/utils/GetEnv';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { LocationUtil, checkWindowExist } from '@airtel-tv/utils';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import {
    getAppId, getBuildNumber, getDeviceType, removeFalsy,
} from '@airtel-tv/utils/GlobalUtil';

const env = AppEnv.getEnv();
let { base, paths, serverBase } = env.endpoints.userApi;
base = checkWindowExist() ? base : serverBase;

export const getPaymentModes = ({
    appId, xAtvDid, planId, os,
}) => {
    const method = 'GET';

    const queryParams = {
        appId,
        planId,
        os,
        bn: getBuildNumber(),
        dt: getDeviceType(),
    };

    const utknData = {
        urlBody: `${method}/${paths.getPaymentModes}?${LocationUtil.objectToQueryParams(queryParams)}`,
        uid: AuthenticationUtil.getUid(),
        token: AuthenticationUtil.getAuthToken(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': xAtvDid,
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        url: `${base}${paths.getPaymentModes}?${LocationUtil.objectToQueryParams(queryParams)}`,
        headers: removeFalsy(headers),
    };
    return RequestUtil.GET(authOptions);
};

export const getBillAccountDetails = ({
    appId, xAtvDid, planId, os,
}) => {
    const method = 'GET';
    const queryParams = {
        appId,
        planId,
        os,
        bn: getBuildNumber(),
        dt: getDeviceType(),
    };

    const utknData = {
        urlBody: `${method}/${paths.accountDetails}?${LocationUtil.objectToQueryParams(queryParams)}`,
        uid: AuthenticationUtil.getUid(),
        token: AuthenticationUtil.getAuthToken(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': xAtvDid,
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        url: `${base}${paths.accountDetails}?${LocationUtil.objectToQueryParams(queryParams)}`,
        headers: removeFalsy(headers),
    };
    return RequestUtil.GET(authOptions);
};

export const getBillStatus = ({
    appId, xAtvDid, os, tid,
}) => {
    const method = 'GET';
    const queryParams = {
        appId,
        os,
        tid,
        bn: getBuildNumber(),
        dt: getDeviceType(),
    };

    const utknData = {
        urlBody: `${method}/${paths.checkPaymentStatus}?${LocationUtil.objectToQueryParams(queryParams)}`,
        uid: AuthenticationUtil.getUid(),
        token: AuthenticationUtil.getAuthToken(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': xAtvDid,
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        url: `${base}${paths.checkPaymentStatus}?${LocationUtil.objectToQueryParams(queryParams)}`,
        // url: `https://demo4602297.mockable.io/v2/bill/status?${LocationUtil.objectToQueryParams(queryParams)}`,
        headers: removeFalsy(headers),
    };
    return RequestUtil.GET(authOptions);
};

export const getBillRecharge = ({
    appId, xAtvDid, planId, os, si, lob,
}) => {
    const method = 'GET';

    const queryParams = {
        appId,
        planId,
        os,
        si,
        lob,
        bn: getBuildNumber(),
        dt: getDeviceType(),
    };

    const utknData = {
        urlBody: `${method}/${paths.billChargeRequest}?${LocationUtil.objectToQueryParams(queryParams)}`,
        uid: AuthenticationUtil.getUid(),
        token: AuthenticationUtil.getAuthToken(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': xAtvDid,
        'x-atv-utkn': utkn,
    };

    const authOptions = {
        url: `${base}${paths.billChargeRequest}?${LocationUtil.objectToQueryParams(queryParams)}`,
        // url: `https://demo4602297.mockable.io/v2/bill/charge?${LocationUtil.objectToQueryParams(queryParams)}`,
        headers: removeFalsy(headers),
    };
    return RequestUtil.GET(authOptions);
};
