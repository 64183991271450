import lodashGet from 'lodash/get';
import { FALLBACK_THEME } from '@airtel-tv/constants/GlobalConst';

export function getThemeParams(theme, defaultTheme) {
    const brandColor = lodashGet(theme, 'brandColor', lodashGet(defaultTheme, 'brandColor', FALLBACK_THEME.brandColor));
    const textColor = lodashGet(theme, 'textColor', lodashGet(defaultTheme, 'textColor', FALLBACK_THEME.textColor));
    const gradientStartColor = lodashGet(theme, 'gradientStartColor', lodashGet(defaultTheme, 'gradientStartColor', FALLBACK_THEME.gradientStartColor));
    const gradientEndColor = lodashGet(theme, 'gradientEndColor', lodashGet(defaultTheme, 'gradientEndColor', FALLBACK_THEME.gradientEndColor));
    const brandColorRGB = lodashGet(theme, 'brandColorRGB', lodashGet(defaultTheme, 'brandColorRGB', FALLBACK_THEME.brandColorRGB));
    const cardColorRGB = lodashGet(theme, 'cardColorRGB', lodashGet(defaultTheme, 'cardColorRGB', FALLBACK_THEME.cardColorRGB));
    const cardColor = lodashGet(theme, 'cardColor', lodashGet(defaultTheme, 'cardColor', FALLBACK_THEME.cardColor));

    return {
        brandColor,
        textColor,
        gradientStartColor,
        gradientEndColor,
        brandColorRGB,
        cardColorRGB,
        cardColor,
    };
}

export default getThemeParams;
