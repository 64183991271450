const FooterActions = {
    CONTENT_PAGE_FOOTER: 'CONTENT_PAGE_FOOTER',
    CONTENT_PAGE_FOOTER_COMPLETED: 'CONTENT_PAGE_FOOTER_COMPLETED',
    PAGE_DESCRIPTION_UPDATE: 'PAGE_DESCRIPTION_UPDATE',
};

export default FooterActions;

export const getContentPageFooterAction = payload => ({
    type: FooterActions.CONTENT_PAGE_FOOTER,
    ...payload,
});

export const getContentPageFooterActionCompleted = payload => ({
    type: FooterActions.CONTENT_PAGE_FOOTER_COMPLETED,
    ...payload,
});

export const updateOnPageDescriptionAction = payload => ({
    type: FooterActions.PAGE_DESCRIPTION_UPDATE,
    payload,
});
