import Detector from 'detector';
import lodashGet from 'lodash/get';
import { NETWORK_TYPE } from '@airtel-tv/constants/NetworkConst';
import {
    BROWSER_LIST,
    IMAGE_TYPE,
    OS_LIST,
    CRAWLER_BOTS,
} from '@airtel-tv/constants/BrowserConst';
import {
    LOCAL_STORE_KEYS, PLAYBACK_CHECK, QUERY_PARAMS, SCOPED_WEBVIEW,
} from '@airtel-tv/constants/GlobalConst';
import browserStore from './BrowserStoreUtil';
import { getWindowNavigator, getWindowInnerWidth, getInnerHeight } from './WindowUtil';
import { getAppVersion, getUuid } from './GlobalUtil';
import { LocationUtil } from './LocationUtil';
import { AppEnv } from './GetEnv';
import { checkWindowExist } from '.';

const env = AppEnv.getEnv();
class DeviceUtility {
    constructor(params) {
        const { userAgent = null, mobileHeader = null, baseUrl } = params || {};
        this.deviceInformationMeta = null;
        this.mobileHeader = null;
        this.deviceName = null;
        this.osVersion = null;
        this.browserName = null;
        this.browserVersion = null;
        this.deviceViewType = null;

        if (getWindowInnerWidth) {
            this.windowWidth = getWindowInnerWidth() || 2001;
        }
        else {
            this.windowWidth = 2001; // 2001 so that all 15 tile are shown,
        }

        this.windowHeight = getInnerHeight() || 945;
        this.privateFingerPrintHolder = this.getFingerPrintFromLocal();

        this.baseUrl = baseUrl || (window && window.location && `${window.location.origin}${window.location.pathname.includes('LARGESCREEN_LGOS') ? '/LARGESCREEN_LGOS' : ''}`);

        if (userAgent) {
            this.deviceInformationMeta = Detector.parse(userAgent);
            this.mobileHeader = mobileHeader;
        }
        else {
            this.deviceInformationMeta = Detector;
        }
    }

    getFingerPrintFromLocal = () => browserStore.get(LOCAL_STORE_KEYS.FINGER_PRINT);

    setFingerPrintFromLocal = value => browserStore.set(LOCAL_STORE_KEYS.FINGER_PRINT, value);

    getBaseUrl = () => (this.baseUrl);

    isBot = () => !!CRAWLER_BOTS[this.getBrowserName()];

    getNetworkType = () => {
        const windowNavigator = getWindowNavigator();
        const connection = windowNavigator.connection || windowNavigator.mozConnection || windowNavigator.webkitConnection || null;
        if (connection === null) {
            return NETWORK_TYPE.DEFAULT;
        }
        if (connection.type === 'cellular') {
            return NETWORK_TYPE.CELLULAR;
        }
        return NETWORK_TYPE.WIFI;
    };

    isMobile = () => {
        if (this.mobileHeader) {
            return (this.mobileHeader === '?1');
        }
        switch (this.deviceInformationMeta?.os?.name || '') {
            case OS_LIST.WINDOWS:
            case OS_LIST.OSX:
            case OS_LIST.LINUX:
            case OS_LIST.NA: // google bot returning 'na'
                return false;

            default:
                return true;
        }
    };

    isMobileViewport = () => {
        if (checkWindowExist()) {
            return window.screen.width < 768;
        }
        throw 'window doesnot exists';
    };

    isTabletViewPort = () => {
        if (checkWindowExist()) {
            return window.screen.width < 992;
        }
        return false;
    };

    isIOS = () => (this.deviceInformationMeta?.os?.name || '') === OS_LIST.IOS;

    isWindows = () => (this.deviceInformationMeta?.os?.name || '') === OS_LIST.WINDOWS;

    is64BitWindows = () => {
        const useragent = this.getUserAgent();
        if (this.isWindows()) {
            if (useragent.indexOf('WOW64') !== -1
            || userAgent.indexOf('Win64') !== -1) {
                return true;
            }
        }
        return false;
    };

    isChrome = () => this.getBrowserName() === BROWSER_LIST.CHROME;

    getBrowserType = () => (this.isWeb() ? (this.isMobile() ? env?.browserOS?.mobile : env?.browserOS?.desktop) : env?.osType);

    // createFingerPrint = async () => {
    //     if (this.privateFingerPrintHolder) {
    //         return Promise.resolve(this.privateFingerPrintHolder);
    //     }

    //     return new Promise((resolve, reject) => {
    //         try {
    //             Fingerprint2.get((components) => {
    //                 const values = components.map(component => component.value);
    //                 const murmur = Fingerprint2.x64hash128(values.join(''), 31);
    //                 this.privateFingerPrintHolder = murmur;
    //                 resolve(murmur);
    //             });
    //         }
    //         catch (error) {
    //             reject(error);
    //         }
    //     });
    // };

    createFingerPrint = () => {
        if (this.privateFingerPrintHolder) {
            return this.privateFingerPrintHolder;
        }

        this.privateFingerPrintHolder = getUuid();
        this.setFingerPrintFromLocal(this.privateFingerPrintHolder);
        return this.privateFingerPrintHolder;
    };


    // getImageFormat = (transparent = false) => {
    //     //* transparent* images are only supported in png and webp format, special handling for channels

    //     const dn = this.deviceInformationMeta.device.name;
    //     // console.error(this.deviceInformationMeta.browser.name);
    //     if (dn === HARDWARE_LIST.IPAD || dn === HARDWARE_LIST.IPHONE || dn === HARDWARE_LIST.IPOD) {
    //         return transparent ? IMAGE_TYPE.PNG : IMAGE_TYPE.JPG;
    //     }

    //     if (this.deviceInformationMeta.browser.name === BROWSER_LIST.FIREFOX && this.deviceInformationMeta.browser.version < 65) {
    //         return IMAGE_TYPE.JPG;
    //     }

    //     if (IMAGE_FORMAT[this.deviceInformationMeta.browser.name] !== undefined) {
    //         if (transparent && this.deviceInformationMeta.browser.name === BROWSER_LIST.SAFARI) {
    //             return IMAGE_TYPE.PNG;
    //         }
    //         return IMAGE_FORMAT[this.deviceInformationMeta.browser.name];
    //     }
    //     return IMAGE_TYPE.JPG;
    // };

    getImageFormat = (url) => {
        if (!url) {
            return '';
        }
        if (!this.isWeb()) {
            return IMAGE_TYPE.WEBP;
        }
        const extension = LocationUtil.getUrlExtension(url);

        if (extension === IMAGE_TYPE.SVG) {
            return extension;
        }

        const browserName = this.getBrowserName();
        const browserVersion = this.getBrowserVersion();
        const osName = this.getOSName();
        if (
            ((browserName === BROWSER_LIST.FIREFOX && browserVersion >= 69)
            || (browserName === BROWSER_LIST.CHROME && browserVersion >= 32)
            || (browserName === BROWSER_LIST.OPERA && browserVersion >= 62)
            || (browserName === BROWSER_LIST.MICROSOFT_EDGE_BROWSER && browserVersion >= 18)
            || (browserName === BROWSER_LIST.UC_BROWSER && browserVersion >= 12)
            || this.isBot())
            && osName !== OS_LIST.IOS
        ) {
            return IMAGE_TYPE.WEBP;
        }

        return extension;
    };

    getBrowserVersion = () => this.browserVersion || lodashGet(this.deviceInformationMeta, 'browser.version', '');

    getDeviceName = () => this.deviceName || lodashGet(this.deviceInformationMeta, 'device.name', '');

    getBrowserName = () => this.browserName || lodashGet(this.deviceInformationMeta, 'browser.name', '');

    getOSName = () => {
        if (this.isWeb()) {
            return lodashGet(this.deviceInformationMeta, 'os.name', '');
        }
        return env.osType;
    };

    getOSVersion = () => this.osVersion || lodashGet(this.deviceInformationMeta, 'os.fullVersion', '');

    setDeviceName = (deviceName) => {
        this.deviceName = deviceName;
    };

    setOsVersion = (version) => {
        this.osVersion = version;
    };

    setBrowserName = (browserName) => {
        this.browserName = browserName;
    };

    setBrowserVersion = (browserVersion) => {
        this.browserVersion = browserVersion;
    };
    // clone = (oldObject, newObject) => ({ ...oldObject, ...newObject });

    getXAtvDid = () => `${this.privateFingerPrintHolder}|${env.deviceType}|${this.getBrowserType()}|${this.deviceInformationMeta.os.fullVersion}|${env.buildNumber}|${getAppVersion()}|${this.getDeviceName()}|${this.getDeviceName()}`;


    getDid = () => this.privateFingerPrintHolder;

    getAtvCustomer = (customerType, networkType) => `${env.atvCustomer.MCC}-${env.atvCustomer.MNC}|${customerType}|${networkType}|${env.atvCustomer.MNC}|${env.atvCustomer.deviceTypeId}`;

    getWindowWidth = () => (this.isBot() ? 1501 : this.windowWidth); // for google bot all 15 tiles should be shown

    getWindowHeight = () => (this.isBot() ? 945 : this.windowHeight);

    // DEVICE ID
    saveDeviceIdToLocal = (deviceId) => {
        browserStore.set(QUERY_PARAMS.DEVICE_ID, deviceId);
    };

    getDeviceIdFromLocal = () => browserStore.get(QUERY_PARAMS.DEVICE_ID);

    removeDeviceIdFromLocal = () => {
        browserStore.remove(QUERY_PARAMS.DEVICE_ID);
    };

    // DEVICE TYPE
    saveDeviceTypeToLocal = (deviceType) => {
        browserStore.set(QUERY_PARAMS.DEVICE_TYPE, deviceType);
    };

    getDeviceTypeFromLocal = () => browserStore.get(QUERY_PARAMS.DEVICE_TYPE);

    removeDeviceTypeFromLocal = () => {
        browserStore.remove(QUERY_PARAMS.DEVICE_TYPE);
    };

    // OS
    saveOsToLocal = (deviceId) => {
        browserStore.set(QUERY_PARAMS.OS, deviceId);
    };

    getOsFromLocal = () => browserStore.get(QUERY_PARAMS.OS);

    removeOsFromLocal = () => {
        browserStore.remove(QUERY_PARAMS.OS);
    };

    // OS VERSION
    saveOsVersionToLocal = (osVersion) => {
        browserStore.set(QUERY_PARAMS.OS_VERSION, osVersion);
    };

    getOsVersionFromLocal = () => browserStore.get(QUERY_PARAMS.OS_VERSION);

    removeOsVersionFromLocal = () => {
        browserStore.remove(QUERY_PARAMS.OS_VERSION);
    };

    // APP VERSION
    saveAppVersionToLocal = (appVersion) => {
        browserStore.set(QUERY_PARAMS.APP_VERSION, appVersion);
    };

    getAppVersionFromLocal = () => browserStore.get(QUERY_PARAMS.APP_VERSION);

    removeAppVersionFromLocal = () => browserStore.remove(QUERY_PARAMS.APP_VERSION);

    // BUILD NUMBER
    savebuildNumberToLocal = (buildNumber) => {
        browserStore.set(QUERY_PARAMS.BUILD_NUMBER, buildNumber);
    };

    getBuildNumberFromLocal = () => browserStore.get(QUERY_PARAMS.BUILD_NUMBER);

    removeBuildNumberFromLocal = () => browserStore.remove(QUERY_PARAMS.BUILD_NUMBER);

    getXAtvDidFromLocal = () => {
        const searchParams = typeof window !== 'undefined' ? LocationUtil.parse(window.location.search) : {};
        const deviceId = searchParams[QUERY_PARAMS.DEVICE_ID] || this.getDeviceIdFromLocal();
        const type = searchParams[QUERY_PARAMS.DEVICE_TYPE] || this.getDeviceTypeFromLocal();
        const os = searchParams[QUERY_PARAMS.OS] || this.getOsFromLocal();
        const osVersion = searchParams[QUERY_PARAMS.OS_VERSION] || this.getOsVersionFromLocal();
        const appVersion = searchParams[QUERY_PARAMS.APP_VERSION] || this.getAppVersionFromLocal();
        const buildNumber = searchParams[QUERY_PARAMS.BUILD_NUMBER] || this.getBuildNumberFromLocal();
        if (!deviceId && !type && !os && !osVersion && !appVersion && !buildNumber) {
            return null;
        }
        return `${deviceId}|${type}|${os}|${osVersion}|${buildNumber}|${appVersion}`;
    };

    removeDeviceAttributesFromLocal = () => {
        this.removeDeviceIdFromLocal();
        this.removeDeviceTypeFromLocal();
        this.removeOsFromLocal();
        this.removeOsVersionFromLocal();
        this.removeAppVersionFromLocal();
        this.removeBuildNumberFromLocal();
    };

    getUserAgent = () => window && window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase() || '';

    getPlatform = () => {
        let platform = window && window.navigator && window.navigator.platform && window.navigator.platform.toLowerCase() || '';
        const userAgent = window && window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase() || '';
        if (userAgent.includes('tizen') || userAgent.includes('smart-tv') || userAgent.includes('smarttv')) {
            platform = 'smarttv';
        }
        if (platform == 'win32') {
            if (userAgent.indexOf('wow64') !== -1
        || userAgent.indexOf('win64') !== -1) {
                platform = 'win64';
            }
        }
        return platform;
    };

    hasTouchScreen = ua => /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(ua)
        || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(ua);

    isWeb = () => AppEnv.getEnv()?.deviceType === PLAYBACK_CHECK.BROWSER;

    isScopedWebview = (isScopedWebview) => {
        if ((checkWindowExist() && window.INITIAL_STATE?.appConfig?.isScopedWebview) || this.viewType === SCOPED_WEBVIEW) {
            return true;
        }
        if (isScopedWebview) {
            return true;
        }
        const viewType = browserStore.get(LOCAL_STORE_KEYS.VIEW_TYPE);

        if (viewType === SCOPED_WEBVIEW) {
            this.viewType = SCOPED_WEBVIEW;
            return true;
        }
        return false;
    };
}


export default class DeviceUtil {
    static instance;

    static getDeviceUtil() {
        if (!this.instance) {
            throw new Error('Device Utillity has not been instanced');
        }
        return this.instance;
    }

    static setDeviceUtil(params) {
        // if(this.instance) {
        //     throw new Error('DeviceUtility is already set');
        // }
        this.instance = new DeviceUtility(params);
    }
}
