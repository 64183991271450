import { DeviceTypeUtil} from './DeviceTypeUtil';
import {LANGUAGES_CODES } from '@airtel-tv/constants/GlobalConst';


export class LanguageProviderUtil {
    static store = null;
    static isWeb = DeviceTypeUtil?.isWeb();
    static moduleName = this.isWeb ? 'web' : 'tv';
    static LANGUAGE = {};
    static WEB_META = {};

    static setProvider(store) {
        this.store = store || null;

        const moduleMap = {
            web: () => import('@airtel-tv/constants/enWeb'),
            tv: () => import('@airtel-tv/constants/enLS'),
            webMeta : () => import('@airtel-tv/constants/web_meta'),
        }
        const module = moduleMap[this.moduleName];
        if(this.isWeb){
            moduleMap['webMeta']().then(({WEB_META}) => {
                this.WEB_META = WEB_META;
            });
        }
       
        if (module) {
            module().then(({LANGUAGE}) => {
                this.LANGUAGE = LANGUAGE;
            });
        }
    }

    static getLanguage() {
        const key = this.isWeb ? 'web_en' : 'largescreen_en';
        const state = this.store?.getState();
        if (state?.appConfig?.[key]) {
            return state?.appConfig[key];
        }
        return this.LANGUAGE;
    }

    static MetaLanguageProvider() {
        const state = this.store?.getState();
        if (state?.appConfig?.web_meta) {
            return state?.appConfig.web_meta;
        }
        return this.WEB_META;
    }

    static getOfflineLanguage() {
        return this.LANGUAGE;
    }

    static getLanguageCodes() {
        // const store = StoreUtil.getStore().getState();
        const state = this.store?.getState();
        if (this.isWeb) {
            if (state?.appConfig?.web_languages_codes) {
                return state?.appConfig.web_languages_codes;
            }
        }
        else {
            if (state?.appConfig?.largescreen_languages_codes) {
                return state?.appConfig.largescreen_languages_codes;
            }
        }
        return LANGUAGES_CODES;
    }
}