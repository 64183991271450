import lodashGet from 'lodash/get';
import { redirectToInNewTab } from '@airtel-tv/utils/WindowUtil';
import { StoreUtil } from '@airtel-tv/utils';
// import { getStore } from '../../redux/Store';


export const openContentInApp = () => {
    const store = StoreUtil.getStore();
    const sharableContent = lodashGet(store.getState(), 'appConfig.sharableContent', {});
    // if (isMobile) {
    //     openTab(base + openContentInMobileApp + contentIdShortLink + QUERY.WEB_SHARE, '_blank');
    // }
    // else {
    redirectToInNewTab(sharableContent.CONTENT_RIGHTS_DEEP_LINK);
    // }
};

export default {
    openContentInApp,
};
