import 'intersection-observer';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DeviceTypeUtil, createIntersectionObserverApi } from '@airtel-tv/utils';

class IntersectionObservableHOC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curPage: 0,
            id: '',
        };
        this.infiniteScrollerRef = React.createRef();
    }

    componentDidMount() {
        const { startPageNumber: curPage } = this.props;
        this.setState({
            curPage,
        });
        this.createObserverApi();
        this.bindObserverApi();
    }

    componentDidUpdate() {
        const { id } = this.props;
        const { id: prevId } = this.state;
        if (id && prevId && id !== prevId) {
            this.createObserverApi();
            this.bindObserverApi();
        }
    }

    componentWillUnmount() {
        this.intersectionObserver.disconnect();
    }

    createObserverApi = () => {
        const { threshold } = this.props;
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
        }
        this.intersectionObserver = createIntersectionObserverApi(
            this.intersectionCallback,
            0,
            threshold,
        );
    };

    intersectionCallback = (entries) => {
        const ratio = entries[0].intersectionRatio;
        const { fetchMore, visibilityChange } = this.props;
        if (ratio === 1 && visibilityChange) {
            visibilityChange(ratio);
        }
        if (ratio > 0 && fetchMore) {
            console.log(`ratio ${ratio}`);
            const { curPage } = this.state;
            fetchMore(curPage);
            this.setState({
                curPage: curPage + 1,
            });
        }
    };

    bindObserverApi = () => {
        const { id } = this.props;
        if (this.infiniteScrollerRef.current) {
            this.intersectionObserver.observe(this.infiniteScrollerRef.current);
            this.setState({
                id,
            });
        }
    };

    render() {
        const {
            children,
            hasMore,
            loaderElem,
        } = this.props;
        const loader = { ...loaderElem };
        loader.ref = this.infiniteScrollerRef;
        if (!children) {
            return loader;
        }

        const childrenElem = [
            children,
        ];

        if (hasMore) {
            childrenElem.push(loader);
        }
        return childrenElem;
    }
}

IntersectionObservableHOC.defaultProps = {
    startPageNumber: 0,
    children: null,
    fetchMore: null,
    visibilityChange: null,
    id: '',
    threshold: DeviceTypeUtil.isWeb() ? [
        1,
    ] : [
        0.5,
    ],
    loaderElem: null,
};

IntersectionObservableHOC.propTypes = {
    children: PropTypes.node,
    fetchMore: PropTypes.func,
    hasMore: PropTypes.bool.isRequired,
    loaderElem: PropTypes.node,
    startPageNumber: PropTypes.number,
    visibilityChange: PropTypes.func,
    id: PropTypes.string,
    threshold: PropTypes.array,
};

export { IntersectionObservableHOC };
