import {
    takeEvery, call, put, getContext,
} from 'redux-saga/effects';
import { getPopupConfig } from '../../service/end-points/ConfigApiService';
import { PopupActions, PopupConfigErrorAction, PopupConfigSuccessAction } from './PopupActions';


function* popupConfigFetch() {
    const deviceUtil = yield getContext('deviceUtil');

    try {
        const popupConfig = yield call(getPopupConfig, { deviceUtil });

        yield put(PopupConfigSuccessAction(popupConfig));
    }
    catch (error) {
        console.error(error);
        const payload = {
            error,
            fetching: false,
        };
        yield put(PopupConfigErrorAction(payload));
    }
}


export default [
    takeEvery(PopupActions.POPUP_CONFIG_FETCH, popupConfigFetch),
];
