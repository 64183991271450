import React, { useState, useEffect } from 'react';
import lodashIsEqual from 'lodash/isEqual';
import TileLoader from '@airtel-tv/ui-lib/atoms/TileLoader';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { IntersectionObservableHOC } from './IntersectionObservableHOC';
import { INTERSECTION_OBSERVER_THRESHOLD } from '@airtel-tv/constants';

function HorizontalLazyLoaderHOC(props) {
    const [
        isRemainingContentVisible,
        toggleRemainingContentVisible,
    ] = useState(false);

    const [
        defaultTileElement,
        updateDefaultTileElement,
    ] = useState([]);

    const [
        tileToBeAdded,
        updateTileToBeAdded,
    ] = useState([]);
    const {
        tileElements, deviceUtil, tileLimit, railKey, isLoaderNeeded = true
    } = props;

    useEffect(() => {
        const firstTileElement = tileElements.slice(0, tileLimit);
        const remainingTiles = tileElements.slice(tileLimit);
        if (isRemainingContentVisible && !lodashIsEqual(defaultTileElement, tileElements)) {
            updateDefaultTileElement(tileElements);
        }
        else if (!isRemainingContentVisible && !lodashIsEqual(defaultTileElement, firstTileElement)) {
            updateDefaultTileElement(firstTileElement);
            updateTileToBeAdded(remainingTiles);
        }
    }, [
        tileElements,
        tileLimit,
        defaultTileElement,
        isRemainingContentVisible,
    ]);

    if (deviceUtil.isBot()) {
        return tileElements;
    }

    if (!isRemainingContentVisible && tileLimit >= tileElements.length) {
        toggleRemainingContentVisible(true);
    }

    function showRemainingTiles() {
        toggleRemainingContentVisible(true);
        updateDefaultTileElement([
            ...defaultTileElement,
            ...tileToBeAdded,
        ]);
    }

    const loaderElem = isLoaderNeeded ? <TileLoader /> : <></>;
    const openingTile = (
        <IntersectionObservableHOC
            fetchMore={showRemainingTiles}
            hasMore={!isRemainingContentVisible}
            loaderElem={loaderElem}
            startPageNumber={1}
            threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
        >
            { defaultTileElement}
        </IntersectionObservableHOC>
    );

    return openingTile;
}

export default withDeviceUtil(React.memo(HorizontalLazyLoaderHOC));
