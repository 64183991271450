import { ANALYTICS_CATEGORY,  } from '@airtel-tv/constants/GlobalConst';
import { AppEnv } from '@airtel-tv/utils/GetEnv';


//ToDo: Move Remaining code of ApiAnalytics here as part of player unification
const env = AppEnv.getEnv();

export const getCriticalApiCategory = (envEndpoints, origin, pathname) => {
    const { packageApi, contentApi, syncApi, userApi, searchApi } = envEndpoints;

    const critical = {
        [packageApi.base]: [
            { path: packageApi.paths.getPaginatedLayout, category: ANALYTICS_CATEGORY.PACKAGE_LAYOUT },
        ],
        [contentApi.base]: [
            { path: contentApi.paths.appConfig, category: ANALYTICS_CATEGORY.APP_CONFIG },
            { path: contentApi.paths.detailedContent, category: ANALYTICS_CATEGORY.CONTENT_DETAIL },
        ],
        [syncApi.base]: [
            { path: syncApi.paths.contentSync, category: ANALYTICS_CATEGORY.CONTENT_SYNC },
        ],
        [userApi.base]: [
            { path: userApi.paths.login, category: ANALYTICS_CATEGORY.USER_LOGIN },
            { path: userApi.paths.allPlans, category: ANALYTICS_CATEGORY.ALL_PLANS },
            { path: userApi.paths.myPlans, category: ANALYTICS_CATEGORY.MY_PLANS },
        ],
        [searchApi.base]: [
            { path: searchApi.paths.searchByPaginatedQuery, category: ANALYTICS_CATEGORY.SEARCH },
        ],
    };

    let matchingPath;
    Object.keys(critical).map(item => {
        if (item === origin + '/') {
            const pathList = critical[item];
            matchingPath = pathList.find((pathObj) => '/' + pathObj.path  == pathname)
        }
    });
    return matchingPath;
};

export const getApiResponseMeta = (errorResponse) => {
    const url = errorResponse?.config?.url;

    const parsedUrl = new URL(url);
    const matchedPathObj = getCriticalApiCategory(env.endpoints, parsedUrl.origin, parsedUrl.pathname);
    if (!matchedPathObj) {
        return null;
    }

    const {config,  data} = errorResponse;
    let {errorcode} = data
    const { headers } = config
    errorcode = errorcode || errorResponse.status;
    const obj = {
        request_category: matchedPathObj.category,
        request_status : 'failure',
        request_error_code: errorcode ? `${errorcode}` : undefined,
        requestx_host: parsedUrl.origin,
        x_atv_traceid: headers['x-atv-utkn']
    };
    return obj;
};
