import React from 'react';
import PropTypes from 'prop-types';

const PlanDetails = (props) => {
    const {
        tileProps,
    } = props;

    const {
        planDetails: {
            meta: {
                tags: specialTags = [],
            } = {},
            planTitle = '',
            discountedPrice: amount = '',
        } = {},
        tileSubHeading = [],
        planTitleElement = null,
        planSubtitleElement = null,
    } = tileProps;

    return (
        <div className="plan-details-wrapper">
            {specialTags?.length ? (
                <div className="plan-tags">
                    {specialTags.map(tag => <span className="plan-tags-holder text-10 text-bolder line-height-14 mobile-line-height-12 mobile-text-10 text-ls-16 text-ls-super-bolder">{tag}</span>)}
                </div>
            ) : null}
            {planTitleElement ? (
                <div className="plan-title text-20 text-bold line-height-28 mobile-line-height-24 mobile-text-16 text-ls-36 text-ls-bolder line-height-ls-46">
                    {planTitleElement}
                </div>
            ) : null}
            {planSubtitleElement ? (
                <div className="plan-subtitle text-16 text-bold line-height-24 mobile-text-12 mobile-text-semi-bold mobile-line-height-16 text-ls-22 text-ls-normal line-height-ls-28">
                    {planSubtitleElement}
                </div>
            ) : null}
            <div className="plan-devider" />
        </div>
    );
};

PlanDetails.defaultProps = {};

PlanDetails.propTypes = {
    tileProps: PropTypes.object.isRequired,
    planTitleElement: PropTypes.element.isRequired,
};

export default PlanDetails;
