import React from 'react';
import PropTypes from 'prop-types';
import { LinkBuilder } from '../../builder/MenuListLinkBuilder';
import { getCurrentWindowSourceName } from '../../../../utilities/WindowUtil';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics';

function DropDownItem(props) {
    const { item, menuSrc } = props;
    const {
        title, type,
    } = item;
    const analyticsMeta = {
        sourceName: menuSrc,
        source_name: getCurrentWindowSourceName(),
        type,
        title,
    };

    return (
        <li className="item sub-menu-items-default common-share-dropdown-button">
            <AnalyticsLinkComponent
                to={LinkBuilder({
                    item: {
                        menuSrc,
                        ...item,
                    },
                })}
                meta={analyticsMeta}
                className="item-link"
            >
                {title.toLowerCase()}
            </AnalyticsLinkComponent>
        </li>
    );
}

DropDownItem.propTypes = {
    menuSrc: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default DropDownItem;
