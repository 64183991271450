export const PAYLOAD_FORMATS = {
    BASE64: 'base64',
    ARRAY_BUFFER: 'arraybuffer',
};

export const ENCRYPTION_TYPES = {
    HMAC: 'hmac',
};

export const PAYLOAD_KEY_PLACEHOLDER = 'EXO_PAYLOAD';
export const PAYLOAD_IOS_KEY_PLACEHOLDER = 'IOS_PAYLOAD';
export const IOS_CERTIFICATE_HEADER_PLACEHOLDER = 'IOS_HEADERS';
export const IOS_LICENSE_HEADER_PLACEHOLDER = 'IOS_HEADERS';

export const PAYLOAD_TYPES = {
    DEFAULT: 'default',
};

export const HEART_BEAT_FALLBACK_INTERVAL = 30 * 1000;

export const NEXT_EPISODE_TIMER = 10;

export const ADD_TO_CW_THRESHOLD_TIMER = 60;

export const INVALID_PLAYBACK_URL = 'INVALID_PLAYBACK_URL';

export const SHOW_PLAYBACK_TRUTHY_PARAM = 'showPlayback=true';
export const SHOW_PLAYBACK_FALSY_PARAM = 'showPlayback=false';

export default {
    PAYLOAD_FORMATS,
    PAYLOAD_KEY_PLACEHOLDER,
    ENCRYPTION_TYPES,
    IOS_CERTIFICATE_HEADER_PLACEHOLDER,
    PAYLOAD_IOS_KEY_PLACEHOLDER,
    IOS_LICENSE_HEADER_PLACEHOLDER,
    PAYLOAD_TYPES,
    HEART_BEAT_FALLBACK_INTERVAL,
    INVALID_PLAYBACK_URL,
    SHOW_PLAYBACK_TRUTHY_PARAM,
    SHOW_PLAYBACK_FALSY_PARAM,
};
