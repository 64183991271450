export const PopupActions = {
    POPUP_CONFIG_FETCH: 'POPUP_CONFIG_FETCH',
    POPUP_CONFIG_SUCCESS: 'POPUP_CONFIG_SUCCESS',
    POPUP_CONFIG_ERROR: 'POPUP_CONFIG_ERROR',
};

export const PopupConfigSuccessAction = payload => ({
    type: PopupActions.POPUP_CONFIG_SUCCESS,
    payload,
});

export const PopupConfigFetchAction = payload => ({
    type: PopupActions.POPUP_CONFIG_FETCH,
    payload,
});

export const PopupConfigErrorAction = payload => ({
    type: PopupActions.POPUP_CONFIG_ERROR,
    payload,
});

export default {
    PopupActions,
    PopupConfigSuccessAction,
    PopupConfigFetchAction,
    PopupConfigErrorAction,
};
