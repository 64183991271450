import { SYNC_ACTIONS } from './SyncActions';

const initialState = JSON.parse(`{
    "continueWatchingData": {},
    "continueWatchingRail": {},
    "lastSyncUpdated": 0,
    "toBeAddedFromRecent": {},
    "toBeRemovedFromRecent": {},
    "watchList": {},
    "addToWatchList": {},
    "removeFromWatchList": {}
}`);

const SyncReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SYNC_ACTIONS.SYNC_CONTENT_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case SYNC_ACTIONS.SYNC_FROM_LOCAL_STORAGE:
            return {
                ...action.payload,
            };
        case SYNC_ACTIONS.SYNC_CONTENT_CLEARED:
            return {
                continueWatchingData: {},
                continueWatchingRail: {},
                // serverSyncedList: [],
                lastSyncUpdated: 0,
                toBeAddedFromRecent: {},
                toBeRemovedFromRecent: {},
                watchList: {},
                addToWatchList: {},
                removeFromWatchList: {},
            };
        case SYNC_ACTIONS.SYNC_CONTENT_CALCULATED_OUTPUT:
            return {
                ...state,
                ...action.payload,
            };
        case SYNC_ACTIONS.ADD_SYNC_LANGUAGE:
            return {
                ...state,
                lang: action.payload,
            };
        default:
            return state;
    }
};

export default SyncReducer;
