import { CACHE_CONFIGS } from '@airtel-tv/constants/GlobalConst';

const cacheWhitelist = [
    CACHE_CONFIGS.CRITICAL_PRIORITY_CACHE,
    CACHE_CONFIGS.HIGH_PRIORITY_CACHE,
    CACHE_CONFIGS.LIFE_TIME_CACHE,
    CACHE_CONFIGS.LOW_PRIORITY_CACHE,
    CACHE_CONFIGS.MEDIUM_PRIORITY_CACHE,
];

export default class CacheUtil {
    static clearAllCache = () => {
        if (window && window.caches && window.caches.keys) {
            return window.caches.keys().then(cacheNames => Promise.all(
                cacheNames.forEach((cacheName) => {
                    if (cacheWhitelist.indexOf(cacheName) !== -1) {
                        return window.caches.delete(cacheName);
                    }
                    return false;
                }),
            ));
        }
        return false;
    }

    static clearByName = (name) => {
        if (window && window.caches) {
            return window.caches.delete(name);
        }
        return false;
    }
}
