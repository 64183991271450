import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { removeFalsy, getAppId } from '@airtel-tv/utils/GlobalUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import env from '../../config';

let { base, paths, serverBase } = env.endpoints.userApi;
base = checkWindowExist() ? base : serverBase;

export const checkRtnApi = async ({
    deviceUtil,
    customerRtn,
}) => {
    const url = `${base}${paths.getDthAccount}`;
    const method = 'GET';
    const params = {
        appId: getAppId(),
    };
    const utknData = {
        urlBody: `${method}/${paths.getDthAccount}?${LocationUtil.objectToQueryParams(params)}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'x-msisdn': customerRtn,
    };
    return RequestUtil.GET({
        url,
        params,
        headers: removeFalsy(headers),
    });
};


export const verifyHappyCodeApi = async ({
    deviceUtil, dthCustomerId, installationCode, customerRtn,
}) => {
    const method = 'POST';
    const params = {
        appId: getAppId(),
    };
    const data = {
        dthCustId: dthCustomerId,
        rtn: customerRtn,
        code: installationCode,
        source: getAppId(),
        agentId: '',
        agentName: '',
    };

    const utknData = {
        urlBody: `${method}/${paths.verifyHappyCode}?${LocationUtil.objectToQueryParams(params)}${JSON.stringify(data)}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'Content-Type': 'application/json',
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    const request = {
        data,
        params,
        url: `${base}${paths.verifyHappyCode}`,
        headers: removeFalsy(headers),
    };
    return RequestUtil.POST(request);
};
