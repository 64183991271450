export const ChannelAction = {
    FETCH_CHANNELS: 'FETCH_CHANNELS',
    FETCH_CHANNELS_SUCCESS: 'FETCH_CHANNELS_SUCCESS',
};

export const fetchAllChannels = payload => ({
    type: ChannelAction.FETCH_CHANNELS,
    ...payload,
});

export const fetchAllChannelsSuccess = payload => ({
    type: ChannelAction.FETCH_CHANNELS_SUCCESS,
    ...payload,
});

export default {
    ChannelAction,
};
