
const EventModel = {
    appid: '',
    app_session_id: '',
    av: '',
    bn: '',
    brand: '',
    did: '',
    dname: '',
    dt: '',
    event_type: '',
    lc: '',
    meta: {
        app_status: '',
    },
    model: '',
    nq: '',
    nt: '',
    os: '',
    ov: '',
    ts: '',
    uid: '',
    msisdnNo: '',
};

export default EventModel;
