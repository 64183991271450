import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import PlanDetails from './PlanDetails';
import PlanButtonPanel from './PlanButtonPanel';
import PartnerImages from './PartnerImages';
import styles from './PlanTile.scss';

const PlanTile = (props) => {
    const { isLargeScreen = false, tileProps = {} } = props;
    const {
        railPosition, tilePosition, planDetails, onKeyDown,
        onKeyUp,
        onFocus, focusProps, tileId, premiumPartnerImages = [], xstreamPartnerImages = [],
        xstreamPlayOTTLabel, remainingOTTLabel,
        style, showDivider,
    } = tileProps;
    const planTileContainerRef = useRef();

    const onFocusCallBack = (e) => {
        if (typeof onFocus === 'function') {
            onFocus({
                e,
                railPosition,
                tilePosition,
                isCwRail: true,
                customElmTarget: { target: planTileContainerRef.current },
                tileDetails: {
                    ...planDetails,
                    nonQuickViewTile: true,
                },
                ...focusProps,
            });
        }
    };

    const onKeyDownCallback = (e) => {
        if (typeof onKeyDown === 'function') {
            onKeyDown({
                event: e,
                tilePosition,
            });
        }
    };

    const onKeyUpCallback = (e) => {
        onKeyUp(e);
    };

    if (isLargeScreen) {
        return (
            <div
                ref={planTileContainerRef}
                id={`onehub-tile-${railPosition}-${tilePosition}`}
                style={{
                    ...style,
                }}
                className={`plan-tile ${FocusManagementClassNames.CAN_FOCUS} ${tileId}`}
            >
                <div className="left-container">
                    <PlanDetails {...props} />
                    <PlanButtonPanel
                        {...props}
                        // {...tileProps}
                        onFocusCallback={onFocusCallBack}
                        onKeyDownCallback={onKeyDownCallback}
                        onKeyUpCallback={onKeyUpCallback}
                    />
                </div>
                <PartnerImages
                    isLargeScreen={isLargeScreen}
                    xstreamPartnerImages={xstreamPartnerImages}
                    premiumPartnerImages={premiumPartnerImages}
                    xstreamPlayOTTLabel={xstreamPlayOTTLabel}
                    remainingOTTLabel={remainingOTTLabel}
                    showDivider={showDivider}
                />
            </div>
        );
    }

    return (
        <div className="plan-tile">
            <PlanDetails {...props} />
            <PartnerImages
                isLargeScreen={isLargeScreen}
                xstreamPartnerImages={xstreamPartnerImages}
                premiumPartnerImages={premiumPartnerImages}
                xstreamPlayOTTLabel={xstreamPlayOTTLabel}
                remainingOTTLabel={remainingOTTLabel}
                showDivider={showDivider}
            />
            <PlanButtonPanel {...props} />
        </div>
    );
};

PlanTile.defaultProps = {
    removeFocusableClass: false,
};

PlanTile.propTypes = {
    isLargeScreen: PropTypes.bool.isRequired,
    tileProps: PropTypes.object.isRequired,
    removeFocusableClass: PropTypes.bool,
};

export default withStyles(styles)(PlanTile);
