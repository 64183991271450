import {
    call, put,
    getContext,
} from 'redux-saga/effects';
import {
    getAppConfig,
    getFaqFile,
    getAdsData,
    getAdsAuthToken,
    getEpoch,
} from '../../../service/end-points/ConfigApiService';
import {
    AppActions,
    appConfigSuccess,
    fetchStaticInformationTextSuccess,
    adsDataSuccess,
    fetchEpochSuccess,
} from '../actions/AppActions';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';

function* onAppConfigInit() {
    try {
        const response = yield call(getAppConfig);

        yield put(appConfigSuccess(response));
    }
    catch (e) {
        console.error(e);
    }
}

function* onAdsDataInit() {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const response = yield call(getAdsAuthToken, deviceUtil);
        const payload = {
            deviceUtil,
            authToken: response['x-auth-token'],
        };
        const data = yield call(getAdsData, payload);

        yield put(adsDataSuccess(data));
    }
    catch (e) {
        console.error(e);
    }
}

function* onfetchStaticInformationText() {
    try {
        const payload = yield call(getFaqFile);

        yield put(fetchStaticInformationTextSuccess({ payload }));
    }
    catch (e) {
        console.error(e);
    }
}

function* fetchEpochSaga() {
    try {
        const severEpoch = yield call(getEpoch);
        yield put(fetchEpochSuccess({
            payload: severEpoch,
        }));
    }
    catch(err) {
        console.log(err);
    }
}

export default [
    takeFirst(AppActions.APP_CONFIG_INIT, onAppConfigInit),
    takeFirst(AppActions.FAQ_FETCH, onfetchStaticInformationText),
    takeFirst(AppActions.ADS_DATA_INIT, onAdsDataInit),
    takeFirst(AppActions.FETCH_EPOCH, fetchEpochSaga),

];
