export const DimensionPageActions = {
    FETCH_DIMENSION_PAGE_DATA: 'FETCH_DIMENSION_PAGE_DATA',
    FETCH_DIMENSION_DATA_SUCCESS: 'FETCH_DIMENSION_DATA_SUCCESS',
    RESET_PARTICULAR_DATA: 'RESET_PARTICULAR_DATA',
};

export default DimensionPageActions;

export const getDimensionPageData = payload => ({
    type: DimensionPageActions.FETCH_DIMENSION_PAGE_DATA,
    ...payload,
});

export const getDimensionPageDataSuccess = payload => ({
    type: DimensionPageActions.FETCH_DIMENSION_DATA_SUCCESS,
    ...payload,
});

export const resetParticularData = payload => ({
    type: DimensionPageActions.RESET_PARTICULAR_DATA,
    ...payload,
});
