import {
    FORMAT_LENGTH,
    TIME_FORMAT,
    FORMAT_TYPE,
    MONTH_LONG,
    MONTH_SHORT,
    WEEKDAY_LONG,
    WEEKDAY_SHORT,
} from '@airtel-tv/constants/DateTimeConst';

class DateTime {
    constructor(time) {
        const dateTime = time ? new Date(time) : new Date();
        this.curTimeinMs = dateTime.valueOf();
        this.date = dateTime.getDate();
        this.month = dateTime.getMonth();
        this.year = dateTime.getFullYear();
        this.hoursIn24format = dateTime.getHours();
        const hourInAMPM = this.hourInAMPMFormat(this.hoursIn24format);
        this.hour = hourInAMPM.hour;
        this.amOrPm = hourInAMPM.amOrPm;
        this.minutes = dateTime.getMinutes();
        this.seconds = dateTime.getSeconds();
        this.dayInNumber = dateTime.getDay();
        // UTC FORMAT
        this.UTCHours = dateTime.getUTCHours();
        this.UTCMinutes = dateTime.getUTCMinutes();
        this.UTCSeconds = dateTime.getUTCSeconds();
    }

    dayInFull = () => {
        switch (this.dayInNumber) {
            case 0: return WEEKDAY_LONG.SUNDAY;
            case 1: return WEEKDAY_LONG.MONDAY;
            case 2: return WEEKDAY_LONG.TUESDAY;
            case 3: return WEEKDAY_LONG.WEDNESDAY;
            case 4: return WEEKDAY_LONG.THURSDAY;
            case 5: return WEEKDAY_LONG.FRIDAY;
            case 6: return WEEKDAY_LONG.SATURDAY;
            default: return '';
        }
    }

    dayInShort = () => {
        switch (this.dayInNumber) {
            case 0: return WEEKDAY_SHORT.SUNDAY;
            case 1: return WEEKDAY_SHORT.MONDAY;
            case 2: return WEEKDAY_SHORT.TUESDAY;
            case 3: return WEEKDAY_SHORT.WEDNESDAY;
            case 4: return WEEKDAY_SHORT.THURSDAY;
            case 5: return WEEKDAY_SHORT.FRIDAY;
            case 6: return WEEKDAY_SHORT.SATURDAY;
            default: return '';
        }
    };

    dateWithSuffix = (date) => {
        let suffix = '';
        switch (Number(date)) {
            case 1: suffix = 'st'; break;
            case 21: suffix = 'st'; break;
            case 31: suffix = 'st'; break;
            case 2: suffix = 'nd'; break;
            case 22: suffix = 'nd'; break;
            case 3: suffix = 'rd'; break;
            case 23: suffix = 'rd'; break;
            default: suffix = 'th'; break;
        }
        return date + suffix;
    };

    format = (format) => {
        let date = '';
        let month = '';
        let year = '';
        let dateTime = '';
        let day = '';
        let hour = '';
        let minutes = '';
        let seconds = '';
        switch (format) {
            case FORMAT_TYPE.FULL_DATE:
                date = this.getDate();
                month = this.getMonth(FORMAT_LENGTH.LONG);
                year = this.getYear(FORMAT_LENGTH.LONG);
                dateTime = `${date}-${month}-${year}`;
                break;

            case FORMAT_TYPE.PROGRAM_DATE:
                date = this.getDate();
                month = this.getMonth(FORMAT_LENGTH.LONG);
                year = this.getYear(FORMAT_LENGTH.LONG);
                dateTime = `${date} ${month} ${year}`;
                break;

            case FORMAT_TYPE.BIRTHDAY:
                date = this.getDate();
                month = this.getMonth(FORMAT_LENGTH.LONG);
                year = this.getYear(FORMAT_LENGTH.LONG);
                dateTime = `${month} ${date}, ${year}`;
                break;

            case FORMAT_TYPE.SHORT_DAY:
                day = this.getDay(FORMAT_LENGTH.SHORT);
                dateTime = `${day}`;
                break;

            case FORMAT_TYPE.FULL_DATE_TIME:
                date = this.getDate();
                month = this.getMonth(FORMAT_LENGTH.NUMBER);
                year = this.getYear(FORMAT_LENGTH.LONG);
                hour = this.getHours(TIME_FORMAT.MILITARY_HOUR_FORMAT);
                minutes = this.getMinutesInMMFormat();
                seconds = this.getSecondsInSSFormat();
                dateTime = `${year}${month}${date}${hour}${minutes}${seconds}`;
                break;

            case FORMAT_TYPE.DATE_TIME_DEFAULT_FORMAT:
                date = this.getDate();
                month = this.getMonth(FORMAT_LENGTH.NUMBER);
                year = this.getYear(FORMAT_LENGTH.LONG);
                hour = this.getHours(TIME_FORMAT.MILITARY_HOUR_FORMAT);
                minutes = this.getMinutesInMMFormat(this.minutes);
                seconds = this.getSecondsInSSFormat(this.seconds);
                dateTime = `${year}-${month}-${date} ${hour}:${minutes}:${seconds}`;
                break;

            case FORMAT_TYPE.SHOW_TIME:
                hour = this.getHours(TIME_FORMAT.SIMPLE_HOUR_FORMAT);
                if (hour < 10) {
                    hour = `0${hour}`
                }
                minutes = this.getMinutesInMMFormat(this.minutes);
                dateTime = `${hour}:${minutes} ${this.amOrPm}`;
                break;

            case FORMAT_TYPE.PLAYER_TIME:
                hour = this.UTCHours;
                minutes = this.getMinutesInMMFormat(this.UTCMinutes);
                seconds = this.getSecondsInSSFormat(this.UTCSeconds);
                dateTime = `${hour}:${minutes}:${seconds}`;
                break;

            case FORMAT_TYPE.CATCHUP_TITLE:
                date = this.getDate();
                month = this.getMonth(FORMAT_LENGTH.SHORT);
                dateTime = `${date} ${month}`;
                break;

            case FORMAT_TYPE.SHOW_DATE:
                day = this.getDay(FORMAT_LENGTH.SHORT);
                date = this.getDate();
                month = this.getMonth(FORMAT_LENGTH.SHORT);
                dateTime = `${day} ${date} ${month}`;
                break;

            case FORMAT_TYPE.SCHEDULE_DATE:
                day = this.getRelativeDay();
                date = this.getDate();
                month = this.getMonth();
                dateTime = `${day}, ${date} ${month}`;
                break;
            case FORMAT_TYPE.DATE_WITH_ORDINAL_FORMAT:
                date = this.dateWithOrdinalSuffix(this.getDate());
                month = this.getMonth(FORMAT_LENGTH.SHORT);
                year = this.getYear(FORMAT_LENGTH.LONG);
                dateTime = `${date} ${month} ${year}`;
                break;
            case FORMAT_TYPE.YEAR_MONTH_DATE_FORMAT:
                date = this.getDate();
                month = this.getMonth(FORMAT_LENGTH.NUMBER);
                year = this.getYear(FORMAT_LENGTH.LONG);
                dateTime = `${year}-${month}-${date}`;
                break;
            case FORMAT_TYPE.DATE_FULL_MONTH_YEAR:
                date = this.getDate();
                month = this.getMonth(FORMAT_LENGTH.LONG);
                year = this.getYear(FORMAT_LENGTH.LONG);
                dateTime = `${this.dateWithSuffix(date)} ${month} ${year}`;
                break;
            default:
                date = this.getDate();
                month = this.getMonth(FORMAT_LENGTH.LONG);
                year = this.getYear(FORMAT_LENGTH.LONG);
                dateTime = `${date}-${month}-${year}`;
        }
        return dateTime;
    }

    addUTCSeconds = (secs) => {
        const seconds = this.UTCSeconds + secs;
        this.UTCSeconds = Math.floor(seconds % 60);
        const minutes = Math.floor(this.UTCMinutes + (seconds / 60));
        this.UTCMinutes = minutes % 60;
        this.UTCHours += Math.floor((minutes / 60));
    }

    getDate = () => {
        if (this.date < 10) {
            return (`0${this.date.toString()}`);
        }
        return this.date.toString();
    }

    getDay = (format) => {
        switch (format) {
            case FORMAT_LENGTH.SHORT: return this.dayInShort();
            case FORMAT_LENGTH.LONG:
            default: return this.dayInFull();
        }
    }

    getRelativeDay = () => {
        const inpDate = new Date(this.curTimeinMs);
        const currDate = new Date();
        if (inpDate.toDateString() === currDate.toDateString()) {
            return 'Today';
        }
        currDate.setDate(currDate.getDate() - 1);
        if (inpDate.toDateString() === currDate.toDateString()) {
            return 'Yesterday';
        }
        return this.getDay(FORMAT_LENGTH.SHORT);
    }

    getMonth = (format) => {
        switch (format) {
            case FORMAT_LENGTH.NUMBER: return this.monthInNumber();
            case FORMAT_LENGTH.SHORT: return this.monthInShort();
            case FORMAT_LENGTH.LONG:
            default: return this.monthInFull();
        }
    }

    getYear = (format) => {
        switch (format) {
            case FORMAT_LENGTH.SHORT: return this.yearInShort();
            case FORMAT_LENGTH.LONG:
            default: return this.yearInFull();
        }
    }

    getHours = (format) => {
        switch (format) {
            case TIME_FORMAT.MILITARY_HOUR_FORMAT: return this.hoursIn24format;
            case TIME_FORMAT.SIMPLE_HOUR_FORMAT:
            default: return this.hour;
        }
    }

    getMinutes = () => this.minutes;

    getMinutesInMMFormat = (minutes) => {
        if (minutes < 10) {
            return (`0${minutes.toString()}`);
        }
        return minutes.toString();
    }

    getSeconds = () => this.seconds;

    getSecondsInSSFormat = (seconds) => {
        if (seconds < 10) {
            return (`0${seconds.toString()}`);
        }
        return seconds.toString();
    }

    hourInAMPMFormat = (hoursIn24format) => {
        let hour;
        if (hoursIn24format > 12) {
            hour = hoursIn24format - 12;
        }
        else if (hoursIn24format === 0) {
            hour = 12;
        }
        else {
            hour = hoursIn24format;
        }
        const amOrPm = hoursIn24format >= 12 ? 'pm' : 'am';
        return {
            hour,
            amOrPm,
        };
    }

    monthInFull = () => {
        let monthName = '';
        switch (this.month) {
            case 0: monthName = MONTH_LONG.JANUARY;
                break;
            case 1: monthName = MONTH_LONG.February;
                break;
            case 2: monthName = MONTH_LONG.MARCH;
                break;
            case 3: monthName = MONTH_LONG.APRIL;
                break;
            case 4: monthName = MONTH_LONG.MAY;
                break;
            case 5: monthName = MONTH_LONG.JUNE;
                break;
            case 6: monthName = MONTH_LONG.JULY;
                break;
            case 7: monthName = MONTH_LONG.AUGUST;
                break;
            case 8: monthName = MONTH_LONG.SEPTEMBER;
                break;
            case 9: monthName = MONTH_LONG.OCTOBER;
                break;
            case 10: monthName = MONTH_LONG.NOVEMBER;
                break;
            case 11: monthName = MONTH_LONG.DECEMBER;
                break;
            default: break;
        }
        return monthName;
    }

    monthInNumber = () => {
        if (this.month < 9) {
            return (`0${(this.month + 1).toString()}`);
        }
        return (this.month + 1).toString();
    }

    monthInShort = () => {
        let monthName = '';
        switch (this.month) {
            case 0: monthName = MONTH_SHORT.JANUARY;
                break;
            case 1: monthName = MONTH_SHORT.February;
                break;
            case 2: monthName = MONTH_SHORT.MARCH;
                break;
            case 3: monthName = MONTH_SHORT.APRIL;
                break;
            case 4: monthName = MONTH_SHORT.MAY;
                break;
            case 5: monthName = MONTH_SHORT.JUNE;
                break;
            case 6: monthName = MONTH_SHORT.JULY;
                break;
            case 7: monthName = MONTH_SHORT.AUGUST;
                break;
            case 8: monthName = MONTH_SHORT.SEPTEMBER;
                break;
            case 9: monthName = MONTH_SHORT.OCTOBER;
                break;
            case 10: monthName = MONTH_SHORT.NOVEMBER;
                break;
            case 11: monthName = MONTH_SHORT.DECEMBER;
                break;
            default: break;
        }
        return monthName;
    }

    subtract = (seconds) => {
        const tempTimeinMs = new Date().getTime();
        return new Date(tempTimeinMs - seconds * 1000);
    }

    yearInFull = () => this.year.toString();

    yearInShort = () => this.yearInFull().slice(-2);

    dateWithOrdinalSuffix = (date) => {
        const j = date % 10;
        const k = date % 100;
        if (j === 1 && k !== 11) {
            return `${date}st`;
        }
        if (j === 2 && k !== 12) {
            return `${date}nd`;
        }
        if (j === 3 && k !== 13) {
            return `${date}rd`;
        }
        return `${date}th`;
    };

    timerInMinSS = (seconds) => {
        let min = Math.floor(seconds / 60);
        if (min < 10) {
            min = `0${min}`;
        }
        const secondLeft = seconds % 60;
        if (secondLeft < 10) {
            return `${min}:0${secondLeft} mins`;
        }
        return `${min}:${secondLeft} mins`;
    };
}




export {DateTime};
