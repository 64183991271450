/* eslint-disable camelcase */
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { AppEnv } from '@airtel-tv/utils/GetEnv';
import { removeFalsy } from '@airtel-tv/utils/GlobalUtil';

const env = AppEnv.getEnv();

// eslint-disable-next-line import/prefer-default-export
export const firePixel = ({ pixelMeta = {}}) => {
    const { base, paths } = env?.endpoints?.distroPixel;
    const url = `${base}${paths.daiPixel}`;
    const {
        random, event_name, playback_id, content_provider_id, show_id, episode_id, dpName, encoded_video_url, advertisingId,
    } = pixelMeta;

    let queryParams = {
        dpname: dpName,
        r: random,
        e: event_name,
        i: playback_id,
        v: playback_id,
        f: encoded_video_url,
        m: dpName,
        p: content_provider_id,
        show: show_id,
        ep: episode_id,
        u: advertisingId,
    };
    queryParams = removeFalsy(queryParams);
    queryParams = {
        ...queryParams,
        ...(queryParams.p === 'blank' && { p: '' }),
        ...(queryParams.show === 'blank' && { show: '' }),
        ...(queryParams.ep === 'blank' && { ep: '' }),
    };
    return RequestUtil.GET({
        url,
        params: queryParams,
        headers: { 'avoid-custom-headers': true },
    });
};
