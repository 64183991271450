
import lodashGet from 'lodash/get';

export const getParentContentId = (contentDetails) => {
    const audioMap = lodashGet(contentDetails, 'langToContentMap', null);
    if (audioMap) {
        const { parentLang, languageMap } = audioMap;
        const parentContentId = lodashGet(languageMap, `${parentLang}`, '');
        return parentContentId;
    }
    return contentDetails.id;
};