export const OffersCheckActions = {
    OFFERS_FETCH_SUCCESS: 'OFFERS_FETCH_SUCCESS',
    OFFERS_FETCH_INIT: 'OFFERS_FETCH_INIT',
    OFFERS_FETCH_FAILURE: 'OFFERS_FETCH_FAILURE',
    OFFERS_FETCH_CLEAR: 'OFFERS_FETCH_CLEAR',
};

export const offersFetchInit = () => (
    {
        type: OffersCheckActions.OFFERS_FETCH_INIT,
    });

export const offersFetchSuccess = payload => ({
    type: OffersCheckActions.OFFERS_FETCH_SUCCESS,
    ...payload,
});

export const offersFetchFailure = payload => ({
    type: OffersCheckActions.OFFERS_FETCH_FAILURE,
    ...payload,
});

export const offersFetchClear = payload => ({
    type: OffersCheckActions.OFFERS_FETCH_CLEAR,
    ...payload,
});
