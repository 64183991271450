// source: https://github.com/sindresorhus/query-string#readme
import queryString from 'query-string';
import lodashGet from 'lodash/get';
import { INVALID_URL, LOCAL_STORE_KEYS, QUERY_PARAMS } from '@airtel-tv/constants/GlobalConst';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { getWindowLocation, checkWindowExist } from './WindowUtil';
import browserStore, { sessionStore } from './BrowserStoreUtil';
import { SubscriptionUtil } from './SubscriptionUtil';
import { AppEnv } from './GetEnv';

export class LocationUtil {
    static parseUrl(url) {
        const windowLocation = getWindowLocation();
        let uri = url;
        let urlSchema = {};
        if (!uri && windowLocation) {
            uri = windowLocation.href;
            urlSchema = {
                ...windowLocation,
                ...queryString.parseUrl(windowLocation.href),
            };
        }
        else {
            let uriData = {};
            const querySchema = queryString.parseUrl(uri);

            try {
                if (URL) {
                    const parsedURL = new URL(uri);
                    const {
                        href, origin, protocol, username, password, host, hostname, port, pathname, search, searchParams, hash,
                    } = parsedURL;
                    uriData = {
                        href,
                        origin,
                        protocol,
                        username,
                        password,
                        host,
                        hostname,
                        port,
                        pathname,
                        search,
                        searchParams,
                        hash,
                    };
                }
            }
            catch (error) {
                console.error(error);
                return INVALID_URL;
            }
            urlSchema = {
                ...querySchema,
                ...uriData,
            };
        }

        return urlSchema;
    }

    static paramArrayToUrlPath = (params) => {
        if (Array.isArray(params)) {
            return params.reduce((url, param) => `${url}/${param}`, '');
        }
        return '/';
    };

    static objectToQueryParams(data) {
        if (data) {
            return queryString.stringify(data);
        }
        return '';
    }

    static getPathParams = (props, param) => {
        if (!props?.match || Object.keys(props?.match).length === 0) {
            return;
        }
        const { params: pathParams } = props.match;
        if (!param) {
            return pathParams;
        }
        return pathParams[param];
    };

    static getQueryParams(props, param) {
        if (!props?.location || Object.keys(props?.location).length === 0) {
            return;
        }
        const parsed = queryString.parse(props.location.search);
        if (!param) {
            return parsed;
        }
        return parsed[param];
    }

    static getQueryParamsForMobility(props, param) {
        const parsed = {};
        const params = props.location.search.substring(1).split('&');
        for (const param of params) {
            const _param = param.split(/=(.*)/s);
            if (_param.length > 1) {
                parsed[_param[0]] = _param[1];
            }
        }
        if (!param) {
            return parsed;
        }
        const parsedParam = parsed;
        for (const property in parsedParam) {
            if (typeof (parsedParam[property]) === 'object') {
                parsedParam[property] = parsedParam[property][parsedParam[property].length - 1];
            }
        }
        return parsedParam[param];
    }

    static getUrlFromProps(props) {
        return lodashGet(props, 'location.pathname') + lodashGet(props, 'location.search');
    }

    static getUrlExtension(src) {
        if (!src) {
            return '';
        }

        let url = src;
        if (url[0] === '/') {
            // added temp domain to be able to parse the url
            url = `https://foobar.com${url}`;
        }

        const urlSchema = LocationUtil.parseUrl(url);

        const path = urlSchema.pathname;
        if (path) {
            const splitted = path.split('.');
            return splitted[splitted.length - 1] || '';
        }

        return '';
    }

    static reload() {
        getWindowLocation().reload();
    }

    static baseUrl() {
        const checkWindow = checkWindowExist();
        let path;
        if (checkWindow) {
            const windowLocation = getWindowLocation();
            path = `${windowLocation.protocol}//${windowLocation.host}`;
        }
        else {
            path = global.baseHostUrl;
        }
        return path;
    }

    static handleMobilityRedirection() {
        const appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
        if (appId === 'MOBILITY') {
            return '?viewType=webview';
        }

        return '';
    }

    static getSubscriptionStatus(status) {
        const SUBSCRIPTION_STATUS = {
            success: 'SUCCESS',
            failure: 'FAILURE',
            cancel: 'CANCEL',
        };
        return SUBSCRIPTION_STATUS[status];
    }

    static sendSubscriptionEventToMobility(event) {
        try {
            window.AirtelTVClient.onSubscriptionComplete(JSON.stringify(event));
        }
        catch (e) {
            try {
                window.webkit.messageHandlers.onSubscriptionComplete.postMessage(JSON.stringify(event));
            }
            catch (e) {
                window.webkit.messageHandlers.onSubscriptionComplete.postMessage({
                    onSubscriptionComplete: SubscriptionUtil.getSubscriptionStatus(event),
                });
            }
        }
    }

    static sendXMPClaimEventToMobility(event) {
        try {
            window.AirtelTVClient.onXmpComplete(JSON.stringify(event));
        }
        catch (e) {
            try {
                window.webkit.messageHandlers.onXmpComplete.postMessage(JSON.stringify(event));
            }
            catch (e) {
                window.webkit.messageHandlers.onXmpComplete.postMessage({
                    onSubscriptionComplete: SubscriptionUtil.getSubscriptionStatus(event),
                });
            }
        }
    }

    static handleAppstate(status) {
        try {
            window.AirtelTVClient.onSubscriptionComplete(SubscriptionUtil.getSubscriptionStatus(status));
        }
        catch (e) {
            try {
                window.webkit.messageHandlers.onSubscriptionComplete.postMessage(JSON.stringify({ status }));
            }
            catch (e) {
                if (window.webkit && window.webkit.messageHandlers) {
                    window.webkit.messageHandlers.onSubscriptionComplete.postMessage({
                        onSubscriptionComplete: SubscriptionUtil.getSubscriptionStatus(status),
                    });
                }
            }
        }
    }

    static handleCloseAppstate() {
        try {
            window.AirtelTVClient.onExitWebView();
        }
        catch (e) {
            try {
                window.webkit.messageHandlers.onExitWebview.postMessage({ onExitWebview: 'SUCCESS' });
            }
            catch (e) {
                if (window.webkit && window.webkit.messageHandlers) {
                    window.webkit.messageHandlers.onExitWebview.postMessage({ onExitWebview: 'SUCCESS' });
                }
            }
        }
    }

    static handleOpenIntent(deeplink) {
        try {
            const ostype = browserStore.get(LOCAL_STORE_KEYS.OS);
            const MOBILITYOS = browserStore.get(LOCAL_STORE_KEYS.MOBILITYOS);
            if (deeplink && (ostype === 'ANDROID' || MOBILITYOS === 'ANDROID') && window && window.AirtelTVClient && window.AirtelTVClient.openIntent) {
                window.AirtelTVClient.openIntent(JSON.stringify({
                    eventName: 'OPEN_INTENT_HOICHOI_CAMPAIGN',
                    packageName: AppEnv.getEnv().AndroidNativeAppPackageName,
                    requestCode: '9003',
                    action: 'android.intent.action.VIEW',
                    deeplink: deeplink || 'wynkpremiere://Landing/page/PartnerChannelHoichoi/Hoichoi/q',
                }));
            }
            else {
                this.handleCloseAppstate();
            }
        }
        catch (e) {
            this.handleCloseAppstate();
        }
    }

    static redirectToAppViaDeeplink({
        deeplink,
        eventName = 'OPEN_APP_LINK',
        packageName = '',
        requestCode = '9003',
        action = 'android.intent.action.VIEW',
        disableSplash = false,
        useDefaultAppPackageName = true,
    }) {
        try {
            if (!deeplink) {
                return;
            }
            const ostype = browserStore.get(LOCAL_STORE_KEYS.OS);
            const MOBILITYOS = browserStore.get(LOCAL_STORE_KEYS.MOBILITYOS);

            if ((ostype === 'ANDROID' || MOBILITYOS === 'ANDROID') && checkWindowExist() && window?.AirtelTVClient?.openIntent) {
                window.AirtelTVClient.openIntent(JSON.stringify({
                    eventName,
                    packageName: useDefaultAppPackageName ? AppEnv.getEnv().AndroidNativeAppPackageName : packageName,
                    requestCode,
                    action,
                    deeplink: deeplink || '', //Put app home page here
                    disableSplash, // diable splash screen of native, internally uses openIntentv2
                }));
            }
            else if ((ostype === 'IOS' || MOBILITYOS === 'IOS') && window?.webkit?.messageHandlers) {
                window.webkit.messageHandlers.onExternalDeeplink.postMessage({
                    onExternalDeeplink: {
                        "deeplink": deeplink,
                    },
                });
            }
        }
        catch(err) {
            alert(err);
            console.log(err);
        }
    };

    static appendQueryParamsInUrl(url, params) {
        let finalQueryParams = { ...params };

        const playbackUrlSchema = LocationUtil.parseUrl(url);

        if (playbackUrlSchema.query && Object.keys(playbackUrlSchema.query).length > 0) {
            finalQueryParams = {
                ...params,
                ...playbackUrlSchema.query,
            };
        }

        const queryParams = LocationUtil.objectToQueryParams(finalQueryParams);
        // append query params in playback url
        return `${playbackUrlSchema.url}?${queryParams}`;
    }

    static saveDataFromQuery(location) {
        const values = LocationUtil.parse(location.search);
        for (const param of Object.keys(QUERY_PARAMS)) {
            const key = QUERY_PARAMS[param];
            const value = values[key];
            if (value && value !== '') {
                browserStore.set(key, value);
            }
        }
    }


    static parse(str) {
        const ret = Object.create(null);

        if (typeof str !== 'string') {
            return ret;
        }
        str = decodeURIComponent(str);
        str = str.trim().replace(/^[?#&]/, '');

        if (!str) {
            return ret;
        }

        str.split('&').forEach((param) => {
            const parts = param.split('=');
            const key = parts.shift();
            const val = parts.length > 0 ? parts.join('=') : undefined;
            ret[key] = val;
        });

        return ret;
    }

    static getKeyFromURL(str, key) {
        return this.parse(str)[key];
    }

    static parseNavigatorForWindows(userAgent, platform) {
        if (platform !== 'Win32') {
            return platform;
        }

        let updatedPlatform = platform;
        if (userAgent.indexOf('WOW64') !== -1
            || userAgent.indexOf('Win64') !== -1) {
            updatedPlatform = 'Win64';
        }
        return updatedPlatform;
    }

    static updateViewTypeFromQuery(location) {
        const values = LocationUtil.parse(location.search);
        if (values.viewType) {
            browserStore.set(LOCAL_STORE_KEYS.VIEW_TYPE, values.viewType);
        }
        else {
            browserStore.remove(LOCAL_STORE_KEYS.VIEW_TYPE);
        }
    }

    static resetXstreamSessionOnThanks(timeout = 2000) {
        try {
            if (checkWindowExist()) {
                const ssoToken = browserStore.get(LOCAL_STORE_KEYS.SSO_TOKEN);
                const parameter = JSON.stringify({
                    ssotoken: ssoToken,
                    value: 'false',
                    deeplink: 'myairtel://home',
                });
                setTimeout(() => {
                    window?.webkit?.messageHandlers?.resetxstreamsession?.postMessage(parameter);
                    window?.MyAirtelAppReact?.resetxstreamsession(parameter);
                    window?.MyAirtelAppReact?.close();
                    window.webkit?.messageHandlers?.triggerAppEvent?.postMessage({
                        eventName: 'close',
                        callback: '',
                    });
                }, timeout);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    static setSessionIdFromThanks({ isIOS }) {
        try {
            let sessionId = '';
            if (checkWindowExist()) {
                if (isIOS) {
                    window?.webkit?.messageHandlers?.triggerAppEvent?.postMessage({
                        eventName:
                        'getSessionID',
                        callback: 'setThanksSessionId',
                    });
                }
                else {
                    sessionId = window?.MyAirtelAppReact?.getSessionID();
                }
                if (sessionId) {
                    sessionStore.set(LOCAL_STORE_KEYS.THANKS_APP_SESSION_ID, sessionId);
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    static searchParamsToObject(urlSearchParams) {//needs an instance of urlSearchParams
        const entries = urlSearchParams.entries();
        const result = {};
        for (const [
            key,
            value,
        ] of entries) { // each 'entry' is a [key, value] tupple
            result[key] = value;
        }
        return result;
    }

    static showNativeBackButtonNavigation() {
        if(window && window.AirtelTVClient && window.AirtelTVClient.showBackButton) {
            try {
                window.AirtelTVClient.showBackButton();
            } catch(e) {
                console.log({e});
            }
        } else if(window?.webkit?.messageHandlers) {
            try {
                window.webkit.messageHandlers.showBackButton.postMessage({
                    showBackButton: {}
                });
            } catch(e) {
                console.log({e})
            }
        }
    }

    static hideNativeBackButtonNavigation() {
        if(window && window.AirtelTVClient && window.AirtelTVClient.hideBackButton) {
            try {
                window.AirtelTVClient.hideBackButton();
            } catch(e) {
                console.log({e});
            }
        } else if(window?.webkit?.messageHandlers) {
            try {
                window.webkit.messageHandlers.hideBackButton.postMessage({
                    hideBackButton: {},
                });
            } catch(e) {
                console.log({e})
            }
        }
    }
}

export default LocationUtil;
