import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { Navigate, matchRoutes } from 'react-router';
import { LocationUtil } from '@airtel-tv/utils';
import LoginComponent from '../modules/auth/components/LoginComponent';
import PaymentsLandingPage from '../modules/PaymentsLandingPage/PaymentsLandingPage';
import getAllRouters from './routes';
import QrCodeConfirmation from '../modules/auth/components/QrCodeConfirmation';

function LoginRouteContainer(props) {
    const {
        component: TargetComponent, isAuthenticated, match, ...rest
    } = props;
    const [
        matchedRoute,
        setMatchedRoute,
    ] = useState(() => {
        const url = LocationUtil.getUrlFromProps(props);
        const routePresent = matchRoutes(getAllRouters(), url);
        let currentRoute = routePresent[0];
        if (routePresent?.length > 1) {
            currentRoute = routePresent[1];
        }
        return {
            params: currentRoute?.params,
            pathname: currentRoute?.pathname,
            pathnameBase: currentRoute?.pathnameBase,
            path: currentRoute?.route?.path || '',
        };
    });

    if (isAuthenticated) {
        const { path = '' } = matchedRoute;
        switch (path) {
            case ROUTE_PATHS.DTH_SUBSCRIBE: return (
                <PaymentsLandingPage
                    isAuthenticated={isAuthenticated}
                    {...rest}
                    {...matchedRoute}
                />
            );
            case ROUTE_PATHS.QR_CODE_LOGIN: return (
                <QrCodeConfirmation
                    isAuthenticated={isAuthenticated}
                    {...rest}
                    {...matchedRoute}
                />
            );
            default: break;
        }
        return <Navigate to={ROUTE_PATHS.ROOT} />;
    }
    return <LoginComponent {...rest} />;
}

LoginRouteContainer.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    path: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { authConfig } = state;
    // const url = LocationUtil.getUrlFromProps(props);
    // const matchedRoute = matchRoutes(getAllRouters(), url);
    // let currentRoute = matchedRoute[0];
    // if (matchedRoute?.length > 1) {
    //     currentRoute = matchedRoute[1];
    // }
    const props = {
        isAuthenticated: authConfig.isAuthenticated,
        // params: currentRoute?.params,
        // pathname: currentRoute?.pathname,
        // pathnameBase: currentRoute?.pathnameBase,
        // path: currentRoute?.route?.path || '',
    };
    return props;
};

export default withRouter(connect(mapStateToProps)(LoginRouteContainer));
