import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { SearchActions } from '../actions/SearchActions';
import { SEARCH_CONFIG } from '../../../constants/AppConst';
import SearchUtil from '../../../utilities/SearchUtil';

const initialState = JSON.parse(`{
    "error": null,
    "recentSearches": [],
    "totalResults": 0,
    "totalCountMisMatch": false,
    "categories": null,
    "suggestions": [],
    "searchResultHistory": {},
    "searchCategoryResult": {}
}`);

const SearchReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case SearchActions.CLEAR_SEARCH:
            changes = {
                categories: null,
                totalResults: 0,
                allContentSearchResult: null,
                suggestions: [],
            };
            break;

        case SearchActions.FETCH_SEARCH_SUCCESS: {
            let { searchResultHistory, searchCategoryResult } = { ...state };

            // Search Page
            if (!action.superType) {
                const searchResult = {
                    [action.query]: [
                        ...action.payload.categories,
                    ],
                };

                if (lodashIsEmpty(searchResultHistory) || !searchResultHistory[action.query]) {
                    searchResultHistory = {
                        ...searchResultHistory,
                        ...searchResult,
                    };
                }
            }

            // Search List Page
            else {
                const { query, superType } = action;
                const searchCategoryKey = SearchUtil.getSearchKeyForCategory(query, superType);

                if (!searchCategoryResult[searchCategoryKey]) {
                    const searchCategoryRes = {
                        [searchCategoryKey]: [
                            ...action.payload.categories,
                        ],
                    };
                    searchCategoryResult = {
                        ...searchCategoryResult,
                        ...searchCategoryRes,
                    };
                }
            }

            changes = {
                ...state,
                ...action.payload,
                error: null,
                totalCountMisMatch: false,
                searchResultHistory,
                searchCategoryResult,
            };
        }
            break;

        case SearchActions.PAGINATED_FETCH_SEARCH: {
            const appendedState = { ...state };
            const { query, superType } = action;
            const searchCategoryKey = SearchUtil.getSearchKeyForCategory(query, superType);
            const searchResultsForCategory = appendedState.searchCategoryResult[searchCategoryKey];

            if (searchResultsForCategory) {
                const appendedSearchResultForCategory = searchResultsForCategory.map((oldCategoryResult) => {
                    const appendedCategoryResult = { ...oldCategoryResult };
                    // find category in current SearchCategory Result and append it
                    action.payload.categories.forEach((newCategory) => {
                        if (newCategory.type === oldCategoryResult.type) {
                            appendedCategoryResult.contentResponseList = [
                                ...oldCategoryResult.contentResponseList,
                                ...newCategory.contentResponseList,
                            ];
                        }
                    });
                    return appendedCategoryResult;
                });

                appendedState.searchCategoryResult = {
                    ...appendedState.searchCategoryResult,
                    [searchCategoryKey]: [
                        ...appendedSearchResultForCategory,
                    ],
                };
                appendedState.totalResults = appendedSearchResultForCategory[0].count;
            }

            const newTotalResults = lodashGet(action, 'payload.totalResults', 0);

            changes = {
                ...appendedState,
                totalCountMisMatch: appendedState.totalResults !== newTotalResults, // stop requesting more results as it may go into infinite loop
                error: null,
            };
        }
            break;

        case SearchActions.FETCH_SEARCH_ERROR:
            changes = {
                error: action.payload,
            };
            break;


        case SearchActions.ADD_RECENT_SEARCHES: {
            // add new and remove duplicates also limit the numbers
            const newSearches = [
                action.query,
                ...SearchUtil.getRecentSearches(),
            ]
                .filter(((q, i, arr) => (q && arr.indexOf(q) === i))) // remove duplicate
                .slice(0, (action.maxCount || SEARCH_CONFIG.MAX_RECENT_SEARCH_COUNT));

            changes = {
                recentSearches: newSearches,
            };

            // if recentSearches changed then put it in browser storage
            if (changes.recentSearches) {
                SearchUtil.saveResentSearchQueries(changes.recentSearches);
            }
        }
            break;


        case SearchActions.CLEAR_RECENT_SEARCHES:
            changes = {
                recentSearches: [],
            };
            SearchUtil.clearSearchQueries();
            break;


        case SearchActions.REFRESH_RECENT_SEARCHES:
            changes = {
                recentSearches: [
                    ...SearchUtil.getRecentSearches(),
                ],
            };
            break;


        case SearchActions.FETCH_SEARCH_SUGGESTIONS_SUCCESS:
            changes = {
                ...state,
                suggestions: lodashGet(action.payload, 'autoSuggestTitles', []),
            };
            break;

        case SearchActions.CLEAR_SEARCH_SUGGESTIONS:
            changes = {
                ...state,
                suggestions: [],
            };
            break;
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default SearchReducer;
