import { GenreListActions } from '../actions/GenreListActions';

const initialState = JSON.parse('{}');

const GenreListReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case GenreListActions.FETCH_GENRE_LIST_SUCCESS: {
            changes = { ...action.payload };
            break;
        }
        default:
            return state;
    }
    return {
        ...state,
        ...changes,
    };
};

export default GenreListReducer;
