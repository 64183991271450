import ModalPopupAction from './ModalPopupActions';

const ModalPopupReducer = (state = { }, action) => {
    let changes = {};

    switch (action.type) {
        case ModalPopupAction.SHOW_MODAL_POPUP:
            changes = {
                showModal: action.showModalValue,
                componentName: action.componentNameValue,
                overrideCrossButton: action.overrideCrossButtonValue,
                showBackButton: action.showBackButton,
                componentProps: action.payload,
            };
            break;

        case ModalPopupAction.HIDE_MODAL_POPUP:
            changes = {
                showModal: action.showModalValue,
                componentName: action.componentNameValue,
                overrideCrossButton: action.overrideCrossButtonValue,
                showBackButton: action.showBackButton,
                componentProps: action.payload,
            };
            break;

        default:
            return state;
    }
    return {
        ...state,
        ...changes,
    };
};

export default ModalPopupReducer;
