export const CONTENT_IMAGE_TYPES = {
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE_169: 'LANDSCAPE_169',
    LANDSCAPE_43: 'LANDSCAPE_43',
    LOGO: 'LOGO',
    LOGOS_TNPT: 'LOGOS_TNPT',
    FEATURE_BANNER: 'FEATURE_BANNER',
    FEATURE_BANNER_HD: 'FEATURE_BANNER_HD',
    RECTANGULAR: 'RECTANGULAR',
    CIRCULAR: 'CIRCULAR',
    SQUARE: 'SQUARE',
    PORTRAIT_HD: 'PORTRAIT_HD',
    LANDSCAPE_169_HD: 'LANDSCAPE_169_HD',
    LANDSCAPE_43_HD: 'LANDSCAPE_43_HD',
    LOGO_HD: 'LOGO_HD',
    LOGOS_TNPT_HD: 'LOGOS_TNPT_HD',
    RECTANGULAR_HD: 'RECTANGULAR_HD',
    CIRCULAR_HD: 'CIRCULAR_HD',
    SQUARE_HD: 'SQUARE_HD',
    RECTANGLE: 'RECTANGLE',
};

export const IMG_TYPE = {
    LOTTIE: 'lottie',
}


export const IMAGE_PATHS = {
    CHEV_LEFT: '/static/left-chev.svg',
    XSTREAMPREMIUM: '/static/xstream-premium.svg',
};

export const IMAGE_SETTINGS = {
    FLOATING_LARGE: {
        1000: 700,
        360: 360,
    },
    CP_IMAGES: {
        1000: 100,
        500: 100,
        320: 100,
    },
    PYW_TILES: {
        1000: 1000,
        500: 1000,
    },
    LOGO_URL: {
        1000: 100,
        500: 100,
        320: 100,
    },
    SVG_ICONS: {
        100: 15,
    },
};

export default {
    CONTENT_IMAGE_TYPES,
    IMAGE_PATHS,
    IMAGE_SETTINGS,
};
