import { BlogActions } from './BlogActions';

const initialState = JSON.parse('{}');

const BlogReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case BlogActions.FETCH_BLOGS_SUCCESS:
            changes = {
                posts: action.payload,
            };
            break;
        case BlogActions.FETCH_BLOG_BY_ID_SUCCESS:
            const { postId = '', posts = {} } = action.payload;
            changes = {
                cdpOnPageData: {
                    [postId]: posts,
                },
            };
            break;
        case BlogActions.FETCH_BLOG_CATEGORIES_SUCCESS:
            changes = {
                categories: action.payload,
            };
            break;
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default BlogReducer;
