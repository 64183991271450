import React, { Component } from 'react';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import CustomIconComponent from '@airtel-tv/ui-lib/molecules/CustomIcon/CustomIconComponent';
import LanguageProvider from '../../../providers/LanguageProvider';
import LinkComponent from '../../shared/components/link/LinkComponent';
import { headerPopRoute, headerAddNewRoute } from '../../notify/NotifyActions';
import { logoutInit } from '../../auth/AuthAction';
import { redirectToInNewTab, getWindowLocation } from '../../../utilities/WindowUtil';
import { IMAGE_PATHS } from '../../../constants/AppConst';
import { HTML_IDS } from '../../../constants/HTMLConst';
import { manageSubscriptionInit } from '../../subscription/SubscriptionAction';

const windowLocation = getWindowLocation();
const LANGUAGE = LanguageProvider();

const PROFILE_ACTION_TYPES = {
    LOGOUT: 'LOGOUT',
    DOWNLOAD_APP: 'DOWNLOAD_APP',
    REDIRECT: 'REDIRECT',
    FEEDBACK: 'FEEDBACK',
    PLANS_AND_OFFER: 'PLANS_AND_OFFER',
    OFFERS: 'OFFERS',
};

const PROFILE_ITEM_VISIBILITIES = {
    BOTH: 'BOTH',
    LOGGED_IN: 'LOGGED_IN',
    LOGGED_OUT: 'LOGGED_OUT',
    PREFERRED_PARTNER_AVAILABLE: 'PREFERRED_PARTNER_AVAILABLE',
};

const LOGOUT_MENU = [
    {
        id: HTML_IDS.NAVBAR_LOGOUT,
        cssId: 2,
        description: LANGUAGE.LOGOUT,
        actionType: PROFILE_ACTION_TYPES.LOGOUT,
        visibility: PROFILE_ITEM_VISIBILITIES.LOGGED_IN,
        redirectUrl: '',
    },
];

class ProfileNavContainer extends Component {
    constructor(props) {
        super(props);
        this.profileMenuRef = React.createRef();
        this.isMobile = props.deviceUtil.isMobile();
    }

    showMenu = ({ e, isClick }) => {
        const { overlay, isAuthenticated, loginHandler } = this.props;
        if (isAuthenticated) {
            if (this.profileMenuRef.current && (!overlay || this.isMobile)) {
                this.profileMenuRef.current.classList.add('block-display');
            }
        }
        else if (isClick) {
            loginHandler();
        }
        e.stopPropagation();
        e.preventDefault();
    };

    hideMenu = (e) => {
        if (this.profileMenuRef.current) {
            this.profileMenuRef.current.classList.remove('block-display');
        }
        if (e) {
            e.stopPropagation();
        }
    };

    itemActionHandler = (item, e) => {
        const { logoutInitDispatch, managePlanRequestDispatch, refLinks } = this.props;
        switch (item.actionType) {
            case PROFILE_ACTION_TYPES.LOGOUT:
                if (e) {
                    e.preventDefault();
                }
                logoutInitDispatch();
                break;
            case PROFILE_ACTION_TYPES.PLANS_AND_OFFER:
                if (e) {
                    e.preventDefault();
                }
                managePlanRequestDispatch({
                    successUrl: windowLocation.origin,
                    failureUrl: windowLocation.origin,
                });
                break;
            case PROFILE_ACTION_TYPES.FEEDBACK:
                if (e) {
                    e.preventDefault();
                }
                this.hideMenu(e);
                redirectToInNewTab(refLinks.FEEDBACK_FORM);
                break;
            case PROFILE_ACTION_TYPES.REDIRECT:
                this.hideMenu(e);
                break;
            default:
                break;
        }
    };

    render() {
        const {
            isAuthenticated, preferredPartnerPlans, profileMenu, experimentDetails,
        } = this.props;
        const isPreferredPartnerAvailable = preferredPartnerPlans.length > 0;
        const experimentDetailsArray = experimentDetails.split('|');
        const listElem = (profileMenu.length ? profileMenu : LOGOUT_MENU)
            .filter((item) => {
                if (!item.experimentId) {
                    return true;
                }
                if (experimentDetailsArray.includes(item.experimentId)) {
                    return true;
                }
                return false;
            })
            .filter((item) => {
                if (item.visibility === PROFILE_ITEM_VISIBILITIES.BOTH) {
                    return true;
                }
                if (isAuthenticated && item.visibility === PROFILE_ITEM_VISIBILITIES.LOGGED_IN) {
                    return true;
                }
                if (!isAuthenticated && item.visibility === PROFILE_ITEM_VISIBILITIES.LOGGED_OUT) {
                    return true;
                }
                if (item.visibility === PROFILE_ITEM_VISIBILITIES.PREFERRED_PARTNER_AVAILABLE) {
                    return true;
                }
                return false;
            })
            .map(item => (
                <li
                    key={item.cssId}
                    className="profile-card-items sub-menu-items-default common-share-dropdown-button"
                >
                    <AnalyticsLinkComponent
                        className="profile-item"
                        to={item.redirectUrl}
                        onClick={e => this.itemActionHandler(item, e)}
                        title={item.description}
                        id={item.id}
                        meta={{
                            asset_name: item.id,
                        }}
                    >
                        <p>{item.description}</p>
                        { item.CheckPPAvailable && isPreferredPartnerAvailable
                            ? <div className="offers-number">{ preferredPartnerPlans.length }</div> : null}
                    </AnalyticsLinkComponent>
                </li>
            ));

        return (
            <LinkComponent
                to="#"
                id="nav-items"
                className="nav-profile-item nav-header-item"
                onMouseEnter={e => this.showMenu({
                    e,
                    isClick: false,
                })}
                onMouseLeave={this.hideMenu}
                onClick={e => this.showMenu({
                    e,
                    isClick: true,
                })}
            >
                {/* <ThumborImage
                    src={IMAGE_PATHS.PROFILE_ICON_NEW}
                /> */}
                {/* <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="19.9995" r="20" fill="#3A404A" fill-opacity="0.7"/>
                <path d="M12.3501 27.4974V27.4717C12.3605 24.386 14.2365 21.6194 17.0416 20.4255C17.8922 21.014 18.8889 21.3367 20.0001 21.3367C21.0846 21.3367 22.1078 20.9842 22.9566 20.4247C25.7708 21.6214 27.6507 24.4007 27.6507 27.4974C27.6507 27.5536 27.6326 27.5887 27.612 27.6094C27.5914 27.63 27.5565 27.648 27.5006 27.648H12.5002C12.4128 27.648 12.3501 27.575 12.3501 27.4974ZM27.5004 29.15C28.4236 29.15 29.1505 28.4215 29.1505 27.4974C29.1505 24.0179 27.1806 20.8621 24.1347 19.3314C24.8342 18.429 25.2503 17.3108 25.2503 16.1081C25.2503 13.2007 22.9034 10.8495 20.0002 10.8495C17.0971 10.8495 14.7502 13.2007 14.7502 16.1081C14.7502 17.3366 15.1647 18.456 15.8641 19.3338C12.8201 20.8917 10.85 24.0174 10.85 27.4974C10.85 28.4215 11.5769 29.15 12.5 29.15H27.5004ZM20.0002 12.3214C22.0772 12.3214 23.7504 13.997 23.7504 16.0781C23.7504 18.1592 22.0773 19.8348 20.0002 19.8348C17.9232 19.8348 16.2501 18.1592 16.2501 16.0781C16.2501 13.997 17.9232 12.3214 20.0002 12.3214Z" fill="#CFD3DC" stroke="#CFD3DC" stroke-width="0.3"/>
                </svg> */}
                <CustomIconComponent IconName="PROFILE" />
                <div className="profile-text">
                    {' '}
                    {isAuthenticated ? 'Profile' : 'Login' }
                </div>
                {isAuthenticated ? (
                    <div
                        className="profile-card common-drop-down"
                        ref={this.profileMenuRef}
                    >
                        <ul className="profile-card-item-list">
                            {listElem}
                        </ul>
                    </div>
                ) : null}
            </LinkComponent>
        );
    }
}

ProfileNavContainer.propTypes = {
    logoutInitDispatch: PropTypes.func.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isAuthenticated: PropTypes.bool.isRequired,
    overlay: PropTypes.bool.isRequired,
    managePlanRequestDispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    preferredPartnerPlans: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    profileMenu: PropTypes.array,
    refLinks: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    experimentDetails: PropTypes.string.isRequired,
};

ProfileNavContainer.defaultProps = {
    preferredPartnerPlans: [],
    profileMenu: [],
};

const mapStateToProps = (state) => {
    const {
        authConfig, notify, userConfig, appConfig,
    } = state;
    return {
        isAuthenticated: authConfig.isAuthenticated,
        overlay: notify.overlay,
        preferredPartnerPlans: userConfig.preferredPartnerPlans,
        profileMenu: appConfig.profileMenu,
        refLinks: appConfig.refLinks,
        experimentDetails: userConfig?.experimentDetails || '',
    };
};

export default connect(mapStateToProps, {
    headerAddNewRouteDispatch: headerAddNewRoute,
    headerPopRouteDispatch: headerPopRoute,
    logoutInitDispatch: logoutInit,
    managePlanRequestDispatch: manageSubscriptionInit,
})(withDeviceUtil(withRouter(ProfileNavContainer)));
