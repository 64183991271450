import {RoutingUtil} from '@airtel-tv/utils';
import PotentialActionParser from './PotentialActionParser';
import { secondToHours, secondToMinutes } from '../../../../utilities/CommonUtil';
import { MARKUP_CONTEXT, MARKUP_TYPES } from '../../../../constants/MarkupConsts';
import { actorDirectorParser } from '../../../../utilities/ParserUtil';

export default function OrganizationMarkupParser({
    contentDetails,
    baseUrl,
}) {
    const {
        title, description, images, durSec, programType, id, credits, languages,
    } = contentDetails;
    const canonicalUrl = RoutingUtil.getContentPlaybackUrlWeb({
        category: programType,
        contentTitle: title,
        contentId: id,
    });
    const embedUrl = baseUrl + canonicalUrl;
    const potentialAction = PotentialActionParser({
        urlTemplate: embedUrl,
        inLanguage: languages && languages[0],
    });

    return {
        '@context': MARKUP_CONTEXT.URL,
        '@type': MARKUP_TYPES.ORGANIZATION,
        name: 'Airtel Xstream',
        alternateName: 'Airtel TV',
        url: 'https://www.airtelxstream.in/',
        logo: 'https://www.airtelxstream.in/static/airtel-logo.svg',
        sameAs: [
            'https://www.facebook.com/AirtelIndia/',
            'https://www.instagram.com/airtelindia/?hl=en',
        ],
    };
}
