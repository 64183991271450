import { MARKUP_TYPES } from '../../../../constants/MarkupConsts';

const PotentialActionParser = ({ urlTemplate, inLanguage }) => ({
    potentialAction: {
        '@type': MARKUP_TYPES.WATCH_ACTION,
        target: [
            {
                '@type': MARKUP_TYPES.ENTRY_POINT,
                urlTemplate,
                inLanguage: inLanguage || '',
                actionPlatform: [
                    'http://schema.org/DesktopWebPlatform',
                    'http://schema.org/MobileWebPlatform',
                    'http://schema.org/IOSPlatform',
                    'http://schema.googleapis.com/GoogleVideoCast',
                    'http://schema.org/AndroidPlatform',
                ],
            },
        ],
        expectsAcceptanceOf: {
            '@type': 'Offer',
            availabilityStarts: '2019-03-31T18:30:00.000Z',
            availabilityEnds: '2029-04-22T18:30:00.000Z',
            category: 'subscription',
            name: 'Airtel Xstream',
            price: 0,
            priceCurrency: '',
            seller: {
                '@type': 'Organization',
                name: 'Airtel',
                sameAs: 'https://www.airtelxstream.in/',
            },
            eligibleRegion: {
                '@type': 'Country',
                name: 'IN',
            },
        },
    },
});

export default PotentialActionParser;
