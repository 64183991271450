import lodashGet from 'lodash/get';
import { LANGUAGE } from '../utilities/en';
import WEB_META from '../utilities/web_meta';
import { getStore } from '../redux/Store';


// let lang = {};

export default function LanguageProvider() {
    const store = getStore && getStore() ? getStore().getState() : null;
    // if (language) {
    //     lang = language;
    // }
    //
    // const handler = {
    //     get: (target, name) => (Object.prototype.hasOwnProperty.call(target, name) ? target[name] : ''),
    // };
    //
    // const proxylang = new Proxy(lang, handler);
    // return proxylang;
    if (store && lodashGet(store, 'appConfig.web_en')) {
        return store.appConfig.web_en;
    }
    return LANGUAGE;
}


export function MetaLanguageProvider() {
    const store = getStore && getStore() ? getStore().getState() : null;
    if (store && lodashGet(store, 'appConfig.web_meta')) {
        return store.appConfig.web_meta;
    }

    return WEB_META;
}

export function getOfflineLanguage() {
    return LANGUAGE;
}
