/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
import {
    call, put, getContext, select,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { layoutReset, purgeLayoutData, purgeStoreLayoutData } from '@airtel-feature/layout/actions/LayoutActions';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { getAuthConfigFromReducer, getBrowserHistoryFromReducer, getModalConfigDataFromReducer } from '@airtel-tv/redux/StoreListing';
import { playbackDetailsFetchAction, playbackWithoutLoginDetailsFetchAction } from '@airtel-feature/playback/actions/PlaybackActions';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import {
    activateFailureEvent, activateSuccessEvent, placeholdereEvent,
} from '@airtel-tv/analytics/FunctionalEvents';
import { Analytics } from '@airtel-tv/analytics';
import UserAction, {
    updateUserEmaiLSuccess, updateUserEmaiLError, updateUserLanguageError, updateTempUserLang, userConfigSuccessAction, updatePreferredPartnerError, showPPModalAction,
    activatePartnerSubscriptionSuccess, activatePartnerSubscriptionError, updateUserConfig, userLocationSuccessAction,
} from './UserAction';
import {
    getUserConfig, updateUserEmail, updateUserInformation, activatePartnerSubscription,
    getUserLocation,
} from '../../service/end-points/ConfigApiService';
import { takeFirst } from '../../utilities/ReduxSagaUtil';
import {
    hideLoadingBar, showLoadingBar, redirectTo, apiFetchCompleteAction,
} from '../notify/NotifyActions';
import LanguageProvider from '../../providers/LanguageProvider';
import { clearAllBrowserStorage, getDayMonth } from '../../utilities/CommonUtil';
import { showToastr } from '../../utilities/NotifyUtil';
import { isOnline, getCurrentWindowSourceName } from '../../utilities/WindowUtil';
import UserStorageUtil from './UserStorageUtil';
import UserInfoUtil from '../../utilities/UserInfoUtil';
import { hideModalComponentAction, showModalComponentAction } from '../modal-popup/ModalPopupActions';
import { setPreferredPartnerPlansRefIds, hidePreferredPartnerOfferPopupUtil } from '../preferred-partner/util/PreferredPartnerUtil';
import { MODAL_POPUP } from '../../constants/AppConst';
import ErrorHandler from '../error-handler/ErrorHandler';
import RoutingUtil from '../../utilities/RoutingUtil';
import { PurgeSvodAllPlans, resetSvodAllPlans } from '@airtel-feature/plansAndSubscription/PlansAndSubscriptionAction';

// EXPORTING THIS BECAUSE IT IS GETTING USED IN LOGOUT SAGA AND IT HAS TO BE SYNCRONUS
// export const getBrowserHistory = state => state.browserHistory;
const getUserConfigFromReducer = state => state.userConfig;

export function* fetchUserConfigSaga({ payload = {} }) {
    try {
        const { stopLayoutReset = false } = payload;
        const deviceUtil = yield getContext('deviceUtil');

        const {
            xppClaimDto,
        } = yield select(getUserConfigFromReducer);

        let response = yield call(getUserConfig, deviceUtil);

        if (xppClaimDto?.xppExpiry) {
            response = {
                ...response,
                xppClaimDto,
            };
        }
        if (!stopLayoutReset) {
            yield put(layoutReset()); // reset layout first then update user config so that user config update will actually update the ui :: reseting layout wont call update as layout container is not listening to whole reducer
        }

        yield put({
            type: UserAction.USER_CONFIG_SUCCESS,
            userConfig: response,
        });
    }
    catch (e) {
        console.error(e);
        clearAllBrowserStorage();
    }
}

function* updateUserEmailSaga({ email }) {
    try {
        const deviceUtil = yield getContext('deviceUtil');

        const response = yield call(updateUserEmail, {
            email,
            deviceUtil,
        });

        yield put(updateUserEmaiLSuccess({
            userConfig: response,
        }));
    }
    catch (error) {
        yield put(updateUserEmaiLError({
            error: error.data,
        }));
    }
}

function* updateUserLanguageSaga({ languageCodes }) {
    try {
        yield put(showLoadingBar());
        const history = yield select(getBrowserHistoryFromReducer);
        const deviceUtil = yield getContext('deviceUtil');
        const { isAuthenticated } = yield select(getAuthConfigFromReducer);
        const {
            userInfo:
            {
                lang,
            },
        } = yield select(getUserConfigFromReducer);

        let newLangCode = languageCodes;
        if (!languageCodes.length) {
            newLangCode = lang;
        }

        if (isAuthenticated) {
            const data = {
                lang: languageCodes,
            };
            const newUserConfig = yield call(updateUserInformation, {
                data,
                deviceUtil,
            });
            yield put(updateUserEmaiLSuccess({
                userConfig: newUserConfig,
            }));
            UserInfoUtil.setUserLanguage(newLangCode);
        }

        else {
            UserStorageUtil.updateTempLang(newLangCode);
            const payload = {
                ln: newLangCode,
            };
            yield put(updateTempUserLang(payload));

            UserInfoUtil.setUserLanguage(newLangCode);
        }
        // language changed so current layout is changed
        yield put(layoutReset());
        const historyLen = history.paths.length;
        let redirectUrl = ROUTE_PATHS.ROOT;
        if (historyLen >= 2) {
            redirectUrl = history.paths[historyLen - 2].pathname + history.paths[historyLen - 2].search;
        }
        // redirect to home page
        yield put(redirectTo({
            redirectUrl,
        }));
    }
    catch (e) {
        console.error('unable to update language ', e);
        let error = e && e.data ? e.data : e;

        if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            error,
        };

        yield put(updateUserLanguageError(payload));
    }
    finally {
        yield put(hideLoadingBar());
    }
}

function* updateUserAgeConsentSaga({ data, isTrailer }) {
    const deviceUtil = yield getContext('deviceUtil');
    const modalConfig = yield select(getModalConfigDataFromReducer);
    const contentId = lodashGet(modalConfig, 'componentProps.contentId', '');
    try {
        yield call(updateUserInformation, {
            data,
            deviceUtil,
        });
        if (isTrailer) {
            yield put(playbackWithoutLoginDetailsFetchAction({
                contentId,
                isTrailer: true,
            }));
        }
        else {
            yield put(playbackDetailsFetchAction({ contentId }));
        }

        yield put(hideModalComponentAction({
            showModalValue: false,
            componentNameValue: null,
            overrideCrossButtonValue: null,
        }));
    }
    catch (error) {
        const payload = lodashGet(error, 'data', error);
        ErrorHandler({
            code: payload.errorcode,
            errortitle: payload.errortitle,
        });
    }
}

function* updateUserPreferredPartners({ preferredPartners, fromContentDetail = false }) {
    const deviceUtil = yield getContext('deviceUtil');
    const data = {
        preferredPartners: [
            preferredPartners,
        ],
    };
    try {
        yield put(apiFetchCompleteAction({ isFetchingDone: false }));
        yield put(updatePreferredPartnerError({}));
        const newUserConfig = yield call(updateUserInformation, {
            data,
            deviceUtil,
        });
        // const { uid } = yield select(getAuthConfigFromReducer);
        const { subscriptions } = newUserConfig;
        // const { preferredPartnerPlans = [] } = yield select(getUserConfigFromReducer);
        // setPreferredPartnerPlansRefIds(preferredPartnerPlans, uid);
        const selectedPlan = subscriptions.find(subs => +subs.productId === data.preferredPartners[0].products[0]);
        let validity = selectedPlan?.expiry || undefined;
        if (validity) {
            validity = `${getDayMonth(validity)}`;
        }
        yield put(userConfigSuccessAction({
            userConfig: {
                ...newUserConfig,
                preferredPartnerPlans: lodashGet(newUserConfig, 'preferredPartnerPlans', []),
            },
            fromContentDetail,
            validity,
        }));
        yield put(apiFetchCompleteAction({ isFetchingDone: true }));

        // yield put(redirectTo({ redirectUrl: RoutingUtil.getChannelPage() }));
    }
    catch (error) {
        console.error(error);
        const payload = {
            error: error && error.data ? error.data : error,
        };
        ErrorHandler({
            showToastOnly: true,
            error: payload.error,
            code: payload.error.errorcode,
            errortitle: payload.error.errortitle,
        });

        yield put(updatePreferredPartnerError(payload));
    }
}

function* userConfigSuccess({ userConfig, fromContentDetail = false, validity = undefined }) {
    const LANGUAGE = LanguageProvider();
    const sourceName = getCurrentWindowSourceName();

    const { preferredPartnerPlans = [] } = userConfig;
    const { isAuthenticated, uid } = yield select(getAuthConfigFromReducer);
    const appId = AuthenticationUtil.getAppId();

    if (fromContentDetail) {
        let MESSAGE = LANGUAGE.XMPCLAIM.CLAIM_SUCCESS_MESSAGE || 'Channel Claimed Successfully! Valid till {timestamp}.';
        if (validity) {
            MESSAGE = MESSAGE.replace('{timestamp}', `${validity}`);
        }
        else {
            MESSAGE = 'Channel Claimed Successfully!';
        }
        showToastr(MESSAGE, LANGUAGE.BUTTON_CLOSE);
        yield put(updateUserConfig());
    }
    const eligibleForxmpRedirect = !sourceName.includes('partner_claim') && !sourceName.includes('hoichoi/campaign') && !sourceName.includes('viewType=webview') && !sourceName.includes('offers') && !sourceName.includes('xmp-claim');// hide ppc popup on partner claim
    if (isAuthenticated) {
        placeholdereEvent('user_config_suceess', {}, true);

        if (preferredPartnerPlans && preferredPartnerPlans.length > 0 && appId !== 'MOBILITY' && eligibleForxmpRedirect) {
            placeholdereEvent('ppc_array_available', {}, true);
            //  yield put(redirectTo({ redirectUrl: RoutingUtil.getXmpClaimRoute() }));
        }
    }
    // if (!sourceName.includes('partner_claim') && !sourceName.includes('hoichoi/campaign') && !sourceName.includes('viewType=webview') && !sourceName.includes('xmp-claim')) {//hide ppc popup on partner claim
    //     yield put(showPPModalAction({
    //         userConfig,
    //     }));
    // }
}

export function* showPreferredPartnerModal({ userConfig }) {
    const { preferredPartnerPlans = [] } = userConfig;
    const { isAuthenticated, uid } = yield select(getAuthConfigFromReducer);
    // const hidePreferredPartnerOfferPopup = hidePreferredPartnerOfferPopupUtil(preferredPartnerPlans, uid);
    const appId = AuthenticationUtil.getAppId();
    if (isAuthenticated && preferredPartnerPlans && preferredPartnerPlans.length > 0 && appId !== 'MOBILITY') {
        // yield put(showModalComponentAction({
        //     showModalValue: true,
        //     componentNameValue: MODAL_POPUP.PREFERRED_PARTNER_MODAL,
        //     overrideCrossButtonValue: true,
        // }));
        placeholdereEvent('redirect_xmp_claim', {}, true);
        yield put(redirectTo({ redirectUrl: RoutingUtil.getXmpClaimRoute() }));
    }
}


function* showClaimXMPPopUp({ userConfig }) {
    const { isAuthenticated, uid } = yield select(getAuthConfigFromReducer);

    if (isAuthenticated) {
        yield put(showModalComponentAction({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.XMP_CLAIM_POPUP,
            overrideCrossButtonValue: true,
        }));
    }
}

function* showNonAirtelUserPopup(payload) {
    const { isAuthenticated, uid } = yield select(getAuthConfigFromReducer);

    if (isAuthenticated) {
        yield put(showModalComponentAction({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.NON_AIRTEL_USER_POPUP,
            overrideCrossButtonValue: true,
            payload,
        }));
    }
}

function* updateUserProfileDetailsSaga(data) {
    const deviceUtil = yield getContext('deviceUtil');
    delete data.type;
    const LANGUAGE = LanguageProvider();

    try {
        const newUserConfig = yield call(updateUserInformation, {
            data,
            deviceUtil,
        });
        yield put(updateUserEmaiLSuccess({
            userConfig: newUserConfig,
        }));
        showToastr(lodashGet(LANGUAGE, 'PROFILE.SUCCESS_MESSAGE', 'Profile updated successfully'), lodashGet(LANGUAGE, 'BUTTON_CLOSE', ''));
    }
    catch (error) {
        console.log(error);
        const errorData = lodashGet(error, 'data', {});
        const message = errorData.errortitle || LANGUAGE[errorData.errorcode] || LANGUAGE[ERROR_CODES.UNKNOWN];
        showToastr(message, lodashGet(LANGUAGE, 'BUTTON_CLOSE', ''));
    }
}

function* activatePartnerSubscriptionSaga({ activationId, sessionId }) {
    const userInfo = yield select(getUserConfigFromReducer);

    const { customerType } = userInfo;
    const deviceUtil = yield getContext('deviceUtil');
    const networkType = deviceUtil.getNetworkType();
    const atvCustomer = deviceUtil.getAtvCustomer(customerType, networkType);
    const data = {
        claimId: activationId,
        claimType: 'SHORT_LINK_CLAIM',
    };
    try {
        const response = yield call(activatePartnerSubscription, {
            data,
            deviceUtil,
            atvCustomer,
        });
        activateSuccessEvent({
            response: JSON.stringify(response),
            sid: sessionId,
        });

        yield put(activatePartnerSubscriptionSuccess());
    }
    catch (error) {
        const errorData = error.data ? error.data : error;
        activateFailureEvent({
            error_code: errorData.errorcode,
            response: JSON.stringify(error),
            sid: sessionId,
        });

        yield put(activatePartnerSubscriptionError({ errorData }));
    }
}
function* fetchUserLocationSaga() {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const userLocationResponse = yield call(getUserLocation, deviceUtil);

        const {
            city = '',
            country = '',
            state = '',
        } = userLocationResponse || {};

        Analytics.update(
            {
                city,
                country,
                state,
            },
        );
        yield put(userLocationSuccessAction({ userLocation: userLocationResponse }));
    }
    catch (error) {
        console.error(error);
    }
}

function* UserConfigLayoutReset() {
    //@NGTD should we reset all plans data
    yield call(fetchUserConfigSaga, {});
    yield put(purgeStoreLayoutData());
    yield put(PurgeSvodAllPlans({}));
}

function* UserConfigAllReset() { //Called when timer expires
    yield call(fetchUserConfigSaga, {});
    yield put(purgeStoreLayoutData()); //Deletes all layout data
    yield put(purgeLayoutData()); //Resets layout reset key so that apis could be called agan
    yield put(resetSvodAllPlans()); //Reset svod reset key so that svod hub api could be called again
}

export default [
    takeFirst(UserAction.USER_CONFIG_INIT, fetchUserConfigSaga),
    takeFirst(UserAction.UPDATE_USER_EMAIL, updateUserEmailSaga),
    takeFirst(UserAction.UPDATE_USER_LANGUAGE, updateUserLanguageSaga),
    takeFirst(UserAction.UPDATE_USER_AGE_CONSENT, updateUserAgeConsentSaga),
    takeFirst(UserAction.UPDATE_PREFERRED_PARTNER_SELECTIONS, updateUserPreferredPartners),
    takeFirst(UserAction.USER_CONFIG_SUCCESS, userConfigSuccess),
    takeFirst(UserAction.SHOW_PP_USER_MODAL, showPreferredPartnerModal),
    takeFirst(UserAction.SHOW_REDEEM_XMP_ACTION_MODAL, showClaimXMPPopUp),
    takeFirst(UserAction.SHOW_NON_AIRTEL_USER_MODAL, showNonAirtelUserPopup),
    takeFirst(UserAction.UPDATE_USER_PROFILE_DETAILS, updateUserProfileDetailsSaga),
    takeFirst(UserAction.ACTIVATE_PARTNER_SUBSCRIPTION_INIT, activatePartnerSubscriptionSaga),
    takeFirst(UserAction.FETCH_USER_LOCATION, fetchUserLocationSaga),
    takeFirst(UserAction.USER_CONFIG_WITH_LAYOUT_RESET, UserConfigLayoutReset),
    takeFirst(UserAction.USER_CONFIG_WITH_ALL_RESET, UserConfigAllReset),
];
