/* eslint-disable class-methods-use-this */
import lodashGet from 'lodash/get';
import React, {
    Component, createRef,
} from 'react';
import {
    matchRoutes,
    matchPath, Navigate, Outlet,
} from 'react-router';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ROUTE_PATHS, ROUTE_SOURCE_NAMES } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import {
    clearSearchSessionCode,
    getMatchedRoute, getPageInfoFromPageTitle, getSourceName,
    getSourceNameFromStorage,
} from '@airtel-tv/utils/GlobalUtil';
import { Analytics, clickEvent, screenVisibleEvent } from '@airtel-tv/analytics/index';
import { LocationUtil, RoutingUtil, checkWindowExist } from '@airtel-tv/utils';
// import appStartEvent from '@airtel-tv/analytics/FunctionalEvents';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import NavigationSetter from '@airtel-tv/utils/NavigationSetter';
import { Crash } from '@airtel-tv/crash-capture';
import CrashCaptureHOC from '@airtel-tv/lib/hoc/CrashCaptureHOC';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
// import RoutingUtil from '../../utilities/RoutingUtil';
import { showModalComponentAction, hideModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import {
    ANALYTICS_ACTIONS, BILL_STATUS, LOCAL_STORE_KEYS, MODAL_POPUP,
    QUERY_PARAMS,
    SCOPED_WEBVIEW_INGRESS_APP,
    THIRY_SEC_TIMER,
} from '@airtel-tv/constants';
import browserStore, { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { getMoengage } from '@airtel-tv/utils/moengageUtil';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
import HistoryUtil from '@airtel-tv/utils/HistoryUtil';
import { handleEpgViewChange, hideRenewBanner, resetAppTimerState } from '@airtel-feature/notify/NotifyActions';
import { backButtonEvents } from '@airtel-tv/analytics/FunctionalEvents';
import { fetchDistroDetails } from '@airtel-feature/layout/actions/DistroAction';
import { webAdSdkInit } from '@airtel-tv/utils/AdSdkUtil';
import { paginatedLayoutFetchAction } from '@airtel-feature/layout/actions/LayoutActions';
import { SvodAllPlansAction } from '@airtel-feature/plansAndSubscription/PlansAndSubscriptionAction';
// import { autoLoginWithSSO } from '@airtel-feature/auth/AuthAction';
// import DownloadAirtelNudge from '@airtel-tv/ui-lib/atoms/nudges/DownloadAirtelNudge';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import AppTimer from '@airtel-tv/utils/AppTimer';
// import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { googleAnalyticsInitialization } from '../../utilities/GoogleAnalyticsUtil';
import { triggerLogin, updateMsisdn } from '../auth/AuthAction';
import { saveChannelPartnerId } from '../channel-partner/ChannelPartnerActions';
import Config from '../../config/index';
import {
    IMAGE_PATHS, ERROR_PAGE_ACTIONS,
} from '../../constants/AppConst';
import { addBrowserHistory } from './actions/BrowserHistoryAction';
import AppContext from '../../contexts/AppContext';
import { isOnline } from '../../utilities/WindowUtil';
import { hidePlayback, showErrorPage } from '../notify/NotifyActions';
import LanguageProvider from '../../providers/LanguageProvider';
import { syncContentInit } from '../../../../features/sync/SyncActions';
import { planConfigInit } from '../plan/PlanActions';
import { appStartAction, adsDataInit } from './actions/AppActions';
import getAllRouters from '../../routes/routes';
import { EVENT_SOURCE_NAME } from '../../constants/EventsConst';
import { themeConfigFetchAction } from '../theme/ThemeActions';
import VIEWS from '../../constants/ViewConst';
import getPageView from './factories/AppViewFactory';
import { PopupConfigFetchAction } from '../preferred-partner/PopupActions';
import BranchioUtil from '../../utilities/BranchioUtil';
import { getCombinedPlansInit, getRechargeInfo } from '../subscription-details/action/SubscriptionAction';
import styles from '../shared/scss/global.scss';
import { fetchUserLocation } from '../user/UserAction';
import { updateNavigationInfo } from '../navigation/NavigationActions';
import { ACTION_TO_NOTIFYID, NOTIFY_ID_MODAL_PAYLOAD } from '../error-handler/ErrorCodes';


// import AutoLoginLoaderComponent from '../shared/components/loader/AutoLoginLoaderComponent';
// const ErrorPageContainer = lazy(() => import('../error-handler/ErrorPageContainer'));
// import route from '../../routes/lazy-routes';
const Loader = () => <div />;
export const UserContainer = Loadable({
    loader: () => import('../user/UserContainer' /* webpackChunkName: "user_container" */),
    loading: Loader,
});
export const AuthContainer = Loadable({
    loader: () => import('../auth/AuthContainer' /* webpackChunkName: "auth_container" */),
    loading: Loader,
});
const ErrorPageContainer = Loadable({
    loader: () => import('../error-handler/ErrorPageContainer' /* webpackChunkName: "error_page" */),
    loading: Loader,
});

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        const appId = LocationUtil.getQueryParams(this.props, QUERY_PARAMS.APP_ID);
        if (appId) {
            AuthenticationUtil.saveAppId(appId);
        }
        this.isMobility = appId === 'MOBILITY';
        this.deviceUtil = DeviceUtil.getDeviceUtil();
        this.isScopedWebview = this.deviceUtil.isScopedWebview();
        this.isIOS = this.deviceUtil.isIOS();
        this.navigationObj = {
            currentRoute: '',
            previousRoute: '',
        };
        this.timeoutId = null;
        this.topSpaceClassRef = createRef(null);
    }

    componentDidMount = () => {
        const {
            updateMsisdnDispatch,
            saveChannelPartnerIdDispatch,
            location,
            addBrowserHistoryDispatch,
            isAuthenticated,
            syncContentInitDispatch,
            planInitDispatch,
            combinedPlanDispatch,
            uid,
            appStartActionDispatch,
            themeConfigFetchDispatch,
            popupConfigFetchDispatch,
            themeConfig,
            fetchUserLocationDispatch,
            email,
            experimentDetails,
            circle,
            triggerLoginDispatch,
            loginTriggered,
            fetchDistroDetailsDispatch,
            appConfig,
            autoLoginInProgress,
            skipWebFeatures,
        } = this.props;
        this.navigationObj.currentRoute = location;
        // const { web_pageId } = appConfig;
        // layoutFetchActionDispatch({
        //     pageId: web_pageId?.SDK_AD_CONFIG,
        // });
        // updateNavigationInfoDispatch({
        //     navigationObj: {
        //         ...this.navigationObj,
        //         currentRoute: location,
        //     },
        // });
        if (!skipWebFeatures) {
            if (this.isScopedWebview) {
                if (checkWindowExist()) {
                    // method on window for thanks app to pause content on watch tab in background
                    window.resetPlayback = this.resetPlayback;
                    if (ScopedWebviewUtil.getUtmParams(appConfig)?.utm_platform === SCOPED_WEBVIEW_INGRESS_APP.THANKS) {
                        window.app = {
                            setThanksSessionId: this.setThanksSessionId,
                        };
                    }
                }
                LocationUtil.setSessionIdFromThanks({ isIOS: this.isIOS });
            }
            if (!autoLoginInProgress) {
                this.timeoutId = setTimeout(() => {
                    if (!loginTriggered && !isAuthenticated) {
                        triggerLoginDispatch({
                            loginTriggered: true,
                        });
                        clearTimeout(this.timeoutId);
                        this.timeoutId = null;
                    }
                }, THIRY_SEC_TIMER);
            }
        }
        const msisdnFromParams = LocationUtil.getQueryParams(this.props, QUERY_PARAMS.MSISDN);
        HistoryUtil.setHistory();
        if (msisdnFromParams) {
            updateMsisdnDispatch(msisdnFromParams);
        }
        this.showFmfModal();
        this.showXPPclaimPopupHandler();
        // this.showActivationPendingPopupHandler();
        const cpid = LocationUtil.getQueryParams(this.props, QUERY_PARAMS.CHANNEL_PARTNER);
        if (cpid) {
            saveChannelPartnerIdDispatch(cpid);
        }

        // if (!AuthenticationUtil.getUid() && uid) {
        //     AuthenticationUtil.saveUid(uid);
        // }

        Analytics.initialize({
            deviceUtil: this.deviceUtil,
            uid,
            experimentDetails,
            campaignDetails: this.deviceUtil.isScopedWebview() ? ScopedWebviewUtil.getUtmParams(appConfig) : {},
        });
        Crash.initialize(this.deviceUtil, uid);
        const Moengage = getMoengage();

        Moengage?.add_unique_user_id(uid);
        if (email) {
            Moengage?.add_email(email);
        }
        if (circle) {
            Moengage?.add_user_attribute('circle', circle);
        }
        // appStartEvent();
        appStartActionDispatch();
        // appLaunchGaEvent(isStandalone());

        // add first route in the history, rest will be added by listener
        addBrowserHistoryDispatch(location);

        this.LANGUAGE = LanguageProvider();


        // this.screenVisible();
        // sync content
        if (isAuthenticated) {
            combinedPlanDispatch();
            // this.getRechargeDetails();

            planInitDispatch();
            this.getSvodPlans();
            syncContentInitDispatch({
                forceSync: false,
                isDelay: false,
            });
            // For getting all the popups
            popupConfigFetchDispatch();
        }
        //removed as not used now 
        // if (themeConfig && Object.keys(themeConfig.configs).length < 1) {
        //     themeConfigFetchDispatch();
        // }
        googleAnalyticsInitialization();
        fetchUserLocationDispatch();
        fetchDistroDetailsDispatch();
        setTimeout(() => {
            BranchioUtil.initializeBranch();
        }, 1000);

        // if(msisdn){
        //     let msisdnNo = '';
        //     if (msisdn && token) {
        //         msisdnNo = CryptoUtil.AESDecryptMsdin({
        //             key: token,
        //             value: msisdn,
        //         });
        //     }
        //     Analytics.initialize({deviceUtil ,msisdnNo});
        // }
        if (checkWindowExist()) {
            window.scrollTo(0, 0);
        }
        this.initAdSDK();
    };

    componentDidUpdate(prevProps, prevState) {
        googleAnalyticsInitialization();

        const {
            isAuthenticated, location = {}, hideRenewBannerDispatch, userConfig: { xppClaimOnThanks = '' }, history, updateNavigationInfoDispatch, loginTriggered,
            uid,
            planInitDispatch,
            svodAllPlansDispatch,
            svodReset, resetAppTimerStateDispatch, isOnehubRoute,
        } = this.props;
        const {
            isAuthenticated: prevIsAuthenticated, location: prevLocation = {}, userConfig: { xppClaimOnThanks: prevXppClaimOnThanks = '' }, history: prevHistory,
            uid: previousUid, svodReset: prevSvodReset,
        } = prevProps;
        if (this.timeoutId && (isAuthenticated || loginTriggered)) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
        if (location.pathname !== prevLocation.pathname) {
            const isBack = this.navigationObj?.previousRoute === location.pathname;
            const prevLocIsCDPRoute = RoutingUtil.isCdpRoute(prevLocation?.pathname, getAllRouters) || false;
            const currLocIsCDPRoute = RoutingUtil?.isCdpRoute(location?.pathname, getAllRouters) || false;
            const prevLocIsSearchRoute = RoutingUtil?.isSearchPage(prevLocation?.pathname, getAllRouters) || false;
            if (!currLocIsCDPRoute && prevLocIsSearchRoute) {
                clearSearchSessionCode();
            }
            if (isBack) {
                const meta = {
                    action: ANALYTICS_ACTIONS.BACK_BUTTON_CLICK,
                };
                backButtonEvents(meta);
                if (prevLocation.pathname.includes('/search')) {
                    clearSearchSessionCode();
                }
            }
            if (!isBack && prevLocIsCDPRoute && !!sessionStore.get(LOCAL_STORE_KEYS.SEARCH_SESSION_CODE)) {
                // clearing search session code when user moves forward from cdp
                clearSearchSessionCode();
            }
            this.navigationObj.currentRoute = location.pathname;
            this.navigationObj.previousRoute = prevLocation.pathname;
            if (this?.topSpaceClassRef?.current && isOnehubRoute) {
                this.topSpaceClassRef.current.scrollTop = 0;
            }
        }
        if (uid && isAuthenticated && uid !== previousUid && !this.isMobility) {
            planInitDispatch({});
            this.getSvodPlans();
        }
        if (svodReset !== prevSvodReset) {
            svodAllPlansDispatch();
        }
        if (isAuthenticated !== prevIsAuthenticated && (prevLocation && prevLocation.pathname && location.pathname === prevLocation.pathname)) {
            this.screenVisible();
        }

        if ((isAuthenticated !== prevIsAuthenticated && isAuthenticated) || (prevXppClaimOnThanks !== xppClaimOnThanks)) {
            this.showFmfModal();
            this.showXPPclaimPopupHandler();
            // this.showActivationPendingPopupHandler();
        }
        if (isAuthenticated !== prevIsAuthenticated && !isAuthenticated) {
            hideRenewBannerDispatch();
            resetAppTimerStateDispatch();
        }
        if ((isAuthenticated !== prevIsAuthenticated && isAuthenticated)) {
            // this.getRechargeDetails(); // get recharge details if login chaged
            // const { web_pageId } = appConfig;
            // layoutFetchActionDispatch({
            //     pageId: web_pageId?.SDK_AD_CONFIG,
            // });
        }

        if (isAuthenticated !== prevIsAuthenticated) {
            this.initAdSDK();
        }
        if (this.isScopedWebview && loginTriggered) {
            LocationUtil.resetXstreamSessionOnThanks(0);
        }
        // if(msisdn !== previousMsisdn){
        //     let msisdnNo = '';
        //     if (msisdn && token) {
        //         msisdnNo = CryptoUtil.AESDecryptMsdin({
        //             key: token,
        //             value: msisdn,
        //         });
        //     }
        //     Analytics.initialize({deviceUtil ,msisdnNo});
        // }
    }

    resetPlayback = (params = '') => {
        const { isFocused = false, lifecyclemethod = 'tab_change' } = params;
        if (checkWindowExist()) {
            window.isAppNotFocused = !isFocused;
        }
    };

    setThanksSessionId = (sessionId = 'empty sessionID') => {
        if (checkWindowExist()) {
            sessionStore.set(LOCAL_STORE_KEYS.THANKS_APP_SESSION_ID, sessionId);
        }
    };

    initAdSDK = () => {
        const { uid } = this.props;
        const did = this.deviceUtil.getDid();
        webAdSdkInit({
            uid,
            did,
        });
    };

    getSvodPlans = () => {
        const { svodAllPlansDispatch, isOnehubRoute } = this.props;
        // const preferredCp = LocationUtil.getQueryParams(this.props, QUERY_PARAMS.PREFERRED_CP);
        // const isPreferredCpPlan = isOnehubRoute && preferredCp;
        if (!isOnehubRoute) {
            // const dataId = isPreferredCpPlan ? `${preferredCp}_plans` : 'all_plans';
            const dataId = 'all_plans';
            svodAllPlansDispatch({
                // preferredCp: isPreferredCpPlan ? preferredCp : '',
                preferredCp: '',
                dataId,
            });
        }
    };

    getRechargeDetails = () => {
        const {
            getRechargeInfoDispatch, uid, hideModalComponentActionDispatch, prepaidBenefitDetails, showModalComponentActionDispatch,
            xEy,
        } = this.props;
        if (prepaidBenefitDetails && Object.keys(prepaidBenefitDetails).length) {
            showModalComponentActionDispatch({
                showModalValue: true,
                componentNameValue: MODAL_POPUP.RECHARGES_XPP_INFO_POPUP,
                overrideCrossButtonValue: true,
                payload: {
                    popUpcustomClass: 'recharge-info-popup',
                    uid,
                    hideModalComponentActionDispatch,
                    amount: prepaidBenefitDetails?.rechargeAmount,
                    validity: prepaidBenefitDetails?.validity,
                    planDuration: prepaidBenefitDetails?.planDuration,
                    shouldCloseOnRouteChange: false,
                    viewPlanCta: ROUTE_PATHS.SUBSCRIPTION_PAGE,
                },
            });
        }
        getRechargeInfoDispatch({
            uid,
            hideModalComponentActionDispatch,
            xEy,
        });
    };

    getAutoPopUpsExcludedPaths = () => {
        if (checkWindowExist()) {
            return [
                ROUTE_PATHS.DTH_SUBSCRIBE,
                ROUTE_PATHS.USER_CHAT,
            ].includes(window.location.pathname);
        }
        return false;
    };

    hideFmfModal = () => {
        const { uid } = this.props;
        const keys = browserStore.get(LOCAL_STORE_KEYS.FMF_DISPLAYED);
        browserStore.set(LOCAL_STORE_KEYS.FMF_DISPLAYED, {
            ...keys,
            [uid]: true,
        });
    };

    showFmfModal = () => {
        const {
            userConfig: { fmfConfig = {} }, ISMOBILITY, isAuthenticated, uid,
        } = this.props;
        const excludepaths = this.getAutoPopUpsExcludedPaths();
        if (isAuthenticated && !excludepaths && Object.keys(fmfConfig).length && !browserStore.get(LOCAL_STORE_KEYS.FMF_DISPLAYED)?.[uid] && !this.deviceUtil.isScopedWebview()) {
            this.props.showModalComponentActionDispatch({
                showModalValue: true,
                componentNameValue: MODAL_POPUP.FMF_MODAL,
                overrideCrossButtonValue: false,
                payload: {
                    popupVisibleMeta: { asset_name: 'offers_popup' },
                    backGroundCustomClass: '',
                    popUpcustomClass: '',
                    uid,
                    showLottie: !ISMOBILITY,
                    closeBtnClickedHandler: this.hideFmfModal,
                },
            });
        }
    };

    hideActivationPendingPopup = () => {
        const { uid, hideModalComponentActionDispatch } = this.props;
        const keys = browserStore.get(LOCAL_STORE_KEYS.ACTIVATION_PENDING);
        const { currentSource, prevSource } = getSourceNameFromStorage() || {};
        const meta = {
            action: 'close_activation_popup',
            source_name: currentSource,
            source_page: prevSource,
        };
        clickEvent(meta);
        browserStore.set(LOCAL_STORE_KEYS.ACTIVATION_PENDING, {
            ...keys,
            [uid]: Date.now(),
        });
        hideModalComponentActionDispatch({
            showModalValue: false,
            componentNameValue: null,
            overrideCrossButtonValue: false,
        });
    };

    showActivationPendingPopupHandler = () => {
        const { uid, isAuthenticated, location: { pathname = '' } = {}, showModalComponentActionDispatch, userConfig: { partnerStatus: { pendingActivationCps = [] } = {} } = {}, appConfig: { cpDetailsById = {}, netflixCpDetails = {}, daysThreshholdForPopupVisible = 1 } = {}, history } = this.props;
        const {
            LATER = 'Later',
            OTT_CLAIM_MULTIPLE_LABEL = 'Claim Your OTT Subscription to Start Watching',
            OTT_CLAIM_NOW = 'Claim Now',
        } = this.LANGUAGE || {};
        const dayDifference = daysThreshholdForPopupVisible * 60 * 60 * 24 * 1000;
        const currentTime = Date.now();
        const activationPendingKey = browserStore.get(LOCAL_STORE_KEYS.ACTIVATION_PENDING)?.[uid];
        const isActivationPending = pendingActivationCps.length;
        let payload = {};
        const imageSettings = {
            320: 250,
            500: 250,
            1000: 450,
        };
        const cpDetailsByIdLocal = {
            ...cpDetailsById,
            [netflixCpDetails.cpId]: netflixCpDetails,
        };
        if (isActivationPending) {
            payload = {
                title: pendingActivationCps.length === 1 ? OTT_CLAIM_MULTIPLE_LABEL?.replace('ubscription', 'ubscriptions') : OTT_CLAIM_MULTIPLE_LABEL,
                imageUrl: pendingActivationCps.map(ele => cpDetailsByIdLocal[ele]?.logoUrl),
            };
        }
        if (isAuthenticated && [
            ROUTE_PATHS.SETTINGS,
            ROUTE_PATHS.ROOT,
        ].includes(pathname) && isActivationPending && (!activationPendingKey || (activationPendingKey && (currentTime - activationPendingKey > dayDifference))) && (payload?.title && payload?.imageUrl)) {
            showModalComponentActionDispatch({
                showModalValue: true,
                componentNameValue: MODAL_POPUP.ACTIVATION_PENDING_POPUP,
                overrideCrossButtonValue: true,
                payload: {
                    removeInnerPopupClass: true,
                    primaryCTA: OTT_CLAIM_NOW,
                    secondaryCTA: LATER,
                    primaryAction: () => {
                        this.hideActivationPendingPopup();
                        history.push({
                            pathname: ROUTE_PATHS.ONE_HUB_ACTIVATE,
                        });
                        // planPurchase(params);
                        // hideActivationPendingPopup();
                    },
                    imageSettings,
                    secondaryAction: this.hideActivationPendingPopup,
                    ...payload,
                },
            });
        }
    };

    showXPPclaimPopupHandler = () => {
        const {
            userConfig: { xppClaimDto = {} }, isAuthenticated, uid, showModalComponentActionDispatch,
            location: { pathname = '' } = {},
        } = this.props;
        // const excludepaths = this.getAutoPopUpsExcludedPaths();
        const oneDay = 60 * 60 * 24 * 1000;
        const currentTime = Date.now();
        const xppClaimKey = browserStore.get(LOCAL_STORE_KEYS.XPP_CLAIM_DISPLAYED)?.[uid];
        const isXppClaim = (xppClaimDto && Object.keys(xppClaimDto).length && (xppClaimDto?.status === BILL_STATUS.PENDING)) || false;
        if (isAuthenticated && [
            ROUTE_PATHS.SETTINGS,
            ROUTE_PATHS.ROOT,
        ].includes(pathname) && isXppClaim && (!xppClaimKey || (xppClaimKey && (currentTime - xppClaimKey > oneDay))) && !this.deviceUtil.isScopedWebview()) {
            const { notifyId = '' } = xppClaimDto;
            const payload = NOTIFY_ID_MODAL_PAYLOAD[notifyId] || '';
            if (payload) {
                showModalComponentActionDispatch({
                    showModalValue: true,
                    componentNameValue: ACTION_TO_NOTIFYID[notifyId],
                    overrideCrossButtonValue: false,
                    payload: {
                        ...payload,
                        popupVisibleMeta: {
                            asset_name: 'xpp_claim',
                            popup_id: notifyId,
                        },
                        closeClickedMeta: {
                            asset_name: 'Cross Button',
                            popup_id: notifyId,
                        },
                        backGroundCustomClass: '',
                        // popUpcustomClass: 'xpp-claim-modal',
                        uid,
                        showLottie: false,
                        closeBtnClickedHandler: this.hideXPPclaimPopupHandler,
                        shouldCloseOnRouteChange: false,
                    },

                });
            }
        }
    };

    hideXPPclaimPopupHandler = () => {
        const { uid } = this.props;
        const keys = browserStore.get(LOCAL_STORE_KEYS.XPP_CLAIM_DISPLAYED);
        const meta = {
            action: 'close_xpp_popup',
            source: 'xpp-claim-steps',
        };
        clickEvent(meta);
        browserStore.set(LOCAL_STORE_KEYS.XPP_CLAIM_DISPLAYED, {
            ...keys,
            [uid]: Date.now(),
        });
    };

    // getPageIdFromAppConfig = ({pages}) => {
    //     // const { appConfig: {pages=[]} = {} }= this.props;
    //     // const matchedRoute = getMatchedRoute();
    //     // const {params: {contentId = ''} = {}, path} = matchedRoute;
    //     const page = getPageInfoFromPageTitle({pages, matchedRoute});
    //     const {id = null} = page;
    //     return id;
    // }

    getAnalyticsMeta = () => {
        const {
            userConfig: { customerType = '', userProperties: { cl = '' } = {} },
            appConfig: { pages = [] } = {},
        } = this.props;

        const matchedRoute = getMatchedRoute();
        const { params: { contentId = '', implicitPageId = '' } = {} } = matchedRoute || {};
        const content_id = contentId && contentId.split('?')[0];
        const { prevSource } = getSourceNameFromStorage();
        let pageId = implicitPageId;
        const page = getPageInfoFromPageTitle({
            pages,
            matchedRoute,
        });
        const { id = null } = page;
        if (id) {
            pageId = id;
        }
        const meta = {
            customer_type: customerType,
            circle: cl,
            ...(pageId && { page_id: pageId }),
            ...(content_id && { content_id }),
            ...(prevSource && { source_page: prevSource }),
        };
        return meta;
    };

    screenVisible = () => {
        const { location } = this.props;
        const meta = this.getAnalyticsMeta();
        const sourceName = SOSUtil.getRouteSourceNameMappedFromCMS(getSourceName(location.pathname, location));
        // const {cl = ''} = userProperties;
        // const meta = {
        //     customer_type: customerType,
        //     circle: cl,
        //     ...(pageId && {page_id: pageId}),
        //     ...(contentId && {content_id: contentId}),
        // }
        screenVisibleEvent(sourceName, false, meta);
    };

    componentDidCatch(error) {
        console.error('app error boundary', error);
        const {
            showErrorPageDispatch,
        } = this.props;

        // if chunk fetch failed
        if (lodashGet(error, 'message', '').toLowerCase().match(new RegExp(ERROR_CODES.CHUNK_FAILED))) {
            const hasInternet = isOnline();
            showErrorPageDispatch({
                errorPageData: {
                    message: !hasInternet ? this.LANGUAGE.ERROR_NETWORK : this.LANGUAGE.NEW_BUILD_CHUNK_404,
                    imageUrl: IMAGE_PATHS.BUY_SUBSCRIPTION,
                    errorAction: ERROR_PAGE_ACTIONS.RELOAD,
                    buttonText: !hasInternet ? this.LANGUAGE.BUTTON_RETRY : this.LANGUAGE.BUTTON_RELOAD,
                },
            });
        }
    }

    render() {
        const {
            errorPageData,
            location,
            overlay,
            viewType,
            appConfig = {},
            showPlayback,
            match,
            showModal,
            hidePlaybackDispatch,
            playingOnBanner,
            autoLoginInProgress,
            isAuthenticated,
        } = this.props;

        const { pathname } = location;
        const route = getAllRouters();
        const routePresent = matchRoutes(route, pathname);
        // if '/' is coming at the end of the url then removing it and redirecting back to the same url
        if (pathname.slice(-1) === '/' && pathname !== ROUTE_PATHS.ROOT) {
            return <Navigate to={pathname.slice(0, pathname.length - 1)} />;
        }

        // // redirect to 404 in case of no matching route found
        // if (routePresent?.length < 1) {
        //     return (
        //         <Navigate
        //             from={RoutingUtil.getHomePage()}
        //             to={RoutingUtil.get404Route()}
        //         />
        //     );
        // }

        const matchedRoute = matchPath({
            path: ROUTE_PATHS.LAYOUT,
            exact: true,
            strict: false,
        }, location.pathname);
        const contentMatchedRoute = matchPath({
            path: ROUTE_PATHS.CONTENT_LAYOUT_PAGES,
            exact: true,
            strict: false,
        }, location.pathname);
        const isBlogPage = location.pathname.includes(ROUTE_PATHS.BLOGS);
        const isQRCodePage = routePresent?.length > 1 && routePresent[1]?.route?.path === ROUTE_PATHS.QR_CODE_LOGIN;

        const isHomePage = matchedRoute && Object.keys(matchedRoute).length > 0;
        const withPageTitle = (isHomePage && !match.isExact) || (contentMatchedRoute && Object.keys(contentMatchedRoute).length > 0);
        if (isHomePage && !withPageTitle && showPlayback) {
            hidePlaybackDispatch();
        }
        let renderElement = (
            <>
                <NavigationSetter locationUpdateCallback={this.screenVisible} />
                {isAuthenticated ? <AppTimer /> : null}
                <main>
                    <div className={`atl-content${showPlayback || playingOnBanner ? ' player-cdp' : ''} ${isHomePage ? ' home-page' : ''}${withPageTitle ? ' with-page-title' : ''}`}>
                        <div className={`atl-content-overlay${overlay ? '' : ' d-none'}`} />
                        <div
                            className={[
                                ROUTE_PATHS.BLOGS,
                                ROUTE_PATHS.BLOG_DESCRIPTION,
                            ].includes(location.pathname) ? '' : 'top-space-class'}
                            ref={this.topSpaceClassRef}
                        >
                            <Outlet />
                        </div>
                    </div>
                </main>
                {!this.isMobility && !this.isScopedWebview
                    ? (
                        <>
                            <UserContainer />
                            <AuthContainer />
                        </>
                    )
                    : null
                }
            </>
        );
        // LiveTv Code commented
        // const pageId = getPageIdFromProps(routePresent[0].match || {}, appConfig);
        // if (errorPageData && pageId !== appConfig.liveTvId) {
        //     renderElement = <ErrorPageContainer errorPageData={errorPageData} />;
        // }
        if (errorPageData) {
            renderElement = (
                <ErrorPageContainer errorPageData={errorPageData} />
            );
        }
        const pageId = this.getAnalyticsMeta().page_id || '';
        // if (autoLoginInProgress) {
        //     return <AutoLoginLoaderComponent heading="SWITCHING TO" />;
        // }
        const utmPlatform = appConfig.utmPlatform || (ScopedWebviewUtil.getUtmParams(appConfig)?.utm_platform || '');
        const isThanksScopedWebview = utmPlatform.toLowerCase() === SCOPED_WEBVIEW_INGRESS_APP.THANKS;
        return (
            <AppContext.Provider value={{
                deviceUtil: this.deviceUtil,
            }}
            >
                <div
                    id={Config.appId}
                    className={`alt-wrapper atl-fixed-sidebar isWeb ${isBlogPage ? 'blog-page' : ''} ${isQRCodePage ? 'force-stop-scroll' : ''} ${this.deviceUtil.isScopedWebview(appConfig.isScopedWebview) && isThanksScopedWebview ? 'scoped-webview' : ''}`}
                >
                    {getPageView({
                        viewType,
                        route,
                        renderElement,
                        pageId,
                    })}
                </div>
            </AppContext.Provider>
        );
    }
}


App.propTypes = {
    // route: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    updateMsisdnDispatch: PropTypes.func.isRequired,
    saveChannelPartnerIdDispatch: PropTypes.func.isRequired,
    errorPageData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    addBrowserHistoryDispatch: PropTypes.func.isRequired,
    popupConfigFetchDispatch: PropTypes.func.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showErrorPageDispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    syncContentInitDispatch: PropTypes.func.isRequired,
    planInitDispatch: PropTypes.func.isRequired,
    svodAllPlansDispatch: PropTypes.func.isRequired,
    combinedPlanDispatch: PropTypes.func.isRequired,
    uid: PropTypes.string,
    appStartActionDispatch: PropTypes.func.isRequired,
    overlay: PropTypes.bool.isRequired,
    themeConfigFetchDispatch: PropTypes.func.isRequired,
    viewType: PropTypes.string,
    fetchAdsToken: PropTypes.func.isRequired,
    showPlayback: PropTypes.bool,
    getRechargeInfoDispatch: PropTypes.func.isRequired,
    hideModalComponentActionDispatch: PropTypes.func.isRequired,
    prepaidBenefitDetails: PropTypes.object,
};

App.defaultProps = {
    errorPageData: null,
    uid: '',
    viewType: '',
    showPlayback: false,
    prepaidBenefitDetails: {},
};

function mapStateToProps(state, props) {
    const {
        notify = {},
        authConfig: {
            uid = '', isAuthenticated = false, loginTriggered = false,
            autoLoginInProgress = false,
        } = {},
        userConfig = {},
        userConfig: {
            experimentDetails = '',
            preferredPartnerPlans = [],
            userInfo: { email, circle } = {},
            prepaidBenefitDetails = {},
            userContentProperties: {
                xEy = '',
            } = {},
        } = {},
        themeConfig = {},
        modalConfig: { showModal } = {},
        plansAndSubscriptionData: {
            svodReset = false,
        } = {},
        appConfig = {},
    } = state;
    const { match, location } = props;
    const url = LocationUtil.getUrlFromProps(props);
    const routePresent = matchRoutes(getAllRouters(), url);
    const selectedRoute = routePresent?.length > 1 ? routePresent[1] : routePresent[0];
    const { isOnehubRoute = false, skipWebFeatures = false } = selectedRoute?.route || {};
    const pathName = routePresent.length > 1 ? ROUTE_SOURCE_NAMES[routePresent[1].route.path] : '';
    // const params = routePresent.length > 1 ? routePresent[1].match.params : {};
    const defaultView = pathName === EVENT_SOURCE_NAME.DTH_RECHARGE ? VIEWS.DTH_VIEW : VIEWS.DEFAULT;
    let isWebViewRoute = RoutingUtil.isWebViewRoute(location?.pathname, getAllRouters);
    const isMobile = DeviceUtil.getDeviceUtil()?.isMobile();
    if (isOnehubRoute && !isMobile) {
        isWebViewRoute = false;
    }
    const viewType = isWebViewRoute ? VIEWS.WEB_VIEW : lodashGet(LocationUtil.getQueryParams(props), 'viewType', defaultView);
    let searchParams = {};
    if (checkWindowExist()) {
        searchParams = LocationUtil.parse(window?.location?.search);
    }
    const { appId = '' } = searchParams;


    return {
        preferredPartnerPlans,
        errorPageData: notify.errorPageData,
        isAuthenticated,
        uid,
        overlay: notify.overlay,
        viewType,
        showPlayback: notify.showPlayback,
        playingOnBanner: notify.playingOnBanner,
        themeConfig,
        match,
        userConfig,
        showModal,
        email,
        ISMOBILITY: appId === 'MOBILITY',
        appConfig,
        circle,
        loginTriggered,
        prepaidBenefitDetails,
        xEy,
        isOnehubRoute,
        autoLoginInProgress,
        svodReset,
        skipWebFeatures,
        // params,
    };
}

export default withRouter(connect(mapStateToProps, {
    showModalComponentActionDispatch: showModalComponentAction,
    addBrowserHistoryDispatch: addBrowserHistory,
    popupConfigFetchDispatch: PopupConfigFetchAction,
    updateMsisdnDispatch: updateMsisdn,
    saveChannelPartnerIdDispatch: saveChannelPartnerId,
    showErrorPageDispatch: showErrorPage,
    syncContentInitDispatch: syncContentInit,
    planInitDispatch: planConfigInit,
    combinedPlanDispatch: getCombinedPlansInit,
    appStartActionDispatch: appStartAction,
    themeConfigFetchDispatch: themeConfigFetchAction,
    fetchAdsToken: adsDataInit,
    hidePlaybackDispatch: hidePlayback,
    fetchUserLocationDispatch: fetchUserLocation,
    hideRenewBannerDispatch: hideRenewBanner,
    updateNavigationInfoDispatch: updateNavigationInfo,
    getRechargeInfoDispatch: getRechargeInfo,
    hideModalComponentActionDispatch: hideModalComponentAction,
    triggerLoginDispatch: triggerLogin,
    fetchDistroDetailsDispatch: fetchDistroDetails,
    layoutFetchActionDispatch: paginatedLayoutFetchAction,
    handleEpgViewChangeDispatch: handleEpgViewChange,
    svodAllPlansDispatch: SvodAllPlansAction,
    // autoLoginWithSSODispatch: autoLoginWithSSO,
    resetAppTimerStateDispatch: resetAppTimerState,
})(CrashCaptureHOC(withStyles(styles)(App))));
