/* eslint-disable camelcase */
import lodashGet from 'lodash/get';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { StoreUtil } from '@airtel-tv/utils/StoreUtil';
import {
    SOS_EVENTS, ANALYTICS_ACTIONS, SOS_SESSION_KEYS, DEFAULT_NONE,
} from '@airtel-tv/constants/AnalyticsConst';
import { SOS_ROUTE_NAMES, _ROUTE_PATHS } from '@airtel-tv/constants/RouteConsts';
import DEVICES from '@airtel-tv/constants/DevicesConst';
import { getMatchedRoute, getPageInfoFromPageTitle } from './GlobalUtil';
import { DeviceTypeUtil } from './DeviceTypeUtil';
import { SOS_SOURCE_NAME } from '@airtel-tv/constants/EventsConst';


const ROOT_PAGE = {
    channel: 'channel',
    search: 'search',
    channels: 'channels',
    home: 'home',
    you: 'you',
    more: 'more',
    tv_shows: 'tv_shows',
    stress_test: 'stress_test',
    blogs: 'blogs',
    offers: 'offers',
    movies: 'movies',
    sports: 'sports',
};

// need a list of all root pages and it's path and it's exact string values

class SOSUtil {
    static store;

    static getSosDict() {
        return sessionStore.get(SOS_SESSION_KEYS.SOS_DICT) || {};
    }

    static setSosDict(key, value) {
        const sosObj = this.getSosDict();
        sessionStore.set(SOS_SESSION_KEYS.SOS_DICT, {
            ...sosObj,
            [key]: value,
        });
    }

    static getCounter() {
        const sosObj = this.getSosDict();
        return sosObj[SOS_SESSION_KEYS.SOS_COUNTER] || 0;
    }

    static setCounter(value) {
        this.setSosDict(SOS_SESSION_KEYS.SOS_COUNTER, value);
    }

    static getSessionSosString() {
        const sosObj = this.getSosDict();
        return sosObj[SOS_SESSION_KEYS.SOS_STRING] || '';
    }

    static setSessionSosString(value) {
        this.setSosDict(SOS_SESSION_KEYS.SOS_STRING, value);
    }

    // static removeSessionSosString() {
    //     sessionStore.remove(SOS_STRING);
    // }

    static getCounterAndUpdateSos() {
        const counter = this.getCounter() + 1;
        this.setCounter(counter);
        this.setSessionSosString('');
        return counter;
    }

    static getRouteSourceNameMappedFromCMS(source_name) {
        if (!this.store) {
            this.store = StoreUtil.getStore();
        }
        if (source_name !== ANALYTICS_ACTIONS.BLACK_HOLE) {
            const { appConfig: { sosMeta = {}, pages = [], pages_largescreen = [], web_pageId: webPageId = {}, } = {} } = this.store.getState();
            const { PYW_PAGE = 'pywPage' } = webPageId;
            const pagesObj = DeviceTypeUtil?.isWeb() ? pages : pages_largescreen;
            const matchedRoute = getMatchedRoute() || {};
            const page = getPageInfoFromPageTitle({
                pages: pagesObj,
                matchedRoute,
            });
            const { sosRouteNames = SOS_ROUTE_NAMES } = sosMeta;
            if (matchedRoute?.params?.implicitPageId === PYW_PAGE) {
                return SOS_SOURCE_NAME.PLAN_YOUR_WEEKEND;
                // return page.filter(el => el.urlName === PYW_PAGE)?.sourceName;
            }

            if (page && page.sourceName) {
                return page.sourceName;
            }
            const sosRouteNameMap = Object.entries(sosRouteNames).length
                ? sosRouteNames
                : SOS_ROUTE_NAMES;
            let { pathname, path, sourceName: sourceNameInRoutes } = matchedRoute;
            if (!pathname) {
                pathname = '/';
            }
            return sosRouteNameMap[pathname] || sosRouteNameMap[path] || sourceNameInRoutes || source_name;
        }
        return source_name;
    }

    static createSosString({ eventInfo = {}, sosRootPages = {} }) {
        const { event_type = 'None', meta: { source_name = '' } = {} } = eventInfo;
        let prefixString = '';
        const sourceName = this.getRouteSourceNameMappedFromCMS(source_name) || '';
        const rootPageDict = Object.entries(sosRootPages)?.length
            ? sosRootPages
            : ROOT_PAGE;
        const isSosRootPage = rootPageDict[sourceName.toLowerCase()]
          && event_type.toLowerCase() === ANALYTICS_ACTIONS.SCREEN_VISIBLE;

        if (isSosRootPage) {
            prefixString = this.getCounterAndUpdateSos();
        }
        else {
            prefixString = this.getSessionSosString() || this.getCounterAndUpdateSos();
        }

        const eventMeta = {
            ...eventInfo,
            meta: {
                ...eventInfo.meta,
                source_name: sourceName,
            },
        };
        const currentString = this.getCurrentEventString(eventMeta);
        const newString = `${prefixString} | ${currentString}`;
        this.setSessionSosString(newString);
        return newString;
    }

    static getCurrentEventString(eventInfo) {
        const {
            event_type,
            meta: {
                source_name,
                action,
                rail_id,
                rail_position,
                asset_position,
                asset_id,
                package_id,
                content_id,
            },
        } = eventInfo;

        return [
            source_name?.toString()?.trim() || DEFAULT_NONE,
            event_type?.toString()?.trim() || DEFAULT_NONE,
            action?.toString()?.trim() || DEFAULT_NONE,
            rail_id?.toString()?.trim() || DEFAULT_NONE,
            rail_position?.toString()?.trim() || DEFAULT_NONE,
            asset_position?.toString()?.trim() || DEFAULT_NONE,
            asset_id?.toString()?.trim() || DEFAULT_NONE,
            package_id?.toString()?.trim() || DEFAULT_NONE,
            content_id?.toString()?.trim() || DEFAULT_NONE,
        ].join('.');
    }

    static runSos(eventInfo = {}) {
        if (!this.store) {
            this.store = StoreUtil.getStore();
        }
        const { appConfig: { sosMeta = {} } = {} } = this.store.getState();
        let { event_type, meta } = eventInfo;
        if (!meta) { // Null case
            meta = {};
        }
        const { action } = meta;
        const { sosEvents = SOS_EVENTS, sosRootPages = ROOT_PAGE, sosEnabled = true } = sosMeta;
        if (!sosEnabled) {
            return '';
        }
        const sosEventsList = Object.entries(sosEvents).length ? sosEvents : SOS_EVENTS;

        const isValidEventOrAction = sosEventsList && ((event_type && sosEventsList[event_type]) || (action && sosEventsList[action]));
        if (isValidEventOrAction) {
            const newString = this.createSosString({
                eventInfo,
                sosRootPages,
            });
            return newString;
        }
        return '';
    }
}

export default SOSUtil;
