import React from 'react';
import { PropTypes } from 'prop-types';
import VIEWS from '../../../constants/ViewConst';
import Loadable from 'react-loadable';
const Loader =() => <div />
const DTHView = Loadable({
    loader: () => import('../views/DTHView' /* webpackChunkName: "DTHView" */),
    loading: Loader,
});
import WebView from '../views/WebView';
import DefaultView from '../views/DefaultView';
// import DTHView from '../views/DTHView';

const getPageView = (props) => {
    switch (props.viewType) {
        case VIEWS.WEB_VIEW:
            return <WebView {...props} />;
        case VIEWS.DTH_VIEW:
            return <DTHView {...props} />;
        default:
            return <DefaultView {...props} />;
    }
};

getPageView.propTypes = {
    viewType: PropTypes.string.isRequired,
};

export default getPageView;
