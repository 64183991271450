import React from 'react';

const FavoritesHeartSVGComponentSelected = ({
    width = "28",
    height = "24",
    heartColor="#E8EAED"
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.8758 2.126C24.5172 0.770817 22.6773 0.0087306 20.7584 0.00638817C18.8395 0.00404574 16.9977 0.761638 15.6358 2.1135L14.0008 3.63225L12.3646 2.1085C11.003 0.750756 9.15785 -0.0105012 7.23501 -0.00780536C5.31218 -0.00510951 3.46917 0.761319 2.11143 2.12287C0.753686 3.48443 -0.0075715 5.32958 -0.00487568 7.25241C-0.00217986 9.17525 0.764248 11.0183 2.1258 12.376L13.2946 23.7085C13.3876 23.803 13.4985 23.878 13.6208 23.9292C13.7432 23.9805 13.8744 24.0068 14.0071 24.0068C14.1397 24.0068 14.271 23.9805 14.3933 23.9292C14.5156 23.878 14.6265 23.803 14.7196 23.7085L25.8758 12.376C27.2345 11.0165 27.9978 9.17307 27.9978 7.251C27.9978 5.32893 27.2345 3.48551 25.8758 2.126Z" fill="#FF3144"/>
        </svg>
    )
}

export default FavoritesHeartSVGComponentSelected;
