import {
    call, put, getContext,
} from 'redux-saga/effects';
import { takeFirst } from '@airtel-tv/utils/ReduxSagaUtil';
import { getAppId } from '@airtel-tv/utils/GlobalUtil';
import {
    getBillRecharge, getPaymentModes, getBillStatus, getBillAccountDetails,
} from './apiService';
import AddToBillAction, {
    setBillRechargeResponse, getBillAccountDetailsSuccess, getBillPaymentModesSuccess, setBillPaymentStatusResponse, rechargeInProgress,
} from './actions';
import { BILL_STATUS } from '@airtel-tv/constants';
import { updateUserConfig } from '../../tv/src/modules/user/UserAction';

function* fetchBillPaymentModes(action) {
    const deviceUtil = yield getContext('deviceUtil');
    const { planId } = action;
    const appId = getAppId();
    try {
        const response = yield call(getPaymentModes, {
            appId,
            planId,
            xAtvDid: deviceUtil.getXAtvDid(),
            os: deviceUtil.getBrowserType(),
        });
        yield put(getBillPaymentModesSuccess(response));
    }
    catch (e) {
        console.error(e);
    }
}

function* fetchBillAccountDetails(action) {
    const deviceUtil = yield getContext('deviceUtil');
    const { planId } = action;
    const appId = getAppId();
    try {
        const response = yield call(getBillAccountDetails, {
            appId,
            planId,
            xAtvDid: deviceUtil.getXAtvDid(),
            os: deviceUtil.getBrowserType(),
        });
        yield put(getBillAccountDetailsSuccess(response));
    }
    catch (e) {
        console.error(e);
    }
}


async function mockRechargeStatus() {
    let transactionStatus = 'INPROGRESS';
    let localState = localStorage.getItem('custm_001_state');
    if (localState) {
        localState = JSON.parse(localState);
        transactionStatus = localState?.paymentStatus || 'INPROGRESS';
    }
    return {
        uid: '12fefvefe12',
        transactionStatus,
    };
}

function* getRechargeStatus(action) {
    const deviceUtil = yield getContext('deviceUtil');
    const { planId, tid } = action;
    const appId = getAppId();
    const method = localStorage.getItem('custm_001_state') ? mockRechargeStatus : getBillStatus;
    try {
        //getBillStatus
        //const response = yield call(getBillStatus, {
        const response = yield call(method, {
            appId,
            planId,
            tid,
            xAtvDid: deviceUtil.getXAtvDid(),
            os: deviceUtil.getBrowserType(),
        });
        const { transactionStatus } = response;
        if (transactionStatus === BILL_STATUS.SUCCESS || transactionStatus === BILL_STATUS.FAILURE || transactionStatus === BILL_STATUS.FAILED) {
            yield put(rechargeInProgress({ status: false }));
        }
        if (transactionStatus === BILL_STATUS.SUCCESS) {
            yield put(updateUserConfig());
        }
        yield put(setBillPaymentStatusResponse(response));
    }
    catch (e) {
        console.error(e);
    }
}

async function mockGetRecharge() {
    let transactionStatus = 'INPROGRESS';
    let localState = localStorage.getItem('custm_001_state');
    if (localState) {
        localState = JSON.parse(localState);
        transactionStatus = localState?.billCharge || 'INPROGRESS';
    }
    return {
        rid: '12fefvefe12',
        data: {
            tid: '868685',
            transactionStatus,
            expiry: 122323,
        },
        success: true,
    };
}

function* startBillRecharge(action) {
    const deviceUtil = yield getContext('deviceUtil');
    const { planId, si, lob } = action;
    const appId = getAppId();
    yield put(rechargeInProgress({ status: true }));
    const method = localStorage.getItem('custm_001_state') ? mockGetRecharge : getBillRecharge;
    try {
        //getBillRecharge
        //const response = yield call(getBillRecharge, {
        const response = yield call(method, {
            appId,
            planId,
            si,
            lob,
            xAtvDid: deviceUtil.getXAtvDid(),
            os: deviceUtil.getBrowserType(),
        });
        const { transactionStatus } = response?.data || {};
        if (transactionStatus === BILL_STATUS.SUCCESS || transactionStatus === BILL_STATUS.FAILURE || transactionStatus === BILL_STATUS.FAILED) {
            yield put(rechargeInProgress({ status: false }));
        }
        if (transactionStatus === BILL_STATUS.SUCCESS) {
            yield put(updateUserConfig());
        }
        yield put(setBillRechargeResponse(response));
    }
    catch (e) {
        console.log(e);
    }
}


export default [
    takeFirst(AddToBillAction.GET_PAYMENT_MODES, fetchBillPaymentModes),
    takeFirst(AddToBillAction.GET_BILL_ACCOUNT_DETAILS, fetchBillAccountDetails),
    takeFirst(AddToBillAction.BILL_RECHARGE, startBillRecharge),
    takeFirst(AddToBillAction.GET_RECHARGE_STATUS, getRechargeStatus),
];
