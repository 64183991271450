import React, { useEffect } from 'react';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants';

const usePayLater = (params) => {
    const { userPaymentModes, isAuthenticated } = params;
    useEffect(() => {
        if (!isAuthenticated) {
            sessionStore.remove(LOCAL_STORE_KEYS.PAY_LATER_USER);
        }
        else if (userPaymentModes?.payLater) {
            sessionStore.set(LOCAL_STORE_KEYS.PAY_LATER_USER, true);
        }
        else if (sessionStore.get(LOCAL_STORE_KEYS.PAY_LATER_USER)) {
            sessionStore.remove(LOCAL_STORE_KEYS.PAY_LATER_USER);
        }
    }, [
        userPaymentModes,
        isAuthenticated,
    ]);

    return null;
};


export default usePayLater;
