import React, { useEffect, useState } from 'react';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import { contentVisibleEvent, nudgeVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { getSourceNameFromStorage } from '@airtel-tv/utils/GlobalUtil';
import { connect } from 'react-redux';

import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import DownloadAppNudgeSVGComponent from '../../svg/DownloadAppNudgeSVGComponent';
import LazyLottieFactory, { LOTTIE_MAP } from '../lottie/LottieFactory';


const DownloadAirtelNudge = (props) => {
    const {
        pageId, classes, isErrorPageShown, isInternetConnected, deepLinkConfig, ingressName, showLottie = false, matchRoute, location,
    } = props;

    const { prevSource } = getSourceNameFromStorage();

    const analyticsContentVisible = {
        page_id: pageId,
        popup_id: 'download_nudge',
        asset_name: 'Download Xstream Play Button',
        source_name: 'mweb_homepage',
        source_page: prevSource,
    };

    const buttonClickMeta = {
        page_id: pageId,
        action: 'download_nudge_click',
        popup_id: 'download_nudge',
        asset_name: 'download_xstream_play_button',
        source_name: 'mweb_homepage',
        source_page: prevSource,
    };

    useEffect(() => {
        nudgeVisibleEvent(analyticsContentVisible);
    }, []);

    const allowedRoutesToShowNudge = !([
        ROUTE_PATHS.SEARCH,
        ROUTE_PATHS.SETTINGS_LANGUAGE_SECTION,
    ].includes(location.pathname));

    const showNudge = !isErrorPageShown && isInternetConnected && allowedRoutesToShowNudge;

    return showNudge ? (
        <div className={`nudge-holder ${classes}`}>
            <AnalyticsButtonComponent
                meta={buttonClickMeta}
                onClick={() => {
                    ScopedWebviewUtil.navigateToDeepLinkOnCta(deepLinkConfig, ingressName);
                }}
            >
                {!showLottie ? (
                    <>
                        <div className="app-nudge">
                            <DownloadAppNudgeSVGComponent />
                            Download Xstream Play
                        </div>

                    </>
                )
                    : (
                        <LazyLottieFactory
                            lottieName={LOTTIE_MAP.DOWNLOAD_POPUP_LOTTIE}
                            loop
                            autoplay
                            customStyleObj={{}}
                            containerStyleObj={{

                            }}
                        />
                    )
                }
            </AnalyticsButtonComponent>
        </div>
    ) : null;
};

function mapStateToProps(state, props) {
    const { notify: { isErrorPageShown, isInternetConnected }, appConfig: { deepLinkConfig } } = state;

    return {
        isErrorPageShown,
        isInternetConnected,
        deepLinkConfig,
    };
}

export default connect(mapStateToProps, {})(withRouter(DownloadAirtelNudge));
