import { DeviceTypeUtil } from './DeviceTypeUtil';
import {
    ContentImageProvider, getPosterUrlFromContentDetails as getPosterUrlFromContentDetailsWeb, getImageFromChannelDetails as getImageFromChannelDetailsWeb, ContentImageProvider as ContentImageProviderLS, getPosterUrlFromContentDetails as getPosterUrlFromContentDetailsLS, getImageFromChannelDetails as getImageFromChannelDetailsLS,
} from './ContentImageProviderWeb';

const isWeb = DeviceTypeUtil.isWeb();


const getPosterUrlFromContentDetails = isWeb ? getPosterUrlFromContentDetailsWeb : getPosterUrlFromContentDetailsLS;

const getImageFromChannelDetails = isWeb ? getImageFromChannelDetailsWeb : getImageFromChannelDetailsLS;


export {
    ContentImageProvider,
    getPosterUrlFromContentDetails,
    getImageFromChannelDetails,
};
