import React, {useEffect, useRef} from 'react';

/*
Runs effect only on subsequuest updates
and not on mount
*/

const useUpdateCustomHook = (callback, dependencies) => {
    const didMount = useRef(true);

    useEffect(() => {
        if (didMount.current) {
            didMount.current = false;
        }
        else {
            return callback();
        }
    }, dependencies);
};

export { useUpdateCustomHook };
