import RequestUtil from '@airtel-tv/utils/RequestUtil';
import env from '../../config';
import {AuthenticationUtil} from '@airtel-tv/utils/AuthenticationUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { removeFalsy } from '@airtel-tv/utils/GlobalUtil';

const { base, paths } = env.endpoints.contentApi;

export const getContentDetails = async ({ contentId, deviceUtil }) => {
    const url = `${base}${paths.detailedContent}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    return RequestUtil.GET({
        url,
        params: {
            id: contentId,
            appId: env.appId,
        },
        headers: {
            'x-atv-did': deviceUtil.getXAtvDid(),
            'x-atv-token': utkn,
            'x-atv-utkn': utkn,
        },
    });
};

export const getSeriesDetails = async ({ contentId, deviceUtil }) => {
    const url = `${base}${paths.detailedContent}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url,
        params: {
            id: contentId,
            appId: env.appId,
        },
        headers: removeFalsy(headers),
    });
};

export const getepisodeTabs = async ({ seasonId, tab, deviceUtil }) => {
    const url = `${base}${paths.detailedContent}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url,
        params: {
            id: seasonId,
            appId: env.appId,
            tab,
        },
        headers: removeFalsy(headers),
    });
};

export const getTabDetails = async ({ seasonId, deviceUtil }) => {
    const url = `${base}${paths.detailedContent}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url,
        params: {
            id: seasonId,
            appId: env.appId,
        },
        headers: removeFalsy(headers),
    });
};

export const getEventDetails = async ({ eventId, deviceUtil }) => {
    const url = `${base}${paths.event}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    return RequestUtil.GET({
        url,
        params: {
            id: eventId,
            appId: env.appId,
        },
        headers: {
            'x-atv-did': deviceUtil.getXAtvDid(),
            'x-atv-token': utkn,
            'x-atv-utkn': utkn,
        },
    });
};

export default {
    getContentDetails,
    getSeriesDetails,
};
