import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import { planPurchaseRequest, planPurchaseUtkn } from '@airtel-tv/analytics/FunctionalEvents';
import { removeFalsy } from '../../utilities/CommonUtil';
import env from '../../config';

const { base, paths } = env.endpoints.userApi;

export const purchaseRedirectionAPI = ({
    deviceUtil, successUrl = '', failureUrl = '', more = false, planId, pendingUrl = '', unknownUrl = '',
    ingressIntent = '', trialOpted = '',
    disableAutoRenew = false,
    isRechargePlan = false,
}) => {
    const searchParams = LocationUtil.parse(window.location.search);

    const appIdFromQuery = searchParams.appId;
    const appIdFromLocal = AuthenticationUtil.getAppId();
    const params = removeFalsy({
        appId: appIdFromQuery || appIdFromLocal || env.appId,
    });

    const data = removeFalsy({
        failureUrl,
        more,
        planId,
        successUrl,
        pendingUrl,
        unknownUrl,
        autoPayEnabled: !disableAutoRenew,
        ingressIntent,
        trialOpted,
        isRechargePlan,
    });

    const url = `${paths.purchase}?${LocationUtil.objectToQueryParams(params)}`;
    const method = 'POST';
    const utknData = {
        urlBody: `${method}/${url}${JSON.stringify(data)}`,
        token: searchParams.token || AuthenticationUtil.getAuthToken(),
        uid: searchParams.uid || AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const xtvDidFromLocal = deviceUtil.getXAtvDidFromLocal();
    const headers = {
        'x-atv-did': xtvDidFromLocal || deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'Content-Type': 'application/json',
    };
    try {
        planPurchaseUtkn({
            response: {
                data,
                utknData,
                utkn,
                headers,
                appIdFromQuery,
                appIdFromLocal,
                searchParams,
                searchUID: searchParams.uid,
                localUID: AuthenticationUtil.getUid(),
                search: window.location.search,
            },
            uid: searchParams.uid || AuthenticationUtil.getUid(),
        });
    }
    catch (e) {
        console.error(e);
    }

    const body = {
        url: base + url,
        headers: removeFalsy(headers),
        data,
    };
    planPurchaseRequest({ ...body });
    return RequestUtil.POST(body);
};

export const checkRechargePaymentStatusAPI = ({ pgId }) => {
    const url = `https://paydigi.airtel.in/web/pg-service/v1/payment/status/pgId/${pgId}`;
    const body = {
        url,
    };
    return RequestUtil.GET(body);
};

export default {
    purchaseRedirectionAPI,
    checkRechargePaymentStatusAPI,
};
