import RequestUtil from '@airtel-tv/utils/RequestUtil';
import env from '../../config';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { removeFalsy } from '@airtel-tv/utils/GlobalUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import { SEARCH_CONFIG, DIMENSIONS_FOR_CALL_MAP } from '@airtel-tv/constants/GlobalConst';

const { base, paths } = env.endpoints.searchApi;

export const searchByPaginatedQuery = ({
    userContentProperties = {},
    lang = '',
    count = 0,
    superType = '',
    offSet = SEARCH_CONFIG.DEFAULT_OFFSET,
    appId = '',
    sortBy,
    query,
    more = '',
    deviceUtil,
}) => {
    const queryParams = removeFalsy({
        ...userContentProperties,
        lang,
        count,
        superType,
        offSet,
        appId,
        more,
        sort: sortBy,
        q: query,
    });

    const method = 'GET';


    const queryParamsString = LocationUtil.objectToQueryParams(queryParams);

    const url = `${base}${paths.searchByPaginatedQuery}?${queryParamsString}`;

    const utknData = {
        urlBody: `${method}/${paths.searchByPaginatedQuery}?${queryParamsString}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'Content-Type': 'application/json',
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url,
        headers: removeFalsy(headers),
    });
};


export const searchAllContentByQuery = ({
    ln = '',
    bn = '',
    os = '',
    mwTvPack = '',
    dt = '',
    appId = '',
    query,
}) => {
    const url = `${base}${paths.searchByPaginatedQuery}`;
    const method = 'GET';

    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const deviceUtil = DeviceUtil.getDeviceUtil();
    const headers = {
        'Content-Type': 'application/json',
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url,
        params: {
            ln,
            bn,
            os,
            mwTvPack,
            dt,
            appId,
            more: true,
            q: query,
        },

        headers: removeFalsy(headers),
    });
};

export const searchSuggestions = ({
    appId = '',
    query,
    maxCount,
    xAtvDid,
    userContentProperties,
}) => {
    const url = `${base}${paths.searchSuggestions}`;
    const method = 'GET';

    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'Content-Type': 'application/json',
        'x-atv-did': xAtvDid,
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url,
        params: {
            appId,
            q: query,
            autosuggest_max_limit: maxCount,
            ...userContentProperties,
        },
        headers: removeFalsy(headers),
    });
};

export const searchVideoRelatedContent = ({
    userContentProperties = {},
    contentId = '',
    appId = '',
    type = '',
    xAtvDid = '',
}) => {
    const queryParams = removeFalsy({
        ...userContentProperties,
        contentId,
        appId,
        type,
    });
    const queryParamsString = LocationUtil.objectToQueryParams(queryParams);
    const url = `${base}${paths.contentRelatedItems}?${queryParamsString}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${paths.contentRelatedItems}?${queryParamsString}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'Content-Type': 'application/json',
        'x-atv-did': xAtvDid,
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url,
        headers: removeFalsy(headers),
    });
};


export const searchPeopleRelatedContent = ({
    userContentProperties = {},
    creditRef = '',
    appId = '',
    type = '',
    xAtvDid = '',
}) => RequestUtil.GET({
    url: `${base}${paths.peopleRelatedItems}`,
    params: {
        ...userContentProperties,
        creditRef,
        appId,
        type,
    },
    headers: {
        'Content-Type': 'application/json',
        'x-atv-did': xAtvDid,
    },
});

export const getDimensionPageData = ({
    lang = '',
    count = 0,
    userContentProperties = {},
    offset = 0,
    appId = '',
    deviceUtil,
    cast = '',
    genres = '',
    ct = '',
    recent = '',
    alphabetical = '',
}) => {
    const queryParams = removeFalsy({
        ...userContentProperties,
        lang,
        count,
        offset,
        appId,
        cast,
        genres,
        ct: DIMENSIONS_FOR_CALL_MAP[ct],
        recent,
        alphabetical,
    });

    const queryParamsString = LocationUtil.objectToQueryParams(queryParams);
    const url = `${base}${paths.dimensionPage}?${queryParamsString}`;

    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'Content-Type': 'application/json',
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url,
        headers: removeFalsy(headers),
    });
};

export const getGenreListData = ({
    ct,
    lang,
    deviceUtil,
    appId,
    userContentProperties,
}) => {
    const queryParams = removeFalsy({
        ...userContentProperties,
        lang,
        ct: DIMENSIONS_FOR_CALL_MAP[ct],
        appId,
    });
    const queryParamsString = LocationUtil.objectToQueryParams(queryParams);
    const url = `${base}${paths.genreList}?${queryParamsString}`;

    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'Content-Type': 'application/json',
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url,
        headers: removeFalsy(headers),
    });
};
