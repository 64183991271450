import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './CustomIconComponent.scss';
import FreeBottomIcon from '../../atoms/icons/components/FreeBottomIcon';
import ChannelsIcon from '../../atoms/icons/components/ChannelsIcon';
import FreeText from '../../atoms/icons/components/FreeText';

const CustomIconComponent = ({ IconName }) => {
    useStyles(styles); useStyles(styles);
    const renderContent = React.useCallback(() => {
        switch (IconName) {
            case 'SEARCH':
                return <div className="search" />;

            case 'LANGUAGE':
                return <div className="language" />;

            case 'PROFILE':
                return <div className="profile" />;

            case 'HOME':
                return (
                    <div className="home dull">
                        <span className="home-head" />
                        <span className="home-door" />
                        <span className="icons-text">Home</span>
                    </div>
                );
            case 'Footer-Profile':
                return (
                    <div className="footer-profile dull">
                        <span className="icons-text">You</span>
                    </div>
                );
            case 'Footer-Search':
                return (
                    <div className="search dull">
                        <span className="icons-text">Search</span>
                    </div>
                );
            case 'Footer-Menu':
                return (
                    <div className="menu dull">
                        <div className="line" />
                        <div className="line" />
                        <div className="line" />
                        <span className="icons-text">Menu</span>
                    </div>
                );
            case 'Free-Content':
                return (
                    <>
                        <div className="menu dull">
                            <FreeBottomIcon />
                        </div>
                        <span className="icons-text"><FreeText /></span>
                    </>
                );
            case 'Channel':
                return (
                    <>
                        <div className="menu dull">
                            <ChannelsIcon />
                        </div>
                    </>
                );

            default:
                return null;
        }
    }, [
        IconName,
    ]);

    return (
        <div className="container-holder">
            {renderContent()}
        </div>
    );
};

export default CustomIconComponent;
