
import {RoutingUtil} from '@airtel-tv/utils';
import { MARKUP_CONTEXT, MARKUP_TYPES } from '../../../../constants/MarkupConsts';

export default function LiveTVMarkupParser({
    contentDetails,
    baseUrl,
}) {
    const {
        title, description, images, programType, id,
    } = contentDetails;
    const canonicalUrl = RoutingUtil.getContentPlaybackUrlWeb({
        category: programType,
        contentTitle: title,
        contentId: id,
    });
    const embedUrl = baseUrl + canonicalUrl;
    const imageObject = images && Object.values(images);

    return {
        '@context': MARKUP_CONTEXT.URL,
        '@id': embedUrl,
        '@type': MARKUP_TYPES.LIVETV,
        name: title,
        url: embedUrl,
        description: description || title,
        image: imageObject.length ? imageObject[0] : null,
    };
}
