import React from 'react';
import PropTypes from 'prop-types';

const ProgressBarComponent = (props) => {
    const { progressPercentage, showProgressBar } = props;

    const progressBar = showProgressBar ? (
        <div className="cont-progress">
            <span className="cont-progress-bar">
                <span
                    role="presentation"
                    className="progress-completed"
                    style={{ width: `${progressPercentage}%` }}
                />
            </span>
        </div>
    )
        : null;
    return progressBar;
};

ProgressBarComponent.defaultProps = {
    progressPercentage: null,
    showProgressBar: false,
};

ProgressBarComponent.propTypes = {
    progressPercentage: PropTypes.number,
    showProgressBar: PropTypes.bool,
};

export { ProgressBarComponent };
