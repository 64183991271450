import jsSha256 from 'js-sha256';
import CryptoJS from 'crypto-js';

export class CryptoUtil {
    static HmacSHA1Encryption = ({ key, value }) => {
        const encrypted = CryptoJS.HmacSHA1(value, key);
        return CryptoJS.enc.Base64.stringify(encrypted);
    };

    static getUtkn = ({ urlBody, token, uid }) => {
        if (!token) {
            return '';
        }

        const encrypted = CryptoJS.HmacSHA1(urlBody, token);
        return `${uid}:${CryptoJS.enc.Base64.stringify(encrypted)}`;
    };

    static Sha256Encryption = ({ key, value }) => jsSha256.hmac(key, value);

    static AESDecrypt = ({ key, value }) => {
        try {
            const k = CryptoJS.enc.Utf8.parse(key);
            const decryptedData = CryptoJS.AES.decrypt(value, k, { mode: CryptoJS.mode.ECB });
            return decryptedData.toString(CryptoJS.enc.Utf8);
        }
        catch (error) {
            console.error(error);
            return null;
        }
    };

    static AESEncrypt = ({ key, value }) => {
        try {
            const k = CryptoJS.enc.Utf8.parse(key);
            const decryptedData = CryptoJS.AES.encrypt(value, k, { mode: CryptoJS.mode.ECB }).toString();
            return decryptedData;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    };

    static AESDecryptMsdin = ({ key, value }) => {
        try {
            const decryptionKey = key.substring(0, 16);
            const parsedDecryptionKey = CryptoJS.enc.Utf8.parse(decryptionKey);

            const decryptedData = CryptoJS.AES.decrypt(value, parsedDecryptionKey, { mode: CryptoJS.mode.ECB });
            return decryptedData.toString(CryptoJS.enc.Utf8);
        }
        catch (error) {
            console.error(error);
            return null;
        }
    };

    static hashString = (str) => {
        const hash = CryptoJS.MD5(str);
        return hash.toString(CryptoJS.enc.Hex);
    };
}

export default CryptoUtil;
