import React from 'react';
import { PropTypes } from 'prop-types';
import { BROWSER_LIST } from '@airtel-tv/constants/BrowserConst';
import {
    exitPictureInPicture, closePip, checkIfPlayInPip, checkFullScreen,
} from '@airtel-tv/utils/WindowUtil';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';
import BlinkerComponent from '../../../shared/components/svg/BlinkerComponent';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';


class PipComponent extends React.PureComponent {
    browser = null;

    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
    }


    componentWillUnmount() {
        if (checkIfPlayInPip()) {
            closePip(this.browser, this.videoElement);
        }
    }

    videoElement = () => null;

    togglePipSafari = () => {
        const { toggleFullScreen } = this.props;
        if (checkFullScreen() && this.videoElement.webkitPresentationMode === 'inline') {
            toggleFullScreen(false);
        }
        this.videoElement.webkitSetPresentationMode(this.videoElement.webkitPresentationMode === 'picture-in-picture' ? 'inline' : 'picture-in-picture');
    };

    togglePipChrome = async () => {
        const { toggleFullScreen } = this.props;
        if (checkIfPlayInPip()) {
            try {
                return await exitPictureInPicture();
            }
            catch (error) {
                console.error(error);
                return false;
            }
        }
        else {
            // show pip
            try {
                if (checkFullScreen()) {
                    toggleFullScreen(false);
                }

                return await this.videoElement.requestPictureInPicture();
            }
            catch (error) {
                console.error(error);
                return false;
            }
        }
    };

    togglePip = () => {
        switch (this.browser) {
            case BROWSER_LIST.CHROME:
                return this.togglePipChrome();

            case BROWSER_LIST.SAFARI:
                return this.togglePipSafari();

            default:
                return false;
        }
    };


    render() {
        const { getVideoTag } = this.props;
        this.browser = this.deviceUtil.getBrowserName();

        this.videoElement = getVideoTag();

        if (!this.videoElement || !this.browser) {
            return null;
        }

        return (
            <div className="vdo-player__options d-md-block">
                <AnalyticsButtonComponent
                    onClick={this.togglePip}
                    type="button"
                    className="pip-button"
                    id="atm_pip-button"
                    aria-label="Picture in Picture button"
                >
                    {/* <i className="player-control-icon-size icon-player-picture" /> */}

                    <div className="pip-blinker-container">
                        <BlinkerComponent
                            viewBoxLength={55}
                            delay={1.5}
                            color="#ED2024"
                            iteration={3}
                            opacityInit={0.5}
                            duration={3}
                            radiusFrom={12}
                            radiusTo={27.5}
                            opacityFrom={0.5}
                            opacityTo={0}
                        />
                    </div>

                    <div className="pip-icon">
                        <ThumborImage
                            className="pip-img"
                            src={IMAGE_PATHS.PIP_ICON}
                        />
                    </div>
                </AnalyticsButtonComponent>
            </div>
        );
    }
}

PipComponent.propTypes = {
    getVideoTag: PropTypes.func.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    toggleFullScreen: PropTypes.func.isRequired,
};

export default withDeviceUtil(PipComponent);
