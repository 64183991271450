import axios from 'axios';
import { REQUEST_METHODS } from '@airtel-tv/constants/GlobalConst';

// TODO: Do not Delete.Used in Server side rendering.Other wise response will be truncated in server side.
if (typeof window === 'undefined') {
    axios.defaults.headers['Accept-Encoding'] = 'gzip, deflate, br';
}

/**
 * Request Wrapper with default success/error actions
 */
const request = (options) => {
    const onSuccess = response => response.data;
    const onError = (error) => {
        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error(error.response);
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
        }
        else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return axios(options)
        .then(onSuccess)
        .catch(onError);
};

export const requestParllel = async (collection) => {
    const requests = collection.map(options => request(options).catch(er => er));
    return axios.all(requests);
};

export default {
    ALL: axios.all,

    GET: ({
        url, params, headers, options,
    }) => request({
        url,
        method: REQUEST_METHODS.GET,
        params,
        headers,
        ...options,
    }),
    POST: ({
        url, params, headers, data, options,
    }) => request({
        url,
        method: REQUEST_METHODS.POST,
        params,
        headers,
        data,
        ...options,
    }),
    PATCH: ({
        url, params, headers, data, options,
    }) => request({
        url,
        method: REQUEST_METHODS.PATCH,
        params,
        headers,
        data,
        ...options,
    }),
    DELETE: ({
        url, params, headers, data, options,
    }) => request({
        url,
        method: REQUEST_METHODS.DELETE,
        params,
        headers,
        data,
        ...options,
    }),
    PUT: ({
        url, params, headers, data, options,
    }) => request({
        url,
        method: REQUEST_METHODS.PUT,
        params,
        headers,
        data,
        ...options,
    }),
};
