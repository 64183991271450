import RequestUtil from '@airtel-tv/utils/RequestUtil';
import env from '../../config';

const { base, paths } = env?.endpoints?.blogApi;

export const getBlogPosts = async ({ postId }) => {
    const url = `${base}${paths.fetchBlogs}`;

    return await RequestUtil.GET({
        url,
        params: { 
            postId 
        }
    });
};

export const getBlogCategories = async () => {
    const url = `${base}${paths.fetchCategories}`;

    return RequestUtil.GET({
        url,
    });
};
