import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { setRailScrollTopAddition } from '@airtel-feature/layout/actions/LayoutActions';
import { connect } from 'react-redux';
import { AnalyticsButtonComponent, AnalyticsLinkComponent } from '@airtel-tv/analytics';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import {
    ANALYTICS_ACTIONS,
    ONEHUB_INGRESS_INTENT,
} from '@airtel-tv/constants';
import { RoutingUtil, withDeviceUtil } from '@airtel-tv/utils';
import AirtelXstreamSVGComponent from '@airtel-tv/ui-lib/svg/AirtelXstreamSVGComponent';
import withStyles from 'isomorphic-style-loader/withStyles';
import { bannerVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import styles from './PendingActivationRail.scss';

const imageSettings = {
    1000: 600,
    500: 500,
    300: 200,
};

const PendingActivationRail = (props) => {
    const {
        tileProps, pendingActivationCps, cpDetailsById, deviceUtil: { isMobile } = {},
    } = props;
    const {
        tileTitle: title,
        tileBgColor,
        tileCtaText,
        tileDescriptionColor,
        pageId,
        railPosition,
        tilePosition = 0,
        onFocus,
        onKeyDown,
        nonQuickViewTile,
        onKeyUp,
        focusProps,
        contentDetails,
        analyticsPageId,
        style,
        zionTileId = '',
        railType,
        packageId,
        sosAnalyticsMeta,
        currentSource,
        prevSource,
        railId,
        tileType,
    } = tileProps;
    const commonAnalyticsMeta = {
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        package_id: packageId,
        source_name: currentSource,
        source_page: prevSource,
        page_id: analyticsPageId,
        rail_type: railType,
        tile_tag: 'none',
        tileId: zionTileId || 'none',
        program_type: '',
        asset_name: tileCtaText,
        cp_name: pendingActivationCps[0],
        channel_list: pendingActivationCps.join('||'),
        ...sosAnalyticsMeta,
    };

    const tileId = `${pageId}-tile-${railPosition}-${tilePosition}`;
    useEffect(() => {
        const meta = {
            ...commonAnalyticsMeta,
            asset_name: 'cp_activation',
        };
        bannerVisibleEvent(meta);
    }, []);

    const link = pendingActivationCps.length > 1 ? RoutingUtil.getOneHubPageCDP({
        ingressIntent: ONEHUB_INGRESS_INTENT.ACTIVATE,
        cpId: null,
    }) : RoutingUtil.getCPActivationPage({ cpId: pendingActivationCps?.[0] });

    let tileTitle = title;
    if (pendingActivationCps?.length > 1) {
        tileTitle = title?.replace('ubscription', 'ubscriptions');
    }

    const pendingCpLogo = pendingActivationCps.filter((value, index, array) => array.indexOf(value) === index);

    return (
        <AnalyticsLinkComponent
            style={{
                background: tileBgColor,
                ...style,
            }}
            id={tileId}
            className={`pending-activation-rail ${FocusManagementClassNames.CAN_FOCUS}  unfocusable  railPosition-${railPosition} tilePosition-${tilePosition} ${pageId}-${tileId} `}
            meta={{
                action: ANALYTICS_ACTIONS.TILE_CLICK,
                ...commonAnalyticsMeta,
            }}
            to={link}
            onFocus={(e) => {
                if (typeof onFocus === 'function') {
                    onFocus({
                        e,
                        railPosition,
                        tilePosition,
                        tileDetails: {
                            ...contentDetails,
                            nonQuickViewTile,
                        },
                    });
                }
            }}
            onKeyDown={(event) => {
                if (typeof onKeyDown === 'function') {
                    onKeyDown({
                        event,
                        tilePosition,
                    });
                }
            }}
            onKeyUp={(event) => {
                if (onKeyUp && typeof onKeyUp === 'function') {
                    onKeyUp({ event });
                }
            }}
            tilePosition={tilePosition}
            railPosition={railPosition}
            {...focusProps}
        >
            <div className="pending-activation-wrap">
                {/* <div className="identity-logo-wrap">
                    <AirtelXstreamSVGComponent
                        className="identity-logo"
                    />
                    <p className="text-logo text-22 mobile-text-12 text-bold">OneHub</p>
                </div> */}
                <div
                    className="title mobile-text-14 mobile-line-height-20 text-ls-24 text-bold line-height-ls-40 text-20 line-height-32 text-ls-24 line-height-ls-32 font-normal"
                    style={{ color: tileDescriptionColor }}
                >
                    {tileTitle}
                </div>

                <AnalyticsButtonComponent
                    className=" common-white-btn primary-cta mobile-text-14 mobile-line-height-20 text-ls-22 text-bold"
                >
                    {tileCtaText}
                </AnalyticsButtonComponent>
            </div>

            {isMobile() && pendingCpLogo?.length > 1 ? null : (
                <div className="partner-images">
                    {pendingCpLogo.map(cp => (
                        <ThumborImage
                            className="partner-img"
                            imageSettings={imageSettings}
                            src={cpDetailsById?.[cp]?.cpIconURL || ''}
                        />
                    ))}
                </div>
            )}
        </AnalyticsLinkComponent>
    );
};

PendingActivationRail.propTypes = {
    pendingActivationCps: PropTypes.array.isRequired,
    cpDetailsById: PropTypes.object.isRequired,
    setRailScrollTopAdditionDisptach: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
    const {
        plansAndSubscriptionData: {
            svodPlans: {
                all_plans: {
                    planClaimStatusClient = {},
                } = {},
            } = {},
        } = {},
        appConfig: {
            cpDetailsById,
        } = {},
    } = state;
    // const pendingActivationCps = [
    //     'ZEEFIVE',
    //     'SONYLIV_VOD',
    //     'SUNNXT',
    // ];

    return {
        cpDetailsById,
        pendingActivationCps: (planClaimStatusClient?.PENDING_ACTIVATION || []).map(item => item?.partner?.cpId),
        planClaimStatusClient,
    };
}


export default connect(mapStateToProps, {
    setRailScrollTopAdditionDisptach: setRailScrollTopAddition,
})(withRouter(withStyles(styles)(withDeviceUtil(PendingActivationRail))));
