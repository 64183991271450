import { checkWindowExist } from './WindowUtil';

let Moengage = {
    track_event: () => {},
    add_unique_user_id: () => {},
    add_email: () => {},
    add_user_attribute :() => {},
};

export const moengaeInit = (config) => {
    if (checkWindowExist() &&  window.moe) {
        Moengage = window.moe({ ...config });
    }
};

export const getMoengage = () => Moengage;
