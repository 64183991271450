import browserStore from "@airtel-tv/utils/BrowserStoreUtil";
import ChannelPartnerActions from "./ChannelPartnerActions";
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';


const initialState = JSON.parse(`{
    "cpId": ""
}`);

function removeCpId() {
    browserStore.remove(LOCAL_STORE_KEYS.CHANNEL_PARTNER_ID);
}

function saveCpId(cpid) {
    browserStore.set(LOCAL_STORE_KEYS.CHANNEL_PARTNER_ID, cpid);
}

const ChannelPartnerReducer = (state = initialState, action = {}) => {
    let changes = {};
    switch (action.type) {
        case ChannelPartnerActions.SAVE_CHANNEL_PARTNER_ID: 
            saveCpId(action.cpid);
            changes = {
                cpId: action.cpId
            };
            break;
        case ChannelPartnerActions.REMOVE_CHANNEL_PARTNER_ID: 
            removeCpId();
            changes = {
                cpId: ''
            };
            break;
        default:
            return state;
    }
    return {
        ...state,
        ...changes,
    };
};

export default ChannelPartnerReducer;