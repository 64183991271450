import { BreadCrumbsActions } from '../actions/BreadCrumbsActions';

const initialState = JSON.parse('{ "breadCrumbsList" : [[]]}');

const BreadCrumbsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case BreadCrumbsActions.UPDATE_BREADCRUMBS_COMPLETED:
            return action.payload;
        default:
            return state;
    }
};

export default BreadCrumbsReducer;
