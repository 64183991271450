import CryptoJS from 'crypto-js';
import { AppEnv } from '@airtel-tv/utils/GetEnv';

import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { getAppId, removeFalsy } from '@airtel-tv/utils/GlobalUtil';
import { ANALYTICS_CATEGORY, REQUEST_METHODS } from '@airtel-tv/constants/GlobalConst';
import ApiAnalytics from '@airtel-feature/playback/helpers/ApiAnalytics';

const env = AppEnv.getEnv();

export const getPlayback = ({
    contentId, uid, playType, customerType, atvSegment, accessToken, token, deviceUtil, apiServiceParams = {}, appIdOverride = '', byPassAcessToken = false
}) => {
    const { base, paths } = env.endpoints.playbackApi;

    const appId = appIdOverride || getAppId();
    const apiParams = removeFalsy(apiServiceParams);

    const queryParams = {
        contentId,
        appId,
        ...apiParams,
    };

    const queryParamsString = LocationUtil.objectToQueryParams(queryParams);
    const method = REQUEST_METHODS.GET;
    const utknData = {
        urlBody: `${method}/${paths.getPlayback}?${queryParamsString}`,
        token,
        uid,
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const atvDid = deviceUtil.getXAtvDid();
    const url = `${base}${paths.getPlayback}?${queryParamsString}`;

    let fancodeF1RedirectionToken = '';
    if (byPassAcessToken) {
        fancodeF1RedirectionToken = {
            urlBody: `${method}/${paths.getPlayback}?${queryParamsString}`,
            token: '12y7ansdlKJh891ewdq98yh9huoHO89G6OufghsdcbH781',
            uid,
        }
        fancodeF1RedirectionToken = CryptoUtil.getUtkn(fancodeF1RedirectionToken);
    }

    const xAtvToken = `${method}/${paths.getPlayback}?${queryParamsString}${accessToken}`;
    const xAtvSAuthSignature = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA1(xAtvToken, token));
    const headers = {
        'x-atv-utkn': utkn,
        'x-atv-did': atvDid,
        'x-atv-segment': atvSegment,
        'x-atv-customer': customerType,
        'play-type': playType,
        'content-type': 'application/json',
        'x-atv-auth': xAtvSAuthSignature,
        'request-init-time': true,
        'x-atv-stkn': accessToken,
        ...(byPassAcessToken && { fancodeF1RedirectionToken: byPassAcessToken ? fancodeF1RedirectionToken : '' }),
    };

    return RequestUtil.GET({
        url,
        headers: removeFalsy(headers),
    });
};

export const getPlaybackWithoutLogin = ({
    contentId, uid, playType, customerType, atvSegment, token, deviceUtil,
    isTrailer, apiServiceParams = {},
}) => {
    const { base, paths } = env.endpoints.playbackApi;

    const appId = getAppId();
    const apiParams = removeFalsy(apiServiceParams);

    const queryParams = {
        contentId,
        appId,
        ...apiParams,
    };

    const queryParamsString = LocationUtil.objectToQueryParams(queryParams);
    const method = REQUEST_METHODS.GET;
    const path = isTrailer ? paths.playbackTrailer : paths.playWithoutLogin;
    const utknData = {
        urlBody: `${method}/${path}?${queryParamsString}`,
        token,
        uid,
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const atvDid = deviceUtil.getXAtvDid();
    const url = `${base}${path}?${queryParamsString}`;

    const headers = {
        'x-atv-utkn': utkn,
        'x-atv-did': atvDid,
        'x-atv-segment': atvSegment,
        'x-atv-customer': customerType,
        'play-type': playType,
        'content-type': 'application/json',
        'request-init-time': true,
    };

    return RequestUtil.GET({
        url,
        headers: removeFalsy(headers),
    });
};


export const sendHeartBeat = ({
    contentId, uid, token, atvDid, appId,
}) => {
    const { base, paths } = env.endpoints.playbackApi;
    const body = { contentId };

    const method = 'POST';
    const utknData = {
        urlBody: `${method}/${paths.heartbeat}?appId=${appId}${JSON.stringify(body)}`,
        token,
        uid,
    };

    const utkn = CryptoUtil.getUtkn(utknData);
    const url = `${base}${paths.heartbeat}`;
    const headers = {
        'x-atv-utkn': utkn,
        'x-atv-did': atvDid,
        'content-type': 'application/json',
    };

    return RequestUtil.POST({
        url,
        params: {
            appId,
        },
        headers: removeFalsy(headers),
        data: body,
    });
};

export default {
    getPlayback,
    sendHeartBeat,
};
