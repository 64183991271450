
import {RoutingUtil} from '@airtel-tv/utils';
import PotentialActionParser from './PotentialActionParser';
import { secondToHours, secondToMinutes } from '../../../../utilities/CommonUtil';
import { MARKUP_CONTEXT, MARKUP_TYPES } from '../../../../constants/MarkupConsts';
import { actorDirectorParser } from '../../../../utilities/ParserUtil';

export default function MovieMarkupParser({
    contentDetails,
    baseUrl,
}) {
    const {
        title, description, images, durSec, programType, id, credits, languages,
    } = contentDetails;
    const canonicalUrl = RoutingUtil.getContentPlaybackUrlWeb({
        category: programType,
        contentTitle: title,
        contentId: id,
    });
    const embedUrl = baseUrl + canonicalUrl;
    const potentialAction = PotentialActionParser({
        urlTemplate: embedUrl,
        inLanguage: languages && languages[0],
    });
    const durationHour = Math.floor(secondToHours(durSec));
    const durationMin = Math.floor(secondToMinutes(durSec));
    const { actorsCatalogue, directorsCatalogue } = actorDirectorParser(credits);

    const imageObject = images && Object.values(images);
    return {
        '@context': MARKUP_CONTEXT.URL,
        '@type': MARKUP_TYPES.MOVIE,
        name: title,
        url: embedUrl,
        description: description || title,
        image: imageObject.length ? imageObject[0] : null,
        ...potentialAction,
        actor: actorsCatalogue,
        director: directorsCatalogue,
        duration: `PT${durationHour}H${durationMin}M00S`,
    };
}
