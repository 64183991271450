// source: https://github.com/marcuswestin/store.js
import browserStore from 'store/dist/store.modern';

import engine from 'store/src/store-engine';
import storeDetaults from 'store/plugins/defaults';
import expire from 'store/plugins/expire';
import local from 'store/storages/localStorage';
import session from 'store/storages/sessionStorage';
import cookie from 'store/storages/cookieStorage';

// optional to use
export const localStore = engine.createStore([
    local,
], [
    storeDetaults,
    expire,
]);
export const sessionStore = engine.createStore([
    session,
], [
    storeDetaults,
    expire,
]);
export const cookieStore = engine.createStore([
    cookie,
], [
    storeDetaults,
    expire,
]);

// default is the priority that will support all modren browser
export default browserStore;
