import lodashIsEmpty from 'lodash/isEmpty';

import {RoutingUtil} from '@airtel-tv/utils';
import { PROGRAM_TYPES, FOOTER_CONFIG_CATEGORY } from '../../constants/AppConst';

export const filterFooterRail = (footerConfig, lang) => {
    let railIds = [];
    Object.keys(footerConfig).forEach((category) => {
        if (category !== FOOTER_CONFIG_CATEGORY.LIVE_TV_CHANNELS && !!footerConfig[category][lang]) {
            railIds = [
                ...railIds,
                [
                    category,
                    footerConfig[category][lang].railId,
                ],
            ];
        }
    });
    return railIds;
};

export const filterRailContent = (categoryWithRailIds, rails) => {
    let categoryWithPackageIds = [];
    categoryWithRailIds.forEach((categoryData) => {
        const category = categoryData[0];
        const railId = categoryData[1];
        if (rails[railId]) {
            categoryWithPackageIds = [
                ...categoryWithPackageIds,
                [
                    category,
                    rails?.[railId]?.contents?.[0]?.packageId,
                ],
            ];
        }
    });
    return categoryWithPackageIds;
};

export const filterPackageContent = (categoryWithPackageIds, packages) => {
    let languageFooterData = {};
    categoryWithPackageIds.forEach((categoryData) => {
        const category = categoryData[0];
        const packageId = categoryData[1];
        if (packages[packageId]) {
            languageFooterData = {
                ...languageFooterData,
                [category]: packages[packageId].content,
            };
        }
    });
    return languageFooterData;
};

const footerForLanguageLinkBuilder = (contentList, channels) => contentList.map((content) => {
    const { programType, title, id: contentId } = content;
    let linkUrl = '';
    if (programType === PROGRAM_TYPES.LIVETVSHOW || programType === PROGRAM_TYPES.LIVETVMOVIE) {
        const { channelId } = content;
        const currentChannel = channels.find(item => item.id === channelId) || '';
        linkUrl = RoutingUtil.getLiveTvShowUrl({
            channelName: currentChannel.title,
            showName: title,
            contentId,
        });
    }
    else {
        linkUrl = RoutingUtil.getContentPlaybackUrlWeb({
            category: programType,
            contentTitle: title,
            contentId,
        });
    }
    return {
        title,
        linkUrl,
    };
});

const footerForAllLanguageLinkBuilder = contents => Object.keys(contents).map((ln) => {
    const { title, railId } = contents[ln];
    const linkUrl = contents[ln]?.deepLink ? contents[ln].deepLink : RoutingUtil.getPackageListingUrlV2({
        railId,
        title,
    });
    return {
        title,
        linkUrl,
    };
});

const liveTvLinkBuilder = contents => Object.keys(contents).map((title) => {
    const contentId = contents[title];
    const linkUrl = RoutingUtil.getContentPlaybackUrlWeb({
        contentId,
        category: PROGRAM_TYPES.LIVETVCHANNEL,
        contentTitle: title,
    });
    return {
        title,
        linkUrl,
    };
});

export const footerBuilder = ({
    lang = null,
    footerData,
    channels,
}) => {
    let result = [];
    if (!footerData || lodashIsEmpty(footerData)) {
        return null;
    }
    if (lang) {
        // playback page
        let dynamicData = [];
        let staticData = [];

        if (footerData.dynamic) {
            dynamicData = Object.keys(footerData.dynamic).map((category) => {
                const contents = footerData.dynamic[category];
                let links = [];
                // eslint-disable-next-line valid-typeof
                if (!Array.isArray(contents)) {
                    links = footerForAllLanguageLinkBuilder(contents);
                }
                else {
                    links = footerForLanguageLinkBuilder(contents.slice(0, 15), channels);
                }
                return {
                    title: category,
                    links,
                };
            });
        }
        if (footerData.static) {
            staticData = Object.keys(footerData.static).map((category) => {
                const contents = footerData.static[category];
                const links = liveTvLinkBuilder(contents);
                return {
                    title: category,
                    links,
                };
            });
        }
        result = [
            ...dynamicData,
            ...staticData,
        ];
    }
    else {
        // Non-playback page
        let dynamicData = [];
        let staticData = [];
        if (footerData.dynamic) {
            dynamicData = Object.keys(footerData.dynamic).map((category) => {
                const categoryData = footerData.dynamic[category];
                let links = [];
                links = footerForAllLanguageLinkBuilder(categoryData);
                return {
                    title: category,
                    links,
                };
            });
        }
        if (footerData.static) {
            staticData = Object.keys(footerData.static).map((category) => {
                const contents = footerData.static[category];
                const links = liveTvLinkBuilder(contents);
                return {
                    title: category,
                    links,
                };
            });
        }
        result = [
            ...dynamicData,
            ...staticData,
        ];
    }
    
    return result;
};

export default {
    filterFooterRail,
    footerBuilder,
    filterPackageContent,
    filterRailContent,
};
