import { CONTENT_PROVIDERS } from '@airtel-tv/constants';
import { DeviceTypeUtil, DistroUtil, getAppVersion } from '@airtel-tv/utils';
import { getRandomNumber, getUuid } from '@airtel-tv/utils/GlobalUtil';


export default class PlaybackParamsBuilder {
    static buildPlayUrlParams(
        params = {},
    ) {
        const { contentDetails, contentId } = params;
        const { cpId } = contentDetails;
        let playbackParams;
        switch (cpId) {
            case CONTENT_PROVIDERS.DISTROTV:
                playbackParams = PlaybackParamsBuilder.getDistroPlaybackParams(params);
                break;
            case CONTENT_PROVIDERS.MINITV:
                playbackParams = PlaybackParamsBuilder.getMiniTvPlaybackParams(params);
                break;
            default:
                // For all other cps only contentId needs to be sent
                playbackParams = {
                    contentId,
                };
                break;
        };
        return playbackParams;
    };

    static getDeviceDetails = ({ controlConfig, deviceUtil }) => {
        let deviceDetails;
        if (DeviceTypeUtil.isTV()) {
            deviceDetails = {
                TIZENOS: controlConfig?.SAMSUNG,
                LGOS: controlConfig?.LG,
            }[deviceUtil?.getOSName?.()];
        }
        else if (deviceUtil?.isMobile?.()) {
            deviceDetails = controlConfig?.MWEB;
        }
        else {
            deviceDetails = controlConfig?.WEB;
        }
        return deviceDetails;
    };


    static getDistroPlaybackParams(params) {
        const {
            contentDetails,
            CP_CONTROL_CONFIG = {},
            deviceUtil,
        } = params;

        const distroConfig = CP_CONTROL_CONFIG?.[contentDetails?.cpId];

        const distroUtil = new DistroUtil({
            contentDetails,
            deviceUtil,
            deviceTypeUtil: DeviceTypeUtil,
            controlConfig: distroConfig,
        });
        const { width, height } = distroUtil.calculatePlayerHeight();
        const userAgent = distroUtil.composeUserAgent();

        const distroPlaySessionId = getUuid();
        const deviceMakeDetails = distroUtil?.getDeviceMakeDetails?.();
        let pageUrl = window.location.hostname === 'localhost' ? 'https://uat.airtelxstream.in' : window.location.origin + window.location.pathname;
        pageUrl = encodeURIComponent(pageUrl);
        const playbackParams = {
            playerWidth: width,
            playerHeight: height,
            appCategory: distroConfig?.appCategory || 'Entertainment',
            appBundle: window.location.host,
            appName: encodeURI(distroConfig?.appName || 'Airtel Xstream Play'),
            appDomain: window.location.host,
            appVersion: getAppVersion(),
            cacheBuster: getRandomNumber(),
            distroPlaySessionId,
            userAgent,
            deviceMake: deviceMakeDetails.deviceMake,
            pageUrl,
            advertisingId: deviceUtil.privateFingerPrintHolder,
        };
        return playbackParams;
    }

    static getMiniTvPlaybackParams(params) {
        const {
            continueWatchingDetails,
            contentDetails,
            CP_CONTROL_CONFIG,
            deviceUtil,
        } = params;
        const cp_config = {
            ...CP_CONTROL_CONFIG,//comment below and test for preprod and prod
        };
        //const miniTvConfig = CP_CONTROL_CONFIG?.[contentDetails?.cpId];
        const miniTvConfig = cp_config?.[CONTENT_PROVIDERS.MINITV];

        const deviceDetails = PlaybackParamsBuilder.getDeviceDetails({ controlConfig: miniTvConfig, deviceUtil});
        const { lastWatchedPosition = 0 } = continueWatchingDetails || {};
        const { durSec } = contentDetails || {};
        let watchPercentage = (lastWatchedPosition / durSec) * 100;
        if (watchPercentage) {
            watchPercentage = watchPercentage.toFixed(2);
            if (watchPercentage > 100) {
                watchPercentage = 100;
            }
        }
        else {
            watchPercentage = '0';
        }
        return {
            watchPercentage,
            contentLengthInSec: durSec || 903,
            miniTvDeviceType: deviceDetails?.deviceType,
        };
    }
}
