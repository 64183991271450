import React, { useEffect } from 'react';
import { matchRoutes } from 'react-router';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ROUTE_PATHS, ROUTE_SOURCE_NAMES, ROUTE_STATIC_IDS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { clickEvent } from '@airtel-tv/analytics';
import { getSourceName } from '@airtel-tv/utils/GlobalUtil';
import CustomIconComponent from '@airtel-tv/ui-lib/molecules/CustomIcon/CustomIconComponent';
import { searchEvents } from '@airtel-tv/analytics/FunctionalEvents';
import EventType from '@airtel-tv/analytics/EventType';
import { EVENT_SOURCE_NAME } from '@airtel-tv/constants';
import { connect } from 'react-redux';
import styles from './BottomNavigationComponent.scss';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import RoutingUtil from '../../../../utilities/RoutingUtil';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
import getAllRouters from '../../../../routes/routes';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';

function BottomNavigationComponent(props) {
    const {
        location, selectedBottomNavigation, showPreferredPartnerTooltip, showPreferredPartnerTooltipForSeconds, shouldShowTooltip,
        currentSource, deviceUtil
    } = props;

    const onTabClick = (e, tabName, isSearchPage = false) => {
        console.log(isSearchPage, 'isSearchPage');
        const metaEvent = {
            source_name: currentSource,
            asset_name: tabName,
            action: ANALYTICS_ACTIONS.TAB_CLICK,
        };
        if (isSearchPage) {
            searchEvents({
                ...metaEvent,
            }, EventType.CLICK);
        }
        else {
            clickEvent({
                ...metaEvent,
            });
        }
    };

    function searchIconClicked(e) {
        const routePresent = matchRoutes(getAllRouters(), location.pathname);
        if (routePresent.length > 0) {
            // const currentRoute = routePresent[0].match;
            const pathName = routePresent.length > 1 ? ROUTE_SOURCE_NAMES[routePresent[1].route.path] : '';

            const alreadyOnSearchPage = pathName === EVENT_SOURCE_NAME.SEARCH_PAGE;

            if (alreadyOnSearchPage && e) {
                e.preventDefault();
            }
            else {
                onTabClick(e, 'search', true);
            }
        }
    }

    useEffect(() => {
        if (showPreferredPartnerTooltip && shouldShowTooltip) {
            showPreferredPartnerTooltipForSeconds(8000);
        }
        // eslint-disable-next-line
    }, [
        showPreferredPartnerTooltip,
    ]);

    const bottomNavBarConfig = {
        homePage: {
            clickHandler: e => onTabClick(e, 'home'),
            to: RoutingUtil.getHomePage(),
            ariaLabel: 'HOME PAGE',
            className: '',
            children: (
                <div className={selectedBottomNavigation === ROUTE_STATIC_IDS.HOME ? 'active' : ''}>
                    <CustomIconComponent
                        IconName="HOME"
                    />
                </div>),
        },
        youPage: {
            clickHandler: e => onTabClick(e, 'you'),
            to: ROUTE_PATHS.YOU_PAGE,
            ariaLabel: 'YOU PAGE',
            className: selectedBottomNavigation === ROUTE_STATIC_IDS.YOU_PAGE ? 'active' : '',
            children: (
                <CustomIconComponent
                    IconName="Footer-Profile"
                />),
        },
        searchPage: {
            clickHandler: searchIconClicked,
            to: ROUTE_PATHS.SEARCH,
            ariaLabel: 'SEARCH PAGE',
            active: selectedBottomNavigation === ROUTE_STATIC_IDS.SEARCH_ICON,
            children: (
                <CustomIconComponent
                    IconName="Footer-Search"
                />),
        },
        freeContent: {
            clickHandler: e => onTabClick(e, 'free_tv'),
            to: ROUTE_PATHS.AVOD,
            ariaLabel: 'FREE CONTENTS',
            listElemClass: 'free-section',
            active: selectedBottomNavigation === ROUTE_STATIC_IDS.MENU_NAV,
            children: (
                <CustomIconComponent
                    IconName="Free-Content"
                />),
        },
        more: {
            clickHandler: e => onTabClick(e, 'more'),
            to: ROUTE_PATHS.SETTINGS,
            ariaLabel: 'MORE MENU PAGE',
            active: selectedBottomNavigation === ROUTE_STATIC_IDS.MENU_NAV,
            children: (
                <CustomIconComponent
                    IconName="Footer-Menu"
                />),
        },
    };

    return (
        <div
            className="navbar-bottom"
            key="navbar-bottom"
            id="navbar-bottom"
        >
            <ul className="nav-bottom-list">
                {/* <li>
                    <LinkComponent
                        onClick={e => onTabClick(e, 'home')}
                        to={RoutingUtil.getHomePage()}
                        aria-label="HOME PAGE"
                    >
                        <div className={selectedBottomNavigation === ROUTE_STATIC_IDS.HOME ? 'active' : ''}>
                            <CustomIconComponent
                                IconName="HOME"
                            />
                        </div>

                    </LinkComponent>
                </li>
                <li>
                    <LinkComponent
                        onClick={e => onTabClick(e, 'you')}
                        to={ROUTE_PATHS.YOU_PAGE}
                        aria-label="YOU PAGE"
                        className={selectedBottomNavigation === ROUTE_STATIC_IDS.YOU_PAGE ? 'active' : ''}
                    >
                        <CustomIconComponent
                            IconName="Footer-Profile"
                        />
                    </LinkComponent>
                </li>
                <li>
                    <LinkComponent
                        to={ROUTE_PATHS.SEARCH}
                        onClick={searchIconClicked}
                        aria-label="SEARCH PAGE"
                        active={selectedBottomNavigation === ROUTE_STATIC_IDS.SEARCH_ICON}
                    >
                        <CustomIconComponent
                            IconName="Footer-Search"
                        />
                    </LinkComponent>

                </li>
                <li className="free-section">
                    <LinkComponent
                        onClick={e => onTabClick(e, 'free_tv')}
                        to={ROUTE_PATHS.AVOD}
                        aria-label="FREE CONTENTS"
                        active={selectedBottomNavigation === ROUTE_STATIC_IDS.MENU_NAV}
                    >
                        <CustomIconComponent
                            IconName="Free-Content"
                        />
                    </LinkComponent>
                </li>
                <li>
                    <LinkComponent
                        onClick={e => onTabClick(e, 'more')}
                        to={ROUTE_PATHS.SETTINGS}
                        aria-label="MORE MENU PAGE"
                        active={selectedBottomNavigation === ROUTE_STATIC_IDS.MENU_NAV}
                    >
                        <CustomIconComponent
                            IconName="Footer-Menu"
                        />
                    </LinkComponent>
                </li> */}
                {Object.keys(bottomNavBarConfig).map((itemName) => {
                    const menuDetail = bottomNavBarConfig[itemName];
                    return (
                        <li className={menuDetail.listElemClass || ''}>
                            <LinkComponent
                                onClick={menuDetail.clickHandler}
                                to={menuDetail.to}
                                aria-label={menuDetail.ariaLabel}
                                className={menuDetail.className}
                                active={menuDetail.active || undefined}
                            >
                                {menuDetail.children}
                            </LinkComponent>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

BottomNavigationComponent.defaultProps = {
    location: null,
    selectedBottomNavigation: '',
    showPreferredPartnerTooltip: null,
    tooltipText: null,
    shouldShowTooltip: null,
};

BottomNavigationComponent.propTypes = {
    route: PropTypes.arrayOf(PropTypes.object).isRequired,
    location: PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
    }),
    selectedBottomNavigation: PropTypes.string,
    showPreferredPartnerTooltip: PropTypes.bool,
    closePreferredPartnerTooltip: PropTypes.func.isRequired,
    showPreferredPartnerTooltipForSeconds: PropTypes.func.isRequired,
    tooltipText: PropTypes.string,
    shouldShowTooltip: PropTypes.bool,
};
function mapStateToProps(state, props) {
    const {
        location: {
            navigationObj: {
                currentSource = '',
            } = {},
        } = {},
    } = state;
    return currentSource;
}


export default connect(
    mapStateToProps,
    {

    },
)(withStyles(styles)(withDeviceUtil(BottomNavigationComponent)));
