import { AppEnv } from '@airtel-tv/utils/GetEnv';

const env = AppEnv?.getEnv() || {};

const throttle = env?.eventQueueSize;
const queue = [];

const EventQueue = {
    add: (event, callback) => {
        if (queue.length >= throttle - 1) {
            queue.push(event);
            EventQueue.flush(callback);
        }
        else {
            queue.push(event);
        }
    },
    pop: () => queue.pop(),
    flush: (callback) => {
        if (queue.length > 0) {
            callback(queue.splice(0, throttle));
            return true;
        }

        return false;
    },
    addAndFlush: (event, callback) => {
        queue.push(event);
        EventQueue.flush(callback);
    },
    purge: () => {
        queue.length = 0;
    },
};

Object.freeze(EventQueue);
export default EventQueue;
