import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';
import { ContentImageProvider } from '@airtel-tv/utils/ContentImageProvider';
import {
    LOCAL_RAIL_TYPES, LOCAL_TILE_TYPES, RAIL_TYPES, TILE_TYPES,
} from '@airtel-tv/constants/LayoutConstants';

export const ImageFactory = ({
    images, railType, tileType, preferredArtwork,
}) => {
    let imageUrl = null;
    if (preferredArtwork) {
        let secondaryImgType = CONTENT_IMAGE_TYPES.PORTRAIT;
        switch (preferredArtwork) {
            case CONTENT_IMAGE_TYPES.LANDSCAPE_169_HD:
                secondaryImgType = CONTENT_IMAGE_TYPES.LANDSCAPE_169;
                break;
            case CONTENT_IMAGE_TYPES.SQUARE_HD:
                secondaryImgType = CONTENT_IMAGE_TYPES.SQUARE;
                break;
            default:
                secondaryImgType = CONTENT_IMAGE_TYPES.PORTRAIT;
        }
        imageUrl = ContentImageProvider({
            imageMap: images,
            imgType: [
                CONTENT_IMAGE_TYPES[preferredArtwork],
                secondaryImgType,
            ],
        });
        return imageUrl;
    }
    switch (railType) {
        case LOCAL_RAIL_TYPES.DIMENSION_LIST:
        case LOCAL_RAIL_TYPES.CHANNEL_SCHEDULE:
        case LOCAL_RAIL_TYPES.NO_RAIL:
        case LOCAL_RAIL_TYPES.LIST: {
            switch (tileType) {
                case LOCAL_TILE_TYPES.LARGE_SCREEN_SEARCH_TILE:
                    imageUrl = ContentImageProvider({
                        imageMap: images,
                        imgType: [
                            CONTENT_IMAGE_TYPES.PORTRAIT_HD,
                            CONTENT_IMAGE_TYPES.PORTRAIT,
                        ],
                    });
                    break;
                case LOCAL_TILE_TYPES.LANDSCAPE_CARD_TILE_GRID:
                case LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID:
                case LOCAL_TILE_TYPES.NEXT_EPISODE_TILE:
                    imageUrl = ContentImageProvider({
                        imageMap: images,
                        imgType: [
                            CONTENT_IMAGE_TYPES.LANDSCAPE_169_HD,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                            CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                            CONTENT_IMAGE_TYPES.PORTRAIT,
                            CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                            CONTENT_IMAGE_TYPES.RECTANGULAR,
                            CONTENT_IMAGE_TYPES.CIRCULAR,
                        ],
                    });
                    break;
                case LOCAL_TILE_TYPES.CONTINUE_WATCHING_TILE:
                case LOCAL_TILE_TYPES.CHANNEL_SCHEDULE:
                case LOCAL_TILE_TYPES.WATCHLIST:
                case RAIL_TYPES.CONTINUE_WATCHING_RAIL:
                case RAIL_TYPES.WATCHLIST_RAIL:
                    imageUrl = ContentImageProvider({
                        imageMap: images,
                        imgType: [
                            CONTENT_IMAGE_TYPES.LANDSCAPE_169_HD,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                            CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                            CONTENT_IMAGE_TYPES.PORTRAIT,
                            CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                            CONTENT_IMAGE_TYPES.RECTANGULAR,
                            CONTENT_IMAGE_TYPES.CIRCULAR,
                        ],
                    });
                    break;
                case LOCAL_TILE_TYPES.CUSTOM_EXPLORE:
                    imageUrl = ContentImageProvider({
                        imageMap: images,
                        imgType: [
                            CONTENT_IMAGE_TYPES.SQUARE,
                            CONTENT_IMAGE_TYPES.RECTANGULAR,
                            CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                            CONTENT_IMAGE_TYPES.PORTRAIT,
                            CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                            CONTENT_IMAGE_TYPES.CIRCULAR,
                        ],
                    });
                    break;
                case RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL:
                    imageUrl = ContentImageProvider({
                        imageMap: images,
                        imgType: [
                            CONTENT_IMAGE_TYPES.FEATURE_BANNER_HD,
                            CONTENT_IMAGE_TYPES.FEATURE_BANNER_HD,
                            CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_169_HD,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_43_HD,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                        ],
                    });
                    break;
                case LOCAL_TILE_TYPES.CHANNEL_LOGO:
                    imageUrl = ContentImageProvider({
                        imageMap: images,
                        imgType: [
                            CONTENT_IMAGE_TYPES.CIRCULAR,
                            CONTENT_IMAGE_TYPES.LOGO,
                            CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                            CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                            CONTENT_IMAGE_TYPES.PORTRAIT,
                            CONTENT_IMAGE_TYPES.RECTANGULAR,
                        ],
                    });
                    break;
                default:
                    imageUrl = ContentImageProvider({
                        imageMap: images,
                        imgType: [
                            CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                            CONTENT_IMAGE_TYPES.PORTRAIT,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                            CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                            CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                            CONTENT_IMAGE_TYPES.RECTANGULAR,
                            CONTENT_IMAGE_TYPES.CIRCULAR,
                        ],
                    });
            }
            break;
        }
        case RAIL_TYPES.GRID_SQUARE_EXPLORE_RAIL:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES.SQUARE,
                    CONTENT_IMAGE_TYPES.RECTANGULAR,
                    CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                    CONTENT_IMAGE_TYPES.PORTRAIT,
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                    CONTENT_IMAGE_TYPES.CIRCULAR,
                ],
            });
            break;
        case RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_PORTRAIT:
        case RAIL_TYPES.GRID_RECTANGLE_CHANNEL_RAIL:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                    CONTENT_IMAGE_TYPES.PORTRAIT,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                    CONTENT_IMAGE_TYPES.RECTANGULAR,
                    CONTENT_IMAGE_TYPES.CIRCULAR,
                ],
            });
            break;
        case RAIL_TYPES.GRID_ROUND_SECTION_RAIL:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES.RECTANGULAR,
                    CONTENT_IMAGE_TYPES.CIRCULAR,
                ],
            });
            break;
        case RAIL_TYPES.TVSHOW_LOGO_43_RAIL:
        case RAIL_TYPES.CONTINUE_WATCHING_RAIL:
        case RAIL_TYPES.WATCHLIST_RAIL:
        case RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                    CONTENT_IMAGE_TYPES.PORTRAIT,
                    CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                    CONTENT_IMAGE_TYPES.RECTANGULAR,
                    CONTENT_IMAGE_TYPES.CIRCULAR,
                ],
            });
            break;
        case RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER_HD,
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                    CONTENT_IMAGE_TYPES.PORTRAIT,
                    CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                    CONTENT_IMAGE_TYPES.RECTANGULAR,
                    CONTENT_IMAGE_TYPES.CIRCULAR,
                ],
            });
            break;
        case RAIL_TYPES.YOUR_CHANNELS_RAIL:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES.CIRCULAR,
                    CONTENT_IMAGE_TYPES.LOGO,
                    CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                    CONTENT_IMAGE_TYPES.PORTRAIT,
                    CONTENT_IMAGE_TYPES.RECTANGULAR,
                ],
            });
            break;
        case RAIL_TYPES.X_FULL_BANNER:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER_HD,
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                    CONTENT_IMAGE_TYPES.CIRCULAR,
                    CONTENT_IMAGE_TYPES.LOGO,
                    CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                    CONTENT_IMAGE_TYPES.PORTRAIT,
                    CONTENT_IMAGE_TYPES.RECTANGULAR,
                ],
            });
            break;
        case TILE_TYPES.X_EXPLORE_V2:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES[preferredArtwork],
                    CONTENT_IMAGE_TYPES.SQUARE,
                    CONTENT_IMAGE_TYPES.RECTANGULAR,
                    CONTENT_IMAGE_TYPES.PORTRAIT,
                    CONTENT_IMAGE_TYPES.CIRCULAR,
                    CONTENT_IMAGE_TYPES.LOGO,
                    CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                ],
            });
            break;
        case TILE_TYPES.TRAILERS_RAIL:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES.PORTRAIT,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                ],
            });
            break;
        case TILE_TYPES.X_VERTICAL_TRAILER_RAIL:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                    CONTENT_IMAGE_TYPES.RECTANGULAR,
                ],
            });
            break;
        default:
            imageUrl = ContentImageProvider({
                imageMap: images,
                imgType: [
                    CONTENT_IMAGE_TYPES.FEATURE_BANNER,
                    CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_43,
                    CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                    CONTENT_IMAGE_TYPES.PORTRAIT,
                    CONTENT_IMAGE_TYPES.RECTANGULAR,
                    CONTENT_IMAGE_TYPES.CIRCULAR,
                ],
            });
            break;
    }
    return imageUrl;
};

export default ImageFactory;
