import lodashGet from 'lodash/get';

import {RoutingUtil} from '@airtel-tv/utils/RoutingUtil';
import PotentialActionParser from './PotentialActionParser';
import { MARKUP_CONTEXT, MARKUP_TYPES } from '../../../../constants/MarkupConsts';
import { actorDirectorParser } from '../../../../utilities/ParserUtil';
import { secondToHours, secondToMinutes } from '../../../../utilities/CommonUtil';

export default function TvShowMarkupParser({
    contentDetails, baseUrl,
}) {
    const {
        title,
        description,
        images,
        programType,
        id,
        credits,
        languages,
        genre,
    } = contentDetails;

    const canonicalUrl = RoutingUtil.getContentPlaybackUrlWeb({
        category: programType,
        contentTitle: title,
        contentId: id,
    });
    const embedUrl = baseUrl + canonicalUrl;

    const potentialAction = PotentialActionParser({
        urlTemplate: embedUrl,
        inLanguage: languages && languages[0],
    });

    const duration = lodashGet(contentDetails, 'seriesTvSeasons[0].firstEpsRef.duration', '');
    const durationHour = Math.floor(secondToHours(duration));
    const durationMin = Math.floor(secondToMinutes(duration));

    const imageObject = images && Object.values(images);
    const { actorsCatalogue } = actorDirectorParser(credits);

    return {
        '@context': MARKUP_CONTEXT.URL,
        '@type': MARKUP_TYPES.TVSERIES,
        name: title,
        url: embedUrl,
        actor: actorsCatalogue,
        video: {
            '@type': 'VideoObject',
            genre,
            name: title,
            description: description || title,
            thumbnailUrl: imageObject && imageObject.length ? imageObject[0] : null,
            contentUrl: embedUrl,
            duration: `PT${durationHour}H${durationMin}M00S`,
            uploadDate: '2019-01-05T08:00:00+08:00',
        },
        description: description || title,
        image: imageObject && imageObject.length ? imageObject[0] : null,
        ...potentialAction,
    };
}
