import React from 'react';

const DownloadAppNudgeSVGComponent = () => (
    <svg
        className="nudge-svg"
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.5 1.25V10.75"
            stroke="#E8EAED"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2 6.25L6.5 10.75L11 6.25"
            stroke="#E8EAED"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1 12.75H12"
            stroke="#E8EAED"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>

);

export default DownloadAppNudgeSVGComponent;
