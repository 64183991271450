// this file will not be executed on server

import React from 'react';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { matchRoutes, renderMatches, } from 'react-router';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { APP_ROOT_ID } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { LocationUtil } from '@airtel-tv/utils';
import configureStore from './redux/Store';
import InitApp from './modules/app/InitApp';
import MobilityInitApp from './modules/app/MobilityInitApp';
import * as serviceWorker from './serviceWorker';

import {
    IMAGE_PATHS, DEFAULT_STRING, QUERY_PARAMS,
} from './constants/AppConst';
import {
    getElementById, retry, isOnline, removeChildElements,
} from './utilities/WindowUtil';
// import './modules/artist/ArtistContainer.scss';
import sagas from './redux/Sagas';
import ElementRender from '@airtel-tv/lib/ComponentRenderer';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { SOS_SESSION_KEYS } from '@airtel-tv/constants';


// import './modules/navigation/components/top-navigation/DesktopNavigationComponent.scss';
// import UserEventCountUtil from './utilities/UserEventCountUtil';
// import { userVisitGaEvent } from './utilities/GaEvents';

const ErrorPageComponent = Loadable({
    loader: () => import('./modules/shared/components/error-page/ErrorPageComponent' /* webpackChunkName: "ErrorPageComponent" */),
    loading: () => (<div />),
});

export default function Main(routes) {
    DeviceUtil.setDeviceUtil();
    const deviceUtil = DeviceUtil.getDeviceUtil();

    const errorHandler = (error) => {
        console.error(error);
        const errorMessage = isOnline() ? DEFAULT_STRING.SERVER_FAILED_MESSAGE : DEFAULT_STRING.NETWORK_FAILED_MESSAGE;
        removeChildElements(APP_ROOT_ID);

        const container = getElementById(APP_ROOT_ID);
        const root = createRoot(container);

        root.render(
            <ErrorPageComponent
                imageUrl={IMAGE_PATHS.ERROR_PAGE_OPSS}
                message={errorMessage}
                buttonText={DEFAULT_STRING.BUTTON_RETRY}
                action={retry}
            />,
        );
    };

    const qp = LocationUtil.parse(window.location.search);
    const { appId } = qp;
    const isMobility = appId === 'MOBILITY';

    const main = () => {
        const { store, sagaMiddleware } = configureStore({ deviceUtil });
        sagaMiddleware.run(sagas);
        // increment visit
        // const visitCount = UserEventCountUtil.getUserVisitCount() + 1;
        // UserEventCountUtil.setUserVisitCount({ visitCount });
        // userVisitGaEvent(visitCount);
        const insertCss = (...styles) => {
            const removeCss = styles.map((style) => {
                if (style._insertCss) {
                    return style._insertCss();
                }
            })
            return () => removeCss.forEach(dispose => dispose())
        }

        sessionStore.remove(SOS_SESSION_KEYS.SOS_DICT);

        function render() {
       
            const container = getElementById(APP_ROOT_ID);
            hydrateRoot(container, (
                // <React.StrictMode>
                <StyleContext.Provider value={{ insertCss }}>
                    <Provider store={store}>
                        <BrowserRouter>
                            {/* {renderMatches(matchRoutes(routes, window.location.pathname), { deviceUtil })} */}
                            <ElementRender routes={routes} />
                        </BrowserRouter>
                    </Provider>
                </StyleContext.Provider>
                // </React.StrictMode>
            ));
        }


        const init = isMobility ? MobilityInitApp : InitApp;
        console.log('===> isMobility', isMobility, appId);
        init(store, deviceUtil)
            .then(() => render())
            .catch(errorHandler);
    };

    !isMobility && serviceWorker.register();

    try {
        deviceUtil.createFingerPrint();
        main();
    }
    catch (error) {
        console.log(error);
        errorHandler();
    }
}
