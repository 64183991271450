import lodahsGet from 'lodash/get';
import lodashCapitalize from 'lodash/capitalize';
import {
    PROGRAM_TYPES, DIMENSION_PAGE_MAP, DIMENSIONS_SUPPORTED_MAP, BREADCRUMBS_MAP, BREADCRUMBS_LINKS, DIMENSION_PROGRAMTYPE_MAP, BREADCRUMBS_NAME,
} from '@airtel-tv/constants/GlobalConst';

import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import { DateTime } from '@airtel-tv/utils/DateTime';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';

import { firstLetterUpper } from '@airtel-tv/utils/GlobalUtil';
export class BreadCrumbsUtil {
    static layoutPageBreadCrumbs({ urlName, title }) {
        const breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        if (title !== BREADCRUMBS_MAP.HOME) {
            breadCrumbsList.push([
                title,
                `/${urlName}`,
            ]);
        }
        return breadCrumbsList;
    }

    static moviePageBreadCrumbs({ contentDetails, curPageLink }) {
        const LANGUAGES_CODES = LanguageProviderUtil.getLanguageCodes();
        const {
            languages,
            title,
            programType,
        } = contentDetails;
        const langCode = lodahsGet(languages, '[0]', null);

        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];

        switch (programType) {
            case PROGRAM_TYPES.MOVIE:
                breadCrumb = BREADCRUMBS_MAP.MOVIES;
                break;
            case PROGRAM_TYPES.VIDEO:
            case PROGRAM_TYPES.OTHER:
                return breadCrumbsList;
            default: breadCrumb = BREADCRUMBS_MAP.MOVIES;
        }
        breadCrumbsList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);
        if (langCode && programType === PROGRAM_TYPES.MOVIE) {
            const language = LANGUAGES_CODES[langCode];
            const langBreadCrumb = `${language} ${BREADCRUMBS_MAP.MOVIES}`;
            const breadCrumbLink = RoutingUtil.getDimensionPageUrlV2({
                lang: language,
                pageTitle: DIMENSION_PAGE_MAP[programType],
                queryParams: `?pageTitle=${DIMENSIONS_SUPPORTED_MAP[programType]}&lang=${langCode}`,
            });
            breadCrumbsList.push([
                langBreadCrumb,
                breadCrumbLink,
            ]);
        }
        breadCrumbsList.push([
            title,
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static liveChannelPageBreadCrumbs = ({ contentDetails, curPageLink, pages }) => {
        const {
            title,
            genre,
            programType,
        } = contentDetails;
        const LANGUAGE = LanguageProviderUtil.getLanguage();

        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        breadCrumb = BREADCRUMBS_MAP.LIVE_TV;
        breadCrumbsList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);
        if (genre) {
            const genreBreadCrumb = `${genre} ${LANGUAGE.CHANNELS}`;
            const genreLink = RoutingUtil.getDimensionPageUrlV2({
                pageTitle: DIMENSION_PAGE_MAP[programType],
                genres: firstLetterUpper(genre),
                queryParams: `?pageTitle=${DIMENSIONS_SUPPORTED_MAP[programType]}&genres=${firstLetterUpper(genre)}`,
            });
            breadCrumbsList.push([
                genreBreadCrumb,
                genreLink,
            ]);
        }
        breadCrumbsList.push([
            title,
            curPageLink,
        ]);
        breadCrumbsList.forEach((item, index) => {
            if (item?.length === 2) {
                const arr = item;
                let urlName = arr[1]?.split('/');
                urlName = urlName?.length > 1 && urlName[0] === "" ? urlName[1] : urlName[0];
                const pageExist = pages.filter(page => !urlName || page?.urlName?.toLowerCase() === urlName?.toLowerCase());
                breadCrumbsList[index] = pageExist?.length ? item : [item[0],''];
            }
        });
        return breadCrumbsList;
    }

    static tvShowPageBreadCrumb({ contentDetails, curPageLink }) {
        const LANGUAGES_CODES = LanguageProviderUtil.getLanguageCodes();
        const {
            languages,
            title,
            programType,
        } = contentDetails;
        const langCode = lodahsGet(languages, '[0]', null);
        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        breadCrumb = BREADCRUMBS_MAP.TV_SHOWS;
        breadCrumbsList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);
        if (langCode) {
            const language = LANGUAGES_CODES[langCode];
            const langBreadCrumb = `${language} ${BREADCRUMBS_MAP.TV_SHOWS}`;
            const breadCrumbLink = RoutingUtil.getDimensionPageUrlV2({
                lang: language,
                pageTitle: DIMENSION_PAGE_MAP[programType],
                queryParams: `?pageTitle=${DIMENSIONS_SUPPORTED_MAP[programType]}&lang=${langCode}`,
            });
            breadCrumbsList.push([
                langBreadCrumb,
                breadCrumbLink,
            ]);
        }

        breadCrumbsList.push([
            title,
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static tvSeasonPageBreadCrumb({ contentDetails, curPageLink, showPageLink }) {
        const LANGUAGES_CODES = LanguageProviderUtil.getLanguageCodes();
        
        const {
            languages,
            title,
            programType,
            tvShowName,
        } = contentDetails;
        const langCode = lodahsGet(languages, '[0]', null);
        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        breadCrumb = BREADCRUMBS_MAP.TV_SHOWS;
        breadCrumbsList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);
        if (langCode) {
            const language = LANGUAGES_CODES[langCode];
            const langBreadCrumb = `${language} ${BREADCRUMBS_MAP.TV_SHOWS}`;
            const breadCrumbLink = RoutingUtil.getDimensionPageUrlV2({
                lang: language,
                pageTitle: DIMENSION_PAGE_MAP[programType],
                queryParams: `?pageTitle=${DIMENSIONS_SUPPORTED_MAP[programType]}&lang=${langCode}`,
            });
            breadCrumbsList.push([
                langBreadCrumb,
                breadCrumbLink,
            ]);
        }
        breadCrumbsList.push([
            tvShowName,
            showPageLink,
        ]);
        breadCrumbsList.push([
            lodashCapitalize(title),
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static tvEpisodePageBreadCrumb({
        contentDetails, curPageLink, showPageLink, seasonPageLink,
    }) {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        const LANGUAGES_CODES = LanguageProviderUtil.getLanguageCodes();
        const {
            languages,
            title,
            programType,
            tvShowName,
            episodeSeasonNum,
            episodeNum,
        } = contentDetails;

        const langCode = lodahsGet(languages, '[0]', 'hi');
        const seasonName = `${LANGUAGE.SEASON} ${episodeSeasonNum}`;
        const episodeName = `${LANGUAGE.EPISODE} ${episodeNum} - ${title}`;

        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        breadCrumb = BREADCRUMBS_MAP.TV_SHOWS;
        breadCrumbsList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);
        if (langCode) {
            const language = LANGUAGES_CODES[langCode];
            const langBreadCrumb = `${language} ${BREADCRUMBS_MAP.TV_SHOWS}`;
            const breadCrumbLink = RoutingUtil.getDimensionPageUrlV2({
                lang: language,
                pageTitle: DIMENSION_PAGE_MAP[programType],
                queryParams: `?pageTitle=${DIMENSIONS_SUPPORTED_MAP[programType]}&lang=${langCode}`,
            });
            breadCrumbsList.push([
                langBreadCrumb,
                breadCrumbLink,
            ]);
        }
        breadCrumbsList.push([
            tvShowName,
            showPageLink,
        ]);

        breadCrumbsList.push([
            seasonName,
            seasonPageLink,
        ]);
        breadCrumbsList.push([
            episodeName,
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static catchupShowPageBreadCrumbs({
        contentDetails, currentChannel, curPageLink,
    }) {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        const {
            title,
            genre,
        } = contentDetails;
        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        breadCrumb = BREADCRUMBS_MAP.LIVE_TV;
        breadCrumbsList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);
        if (genre) {
            const genreBreadCrumb = `${genre} ${LANGUAGE.CHANNELS}`;
            const genreLink = RoutingUtil.getDimensionPageUrlV2({
                pageTitle: DIMENSION_PAGE_MAP.LIVETVCHANNEL,
                genres: firstLetterUpper(genre),
                queryParams: `?pageTitle=${DIMENSIONS_SUPPORTED_MAP.LIVETVCHANNEL}&genres=${firstLetterUpper(genre)}`,
            });
            breadCrumbsList.push([
                genreBreadCrumb,
                genreLink,
            ]);
        }
        if (currentChannel) {
            const {
                programType: channelProgramType,
                title: channelTitle,
                id,
            } = currentChannel;
            const channelLink = RoutingUtil.getContentPlaybackUrlWeb({
                category: channelProgramType,
                contentTitle: channelTitle,
                contentId: id,
            });
            breadCrumbsList.push([
                channelTitle,
                channelLink,
            ]);
        }
        breadCrumbsList.push([
            title,
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static catchupEpisodePageBreadCrumbs({
        tvShowDetails, currentChannel, episodesDetails, curPageLink, showPageLink,
    }) {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        const {
            title: tvShowTitle,
            genre,
        } = tvShowDetails;
        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        breadCrumb = BREADCRUMBS_MAP.LIVE_TV;
        breadCrumbsList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);

        if (genre) {
            const genreBreadCrumb = `${genre} ${LANGUAGE.CHANNELS}`;
            const genreLink = RoutingUtil.getDimensionPageUrlV2({
                pageTitle: DIMENSION_PAGE_MAP.LIVETVCHANNEL,
                genres: firstLetterUpper(genre),
                queryParams: `?pageTitle=${DIMENSIONS_SUPPORTED_MAP.LIVETVCHANNEL}&genres=${firstLetterUpper(genre)}`,
            });
            breadCrumbsList.push([
                genreBreadCrumb,
                genreLink,
            ]);
        }
        if (currentChannel) {
            const {
                programType: channelProgramType,
                title: channelTitle,
                id,
            } = currentChannel;
            const channelLink = RoutingUtil.getContentPlaybackUrlWeb({
                category: channelProgramType,
                contentTitle: channelTitle,
                contentId: id,
            });
            breadCrumbsList.push([
                channelTitle,
                channelLink,
            ]);
        }
        breadCrumbsList.push([
            tvShowTitle,
            showPageLink,
        ]);
        const { airDate } = episodesDetails;
        const formattedDate = new DateTime(airDate).format('DD MMMM YYYY');
        breadCrumbsList.push([
            formattedDate,
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static catchupMovieBreadCrumbs({
        contentDetails,
        currentChannel,
        curPageLink,
    }) {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        const {
            title,
            genre,
        } = contentDetails;
        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        breadCrumb = BREADCRUMBS_MAP.LIVE_TV;
        breadCrumbsList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);
        if (genre) {
            const genreBreadCrumb = `${genre} ${LANGUAGE.CHANNELS}`;
            const genreLink = RoutingUtil.getDimensionPageUrlV2({
                pageTitle: DIMENSION_PAGE_MAP.LIVETVCHANNEL,
                genres: firstLetterUpper(genre),
                queryParams: `?pageTitle=${DIMENSIONS_SUPPORTED_MAP.LIVETVCHANNEL}&genres=${firstLetterUpper(genre)}`,
            });
            breadCrumbsList.push([
                genreBreadCrumb,
                genreLink,
            ]);
        }
        if (currentChannel) {
            const {
                programType: channelProgramType,
                title: channelTitle,
                id,
            } = currentChannel;
            const channelLink = RoutingUtil.getContentPlaybackUrlWeb({
                category: channelProgramType,
                contentTitle: channelTitle,
                contentId: id,
            });
            breadCrumbsList.push([
                channelTitle,
                channelLink,
            ]);
        }
        breadCrumbsList.push([
            title,
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static listingPageBreadCrumbs({ contentDetails, curPageLink }) {
        const {
            title,
            programType,
            pageTitle,
        } = contentDetails;
        const breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        if (pageTitle !== BREADCRUMBS_MAP.HOME || !pageTitle) {
            breadCrumbsList.push([
                pageTitle,
                `/${programType}`,
            ]);
        }
        breadCrumbsList.push([
            title,
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static artistPageBreadCrumbs({ artistName, curPageLink }) {
        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        breadCrumb = BREADCRUMBS_MAP.ARTISTS;
        breadCrumbsList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);
        breadCrumbsList.push([
            artistName,
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static dimensionPageBreadCrumbs({
        pageTitle, prefix, curPageLink, title,
    }) {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        breadCrumb = prefix ? title : `${DIMENSION_PROGRAMTYPE_MAP[pageTitle]} ${LANGUAGE.COLLECTION}`;
        breadCrumbsList.push([
            breadCrumb,
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static schedulePageBreadCrumbs({
        channel,
        curPageLink,
    }) {
        const {
            title,
            genres: [
                genre,
            ],
            id,
        } = channel;
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        let breadCrumb = BREADCRUMBS_MAP.HOME;
        const breadCrumbsList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];
        breadCrumb = BREADCRUMBS_MAP.LIVE_TV;
        breadCrumbsList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);
        if (genre) {
            const genreBreadCrumb = `${genre} ${LANGUAGE.CHANNELS}`;
            const genreLink = RoutingUtil.getDimensionPageUrlV2({
                pageTitle: DIMENSION_PAGE_MAP.LIVETVCHANNEL,
                genres: firstLetterUpper(genre),
                queryParams: `?pageTitle=${DIMENSIONS_SUPPORTED_MAP.LIVETVCHANNEL}&genres=${firstLetterUpper(genre)}`,
            });
            breadCrumbsList.push([
                genreBreadCrumb,
                genreLink,
            ]);
        }
        const link = RoutingUtil.getContentPlaybackUrlWeb({
            category: PROGRAM_TYPES.LIVETVCHANNEL,
            contentTitle: title,
            contentId: id,
        });
        breadCrumbsList.push([
            title,
            link,
        ]);
        breadCrumbsList.push([
            BREADCRUMBS_NAME.SCHEDULE_PAGE,
            curPageLink,
        ]);
        return breadCrumbsList;
    }

    static channelPartnerPageBreadcrumb({
        channelPartnerName,
        pageId,
    }) {
        let breadCrumb = BREADCRUMBS_NAME.HOME;
        const breadCrumbList = [
            [
                breadCrumb,
                BREADCRUMBS_LINKS[breadCrumb],
            ],
        ];

        breadCrumb = BREADCRUMBS_NAME.CHANNEL;
        breadCrumbList.push([
            breadCrumb,
            BREADCRUMBS_LINKS[breadCrumb],
        ]);
        breadCrumb = channelPartnerName;
        const channelPartnerUrl = RoutingUtil.getChannelPartnerPageUrl({
            channelPartnerName,
            pageLink: pageId,
        });

        breadCrumbList.push([
            breadCrumb,
            channelPartnerUrl,
        ]);

        return breadCrumbList;
    }
}
