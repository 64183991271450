import React, { useEffect } from 'react';
import { DeviceTypeUtil, checkWindowExist } from '@airtel-tv/utils';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { useNavigate } from 'react-router';
import { CUSTOMER_TYPE, MODAL_POPUP } from '@airtel-tv/constants';

export const useLoginPopup = ({
    isAuthenticated,
    customerType,
    hasPrevPath,
    history,
    showNonAirtelUserActionDispatch,
    showModalComponentActionDispatch,
    triggerLoginDispatch,
    currentPath,
    sourceOfLoginTrigger,
    navigateToHomeOnLoginClose = true,
    toggleNonAirtelPopUpDispatch = () => {},
}) => {
    const isLargeScreen = DeviceTypeUtil?.isTV();
    const navigate = useNavigate();
    const navigateBack = () => {
        if (checkWindowExist() && hasPrevPath) {
            history?.back();
        }
        else {
            navigate(ROUTE_PATHS.ROOT);
        }
    };
    useEffect(() => {
        if (!isAuthenticated) {
            // navigateBack();
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger,
                redirectUrl: currentPath,
                navigateToHomeOnLoginClose,
            });
        }
        else if (customerType === CUSTOMER_TYPE.NON_AIRTEL) {
            if (showNonAirtelUserActionDispatch && typeof showNonAirtelUserActionDispatch === 'function') {
                if (DeviceTypeUtil?.isWeb()) {
                    // navigateBack();
                    showNonAirtelUserActionDispatch();
                }
                else {
                    navigateBack();
                    toggleNonAirtelPopUpDispatch({show: true})
                }
            }
        }
    }, [
        isAuthenticated,
        customerType,
    ]);
};
