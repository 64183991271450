import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '@airtel-tv/common-scss/_cards.scss';
import { AnalyticsButtonComponent, AnalyticsLinkComponent } from '@airtel-tv/analytics';
import { ANALYTICS_ACTIONS } from '@airtel-tv/constants';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import { DeviceTypeUtil, LanguageProviderUtil } from '@airtel-tv/utils';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { setFocusedTileId, setLayoutDimensionMap } from '@airtel-feature/layout/actions/LayoutActions';
import { useUpdateCustomHook } from '@airtel-tv/lib/hooks';
import useCustomMemo from '@airtel-tv/lib/hooks/useCustomMemo';
import { railVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import HorizontalLazyLoaderHOC from '../../../lib/hoc/HorizontalLazyLoaderHOC';
import { showModalComponentAction, hideModalComponentAction } from '../../../../web/src/modules/modal-popup/ModalPopupActions';
import { setFocusedElement } from '../../../../tv/src/modules/navigation/NavigationActions';

const PillsRailComponent = (props) => {
    const LANGUAGE = LanguageProviderUtil?.getLanguage();
    const {
        railProps: {
            railIndex, tiles, railCtaActionUrl, railId, imageSettings, scrollRail, style, toggleShowPillsSidebarDispatch,
            packageId,
            onFocus = null,
            pageId,
            nonQuickViewRail,
            keyDownCallBack,
            railType,
        },
        getTile,
        setLayoutDimensionMapDispatch,
        showPillsSidebar,
    } = props;
    const {
        RAIL_CONTAINER_CLASS, CAN_FOCUS, SCROLL_TO_TOP, BY_PASS_SCROLL, ACTIVE_SIDEBAR,
    } = FocusManagementClassNames;

    useUpdateCustomHook(() => {
        if (Object.keys(showPillsSidebar).length === 0) {
            const { setFocusedElementDispatch } = props;
            setFocusedElementDispatch({
                selector: `${pageId}-tile-${railIndex}-3`,
                targetByClass: false,
            });
        }
    }, [
        showPillsSidebar,
    ]);

    const { PILLS_SEE_MORE = 'See More', PILLS_TO_SHOW = 4 } = LANGUAGE;


    const isWeb = DeviceTypeUtil?.isWeb();
    const { isMobile } = DeviceUtil?.getDeviceUtil();
    let tileElements = [];
    let lazyTiles = [];
    const tilesToShow = tiles;

    const railContainer = useRef();
    useEffect(() => {
        setLayoutDimensionMapDispatch({
            pageId,
            railPosition: railIndex,
            dimensions: {
                lastFocusedTileId: 0,
                focusSelectorleft: 0,
                transitionDuration: 0,
                height: 0,
                scrollTop: 0,
                nonQuickViewRail,
                focusOnSelection: true,
                subtitleHeight: null,
            },
        });
    }, []);

    const keyDownHandler = (event) => {
        keyDownCallBack({ event });
    };

    if (tilesToShow && Array.isArray(tilesToShow)) {
        tileElements = tilesToShow.slice(0, isMobile && isMobile() ? tilesToShow.length : tilesToShow.length > PILLS_TO_SHOW ? (PILLS_TO_SHOW - 1) : tilesToShow.length).map((tile, i) => {
            const pillAnalyticsMeta = {
                asset_name: `Pill ${tile.title}`,
                action: ANALYTICS_ACTIONS.TILE_CLICK,
                package_id: packageId,
                ...(tile?.zionTileId && { tile_id: tile?.zionTileId }),
            };
            const tileProps = {
                key: `${tile.id}-${i}`,
                ...tile,
                railCtaActionUrl,
                railPosition: railIndex,
                tilePosition: i,
                railId,
                imageSettings,
                tilesLength: tiles.length,
                style,
                scrollRail: (params) => {
                    scrollRail(params);
                },
                onFocus: (e, railPosition, tilePosition) => {
                    onFocus(e, railPosition, tilePosition, {});
                },
                pillAnalyticsMeta,
                packageId,
                pageId,
                focusRightElement: `${pageId}-tile-${railIndex}-${i + 1}`,
                focusLeftElement: i === 0 ? ACTIVE_SIDEBAR : `${pageId}-tile-${railIndex}-${i - 1}`,
                focusLeftTargetByClass: i === 0,
                tileId: `tile-${railIndex}-${i}`,
                onKeyDown: keyDownHandler,
            };
            const tileElement = getTile({
                tileProps,
            });

            return tileElement;
        });
        const key = `${railId}-${railIndex}`;
        lazyTiles = (
            <HorizontalLazyLoaderHOC
                tileElements={tileElements}
                railKey={key}
                isLoaderNeeded={false}
            />
        );
    }
    const onSeeMoreClick = () => {
        if (!isWeb) {
            toggleShowPillsSidebarDispatch({ tiles });
        }
    };

    const tileAnalyticsMeta = useCustomMemo({
        rail_position: railIndex,
        rail_id: railId,
        rail_type: railType,
        package_id: packageId,
    });

    useEffect(() => {
        railVisibleEvent(tileAnalyticsMeta);
    }, [
        tileAnalyticsMeta,
    ]);

    return (
        <div
            id={`railPosition-${railIndex}`}
            railPosition={railIndex}
            ref={railContainer}
            className={`${RAIL_CONTAINER_CLASS} ${SCROLL_TO_TOP} ${BY_PASS_SCROLL} z-index-0 railContainerWrapper carousel-right-margin extended-rail-margin landscape-rail pills-rail-wrapper`}
        >
            <div
                className="pills-rail"
            >
                {lazyTiles}
                {(isMobile && !isMobile()) && tiles.length > PILLS_TO_SHOW && (
                    <AnalyticsButtonComponent
                        id={`${pageId}-tile-${railIndex}-${PILLS_TO_SHOW - 1}`}
                        className={`nostyling d-flex ${CAN_FOCUS} pills-capsule relative-pill see-more-container scrollToTop`}
                        onClick={onSeeMoreClick}
                        onKeyDown={({ event }) => keyDownHandler(event)}
                        railPosition={railIndex}
                        tilePosition={3}
                        focusLeftElement={`${pageId}-tile-${railIndex}-${2}`}
                    >
                        {PILLS_SEE_MORE}
                        <svg
                            width="3.2rem"
                            height="3.2rem"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 6L22 16L12 26"
                                stroke="#E8EAED"
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        {isWeb && (
                            <ul className="sub-drop-down">
                                {
                                    tiles.slice(PILLS_TO_SHOW - 1, tiles.length).map((tile, index) => (
                                        <AnalyticsLinkComponent
                                            to={tile.tileCtaActionUrl}
                                            meta={{
                                                // source_name: '',
                                                asset_name: `Pill ${tile.title}`,
                                                action: ANALYTICS_ACTIONS.TILE_CLICK,
                                                package_id: packageId,
                                                ...(tile?.zionTileId && { tile_id: tile?.zionTileId }),
                                            }}
                                            className="pills-options no-styling subs-item"
                                            id={`pills-${index}`}
                                        >
                                            {tile.title}
                                        </AnalyticsLinkComponent>
                                    ))
                                }
                            </ul>
                        )}

                    </AnalyticsButtonComponent>
                )}

            </div>
        </div>
    );
};

export default connect((state, props) => {
    const {
        homepageConfig,
        notify: { showPillsSidebar },
    } = state;

    return {
        hideRail: homepageConfig?.tileDetails?.enabled,
        showPillsSidebar,
    };
}, {
    showModalComponentActionDispatch: showModalComponentAction,
    hideModalComponentActionDispatch: hideModalComponentAction,
    setFocusedTileIdDispatch: setFocusedTileId,
    setLayoutDimensionMapDispatch: setLayoutDimensionMap,
    setFocusedElementDispatch: setFocusedElement,
})(withStyles(styles)(PillsRailComponent));
