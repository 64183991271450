import { getAppVersion } from "./GlobalUtil";
import { checkWindowExist } from "./WindowUtil";

// eslint-disable-next-line import/prefer-default-export
export class DistroUtil {
    constructor({
        contentDetails,
        deviceUtil,
        deviceTypeUtil,
        controlConfig,

    }) {
        this.contentDetails = contentDetails;
        this.deviceUtil = deviceUtil;
        this.deviceTypeUtil = deviceTypeUtil;
        this.controlConfig = controlConfig;
    }


    calculatePlayerHeight = () => {
        if (!checkWindowExist()) {
            return {
                width: 0,
                height: 0,
            };
        }

        let { width, height } = window?.screen || {};
        if (this.contentDetails?.contentAutoplay) {
            if (this.deviceUtil?.isMobile?.()) {
                height = width * 0.5625;
            }
            else if (this.deviceUtil?.isWeb?.()) {
                width = this.deviceUtil.getWindowWidth();
                height = this.deviceUtil.getWindowHeight() - 60;
            }
        }
        return {
            width,
            height,
        };
    };

    getDeviceMakeDetails = () => {
        let deviceDetails;

        if (this.deviceTypeUtil.isTV()) {
            deviceDetails = {
                TIZENOS: this.controlConfig?.SAMSUNG,
                LGOS: this.controlConfig?.LG,
            }[this.deviceUtil?.getOSName?.()];
        }
        else if (this.deviceUtil?.isMobile?.()) {
            deviceDetails = this.controlConfig?.MWEB;
        }
        else {
            deviceDetails = this.controlConfig?.WEB;
        }
        return deviceDetails;
    };

    composeUserAgent = () => {
        const agent = navigator.userAgent || '';
        const deviceMakeDetails = this.getDeviceMakeDetails();
        const { deviceMake, manufacturer } = deviceMakeDetails;

        const { uaAppName: appName, dpName } = this.controlConfig;

        const av = getAppVersion();
        const result = `${agent} ${deviceMake}/${manufacturer} ${dpName} ${appName}/${av}`;
        return result;
    };
}
