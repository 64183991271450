const UserAction = {
    USER_CONFIG_INIT: 'USER_CONFIG_INIT',
    USER_CONFIG_SUCCESS: 'USER_CONFIG_SUCCESS',

    USER_CONFIG_POLLING: 'USER_CONFIG_POLLING',

    SHOW_EMAIL_CONFIRMATION: 'SHOW_EMAIL_CONFIRMATION',
    HIDE_EMAIL_CONFIRMATION: 'HIDE_EMAIL_CONFIRMATION',

    UPDATE_USER_EMAIL: 'UPDATE_USER_EMAIL',
    UPDATE_USER_EMAIL_ERROR: 'UPDATE_USER_EMAIL_ERROR',
    UPDATE_USER_EMAIL_SUCCESS: 'UPDATE_USER_EMAIL_SUCCESS',

    UPDATE_USER_LANGUAGE: 'UPDATE_USER_LANGUAGE',
    UPDATE_USER_LANGUAGE_SUCCESS: 'UPDATE_USER_LANGUAGE_SUCCESS',
    UPDATE_USER_LANGUAGE_ERROR: 'UPDATE_USER_LANGUAGE_ERROR',

    UPDATE_TEMP_USER_LANGUAGE: 'UPDATE_TEMP_USER_LANGUAGE',
    UPDATE_USER_AGE_CONSENT: 'UPDATE_USER_AGE_CONSENT',

    UPDATE_PREFERRED_PARTNER_SELECTIONS: 'UPDATE_PREFERRED_PARTNER_SELECTIONS',
    UPDATE_PREFERRED_PARTNER_ERROR: 'UPDATE_PREFERRED_PARTNER_ERROR',

    SHOW_PP_USER_MODAL: 'SHOW_PP_USER_MODAL',
    USER_LOCATION_SUCCESS: 'USER_LOCATION_SUCCESS',
    FETCH_USER_LOCATION: 'FETCH_USER_LOCATION',
    SHOW_NON_AIRTEL_USER_MODAL: 'SHOW_NON_AIRTEL_USER_MODAL',
    USER_CONFIG_WITH_ALL_RESET: 'USER_CONFIG_WITH_ALL_RESET',
};

export default UserAction;

export const userConfigSuccessAction = payload => ({
    type: UserAction.USER_CONFIG_SUCCESS,
    ...payload,
});

export const showEmailConfirmation = payload => ({
    type: UserAction.SHOW_EMAIL_CONFIRMATION,
    ...payload,
});

export const hideEmailConfirmation = payload => ({
    type: UserAction.HIDE_EMAIL_CONFIRMATION,
    ...payload,
});

export const updateUserEmail = payload => ({
    type: UserAction.UPDATE_USER_EMAIL,
    ...payload,
});

export const updateUserEmaiLError = payload => ({
    type: UserAction.UPDATE_USER_EMAIL_ERROR,
    ...payload,
});

export const updateUserEmaiLSuccess = payload => ({
    type: UserAction.UPDATE_USER_EMAIL_SUCCESS,
    ...payload,
});

export const updateUserLanguageAction = payload => ({
    type: UserAction.UPDATE_USER_LANGUAGE,
    ...payload,
});

export const updateUserLanguageActionSuccess = payload => ({
    type: UserAction.UPDATE_USER_LANGUAGE_SUCCESS,
    ...payload,
});

export const updateUserLanguageError = payload => ({
    type: UserAction.UPDATE_USER_LANGUAGE_ERROR,
    ...payload,
});

export const updateTempUserLang = payload => ({
    type: UserAction.UPDATE_TEMP_USER_LANGUAGE,
    ...payload,
});

export const updateUserAgeConsent = payload => ({
    type: UserAction.UPDATE_USER_AGE_CONSENT,
    ...payload,
});

export const updatePreferredPartnerSelections = payload => ({
    type: UserAction.UPDATE_PREFERRED_PARTNER_SELECTIONS,
    ...payload,
});

export const updatePreferredPartnerError = payload => ({
    type: UserAction.UPDATE_PREFERRED_PARTNER_ERROR,
    ...payload,
});

export const showPPModalAction = payload => ({
    type: UserAction.SHOW_PP_USER_MODAL,
    ...payload,
});

export const userConfigPolling = payload => ({
    type: UserAction.USER_CONFIG_POLLING,
    payload,
});

export const fetchUserLocation = () => ({
    type: UserAction.FETCH_USER_LOCATION,
});

export const userLocationSuccessAction = payload => ({
    type: UserAction.USER_LOCATION_SUCCESS,
    ...payload,
});

export const showNonAirtelUserAction = payload => ({
    type: UserAction.SHOW_NON_AIRTEL_USER_MODAL,
    payload,
});

export const updateUserConfig = (payload = {}) => ({
    type: UserAction.USER_CONFIG_INIT,
    payload: { ...payload },
});

export const UserConfigWithAllReset = payload => ({
    type: UserAction.USER_CONFIG_WITH_ALL_RESET,
    ...payload,
});