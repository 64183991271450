import lodashGet from 'lodash/get';
import { DateTime } from '@airtel-tv/utils';

export const getTimeArray = (time = new Date().getTime(), interval = 30) => {
    const times = []; // time array
    let tt = 0; // start time
    const ap = [
        'AM',
        'PM',
    ]; // AM-PM
    // loop to increment the time and push results in array
    for (let i = 0; tt < 24 * 60; i++) {
        const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        const mm = (tt % 60); // getting minutes of the hour in 0-55 format
        let timeStamp = new Date(time).setHours(hh);
        timeStamp = new Date(timeStamp).setMinutes(mm);

        times[i] = {
            time: `${(hh === 0 || hh === 12) ? 12 : (`0${hh % 12}`).slice(-2)}:${(`0${mm}`).slice(-2)}${ap[Math.floor(hh / 12)]}`,
            minutes: tt,
            timeStamp,
        }; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt += interval;
    }
    return times;
};

export const getDateFilter = (maxScope = 1) => {
    const DateFilter = [];
    for (let i = 0; i < maxScope; i++) {
        const timeStamp = new Date(new Date().getTime() + i * 24 * 60 * 60 * 1000);
        let title = '';

        switch (i) {
            case 0:
                title = 'Today';

                break;
            case 1:
                title = 'Tomorrow';

                break;
            default:
                title = new DateTime(timeStamp).format('dd DD MM');
        }
        const date = timeStamp.getDate();
        DateFilter.push({
            index: i,
            title,
            timeStamp: timeStamp.getTime(),
            date,
            startTimestamp: timeStamp.setHours(0, 0, 0, 0),
        });
    }
    return DateFilter;
};

export const filterChannels = (channels, filter, order) => {
    let fiteredChannel = channels;
    if (filter && filter.lang && filter.lang.length) {
        fiteredChannel = channels.filter((channel) => {
            const channelLang = lodashGet(channel, 'lang', []);
            let exist = false;
            channelLang.forEach((lang) => { // language filter
                if (lodashGet(filter, 'lang', []).includes(lang)) {
                    exist = true;
                }
            });
            return exist;
        });
    }
    if (filter && filter.genre && filter.genre !== 'all_channel') {
        fiteredChannel = fiteredChannel.filter(channel => lodashGet(channel, 'genres', []).map(genre => genre.toUpperCase()).includes(lodashGet(filter, 'genre', '').toUpperCase()));
    }
    if (filter && filter.isHd) {
        fiteredChannel = fiteredChannel.filter(channel => channel.hd); // hd filter
    }

    fiteredChannel = fiteredChannel.sort((a, b) => order.indexOf(lodashGet(a, 'lang[0]', '')) - order.indexOf(lodashGet(b, 'lang[0]', '')));
    return fiteredChannel;
};

export const getMinutes = time => (time ? new Date(time).getHours() * 60 + new Date(time).getMinutes() : new Date().getHours() * 60 + new Date().getMinutes());


export const getSelectedShowDetails = (epgData = [], selectedTime, selectedDate) => {
    let showData = {
        title: '',
        time: '',
        endTimeMin: 0,
    };
    const currentTimestamp = new Date(selectedTime.timeStamp).setDate(selectedDate.date);
    epgData.forEach((show) => {
        const { startTime, endTime } = show;
        if (startTime <= currentTimestamp && currentTimestamp < endTime) {
            showData = {
                ...show,
                time: `${new DateTime(startTime).format('hh:mm a')} - ${new DateTime(endTime).format('hh:mm a')}`,
            };
        }
    });
    return showData;
};
