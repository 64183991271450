import React from 'react';
import propTypes from 'prop-types';
import { ThumborImage } from '../ThumborImage/ThumborImage';

const IMAGESETTINGS = {300: 300};

const TileTag = ({ tagConfig }) => {

    const tagImage = tagConfig?.imgUrl;
    const tagText = tagConfig?.tagText;
    const tagStripStyle = {
        ...(tagConfig.tagStripStyle && tagConfig.tagStripStyle ),
    };

    const imgContainerStyle = {
        ...(tagConfig.imgContainerStyle && tagConfig.imgContainerStyle),
    };

    const imgBackgroundStyle = {
        ...(tagConfig.showBlur && { backdropFilter: `blur(${tagConfig.blurRadius})` }),
        ...(tagConfig.imgBackgroundStyle && tagConfig.imgBackgroundStyle),
    };

    const imgStyle = {
        ...(tagConfig.imgStyle && tagConfig.imgStyle),
    };

    const pictureImgStyle = tagConfig ? tagConfig.pictureImgStyle || {} : {};
    const pictureImgSettings = tagConfig && tagConfig.pictureImgSettings ? tagConfig.pictureImgSettings: IMAGESETTINGS;

    if (!tagConfig.showTag) {
        return null;
    }
    return (
        <div 
            className="tag-container"
            style={{ ...tagStripStyle }}
        >
            <div 
                style={{ ...imgContainerStyle }}
            >
                <div 
                    className="tag-img-background"
                    style={{ ...imgBackgroundStyle }}
                />
                {tagImage ? (
                    <div
                        style={{ ...imgStyle }}
                        className="tag-image"
                    >
                        <ThumborImage
                            className="tag-thumbor"
                            imageStyle={pictureImgStyle}
                            src={tagImage}
                            alt={tagConfig.title || 'tag'}
                            imageSettings={pictureImgSettings}
                            hideCustomAlt
                        />
                    </div>
                ) : (
                    <div
                        style={{ ...imgStyle }}
                        className="tag-text"
                    >
                        {tagText}
                    </div>
                )}

            </div>
        </div>
    );
};

TileTag.defaultProps = {
    tagConfig: {},
};

TileTag.propTypes = {
    tagConfig: propTypes.object,
};
export default TileTag;
