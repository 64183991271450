export const ERROR_ACTIONS = {
    LOGIN: 'LOGIN',
    BUY_SUBSCRIPTION: 'BUY_SUBSCRIPTION',
    RETRY_PLAYBACK: 'RETRY_PLAYBACK',
    ERROR_MESSAGE_ON_PLAYER: 'ERROR_MESSAGE_ON_PLAYER',
    SWITCH_TO_AIRTEL: 'SWITCH_TO_AIRTEL',
    HELP_DESK: 'HELP_DESK',
    SHOW_ERROR_MESSAGE: 'SHOW_ERROR_MESSAGE',
    COMPLETE_REGISTRATION: 'COMPLETE_REGISTRATION',
    CONCURRENT_DEVICES_LIMIT_EXCEEDED: 'CONCURRENT_DEVICES_LIMIT_EXCEEDED',
    MOBILE_APP_PUSH: 'MOBILE_APP_PUSH',
    RETRY_ERROR_PAGE: 'RETRY_ERROR_PAGE',
    GOTO_HOME_ERROR_PAGE: 'GOTO_HOME_ERROR_PAGE',
    SEARCH_AGAIN_ERROR_PAGE: 'SEARCH_AGAIN_ERROR_PAGE',
    NO_ACTION: 'NO_ACTION',
    RIGHTS_NOT_AVAILABLE: 'RIGHTS_NOT_AVAILABLE',
    SAFARI_DRM_MOBILE_APP_PUSH: 'SAFARI_DRM_MOBILE_APP_PUSH',
};

export const ERROR_CODES = {
    // un handled
    UNKNOWN: 'UNKNOWN',

    // PLAYBACK API ERROR CODE
    ATV032: 'ATV032', // Email Id not available
    ATV033: 'ATV033', // content expired
    ATV201: 'ATV201', // Playable Content not found
    ATV202: 'ATV202', // Tiering: Subscription not found or Expired
    ATV203: 'ATV203', // CP Subscription purchase / Registration failed
    ATV204: 'ATV204', // Bundle counter exhausted
    ATV205: 'ATV205', // Unable to fetch steaming url
    ATV206: 'ATV206', // Unable to Register this device | Device Limit exceeded,cannot register more than 5 devices
    ATV207: 'ATV207', // Universal Concurrent Device Limit Exceeded
    ATV208: 'ATV208', // Partner Concurrent Device Limit Exceeded
    ATV209: 'ATV209', // Subscription not found or Expired
    ATV213: 'ATV213', // Content requires age consent from user
    ATV251: 'ATV251', // Switch to Airtel
    ATV252: 'ATV252', // Switch to Airtel
    ATV253: 'ATV253', // Switch to Airtel SIM
    ATV254: 'ATV254', // Switch to Airtel network prepaid
    ATV255: 'ATV255', // Switch to Airtel network non-prepaid
    ATV256: 'ATV256', // Switch to Airtel network
    ATV257: 'ATV257', // Login with Airtel SIM
    ATV258: 'ATV258', // No Hotstar for DTH customers
    ATV426: 'ATV426', // geo blocked
    ATV210: 'ATV210', // content not available on this platform

    // GENERIC ERROR CODES
    ATV998: 'ATV998', // External service failure
    ATV001: 'ATV001', // Invalid input
    ATV002: 'ATV002', // Invalid Otp
    ATV003: 'ATV003', // Otp Expired
    ATV004: 'ATV004', // Invalid Device
    ATV011: 'ATV011', // Request mapping not found
    ATV012: 'ATV012', // People Resource not found
    ATV013: 'ATV013', // Unauthenticated request
    ATV014: 'ATV014', // Content Resource not found
    ATV015: 'ATV015', // No config found
    ATV020: 'ATV020', // User does not exits
    ATV021: 'ATV021', // Product not found
    ATV030: 'ATV030', // Content Partner not found

    ATV031: 'ATV031', // CP Subscription failed : retry after some time

    ATV90: 'ATV90', // Server error : JVM Cache build failed
    ATV999: 'ATV999', // Server crash
    ATV409: 'ATV409', // Conflicted content
    ATV401: 'ATV401', // Unauthorized
    ATV259: 'ATV259', // Redeem Amount Validation Failed
    ATV260: 'ATV260', // Transaction Success already
    ATV261: 'ATV261', // Transaction Failure already
    ATV262: 'ATV262', // Invalid short code for large-screen payment
    ATV404: 'ATV404', // Not found
    ATV410: 'ATV410', // restricted content
    // LOCAL APP LEVEL ERRORS
    LOCAL1001: 'LOCAL1001', // login needed
    LOCAL1002: 'LOCAL1002', // network issue page
    LOCAL1003: 'LOCAL1003', // network issue page handle by toastr
    LOCAL1004: 'LOCAL1004', // content not found
    LOCAL1005: 'LOCAL1005', // no search result found
    LOCAL1006: 'LOCAL1006', // playback url error
    LOCAL1007: 'Network Error', // playback url error
    LOCAL1008: 'LOCAL1008', // retry playback
    LOCAL1009: 'LOCAL1009', // server crashed
    LOCAL1010: 'LOCAL1010', // no package id,
    SERVER404: 'SERVER404', // 404
    CHUNK_FAILED: 'loading chunk .* failed', // chunk error

    // shaka player error codes
    SHAKA_6001: '6001', // drm not supported on mobile
    SHAKA_6007: '6007', // drm license call failed
    SHAKA_6003: '6003', // FAILED_TO_ATTACH_TO_VIDEO
    SHAKA_6015: '6015', // MEDIA_SOURCE_OPERATION_THREW
    SHAKA_3015: '3015', // MEDIA_SOURCE_OPERATION_THREW
    SHAKA_4015: '4015', // #EXTM3U header tag missing
    SHAKA_1002: '1002', // unable to load playback url
    SHAKA_1001: '1001', // unable to load playback url
    SHAKA_4000: '4000',
    SHAKA_7000: '7000',

    SHAKA_BROWSER_NOT_SUPPORTED: 'SHAKA_BROWSER_NOT_SUPPORTED',

    VIDEOJS_3: '3', // drm not supported on mobile MEDIA_ERR_DECODE or some corruption problem
    VIDEOJS_2: '2', // MEDIA_ERR_NETWORK
    VIDEOJS_4: '4', // MEDIA_ERR_SRC_NOT_SUPPORTED
    VIDEOJS_5: '5', // MEDIA_ERR_ENCRYPTED
    VIDEOJS_BROWSER_NOT_SUPPORTED: 'VIDEOJS_BROWSER_NOT_SUPPORTED',

    HTML_MEDIAELEMENT_NATIVE_9: '9',
    HTML_MEDIAELEMENT_NATIVE_11: '11',

    PLAYER_NETWORK_ERROR: 'PLAYER_NETWORK_ERROR',

    ATV041: 'ATV041', // Login again(Invalid token, unauthenticated user) using above api to get auth token
    ATV042: 'ATV042', // Token still valid, refresh not required
    ATV043: 'ATV043', // Token is expired, refresh token using following api
    ATV427: 'ATV427', // uid ya ip blocked

    // HAPPYCODE API ERRORS
    ATV022: 'ATV022', // Error when wrong RTN is entered.
    ATV006: 'ATV006', // Error when wrong happycode is entered.
    ATV007: 'ATV007', // Error when already verified happycode is entered.

    // New Analytics Error Codes
    ATV_CL_6001: 'ATV_CL_6001', // Shaka error code for drm disabled at client
    ATV_CL_809: 'ATV_CL_809', // DATA_SOURCE_EXCEPTION, error while reading from data source
    ATV_CL_810: 'ATV_CL_810', // PARSE_EXCEPTION, erorr while parsing media data and metadata
    ATV_CL_820: 'ATV_CL_820', // Generic DRM error
    ATV_CL_700: 'ATV_CL_700', // Absence of error code in apiAnalytics (undefined case)
    ATV_CL_701: 'ATV_CL_701', // Absence of error code in playback event reporting(undefined case)
    ATV_CL_835: 'ATV_CL_835', // Heartbeat api failure concurrent device
    SAFARI_DRM_UNSUPPORTED_MOBILE_PUSH: 'SAFARI_DRM_UNSUPPORTED_MOBILE_PUSH',

};

export const ANALYTICS_V2_ERROR_TYPES = {
    SYSTEM_ERROR: 'System Error',
    NETWORK_ERROR: 'Network Error',
};

export const ANALYTICS_ERROR_CODES = {
    [ERROR_CODES.SHAKA_6001]: { // Protected ids
        error_code: 'ATV_CL_6001',
        player_error_code: ERROR_CODES.SHAKA_6001,
        error_message: 'REQUESTED_KEY_SYSTEM_CONFIG_UNAVAILABLE',
        error_type: ANALYTICS_V2_ERROR_TYPES.SYSTEM_ERROR,
        is_recoverable: false,
    },
    [ERROR_CODES.SHAKA_1001]: { // Try to load Kancha Lanka content ex 2 Chocolate in mobile view in chrome
        error_code: 'ATV_CDN_403',
        player_error_code: ERROR_CODES.SHAKA_1001,
        error_type: ANALYTICS_V2_ERROR_TYPES.NETWORK_ERROR,
        error_message: 'BAD_HTTP_ERROR',
        is_recoverable: false,
    },
    [ERROR_CODES.SHAKA_1002]: { // cors error or blocked error or some http error while load of url
        error_code: 'ATV_CDN_1002',
        player_error_code: ERROR_CODES.SHAKA_1002,
        error_type: ANALYTICS_V2_ERROR_TYPES.NETWORK_ERROR,
        error_message: 'HTTP Error while uri load',
        is_recoverable: false,
    },
    [ERROR_CODES.LOCAL1003]: { // Network tunred off, use throttling in network tab and swith to offline
        error_code: 'ATV_CL_809',
        player_error_code: ERROR_CODES.LOCAL1003,
        error_type: ANALYTICS_V2_ERROR_TYPES.NETWORK_ERROR,
        error_message: 'No Internet connection',
        is_recoverable: false,
    },
    [ERROR_CODES.SHAKA_4015]: { // HLS Playlist doesn't start with #EXTM3U tag. Ex. Urf ghanta
        error_code: 'ATV_CL_810',
        player_error_code: ERROR_CODES.SHAKA_4015,
        error_message: '#EXTM3U Header tag missing',
        error_type: ANALYTICS_V2_ERROR_TYPES.SYSTEM_ERROR,
        is_recoverable: false,
    },
    [ERROR_CODES.SHAKA_6007]:  { // charles manipulate license requests
        error_code: 'ATV_LCS_6007',
        player_error_code: ERROR_CODES.SHAKA_6007,
        error_message: 'LICENSE_REQUEST_FAILED',
        error_type: ANALYTICS_V2_ERROR_TYPES.NETWORK_ERROR,
        is_recoverable: false,
    },
    [ERROR_CODES.SHAKA_3015]: { // SHAKA_3015: overwrite a segment by charles
        error_code: 'ATV_CL_881',
        player_error_code: ERROR_CODES.SHAKA_3015,
        error_message: 'MEDIA_SOURCE_OPERATION_THREW',
        error_type: ANALYTICS_V2_ERROR_TYPES.SYSTEM_ERROR,
        is_recoverable: false,
    },
    [ERROR_CODES.VIDEOJS_5]: { // Play jehanabad on safari
        error_code: ERROR_CODES.ATV_CL_810,
        player_error_code: ERROR_CODES.VIDEOJS_5,
        error_message: 'MEDIA_ERR_ENCRYPTED',
        error_type: ANALYTICS_V2_ERROR_TYPES.SYSTEM_ERROR,
        is_recoverable: false,
    },
    [ERROR_CODES.VIDEOJS_3]: { // Fired in jehanabad just after videojs_5
        error_code: ERROR_CODES.ATV_CL_820,
        player_error_code: ERROR_CODES.VIDEOJS_3,
        error_message: 'MEDIA_ERR_DECODE',
        error_type: ANALYTICS_V2_ERROR_TYPES.SYSTEM_ERROR,
        is_recoverable: false,
    },
    [ERROR_CODES.HTML_MEDIAELEMENT_NATIVE_9]: {
        error_code: ERROR_CODES.HTML_MEDIAELEMENT_NATIVE_9,
        player_error_code: ERROR_CODES.HTML_MEDIAELEMENT_NATIVE_9,
        error_message: 'HTML_PLAYBACK_RATE_NOT_SUPPORTED_9', // Analytics error code 9 found in opera for one user
        error_type: ANALYTICS_V2_ERROR_TYPES.SYSTEM_ERROR,
        is_recoverable: false,
    },
    [ERROR_CODES.HTML_MEDIAELEMENT_NATIVE_11]: {
        error_code: ERROR_CODES.HTML_MEDIAELEMENT_NATIVE_11,
        player_error_code: ERROR_CODES.HTML_MEDIAELEMENT_NATIVE_11,
        error_message: 'MEDIASOURCE_READYSTATE_NOT_OPEN', // Analytics error code 11 found on shaka player but not documented
        error_type: ANALYTICS_V2_ERROR_TYPES.SYSTEM_ERROR,
        is_recoverable: false,
    },
};

export const ACTION_TO_ERROR_CODE = {
    [ERROR_CODES.LOCAL1001]: ERROR_ACTIONS.LOGIN,
    [ERROR_CODES.ATV013]: ERROR_ACTIONS.LOGIN,
    [ERROR_CODES.ATV401]: ERROR_ACTIONS.LOGIN,
    [ERROR_CODES.ATV041]: ERROR_ACTIONS.LOGIN,

    [ERROR_CODES.ATV204]: ERROR_ACTIONS.BUY_SUBSCRIPTION,
    [ERROR_CODES.ATV209]: ERROR_ACTIONS.BUY_SUBSCRIPTION,
    [ERROR_CODES.ATV210]: ERROR_ACTIONS.BUY_SUBSCRIPTION,

    [ERROR_CODES.ATV207]: ERROR_ACTIONS.CONCURRENT_DEVICES_LIMIT_EXCEEDED,
    [ERROR_CODES.ATV208]: ERROR_ACTIONS.CONCURRENT_DEVICES_LIMIT_EXCEEDED,
    [ERROR_CODES.ATV206]: ERROR_ACTIONS.CONCURRENT_DEVICES_LIMIT_EXCEEDED,

    [ERROR_CODES.ATV201]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.ATV204]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.ATV205]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.ATV031]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.SHAKA_6007]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.SHAKA_6015]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.PLAYER_NETWORK_ERROR]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.VIDEOJS_2]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.VIDEOJS_4]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.SHAKA_1001]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.SHAKA_1002]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.LOCAL1008]: ERROR_ACTIONS.RETRY_PLAYBACK,

    [ERROR_CODES.ATV202]: ERROR_ACTIONS.ERROR_MESSAGE_ON_PLAYER,
    [ERROR_CODES.ATV426]: ERROR_ACTIONS.ERROR_MESSAGE_ON_PLAYER,
    [ERROR_CODES.ATATV427V426]: ERROR_ACTIONS.ERROR_MESSAGE_ON_PLAYER,

    [ERROR_CODES.ATV251]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV252]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV253]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV254]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV255]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV256]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV257]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV258]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,

    [ERROR_CODES.ATV033]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV998]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV001]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV002]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV003]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV004]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV015]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV020]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV021]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV030]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV90]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV999]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV409]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV259]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV260]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV261]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV404]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.UNKNOWN]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.LOCAL1003]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV022]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV006]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV007]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,

    [ERROR_CODES.ATV032]: ERROR_ACTIONS.COMPLETE_REGISTRATION,
    [ERROR_CODES.ATV203]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,

    [ERROR_CODES.SHAKA_6001]: ERROR_ACTIONS.MOBILE_APP_PUSH,
    [ERROR_CODES.SHAKA_BROWSER_NOT_SUPPORTED]: ERROR_ACTIONS.MOBILE_APP_PUSH,
    [ERROR_CODES.VIDEOJS_3]: ERROR_ACTIONS.MOBILE_APP_PUSH,
    [ERROR_CODES.VIDEOJS_BROWSER_NOT_SUPPORTED]: ERROR_ACTIONS.MOBILE_APP_PUSH,

    [ERROR_CODES.ATV014]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.ATV012]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.LOCAL1004]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.ATV011]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.LOCAL1009]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.LOCAL1010]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.ATV262]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.ATV410]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,

    [ERROR_CODES.LOCAL1002]: ERROR_ACTIONS.RETRY_ERROR_PAGE,

    [ERROR_CODES.SHAKA_3015]: ERROR_ACTIONS.NO_ACTION,
    [ERROR_CODES.SHAKA_6003]: ERROR_ACTIONS.NO_ACTION,
    [ERROR_CODES.SHAKA_6015]: ERROR_ACTIONS.NO_ACTION,
    [ERROR_CODES.VIDEOJS_5]: ERROR_ACTIONS.NO_ACTION,
    [ERROR_CODES.ATV043]: ERROR_ACTIONS.NO_ACTION,

    [ERROR_CODES.LOCAL1005]: ERROR_ACTIONS.SEARCH_AGAIN_ERROR_PAGE,
    [ERROR_CODES.SAFARI_DRM_UNSUPPORTED_MOBILE_PUSH]: ERROR_ACTIONS.SAFARI_DRM_MOBILE_APP_PUSH,
};

export const NOTIFYIDS = {
    AGE_CONSENT_POPUP_ADULT: 'AGE_CONSENT_POPUP_ADULT',
    CONCURRENT_PLAYBACK_LIMIT_POPUP: 'CONCURRENT_PLAYBACK_LIMIT_POPUP',
    XPP_CLAIM_PENDING_POP_UP_ID: 'XPP_CLAIM_PENDING_POP_UP_ID',
    XPP_PURCHASE_POP_UP_ID: 'XPP_PURCHASE_POP_UP_ID',
    XPP_FREE_TRIAL_POP_UP_ID: 'XPP_FREE_TRIAL_POP_UP_ID',
    ZEE5_UNAVAILABLE_POPUP: 'ZEE5_UNAVAILABLE_POPUP',
};

export const ACTION_TO_NOTIFYID = {
    [ERROR_CODES.ATV213]: 'AgeClassificationPopup',
};

export const ERROR_SOURCES = {
    ARTIST_CONTAINER: 'Artist',
    LAYOUT_CONTAINER: 'Page',
    CHANNEL_PARTNER: 'Channel_Partner',
    DTH_PAYMENT_PAGE: 'Dth_Payment_Page',
    CONTENT_DETAIL_LAYOUT_CONTAINER: 'ContentDetail'
};

export const ERROR_PLACE_HOLDERS = {
    SOURCE: '{source}',
};

export const ERROR_HANDLING_TYPES = {
    TOAST: 'TOAST',
    MODAL: 'MODAL',
    REDIRECTION: 'REDIRECTION',
    SHOWN_ERROR_PAGE: 'SHOWN_ERROR_PAGE',
    ERROR_PLAYER_COMPONENT: 'ERROR_PLAYER_COMPONENT',
    LOGIN: 'LOGIN',
    NETWORK_ISSUE: 'NETWORK_ISSUE',
    DO_NOTHING: 'DO_NOTHING',
    PAUSE_PLAYBACK: 'PAUSE_PLAYBACK',
};

export const HTTP_STATUS_CODE = {
    FORBIDDEN_403: 403,
};


export default {
    ERROR_ACTIONS,
    ERROR_CODES,
    NOTIFYIDS,
    ACTION_TO_ERROR_CODE,
    ACTION_TO_NOTIFYID,
    ERROR_SOURCES,
    ERROR_PLACE_HOLDERS,
};
