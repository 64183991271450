import React from 'react';
import { PropTypes } from 'prop-types';
import LinkComponent from '../../../../shared/components/link/LinkComponent';
import { LocationUtil } from '@airtel-tv/utils';

const NextEpisodeCtaComponent = ({ link, title, ctaText, nextEpisodeDetails, contentDetailsFetchActionDispatch }) => {
    if (!link) return null;
    return (<button
        className="player-cta-btn bg-red-btn"
        onClick={() => contentDetailsFetchActionDispatch(nextEpisodeDetails.details.refId)}
        title={title}
    >
        <p>{ctaText}</p>
    </button>)
};

NextEpisodeCtaComponent.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    ctaText: PropTypes.string.isRequired,
};

export default React.memo(NextEpisodeCtaComponent);
