import {
    getAppVersion, getBuildNumber, getNetworkQuality, getDeviceType, getAppId,
    getUniqueId,
} from '@airtel-tv/utils/GlobalUtil';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import appStartEvent, {
    clickEvent, fullScreenEvent, playbackErrorEvent, playerInitEvent, playPauseEvent, playStartEvent, playStopEvent, screenVisibleEvent, popUpVisibleEvent, appInitEvent,
} from './FunctionalEvents';
import AnalyticsButtonComponent from './AnalyticsButtonComponent';
import { AnalyticsLinkComponent } from './AnalyticsLinkComponent';
import EventModel from './EventModel';


function getAppSessionId() {
    if (checkWindowExist()) {
        let sessionId = sessionStore.get(LOCAL_STORE_KEYS.APP_SESSION_ID);
        if (!sessionId) {
            const uniqueId = getUniqueId();
            sessionStore.set(LOCAL_STORE_KEYS.APP_SESSION_ID, uniqueId);
            sessionId = uniqueId;
        }
        return sessionId;
    }
    return null;
}

function getThanksAppSessionId() {
    if (checkWindowExist()) {
        const sessionId = sessionStore.get(LOCAL_STORE_KEYS.THANKS_APP_SESSION_ID);
        if (!sessionId) {
            return null;
        }
        return sessionId;
    }
    return null;
}

export const Analytics = {
    initialize: ({
        deviceUtil = { }, experimentDetails = '', userLocation = {}, uid = '', msisdnNo = '', campaignDetails,
    }) => {
        EventModel.appid = AuthenticationUtil.getAppId() || getAppId();
        EventModel.bn = deviceUtil.getBuildNumberFromLocal() || getBuildNumber();
        EventModel.av = deviceUtil.getAppVersionFromLocal() || getAppVersion();
        EventModel.did = deviceUtil.getDid();
        EventModel.model = deviceUtil.getBrowserVersion();
        EventModel.brand = deviceUtil.getBrowserName();
        EventModel.dname = deviceUtil.getDeviceName();
        EventModel.dt = deviceUtil.getDeviceTypeFromLocal() || getDeviceType();
        EventModel.lc = deviceUtil.getUserAgent();
        EventModel.user_agent = deviceUtil.getUserAgent();
        EventModel.platform = deviceUtil.getPlatform();
        EventModel.nq = getNetworkQuality();
        EventModel.nt = deviceUtil.getNetworkType();
        EventModel.os = deviceUtil.getOSName();
        EventModel.ov = deviceUtil.getOSVersion();
        EventModel.uid = uid || AuthenticationUtil.getUid();
        EventModel.app_session_id = getAppSessionId();
        EventModel.thanks_app_session_id = getThanksAppSessionId();
        if (userLocation && Object.keys(userLocation).length > 0) {
            EventModel.city = userLocation.city;
            EventModel.country = userLocation.country;
            // EventModel.countryCode = userLocation.countryCode;
            // EventModel.ip = userLocation.ip;
            EventModel.state = userLocation.state;
            // EventModel.stateCode = userLocation.stateCode;
        }

        if (msisdnNo) {
            EventModel.msisdnNo = msisdnNo;
        }
        if (experimentDetails) {
            EventModel.ab_test = experimentDetails;
        }
        if (campaignDetails && Object.keys(campaignDetails).length) {
            EventModel.meta = {
                ...EventModel.meta,
                ...campaignDetails,
            };
        }
    },
    update: (updatedKeys = {}) => {
        if (Object.keys(updatedKeys).length > 0) {
            Object.keys(updatedKeys).forEach((key) => {
                EventModel[key] = updatedKeys[key];
            });
        }
    },
};

export {
    appStartEvent, clickEvent, fullScreenEvent, screenVisibleEvent, playbackErrorEvent, playerInitEvent, playPauseEvent, playStartEvent, playStopEvent, AnalyticsButtonComponent, AnalyticsLinkComponent, popUpVisibleEvent, appInitEvent,
};
