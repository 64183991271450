import { PropTypes } from 'prop-types';
import React from 'react';

const PlayerLoaderComponent = ({ pause }) => (
    /* eslint-disable  */
    <div
        className="vdo-player__overlay--visible"
        onClick={pause}
    >
        {/* eslint-enable */}

        {/* ## PART CENTER - LOADER */}
        <div className="vdo-player__center-controls d-flex justify-content-between align-items-center">
            {/* ## LOADER */}
            <div
                className="vdo-player__loader"
                id="atm_video-player-loader"
            >
                <div className="d-flex justify-content-center align-items-center">
                    <div className="atl-loader__ring">
                        <div className="lds-ring lds-ring--small">
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

PlayerLoaderComponent.defaultProps = {
    pause: () => { },
};

PlayerLoaderComponent.propTypes = {
    pause: PropTypes.func,
};

export default PlayerLoaderComponent;
