import React from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { DropDownComponent } from './DropDownComponent';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { formQueryParams, joinDimensions } from '../helper/DimensionPageHelper';
import {
    DROPDOWN_NAMES, PROGRAM_TYPES, DIMENSION_SORT_OPTIONS, DIMENSION_SORT_API_OPTION, DIMENSION_SORT_DISPLAY, DIMENSION_PROGRAMTYPE_MAP, DIMENSION_PAGE_MAP,
} from '../../../constants/AppConst';
import { getLangfromCode } from '../../../utilities/CommonUtil';

export const FilterComponent = (props) => {
    const {
        languageMap, genres, pageTitle, lang, railTitle, dropDownItemClicked, sortDropDownItemClicked, sortAlphabetic, sortRecent, isMobile, isBot, genreList, windowWidth, history, showFilter,
    } = props;

    const isLangSelected = lang && lang.length > 0;
    const isGenreSelected = genres && genres.length > 0;

    const languageItems = languageMap.map((language) => {
        const queryParams = formQueryParams({
            lang: lodashGet(language, 'code', ''),
            pageTitle: joinDimensions({
                params: pageTitle,
                seperator: ',',
            }),
        });
        const langName = lodashGet(language, 'name', '');
        return {
            name: langName,
            queryParams,
            redirectionLink: RoutingUtil.getDimensionPageUrlV2({
                lang: langName !== DROPDOWN_NAMES.ALL ? langName : '',
                pageTitle: DIMENSION_PAGE_MAP[pageTitle[0]],
                queryParams,
            }),
        };
    });
    const genreItems = genreList.map((genre) => {
        const genreName = lodashGet(genre, 'name', '');
        const queryParams = formQueryParams({
            genres: genreName !== DROPDOWN_NAMES.ALL ? genreName : '',
            lang: joinDimensions({
                params: lang,
                seperator: ',',
            }),
            pageTitle: joinDimensions({
                params: pageTitle,
                seperator: ',',
            }),
        });
        return {
            name: genreName,
            queryParams,
            redirectionLink: RoutingUtil.getDimensionPageUrlV2({
                lang: isLangSelected ? getLangfromCode({ lang: lang[0] }) : null,
                genres: genreName !== DROPDOWN_NAMES.ALL ? genreName : '',
                pageTitle: DIMENSION_PAGE_MAP[pageTitle[0]],
                queryParams,
            }),
        };
    });
    const pageTitleArray = [
        PROGRAM_TYPES.MOVIE,
        PROGRAM_TYPES.TVSHOW,
        // PROGRAM_TYPES.LIVETVCHANNEL,
        PROGRAM_TYPES.VIDEO,
        // PROGRAM_TYPES.LIVETV,
    ];
    const pageTitleItem = pageTitleArray.map((type) => {
        const queryParams = formQueryParams({
            pageTitle: type,
        });
        return {
            name: DIMENSION_PROGRAMTYPE_MAP[type],
            queryParams,
            redirectionLink: RoutingUtil.getDimensionPageUrlV2({
                pageTitle: DIMENSION_PAGE_MAP[type],
                queryParams,
            }),
        };
    });
    const sortOptions = [
        DIMENSION_SORT_OPTIONS.ALPHABETIC_ASCENDING,
        DIMENSION_SORT_OPTIONS.ALPHABETIC_DESCENDING,
        DIMENSION_SORT_OPTIONS.NEWLY_ADDED,
    ];
    const sortOptionsItems = sortOptions.map((option) => {
        const queryParams = formQueryParams({
            genres: joinDimensions({
                params: genres,
                seperator: ',',
            }),
            lang: joinDimensions({
                params: lang,
                seperator: ',',
            }),
            pageTitle: joinDimensions({
                params: pageTitle,
                seperator: ',',
            }),
            sortAlphabetic: (option === DIMENSION_SORT_OPTIONS.ALPHABETIC_ASCENDING || option === DIMENSION_SORT_OPTIONS.ALPHABETIC_DESCENDING) ? DIMENSION_SORT_API_OPTION[option] : '',
            sortRecent: option === DIMENSION_SORT_OPTIONS.NEWLY_ADDED ? DIMENSION_SORT_API_OPTION[option] : '',
        });
        return {
            name: DIMENSION_SORT_DISPLAY[option],
            queryParams,
            redirectionLink: RoutingUtil.getDimensionPageUrlV2({
                lang: isLangSelected ? getLangfromCode({ lang: lang[0] }) : null,
                genres: isGenreSelected ? genres[0] : null,
                pageTitle: DIMENSION_PAGE_MAP[pageTitle[0]],
                queryParams,
            }),
        };
    });
    const languageDropDown = (
        <DropDownComponent
            items={languageItems}
            title={isLangSelected ? getLangfromCode({ lang: lang[0] }) : DROPDOWN_NAMES.LANGUAGE}
            dropDownItemClicked={dropDownItemClicked}
            selectedItem={isLangSelected ? getLangfromCode({ lang: lang[0] }) : DROPDOWN_NAMES.ALL}
            isMobile={isMobile}
            isBot={isBot}
            windowWidth={windowWidth}
            history={history}
        />
    );
    const genreDropDown = (
        <DropDownComponent
            items={genreItems}
            title={isGenreSelected ? genres[0] : DROPDOWN_NAMES.GENRE}
            dropDownItemClicked={dropDownItemClicked}
            selectedItem={isGenreSelected ? genres[0] : DROPDOWN_NAMES.ALL}
            isMobile={isMobile}
            isBot={isBot}
            windowWidth={windowWidth}
            history={history}
        />
    );
    const pageTitleDropDown = (
        <DropDownComponent
            items={pageTitleItem}
            title={DIMENSION_PROGRAMTYPE_MAP[pageTitle[0]] || DROPDOWN_NAMES.TYPE}
            dropDownItemClicked={dropDownItemClicked}
            selectedItem={DIMENSION_PROGRAMTYPE_MAP[pageTitle[0]]}
            isMobile={isMobile}
            isBot={isBot}
            windowWidth={windowWidth}
            history={history}
        />
    );
    let selectedSortOption = DROPDOWN_NAMES.NONE;
    if (sortAlphabetic) {
        selectedSortOption = sortAlphabetic === DIMENSION_SORT_API_OPTION[DIMENSION_SORT_OPTIONS.ALPHABETIC_ASCENDING] ? DIMENSION_SORT_OPTIONS.ALPHABETIC_ASCENDING : DIMENSION_SORT_OPTIONS.ALPHABETIC_DESCENDING;
    }
    if (sortRecent) {
        selectedSortOption = DIMENSION_SORT_OPTIONS.NEWLY_ADDED;
    }
    const sortDropDown = (
        <DropDownComponent
            items={sortOptionsItems}
            title={DIMENSION_SORT_DISPLAY[selectedSortOption] || DROPDOWN_NAMES.SORT}
            sortDropDownItemClicked={sortDropDownItemClicked}
            selectedItem={DIMENSION_SORT_DISPLAY[selectedSortOption]}
            isMobile={isMobile}
            isBot={isBot}
            isSortDropDown
            windowWidth={windowWidth}
            history={history}
        />
    );
    return (
        <div
            className="title title-with-cta d-flex justify-content-between align-items-center dimensionFilters"
            id="dimensionFilters"
        >
            <div>
                <h1
                    className={`mb-0 ${isMobile ? 'mobileTitle' : 'desktopTitle'}`}
                    id="railTitle"
                >
                    {railTitle}
                </h1>
            </div>
            {showFilter ? (
                <section className="filter_container">
                    {pageTitleDropDown}
                    {languageDropDown}
                    {genreDropDown}
                    {sortDropDown}
                </section>
            ) : null}
        </div>
    );
};
FilterComponent.propTypes = {
    languageMap: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    genres: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    pageTitle: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    lang: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    railTitle: PropTypes.string,
    dropDownItemClicked: PropTypes.func.isRequired,
    sortDropDownItemClicked: PropTypes.func.isRequired,
    sortAlphabetic: PropTypes.string,
    sortRecent: PropTypes.string,
    isMobile: PropTypes.bool,
    isBot: PropTypes.bool,
    genreList: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    windowWidth: PropTypes.number,
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showFilter: PropTypes.bool,
};
FilterComponent.defaultProps = {
    languageMap: [],
    genres: [],
    pageTitle: [
        PROGRAM_TYPES.MOVIE,
    ],
    lang: [],
    railTitle: '',
    sortAlphabetic: '',
    sortRecent: '',
    isMobile: false,
    isBot: false,
    genreList: [],
    windowWidth: 1440,
    showFilter: true,
};
export default FilterComponent;
