import DeviceUtil from '@airtel-tv/utils/DeviceUtil';

const openDeepLinkFromWebView = (deeplink) => {
    const isIOS = DeviceUtil.getDeviceUtil().isIOS();
    // window.MyAirtelAppReact
    const paramName = isIOS ? 'au' : 'au';
    const url = `myairtel://web?${paramName}=${deeplink}`;
    if (isIOS) {
        window?.webkit?.messageHandlers?.openDeeplink?.postMessage(url);
    }
    else {
        window.open(url, '_self');
    }
};

export default openDeepLinkFromWebView;
