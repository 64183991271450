import React, { Component } from 'react';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';

function withDeviceUtil(WrappedComponent) {
    class DeviceUtilWrapper extends Component {
        constructor(props, context) {
            super(props);
            this.deviceUtil = DeviceUtil.getDeviceUtil() || {};
        }

        render() {
            return (
                <WrappedComponent
                    deviceUtil={this.deviceUtil}
                    {...this.props}
                />
            );
        }
    }


    return DeviceUtilWrapper;
}

export { withDeviceUtil };
