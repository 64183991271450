import FooterActions from '../actions/FooterAction';


const initialState = JSON.parse('{}');

const FooterReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case FooterActions.CONTENT_PAGE_FOOTER_COMPLETED:
            return {
                ...state,
                ...action.payload,
            };
        case FooterActions.PAGE_DESCRIPTION_UPDATE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default FooterReducer;
