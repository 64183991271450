import { LocationUtil } from './LocationUtil';
import { IMAGE_TYPE } from '../constants/BrowserConst';
import { AppEnv } from './GetEnv';

export class ThumborUtil {
    static getThumborUrl = (width, height, url, imageFormat, quality) => {
        const {
            endpoints: {
                thumbor: {
                    base,
                    paths: {
                        unsafe,
                    },
                    filter: {
                        format,
                    },
                },
            },
            env,
        } = AppEnv.getEnv();
        const prefix = `${base}${unsafe}`;
        if ([
            IMAGE_TYPE.SVG,
            IMAGE_TYPE.GIF,
            IMAGE_TYPE.JSON,
        ].includes(LocationUtil.getUrlExtension(url).toLowerCase())) {
            return url;
        }
        if(url.includes(prefix) || url.includes('https://img.airtel.tv/unsafe/filters')){
             return `${url}?o=${env}`;
        }
        return `${prefix}${width}x${height}/${format}(${imageFormat})${quality ? `:quality(${quality})` : ''}/${url}?o=${env}`;
    };
}
