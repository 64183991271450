export const NAVIGATION_ACTIONS = {
    SIDE_BAR_ACTION: 'SIDE_BAR_ACTION',
    SET_FOCUSED_ELEMENT: 'SET_FOCUSED_ELEMENT',
    SAVE_BACK_BUTTON_SELECTOR: 'SAVE_BACK_BUTTON_SELECTOR',
    SIDE_BAR_CLOSED_WIDTH: 'SIDE_BAR_CLOSED_WIDTH',
};

export const changeSideBarStatus = payload => ({
    type: NAVIGATION_ACTIONS.SIDE_BAR_ACTION,
    payload: { ...payload },
});

export const setFocusedElement = payload => ({
    type: NAVIGATION_ACTIONS.SET_FOCUSED_ELEMENT,
    payload: { ...payload },
});

export const saveBackButtonSelector = payload => ({
    type: NAVIGATION_ACTIONS.SAVE_BACK_BUTTON_SELECTOR,
    payload: { ...payload },
});

export const sideBarClosedWidth = payload => ({
    type: NAVIGATION_ACTIONS.SIDE_BAR_CLOSED_WIDTH,
    payload: { ...payload },
});

export const syncLanguagedetail = {};

export default {
    changeSideBarStatus,
};
