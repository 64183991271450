import { MARKUP_CONTEXT, MARKUP_TYPES } from '../../../../constants/MarkupConsts';


export default function WebsiteMarkupParser({ baseUrl }) {
    return {
        '@context': MARKUP_CONTEXT.URL,
        '@type': MARKUP_TYPES.WEBSITE,
        url: baseUrl,
        potentialAction: {
            '@type': MARKUP_TYPES.SEARCH_ACTION,
            target: 'https://www.airtelxstream.in/search/{search_term_string}?q={search_term_string}',
            'search_term_string': 'required',
        },
    };
}
