const SubscriptionAction = {
    SUBSCRIPTION_INIT: 'SUBSCRIPTION_INIT',
    MANAGE_SUBSCRIPTION_INIT: 'MANAGE_SUBSCRIPTION_INIT',
    SUBSCRIPTION_ERROR: 'SUBSCRIPTION_ERROR',
};

export default SubscriptionAction;

export const subscriptionInit = payload => ({
    type: SubscriptionAction.SUBSCRIPTION_INIT,
    ...payload,
});

export const manageSubscriptionInit = payload => ({
    type: SubscriptionAction.MANAGE_SUBSCRIPTION_INIT,
    ...payload,
});

export const subscriptionError = payload => ({
    type: SubscriptionAction.SUBSCRIPTION_ERROR,
    ...payload,
});
