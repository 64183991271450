import { DIMENSION_URL_TO_TYPE, DIMENSION_PAGE_MAP } from '../../../constants/AppConst';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { formQueryParams } from '../../dimension-pages/helper/DimensionPageHelper';

const MegaMenuType = {
    FILTER: 'FILTER',
};

function filterPageLinkBuilder(item) {
    try {
        const programType = DIMENSION_URL_TO_TYPE[item.menuSrc];
        return RoutingUtil.getDimensionPageUrlV2({
            pageTitle: DIMENSION_PAGE_MAP[programType],
            lang: item.title.toLowerCase() !== 'more' ? item.title.toLowerCase() : '',
            genres: item.genres,
            queryParams: formQueryParams({
                lang: item.lgCode,
                pageTitle: programType,
                genres: item.genres,
            }),
        });
    }
    catch (e) {
        console.error(e);
        return '/';
    }
}

export function LinkBuilder({ item }) {
    switch (item.superType) {
        case MegaMenuType.FILTER:
            return filterPageLinkBuilder(item);
        default:
            return null;
    }
}

export default LinkBuilder;
