import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';

class UserStorageUtil {
    static clearTempLang = () => {
        browserStore.remove(LOCAL_STORE_KEYS.TEMP_LANG);
    };

    static updateTempLang = (lang) => {
        browserStore.set(LOCAL_STORE_KEYS.TEMP_LANG, lang);
    };

    static getTempLang = () => browserStore.get(LOCAL_STORE_KEYS.TEMP_LANG);
}

export default UserStorageUtil;
