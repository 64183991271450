const ModalPopupAction = {
    SHOW_MODAL_POPUP: 'SHOW_MODAL_POPUP',
    HIDE_MODAL_POPUP: 'HIDE_MODAL_POPUP',
};

export default ModalPopupAction;

export const showModalComponentAction = payload => ({
    type: ModalPopupAction.SHOW_MODAL_POPUP,
    ...payload,
});

export const hideModalComponentAction = payload => ({
    type: ModalPopupAction.HIDE_MODAL_POPUP,
    ...payload,
});
