import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import {
    ACTION_MODALS_TYPES, IMAGE_PATHS, ERROR_PAGE_ACTIONS,
} from '@airtel-tv/constants/GlobalConst';
import { isOnline } from '@airtel-tv/utils/WindowUtil';
import { getPlaceholderFromLocal, replaceAll } from '@airtel-tv/utils/GlobalUtil';
import { OS_LIST, BROWSER_LIST, DESKTOP_OS_LIST } from '@airtel-tv/constants/BrowserConst';
import { updateUserConfigPurgeLayout } from '@airtel-feature/layout/actions/LayoutActions';
import { heartBeatStopPlayback, closePlayer } from '@airtel-feature/playback/actions/PlaybackActions';
import {
    ERROR_ACTIONS,
    ACTION_TO_ERROR_CODE,
    ERROR_PLACE_HOLDERS,
    ACTION_TO_NOTIFYID,
    NOTIFY_ID_MODAL_PAYLOAD,
    RELOAD_LAYOUT_POPUPS,
} from './ErrorCodes';
import { ERROR_HANDLING_TYPES } from './ErrorConst';
import { triggerLogin, clearAccessToken } from '../auth/AuthAction';
import {
    addToast, showActionModal, showErrorPage,
    redirectTo,
} from '../notify/NotifyActions';
import { showEmailConfirmation, showNonAirtelUserAction } from '../user/UserAction';
import LanguageProvider from '../../providers/LanguageProvider';
import { showModalComponentAction } from '../modal-popup/ModalPopupActions';

let reduxStoreDispatch = () => null;

export const setErrorHandlerDispatch = (dispatch) => {
    reduxStoreDispatch = dispatch;
};

const customizeErrorMessageForDevice = (errorAction, os, browser) => {
    const LANGUAGE = LanguageProvider();

    const ERROR_ACTION_MESSAGE_MAPPING = {
        [ERROR_ACTIONS.MOBILE_APP_PUSH]: {
            [OS_LIST.OSX]: {
                [BROWSER_LIST.SAFARI]: LANGUAGE.BROWSER_NOT_SUPPORTED,
                DEFAULT: LANGUAGE.OPEN_IN_SUPPORTED_BROWSER_OPEN_SAFARI,
            },
            [OS_LIST.IOS]: {
                [BROWSER_LIST.SAFARI]: LANGUAGE.BROWSER_NOT_SUPPORTED,
                DEFAULT: LANGUAGE.OPEN_IN_SUPPORTED_BROWSER_OPEN_SAFARI,
            },
            [OS_LIST.WINDOWS]: {
                [BROWSER_LIST.CHROME]: LANGUAGE.BROWSER_NOT_SUPPORTED,
                DEFAULT: LANGUAGE.OPEN_IN_SUPPORTED_BROWSER_OPEN_CHROME,
            },
            [OS_LIST.LINUX]: {
                [BROWSER_LIST.CHROME]: LANGUAGE.BROWSER_NOT_SUPPORTED,
                DEFAULT: LANGUAGE.OPEN_IN_SUPPORTED_BROWSER_OPEN_CHROME,
            },
            [OS_LIST.ANDROID]: {
                [BROWSER_LIST.CHROME]: LANGUAGE.BROWSER_NOT_SUPPORTED,
                DEFAULT: LANGUAGE.OPEN_IN_SUPPORTED_BROWSER_OPEN_CHROME,
            },
            DEFAULT: LANGUAGE.DEVICE_IS_NOT_SUPPORTED,
        },
    };

    if (!ERROR_ACTION_MESSAGE_MAPPING[errorAction]) {
        return null;
    }

    if (!ERROR_ACTION_MESSAGE_MAPPING[errorAction][os]) {
        return ERROR_ACTION_MESSAGE_MAPPING[errorAction].DEFAULT;
    }

    if (!ERROR_ACTION_MESSAGE_MAPPING[errorAction][os][browser]) {
        return ERROR_ACTION_MESSAGE_MAPPING[errorAction][os].DEFAULT;
    }

    return ERROR_ACTION_MESSAGE_MAPPING[errorAction][os][browser];
};

export default function ErrorHandler({
    code = '',
    doNotHandle = false,
    error = {},
    source = '',
    browser = '',
    os = '',
    contentId = '',
    errortitle = '',
    storeDispatch = null, // for SSR
    errorTitle = '',
    showToastOnly = false,
    resetLayout = false,
    goBack = false,
    isTrailer = false,
}) {
    const LANGUAGE = LanguageProvider();

    const dispatch = storeDispatch || reduxStoreDispatch;

    let errorData = {
        message: error.appErrorMessage || errortitle || LANGUAGE[code] || LANGUAGE[ERROR_CODES.UNKNOWN],
    };
    const { notifyId = null } = error;

    const notifyIdPopup = ACTION_TO_NOTIFYID[code] || ACTION_TO_NOTIFYID[notifyId];
    if (notifyId && notifyIdPopup) {
        const payload = NOTIFY_ID_MODAL_PAYLOAD[code] || NOTIFY_ID_MODAL_PAYLOAD[notifyId];
        if (payload.closePlayer) {
            dispatch(closePlayer({ closePlayer: true }));
        }
        const timeout = payload.closePlayer ? 100 : 0;
        if (RELOAD_LAYOUT_POPUPS.includes(notifyId)) {
            if (resetLayout) {
                dispatch(updateUserConfigPurgeLayout());
            }
            payload.resetLayoutPopups = true;
        }
       
        setTimeout(() => {
            dispatch(showModalComponentAction({
                showModalValue: true,
                componentNameValue: notifyIdPopup,
                overrideCrossButtonValue: payload.overrideCrossButtonValue,
                payload: {
                    ...payload,
                    notifyId,
                    contentId,
                    isTrialEnded: true,
                    isTrailer,
                },
            }));
        }, timeout);
        return;
    }
    if (showToastOnly) {
        dispatch(addToast({
            toast: {
                message: errorData.message,
                buttonText: LANGUAGE.BUTTON_CLOSE,
            },
        }));
        return;
    }
    if (code) {
        const action = ACTION_TO_ERROR_CODE[code];

        if (action === ERROR_ACTIONS.NO_ACTION) {
            return {
                handledBy: ERROR_HANDLING_TYPES.DO_NOTHING,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.LOGIN) {
            if (!doNotHandle) {
                dispatch(clearAccessToken()); // clear access token to mentation auth state in playback container, FYI: right now there are 2 login state 1. auth token 2. access token
                dispatch(triggerLogin({
                    loginTriggered: true,
                }));
            }

            return {
                handledBy: ERROR_HANDLING_TYPES.LOGIN,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE) {
            if (!doNotHandle) {
                const errorMessage = replaceAll(LANGUAGE[code] || LANGUAGE[ERROR_CODES.UNKNOWN], ERROR_PLACE_HOLDERS.SOURCE, source);
                dispatch(showErrorPage({
                    errorPageData: {
                        message: errorMessage,
                        imageUrl: IMAGE_PATHS[code] || IMAGE_PATHS.BUY_SUBSCRIPTION,
                        errorAction: ERROR_PAGE_ACTIONS.GOTO_HOME,
                        buttonText: LANGUAGE.BUTTON_GOTO_HOME,
                    },
                }));
            }

            return {
                handledBy: ERROR_HANDLING_TYPES.SHOWN_ERROR_PAGE,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.SEARCH_AGAIN_ERROR_PAGE) {
            if (!doNotHandle) {
                dispatch(showErrorPage({
                    errorPageData: {
                        message: LANGUAGE[code] || LANGUAGE[ERROR_CODES.UNKNOWN],
                        imageUrl: IMAGE_PATHS.BUY_SUBSCRIPTION,
                        // errorAction: ERROR_PAGE_ACTIONS.SEARCH,
                        // buttonText: LANGUAGE.BUTTON_RETRY,
                    },
                }));
            }

            return {
                handledBy: ERROR_HANDLING_TYPES.SHOWN_ERROR_PAGE,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.RETRY_ERROR_PAGE) {
            if (!doNotHandle) {
                dispatch(showErrorPage({
                    errorPageData: {
                        message: LANGUAGE[code] || LANGUAGE[ERROR_CODES.UNKNOWN],
                        imageUrl: IMAGE_PATHS.BUY_SUBSCRIPTION,
                        errorAction: ERROR_PAGE_ACTIONS.RELOAD,
                        buttonText: LANGUAGE.BUTTON_RETRY,
                    },
                }));
            }

            return {
                handledBy: ERROR_HANDLING_TYPES.SHOWN_ERROR_PAGE,
                errorData,
                action,
            };
        }
        if (action === ERROR_ACTIONS.BUY_SUBSCRIPTION) {
            if (!doNotHandle) {
                dispatch(showActionModal({
                    actionModalData: {
                        errorCode: `${LANGUAGE.PLACEHOLDER_ERROR_TITLE} ${code}`,
                        message: LANGUAGE[code] || LANGUAGE[ERROR_CODES.UNKNOWN],
                        identifier: ACTION_MODALS_TYPES.BUY_SUBSCRIPTION,
                        buttonText: LANGUAGE.BUTTON_BUY_SUBSCRIPTION,
                        imageUrl: IMAGE_PATHS.BUY_SUBSCRIPTION,
                    },
                }));
            }

            return {
                handledBy: ERROR_HANDLING_TYPES.REDIRECTION,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.RETRY_PLAYBACK) {
            errorData.buttonText = LANGUAGE.BUTTON_RETRY;

            // if (!doNotHandle) {
            //     return dispatch(playbackErrorPopUp());
            // }
            return {
                handledBy: ERROR_HANDLING_TYPES.ERROR_PLAYER_COMPONENT,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.ERROR_MESSAGE_ON_PLAYER) {
            if (doNotHandle) {
                return {
                    handledBy: ERROR_HANDLING_TYPES.ERROR_PLAYER_COMPONENT,
                    errorData: {
                        ...errorData,
                        subText: LANGUAGE.REFRESH_FOR_SUBSCRIPTION_UPDATE,
                        message: LANGUAGE.GET_SUBSCRIPTION,
                        buttonText: LANGUAGE.SUBSCRIBE_NOW,
                        redirection: true,
                    },
                    action,
                };
            }
            return {
                handledBy: ERROR_HANDLING_TYPES.ERROR_PLAYER_COMPONENT,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.SWITCH_TO_AIRTEL) {
            if (goBack) {
                dispatch(redirectTo({ redirectUrl: -1 }));
            }
            if (!doNotHandle) {
                setTimeout(() => {
                    dispatch(showNonAirtelUserAction());
                }, 200);
            }

            return {
                handledBy: ERROR_HANDLING_TYPES.SHOWN_ERROR_PAGE,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.HELP_DESK) {
            if (!doNotHandle) {
                dispatch(showActionModal({
                    actionModalData: {
                        errorCode: `${LANGUAGE.PLACEHOLDER_ERROR_TITLE} ${code}`,
                        message: LANGUAGE[code] || LANGUAGE[ERROR_CODES.UNKNOWN],
                        identifier: ACTION_MODALS_TYPES.HELP_DESK,
                        buttonText: LANGUAGE.BUTTON_HELP_DESK,
                        imageUrl: IMAGE_PATHS.HELP_DESK,
                    },
                }));
            }

            return {
                handledBy: ERROR_HANDLING_TYPES.REDIRECTION,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.CONCURRENT_DEVICES_LIMIT_EXCEEDED) {
            errorData.buttonText = LANGUAGE.BUTTON_RETRY;
            if (errorTitle) {
                errorData.message = errorTitle;
            }
            if (!doNotHandle) {
                dispatch(heartBeatStopPlayback({
                    contentId,
                    errorData: {
                        ...errorData,
                        errorcode: code,
                    },
                }));
                // dispatch(showActionModal({
                //     actionModalData: {
                //         errorCode: `${LANGUAGE.PLACEHOLDER_ERROR_TITLE} ${code}`,
                //         message: LANGUAGE[code] || LANGUAGE[ERROR_CODES.UNKNOWN],
                //         identifier: ACTION_MODALS_TYPES.MANAGE_DEVICES,
                //         buttonText: LANGUAGE.BUTTON_CONCURRENT_DEVICES,
                //         imageUrl: IMAGE_PATHS.MANAGE_DEVICES,
                //     },
                // }));
            }

            return {
                handledBy: ERROR_HANDLING_TYPES.PAUSE_PLAYBACK,
                errorData: {
                    ...errorData,
                    errorcode: code,
                },
                action,
            };
        }

        if (action === ERROR_ACTIONS.MOBILE_APP_PUSH) {
            errorData.buttonText = LANGUAGE.BUTTON_OPEN_IN_APP;
            errorData.message = customizeErrorMessageForDevice(ERROR_ACTIONS.MOBILE_APP_PUSH, os, browser) || LANGUAGE.LABEL_OPEN_IN_APP;
            if (code == ERROR_CODES.SHAKA_6001 && browser == BROWSER_LIST.CHROME && DESKTOP_OS_LIST.includes(os)) {
                errorData.message = LANGUAGE.CDP_CHROME_ERROR_MESSAGE || getPlaceholderFromLocal('CDP_CHROME_ERROR_MESSAGE');
                errorData.extendedMsg = true;
            }
            return {
                handledBy: ERROR_HANDLING_TYPES.ERROR_PLAYER_COMPONENT,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.SAFARI_DRM_MOBILE_APP_PUSH) {
            errorData.buttonText = LANGUAGE.BUTTON_OPEN_IN_APP;
            errorData.message = LANGUAGE.PLATFORM_NOT_SUPPORTED || getPlaceholderFromLocal('PLATFORM_NOT_SUPPORTED');

            return {
                handledBy: ERROR_HANDLING_TYPES.ERROR_PLAYER_COMPONENT,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.COMPLETE_REGISTRATION) {
            if (!doNotHandle) {
                dispatch(showEmailConfirmation());
            }

            return {
                handledBy: ERROR_HANDLING_TYPES.MODAL,
                errorData,
                action,
            };
        }

        if (action === ERROR_ACTIONS.SHOW_ERROR_MESSAGE) {
            if (!doNotHandle && LANGUAGE[code]) {
                // SHOW_ERROR_MESSAGE is default
                dispatch(addToast({
                    toast: {
                        message: LANGUAGE[code],
                        buttonText: LANGUAGE.BUTTON_CLOSE,
                    },
                }));
            }

            return {
                handledBy: ERROR_HANDLING_TYPES.TOAST,
                errorData,
                action,
            };
        }
    }

    // network error
    if (!isOnline()) {
        errorData = {
            message: LANGUAGE.ERROR_NETWORK,
            buttonText: LANGUAGE.BUTTON_RETRY,
        };

        return {
            handledBy: ERROR_HANDLING_TYPES.NETWORK_ISSUE,
            errorData,
        };
    }

    if (!doNotHandle) {
        // SHOW_ERROR_MESSAGE is default
        if (errorData.message !== LANGUAGE.UNKNOWN) {
            dispatch(addToast({
                toast: {
                    message: errorData.message,
                    buttonText: LANGUAGE.BUTTON_CLOSE,
                },
            }));
        }
    }

    return {
        handledBy: ERROR_HANDLING_TYPES.TOAST,
        errorData,
    };
}
