import { combineReducers } from 'redux';
import PlansAndSubscriptionReducer from '@airtel-feature/plansAndSubscription/PlansAndSubscriptionReducer';
import AppConfigReducer from '../modules/app/reducers/AppConfigReducer';
import PlanConfigReducer from '../modules/plan/PlanConfigReducer';
import SubscriptionsReducer from '../modules/subscription-details/reducer/SubscriptionsReducer';
import AuthReducer from '../modules/auth/AuthReducer';
import ChannelPartnerReducer from '../modules/channel-partner/ChannelPartnerReducer';
import ContentDetailsReducer from '@airtel-feature/content-details/content-details/reducers/ContentDetailsReducer';
import PlaybackReducer from '@airtel-feature/playback/reducers/PlaybackReducer';
import UserReducer from '../modules/user/UserReducer';
import NotifyReducer from '@airtel-feature/notify/NotifyReducer';
import LocationReducer from '@airtel-feature/location/LocationReducer';
import RelatedContentReducer from '../modules/rail/reducers/RelatedContentRecuder';
import ArtistReducer from '../modules/artist/ArtistReducer';
import LayoutReducer from '@airtel-feature/layout/reducers/LayoutReducer';
import PackageReducer from '../modules/layout/reducers/PackageReducer';
import SearchReducer from '../modules/search/reducers/SearchReducer';
import ChannelReducer from '../modules/rail/reducers/ChannelReducer';
import EpgReducer from '../modules/rail/reducers/EpgReducer';
import RailReducer from '../modules/layout/reducers/RailReducer';
import BrowseHistoryReducer from '../modules/app/reducers/BrowseHistoryReducer';
import SyncReducer from '../../../features/sync/SyncReducer';
import FooterReducer from '../modules/footer/reducers/FooterReducer';
import DimensionPageReducer from '../modules/dimension-pages/reducer/DimensionPageReducer';
import GenreListReducer from '../modules/dimension-pages/reducer/GenreListReducer';
import BreadCrumbsReducer from '../modules/breadcrumbs/reducers/BreadCrumbsReducer';
import ChannelScheduleReducer from '../modules/rail/reducers/ChannelScheduleReducer';
import ThemeReducer from '../modules/theme/ThemeConfigReducer';
import SubscriptionReducer from '../modules/subscription/SubscriptionReducer';
import HappyCodeReducer from '../modules/happycode/reducers/HappyCodeReducer';
import PopupReducer from '../modules/preferred-partner/PopupReducer';
import ModalPopupReducer from '../modules/modal-popup/ModalPopupReducer';
import BlogReducer from '../modules/blog/BlogReducers';
import OffersCheckReducer from '../modules/OffersCheck/OffersCheckReducer';
import ChatbotReducer from '../modules/chatbot/ChatbotReducer';
import AddToBillReducer from '@airtel-feature/addToBill/reducer';
import XppClaimOnThanksReducer from '../../../features/XppOneClickClaim/reducer/XppClaimOnThanksReducer';
import DistroReducer from '../../../features/layout/reducers/DistroReducer';

export default combineReducers({
    appConfig: AppConfigReducer,
    planConfig: PlanConfigReducer,
    allPlans: SubscriptionsReducer,
    authConfig: AuthReducer,
    channelPartnerConfig: ChannelPartnerReducer,
    themeConfig: ThemeReducer,
    contentDetails: ContentDetailsReducer,
    playbackDetails: PlaybackReducer,
    userConfig: UserReducer,
    notify: NotifyReducer,
    relatedContents: RelatedContentReducer,
    artistDetails: ArtistReducer,
    layoutDetails: LayoutReducer,
    packageContents: PackageReducer,
    searchResults: SearchReducer,
    channel: ChannelReducer,
    epg: EpgReducer,
    distroDetails: DistroReducer,
    railsMeta: RailReducer,
    browserHistory: BrowseHistoryReducer,
    syncContent: SyncReducer,
    footerData: FooterReducer,
    dimensionPageData: DimensionPageReducer,
    genreData: GenreListReducer,
    breadCrumbs: BreadCrumbsReducer,
    channelSchedule: ChannelScheduleReducer,
    subscription: SubscriptionReducer,
    happyCode: HappyCodeReducer,
    popupConfig: PopupReducer,
    modalConfig: ModalPopupReducer,
    blogConfig: BlogReducer,
    OffersCheck: OffersCheckReducer,
    chatbotConfig: ChatbotReducer,
    addToBill: AddToBillReducer,
    location: LocationReducer,
    xppClaimOnThanksConfig: XppClaimOnThanksReducer,
    plansAndSubscriptionData: PlansAndSubscriptionReducer,
});
