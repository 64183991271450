import {
    takeEvery, getContext, call, put, select, delay,
} from 'redux-saga/effects';
import { activateOneHubPartnerService, getSvodAllPlans } from '@airtel-tv/services/PlansAndSubscriptionApiService';
import { ErrorHandler } from '@airtel-feature/playback/factories/PlaybackUiComponentFactory';
import {
    getUserConfigFromReducer,
} from '@airtel-tv/redux/StoreListing';
import { AIRTEL_USER_TYPES, ONEHUB_PLAN_STATUS } from '@airtel-tv/constants';
import PlansAndSubscriptionAction, {
    SvodAllPlansSuccessAction,
    SvodAllPlansErrorAction,
    SetPartnerActivationData,
} from './PlansAndSubscriptionAction';


function* svodAllPlansFetch(action = {}) {
    const { preferredCp, dataId = 'all_plans', isMobility = false } = action?.payload || {};
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const { userContentProperties: { oSeg = '' } = {} } = yield select(getUserConfigFromReducer) || {};
        const isSubscribed = oSeg.includes(AIRTEL_USER_TYPES.XSTREAMPREMIUM_PAID);
        const svodAllPlansData = yield call(getSvodAllPlans, {
            deviceUtil,
            preferredCp,
            isSubscribed,
            isMobility,
            // requiredSubscriptions,
        });
        // const TRIMMED_PLANS_LENGTH = 2;
        // const { recommendedPlans, d2cPlans, rechargePlans } = svodAllPlansData;
        // let trimmed = [];
        // //let rechargePlansTrimmed = [];
        // let trimmedKeyName = '';
        // if (recommendedPlans && recommendedPlans.length) {
        //     trimmedKeyName = 'recommended';
        //     trimmed = recommendedPlans.slice(0, TRIMMED_PLANS_LENGTH);
        // }
        // else if (d2cPlans && d2cPlans.length) {
        //     trimmedKeyName = 'd2cPlans';
        //     trimmed = d2cPlans.slice(0, TRIMMED_PLANS_LENGTH);
        // }
        // else if (rechargePlans && rechargePlans.length) {
        //     trimmedKeyName = 'rechargePlans';
        //     trimmed = rechargePlans.slice(0, TRIMMED_PLANS_LENGTH);
        // }
        const { premiumSubscriptionStatus = [], xppSubscriptionStatus = [], activePlans = [] } = svodAllPlansData || {};
        const planClaimStatusClient = {
            PENDING_ACTIVATION: [],
            CLAIMED: [],
            CLAIM_IN_PROGRESS: [],
            ALL_PARTNERS: [],
        };
        const partnerObj = {};
        (activePlans || []).filter(plan => !plan.upcoming).forEach((plan) => {
            (plan?.partners || []).forEach((partner) => {
                if (!partnerObj[partner.cpId]) {//If not present directly add in dict
                    partnerObj[partner.cpId] = { partner, validTill: plan.validTill, validityPlaceholder: plan.validityPlaceholder};
                }
                else if (plan.validityPlaceholder) {//If incoming has validityPlaceholder "As per airtel plan" then it will have more priority and shall replace already existing one
                    partnerObj[partner.cpId] = {
                        partner,
                        validTill: plan.validTill,
                        validityPlaceholder: plan.validityPlaceholder,
                    };
                }
                else if (!partnerObj[partner.cpId].validityPlaceholder && partnerObj[partner.cpId].validTill < plan.validTill) { //Replace the existing if existing is not as per airtel plan and its validity is less than incoming
                    partnerObj[partner.cpId] = {
                        partner,
                        validTill: plan.validTill,
                        validityPlaceholder: plan.validityPlaceholder,
                    };
                }
            });
        });

        Object.keys(partnerObj || {}).map(item => {//Putting back in array form
            planClaimStatusClient.ALL_PARTNERS.push(partnerObj[item]);
        });

        if (premiumSubscriptionStatus) {
            premiumSubscriptionStatus.map((item) => {
                if (item.status === ONEHUB_PLAN_STATUS.CLAIMED) {
                    planClaimStatusClient.CLAIMED.push(item);
                }
                else if (item.status === ONEHUB_PLAN_STATUS.PENDING_ACTIVATION) {
                    planClaimStatusClient.PENDING_ACTIVATION.push(item);
                }
                else if (item.status === ONEHUB_PLAN_STATUS.CLAIM_IN_PROGRESS) {
                    planClaimStatusClient.CLAIM_IN_PROGRESS.push(item);
                }
                return item;
            });
        }
        if (planClaimStatusClient.ALL_PARTNERS) {//Using ALL_PARTNERS and not xppSubscriptionStatus as BE doesn't send these plans to us in case of existing plan subs
            planClaimStatusClient.ALL_PARTNERS.map((item) => {
                if (item?.partner?.meta?.premiumOTT) {
                    return item;
                }
                else {//Except premiumOTT all are pushed into CLAIMED as these get autoclaimed
                    planClaimStatusClient.CLAIMED.push(item);
                }
                // else if (item.status === ONEHUB_PLAN_STATUS.PENDING_ACTIVATION) {
                //     planClaimStatusClient.PENDING_ACTIVATION.push(item);
                // }
                // else if (item.status === ONEHUB_PLAN_STATUS.CLAIM_IN_PROGRESS) {
                //     planClaimStatusClient.CLAIM_IN_PROGRESS.push(item);
                // }
                return item;
            });
        }
        const svodPlans = {
            [dataId]: {
                ...svodAllPlansData,
                planClaimStatusClient,
                // recommendedPlansTrimmed,
                // d2cPlansTrimmed,
                // rechargePlansTrimmed,
            },
        };
        yield put(SvodAllPlansSuccessAction(svodPlans));
    }
    catch (error) {
        console.error('error', error);
        const ErrorData = {
            error: error && error.data ? error.data : error,
        };
        if (ErrorData.error) {
            ErrorHandler({
                error: ErrorData.error,
            });
        }
        yield put(SvodAllPlansErrorAction(ErrorData));
    }
}

function* activateOneHubPartner(action = {}) {
    const deviceUtil = yield getContext('deviceUtil');
    const { serviceId, referenceId } = action.payload;
    try {
        const activationData = yield call(activateOneHubPartnerService, {
            deviceUtil,
            data: {
                serviceId,
                referenceId,
            },
        });

        yield put(SetPartnerActivationData(activationData));
    }
    catch (err) {
        yield put(SetPartnerActivationData({
            serviceId,
            referenceId,
            status: 'FAILURE',
            errortitle: err?.data?.errortitle,
            errorCode: err?.data?.errorCode,
        }));
        console.log(err);
    }
}

export default [
    takeEvery(PlansAndSubscriptionAction.SVOD_ALL_PLANS, svodAllPlansFetch),
    takeEvery(PlansAndSubscriptionAction.ACTIVATE_ONEHUB_PARTNER, activateOneHubPartner),
];
