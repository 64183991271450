import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {
    KEY_CODES, CDP_EVENT_CONST, IMAGE_PATHS, PROGRAM_TYPES, LOCAL_STORE_KEYS,
} from '@airtel-tv/constants/GlobalConst';
import {
    documentAddEventListener, checkFullScreen, documentRemoveEventListener, addListenerToEvent, removeListenerFromEvent,
} from '@airtel-tv/utils/WindowUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
// import LinkComponent from '../../../../shared/components/link/LinkComponent';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';

import { CONTROL_BAR_EVENTS, PLAYER_EVENTS_LIST, SUBTITLE_TYPE } from '@airtel-tv/constants/PlayerConsts';
import { hasPip } from '@airtel-feature/playback/PipUtil';
// import { LocationUtil } from '@airtel-tv/utils';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import EventType from '@airtel-tv/analytics/EventType';
import { contentDetailsFetchAction } from '@airtel-feature/content-details/content-details/actions/ContentDetailsAction';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import ChannelGuideSVGComponent from '@airtel-tv/ui-lib/svg/ChannelGuideSVGComponent';
import FavoritesHeartSVGComponent from '@airtel-tv/ui-lib/svg/FavoritesHeartSVGComponent';
import FavoritesHeartSVGComponentSelected from '@airtel-tv/ui-lib/svg/FavoritesHeartSVGComponentSelected';
import ChannelGuideFavouritesComponent from '@airtel-tv/ui-lib/molecules/ChannelGuideFavouritesComponent/ChannelGuideFavouritesComponent';
import { checkSdkAdsAllowed } from '@airtel-tv/utils/AdSdkUtil';
import { CONTENT_PROVIDERS } from '@airtel-tv/constants';
import PlaybackSettingsContainer from '../PlaybackSettingsContainer';
import NextEpisodeComponent from './components/NextEpisodeComponent';
import LanguageProvider from '../../../../../providers/LanguageProvider';
import PipComponent from '../../player-controls/PipComponent';

const FULL_SCREEN_EVENTS = [
    '',
    'webkit',
    'moz',
    'ms',
];

const HOVER_OPTIONS = {
    SETTING_OPTION: 'SETTING_OPTION',
    NEXT_EPISODE_OPTION: 'NEXT_EPISODE_OPTION',
};

class RightPlayerControlsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSettingOption: false,
            showNextEpisodeOption: false,
        };

        FULL_SCREEN_EVENTS.forEach(
            prefix => documentAddEventListener(`${prefix}fullscreenchange`, this.fullScreenChange, false),
        );

        this.LANGUAGE = LanguageProvider();

        this.optionClicked = false;

        this.deviceUtil = props.deviceUtil;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let changes = {};

        const {
            toggleFullScreen,
            getVideoTag,
            videoEnded,
            showPreRollAds,
            adsManager,
            playingMidRollAds,
        } = nextProps;

        if (toggleFullScreen !== prevState.toggleFullScreen
            || getVideoTag !== prevState.getVideoTag
            || videoEnded !== prevState.videoEnded
            || showPreRollAds !== prevState.showPreRollAds
            || adsManager !== prevState.adsManager
            || playingMidRollAds !== prevState.playingMidRollAds) {
            changes = {
                toggleFullScreen,
                getVideoTag,
                videoEnded,
                showPreRollAds,
                adsManager,
                playingMidRollAds,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    componentDidMount() {
        const { controlBarEventEmitter, toggleFullScreen, autoFullScreen = false } = this.props;
        addListenerToEvent('keyup', this.keyupWeb);

        if (controlBarEventEmitter) {
            controlBarEventEmitter.on(CONTROL_BAR_EVENTS.HIDE_SETTINGS_MENU, this.hideOnTouch);
        }
       // const isFullScreen = !!checkFullScreen();
        // if (!isFullScreen && autoFullScreen) {
        //     toggleFullScreen();
        // }
    }

    componentDidUpdate() {
        const { videoEnded, toggleFullScreen } = this.props;
        if (videoEnded) {
            const isFullScreen = !!checkFullScreen();
            if (isFullScreen) {
                toggleFullScreen();
            }
        }
    }

    componentWillUnmount() {
        const { controlBarEventEmitter } = this.props;
        removeListenerFromEvent('keydown', this.keyupWeb);
        if (controlBarEventEmitter) {
            controlBarEventEmitter.off(CONTROL_BAR_EVENTS.HIDE_SETTINGS_MENU, this.hideOnTouch);
        }
        FULL_SCREEN_EVENTS.forEach(
            prefix => documentRemoveEventListener(`${prefix}fullscreenchange`, this.fullScreenChange, false),
        );
    }

    keyupWeb = (e) => {
        if (e.keyCode === KEY_CODES.F) {
            this.props.toggleFullScreen();
        }
    };

    showOptions = (e, option) => {
        const { onHover, setSettingsVisibility } = this.props;
        if (e) {
            e.preventDefault();
        }
        if (this.deviceUtil.isMobile()) {
            return;
        }
        switch (option) {
            case HOVER_OPTIONS.SETTING_OPTION:
                this.setState({
                    showSettingOption: true,
                    showNextEpisodeOption: false,
                });
                setSettingsVisibility(true);
                break;
            case HOVER_OPTIONS.NEXT_EPISODE_OPTION:
                this.setState({
                    showSettingOption: false,
                    showNextEpisodeOption: true,
                });
                setSettingsVisibility(false);
                break;
            default:
        }
        onHover(e);
    };

    hideOptions = (e, option) => {
        const { onLeaveHover, setSettingsVisibility } = this.props;
        if (e) {
            e.preventDefault();
        }
        if (this.deviceUtil.isMobile()) {
            return;
        }
        switch (option) {
            case HOVER_OPTIONS.SETTING_OPTION:
                this.setState({
                    showSettingOption: false,
                });
                setSettingsVisibility(false);
                break;
            case HOVER_OPTIONS.NEXT_EPISODE_OPTION:
                this.setState({
                    showNextEpisodeOption: false,
                });
                break;
            default:
        }
        onLeaveHover(e);
    };

    hideOnTouch = () => {
        const { onLeaveHover, setSettingsVisibility } = this.props;
        if (this.optionClicked) {
            this.setState({
                showSettingOption: false,
                showNextEpisodeOption: false,
            });
            setSettingsVisibility(false);
            onLeaveHover(null, true);
            this.optionClicked = false;
        }
    };

    toggleOption = (e) => {
        if (!this.deviceUtil.isMobile()) {
            return;
        }
        const { onLeaveHover, onHover, setSettingsVisibility } = this.props;
        if (this.optionClicked) {
            this.setState({
                showSettingOption: false,
                showNextEpisodeOption: false,
            });
            setSettingsVisibility(false);
            onLeaveHover(e, true);
            this.optionClicked = false;
        }
        else {
            this.setState({
                showSettingOption: true,
            });
            setSettingsVisibility(true);
            onHover(e, true);
            this.optionClicked = true;
        }
    };

    fullScreenChange = () => {
        const isFullScreen = !!checkFullScreen();
        const { setFullScreenState } = this.props;
        setFullScreenState(isFullScreen);
    };

    nextEpClick = () => {
        const {
            contentDetailsFetchActionDispatch, nextEpisodeDetails, playerFunctions, adConfig, contentDetails, firePlayerEvent,
        } = this.props;
        playerFunctions.emitEvent(PLAYER_EVENTS_LIST.NEXT_EPISODE_CLICK);
        contentDetailsEvent({ action: CDP_EVENT_CONST.NEXT_EP_CLICK }, EventType.NEXT_EP_CLICK);
        if (firePlayerEvent && checkSdkAdsAllowed(contentDetails, adConfig)) {
            firePlayerEvent(PLAYER_EVENTS_LIST.TRIGGER_POST_ROLL_AD);
        }
        else {
            contentDetailsFetchActionDispatch(nextEpisodeDetails.details.refId);
        }
    };

    render() {
        const {
            fullScreen,
            toggleFullScreen,
            nextEpisodeDetails,

            meta,
            enableBitrateSelector,
            playerFunctions,
            playbackSessionId,
            enabled,
            browser,
            onPlayerFunctionsNotFound,
            renditionFromLocalStorage,
            getVideoTag,
            showPreRollAds,
            lang,
            playingMidRollAds,
            hideSettingsButton,
            contentDetails,
            continueWatchingData,
            playbackDetails,
            contentDetails: { cpId = '', programType = '' } = {},
            handleChangeEpgPageVisibility = () => {},
            epg = {},
            isChannelGuideVisible = true,
        } = this.props;

        const { CHANNEL_GUIDE_BUTTON = 'Channel Guide' } = this.LANGUAGE;

        const { showNextEpisodeOption, showSettingOption } = this.state;

        const fullScreenTogglerSrc = fullScreen ? '/static/fulllscreen-new.svg' : '/static/fullscreen-exit-new.svg';

        const hasNextEp = !(nextEpisodeDetails == null || Object.keys(nextEpisodeDetails).length === 0);

        const isMobile = this.deviceUtil.isMobile();
        const browserName = this.deviceUtil.getBrowserName();
        const deviceName = this.deviceUtil.getDeviceName();
        const videoElement = getVideoTag();
        const playerMeta = playerFunctions.getMeta();
        const { textTracks = {} } = playerMeta;
        const subtitleType = contentDetails?.playableId && Object.keys(textTracks).length ? playbackDetails?.[contentDetails?.playableId]?.subs ? SUBTITLE_TYPE.SIDE_LOADED : SUBTITLE_TYPE.INLINE : '';
        const hasEpg = cpId === CONTENT_PROVIDERS.DISTROTV && programType === PROGRAM_TYPES.LIVETVCHANNEL;

        return (
            <div
                className={`${hasEpg ? 'has-epg' : ''}  bottom-control-right`}
                id="atm_bottom-right-controls"
            >
                {
                    (hasEpg) ? (
                        <ChannelGuideFavouritesComponent
                            ChannelIcon={ChannelGuideSVGComponent}
                            FavouritesIcon={FavoritesHeartSVGComponent}
                            FavouritesIconSelected={FavoritesHeartSVGComponentSelected}
                            width={30}
                            height={30}
                            handleChangeEpgPageVisibility={handleChangeEpgPageVisibility}
                            contentDetails={contentDetails}
                            epg={epg}
                            playerFunctions={playerFunctions}
                            isChannelGuideVisible={isChannelGuideVisible}
                        />
                    ) : ''
                }
                {
                    hasNextEp ? (
                        <div
                            className="pos-relative btm-control-space-manager setting-mobile-btm overlay-ref next-episode"
                            onMouseEnter={e => this.showOptions(e, HOVER_OPTIONS.NEXT_EPISODE_OPTION)}
                            onMouseLeave={e => this.hideOptions(e, HOVER_OPTIONS.NEXT_EPISODE_OPTION)}
                            onClick={this.nextEpClick}
                        >
                            <AnalyticsButtonComponent
                                className="bottom-control-buttons scale-hover btm-control-btn"
                                aria-label="Next Episode"
                            >
                                <ThumborImage
                                    src={IMAGE_PATHS.NEXT_EPISODE}
                                />
                            </AnalyticsButtonComponent>
                            <span className="mobile-setting-option-name for-mobile-only">{this.LANGUAGE.NEXT_EPISODE}</span>
                            <NextEpisodeComponent
                                show={showNextEpisodeOption}
                                epDetails={nextEpisodeDetails}
                                deviceUtil={this.deviceUtil}
                            />
                        </div>

                    ) : null
                }

                {!(showPreRollAds || playingMidRollAds) && !hideSettingsButton && (
                    <div
                        className="pos-relative btm-control-space-manager setting-mobile-btm overlay-ref setting"
                        onMouseEnter={e => this.showOptions(e, HOVER_OPTIONS.SETTING_OPTION)}
                        onMouseLeave={e => this.hideOptions(e, HOVER_OPTIONS.SETTING_OPTION)}
                        ref={(ref) => {
                            this.settingOptionRef = ref;
                        }}
                    >
                        <AnalyticsButtonComponent
                            id="player-setting-controller"
                            type="button"
                            className="bottom-control-buttons scale-hover btm-control-setting"
                            onClick={e => this.toggleOption(e, HOVER_OPTIONS.SETTING_OPTION)}
                            aria-label="Settings"
                        >
                            <ThumborImage
                                src={IMAGE_PATHS.SETTINGS}
                            />
                        </AnalyticsButtonComponent>
                        <span className="mobile-setting-option-name for-mobile-only">{this.LANGUAGE.PLAYER_SETTINGS}</span>

                        <PlaybackSettingsContainer
                            show={showSettingOption}
                            meta={meta}
                            enableBitrateSelector={enableBitrateSelector}
                            playerFunctions={playerFunctions}
                            playbackSessionId={playbackSessionId}
                            enabled={enabled}
                            lang={lang}
                            browser={browser}
                            onPlayerFunctionsNotFound={onPlayerFunctionsNotFound}
                            toggleOption={this.toggleOption}
                            hideOptions={this.hideOptions}
                            hoverOptionEl={HOVER_OPTIONS.SETTING_OPTION}
                            renditionFromLocalStorage={renditionFromLocalStorage}
                            contentDetails={contentDetails}
                            continueWatchingData={continueWatchingData}
                            subtitleType={subtitleType}
                        />

                    </div>
                )}

                {
                    !(showPreRollAds || playingMidRollAds) && hasPip(isMobile, videoElement, deviceName, browserName) ? (
                        <div className="pos-relative btm-control-space-manager setting-mobile-btm overlay-ref pip-area">
                            <PipComponent
                                getVideoTag={getVideoTag}
                                toggleFullScreen={toggleFullScreen}
                            />
                        </div>
                    ) : null
                }

                <div className="btm-control-space-manager toggle-fullscreen-for-mobile">
                    <AnalyticsButtonComponent
                        id="atm_player-toggle-fullscreen"
                        onClick={toggleFullScreen}
                        className="bottom-control-buttons  btm-control-fullscreen scale-hover"
                        type="button"
                        title={this.LANGUAGE.PLAYER_TOGGLE_FULL_SCREEN}
                        aria-label={this.LANGUAGE.PLAYER_TOGGLE_FULL_SCREEN}
                    >
                        <ThumborImage
                            src={fullScreenTogglerSrc}
                            imageChanged
                        />
                    </AnalyticsButtonComponent>
                </div>

            </div>
        );
    }
}

RightPlayerControlsContainer.propTypes = {
    toggleFullScreen: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    setFullScreenState: PropTypes.func.isRequired,
    videoEnded: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    getVideoTag: PropTypes.func.isRequired,
    nextEpisodeDetails: PropTypes.shape({
        url: PropTypes.string,
        nextEpTitle: PropTypes.string,
        nextEpLinkTitle: PropTypes.string,
        details: PropTypes.object,
    }),
    onHover: PropTypes.func.isRequired,
    onLeaveHover: PropTypes.func.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    meta: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    enableBitrateSelector: PropTypes.bool,
    playerFunctions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    playbackSessionId: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    adsManager: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showPreRollAds: PropTypes.bool.isRequired,
    playingMidRollAds: PropTypes.bool.isRequired,
    browser: PropTypes.string.isRequired,
    onPlayerFunctionsNotFound: PropTypes.func.isRequired,
    controlBarEventEmitter: PropTypes.shape({
        on: PropTypes.func.isRequired,
        off: PropTypes.func.isRequired,
        emit: PropTypes.func.isRequired,
    }),
    renditionFromLocalStorage: PropTypes.shape({
        id: PropTypes.string.isRequired,
        des: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    }),
    continueWatchingData: PropTypes.object,
    setSettingsVisibility: PropTypes.func,
    contentDetailsFetchActionDispatch: PropTypes.func.isRequired,
    adConfig: PropTypes.object,
    contentDetails: PropTypes.object.isRequired,
    firePlayerEvent: PropTypes.func,
};

RightPlayerControlsContainer.defaultProps = {
    nextEpisodeDetails: null,
    enableBitrateSelector: true,
    controlBarEventEmitter: {
        on: () => { },
        off: () => { },
        emit: () => { },
    },
    renditionFromLocalStorage: null,
    continueWatchingData: {},
    setSettingsVisibility: () => {},
    adConfig: {},
    firePlayerEvent: null,
};

const mapStateToProps = (state) => {
    const { syncContent: { continueWatchingData }, playbackDetails, epg = {} } = state;
    return {
        continueWatchingData,
        playbackDetails,
        epg,
    };
};

export default connect(mapStateToProps, {
    contentDetailsFetchActionDispatch: contentDetailsFetchAction,
})(withDeviceUtil(RightPlayerControlsContainer));
