import { PLAYBACK_CHECK } from "@airtel-tv/constants/GlobalConst";
import { AppEnv } from "./GetEnv";

class DeviceTypeUtil {
    static isWeb = () => AppEnv.getEnv()?.deviceType === PLAYBACK_CHECK.BROWSER;
    static getDevice = () => AppEnv.getEnv()?.deviceType;
    static isLG = () => AppEnv.getEnv()?.osType === 'LGOS';
    static isTV = () => AppEnv.getEnv()?.appId === 'LARGESCREEN';
    static isTizen = () => AppEnv.getEnv()?.osType === 'TIZENOS';
}

export {DeviceTypeUtil};