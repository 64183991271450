import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';

class SyncStorageUtil {
    static clearSyncData = (uid) => {
        const syncKeyForUser = LOCAL_STORE_KEYS.USER_DATA + uid;
        browserStore.remove(syncKeyForUser);
    };

    static updateSyncData = (data, uid) => {
        const syncKeyForUser = LOCAL_STORE_KEYS.USER_DATA + uid;
        browserStore.set(syncKeyForUser, data);
    };

    static getSyncData = (uid) => {
        const syncKeyForUser = LOCAL_STORE_KEYS.USER_DATA + uid;
        return browserStore.get(syncKeyForUser);
    }
}

export default SyncStorageUtil;
