import {
    call, select, getContext, put,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { takeFirst } from '../../utilities/ReduxSagaUtil';
import SubscriptionAction, { subscriptionError } from './SubscriptionAction';
import { subscriptionPurchaseRedirectionAPI, managePlanRedirectionAPI } from '../../service/end-points/SubscriptionApiService';
import { getWindowLocation, redirectTo, getCurrentWindowSourceName } from '../../utilities/WindowUtil';
import { getUserConfigFromReducer, getAppConfigFromReducer } from '@airtel-tv/redux/StoreListing';
import { SUBSCRIPTION_SUCCESSFUL_PAGE_REDIRECTION } from '../../constants/SubscriptionConst';
import RoutingUtil from '../../utilities/RoutingUtil';
import { SUBSCRIPTION_SOURCE } from '../../constants/EventsConst';
import { subscriptionClickEvent } from '@airtel-tv/analytics/FunctionalEvents';

const windowLocation = getWindowLocation();

function* subscriptionSaga(action) {
    const deviceUtil = yield getContext('deviceUtil');
    const userConfig = yield select(getUserConfigFromReducer);
    const id = lodashGet(action, 'id', '');
    const shortCode = lodashGet(action, 'shortCode', '');
    const redirectionType = lodashGet(action, 'successRedirection', '');
    const source = lodashGet(action, 'source', SUBSCRIPTION_SOURCE.CHANNEL_PARTNER_PAGE);

    subscriptionClickEvent({
        asset_name: source,
        source_name: getCurrentWindowSourceName(),
        channel_name: id,
    });
    let successUrl = getCurrentWindowSourceName();
    if (redirectionType === SUBSCRIPTION_SUCCESSFUL_PAGE_REDIRECTION.PARTNER_CHANNEL) {
        const { cpDetailsById } = yield select(getAppConfigFromReducer);
        const channelDetail = lodashGet(cpDetailsById, `${id}`, null);

        if (channelDetail) {
            successUrl = windowLocation.origin + RoutingUtil.getChannelPartnerPageUrl({
                channelPartnerName: channelDetail.title,
                pageLink: channelDetail.pageLink,
            }) || successUrl;
        }
    }
    try {
        const response = yield call(subscriptionPurchaseRedirectionAPI, {
            deviceUtil,
            id,
            userConfig,
            shortCode,
            successUrl,
            failureUrl: windowLocation.origin,
        });

        const redirectionUrl = lodashGet(response, 'data.redirectUrl', '');
        redirectTo(redirectionUrl);
    }
    catch (e) {
        console.error(e);
        const payload = {
            ...e.data,
        };
        yield put(subscriptionError({ payload }));
    }
}

function* manageSubscription(action) {
    const deviceUtil = yield getContext('deviceUtil');
    const userConfig = yield select(getUserConfigFromReducer);
    const id = lodashGet(action, 'id', '');
    const shortCode = lodashGet(action, 'shortCode', '');
    const successUrl = lodashGet(action, 'successUrl', '');
    const failureUrl = lodashGet(action, 'failureUrl', '');

    try {
        const response = yield call(managePlanRedirectionAPI, {
            deviceUtil,
            id,
            userConfig,
            shortCode,
            successUrl,
            failureUrl,
        });

        const redirectionUrl = lodashGet(response, 'data.redirectUrl', '');
        redirectTo(redirectionUrl);
    }
    catch (e) {
        console.error(e);
        const payload = {
            ...e.data,
        };
        yield put(subscriptionError({ payload }));
    }
}


export default [
    takeFirst(SubscriptionAction.SUBSCRIPTION_INIT, subscriptionSaga),
    takeFirst(SubscriptionAction.MANAGE_SUBSCRIPTION_INIT, manageSubscription),
];
