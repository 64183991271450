import React from 'react';

const ArrowIcon = ({ height = '', width = '', d = '', stroke = "#636972" }) => (
    <svg
        width={width || '7'}
        height={height || '12'}
        viewBox={`0 0 ${width || 7} ${height || 12}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d={d || 'M1.25 10.375L5.625 6L1.25 1.625'}
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default ArrowIcon;
