import React from 'react';
import PropTypes from 'prop-types';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { ThumborUtil } from '@airtel-tv/utils/ThumborUtil';
import { checkWindowExist, DeviceTypeUtil } from '@airtel-tv/utils';
import { AppEnv } from '@airtel-tv/utils/GetEnv';
import LazyLoader from './LazyLoader';

function ThumborImage(props) {
    const {
        className, src, imageSettings, alt, quality, transparent, imageChanged,
        defaultImageWidth, contentId,
        shouldLazyLoad, hideCustomAlt, deviceUtil, onImageLoaded,
        imageUrlMobile, imageStyle,
        fadeInEffect,
    } = props;
    const hideAltTagInit = deviceUtil.isBot() || !alt || !hideCustomAlt;

    const qualityRatio = deviceUtil.isMobile() ? 1.3 : 1.1; // increasing quality of image by this ratio
    const isStaticImage = src?.startsWith('/static') || src?.startsWith('./static');

    let baseUrl = deviceUtil.getBaseUrl();
    // const [hideAltTagState, setHideAltTagState] = useState(hideAltTagInit);
    if (isStaticImage) {
        if (DeviceTypeUtil.isTizen()) {
            const env = AppEnv.getEnv();
            baseUrl = `${env.baseUrl}/LARGESCREEN_TIZEN`;
        }
    }

    const getImageUrl = (src, width, quality, transparent, height = 0) => {
        // console.log('content === .   ',src, '          ',width, '          ',quality, '          ',transparent, '          ',height, '          ', quality)
        if (src) {
            const imageFormat = deviceUtil.getImageFormat(src);
            let targetUrl = src;

            if (isStaticImage) {
                targetUrl = `${baseUrl}${src}`; // https://www.airtelxstream.in as base for localhost
            }
            else {
                try {
                    targetUrl = encodeURI(targetUrl);
                }
                catch (e) {
                    console.log(e);
                }
            }

            return ThumborUtil.getThumborUrl(
                Math.floor(width * qualityRatio),
                Math.floor(height * qualityRatio),
                targetUrl,
                imageFormat,
                quality,
            );
        }
        return src;
    };

    const showAltTag = () => {
        // setHideAltTagState(false);

    };
    const hideAltTag = () => {
        // setHideAltTagState(true);
    };


    const pictureSettings = [];
    if (imageSettings) {
        Object.keys(imageSettings).reverse().forEach((key) => {
            const value = imageSettings[key];
            let imagSrc = src;
            if (key < 500 && imageUrlMobile) {
                imagSrc = imageUrlMobile;
            }
            const srcUrl = getImageUrl(imagSrc, value, quality, transparent, 0);
            pictureSettings.push(<source
                key={key}
                media={`(min-width: ${key}px)`}
                data-srcset={srcUrl}
                srcSet={srcUrl}
            />);
        });
    }
    if (!src) {
        return hideAltTagInit ? (
            <div
                className="tv-episode-alt"
            >
                {alt}
            </div>
        ) : null;
    }
    return (
    // lazyloader for static images to decrease dom size
        isStaticImage ? (
            <picture>
                <img
                    key={contentId}
                    src={baseUrl + src.replace('./static/', '/static/')}
                    className={`${className} lazyload`}
                    alt={alt || ''}
                    loading="lazy"
                    style={imageStyle}
                    draggable="false"
                />
            </picture>
        ) : (
            <>
                <LazyLoader
                    {...props}
                    showAltTag={showAltTag}
                    hideAltTag={hideAltTag}
                    pictureSettings={pictureSettings}
                    getImageUrl={getImageUrl}
                    fadeInEffect={fadeInEffect}
                    isBot={deviceUtil.isBot()}
                    imageChanged={imageChanged}
                    defaultImageWidth={defaultImageWidth}
                    shouldLazyLoad={shouldLazyLoad && checkWindowExist()}
                    onImageLoaded={onImageLoaded}
                />
                {hideAltTagInit ? (
                    <div
                        className="tv-episode-alt"
                    >
                        {alt}
                    </div>
                ) : null}


            </>
        )
    );
}

ThumborImage.defaultProps = {
    className: '',
    src: '',
    alt: '',
    imageSettings: null,
    quality: null,
    transparent: false,
    imageChanged: false,
    defaultImageWidth: 0,
    contentId: '',
    shouldLazyLoad: true,
    hideCustomAlt: false,
    imageUrlMobile: '',
    imageStyle: {},
    fadeInEffect: false,
};

ThumborImage.propTypes = {
    className: PropTypes.string,
    imageSettings: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    src: PropTypes.string,
    alt: PropTypes.string,
    quality: PropTypes.number,
    transparent: PropTypes.bool,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    imageChanged: PropTypes.bool,
    defaultImageWidth: PropTypes.number,
    contentId: PropTypes.string,
    shouldLazyLoad: PropTypes.bool,
    hideCustomAlt: PropTypes.bool,
    imageUrlMobile: PropTypes.string,
    imageStyle: PropTypes.object,
    onImageLoaded: PropTypes.func.isRequired,
    fadeInEffect: PropTypes.bool,
};
const thumborImage = React.memo(withDeviceUtil(ThumborImage));
export { thumborImage as ThumborImage };
export default thumborImage;
