import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { LanguageProviderUtil, LocationUtil } from '@airtel-tv/utils';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { setRailScrollTopAddition, setLayoutDimensionMap } from '@airtel-feature/layout/actions/LayoutActions';
import { connect } from 'react-redux';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics';

const IMAGE_SETTINGS = {
    300: 100,
};

const ComingSoonComponent = (props) => {
    const {
        componentProps,
        cpDetailsById = {},
        match: { params: { cpId = '' } } = {},
        setLayoutDimensionMapDispatch,
    } = props;

    const queryParams = LocationUtil.getQueryParams(props);
    const preferredCp = cpId || queryParams?.preferredCp;
    const {
        titleHeading = '', titleSubheading, zionDisplay: { titleImg },
        pageId,
        railIndex,
        style,
        keyDownCallBack,
        onKeyUp,
        onFocus = null,
        tilePosition = 0,
        isLargeScreen = false,
    } = componentProps;
    const { height } = style;
    const tileId = `${pageId}-tile-${railIndex}-${tilePosition}`;

    // const preferredCp = LocationUtil.getQueryParams(props, QUERY_PARAMS.PREFERRED_CP) || '';
    const preferredCpData = cpDetailsById[preferredCp] || {};
    const { OTT_COMING_SOON = '26 OTTs are coming soon', CAN_BUY_EXISTING_PLAN = 'You can still buy the existing plans' } = LanguageProviderUtil.getLanguage();
    // const referredChannel = preferredCp?.charAt(0)?.toUpperCase() + preferredCp?.slice(1)?.toLowerCase();
    const title = preferredCpData?.title && titleHeading ? titleHeading?.replace('{target_cp}', preferredCpData?.title) : OTT_COMING_SOON;
    const subTitle = titleSubheading || CAN_BUY_EXISTING_PLAN;
    const railContainerRef = useRef();

    useEffect(() => {
        if (isLargeScreen) {
            setLayoutDimensionMapDispatch({
                pageId,
                railPosition: railIndex,
                dimensions: {
                    lastFocusedTilePosition: 0,
                    focusSelectorleft: 0,
                    height,
                    scrollTop: railContainerRef.current?.offsetTop,
                    nonQuickViewRail: true,
                    focusOnSelection: true,
                    subtitleHeight: null,
                },
            });
        }
    }, []);

    return (
        <div
            className="coming-soon-tile"
            ref={railContainerRef}
            style={style}
        >
            <AnalyticsLinkComponent
                id={tileId}
                to="#"
                tilePosition={tilePosition}
                railPosition={railIndex}
                onClick={(e) => {
                    e.preventDefault();
                }}
                onKeyDown={(e) => {
                    keyDownCallBack({ event: e });
                }}
                onFocus={(e) => {
                    if (typeof onFocus === 'function') {
                        onFocus({
                            e,
                            railPosition: railIndex,
                            tilePosition: 0,
                            tileDetails: { nonQuickViewTile: true },
                            railOffsetTop: railContainerRef?.current?.offsetTop || 0,
                        });
                    }
                }}
                // onKeyDown={(event) => {
                //     if (typeof onKeyDown === 'function') {
                //         onKeyDown({
                //             event,
                //             tilePosition,
                //         });
                //     }
                // }}
                onKeyUp={onKeyUp}
            >
                <div className="coming-soon-wrapper">
                    <ThumborImage
                        className="plan-icon-info"
                        src={titleImg}
                        imageSettings={IMAGE_SETTINGS}
                        hideCustomAlt
                    />
                    <div className="title-wrapper">
                        <div className="title text-bolder text-16 line-height-20 mobile-text-14 text-ls-28 text-ls-bold line-height-ls-40">{title}</div>
                        <div className="sub-title mobile-text-12 mobile-text-bold mobile-line-height-18 text-ls-24 text-ls-semi-bold line-height-ls-18">{subTitle}</div>
                    </div>
                    {/* {!isLargescreen ? (
                    <div className="partner-images">
                        {partnerImages.map(img => <ThumborImage src={img} alt="logo" imageSettings={IMAGE_SETTINGS} />)}
                    </div>
                ) : null} */}
                    {/* <div className="footer">
                    <div className="divider" />
                    <div>{railSubtitle}</div>
                </div> */}
                </div>
                {/* {isLargescreen ? (
                <div className="partner-images">
                    {partnerImages.map(img => <ThumborImage src={img} alt="" imageSettings={IMAGE_SETTINGS} />)}
                </div>
            ) : null} */}
            </AnalyticsLinkComponent>
        </div>

    );
};

ComingSoonComponent.propTypes = {
    isLargescreen: PropTypes.bool.isRequired,
    componentProps: PropTypes.object.isRequired,
    setRailScrollTopAdditionDisptach: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
    const {
        componentProps: {
            cpDetailsById = {},
        } = {},
    } = props;
    return {
        cpDetailsById,
    };
};

export default connect(mapStateToProps, {
    setRailScrollTopAdditionDisptach: setRailScrollTopAddition,
    setLayoutDimensionMapDispatch: setLayoutDimensionMap,
})(withRouter(ComingSoonComponent));
