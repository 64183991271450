import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './ToolTip.scss';


function ToolTip({ children }) {
    useStyles(styles);
    return (
        <div className="btn-tooltip">
            {children}
        </div>
    );
}
export default ToolTip;
