import { matchRoutes } from 'react-router';
import React, {
    useRef, memo,
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { LinkComponent } from '@airtel-tv/ui-lib/atoms/link/LinkComponent';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { getCurrentWindowSourceName } from '@airtel-tv/utils/WindowUtil';
import {
    getSourceName, setAnalyticsMeta, setStitchId, matchClassSelector,
} from '@airtel-tv/utils/GlobalUtil';
import { DeviceTypeUtil } from '@airtel-tv/utils/DeviceTypeUtil';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import { connect } from 'react-redux';
import { TIZEN_KEYCODES } from '@airtel-tv/constants';
import getAllRouters from '../../web/src/routes/routes';
import { clickEvent, searchEvents } from './FunctionalEvents';
import { setFocusedElement } from '../../tv/src/modules/navigation/NavigationActions';
import EventType from './EventType';

const AnalyticsLinkComponent = (props) => {
    const {
        meta,
        onClick,
        children,
        className,
        to,
        title,
        onMouseEnter,
        id,
        onBlur,
        onFocus,
        onKeyDown,
        'data-sn-down': dataSnDown,
        'data-sn-up': dataSnUp,
        'data-sn-left': dataSnLeft,
        'data-sn-right': dataSnRight,
        focusUpElement,
        focusDownElement,
        focusLeftElement,
        focusRightElement,
        focusUpTargetByClass,
        focusDownTargetByClass,
        focusLeftTargetByClass,
        focusRightTargetByClass,
        tilePosition,
        railPosition,
        style,
        onKeyUp,
        'aria-label': ariaLabel, 'area-role': areaRole,
        'data-toggle': dataToggle, onDragStart,
        focusLink = false,
        setFocusedElementDispatch,
        isSearchEvent = false,
        selfFocusSelector = false,
        selfFocusTargetByClass = false,
        navigationObj: {
            currentSource = '',
        } = {},
    } = props;


    const isBot = DeviceUtil?.getDeviceUtil()?.isBot();

    const linkRef = useRef();
    const [
        isFocused,
        setIsfocused,
    ] = useState(false);

    useEffect(() => {
        if (focusLink && !isFocused) {
            linkRef.current.focus();
            
        }
        if(selfFocusSelector){
            setFocusedElementDispatch({
                selector: selfFocusSelector,
                targetByClass: selfFocusTargetByClass,
            });
        }
    }, [
        focusLink,
    ]);

    const onLinkClick = (meta, onClick, e) => {
        const location = getCurrentWindowSourceName();
        let analyticsSessionMeta;
        let stitchId;
        if (DeviceTypeUtil?.isWeb()) {
            const routes = getAllRouters();
            const matchedRoutes = matchRoutes(routes, to);
            const isLinkToCdp = (matchedRoutes || []).find(r => (!!r?.route?.isCDP));
            if (isLinkToCdp) {
                const contentId = lodashGet(isLinkToCdp, 'match.params.contentId', null);
                if (contentId) {
                    analyticsSessionMeta = setAnalyticsMeta(contentId);
                    stitchId = setStitchId();
                }
            }
        }
        // const sourceName = (location || { pathname: ROUTE_PATHS.ROOT }).pathname;
        if (meta) {
            let sourceName = meta.source_name;
            if (!sourceName) {
                // const currLocation = getWindowLocation();
                // const pathName = getCurrentWindowSourceName() || ROUTE_PATHS.ROOT;
                sourceName = currentSource;
            }
            const metaEvent = {
                ...meta,
                ...(analyticsSessionMeta && { user_session_id: analyticsSessionMeta.userSessionId }),
                ...(stitchId && { playback_stitch_key: stitchId }),
                source_name: sourceName,
            };
            if (isSearchEvent) {
                searchEvents({
                    ...metaEvent,
                }, EventType.CLICK);
            }
            else {
                clickEvent(
                    metaEvent,
                );
            }
        }

        // if same route then stop routing : no need to reload same page
        if (location === to && e) {
            e.preventDefault();
        }

        if (onClick !== undefined) {
            onClick(e);
        }
    };

    const onLinkKeyDown = (e) => {
        if (onKeyDown
            && typeof onKeyDown === 'function') {
            onKeyDown(e);
        }
        const { keyCode = '' } = e;
        const {
            UP_ARROW, DOWN_ARROW, RIGHT_ARROW,
            LEFT_ARROW,
        } = TIZEN_KEYCODES;
        switch (keyCode) {
            case UP_ARROW:
                if (focusUpElement) {
                    setFocusedElementDispatch({
                        selector: focusUpElement,
                        targetByClass: focusUpTargetByClass,
                    });
                }
                break;
            case DOWN_ARROW:
                if (focusDownElement) {
                    setFocusedElementDispatch({
                        selector: focusDownElement,
                        targetByClass: focusDownTargetByClass,
                    });
                }
                break;
            case LEFT_ARROW:
                if (focusLeftElement) {
                    setFocusedElementDispatch({
                        selector: focusLeftElement,
                        targetByClass: focusLeftTargetByClass,
                    });
                }
                break;
            case RIGHT_ARROW:
                if (focusRightElement) {
                    setFocusedElementDispatch({
                        selector: focusRightElement,
                        targetByClass: focusRightTargetByClass,
                    });
                }
                break;
            default:
        }
    };

    const onLinkFocus = (e) => {
        const {
            scrollPositionVertical,
            scrollPositionHorizontal,
            scrollBehaviour,
            scrollToTop,
            isList,
            scrollIntoViewIfNeeded,
        } = props;
        e?.stopPropagation();
        e?.nativeEvent.stopPropagation();
        e?.preventDefault();
        e?.nativeEvent?.stopImmediatePropagation();
        setIsfocused(true);
        if (onFocus && typeof onFocus === 'function') {
            onFocus(e);
        }
        if (className.includes(FocusManagementClassNames.BY_PASS_SCROLL)) {
            return;
        }
        if (scrollToTop) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto',
            });
            window.document.body.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto',
            });
            return;
        }
        const currentElem = linkRef?.current;
        if (currentElem) {
            if (scrollIntoViewIfNeeded) {
                currentElem.scrollIntoView({
                    behavior: scrollBehaviour,
                    block: scrollPositionVertical,
                    inline: scrollPositionHorizontal,
                });
            }
            if (isList) {
                const scrollY = currentElem.getBoundingClientRect().top + window.pageYOffset - 140;
                window.scroll({
                    top: scrollY,
                    left: 0,
                    behavior: 'auto',
                });
            }
        }
    };

    if (DeviceTypeUtil?.isWeb()) {
        return (
            <LinkComponent
                className={className}
                aria-label={ariaLabel}
                area-role={areaRole}
                to={to}
                data-toggle={dataToggle}
                onClick={e => onLinkClick(meta, onClick, e)}
                onDragStart={onDragStart}
                title={isBot ? title : ''}
                id={id}
                onMouseEnter={onMouseEnter}
                style={style}
                ref={linkRef}
            >
                {children}
            </LinkComponent>
        );
    }
    return (
        <LinkComponent
            className={`${className} railPosition-${railPosition} tilePosition-${tilePosition}`}
            style={style}
            to={to}
            onClick={e => onLinkClick(meta, onClick, e)}
            title={title}
            id={id}
            ref={linkRef}
            data-sn-up={dataSnUp}
            data-sn-down={dataSnDown}
            data-sn-left={dataSnLeft}
            data-sn-right={dataSnRight}
            focusUpElement={focusUpElement}
            focusDownElement={focusDownElement}
            focusLeftElement={focusLeftElement}
            focusRightElement={focusRightElement}
            focusLeftTargetByClass={focusLeftTargetByClass}
            tilePosition={tilePosition}
            railPosition={railPosition}
            onFocus={onLinkFocus}
            onBlur={(e) => {
                setIsfocused(false);
                if (onBlur !== undefined && typeof onBlur === 'function') {
                    onBlur(e);
                }
            }}
            onKeyDown={onLinkKeyDown}
            onKeyUp={(e) => {
                if (onKeyUp && typeof onKeyUp === 'function') {
                    onKeyUp(e);
                }
            }}
            onMouseEnter={(e) => {
                // onLinkFocus(onFocus, props, e);
                if (onMouseEnter) {
                    onMouseEnter(e);
                }
            }}
        >
            {children}
        </LinkComponent>
    );
};

AnalyticsLinkComponent.propTypes = {
    className: PropTypes.string,
    meta: PropTypes.instanceOf(Object).isRequired,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    to: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string,
    id: PropTypes.string,
    onMouseEnter: PropTypes.func,
    tilePosition: PropTypes.number,
    'data-sn-down': PropTypes.string,
    'data-sn-up': PropTypes.string,
    'data-sn-left': PropTypes.string,
    'data-sn-right': PropTypes.string,
    'area-role': PropTypes.string, // eslint-disable-line react/forbid-prop-types
    'aria-label': PropTypes.string, // eslint-disable-line react/forbid-prop-types
    'data-toggle': PropTypes.string, // eslint-disable-line react/forbid-prop-types
    onDragStart: PropTypes.func,
    scrollPositionVertical: PropTypes.string,
    scrollPositionHorizontal: PropTypes.string,
    scrollBehaviour: PropTypes.string,
    scrollParentHorizontal: PropTypes.bool,
    scrollToTop: PropTypes.bool,
    scrollToWindowTopLeft: PropTypes.bool,
    isList: PropTypes.bool,
    scrollIntoViewIfNeeded: PropTypes.bool,
    scrollToContainerTop: PropTypes.bool,
    scrollToContainerLeft: PropTypes.bool,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    railPosition: PropTypes.number,
    style: PropTypes.object,
    onKeyUp: PropTypes.func,
    focusLink: PropTypes.bool,
    focusUpElement: PropTypes.bool,
    focusDownElement: PropTypes.bool,
    focusLeftElement: PropTypes.bool,
    focusRightElement: PropTypes.bool,
    setFocusedElementDispatch: PropTypes.func,
    isSearchEvent: PropTypes.bool,
};

AnalyticsLinkComponent.defaultProps = {
    className: '',
    onClick: undefined,
    onBlur: undefined,
    children: '',
    to: '',
    title: '',
    id: null,
    tilePosition: undefined,
    'data-sn-down': undefined,
    'data-sn-up': undefined,
    'data-sn-left': undefined,
    'data-sn-right': undefined,
    'area-role': '',
    'aria-label': '',
    'data-toggle': '',
    focusUpElement: '',
    focusDownElement: '',
    focusLeftElement: '',
    focusRightElement: '',
    onDragStart: null,
    scrollPositionVertical: 'center',
    scrollPositionHorizontal: 'nearest',
    scrollBehaviour: 'smooth',
    scrollParentHorizontal: false,
    scrollToTop: false,
    scrollToWindowTopLeft: true,
    isList: false,
    scrollIntoViewIfNeeded: false,
    scrollToContainerTop: false,
    scrollToContainerLeft: false,
    onFocus: () => {},
    onKeyDown: () => {},
    onKeyUp: null,
    onMouseEnter: null,
    railPosition: null,
    style: {},
    autoFocus: false,
    focusLink: false,
    setFocusedElementDispatch: () => {},
    isSearchEvent: false,
};


function mapStateToProps(state, props) {
    const {
        navigation: { selector = '', targetByClass = false } = {}, location: {
            navigationObj = {},
        } = {},
    } = state;
    const { id = '', className = '', autoFocus } = props;
    let isFocused = false;
    if (selector) {
        if (!targetByClass) {
            isFocused = selector === id;
        }
        else {
            isFocused = matchClassSelector({
                classSelector: selector,
                className,
            });
        }
    }
    return {
        focusLink: isFocused || autoFocus,
        navigationObj,
    };
}

const analyticsLinkComponent = connect(mapStateToProps, {
    setFocusedElementDispatch: setFocusedElement,
})(memo(AnalyticsLinkComponent));

export { analyticsLinkComponent as AnalyticsLinkComponent };
