import {
    call, put, select, takeLatest, getContext,
} from 'redux-saga/effects';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { isOnline } from '../../../utilities/WindowUtil';
import { searchByPaginatedQuery, searchSuggestions } from '../../../service/end-points/SearchApiService';
import {
    SearchActions, getSearchResultError, getSearchResultSuccess, getPaginatedSearchResultSuccess, getSearchSuggestionsSuccess, addResentSearch,
} from '../actions/SearchActions';
import { SEARCH_CONFIG } from '../../../constants/AppConst';
import { convertToNumber, getAppId } from '../../../utilities/CommonUtil';
import { getUserConfigFromReducer } from '@airtel-tv/redux/StoreListing';


function* fetchPaginatedSearchResults(action) {
    const userConfig = yield select(getUserConfigFromReducer);

    const {
        lang,
        superType,
        sortBy,
        query,
        more,
    } = action;

    let {
        offSet,
        count,
    } = action;

    offSet = convertToNumber(offSet);
    count = convertToNumber(count);

    if (!count) {
        count = SEARCH_CONFIG.DEFAULT_COUNT;
    }

    const deviceUtil = yield getContext('deviceUtil');

    const appId = getAppId();

    try {
        const searchResult = yield call(searchByPaginatedQuery, {
            userContentProperties: userConfig.userContentProperties || {},
            lang,
            count,
            superType: superType ? superType.toUpperCase() : '',
            offSet,
            appId,
            sortBy,
            query,
            more,
            xAtvDid: deviceUtil.getXAtvDid(),
            deviceUtil,
        });

        const payload = {
            ...searchResult,
            categories: searchResult.categories || [], // categories key is must in successful response
        };

        const isPaginated = offSet !== 0;

        if (!isPaginated) {
            if (query) {
                yield put(addResentSearch({
                    query,
                    maxCount: SEARCH_CONFIG.MAX_RECENT_SEARCH_COUNT,
                }));
            }

            yield put(getSearchResultSuccess({
                query,
                superType,
                payload,
            }));
            yield put(getSearchSuggestionsSuccess({ payload: [] })); // hide suggestions
        }
        else {
            yield put(getPaginatedSearchResultSuccess({
                query,
                superType,
                payload,
            }));
        }
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            ...error,
        };

        yield put(getSearchResultError({ payload }));
    }
}

function* fetchSearchSuggestions(action) {
    const {
        query,
    } = action;

    const { userContentProperties } = yield select(getUserConfigFromReducer);

    const deviceUtil = yield getContext('deviceUtil');

    const appId = getAppId();

    try {
        const searchResult = yield call(searchSuggestions, {
            appId,
            query,
            maxCount: SEARCH_CONFIG.SUGGESTION_LIMIT,
            xAtvDid: deviceUtil.getXAtvDid(),
            userContentProperties,
        });

        yield put(getSearchSuggestionsSuccess({ payload: searchResult }));
    }
    catch (e) {
        yield put(getSearchSuggestionsSuccess({ payload: [] }));

        console.error(e);
    }
}

export default [
    takeFirst(SearchActions.FETCH_SEARCH, fetchPaginatedSearchResults),
    takeLatest(SearchActions.FETCH_SEARCH_SUGGESTIONS, fetchSearchSuggestions),
];
