// TimeoutManager.js
export const timeouts = {};

export function setCustomTimeout(id, callback, delay) {
    timeouts[id] = setTimeout(callback, delay);
    return timeouts[id];
}

export function clearCustomTimeout(id) {
    if (timeouts[id]) {
        clearTimeout(timeouts[id]);
        delete timeouts[id];
    }
}

export const timeoutIds = {
    RAIL_SCROLL_TIMEOUT: 'RAIL_SCROLL_TIMEOUT',
    SAVE_QUICK_VIEW_TIMEOUT: 'SAVE_QUICK_VIEW_TIMEOUT',
    QUICK_VIEW_TRAILER_TIMEOUT: 'QUICK_VIEW_TRAILER_TIMEOUT',
    IMAGE_LAZY_TIMER: 'IMAGE_LAZY_TIMER',
};
