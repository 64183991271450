import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';

export const getPreferredPartnerPlansRefIds = (uid) => {
    const preferredPartnerPlansRefIdsArray = browserStore.get(`${LOCAL_STORE_KEYS.PREFERRED_PARTNER_PLANS_REFIDS}${uid}`) ? browserStore.get(`${LOCAL_STORE_KEYS.PREFERRED_PARTNER_PLANS_REFIDS}${uid}`) : [];
    return preferredPartnerPlansRefIdsArray;
};

export const setPreferredPartnerPlansRefIds = (preferredPartnerPlans = [], uid) => {
    const preferredPartnerRefIds = preferredPartnerPlans.map(preferredPartnerPlansOldItem => preferredPartnerPlansOldItem.refId);
    browserStore.set(`${LOCAL_STORE_KEYS.PREFERRED_PARTNER_PLANS_REFIDS}${uid}`, preferredPartnerRefIds);
};

export const hidePreferredPartnerOfferPopupUtil = (preferredPartnerPlans, uid) => {
    const preferredPartnerPlansRefIdsInStore = getPreferredPartnerPlansRefIds(uid);
    const preferredPartnerPlansRefIds = preferredPartnerPlans.map(preferredPartnerPlansItem => preferredPartnerPlansItem.refId);
    const hidePreferredPartnerOfferPopupValue = preferredPartnerPlansRefIds.every(val => preferredPartnerPlansRefIdsInStore.includes(val));
    return hidePreferredPartnerOfferPopupValue;
};
