import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import lodashIsEqual from 'lodash/isEqual';
import { Navigate } from 'react-router';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { LocationUtil } from '@airtel-tv/utils';
import {
    isObjEmpty,
} from '@airtel-tv/utils/GlobalUtil';
import { getLangfromCode } from '@airtel-tv/utils/ContentMetaUtil';
import { LOCAL_RAIL_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { buildRail } from '@airtel-feature/layout/builders/RailBuilder';
import { getDimensionPageData, resetParticularData } from '../actions/DimensionPageActions';
import {
    joinDimensions, formTitle, dimensionUrlHelper, newDimensionUrlHelper, DIMENSION_REDIRECTION_PATHS,
} from '../helper/DimensionPageHelper';
import {
    getTileType,
    getDimensionMeta,
    getDimensionPageContent,
    firstLetterUpper,
} from '../../../utilities/CommonUtil';
import {
    DIMENSION_PAGE_CONTENT_LIMIT, DROPDOWN_NAMES, IMAGE_PATHS, DIMENSION_PAGE_MAP, INTERSECTION_OBSERVER_THRESHOLD, BREADCRUMBS_NAME, DIMENSION_URL_TO_TYPE,
} from '../../../constants/AppConst';
import { getRail } from '../../rail/factories/RailFactory';
import IntersectionObservableHOC from '../../shared/hoc/IntersectionObservableHOC';
import SmallLoaderComponent from '../../shared/components/loader/SmallLoaderComponent';
import { fetchAllChannels } from '../../rail/actions/ChannelAction';
import { changeMetaData } from '../../notify/NotifyActions';
import LanguageProvider from '../../../providers/LanguageProvider';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { FilterComponent } from '../components/FilterComponent';
import { getGenreList } from '../actions/GenreListActions';
import BreadCrumbsUtil from '../../breadcrumbs/util/BreadCrumbsUtil';
import { updatePageBreadCrumbs } from '../../breadcrumbs/actions/BreadCrumbsActions';
import MarkupFactory from '../../watch-actions/factories/MarkupFactory';
import { updateOnPageDescriptionAction } from '../../footer/actions/FooterAction';
import { fetchBlogs } from '../../blog/BlogActions';

const ErrorPageComponent = Loadable({
    loader: () => import('../../shared/components/error-page/ErrorPageComponent' /* webpackChunkName: "Error_Component" */),
    loading: () => <div />,
});


class DimensionPageContainer extends Component {
    constructor(props) {
        super(props);
        this.title = '';
        this.genreId = '';
        this.dimensionPageId = '';
        this.LANGUAGE = LanguageProvider();
        this.deviceUtil = props.deviceUtil;
        this.isGenreExist = false;
        this.contentLimit = this.deviceUtil.isBot() ? DIMENSION_PAGE_CONTENT_LIMIT.GOOGLE_BOT : DIMENSION_PAGE_CONTENT_LIMIT.USER;
        this.state = {
            genreList: [],
            sortAlphabetic: '',
            sortRecent: 'desc',
        };
        this.init();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            queryParams,
        } = nextProps;

        if (!prevState || queryParams !== prevState.queryParams) {
            return {
                queryParams,
            };
        }
        return null;
    }


    componentDidMount() {
        this.init();
        const {
            dimensionPageData, genreListData, cdpPostFooterMapping, blogConfig, fetchBlogsDispatch, location: { pathname = '' },
        } = this.props;
        if (pathname !== '' && cdpPostFooterMapping && Object.keys(cdpPostFooterMapping).length > 0) {
            const postId = cdpPostFooterMapping[pathname];
            if (blogConfig?.cdpOnPageData && blogConfig?.cdpOnPageData[postId]) {
                return;
            }
            fetchBlogsDispatch({ postId });
        }
        if (isObjEmpty(genreListData[this.genreId])) {
            this.fetchGenres();
        }
        else {
            this.getGenreList();
        }

        if (isObjEmpty(dimensionPageData[this.dimensionPageId])) {
            this.fetchDimensionPageData({ isParamChanged: false });
        }
    }

    componentDidUpdate(prevState, prevProps) {
        const {
            queryParams, sortAlphabetic, sortRecent, genreList,
        } = this.state;
        const {
            dimensionPageData, resetDataDispatch, genreListData, location = {}, blogConfig,
        } = this.props;
        const { sortAlphabetic: prevSortAlphabetic = '', sortRecent: prevSortRecent = '' } = prevProps;
        const { location: prevLocation = {} } = prevState;
        const { pathname: prevPathname = '' } = prevLocation;
        const { pathname = '' } = location;

        if (prevState && prevState.queryParams !== queryParams) {
            this.init();
            if (isObjEmpty(dimensionPageData[this.dimensionPageId])) {
                this.fetchGenres();
                this.fetchDimensionPageData({ isParamChanged: false });
            }
        }
        // eslint-disable-next-line react/prop-types
        if ((prevSortAlphabetic !== sortAlphabetic) || (prevSortRecent !== sortRecent) || (prevPathname !== pathname) || (blogConfig !== prevState.blogConfig)) {
            const payload = {
                [this.dimensionPageId]: {},
            };
            this.init();
            resetDataDispatch({ payload });
            this.fetchDimensionPageData({ isParamChanged: true });
        }

        if (prevProps && !lodashIsEqual(prevProps.genreListData, genreListData) && genreList && genreList.length < 1) {
            this.getGenreList();
        }
    }

    fetchGenres = () => {
        const {
            pageTitle, lang, getGenreListDispatch, genreListData,
        } = this.props;
        // call genre dispatch saga
        const availableGenres = lodashGet(genreListData, `[${this.genreId}].genres`, []);
        if (!availableGenres || availableGenres.length < 1) {
            const payload = {
                ct: joinDimensions({
                    params: pageTitle,
                    seperator: ',',
                }),
                lang: joinDimensions({
                    params: lang,
                    seperator: ',',
                }),
                genreId: this.genreId,
            };
            getGenreListDispatch({ payload });
        }
    };

    getGenreList = () => {
        const { genreListData, genres } = this.props;
        const genreCount = lodashGet(genreListData, `[${this.genreId}].count`, -1);
        let genreList = [
            { name: DROPDOWN_NAMES.ALL },
        ];
        if (genreCount > 0) {
            const availableGenres = lodashGet(genreListData, `[${this.genreId}].genres`, []);


            const genreFromStore = availableGenres.map(genre => ({
                name: genre,
            }));
            let indexCurrentGenre;

            genres.forEach((genre) => {
                indexCurrentGenre = availableGenres.indexOf(genre);
                if (indexCurrentGenre === -1 && genre && this.isGenreExist) {
                    genreList.push({ name: genre });
                }
            });

            genreList = [
                ...genreList,
                ...genreFromStore,
            ];
            this.setState({
                genreList,
            });
        }
        else if (genreCount === 0) {
            if (genres && genres.length > 0 && this.isGenreExist) {
                genres.forEach(genre => genreList.push({ name: genre }));
            }
            this.setState({
                genreList,
            });
        }
    };


    fetchDimensionPageData = ({ isParamChanged = false }) => {
        const {
            getDimensionPageDataDispatch, dimensionPageData,
        } = this.props;

        const {
            pageTitle, genres, lang,
        } = this.props;

        const { sortRecent, sortAlphabetic } = this.state;

        const totalCount = lodashGet(dimensionPageData[this.dimensionPageId], 'totalCount', 200);
        const count = lodashGet(dimensionPageData[this.dimensionPageId], 'count', 0);
        if (!isParamChanged && count === totalCount) {
            return;
        }

        const contentCount = totalCount && (count + this.contentLimit > totalCount) ? totalCount - count : this.contentLimit;
        const queryParams = {
            ct: joinDimensions({
                params: pageTitle,
                seperator: ',',
            }),
            genres: joinDimensions({
                params: this.isGenreExist ? genres : [],
                seperator: ',',
            }),
            lang: joinDimensions({
                params: lang,
                seperator: ',',
            }),
            offset: isParamChanged ? 0 : count,
            count: isParamChanged ? this.contentLimit : contentCount,
            recent: sortRecent || null,
            alphabetical: sortAlphabetic || null,
        };
        const payload = {
            ...queryParams,
            isParamChanged,
            dataId: this.dimensionPageId,
        };
        getDimensionPageDataDispatch({ payload });
    };

    findDimensionPageId = () => {
        const {
            pageTitle, lang, genres,
        } = this.props;

        this.genreId = joinDimensions({
            params: [
                getLangfromCode({ lang: lodashGet(lang, '[0]', null) }),
                pageTitle[0],
            ],
            seperator: '',
        });

        const params = [
            getLangfromCode({ lang: lodashGet(lang, '[0]', null) }),
            this.isGenreExist ? lodashGet(genres, '[0]', null) : '',
            pageTitle[0],
        ];
        this.dimensionPageId = joinDimensions({
            params,
            seperator: '',
        });
    };


    init = () => {
        const {
            changeMetaDataDispatch, genres, lang, pageTitle, updatePageBreadCrumbsDispatch, updateOnPageDescriptionActionDispatch, match, customCdpContent, blogConfig, cdpPostFooterMapping, location: { pathname = '' }, dimensionGenersWeb,
        } = this.props;
        const {
            queryParams,
        } = this.state;

        this.isGenreExist = dimensionGenersWeb.includes(
            genres[0]?.toUpperCase(),
        );

        const newTitle = formTitle({
            genres: this.isGenreExist ? lodashGet(genres, '[0]', null) : '',
            lang: getLangfromCode({ lang: lodashGet(lang, '[0]', null) }),
            pageTitle: pageTitle[0],
        });
        if (this.title !== newTitle) {
            this.setState({
                genreList: [],
            });
            this.title = newTitle;
        }

        this.findDimensionPageId();
        const canonicalUrl = RoutingUtil.getDimensionPageUrlV2({
            pageTitle: DIMENSION_PAGE_MAP[pageTitle[0]],
            genres: this.isGenreExist ? lodashGet(genres, '[0]', null) : '',
            lang: getLangfromCode({ lang: lodashGet(lang, '[0]', null) }),
            queryParams,
        });
        this.fetchChannels();
        const breadCrumbsList = BreadCrumbsUtil.dimensionPageBreadCrumbs({
            pageTitle: pageTitle[0],
            prefix: (this.isGenreExist && lodashGet(genres, '[0]', null)) || lodashGet(lang, '[0]', null),
            title: this.title,
            curPageLink: canonicalUrl,
        });
        const watchActionCatalogues = MarkupFactory({
            contentDetails: {
                programType: BREADCRUMBS_NAME.ARTISTS,
            },
            baseUrl: this.deviceUtil.getBaseUrl(),
            breadCrumbsList,
        });

        const { cdpOnPageData = {} } = blogConfig;
        const postId = pathname && cdpPostFooterMapping?.[pathname];
        if (postId) {
            const { content = {}, yoast_head_json: headJson = {} } = (postId && cdpOnPageData?.[postId]) || {};
            if (content && Object.keys(content).length > 0) {
                const { rendered } = content;
                updateOnPageDescriptionActionDispatch(
                    { onPageDescription: rendered },
                );
            }
            if (headJson) {
                const { description = '', title = '', og_description = '' } = headJson;
                const headMetaData = {
                    title,
                    description: description || og_description,
                    link: canonicalUrl,
                };
                changeMetaDataDispatch({
                    headMetaData,
                });
            }
        }
        else {
            const seoFooterContent = getDimensionPageContent({
                pageTitle: pageTitle[0],
                genres: this.isGenreExist ? lodashGet(genres, '[0]', '') : '',
                lang: getLangfromCode({ lang: lodashGet(lang, '[0]', '') }),
            });
            if (seoFooterContent) {
                const { title: seoTitle, description: seoDescription } = getDimensionMeta({
                    genres: this.isGenreExist ? lodashGet(genres, '[0]', '') : '',
                    lang: getLangfromCode({ lang: lodashGet(lang, '[0]', '') }),
                    pageTitle: pageTitle[0],
                });
                updateOnPageDescriptionActionDispatch({
                    onPageDescription: seoFooterContent,
                });

                changeMetaDataDispatch({
                    headMetaData: {
                        title: seoTitle,
                        description: seoDescription,
                        link: canonicalUrl,
                        watchActionCatalogues,
                    },
                });
            }
        }
        // if (this.LANGUAGE.META_DIMENSION_PAGE_CONTENT) {
        //     const onPageDescription = replaceAll(this.LANGUAGE.META_DIMENSION_PAGE_CONTENT, '{dimensionPageMeta}', this.title);
        //     updateOnPageDescriptionActionDispatch({
        //         onPageDescription,
        //     });
        // }
        updatePageBreadCrumbsDispatch({ breadCrumbsList });
    };

    fetchChannels = () => {
        const { channels, fetchAllChannelsDispatch } = this.props;
        if (channels.length <= 1) {
            fetchAllChannelsDispatch();
        }
    };

    dropDownItemClicked = ({ queryParams }) => {
        this.setState({
            queryParams,
        });
    };

    sortDropDownItemClicked = (order) => {
        if (order === 'A-Z') {
            this.setState({
                sortAlphabetic: 'asc',
                sortRecent: '',
            });
        }
        else if (order === 'Z-A') {
            this.setState({
                sortAlphabetic: 'desc',
                sortRecent: '',
            });
        }
        else if (order === 'Recent') {
            this.setState({
                sortAlphabetic: '',
                sortRecent: 'desc',
            });
        }
    };

    render() {
        const {
            dimensionPageData, channels, languageMap, genres, lang, pageTitle,
            genreListData, history,
            cpDetailsById, subscriptionDataById, errorPageProps, redirectTo404, location, dimensionGenersWeb,
        } = this.props;
        const { genreList, sortAlphabetic, sortRecent } = this.state;
        if (!this.dimensionPageId) {
            this.findDimensionPageId();
        }
        let genreItems = genreList;
        if (!genreItems || genreItems.length < 1) {
            // genreItems = lodashGet(genreListData, `[${this.genreId}].genres`, []).map(genre => ({ name: genre }));
            genreItems = dimensionGenersWeb.map(genre => ({ name: firstLetterUpper(genre?.toLowerCase()) }));
            genreItems = [
                { name: DROPDOWN_NAMES.ALL },
                ...genreItems,
            ];
        }

        this.title = formTitle({
            genres: this.isGenreExist ? lodashGet(genres, '[0]', null) : '',
            pageTitle: pageTitle[0],
            lang: getLangfromCode({ lang: lodashGet(lang, '[0]', null) }),
        });
        const pageData = lodashGet(dimensionPageData, `[${this.dimensionPageId}]`, {});
        const totalCount = lodashGet(pageData, 'totalCount', 0);
        const count = lodashGet(pageData, 'count', -1); // -1 -> data still being loaded ; 0 -> empty data from call!
        const items = lodashGet(pageData, 'results', []);

        const tileType = getTileType({ pageTitle: pageTitle[0] });

        const railProps = buildRail({
            items,
            explicitTileType: tileType,
            explicitRailType: LOCAL_RAIL_TYPES.DIMENSION_LIST,
            explicitRailTitle: this.title,
            channels,
            cpDetailsById,
            subscriptionDataById,
            totalCount,
        });
        const errorMessage = lodashGet(errorPageProps, 'errorMessage', {});
        let railElement = [];
        if (count === 0) {
            railElement = (
                <ErrorPageComponent
                    message={errorMessage}
                    imageUrl={IMAGE_PATHS.ERROR_PAGE_OPSS}
                />
            );
        }
        else {
            railElement = count !== -1 ? getRail({ railProps }) : null;
        }
        const filterElement = (
            <FilterComponent
                // languageMap={languageMap}
                // genres={this.isGenreExist ? genres : []}
                // pageTitle={pageTitle}
                // lang={lang}
                railTitle={this.title}
                // dropDownItemClicked={this.dropDownItemClicked}
                // genreDropDownItemClicked={this.genreDropDownItemClicked}
                // sortDropDownItemClicked={this.sortDropDownItemClicked}
                // sortAlphabetic={sortAlphabetic}
                // sortRecent={sortRecent}
                isBot={this.deviceUtil.isBot()}
                isMobile={this.deviceUtil.isMobile()}
                // genreList={genreItems}
                // windowWidth={this.deviceUtil.windowWidth}
                // history={history}
                showFilter={false}
            />
        );
        const loader = count < totalCount ? (
            <SmallLoaderComponent
                style={{ marginTop: '20px' }}
                key={`${pageTitle - lang - genres}`}
            />
        ) : null;
        /* to be reviewed */

        if (redirectTo404) {
            return <Navigate to={ROUTE_PATHS.NOT_FOUND} />;
        }
        if (DIMENSION_REDIRECTION_PATHS[location.pathname]) {
            return <Navigate to={ROUTE_PATHS.ROOT} />;
        }

        return (
            <div
                className="dimension-page-tab-padding"
                style={{ paddingBottom: '18px' }}
            >
                <section className="container-fluid component-top-padding more-like-container grid-page-channel">
                    {filterElement}
                    <IntersectionObservableHOC
                        fetchMore={this.fetchDimensionPageData}
                        hasMore={count < totalCount}
                        loaderElem={loader}
                        startPageNumber={1}
                        id={`${sortAlphabetic}${this.dimensionPageId}${sortRecent}`}
                        threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
                    >
                        {railElement}
                    </IntersectionObservableHOC>
                </section>
            </div>
        );
    }
}

DimensionPageContainer.propTypes = {
    dimensionPageData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    getDimensionPageDataDispatch: PropTypes.func.isRequired,
    pageTitle: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    genres: PropTypes.array, // eslint-disable-line react/forbid-prop-types,
    lang: PropTypes.array, // eslint-disable-line react/forbid-prop-types,
    channels: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    fetchAllChannelsDispatch: PropTypes.func.isRequired,
    changeMetaDataDispatch: PropTypes.func.isRequired,
    queryParams: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    genreListData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    languageMap: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    resetDataDispatch: PropTypes.func.isRequired,
    getGenreListDispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    updatePageBreadCrumbsDispatch: PropTypes.func.isRequired,
    cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
    subscriptionDataById: PropTypes.objectOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
    dimensionGenersWeb: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    updateOnPageDescriptionActionDispatch: PropTypes.func.isRequired,
    blogConfig: PropTypes.object,
};

DimensionPageContainer.defaultProps = {
    dimensionPageData: {},
    genres: '',
    lang: '',
    channels: [],
    queryParams: '',
    blogConfig: {},
    dimensionGenersWeb: [],
};

function mapStateToProps(state, props) {
    const { location: { search: queryParams }, match } = props;
    const {
        dimensionPageData, channel, appConfig, genreData: genreListData, userConfig: { subscriptionDataById }, blogConfig,
    } = state;
    const {
        cpDetailsById,
        customCdpContent,
        cdpPostFooterMapping,
        dimensionGenersWeb,
    } = appConfig;
    const query = LocationUtil.getQueryParams(props);
    const { genres, lang, pageTitle } = newDimensionUrlHelper(appConfig, props.match, query);

    const languageMap = [
        { name: DROPDOWN_NAMES.ALL },
    ];
    const availableLang = lodashGet(appConfig, 'filterpages', {});
    const errorPageProps = lodashGet(appConfig, 'errorPageProps', '');
    lang.forEach((language) => {
        if (language && !availableLang[getLangfromCode({ lang: language })]) {
            languageMap.push({
                name: getLangfromCode({ lang: language }),
                code: language,
            });
        }
    });

    Object.keys(availableLang).forEach((language) => {
        languageMap.push({
            name: language,
            code: availableLang[language],
        });
    });

    const pageTitleParam = lodashGet(match, 'params.pageTitle');
    const title = appConfig.dimensionPages.find(item => item.urlName === pageTitleParam);
    let redirectTo404;
    if (!title) {
        redirectTo404 = true;
    }

    return {
        errorPageProps,
        dimensionPageData,
        pageTitle,
        lang,
        genres,
        channels: channel.channels,
        queryParams,
        genreListData,
        languageMap,
        cpDetailsById,
        subscriptionDataById,
        redirectTo404,
        customCdpContent,
        blogConfig,
        cdpPostFooterMapping,
        dimensionGenersWeb,
    };
}

export default withRouter(withDeviceUtil(connect(mapStateToProps, {
    fetchAllChannelsDispatch: fetchAllChannels,
    resetDataDispatch: resetParticularData,
    getDimensionPageDataDispatch: getDimensionPageData,
    changeMetaDataDispatch: changeMetaData,
    getGenreListDispatch: getGenreList,
    updatePageBreadCrumbsDispatch: updatePageBreadCrumbs,
    updateOnPageDescriptionActionDispatch: updateOnPageDescriptionAction,
    fetchBlogsDispatch: fetchBlogs,
})((DimensionPageContainer))));
