import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { StoreUtil } from '@airtel-tv/utils/StoreUtil';
import reducers from './Reducers';
import { hasWindow, hasReduxDeveloperTools, getReduxDeveloperTools } from '../utilities/WindowUtil';
import { APP_ENVIRONMENTS } from '../constants/AppConst';
import ExperimentUtil from '@airtel-tv/utils/ExperimentUtil';

let store = null;

// redux dev tools enable for non production builds
function enableDeveloperTools(mw) {
    let middleware = mw;
    const NODE_ENV  = process.env.NODE_ENV;

    if (hasWindow() && NODE_ENV !== APP_ENVIRONMENTS.PRODUCTION) {
        const composeEnhancers = hasReduxDeveloperTools()
            ? getReduxDeveloperTools()({
                // Can specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
            })
            : compose;

        const devToolsExtension = getReduxDeveloperTools(); // https://github.com/zalmoxisus/redux-devtools-extension
        if (typeof devToolsExtension === 'function') {
            middleware = composeEnhancers(middleware);
        }
    }


    // remove log function when production
    else if (NODE_ENV === APP_ENVIRONMENTS.PRODUCTION) {
        // monkey patch
        const { log, info, debug } = console;

        console.log = (...args) => {
            if (window && window.enableConsole) {
                log(...args);
            }
        };
        console.info = (...args) => {
            if (window && window.enableConsole) {
                info(...args);
            }
        };
        console.debug = (...args) => {
            if (window && window.enableConsole) {
                debug(...args);
            }
        };
    }

    return middleware;
}

export default function configureStore({ deviceUtil }) {
    const sagaMiddleware = createSagaMiddleware({ context: { deviceUtil } });
    let middleware = applyMiddleware(sagaMiddleware);

    middleware = enableDeveloperTools(middleware); // enable dev tools for non production builds

    store = createStore(reducers, window.INITIAL_STATE, middleware);
    LanguageProviderUtil.setProvider(store);
    ExperimentUtil.setStore(store);
    StoreUtil.setStore(store);
    // NOTE: THIS HAS BEEN MOVED TO MAIN.JS AND SERVERRENDER.JS
    // sagaMiddleware.run(sagas);
    if (module.hot) {
        module.hot.accept('./Reducers', () => {
            store.replaceReducer(require('./Reducers').default); /* eslint-disable-line global-require */
        });
    }
    return {
        store,
        sagaMiddleware,
    };
}

export const getStore = () => store;

export function getDispatch() {
    return store ? store.dispatch : () => null;
}


export const setStore = (_store) => {
    if (!store) {
        store = _store;
        StoreUtil.setStore(store);
    }
};
