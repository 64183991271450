import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import CryptoUtil from '@airtel-tv/utils/CryptoUtil';
import { getBuildNumber, getDeviceType, removeFalsy } from '@airtel-tv/utils/GlobalUtil';
import { AppEnv } from '@airtel-tv/utils/GetEnv';

const env = AppEnv.getEnv();
const { base = '', paths = '' } = env.endpoints?.distroApi || {};

export const getDistroChannels = async ({
    deviceUtil, cpId, includePlayableOnly, start, end,
}) => {
    const url = `${base}${paths.getEpgPage}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };


    return RequestUtil.GET({
        url,
        params: {
            appId: env.appId,
            cp: cpId,
            includePlayableOnly,
            // start,
            // end,
            os: deviceUtil.getBrowserType(),
            bn: getBuildNumber(),
            dt: getDeviceType(),
        },
        headers: removeFalsy(headers),
    });
};

export const getDistroEpgDetails = async ({
    deviceUtil, cpId, includePlayableOnly, start, end,
}) => {
    const url = `${base}${paths.getEpgPageDetails}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };


    return RequestUtil.GET({
        url,
        params: {
            appId: env.appId,
            cp: cpId,
            includePlayableOnly,
            start,
            end,
            os: deviceUtil.getBrowserType(),
            bn: getBuildNumber(),
            dt: getDeviceType(),
        },
        headers: removeFalsy(headers),
    });
};


export default {
    getDistroChannels,
    getDistroEpgDetails,
};
