import { MARKUP_CONTEXT, MARKUP_TYPES } from '../../../../constants/MarkupConsts';
import ListItemMarkupParser from './ListItemMarkupParser';

export default function ItemListMarkupParser({
    itemList, baseUrl,
}) {
    const itemListElement = itemList && itemList.content ? itemList.content.map((content, index) => ListItemMarkupParser({
        contentDetails: content,
        baseUrl,
        position: index + 1,
    })) : [];
    return {
        '@context': MARKUP_CONTEXT.URL,
        '@type': MARKUP_TYPES.ITEM_LIST,
        itemListElement,
    };
}
