import RequestUtil from '@airtel-tv/utils/RequestUtil';
import env from '../../config';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { removeFalsy } from '@airtel-tv/utils/GlobalUtil';

const { base, paths } = env.endpoints.contentApi;

export const getArtistDetails = async ({ artistId, deviceUtil }) => {
    const url = `${base}${paths.people}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url: `${base}${paths.people}`,
        params: {
            id: artistId,
            appId: env.appId,
        },
        headers: removeFalsy(headers),
    });
};

export default {
    getArtistDetails,
};
