import React, { Component } from 'react';

// TODO: Look for better implementation around this HOC
const withDeviceHOC = (appContext) => {
    return function (WrappedComponent) {
        class DeviceUtilWrapper extends Component {
            constructor(props, context) {
                super(props);
                this.deviceUtil = context && context.deviceUtil || {};
            }
    
            render() {
                return (
                    <WrappedComponent
                        deviceUtil={this.deviceUtil}
                        {...this.props}
                    />
                );
            }
        }
    
        DeviceUtilWrapper.contextType = appContext;
    
        return DeviceUtilWrapper;
    } 
}

export { withDeviceHOC };
