import RequestUtil from '@airtel-tv/utils/RequestUtil';
import env from '../../config';
import {AuthenticationUtil} from '@airtel-tv/utils/AuthenticationUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { removeFalsy, getAppId } from '@airtel-tv/utils/GlobalUtil';


export const getPaginatedPackageDetails = ({
    packageIds = [], offset, count, deviceUtil, userContentProperties = {},
}) => {
    if (!packageIds) {
        return { };
    }
    const { base, paths } = env.endpoints.packageApi;
    const method = 'GET';

    const params = {
        id: packageIds.join(','),
        isMax: true,
        appId: getAppId(),
        packageExperimentId: 0,
        ...userContentProperties,
    };

    if (offset !== undefined || offset !== null) {
        params.offset = offset;
    }

    if (count) {
        params.count = (new Array(packageIds.length)).fill(count).join(',');
    }

    const url = `${base}${paths.getListingPackageContent}`;

    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };

    return RequestUtil.GET({
        url,
        params,
        headers: removeFalsy(headers),
    });
};


export const getPackageDetails = ({
    packageIds = [], offset, count, deviceUtil, userContentProperties = {},
}) => {
    const { base, paths } = env.endpoints.packageApi;
    if (!packageIds) {
        return { };
    }

    const params = {
        id: packageIds.join(','),
        isMax: true,
        appId: getAppId(),
        packageExperimentId: 0,
        ...userContentProperties,
    };

    if (offset !== undefined || offset !== null) {
        params.offset = offset;
    }

    if (count) {
        params.count = (new Array(packageIds.length)).fill(count).join(',');
    }

    const url = `${base}${paths.getPackageContent}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url,
        params,
        headers: removeFalsy(headers),
    });
};


export default {
    getPackageDetails,
    getPaginatedPackageDetails,
};
