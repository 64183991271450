import React, { lazy, Suspense } from 'react';
import Loadable from 'react-loadable';
import LoaderComponent from '../modules/shared/components/loader/LoaderComponent';
import { IMAGE_PATHS, ERROR_PAGE_ACTIONS } from '../constants/AppConst';
import LanguageProvider from '../providers/LanguageProvider';
import ArtistPageLoader from '../modules/shared/components/loader/ArtistPageLoader';


const showLazyLoadErrorScreen = (e) => {
    const LANGUAGE = LanguageProvider();
    console.error(e, 'lazy error');
    const errorPageData = {
        message: LANGUAGE.LOCAL1002,
        imageUrl: IMAGE_PATHS.BUY_SUBSCRIPTION,
        errorAction: ERROR_PAGE_ACTIONS.RELOAD,
        buttonText: LANGUAGE.BUTTON_RETRY,
    };
    const ErrorPageContainer = lazy(() => import('../modules/error-handler/ErrorPageContainer'));


    return () =>
    <Suspense fallback={<div />}>
            <ErrorPageContainer errorPageData={errorPageData} />
    </Suspense>;
};

// GROUP NAV
export const AsyncSearchListContainer = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.SearchListContainer),
    loading: LoaderComponent,
});

export const AsyncLayoutContainer = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.LayoutContainer),
    loading: LoaderComponent,
});


export const AsyncHelpComponent = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.HelpComponent),
    loading: LoaderComponent,
});

export const AsyncWatchlistContainer = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.WatchlistContainer),
    loading: LoaderComponent,
});

export const AsyncYoupageComponent = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.YoupageComponent),
    loading: LoaderComponent,
});

export const AsyncSettingsContainer = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.SettingsContainer),
    loading: LoaderComponent,
});

export const AsyncLanguageSettingsContainer = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.LanguageSettingsContainer),
    loading: LoaderComponent,
});


// GROUP CONTENT
export const AsyncContentDetailsContainer = Loadable({
    loader: () => import('@airtel-feature/content-details/content-details/ContentDetailsContainer' /* webpackChunkName: "content" */)
        .catch(showLazyLoadErrorScreen),
    loading: LoaderComponent,
});

export const AsyncContentLayoutContainer = Loadable({
    loader: () => import('@airtel-feature/content-details/content-details/containers/ContentLayoutContainer' /* webpackChunkName: "content" */)
        .catch(showLazyLoadErrorScreen),
    loading: LoaderComponent,
});


export const AsyncContentDetailLayoutContainerWrapper = Loadable({
    loader: () => import('../modules/content-details/containers/ContentDetailLayoutContainerWrapper' /* webpackChunkName: "content" */)
        .catch(showLazyLoadErrorScreen),
    loading: LoaderComponent,
});


// GROUP APP
export const AsyncApp = Loadable({
    loader: () => import('../modules/app/App' /* webpackChunkName: "app" */)
        .catch(showLazyLoadErrorScreen),
    loading: LoaderComponent,
});

// GROUP ARTIST
export const AsyncArtistContainer = Loadable({
    loader: () => import('../modules/artist/ArtistContainer' /* webpackChunkName: "artist" */)
        .catch(showLazyLoadErrorScreen),
    loading: ArtistPageLoader,
});

export const AsyncDTHLoginComponent = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.DTHLoginComponent),
    loading: LoaderComponent,
});

export const AsyncDTHPaymentComponent = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.DTHPaymentComponent),
    loading: LoaderComponent,
});

export const AsyncHappyCodeContainer = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.HappyCodeContainer),
    loading: LoaderComponent,
});

export const AsyncHappyCodeGenerateComponent = Loadable({
    loader: () => import('./AsyncNavigation' /* webpackChunkName: "nav" */).then(m => m.HappyCodeGenerateComponent),
    loading: LoaderComponent,
});


export default {
    AsyncSearchListContainer,
    AsyncContentDetailsContainer,
};
