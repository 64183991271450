import lodashGet from 'lodash/get';
import { PROGRAM_TYPES, PAGE_TYPE, BREADCRUMBS_NAME } from '../../../constants/AppConst';
import MovieMarkupParser from '../parsers/markup/MovieMarkupParser';
import VideoMarkupBuilder from '../parsers/builder/VideoMarkupBuilder';
import TvSeasonMarkupParser from '../parsers/markup/TvSeasonMarkupParser';
import TvShowEpisodeMarkupParser from '../parsers/markup/TvShowEpisodeMarkupParser';
import TvShowMarkupParser from '../parsers/markup/TvShowMarkupParser';
import ItemListMarkupParser from '../parsers/markup/ItemListMarkupParser';
import BreadCrumbMarkupParser from '../parsers/markup/BreadCrumbMarkupParser';
import WebsiteMarkupParser from '../parsers/markup/WebsiteMarkupParser';
import OrganizationMarkupParser from '../parsers/markup/OrganizationMarkupParser';
import ViewActionParser from '../parsers/markup/ViewActionParser';


const MarkupFactory = ({
    contentDetails, tvShowDetails = {}, seasonDetails = {}, episodeDetails = {}, baseUrl, itemList = {}, breadCrumbsList = [],
}) => {
    const programType = lodashGet(contentDetails, 'programType', itemList ? PAGE_TYPE.LIST : '');
    switch (programType) {
        case PROGRAM_TYPES.MOVIE:
            return [
                BreadCrumbMarkupParser({
                    breadCrumbsList,
                    baseUrl,
                }),
                ViewActionParser(),
                VideoMarkupBuilder({
                    contentDetails,
                    programType,
                    baseUrl,
                }),
               
            ];
        case PROGRAM_TYPES.MOVIETAB:
            return [
                WebsiteMarkupParser({ baseUrl }),
                OrganizationMarkupParser({
                    contentDetails,
                    baseUrl,
                }),
                ViewActionParser(),
            ];
        case PROGRAM_TYPES.HOME:
            return [
                WebsiteMarkupParser({ baseUrl }),
                OrganizationMarkupParser({
                    contentDetails,
                    baseUrl,
                }),
                ViewActionParser(),
            ];
        case PROGRAM_TYPES.LIVETVCHANNEL:
            return [
                VideoMarkupBuilder({
                    contentDetails,
                    programType,
                    baseUrl,
                }),
                ViewActionParser(),
                BreadCrumbMarkupParser({
                    breadCrumbsList,
                    baseUrl,
                }),
            ];
        case PROGRAM_TYPES.LIVETVCHANNELTAB:
            return [
                ViewActionParser(),
                BreadCrumbMarkupParser({
                    breadCrumbsList,
                    baseUrl,
                }),
            ];

        case PROGRAM_TYPES.EPISODE:
            return [
                TvShowEpisodeMarkupParser({
                    contentDetails,
                    tvShowDetails,
                    seasonDetails,
                    baseUrl,
                }),
                VideoMarkupBuilder({
                    contentDetails,
                    programType,
                    baseUrl,
                }),
                ViewActionParser(),
                BreadCrumbMarkupParser({
                    breadCrumbsList,
                    baseUrl,
                }),
            ];
        case PROGRAM_TYPES.SEASON:
            return [
                TvSeasonMarkupParser({
                    contentDetails,
                    episodeDetails,
                    baseUrl,
                }),
                ViewActionParser(),
                BreadCrumbMarkupParser({
                    breadCrumbsList,
                    baseUrl,
                }),
            ];


        case PROGRAM_TYPES.TVSHOWTAB:
            return [
                BreadCrumbMarkupParser({
                    breadCrumbsList,
                    baseUrl,
                }),
                ViewActionParser()
            ];

        case PROGRAM_TYPES.TVSHOW:
            return [
                
                BreadCrumbMarkupParser({
                    breadCrumbsList,
                    baseUrl,
                }),
                ViewActionParser(),
                VideoMarkupBuilder({
                    contentDetails,
                    programType,
                    baseUrl,
                })
            ];

        case PAGE_TYPE.LIST:
            return [
                ItemListMarkupParser({
                    itemList,
                    baseUrl,
                }),
                ViewActionParser(),
                BreadCrumbMarkupParser({
                    breadCrumbsList,
                    baseUrl,
                }),
            ];

        case PROGRAM_TYPES.LIVETVSHOW:
        case PROGRAM_TYPES.LIVETVMOVIE:
        case PROGRAM_TYPES.VIDEO:
        case BREADCRUMBS_NAME.ARTISTS:
        case BREADCRUMBS_NAME.DIMENSION_PAGES:
        case BREADCRUMBS_NAME.SCHEDULE_PAGE:
            return [
                BreadCrumbMarkupParser({
                    breadCrumbsList,
                    baseUrl,
                }),
                ViewActionParser(),
            ];

        case BREADCRUMBS_NAME.LAYOUT:
            return [
                WebsiteMarkupParser({ baseUrl }),
                OrganizationMarkupParser({
                    contentDetails,
                    baseUrl,
                }),
                ViewActionParser(),
                BreadCrumbMarkupParser({
                    breadCrumbsList,
                    baseUrl,
                }),
            ];

        default:
            return null;
    }
};


export default MarkupFactory;
