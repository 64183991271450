import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { showModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import { UserConfigWithAllReset } from '../../web/src/modules/user/UserAction';
import SvodActivationTimer from '@airtel-tv/utils/AppTimers/SvodActivationTimer';

const AppTimer = (props) => {
    const {
        appTimers,
        deviceUtil,
        setFocusedElementDispatch = () => {},
    } = props;
    const [ timerComponents, setTimerComponents] = useState([]);
    const thisRef = useRef({
        appTimersList: {},
    }).current;

    const markAppTimerCompleted = ({ timerName }) => {
        thisRef.appTimersList[timerName] = 'COMPLETED';
        //Filter
    };

    useEffect(() => {
        if (!(appTimers && Object.keys(appTimers).length)) {
            return;
        }
        const components = [];
        Object.keys(appTimers).filter(timerName => !thisRef.appTimersList[timerName]).map(timerName => {
            //We want to create only componets for new timer set in redux. Older ones are filtered by appTimersList having value INPROGRESS or COMPLETED
            const item = appTimers[timerName];
            switch (item.type) {
                case "SVOD_ACTIVATION_TIMER":
                    thisRef.appTimersList[timerName] = 'INPROGRESS';
                    components.push(<SvodActivationTimer key={timerName} timerName={timerName} {...item} deviceUtil={deviceUtil} setFocusedElementDispatch={setFocusedElementDispatch} markAppTimerCompleted={markAppTimerCompleted}/>);
                    break;
                default:
                    break;
            }
            if (components.length) {
                setTimerComponents(comp => [...comp, ...components]);
            }
        })
    }, [appTimers]);

    return (
        <>
            {timerComponents.map(item => item)}
        </>
    );

};


const mapStateToProps = (state) => {
    const {
        notify: {
            appTimers,
        },
    } = state;


    return {
        appTimers,
    };
};

export default withDeviceUtil(connect(mapStateToProps, {
    showModalComponentActionDispatch: showModalComponentAction,
    UserConfigWithAllResetDispatch: UserConfigWithAllReset,
})(AppTimer));
