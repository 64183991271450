import React from 'react';
import { PropTypes } from 'prop-types';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';

function SeekButtonComponent(props) {
    const {
        onButtonClick, isClickable, imageActiveSrc, imageDisabledSrc, id,
    } = props;

    // console.log("SeekButtonComponent: ",isClickable);
    return (
        <div className=" btm-control-space-manager">
            <AnalyticsButtonComponent
                id={id}
                type="button"
                className={`bottom-control-buttons scale-hover${!isClickable ? ' disable-el' : ''}`}
                onClick={onButtonClick}
                aria-label="Seek button"
            >
                {
                    isClickable && (
                        <ThumborImage
                            src={imageActiveSrc}
                        />
                    )}
                {
                    !isClickable && (
                        <ThumborImage
                            src={imageDisabledSrc}
                        />
                    )}

            </AnalyticsButtonComponent>
        </div>
    );
}

SeekButtonComponent.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
    isClickable: PropTypes.bool.isRequired,
    imageActiveSrc: PropTypes.string.isRequired,
    imageDisabledSrc: PropTypes.string.isRequired,
    // eslint-disable-next-line react/require-default-props
    id: PropTypes.string,
};


export default React.memo(SeekButtonComponent);
