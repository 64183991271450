import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { isObjEmpty } from '@airtel-tv/utils/GlobalUtil';
import browserStore from './BrowserStoreUtil';

// TODO: put key names in a seperate file

export class AuthenticationUtil {
    static saveAccessToken(token) {
        browserStore.set(LOCAL_STORE_KEYS.ACCESS_TOKEN, token);
    }

    static getAccessToken() {
        // if (this.getQueryParams('authToken')) {
        //     return this.getQueryParams('authToken');
        // }
        return browserStore.get(LOCAL_STORE_KEYS.ACCESS_TOKEN);
    }

    static clearAccessToken() {
        browserStore.remove(LOCAL_STORE_KEYS.ACCESS_TOKEN);
    }

    static saveAuthToken(token) {
        browserStore.set(LOCAL_STORE_KEYS.TOKEN, token);
    }

    static getAuthToken() {
        // if (this.getQueryParams('token')) {
        //     return this.getQueryParams('token');
        // }
        return browserStore.get(LOCAL_STORE_KEYS.TOKEN);
    }

    static clearAuthToken() {
        browserStore.remove(LOCAL_STORE_KEYS.TOKEN);
    }

    static saveUid(uid) {
        browserStore.set(LOCAL_STORE_KEYS.UID, uid);
    }

    static saveAppId(appId) {
        browserStore.set(LOCAL_STORE_KEYS.APP_ID, appId);
    }

    static getAppId() {
        return browserStore.get(LOCAL_STORE_KEYS.APP_ID);
    }

    static getOS() {
        return browserStore.get(LOCAL_STORE_KEYS.OS);
    }

    static removeAppId() {
        browserStore.remove(LOCAL_STORE_KEYS.APP_ID);
    }

    static getUid() {
        return browserStore.get(LOCAL_STORE_KEYS.UID);
    }

    static getAppVersion() {
        return browserStore.get(LOCAL_STORE_KEYS.APP_VERSION);
    }

    static clearUid() {
        browserStore.remove(LOCAL_STORE_KEYS.UID);
    }

    static saveMsisdnDetected(token) {
        browserStore.set(LOCAL_STORE_KEYS.MSISDN_DETECTED, token);
    }

    static getMsisdnDetected() {
        return browserStore.get(LOCAL_STORE_KEYS.MSISDN_DETECTED);
    }

    static clearMsisdnDetected() {
        browserStore.remove(LOCAL_STORE_KEYS.MSISDN_DETECTED);
    }

    static clearSunnxtKeys() {
        //clear all sunnxt keys on logout
        browserStore.remove(LOCAL_STORE_KEYS.MEDIAKEY);
        browserStore.remove(LOCAL_STORE_KEYS.GLOBALELAPSEDTIME);
        browserStore.remove(LOCAL_STORE_KEYS.SUN_CLIENTKEY);
        browserStore.remove(LOCAL_STORE_KEYS.BOXID);
        browserStore.remove(LOCAL_STORE_KEYS.USERDETAILS);
    }

    static isAuthenticated() {
        const appId = this.getAppId();
        if (appId === 'MOBILITY' || appId === 'ANDROID' || appId === 'IOS') {
            return !isObjEmpty(this.getAuthToken())
                && !isObjEmpty(this.getUid());
        }
        return !isObjEmpty(this.getMsisdnDetected())
            && !isObjEmpty(this.getAuthToken())
            && !isObjEmpty(this.getAccessToken())
            && !isObjEmpty(this.getUid());
    }

    static generateSessionId() {
        return `${AuthenticationUtil.getUid()}-${new Date().getTime()}`;
    }
}

export default AuthenticationUtil;
