import React from 'react';

const SearchIcon = () => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="16"
            cy="16"
            r="16"
            fill="#3C434A"
        />
        <path
            d="M16.125 22.9C19.8667 22.9 22.9 19.8667 22.9 16.125C22.9 12.3833 19.8667 9.35 16.125 9.35C12.3833 9.35 9.35 12.3833 9.35 16.125C9.35 19.8667 12.3833 22.9 16.125 22.9Z"
            stroke="white"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21 21L24 24"
            stroke="white"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>

);

export default SearchIcon;
