import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { removeFalsy } from '@airtel-tv/utils/GlobalUtil';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import env from '../../config';

let { base, paths, serverBase } = env.endpoints.userApi;
base = checkWindowExist() ? base : serverBase;

export const subscriptionPurchaseRedirectionAPI = ({
    deviceUtil, successUrl = '', failureUrl = '', id, userConfig, shortCode,
}) => {
    const {
        os,
        dt,
        bn,
    } = userConfig.userContentProperties;

    const params = removeFalsy({
        cp: id,
        appId: env.appId,
        os,
        dt,
        bn,
        sc: shortCode,
    });

    const data = removeFalsy({
        cpName: id,
        failureUrl,
        successUrl,
    });

    const url = `${paths.subscriptionPurchase}?${LocationUtil.objectToQueryParams(params)}`;

    const method = 'POST';
    const utknData = {
        urlBody: `${method}/${url}${JSON.stringify(data)}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'Content-Type': 'application/json',
    };

    return RequestUtil.POST({
        url: base + url,
        headers: removeFalsy(headers),
        data,
    });
};

export const managePlanRedirectionAPI = ({
    deviceUtil, successUrl = '', failureUrl = '', id, userConfig, shortCode,
}) => {
    const {
        os,
        dt,
        bn,
    } = userConfig.userContentProperties;

    const params = removeFalsy({
        cp: id,
        appId: env.appId,
        os,
        dt,
        bn,
        sc: shortCode,
    });

    const data = removeFalsy({
        cpName: id,
        failureUrl,
        successUrl,
    });

    const url = `${paths.manageSubscription}?${LocationUtil.objectToQueryParams(params)}`;

    const method = 'POST';
    const utknData = {
        urlBody: `${method}/${url}${JSON.stringify(data)}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'Content-Type': 'application/json',
    };

    return RequestUtil.POST({
        url: base + url,
        headers: removeFalsy(headers),
        data,
    });
};

export const stopAutoRenewAPI = ({
    deviceUtil, planId, userConfig, shortCode,
}) => {
    const url = `${paths.stopAutoRenew}?planId=${planId}`;

    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'Content-Type': 'application/json',
    };

    return RequestUtil.GET({
        url: base + url,
        headers: removeFalsy(headers),
    });
};

export const xppClaimApi = ({
    deviceUtil, serviceId, userConfig, shortCode,
}) => {
    const url = `${paths.thanksClaim}?serviceId=${serviceId}`;

    const method = 'POST';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'Content-Type': 'application/json',
    };

    return RequestUtil.POST({
        url: base + url,
        headers: removeFalsy(headers),
    });
};

export const rechargeInfo = ({
    deviceUtil,
}) => {
    const url = `${paths.rechargeInfo}`;

    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);
    const headers = {
        'x-atv-did': deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
        'Content-Type': 'application/json',
    };

    return RequestUtil.GET({
        url: base + url,
        headers: removeFalsy(headers),
    });
};

export default {
    subscriptionPurchaseRedirectionAPI,
    managePlanRedirectionAPI,
    stopAutoRenewAPI,
    xppClaimApi,
    rechargeInfo,
};
