import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';


class ApplicationUtil {
    static getAppHookBannerVisibility() {
        return browserStore.get(LOCAL_STORE_KEYS.SHOW_APP_HOOK);
    }

    static setAppHookBannerVisibility(status = true) {
        browserStore.set(LOCAL_STORE_KEYS.SHOW_APP_HOOK, status);
    }

    static getVisitCountAfterHidingAppHook() {
        return browserStore.get(LOCAL_STORE_KEYS.VISIT_COUNT_AFTER_HIDE_APP_HOOK);
    }

    static setVisitCountAfterHidingAppHook(visCount = 0) {
        browserStore.set(LOCAL_STORE_KEYS.VISIT_COUNT_AFTER_HIDE_APP_HOOK, visCount);
    }
}


export default ApplicationUtil;
