export const XppClaimOnThanksAction = {
    SINGLE_CLAIM_CLICKED: 'SINGLE_CLAIM_CLICKED',
    SINGLE_CLAIM_SUCCESS: 'SINGLE_CLAIM_SUCCESS',
    SINGLE_CLAIM_FAILURE: 'SINGLE_CLAIM_FAILURE',
    STATUS_UPDATE: 'STATUS_UPDATE',
};

export const onSingleClaimClicked = payload => ({
    type: XppClaimOnThanksAction.SINGLE_CLAIM_CLICKED,
    ...payload,
});

export const singleClaimSuccess = payload => ({
    type: XppClaimOnThanksAction.SINGLE_CLAIM_SUCCESS,
    payload: { ...payload },
});

export const singleClaimFailure = payload => ({
    type: XppClaimOnThanksAction.SINGLE_CLAIM_FAILURE,
    payload: { ...payload },
});
export const claimStatusUpdate = payload => ({
    type: XppClaimOnThanksAction.STATUS_UPDATE,
    payload: { ...payload },
});

export const updateUserConfiguration = payload => ({
    type: 'UPDATE_CONFIGURATION',
    payload: { ...payload },
});
