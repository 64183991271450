export class AppEnv {
    constructor(env) {
        this.env = env;
    }

    setBuildDetails(version, buildNumber) {
        const [
            maj,
            min,
            patch,
        ] = version.split('.');

        this.env.appVersion = {
            maj,
            min,
            patch,
        };
        this.env.buildNumber = buildNumber || maj;
    }

    static setEnv(env, version, buildNumber) {
        if (!this.instance) {
            // throw new Error('Environment is already set');
            this.instance = new AppEnv(env);
            this.instance.setBuildDetails(version, buildNumber);
        }
    }

    static getEnv() {
        return this.instance ? this.instance.env : {
            appVersion: '',
            buildNumber: '',
        };
    }
}

export default AppEnv;
