export const ChatbotActions = {
    FETCH_CHATBOT: 'FETCH_CHATBOT',
    FETCH_CHATBOT_SUCCESS: 'FETCH_CHATBOT_SUCCESS',
    FETCH_CHATBOT_FAILURE: 'FETCH_CHATBOT_FAILURE',

};

export const fetchChatbot = payload => ({
    type: ChatbotActions.FETCH_CHATBOT,
    ...payload,
});

export const fetchChatbotSuccess = payload => ({
    type: ChatbotActions.FETCH_CHATBOT_SUCCESS,
    ...payload,
});

export const fetchChatbotFailure = payload => ({
    type: ChatbotActions.FETCH_CHATBOT_FAILURE,
    ...payload,
});