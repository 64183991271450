import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { TIZEN_KEYCODES } from '@airtel-tv/constants';
import { getSourceNameFromStorage } from '@airtel-tv/utils/GlobalUtil';
import { checkWindowExist } from '@airtel-tv/utils';


const PlanButtonPanel = (props) => {
    const {
        tileId, activeTile, onFocusCallback, onKeyDownCallback, onKeyUpCallback, tileProps,
    } = props;
    const {
        purchaseCtaText,
        seePlanCtaText,
        subscriberInfo = {},
        primaryActionHandler,
        secondaryActionHandler,
        analyticsPageId,
        railPosition,
        railId,
        railType,
        railTitle,
        tilePosition,
        assetNamePrefix,
    } = tileProps;

    const [
        focusSecondaryCta,
        setFocusSecondaryCta,
    ] = useState();

    const { currentSource, prevSource } = getSourceNameFromStorage() || {};
    const analyticsMeta = {
        source_name: currentSource,
        source_page: prevSource,
        rail_position: railPosition,
        tile_type: railType,
        rail_id: railId,
        rail_title: railTitle,
        rail_type: railType,
        asset_position: tilePosition,
        page_id: analyticsPageId,
        tileId: 'Subscription',
    };

    return (
        <div
            id="plan-cta"
            className="plan-cta"
        >
            {Object.keys(subscriberInfo)?.length ? (
                <div className="subscribe-for">
                    <div className="subscribe-for-icon-holder">
                        <div className="suscribe-for-icon">
                            <ThumborImage
                                src={subscriberInfo?.icon}
                                className="device-icon"
                            />
                        </div>
                    </div>

                    <div>
                        {subscriberInfo?.heading ? (
                            <div className="subscribe-for-title-holder">
                                <span className="subscribe-for-title text-16 text-bold line-height-24 mobile-line-height-20 mobile-text-14 text-ls-24 text-ls-bolder line-height-ls-36">{subscriberInfo?.heading}</span>
                            </div>
                        ) : null}
                        {subscriberInfo?.subHeading ? <div className="subscribe-for-subtitle text-bold text-14 line-height-18 mobile-line-height-18 text-ls-24 text-ls-normal line-height-ls-36 ">{subscriberInfo?.subHeading}</div> : null}
                    </div>
                </div>
            ) : null}
            <div className="plan-buttons">
                {purchaseCtaText ? (
                    <AnalyticsButtonComponent
                        id={tileId}
                        autoFocus={activeTile && !focusSecondaryCta}
                        onFocus={(e) => { 
                            e.preventDefault();
                            onFocusCallback(e)
                            if (checkWindowExist()) {
                                window.scrollTo(0, 0);
                            }
                        }}
                        onKeyDown={(e) => {
                            const { keyCode = '' } = e.event;
                            if (keyCode === TIZEN_KEYCODES.DOWN_ARROW) {
                                setFocusSecondaryCta(true);
                            }
                            else {
                                onKeyDownCallback(e.event);
                            }
                        }}
                        onKeyUp={e => onKeyUpCallback(e?.event)}
                        onClick={() => {
                            if (typeof primaryActionHandler === 'function') {
                                primaryActionHandler(props);
                            }
                        }}
                        className="one-hub-tile-cta text-bold text-16 line-height-24 mobile-text-14 text-ls-24 text-ls-bold primary"
                        meta={{
                            ...analyticsMeta,
                            action: purchaseCtaText,
                            asset_name: `${assetNamePrefix}_purchasePlan`,
                        }}
                    >
                        {purchaseCtaText}
                    </AnalyticsButtonComponent>
                ) : null}
                { seePlanCtaText ? (
                    <AnalyticsButtonComponent
                        onClick={() => {
                            if (typeof secondaryActionHandler === 'function') {
                                secondaryActionHandler(props);
                            }
                        }}
                        autoFocus={activeTile && focusSecondaryCta}
                        onFocus={(e) => { 
                            e.preventDefault();
                            onFocusCallback(e)
                            if (checkWindowExist()) {
                                window.scrollTo(0, 0);
                            }
                        }}
                        onKeyDown={(e) => {
                            const { keyCode = '' } = e?.event;
                            if (keyCode === TIZEN_KEYCODES.UP_ARROW) {
                                setFocusSecondaryCta(false);
                            }
                            else {
                                onKeyDownCallback(e?.event);
                            }
                        }}
                        onKeyUp={e => onKeyUpCallback(e.event)}
                        className="one-hub-tile-cta text-bold text-16 line-height-24 mobile-text-14 text-ls-24 text-ls-bold line-height-ls-30"
                        meta={{
                            ...analyticsMeta,
                            action: seePlanCtaText,
                            asset_name: `${assetNamePrefix}_planDetails`,
                        }}
                    >
                        {seePlanCtaText}
                    </AnalyticsButtonComponent>
                ) : null}
            </div>
        </div>
    );
};

PlanButtonPanel.defaultProps = {
    purchaseCtaText: '',
    seePlanCtaText: '',
    onFocusCallback: () => {},
    onKeyDownCallback: () => {},
    onKeyUpCallback: () => {},
    tileId: '',
    activeTile: false,
};

PlanButtonPanel.propTypes = {
    purchaseCtaText: PropTypes.string,
    seePlanCtaText: PropTypes.string,
    primaryActionHandler: PropTypes.func.isRequired,
    secondaryActionHandler: PropTypes.func.isRequired,
    onFocusCallback: PropTypes.func,
    onKeyDownCallback: PropTypes.func,
    onKeyUpCallback: PropTypes.func,
    tileId: PropTypes.string,
    activeTile: PropTypes.bool,
    tileProps: PropTypes.object.isRequired,
};

export default PlanButtonPanel;
