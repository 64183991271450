import lodashGet from 'lodash/get';
import {
    getContext, call, put, select,
} from 'redux-saga/effects';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { GenreListActions, genreListSuccess } from '../actions/GenreListActions';
// import { getGenreListData } from '../../../service/end-points/SearchApiService';
import { firstLetterUpper } from '../../../utilities/CommonUtil';
import { getAppConfigFromReducer, getUserConfigFromReducer } from '@airtel-tv/redux/StoreListing';


export function* fetchGenreList(action) {
    const payload = lodashGet(action, 'payload', {});
    const { genreId } = payload;
    // const { ct, lang, genreId } = payload;
    // const deviceUtil = yield getContext('deviceUtil');
    // const userConfig = yield select(getUserConfigFromReducer);
    const { dimensionGenersWeb = [] } = yield select(getAppConfigFromReducer);

    // const appId = getAppId();
    // const queryParams = {
    //     userContentProperties: userConfig.userContentProperties,
    //     ct,
    //     lang,
    //     deviceUtil,
    //     appId,
    // };
    // const genreDataFromCall = yield call(getGenreListData, queryParams);
    // const genreList = lodashGet(genreDataFromCall, 'genres', {});
    // const count = lodashGet(genreDataFromCall, 'count', 0);
    try {
        const genreArray = dimensionGenersWeb.map(genre => firstLetterUpper(genre.toLowerCase()));
        const genreData = {
            [genreId]: {
                genres: genreArray,
                count: 100,
            },
        };
        yield put(genreListSuccess({ payload: genreData }));
        // const genreArray = Object.keys(genreList).filter(
        //     genre => dimensionGenersWeb.includes(genre.toUpperCase())
        //     && firstLetterUpper(genre),
        // );
        // if (!isObjEmpty(genreList)) {
        //     const genreData = {
        //         [genreId]: {
        //             genres: genreArray,
        //             count,
        //         },
        //     };
        //     yield put(genreListSuccess({ payload: genreData }));
        // }
        // else {
        //     const genreData = {
        //         [genreId]: {
        //             genres: [],
        //             count: 0,
        //         },
        //     };
        //     yield put(genreListSuccess({ payload: genreData }));
        // }
    }
    catch (error) {
        yield put(genreListSuccess({ payload: {} }));
        console.error(error);
    }
}

export default [
    takeFirst(GenreListActions.FETCH_GENRE_LIST, fetchGenreList),
];
