export const CONTENT_PROVIDERS = {
    ALT_BALAJI: 'ALTBALAJI',
    HOICHOI: 'HOICHOI',
    HOOQ: 'HOOQ',
    ZEE5: 'ZEE5',
    EUROS: 'EROSNOW',
    LIVETV: 'LIVETV',
    FAST_FILMS: 'FASTFILMZ',
    MWTV: 'MWTV',
    HUNGAMA: 'HUNGAMA',
    SHEMAROOME: 'SHEMAROOME',
    CURIOSITYSTREAM: 'CURIOSITYSTREAM',
    ULTRA: 'ULTRA',
    LIONSGATEPLAY: 'LIONSGATEPLAY',
    DEVILS_CIRCUIT: 'DEVILS_CIRCUIT',
    NODWIN: 'NODWIN',
    VOOT: 'VOOT',
    MUBI: 'MUBI',
    DISTROTV: 'DISTROTV',
    MINITV: 'MINITV',
    NETFLIX: 'NETFLIX',
};

export const APP_INSTALL_META = {
    NETFLIX: {
        title: 'Watch on the Netflix App',
        primaryCTA: 'Okay, got it',
        ctaPretext: 'For further assistance, please refer to our Help & Support section',
        imageUrl: 'https://image.airtel.tv/grandslam/content/2024_10_11/Netflix_CP_Icons_Square.png',
        imageSettings: {
            320: 250,
            500: 250,
            1000: 450,
        },
        stepTitle: 'How to Watch?',
        steps: [
            'Download the Netflix App on your device',
            'Login to the Netflix App',
            'Enjoy watching your favourite contents',
        ],
        popupId: 'netflix_app_install',
    },
};

export const DEFAULT_CHANNEL_PRODUCT_ID = '200292';

export const CP_CONFIG = {

};

export default {
    CONTENT_PROVIDERS,
    DEFAULT_CHANNEL_PRODUCT_ID,
    CP_CONFIG,
};
