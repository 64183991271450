import React from 'react';

const PYWBackButton = ({iconProps = {}}) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.5" cx="16" cy="16" r="16" fill="#0C0F12"/>
    <path d="M18.5 22.25L12.25 16L18.5 9.75" stroke="#E8EAED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default PYWBackButton;
