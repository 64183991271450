import React from 'react';
import PropTypes from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import Loadable from 'react-loadable';

const LoaderComponent = ()=><div/>;

const ModalPopupContainer = Loadable({
    loader: () => import('../../modal-popup/ModalPopupContainer' /* webpackChunkName: "ModalPopupContainer" */),
    loading: LoaderComponent,
});
const NotifyContainer = Loadable({
    loader: () => import('../../notify/NotifyContainer' /* webpackChunkName: "NotifyContainer" */),
    loading: LoaderComponent,
});
function WebView(props) {
    const {
        route, renderElement,
    } = props;
    return (
        <React.Fragment>
            {renderElement}
            <NotifyContainer route={route.routes} />
            <ModalPopupContainer />
        </React.Fragment>
    );
}

WebView.propTypes = {
    renderElement: PropTypes.node.isRequired,
    route: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withRouter(WebView);
