import React, { memo } from 'react';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import { ELEMENT_ID } from '@airtel-tv/constants';

const PillTileComponent = (props) => {
    const {
        tileProps: {
            railPosition, tilePosition, contentDetails: { title = '' } = {}, focusProps, tileCtaActionUrl = '', pillAnalyticsMeta = {},
            onFocus,
            isFocused,
            onKeyDown,
            pageId,
            focusLeftElement,
            focusRightElement,
            focusLeftTargetByClass,
        } = {},
    } = props;
    const tileId = `${pageId}-tile-${railPosition}-${tilePosition}`;

    return (
        <AnalyticsLinkComponent
            meta={pillAnalyticsMeta}
            to={tileCtaActionUrl}
            id={tileId}
            className={`nostyling ${FocusManagementClassNames.CAN_FOCUS} line-height-16 text-normal-14 pills-capsule scrollToTop ${ELEMENT_ID.CAN_SCROLL_LG}`}
            autoFocus={isFocused}
            onKeyDown={onKeyDown}
            focusLeftTargetByClass={focusLeftTargetByClass}
            onFocus={(e) => {
                if (typeof onFocus === 'function') {
                    onFocus({
                        e,
                        railPosition,
                        tilePosition,
                        tileDetails: { nonQuickViewTile: true },
                    });
                }
            }}
            focusLeftElement={focusLeftElement}
            focusRightElement={focusRightElement}
        >
            {title}
        </AnalyticsLinkComponent>

    );
};

export default memo(PillTileComponent);
