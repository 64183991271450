/** Filter out duplicate */
export function filterOutDuplicate() {
    const hashObj = Object.create(null);
    return function filter(id) {
        if (Object.prototype.hasOwnProperty.call(hashObj, id)) {
            return false;
        }
        hashObj[id] = '';
        return true;
    };
}
/** Convert an array to Object */
export function arrayToObj(arr) {
    return JSON.stringify(Object.assign({}, arr));
}
/** Convert a Object with value as array to object */
export function arrayValueToObj(obj) {
    let ans = {};
    Object.keys(obj).forEach((key) => {
        let val = obj[key];
        if (Array.isArray(val)) {
            val = arrayToObj(val);
        }
        ans = {
            ...ans,
            [key]: val,
        };
    });
    return ans;
}

export default {
    filterOutDuplicate,
    arrayValueToObj,
    arrayToObj,
};
