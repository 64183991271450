import { all } from 'redux-saga/effects';
import contentDetailsSagas from '@airtel-feature/content-details/content-details/sagas';
import playbackSagas from '@airtel-feature/playback/sagas';
import SyncSaga from '@airtel-tv/sync/SyncSaga';
import AddToBillSaga from '@airtel-feature/addToBill/saga';
import DistroSaga from '@airtel-feature/layout/sagas/DistroSaga';
import PlansAndSubscriptionSaga from '@airtel-feature/plansAndSubscription/PlansAndSubscriptionSaga';
import authSagas from '../modules/auth/AuthSaga';
import appSagas from '../modules/app/sagas';
import userConfigSagas from '../modules/user/UserSaga';
import railContentSaga from '../modules/rail/sagas';
import artistDetailSaga from '../modules/artist/ArtistSaga';
import layoutSaga from '../modules/layout/sagas/LayoutSaga';
import PackageSaga from '../modules/layout/sagas/PackageSaga';
import SearchSaga from '../modules/search/sagas/SearchSaga';
import LiveTvSaga from '../modules/rail/sagas/ChannelSaga';
import EpgSaga from '../modules/rail/sagas/EpgSaga';
import RailSaga from '../modules/layout/sagas/RailSaga';
import FooterSaga from '../modules/footer/sagas/FooterSaga';
import DimensionPageSaga from '../modules/dimension-pages/saga/DimensionPageSaga';
import GenreListSaga from '../modules/dimension-pages/saga/GenreListSaga';
import ChannelScheduleSaga from '../modules/rail/sagas/ChannelScheduleSaga';
import SubscriptionSaga from '../modules/subscription/SubscriptionSaga';
import ThemeSaga from '../modules/theme/ThemeSaga';
import HappyCodeSaga from '../modules/happycode/sagas/HappyCodeSaga';
import PlanConfigSaga from '../modules/plan/PlanConfigSaga';
import SubscriptionsSaga from '../modules/subscription-details/saga/SubscriptionsSaga';
import PopupSaga from '../modules/preferred-partner/PopupSaga';
import BlogSaga from '../modules/blog/BlogSaga';
import OffersCheckSaga from '../modules/OffersCheck/OffersCheckSaga';
import ChatbotSaga from '../modules/chatbot/ChatbotSaga';
import xppClaimOnThanksSaga from '../../../features/XppOneClickClaim/sagas/xppClaimOnThanksSaga';

export default function* sagas() {
    yield all([
        ...authSagas,
        ...appSagas,
        ...contentDetailsSagas,
        ...playbackSagas,
        ...userConfigSagas,
        ...railContentSaga,
        ...artistDetailSaga,
        ...layoutSaga,
        ...PackageSaga,
        ...SearchSaga,
        ...LiveTvSaga,
        ...EpgSaga,
        ...RailSaga,
        ...SyncSaga,
        ...FooterSaga,
        ...DimensionPageSaga,
        ...GenreListSaga,
        ...ChannelScheduleSaga,
        ...SubscriptionSaga,
        ...ThemeSaga,
        ...HappyCodeSaga,
        ...PlanConfigSaga,
        ...SubscriptionsSaga,
        ...PopupSaga,
        ...BlogSaga,
        ...OffersCheckSaga,
        ...ChatbotSaga,
        ...AddToBillSaga,
        ...xppClaimOnThanksSaga,
        ...DistroSaga,
        ...PlansAndSubscriptionSaga,
    ]);
}
