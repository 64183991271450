import { getTimeStamp } from '@airtel-tv/utils/GlobalUtil';
import { arrayValueToObj } from '@airtel-tv/utils/ArrayListUtil';
import { logFireBaseEvent } from '@airtel-tv/firebase';
import { checkWindowExist } from '@airtel-tv/utils';
import CrashScheduler from './CrashScheduler';
import CrashType from './CrashType';
import CrashModel from './CrashModel';

const crashModel = CrashModel;

export const renderCrash = (meta = {}) => {
    const model = { ...crashModel };
    model.crash_type = CrashType.RENDER_CRASH.val;
    model.ts = getTimeStamp();
    model.meta = arrayValueToObj(meta);
    CrashScheduler.submit(model, CrashType.RENDER_CRASH.isCritical);
    logFireBaseEvent({
        event: CrashType.RENDER_CRASH.val,
        params: model.meta,
    });
};

export const apiCrash = (meta = {}) => {
    const model = { ...crashModel };
    model.crash_type = CrashType.API_CRASH.val;
    model.ts = getTimeStamp();
    model.meta = arrayValueToObj(meta);
    CrashScheduler.submit(model, CrashType.API_CRASH.isCritical);
};

export const playbackError = (meta = {}) => {
    const model = { ...crashModel };
    model.event_type = CrashType.PLAYBACK_ERROR.val;
    model.ts = getTimeStamp();
    model.meta = arrayValueToObj(meta);
    CrashScheduler.submit(model, CrashType.PLAYBACK_ERROR.isCritical);
};

export const licenseCrash = (meta = {}) => {
    const model = { ...crashModel };
    model.event_type = CrashType.LICENSE_ERROR.val;
    model.ts = getTimeStamp();
    model.meta = arrayValueToObj(meta);
    CrashScheduler.submit(model, true);
};

export const licenseRequest = (meta = {}) => {
    const model = { ...crashModel };
    model.event_type = CrashType.LICENSE_REQUEST.val;
    model.ts = getTimeStamp();
    model.meta = arrayValueToObj(meta);
    CrashScheduler.submit(model, true);
};

export default {
    renderCrash,
    licenseCrash,
    apiCrash,
    licenseRequest,
};
