import ArtistAction from './ArtistAction';

// id: '',
// normalizedTitle: '',
// displayTitle: '',
// images: {},
// type: '',
// portraitImage: '',
// description: '',
// imdbId: '',
// gender: '',
// placeOfBirth: '',
// birthday: 0,
// adult: false,
// relatedContents: [],
// genres: [],
// errorCode: null,

const initialState = JSON.parse('{}');

const ArtistReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case ArtistAction.ARTIST_DETAIL_FETCH_SUCCESS:
            changes = {
                ...state,
                ...action.artistData,
            };
            break;

        case ArtistAction.ARTIST_DETAIL_FETCH_ERROR:
            changes = {
                ...initialState,
                ...action.artistData,
            };
            break;

        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default ArtistReducer;
