import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { PLAYER_EVENTS_LIST, CONTROL_BAR_EVENTS, VOLUME_BUTTONS } from '@airtel-tv/constants/PlayerConsts';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';

import PlaybackStorageUtil from '@airtel-feature/playback/PlaybackStorageUtil';
import EventType from '@airtel-tv/analytics/EventType';
import { CDP_EVENT_CONST } from '@airtel-tv/constants/GlobalConst';
import { getSourceName } from '@airtel-tv/utils/GlobalUtil';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';

const ELEMENT_WIDTH = {
    THUMBOR_WIDTH: 16,
    VOLUME_BAR_WIDTH: 100,
};
class VolumeBarControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            volumeLevel: 1,
            showVolumeSlider: false,
        };
        this.deviceUtil = props.deviceUtil;

        this.isMouseDown = false;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            enabled,
            onPlayerFunctionsNotFound,
            adsManager,
            showPreRollAds,
            playingMidRollAds,
        } = nextProps;

        if (
            enabled !== prevState.enabled
            || onPlayerFunctionsNotFound !== prevState.onPlayerFunctionsNotFound
            || showPreRollAds !== prevState.showPreRollAds
            || adsManager !== prevState.adsManager
            || playingMidRollAds !== prevState.playingMidRollAds
        ) {
            return {
                enabled,
                onPlayerFunctionsNotFound,
                adsManager,
                showPreRollAds,
                playingMidRollAds,
            };
        }
        return null;
    }

    componentDidMount() {
        this.initVolumeLevel();
        const { controlBarEventEmitter } = this.props;
        controlBarEventEmitter.on(CONTROL_BAR_EVENTS.UPDATE_VOLUME_BAR, this.setVolumeLevel);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }

    componentWillUnmount() {
        const { controlBarEventEmitter } = this.props;
        controlBarEventEmitter.off(PLAYER_EVENTS_LIST.VOLUME_CHANGE, this.setVolumeLevel);
    }

    toggleSliderVisibility = () => {
        if (this.deviceUtil.isMobile()) {
            return;
        }

        const { showVolumeSlider } = this.state;

        this.setState({
            showVolumeSlider: !showVolumeSlider,
        });
    }

    initVolumeLevel = () => {
        const volumeLevel = PlaybackStorageUtil.getPlayerVolumeLevel();
        const {
            playerFunctions, adsManager,
        } = this.props;
        // TODO add volume level if there is any pre set volume level  
        if (playerFunctions.isMute()) {
            this.setState({
                volumeLevel: 0,
            });
            if (adsManager) {
                adsManager.setVolume(0);
            }
        }
        else {
            this.setState({
                volumeLevel,
            });
        }
    }

    setVolumeLevel = (volumeLevel) => {
        this.setState({
            volumeLevel,
        });
    }

    computeVolume = (event) => {
        if (event) {
            const slide = event.pageX - this.volumeBar.getBoundingClientRect().left;
            const volumeBarToPaint = (ELEMENT_WIDTH.VOLUME_BAR_WIDTH - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
            const volume = slide * ELEMENT_WIDTH.VOLUME_BAR_WIDTH / volumeBarToPaint;
            return Math.min(100, volume);
        }
        return 0;
    }

    updateVolume = (event, toggle = false) => {
        const {
            playerFunctions, enabled, onPlayerFunctionsNotFound, adsManager, showPreRollAds, location
        } = this.props;
        const { volumeLevel } = this.state;
        if (!enabled) {
            return;
        }

        const source =  getSourceName(location.pathname)
        const meta = {
            action: CDP_EVENT_CONST.MUTE_CLICK,
            source_name: source,
        }
        contentDetailsEvent(meta, EventType.MUTE_CLICK);
        if (!playerFunctions) {
            onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        if (toggle && adsManager && showPreRollAds) {
            if (volumeLevel === 0) {
                adsManager.setVolume(1);
            }
            if (volumeLevel !== 0) {
                adsManager.setVolume(0);
            }
        }

        if (toggle) {
            playerFunctions.toggleVolume(this.deviceUtil.isMobile());
        }
        else if (this.isMouseDown) {
            const volume = this.computeVolume(event);
            playerFunctions.volumeChange(volume / 100);
        }

        event.stopPropagation();
    };

    setIsMouseDown = (val, e) => {
        this.isMouseDown = val;

        // for onCLick event
        if (val) {
            this.updateVolume(e);
        }
    }

    getThumborPosition = () => {
        const { volumeLevel } = this.state;
        const volumeBarToPaint = (ELEMENT_WIDTH.VOLUME_BAR_WIDTH - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
        const left = Math.max(0, volumeLevel * volumeBarToPaint - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);


        return {
            left: `${left}px`,
            marginLeft: '0px',
            width: `${ELEMENT_WIDTH.THUMBOR_WIDTH}px`,
        };
    }

    getPositionStyle = () => {
        const { volumeLevel } = this.state;
        const volumeBarToPaint = (ELEMENT_WIDTH.VOLUME_BAR_WIDTH - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
        const width = Math.max(0, volumeLevel * volumeBarToPaint);
        return {
            width: `${width}px`,
        };
    }


    render() {
        const { volumeLevel, showVolumeSlider } = this.state;
        const { showPreRollAds, playingMidRollAds } = this.props;

        const volumeSlider = showVolumeSlider ? (
            <div
                id="volume-controller"
                role="slider"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-valuenow={volumeLevel * 100}
                tabIndex={0}
                className="volume-control-bar-extender"
                onMouseDown={e => this.setIsMouseDown(true, e)}
                onMouseMove={e => this.updateVolume(e, false)}
                onMouseUp={() => this.setIsMouseDown(false)}
            >
                <div
                    className="volume-control-bar"
                    ref={(ref) => {
                        this.volumeBar = ref;
                    }}
                    style={{
                        width: `${ELEMENT_WIDTH.VOLUME_BAR_WIDTH}px`,
                    }}
                >
                    <div
                        className="volume-level"
                        style={this.getPositionStyle()}
                    />
                    <div
                        className="volume-control-bar-thumb"
                        style={this.getThumborPosition()}
                        ref={(ref) => {
                            this.thumborRef = ref;
                        }}
                    />
                </div>
            </div>
        ) : null;

        let volumeSrc = '';
        if (volumeLevel === 0) {
            volumeSrc = VOLUME_BUTTONS.MUTE;
        }
        else if (volumeLevel > 0 && volumeLevel < 0.5) {
            volumeSrc = VOLUME_BUTTONS.LOW;
        }
        else {
            volumeSrc = VOLUME_BUTTONS.HIGH;
        }

        return (
            <div
                className={`d-flex show-volume-bar scale-hover ${showPreRollAds || playingMidRollAds ? 'show-ads-controls' : ''}`}
                onMouseEnter={this.toggleSliderVisibility}
                onMouseLeave={this.toggleSliderVisibility}
            >
                <AnalyticsButtonComponent
                    id="volume-bar"
                    type="button"
                    className="bottom-control-buttons"
                    onClick={e => this.updateVolume(e, true)}
                    aria-label="Volume button"
                >
                    <ThumborImage
                        src={volumeSrc}
                        imageChanged
                    />
                </AnalyticsButtonComponent>
                {volumeSlider}
            </div>
        );
    }
}

VolumeBarControl.propTypes = {
    playerFunctions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    adsManager: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showPreRollAds: PropTypes.bool.isRequired,
    playingMidRollAds: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    onPlayerFunctionsNotFound: PropTypes.func.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    controlBarEventEmitter: PropTypes.shape({
        on: PropTypes.func.isRequired,
        off: PropTypes.func.isRequired,
        emit: PropTypes.func.isRequired,
    }),
};

VolumeBarControl.defaultProps = {
    controlBarEventEmitter: {
        on: () => {},
        off: () => {},
        emit: () => {},
    },
};

export default withRouter(withDeviceUtil(VolumeBarControl));
