export const FORMAT_LENGTH = {
    NUMBER: 'Number',
    SHORT: 's',
    LONG: 'l',
};

export const TIME_FORMAT = {
    MILITARY_HOUR_FORMAT: '24h',
    SIMPLE_HOUR_FORMAT: '12h',
};

export const FORMAT_TYPE = {
    FULL_DATE: 'DD-MMMM-YYYY',
    PROGRAM_DATE: 'DD MMMM YYYY',
    BIRTHDAY: 'MMMM DD, YYYY',
    FULL_DATE_TIME: 'YYYYMMDDHHmmss',
    DATE_TIME_DEFAULT_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    SHORT_DAY: 'dddd',
    SHOW_TIME: 'hh:mm a',
    PLAYER_TIME: 'H:mm:ss',
    CATCHUP_TITLE: 'DD MMMM',
    SHOW_DATE: 'dd DD MM',
    SCHEDULE_DATE: 'd, DD MM',
    DATE_WITH_ORDINAL_FORMAT: 'DDO-MMMM-YYYY',
    YEAR_MONTH_DATE_FORMAT: 'YYYY-MM-DD',
    DATE_FULL_MONTH_YEAR: 'DD Month YYYY',
};

export const MONTH_LONG = {
    JANUARY: 'January',
    February: 'February',
    MARCH: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December',
};

export const MONTH_SHORT = {
    JANUARY: 'Jan',
    February: 'Feb',
    MARCH: 'Mar',
    APRIL: 'Apr',
    MAY: 'May',
    JUNE: 'Jun',
    JULY: 'Jul',
    AUGUST: 'Aug',
    SEPTEMBER: 'Sep',
    OCTOBER: 'Oct',
    NOVEMBER: 'Nov',
    DECEMBER: 'Dec',
};

export const WEEKDAY_LONG = {
    SUNDAY: 'Sunday',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
};

export const WEEKDAY_SHORT = {
    SUNDAY: 'Sun',
    MONDAY: 'Mon',
    TUESDAY: 'Tue',
    WEDNESDAY: 'Wed',
    THURSDAY: 'Thu',
    FRIDAY: 'Fri',
    SATURDAY: 'Sat',
};

export const MS_IN_A_DAY = 86400000;

export default {
    FORMAT_LENGTH,
    MS_IN_A_DAY,
};
