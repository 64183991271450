import {
    call, put, getContext,
} from 'redux-saga/effects';
import { takeFirst } from '@airtel-tv/utils/ReduxSagaUtil';
import { getDistroChannels } from '@airtel-tv/services/DistroApiService';
import { DistroAction, fetchDistroDetailsSuccess } from '../actions/DistroAction';

function* fetchDistroChannels() {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const response = yield call(getDistroChannels, {
            deviceUtil,
            cpId: 'DISTROTV',
            includePlayableOnly: true,
            start: 'now',
            end: 'nowPLUS1d',
        });
        const { data = [] } = response;
        yield put(fetchDistroDetailsSuccess({
            data,
        }));
    }
    catch (e) {
        console.error(e);
    }
}

export default [
    takeFirst(DistroAction.FETCH_DISTRO_DETAILS, fetchDistroChannels),
];
