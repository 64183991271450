
import {RoutingUtil} from '@airtel-tv/utils/RoutingUtil';
import PotentialActionParser from './PotentialActionParser';
import { MARKUP_CONTEXT, MARKUP_TYPES } from '../../../../constants/MarkupConsts';
import { PartofSeasonParser, PartofSeriesParser, actorDirectorParser } from '../../../../utilities/ParserUtil';
import { PROGRAM_TYPES } from '../../../../constants/AppConst';

export default function TvShowEpisodeMarkupParser({
    contentDetails, seasonDetails, baseUrl,
}) {
    const {
        title,
        description,
        images,
        programType,
        id,
        credits,
        languages,
        episodeSeasonNum,
        episodeNum,
        episodeTvShowName,
        tvShowName,
        seriesId,
    } = contentDetails;

    const {
        id: tvSeasonId,
        programType: tvSeasonProgramType,
        title: tvSeasonTitle,
    } = seasonDetails;

    const canonicalUrl = RoutingUtil.getTvEpisodeUrlWeb({
        programType,
        title: episodeTvShowName,
        episodeNumber: episodeNum,
        episodeName: title,
        seasonName: `season-${episodeSeasonNum}`,
        contentId: id,
    });
    const embedUrl = baseUrl + canonicalUrl;

    const tvShowUrlId = baseUrl + RoutingUtil.getContentPlaybackUrlWeb({
        category: PROGRAM_TYPES.TVSHOW,
        contentTitle: tvShowName,
        contentId: seriesId,
    });

    const tvSeasonUrlId = baseUrl + RoutingUtil.getTvSeasonUrlWeb({
        programType: tvSeasonProgramType,
        title: tvShowName,
        seasonName: tvSeasonTitle,
        contentId: tvSeasonId,
    });

    const partOfSeason = PartofSeasonParser(tvSeasonUrlId, episodeSeasonNum);
    const partOfSeries = PartofSeriesParser(tvShowUrlId, episodeTvShowName);

    const potentialAction = PotentialActionParser({
        urlTemplate: embedUrl,
        inLanguage: languages && languages[0],
    });

    const { actorsCatalogue } = actorDirectorParser(credits);
    return {
        '@context': MARKUP_CONTEXT.URL,
        '@type': MARKUP_TYPES.TVEPISODE,
        name: title,
        url: embedUrl,
        episodeNumber: episodeNum,
        partOfSeason,
        partOfSeries,
        description: description || title,
        image: images && Object.values(images),
        ...potentialAction,
        actor: actorsCatalogue,
    };
}
