import { DIMENSION_PROGRAMTYPE_MAP, DIMENSION_PAGE_MAP, DIMENSIONS_SUPPORTED_MAP, DIMENSION_URL_TO_TYPE } from '../../../constants/AppConst';
import { toTitleCase, firstLetterUpper, getLangfromCode } from '../../../utilities/CommonUtil';
import RoutingUtil from '../../../utilities/RoutingUtil';
import lodashGet from 'lodash/get';

export const joinDimensions = ({
    params,
    seperator = '',
}) => {
    const id = params.join(seperator);


    return id;
};

export const formTitle = ({
    pageTitle = '',
    genres = '',
    lang = '',
}) => {
    const params = [
        toTitleCase(lang),
        toTitleCase(genres),
        DIMENSION_PROGRAMTYPE_MAP[pageTitle],
    ];
    return `${joinDimensions({
        seperator: ' ',
        params,
    })}`;
};

export const formQueryParams = ({
    lang = '', genres = '', pageTitle = '',
}) => {
    const queryParams = [];
    if (lang) {
        queryParams.push(`lang=${lang}`);
    }
    if (genres) {
        queryParams.push(`genres=${genres}`);
    }
    if (pageTitle) {
        queryParams.push(`pageTitle=${pageTitle}`);
    }

    return `?${queryParams.join('&').toString()}`;
};

export const getGenreTupple = ({ genres, programType, lang }) => {
    if (programType && genres && Array.isArray(genres) && genres.length > 0) {
        return genres.map(genre => ({
            genre: firstLetterUpper(genre),
            link: RoutingUtil.getDimensionPageUrlV2({
                pageTitle: DIMENSION_PAGE_MAP[programType],
                genres: firstLetterUpper(genre),
                lang: getLangfromCode({ lang }),
                queryParams: formQueryParams({
                    genres: firstLetterUpper(genre),
                    lang,
                    pageTitle: DIMENSIONS_SUPPORTED_MAP[programType],
                }),
            }),
            title: formTitle({
                genres: firstLetterUpper(genre),
                lang: getLangfromCode({ lang }),
                pageTitle: programType,
            }),
        }));
    }
    if (genres) {
        const genre = JSON.stringify(genres.join(', ')).replace(/['"]+/g, '');
        return genre;
    }
    return null;
};

export const transformDimensionUrl = (match, query) => {
    const pageTitleParam = lodashGet(match, 'params.pageTitle');
    const genreOrLangParam = lodashGet(match, 'params.genreOrLang');
    const langParam = lodashGet(match, 'params.lang');
    const genreParam = lodashGet(match, 'params.genre');
    let url;
    if (genreOrLangParam) {
        url = `${genreOrLangParam}-${pageTitleParam}`;
    }
    else if (langParam && genreParam) {
        url = `${langParam}-${genreParam}-${pageTitleParam}`;
    }

    url = `/${pageTitleParam}/${url}`;
    return url;
};

export const dimensionUrlHelper = (appConfig, match, query) => {
    const pageTitleParam = lodashGet(match, 'params.pageTitle');
    const genreOrLangParam = lodashGet(match, 'params.genreOrLang');
    const langParam = lodashGet(match, 'params.lang');
    const genreParam = lodashGet(match, 'params.genre');
    
    const {
            genres: genresFromQuery, lang: langFromQuery
    } = query;
    

    let lang = [];
    let genre = [];
    const pageTitle = [];

    if(pageTitleParam) {
        // eslint-disable-next-line prefer-destructuring
        const title = appConfig.dimensionPages.find(item => item.urlName === pageTitleParam);
        if (title) {
            pageTitle.push(DIMENSION_URL_TO_TYPE[title.sourceName === 'live_tv' ? 'live' : title.sourceName]);
        }
    }

    // HANDLING FOR LANGUAGE FROM URL
    if(genreOrLangParam) {
        // eslint-disable-next-line camelcase
        const { language_map } = appConfig;
        // eslint-disable-next-line camelcase
        if (language_map && language_map.length > 0) {
            const language = language_map.find(item => item.n.toLowerCase() === genreOrLangParam || item.n.toLowerCase() === lang);
            if (language) {
                lang.push(language.lan.toLowerCase());
            }else {
                genre.push(genreOrLangParam);
            }
        }
    }

    if(langParam) {
        // eslint-disable-next-line camelcase
        const { language_map } = appConfig;
        if (language_map && language_map.length > 0) {
            const language = language_map.find(item => item.n.toLowerCase() === langParam || item.n.toLowerCase() === lang);
            if (language) {
                lang.push(language.lan.toLowerCase());
            }
        }
    }

    if(genreParam) {
        let gen = genreParam;
        gen = gen.split('-');
        gen = gen.map(item => `${item.charAt(0).toUpperCase()}${item.slice(1)}`);
        gen = gen.join(' ');
        genre.push(gen);
    }

    if (langFromQuery) {
        lang = langFromQuery.split(',');
    }
    if (genresFromQuery) {
        genre = genresFromQuery.split(',');
    }

    return  {
        lang,
        genres: genre,
        pageTitle
    }
}

export const newDimensionUrlHelper = (appConfig, match, query) => {
    let lang = [];
    let genre = [];
    const pageTitle = [];
    const pageTitleParam = lodashGet(match, 'params.pageTitle');
    const category = lodashGet(match, 'params.category');

    const {
        genres: genresFromQuery, lang: langFromQuery
    } = query;

    if(pageTitleParam) {
        // eslint-disable-next-line prefer-destructuring
        const title = appConfig.dimensionPages.find(item => item.urlName === pageTitleParam);
        if (title) {
            pageTitle.push(DIMENSION_URL_TO_TYPE[title.sourceName === 'live_tv' ? 'live' : title.sourceName]);
        }
    }

    if(category && category !== 'collection') {
        let subscriptPos = category.lastIndexOf(pageTitleParam);
        let rootCategory = category.slice(0,subscriptPos-1) // hifen also not to be included

        let categoryArr = rootCategory.split('-');
        let startParam = categoryArr[0];

        const { language_map } = appConfig;

        if (language_map && language_map.length > 0) {
            const language = language_map.find(item => item.n.toLowerCase() === startParam);
            if (language) {
                lang.push(language.lan.toLowerCase());
            }
        }
        let genreParam = '';
        if(!lang.length) {
            genreParam = rootCategory;
        } else {
            if(categoryArr.length > 1) {
                genreParam = categoryArr.slice(1,categoryArr.length).join('-');
            }
        }

        if(genreParam) {
            let gen = genreParam;
            gen = gen.split('-');
            gen = gen.map(item => `${item.charAt(0).toUpperCase()}${item.slice(1)}`);
            gen = gen.join(' ');
            genre.push(gen);
        }
    }

    if (langFromQuery) {
        lang = langFromQuery.split(',');
    }
    if (genresFromQuery) {
        genre = genresFromQuery.split(',');
    }

    return {
        lang,
        genres: genre,
        pageTitle,
    };
};

export const DIMENSION_REDIRECTION_PATHS = {
    '/movies/multi-movies': '/',
};

export default {
    joinDimensions,
    formTitle,
    formQueryParams,
    dimensionUrlHelper,
    DIMENSION_REDIRECTION_PATHS,
};
