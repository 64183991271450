import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import LanguageProvider from '../../../providers/LanguageProvider';
import styles from '../LiveChannelBox.scss';
import { LIVE_CHANNEL_FILTER_IMAGE_PATHS } from '../../../constants/LiveChannelConst';
import { connect } from 'react-redux';
import { showToastr } from '../../../utilities/NotifyUtil';

function LiveLanguagePopup(props) {
    const {
        hidePopup,
        updateLanguages,
        availableLanguages,
    } = props;
    const [
        languages,
        setLanguages,
    ] = useState(availableLanguages);
    const LANGUAGE = LanguageProvider();

    const selectLanguage = (index, isSelected) => {
        const updatedLanguages = [
            ...languages,
        ];
        updatedLanguages[index].isSelected = !isSelected;
        setLanguages(updatedLanguages);
    };


    return (
        <div className="live_lang_popup">
            <button
                onClick={hidePopup}
                className="cancel"
                type="button"
                id="close-lang-popup"
            >
                {/* <img
                    src={LIVE_CHANNEL_FILTER_IMAGE_PATHS.POPUP_CROSS}
                    alt=""
                /> */}
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.88 9.00002L16.6133 3.28002C16.8644 3.02895 17.0054 2.68843 17.0054 2.33336C17.0054 1.97829 16.8644 1.63776 16.6133 1.38669C16.3622 1.13562 16.0217 0.994568 15.6666 0.994568C15.3116 0.994568 14.9711 1.13562 14.72 1.38669L8.99998 7.12002L3.27998 1.38669C3.02891 1.13562 2.68838 0.994568 2.33331 0.994568C1.97824 0.994568 1.63772 1.13562 1.38665 1.38669C1.13557 1.63776 0.994524 1.97829 0.994524 2.33336C0.994524 2.68843 1.13557 3.02895 1.38665 3.28002L7.11998 9.00002L1.38665 14.72C1.26168 14.844 1.16248 14.9914 1.09479 15.1539C1.0271 15.3164 0.992249 15.4907 0.992249 15.6667C0.992249 15.8427 1.0271 16.017 1.09479 16.1795C1.16248 16.3419 1.26168 16.4894 1.38665 16.6134C1.5106 16.7383 1.65806 16.8375 1.82054 16.9052C1.98302 16.9729 2.1573 17.0078 2.33331 17.0078C2.50933 17.0078 2.6836 16.9729 2.84608 16.9052C3.00856 16.8375 3.15603 16.7383 3.27998 16.6134L8.99998 10.88L14.72 16.6134C14.8439 16.7383 14.9914 16.8375 15.1539 16.9052C15.3164 16.9729 15.4906 17.0078 15.6666 17.0078C15.8427 17.0078 16.0169 16.9729 16.1794 16.9052C16.3419 16.8375 16.4894 16.7383 16.6133 16.6134C16.7383 16.4894 16.8375 16.3419 16.9052 16.1795C16.9729 16.017 17.0077 15.8427 17.0077 15.6667C17.0077 15.4907 16.9729 15.3164 16.9052 15.1539C16.8375 14.9914 16.7383 14.844 16.6133 14.72L10.88 9.00002Z"
                        fill="white"
                    />
                </svg>
            </button>
            <div className="live_lang_popup__heading">
                {LANGUAGE.LANG_POPUP_LABEL}
            </div>
            <div className="live_lang_popup__sub_heading">
                {LANGUAGE.LANG_POPUP_DESCRIPTION}
            </div>
            <div className="language_option_box">
                <div className="language_options">
                    { languages.map((item, index) => (
                        <button
                            className="option"
                            onClick={() => selectLanguage(index, item.isSelected)}
                            type="button"
                            id="select-language"
                            language={item.n}
                            active={`${item.isSelected}`}
                            key={`selectedlang-${index}`}
                        >
                            <div className={`checkbox ${item.isSelected ? 'active' : ''}`}>
                                <img
                                    src={LIVE_CHANNEL_FILTER_IMAGE_PATHS.CHECK_ARROW}
                                    alt=""
                                />
                            </div>
                            <p className="lang_rn">
                                {item.n}
                            </p>
                            <p className="lang_n">
                                {item.rn}
                            </p>
                        </button>
                    ))}
                </div>
            </div>
            <div className="language_done">
                <button
                    type="button"
                    id="language-submit"
                    onClick={() => {
                        const langFilter = languages.filter(language => language.isSelected);
                        if (!langFilter.length) {
                            showToastr(LANGUAGE.LANGUAGE_POPUP_WARNING, '');
                        }
                        else {
                            updateLanguages(languages);
                        }
                    }}
                >
                    {LANGUAGE.LANG_POPUP_SUBMIT}
                </button>
            </div>
        </div>

    );
}

LiveLanguagePopup.propTypes = {
    hidePopup: PropTypes.func,
};
function mapStateToProps(state, props) {
    const {
        userConfig: {
            langInfo,
        },
        appConfig: {
            languageMapped = [],

        },
    } = state;
    const selectedLanguagesArray = props.selectedLanguages.filter(lang => lang.isSelected).map(lang => lang.lan);

    const availableLanguages = Object.values(languageMapped).map(language => ({
        ...language,
        isSelected: selectedLanguagesArray.includes(language.lan),
    }))
        .filter(language => langInfo.includes(language.lan.toLowerCase()));

    return {
        availableLanguages,
    };
}

export default connect(mapStateToProps, {})(withStyles(styles)(LiveLanguagePopup));
