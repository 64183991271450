import AddToBillAction from './actions';


const initialState = {
    accountDetails: null,
    paymentModes: null,
    paymentRechargeResponse: null,
    paymentStatus: null,
    isRechargeInProgress: false,
};


const AddToBillReducer = (state = initialState, action = {}) => {
    let changes = {};
    switch (action.type) {
        case AddToBillAction.GET_PAYMENT_MODES_SUCCESS:
            changes = {
                ...state,
                paymentModes: action.payload,
            };
            break;
        case AddToBillAction.GET_BILL_ACCOUNT_DETAILS_SUCCESS:
            changes = {
                ...state,
                accountDetails: action.payload,
            };
            break;
        case AddToBillAction.BILL_RECHARGE_RESPONSE:
            changes = {
                ...state,
                paymentRechargeResponse: action.payload,
            };
            break;
        case AddToBillAction.SET_BILL_RECHARGE_STATUS_RESPONSE:
            changes = {
                ...state,
                paymentStatus: action.payload,
            };
            break;
        case AddToBillAction.IS_REACHARGE_IN_PROGRESS:
            changes = {
                ...state,
                isRechargeInProgress: action.payload.status,
            };
            break;
        case AddToBillAction.CLEAR_ADD_TO_BILL_DETAILS:
            changes = {
                ...initialState,
            };
            break;
        default:
            return state;
    }
    return {
        ...state,
        ...changes,
    };
};

export default AddToBillReducer;
