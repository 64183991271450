import {
    put, select, call,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import FooterActions, { getContentPageFooterActionCompleted } from '../actions/FooterAction';
import { filterFooterRail, filterPackageContent, filterRailContent } from '../FooterHelper';
import { fetchPackages } from '../../layout/sagas/PackageSaga';
import { fetchRails } from '../../layout/sagas/RailSaga';
import {
    getAppConfigFromReducer, getPackageContentsFromReducer, getFooterDataFromReducer, getRailsFromReducer,
} from '@airtel-tv/redux/StoreListing';

function* fetchRailsForLanguage({ railIds, lang }) {
    const footerData = yield select(getFooterDataFromReducer);
    if (!lodashGet(footerData, lang, '')) {
        yield call(fetchRails, {
            railIds,
        });
    }
}

export function* getFooterForContent({
    lang = null,
}) {
    try {
        if (!lang) {
            return;
        }
        const {
            footer,
        } = yield select(getAppConfigFromReducer);

        // rail Data
        const categoryWithRailIds = filterFooterRail(footer.dynamic, lang);
        if (!categoryWithRailIds) { // No mapping for the current language
            return;
        }
        const railIds = categoryWithRailIds.map(rail => rail[1]);
        yield call(fetchRailsForLanguage, {
            railIds,
            lang,
        });
        const railContents = yield select(getRailsFromReducer);
        const categoryWithPackageIds = filterRailContent(categoryWithRailIds, railContents);

        // packageData
        const packageIds = categoryWithPackageIds.map(pkg => pkg[1]);
        yield call(fetchPackages, {
            packageIds,
            lang,
        });
        const packageContents = yield select(getPackageContentsFromReducer);
        const packages = filterPackageContent(categoryWithPackageIds, packageContents);
        const payload = {
            currentLang: lang[0],
            [lang]: {
                static: footer.static,
                dynamic: {
                    ...footer.dynamic,
                    ...packages,
                },
            },
        };
        yield put(getContentPageFooterActionCompleted({
            payload,
        }));
    }
    catch (e) {
        console.error(e);
    }
}

export default [
    takeFirst(FooterActions.CONTENT_PAGE_FOOTER, getFooterForContent),
];
