import React from 'react';

const FavoritesHeartSVGComponent = ({
    width = "50",
    height = "50",
    heartColor="#E8EAED"
}) => {
    return (
        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7029 2.18481L15.7029 2.18485L15.7053 2.18252C17.0484 0.84928 18.8648 0.102125 20.7573 0.104435C22.6497 0.106745 24.4642 0.858292 25.8041 2.19474C27.1441 3.5355 27.8968 5.35348 27.8968 7.24905C27.8968 9.14461 27.1441 10.9626 25.8041 12.3034L25.8036 12.3039L14.6473 23.6364C14.5636 23.7214 14.4638 23.7889 14.3537 23.835C14.2436 23.8811 14.1254 23.9049 14.0061 23.9049C13.8867 23.9049 13.7686 23.8811 13.6585 23.835C13.5484 23.7889 13.4486 23.7214 13.3648 23.6364L13.3648 23.6364L2.19605 12.3038L2.19544 12.3032C0.852663 10.9642 0.0968063 9.14663 0.0941477 7.25032C0.091489 5.35401 0.842247 3.53431 2.18126 2.19153C3.52028 0.848757 5.33786 0.0929001 7.23418 0.0902414C9.13049 0.0875827 10.9502 0.83834 12.293 2.17736L12.2929 2.1774L12.2954 2.17973L13.9317 3.70348L13.9997 3.76686L14.0679 3.70356L15.7029 2.18481ZM24.5465 11.0198L24.5476 11.0151C25.5394 10.0143 26.0966 8.66234 26.0976 7.25265C26.0986 5.83367 25.5358 4.47243 24.5331 3.46838C23.5304 2.46434 22.1699 1.89973 20.7509 1.89878C19.3319 1.89782 17.9707 2.46059 16.9667 3.46329L16.9666 3.46334L16.9429 3.48704L14.613 5.65458L14.613 5.65459C14.4464 5.80958 14.2273 5.89575 13.9998 5.89575C13.7723 5.89575 13.5533 5.80958 13.3867 5.65459L13.3867 5.65458L11.0568 3.48707L11.033 3.46334C10.0297 2.45997 8.66881 1.89628 7.24983 1.89628C5.83085 1.89628 4.46999 2.45996 3.46662 3.46334C2.46325 4.4667 1.89956 5.82757 1.89956 7.24655C1.89956 8.66542 2.46317 10.0262 3.4664 11.0295C3.46647 11.0296 3.46654 11.0297 3.46662 11.0298L13.9286 21.6442L13.9998 21.7165L14.071 21.6443L24.5273 11.0393L24.5465 11.0198Z" fill="#C3C8D0" stroke="black" stroke-width="0.2"/>
        </svg>
    )
};

export default FavoritesHeartSVGComponent;
