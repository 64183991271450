const PlansOffersAction = {
    PLANS_OFFERS_FETCH: 'PLANS_OFFERS_FETCH',
    PLANS_OFFERS_SUCCESS: 'PLANS_OFFERS_SUCCESS',
    PLANS_OFFERS_ERROR: 'PLANS_OFFERS_ERROR',
    PLANS_PAYMENTS_FETCH: 'PLANS_PAYMENTS_FETCH',
    PLANS_PAYMENTS_SUCCESS: 'PLANS_PAYMENTS_SUCCESS',
    PLANS_PAYMENTS_ERROR: 'PLANS_PAYMENTS_ERROR',
    PLANS_PAYMENTS_DETAILS_SAVE: 'PLANS_PAYMENTS_DETAILS_SAVE',
    ELIGIBLE_PLANS_FETCH: 'ELIGIBLE_PLANS_FETCH',
    ELIGIBLE_PLANS_SUCCESS: 'ELIGIBLE_PLANS_SUCCESS',
    PLANS_OFFERS_CLEAR: 'PLANS_OFFERS_CLEAR',
    PLAN_DETAILS_CLEAR: 'PLAN_DETAILS_CLEAR',
    PLANS_OFFERS_FETCHING: 'PLANS_OFFERS_FETCHING',
    CLEAR_QR_CODE_CONFIG_DATA: 'CLEAR_QR_CODE_CONFIG_DATA',
    QR_CODE_FETCH: 'QR_CODE_FETCH',
    SMS_LINK_FETCH: 'SMS_LINK_FETCH',
    SMS_LINK_SUCCESS: 'SMS_LINK_SUCCESS',
    SMS_LINK_ERROR: 'SMS_LINK_ERROR',
    SMS_LINK_DEFAULT_RESET: 'SMS_LINK_DEFAULT_RESET',
};

export default PlansOffersAction;

export const PlansOffersFetchAction = payload => ({
    type: PlansOffersAction.PLANS_OFFERS_FETCH,
    ...payload,
});

export const PlansOffersSuccessAction = payload => ({
    type: PlansOffersAction.PLANS_OFFERS_SUCCESS,
    payload,
});

export const EligiblePlansSuccessAction = payload => ({
    type: PlansOffersAction.ELIGIBLE_PLANS_SUCCESS,
    payload,
});

export const PlansOffersErrorAction = payload => ({
    type: PlansOffersAction.PLANS_OFFERS_ERROR,
    payload,
});

export const PlanPaymentsDetailsAction = payload => ({
    type: PlansOffersAction.PLANS_PAYMENTS_DETAILS_SAVE,
    payload,
});

export const PlansPaymentsFetchAction = payload => ({
    type: PlansOffersAction.PLANS_PAYMENTS_FETCH,
    ...payload,
});

export const PlansPaymentsUrlSuccessAction = payload => ({
    type: PlansOffersAction.PLANS_PAYMENTS_SUCCESS,
    payload,
});

export const PlansPaymentsErrorAction = payload => ({
    type: PlansOffersAction.PLANS_PAYMENTS_ERROR,
    ...payload,
});

export const EligiblePlansFetchAction = payload => ({
    type: PlansOffersAction.ELIGIBLE_PLANS_FETCH,
    ...payload,
});

export const EligiblePlansClearAction = payload => ({
    type: PlansOffersAction.PLANS_OFFERS_CLEAR,
    ...payload,
});

export const PlanDetailsClearAction = payload => ({
    type: PlansOffersAction.PLANS_OFFERS_CLEAR,
    ...payload,
});

export const PlansOffersFetchingAction = payload => ({
    type: PlansOffersAction.PLANS_OFFERS_FETCHING,
    ...payload,
});

export const PaymentDetailsClearAction = () => ({
    type: PlansOffersAction.CLEAR_QR_CODE_CONFIG_DATA,
});

export const FetchQRCodeAction = payload => ({
    type: PlansOffersAction.QR_CODE_FETCH,
    ...payload,
});

export const FetchSMSLinkAction = payload => ({
    type: PlansOffersAction.SMS_LINK_FETCH,
    ...payload,
});

export const FetchSMSLinkSuccessAction = payload => ({
    type: PlansOffersAction.SMS_LINK_SUCCESS,
    ...payload,
});

export const FetchSMSLinkErrorAction = payload => ({
    type: PlansOffersAction.SMS_LINK_ERROR,
    ...payload,
});

export const ResetSMSLinkAction = payload => ({
    type: PlansOffersAction.SMS_LINK_DEFAULT_RESET,
    ...payload,
});
