import { createBrowserHistory } from 'history';

export class HistoryUtil {
    static history = null;

    static setHistory() {
        this.history = createBrowserHistory();
    }

    static getHistory() {
        if (!this.history) {
            this.setHistory();
        }
        return this.history;
    }
}

export default HistoryUtil;
