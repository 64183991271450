const EventType = Object.freeze({

    // Play Events
    PLAY_START: {
        val: 'play_start',
        isCritical: true,
    },
    PLAY_INIT: {
        val: 'play_init',
        isCritical: true,
    },
    PLAY_PAUSE: {
        val: 'play_pause',
        isCritical: true,
    },
    PLAYER_REWIND: {
        val: 'player_rewind',
        isCritical: true,
    },
    PLAYER_FORWARD: {
        val: 'player_forward',
        isCritical: true,
    },
    PLAYER_SEEK: { // v2
        val: 'player_seek',
        isCritical: true,
    },
    PLAY_STATUS: {
        val: 'play_status',
        isCritical: true,
    },
    FULL_SCREEN: {
        val: 'fullscreen',
        isCritical: true,
    },
    UNDO_FULLSCREEN: {
        val: 'undo_fullscreen',
        isCritical: true,
    },
    PLAY_STOP: {
        val: 'play_stop',
        isCritical: true,
    },
    PLAYER_LOCK_UNLOCK: {
        val: 'player_lock_unlock',
        isCritical: false,
    },
    SKIP_INTRO_EVENT: {
        val: 'skip_intro',
        isCritical: false,
    },
    SKIP_CREDIT_EVENT: {
        val: 'skip_credit',
        isCritical: false,
    },
    SEEK_BAR: {
        val: 'seek_bar',
        isCritical: false,
    },
    LIVE_BUTTON_CLICK: {
        val: 'live_button_click',
        isCritical: false,
    },
    READY_TO_PLAY: {
        val: 'ready_to_play',
        isCritical: true,
    },
    PLAYER_LOG: { // v2
        val: 'player_log',
        isCritical: true,
    },
    PLAY_RESUME: {
        val: 'play_resume',
        isCritical: true,
    },
    PLAY_SESSION_END: { // v2
        val: 'play_session_end',
        isCritical: true,
    },
    FIRST_PLAY: { // v2
        val: 'FIRST_PLAY',
        isCritical: true,
    },
    API_INIT: {
        val: 'api_init',
        isCritical: true,
    },
    API_RESPONSE: {
        val: 'api_response',
        isCritical: true,
    },
    API_LOG: {
        val: 'api_log',
        isCritical: true,
    },
    // Ads Events

    AD_LOAD: {
        val: 'ad_load',
        isCritical: true,
    },
    AD_BREAK_START: {
        val: 'ad_break_started',
        isCritical: true,
    },
    AD_START: {
        val: 'ad_start',
        isCritical: true,
    },
    AD_COMPLETE: {
        val: 'ad_complete',
        isCritical: true,
    },
    AD_BREAK_END: {
        val: 'ad_break_ended',
        isCritical: true,
    },
    AD_CLICK: {
        val: 'ad_click',
        isCritical: true,
    },
    AD_PAUSE: {
        val: 'ad_paused',
        isCritical: true,
    },
    AD_RESUME: {
        val: 'ad_resumed',
        isCritical: true,
    },
    AD_INIT: {
        val: 'AD_INIT',
        isCritical: true,
    },

    AD_STARTED: {
        val: 'AD_STARTED',
        isCritical: true,
    },
    AD_ENDED: {
        val: 'AD_ENDED',
        isCritical: true,
    },
    AD_ERROR: {
        val: 'AD_ERROR',
        isCritical: true,
    },
    AD_CONDITION_SUCCESS: {
        val: 'AD_CONDITION_SUCCESS',
        isCritical: true,
    },
    //* ************* Error Events **************
    ERROR_PLAYBACK: {
        val: 'error_playback',
        isCritical: true,
    },
    PLAYSTART_ERROR: {
        val: 'play_start_error',
        isCritical: true,
    },
    STREAM_URL_ERROR: {
        val: 'stream_url_error',
        isCritical: false,
    },
    HUAWEI_ERROR: {
        val: 'huawei_error',
        isCritical: false,
    },
    RETRY_ERROR_PLAYBACK: {
        val: 'retry_error_playback',
        isCritical: false,
    },
    HUAWEI_SUCCESS: {
        val: 'huawei_success',
        isCritical: false,
    },

    //* ************** Click Events ******************
    CLICK: {
        val: 'click',
        isCritical: true,
    },
    CRITICAL_CLICK: {
        val: 'click',
        isCritical: true,
    },
    AUTOSUGGEST_CLICK: {
        val: 'autosuggest_click',
        isCritical: true,
    },
    LANGUAGE_SELECT_CLICK: {
        val: 'language_select_click',
        isCritical: false,
    },
    NOTIFICATION_CLICK: {
        val: 'notification_click',
        isCritical: false,
    },
    SUBSCRIPTION_CLICK: {
        val: 'subscription_click',
        isCritical: true,
    },
    XMP_CLICK: {
        val: 'xmp_click',
        isCritical: true,
    },

    PAGE_LOAD: {
        val: 'page_load',
        isCritical: true,
    },
    OFFER_SELECTION: {
        val: 'offer_selection',
        isCritical: true,
    },
    SEE_PLANS_XPP: {
        val: 'see_all_plans_xpp',
        isCritical: true,
    },
    SEE_MORE_ALACARTE: {
        val: 'see_more_alacarte',
        isCritical: true,
    },
    POPUP_VISIBLE: {
        val: 'popup_visible',
        isCritical: true,
    },
    SWITCH_TO_AIRTEL: {
        val: 'switch_to_airtel_clicked',
        isCritical: true,
    },
    SUBSCRIPTION_SUCCESS_OK_CLICK: {
        val: 'subscription_success_ok_click',
        isCritical: true,
    },
    SUBSCRIPTION_CANCEL_CLICK: {
        val: 'subscription_cancel_click',
        isCritical: true,
    },
    ATB_OK_CLICK: {
        val: 'add_to_bill_ok_click',
        isCritical: true,
    },
    ATB_CANCEL_CLICK: {
        val: 'add_to_bill_cancel_click',
        isCritical: true,
    },
    ATB_INPROGRESS_CLICK: {
        val: 'add_to_bill_inprogress_click',
        isCritical: true,
    },
    FAILURE_RETRY_CLICK: {
        val: 'failure_retry_click',
        isCritical: true,
    },
    PLAN_PURCHASE_REQUEST: {
        val: 'plan_purchase_request',
        isCritical: true,
    },
    PLAN_PURCHASE_REQUEST_UTKN: {
        val: 'plan_purchase_utkn',
        isCritical: true,
    },
    PLAN_SELECTION: {
        val: 'select_plan',
        isCritical: true,
    },
    CONTINUE_TO_PAY: {
        val: 'continue_to_pay',
        isCritical: true,
    },
    SEE_OTHER_PLANS_CLICK: {
        val: 'see_other_plans_clicked',
        isCritical: true,
    },
    SEE_PLANS_CLICK: {
        val: 'see_plans_alacarte_clicked',
        isCritical: true,
    },
    SEE_PLANS_ALACARTE_CLICK: {
        val: 'see_alacarte_plans',
        isCritical: true,
    },
    CLAIM_NOW_CLICK: {
        val: 'XMP_claim_now_clicked',
        isCritical: true,
    },
    CHANGED_CP_CLICKED: {
        val: 'changed_cp_clicked',
        isCritical: true,
    },
    WATCH_FOR_FREE_CLICK: {
        val: 'watch_for_free_clicked',
        isCritical: true,
    },
    EXPLORE_OTHER_CHANNELS_CLICK: {
        val: 'explore_other_channels_clicked',
        isCritical: true,
    },
    UNLOCK_NOW_CLICK: {
        val: 'unlock_now_clicked',
        isCritical: true,
    },
    OK_GOT_IT_CLICK: {
        val: 'ok_got_it_clicked',
        isCritical: true,
    },
    OK_CANCEL_XMP_CLICK: {
        val: 'ok_cancel_xmp_clicked',
        isCritical: true,
    },
    ON_CANCEL_CLICK_XMP_PAGE: {
        val: 'on_cancel_click_xmp_page',
        isCritical: true,
    },
    DO_LATER_CLICK: {
        val: 'I_will_do_later_clicked',
        isCritical: true,
    },
    SUBSCRIBE_MORE_CLICK: {
        val: 'subscribe_more_channels_clicked',
        isCritical: true,
    },
    RECHARGE_NOW_CLICK: {
        val: 'recharge_clicked',
        isCritical: true,
    },
    RECHARGE_XMP_CLICK: {
        val: 'recharge_xmp',
        isCritical: true,
    },
    //* ******* Screen Visibility Events ************
    SCREEN_VISIBLE: {
        val: 'screen_visible',
        isCritical: true,
    },
    NOTIFICATION_VISIBLE: {
        val: 'notification_visible',
        isCritical: false,
    },
    OVERLAY_VISIBLE: {
        val: 'overlay_visible',
        isCritical: false,
    },
    LANGUAGE_SCREEN_VISIBLE: {
        val: 'language_screen_visible',
        isCritical: false,
    },

    BANNER_VISIBLE: {
        val: 'BANNER_VISIBLE',
        isCritical: false,
    },

    //* ************** Auth Events ****************
    PARTIAL_REGISTER: {
        val: 'reg_partial',
        isCritical: true,
    },
    COMPLETE_REGISTER: {
        val: 'reg_complete',
        isCritical: true,
    },
    REG_FAILED: {
        val: 'reg_failed',
        isCritical: true,
    },
    REG_ABANDONED: {
        val: 'reg_abandoned',
        isCritical: true,
    },
    REG_OTP_RESPONSE: {
        val: 'reg_otp_response',
        isCritical: true,
    },
    SELECT_RENEW: {
        val: 'select_renew',
        isCritical: true,
    },
    REDIRECT_TO_PAYMENT: {
        val: 'redirect_to_payment',
        isCritical: true,
    },
    CONTINUE_TO_PAY_ERROR: {
        val: 'continue_to_play_error',
        isCritical: true,
    },
    //* *************** App Events ******************
    APP_START: {
        val: 'app_start',
        isCritical: true,
    },
    APP_INIT: {
        val: 'app_init',
        isCritical: true,
    },
    APP_STATUS: {
        val: 'app_status',
        isCritical: true,
    },
    APP_INIT_COMPLETE: {
        val: 'app_init_complete',
        isCritical: false,
    },
    ITEM_SEARCH: {
        val: 'item_search',
        isCritical: true,
    },
    DISCOVERY: {
        val: 'discovery',
        isCritical: false,
    },
    DISCOVERY_RESULT: {
        val: 'discovery_result',
        isCritical: false,
    },
    DISCOVERY_RESULT_CONSUMED: {
        val: 'discovery_result_consumed',
        isCritical: false,
    },
    LIVE_GO_LIVE: {
        val: 'live_golive',
        isCritical: false,
    },
    SEARCH_RESULT_CONSUMED: {
        val: 'search_result_consumed',
        isCritical: false,
    },
    SUB_UNSUB: {
        val: 'sub_unsub',
        isCritical: false,
    },
    API_LATENCY: {
        val: 'api_latency',
        isCritical: false,
    },

    STRETCH: {
        val: 'stretch_extend',
        isCritical: false,
    },
    BACK: {
        val: 'back',
        isCritical: false,
    },
    NOTIFICATION_CLOSE: {
        val: 'notification_close',
        isCritical: false,
    },
    INTERACT_LANGUAGE_BANNER: {
        val: 'interact_language_banner',
        isCritical: false,
    },

    //* ************** Ads Events *****************
    AD_IMPRESSION_RECORDED: {
        val: 'ad_impression_recorded',
        isCritical: false,
    },
    AD_PLAY_INIT: {
        val: 'ad_play_init',
        isCritical: false,
    },
    AD_PLAY_STATUS: {
        val: 'ad_play_status',
        isCritical: false,
    },
    AD_ITEM_REMOVED: {
        val: 'ad_item_removed',
        isCritical: false,
    },
    AD_PLAY_STOP: {
        val: 'ad_play_stop',
        isCritical: false,
    },
    AD_SKIP: {
        val: 'ad_skip',
        isCritical: false,
    },
    AD_PIP_OPEN: {
        val: 'ad_pip_open',
        isCritical: false,
    },
    AD_PLAY_START: {
        val: 'ad_play_start',
        isCritical: false,
    },
    AD_PIP_CLOSED: {
        val: 'ad_pip_closed',
        isCritical: false,
    },
    DFP_PREROLL_MISSED: {
        val: 'dfp_preroll_missed',
        isCritical: false,
    },
    AD_VIDEO_IMPRESSION_RECORDED: {
        val: 'ad_video_impression_recorded',
        isCritical: false,
    },
    AD_PROGRESS: {
        val: 'AD_PROGRESS',
        isCritical: true,
    },

    //* *********** MoEngage Notification Event *************
    ME_NOTIFICATION_VISIBLE: {
        val: 'me_notification_visible',
        isCritical: false,
    },
    ME_NOTIFICATION_DISMISSED: {
        val: 'me_notification_dismissed',
        isCritical: false,
    },
    ME_NOTIFICATION_CLICKED: {
        val: 'me_notification_clicked',
        isCritical: false,
    },
    ADD_TO_WATCHLIST: {
        val: 'add_to_watchlist',
        isCritical: false,
    },
    REMOVE_FROM_WATCHLIST: {
        val: 'remove_from_watchlist',
        isCritical: false,
    },
    GO_TO_WATCHLIST: {
        val: 'me_notification_visible',
        isCritical: false,
    },
    NEW_INSTALL: {
        val: 'new_install',
        isCritical: false,
    },
    STREAMING_PLAYBACK_API_LATENCY: {
        val: 'streaming_playback_api_latency',
        isCritical: false,
    },

    //* ****************** NDTV Events **********************
    NDTV_HOP_BACK: {
        val: 'ndtvhop_back',
        isCritical: false,
    },
    CART_ADD_CLICK: {
        val: 'cart_add_click',
        isCritical: false,
    },
    POPUP_DISAPPEAR: {
        val: 'popup_disappear',
        isCritical: false,
    },
    BUY_CLICK: {
        val: 'buy_click',
        isCritical: false,
    },
    REMOVE_CLICK: {
        val: 'remove_click',
        isCritical: false,
    },
    CONTENT_VISIBLE: {
        val: 'content_visible',
        isCritical: true,
    },
    NUDGE_VISIBLE: {
        val: 'nudge_visible',
        isCritical: true,
    },
    POLL_CLICK: {
        val: 'poll_click',
        isCritical: false,
    },

    //* ****************** RAIL EVENTS **************************/

    RAIL_VISIBLE_EVENT: {
        val: 'rail_visible',
        isCritical: false,
    },
    RAIL_SCROLL_EVENT: {
        val: 'rail_scroll',
        isCritical: false,
    },

    TILE_SCROLL_EVENT: {
        val: 'tile_scroll',
        isCritical: false,
    },

    //* ******************* PIP EVENTS **************************/

    PIP_OPEN: {
        val: 'pip_in',
        isCritical: true,
    },
    PIP_CLOSE: {
        val: 'pip_out',
        isCritical: true,
    },
    OUT_WEB: {
        val: 'out_web',
        isCritical: true,
    },
    IN_WEB: {
        val: 'in_web',
        isCritical: true,
    },

    //* ******************** HOVER EVETNS ***************/
    MEGA_MENU_HOVER: {
        val: 'mega_menu_hover',
        isCritical: false,
    },

    //* ******************** HAPPYCODE EVENTS ***************/
    HAPPYCODE_VERIFICATION_STATUS: {
        val: 'happycode_verification_status',
        isCritical: true,
    },

    //* ******************** ACCOUNT DELETION EVENTS ***************/
    SCREEN_OPENED: {
        val: 'Screen_Opened',
        isCritical: false,
    },
    SCREEN_CLOSED: {
        val: 'Screen_Closed',
        isCritical: false,
    },
    DUMMY_EVENT: {
        val: 'dummy_event',
        isCritical: true,
    },
    ACTIVATE_EVENT: {
        val: 'click_activate',
        isCritical: true,
    },
    ACTIVATE_SUCCESS_EVENT: {
        val: 'activate_success',
        isCritical: true,
    },
    ACTIVATE_FAILURE_EVENT: {
        val: 'activate_failure',
        isCritical: true,
    },
    //* ******************** CONTENT DETAIL PAGE EVENTS ***************/
    WATCH_CLICK: {
        val: 'watch_click',
        isCritical: false,
    },
    TRAILER_CLICK: {
        val: 'trailer_click',
        isCritical: false,
    },
    TRAILER_VIEW: {
        val: 'trailer_view',
        isCritical: true,
    },
    WATCHLIST_CLICK: {
        val: 'click',
        isCritical: false,
    },
    MUTE_CLICK: {
        val: 'click',
        isCritical: false,
    },
    SHARE_CLICK: {
        val: 'click',
        isCritical: false,
    },
    EPISODE_TAB_CLICK: {
        val: 'click',
        isCritical: false,
    },
    SEASON_CLICK: {
        val: 'click',
        isCritical: false,
    },
    EPISODE_CLICK: {
        val: 'click',
        isCritical: false,
    },
    TAB_CLICK: {
        val: 'click',
        isCritical: false,
    },
    RAIL_VISIBLE: {
        val: 'content_visible',
        isCritical: false,
    },
    TILE_CLICK: {
        val: 'click',
        isCritical: false,
    },
    PLAY_CLICK: {
        val: 'click',
        isCritical: false,
    },
    PAUSE_CLICK: {
        val: 'click',
        isCritical: false,
    },
    TRAILER_END: {
        val: 'trailer_end',
        isCritical: true,
    },
    TRAILER_MUTE: {
        val: 'click',
        isCritical: false,
    },
    TRAILER_UNMUTE: {
        val: 'click',
        isCritical: false,
    },
    TRAILER_RELOAD: {
        val: 'click',
        isCritical: false,
    },
    NEXT_EP_CLICK: {
        val: 'click',
        isCritical: false,
    },
    SUBTITLE_CLICK: {
        val: 'subtitle_click',
        isCritical: false,
    },
    STARNAME_CLICK: {
        val: 'click',
        isCritical: false,
    },
    //* ******************** PLAN CHANGE EVENT ***************/
    PLAN_CHANGE_VALUE: {
        val: 'plan_change',
        isCritical: false,
    },
    APP_INSTALL: {
        val: 'install',
        isCritical: true,
    },
    APP_IN_APP_REDIRECTION: {
        val: 'app_in_app_redirection',
        isCritical: true,
    },
    APP_ERROR_EVENT: {
        val: 'APP_ERROR_EVENT',
        isCritical: true,
    },
    SUNNXT_PLAYER_LOGS: {
        val: 'sunnxt_player',
        isCritical: false,
    },
    LOGIN_COMPLETE: {
        val: 'login_complete',
        isCritical: true,
    },
    SSO_FAILED:
    {
        val: 'sso_failed',
        isCritical: true,
    },
    BRANCH_API_FAILED:
    {
        val: 'branch_api_failed',
        isCritical: true,
    },
});

export default EventType;
