import RoutingUtil from '../../../../utilities/RoutingUtil';
import PotentialActionParser from './PotentialActionParser';
import { secondToHours, secondToMinutes } from '../../../../utilities/CommonUtil';
import { MARKUP_CONTEXT, MARKUP_TYPES } from '../../../../constants/MarkupConsts';
import { actorDirectorParser } from '../../../../utilities/ParserUtil';

export default function ViewActionParser() {
    return {
        '@context': MARKUP_CONTEXT.URL,
        '@type': MARKUP_TYPES.VIEW_ACTION,
        expectsAcceptanceOf: {
            '@type': 'Offer',
            eligibleRegion: {
                '@type': 'Country',
                name: 'IN',
            },
        },
        target: [
            {
                '@type': 'EntryPoint',
                urlTemplate: 'https://www.airtelxstream.in/',
                actionPlatform: [
                    'http://schema.org/IOSPlatform',
                ],
            },
            {
                '@type': 'EntryPoint',
                urlTemplate: 'android-app://tv.accedo.airtel.wynk/https://www.airtelxstream.in/',
                actionPlatform: [
                    'http://schema.org/AndroidPlatform',
                ],
            },
            {
                '@type': 'EntryPoint',
                urlTemplate: 'android-app://tv.accedo.airtel.wynk/https://www.airtelxstream.in/',
                actionPlatform: [
                    'http://schema.org/MobileWebPlatform',
                ],
            },
        ],
    };
}
