import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { watchListContentRemove, watchListContentAdd } from '../../../features/sync/SyncActions';
import { triggerLogin } from '../../../web/src/modules/auth/AuthAction';
import { addToWatchlistClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ANALYTICS_ACTIONS, LOGIN_SOURCE } from '@airtel-tv/constants';

function withWatchlistDataHOC(WrappedTile) {
    class WatchListWrapper extends PureComponent {
        constructor(props) {
            super(props);
            this.state = {};
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            const {
                inWatchList,
                isAuthenticated,
            } = nextProps;
            if (!prevState || prevState.inWatchList !== inWatchList || prevState.isAuthenticated !== isAuthenticated) {
                return {
                    inWatchList,
                    isAuthenticated,
                };
            }
            return null;
        }

        watchListToggleButtonClick = (contentDetails, addToWatchlistAnalyticsMeta) => {
            const {
                watchListContentAddDispatch, triggerLoginDispatch, watchListContentRemoveDispatch, isAuthenticated, inWatchList, watchList
            } = this.props;

            if (inWatchList || watchList[contentDetails.id]) {
                watchListContentRemoveDispatch({ contentDetails });
            }
            else {
                if (!isAuthenticated) {
                    triggerLoginDispatch({
                        loginTriggered: true,
                        sourceOfLoginTrigger: LOGIN_SOURCE.WATCHLIST_BUTTON,
                    });
                    return;
                }
                watchListContentAddDispatch({ contentDetails });
                addToWatchlistClickEvent(addToWatchlistAnalyticsMeta);
            }
        }

        pywWatchListToggleButtonClick = (contentDetails, addToWatchlistAnalyticsMeta) => {
            const {
                watchListContentRemoveDispatch, triggerLoginDispatch, watchListContentAddDispatch, isAuthenticated, watchList,
            } = this.props;
            if (watchList[contentDetails.id]) {
                watchListContentRemoveDispatch({ contentDetails, watchlistMeta: {isFromPYW: true} });
                addToWatchlistAnalyticsMeta = {
                    ...addToWatchlistAnalyticsMeta,
                    action: ANALYTICS_ACTIONS.REMOVE_FROM_WATCHLIST,
                };
            }
            else {
                if (!isAuthenticated) {
                    triggerLoginDispatch({
                        loginTriggered: true,
                        sourceOfLoginTrigger: LOGIN_SOURCE.WATCHLIST_BUTTON,
                    });
                    return;
                }
                watchListContentAddDispatch({ contentDetails, watchlistMeta: { isFromPYW: true } });
                addToWatchlistAnalyticsMeta = {
                    ...addToWatchlistAnalyticsMeta,
                    action: ANALYTICS_ACTIONS.ADD_TO_WATCHLIST_CLICK,
                };
            }
            addToWatchlistClickEvent(addToWatchlistAnalyticsMeta);
        };

        render() {
            return (
                <WrappedTile
                    {...this.props}
                    watchListToggleButtonClick={this.watchListToggleButtonClick}
                    pywWatchListToggleButtonClick={this.pywWatchListToggleButtonClick}
                />
            );
        }
    }
    function mapStateToProps(state, props) {
        const {
            syncContent: {
                watchList = {},
            } = {},
            authConfig,
        } = state;
        const { id } = props;
        let inWatchList = false;
        if (watchList[id]) {
            inWatchList = true;
        }
        // if (pywWatchList[id]) {
        //     inPywWatchList = true;
        // }
        return {
            watchList,
            inWatchList,
            isAuthenticated: authConfig.isAuthenticated,
        };
    }

    WatchListWrapper.propTypes = {
        inWatchList: PropTypes.bool.isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
        watchListContentRemoveDispatch: PropTypes.func.isRequired,
        watchListContentAddDispatch: PropTypes.func.isRequired,
        triggerLoginDispatch: PropTypes.func.isRequired,
    };
    return connect(mapStateToProps, {
        watchListContentRemoveDispatch: watchListContentRemove,
        watchListContentAddDispatch: watchListContentAdd,
        triggerLoginDispatch: triggerLogin,
        // pywWatchlistContentAddDispatch: pywWatchlistContentAddAction,
        // pywWatchlistContentRemoveDispatch: pywWatchlistContentRemoveAction,
    })(WatchListWrapper);
}

export { withWatchlistDataHOC };
