import React from 'react';
import { LanguageProviderUtil } from '@airtel-tv/utils';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '@airtel-tv/common-scss/_cssloader.scss';
import BigBoxShimmer from './BigBoxShimmer';

const LoaderComponentInstance = (props) => {
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const { pageType, defaultShimmer = false, shimmerHeight } = props;

    if (defaultShimmer) {
        return (
            <div
                className="loader-shimmer subscriction"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    background: 'transparent',
                }}
            >
                <div
                    className="shimmer-skeleton"
                    style={{
                        width: '100VW',
                        background: '#0c0f12',
                    }}
                >
                    <div
                        className=" smallbox shimmer"
                        style={{
                            width: '100%',
                            height: shimmerHeight || '50px',
                        }}
                    />
                </div>
            </div>
        );
    }

    if (pageType === 'cdp') {
        return (
            <div className="loader-shimmer shimmer-height">
                <div
                    className="player-shimmer"
                    style={{
                        padding: 0,
                    }}
                >
                    <div
                        className="shimmer-left cdp-shimmer cdp-shimmer-bg"
                        style={{
                            position: 'relative',

                        }}
                    >
                        {/* <img
                            src="/static/bigbox-shimmer.png"
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                            }}
                        /> */}
                        <BigBoxShimmer
                            customStyle={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                            }}
                        />
                        <div className="inner-section-shimmer">
                            <span className="long-section short-section" />
                            <span className=" long-section medium-section" />
                            <span className=" long-section short-height" />
                            <span className=" long-section shimmer-btn round-shape-remove" />
                            <span className=" long-section shimmer-btn-short round-shape-remove" />
                            <div className="button-container-shimmer">
                                <span className="round-shape" />
                                {/* <span className="round-shape round-shape-remove"></span> */}
                            </div>
                        </div>
                    </div>

                </div>
                <div className=" smallbox shimmer" />
                <div className=" d-flex medium-container">
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                </div>

            </div>
        );
    }

    if (pageType === 'live-tv') {
        return (
            <div className="loader-shimmer shimmer-height">
                <div className="player-shimmer">
                    <div className="shimmer-left col-xl-8 shimmer" />
                    <div className="shimmer-right col-xl-4 shimmer">
                        <div className="place-holders place-holders-medium" />
                        <div className="place-holders place-holders-long" />
                        <div className="place-holders place-holders-long" />
                        <div className="place-holders place-holders-small" />
                        <div className="place-holders place-holders-small" />
                        <div className="shifter">
                            <div className="place-holders place-holders-small" />
                            <div className="place-holders place-holders-small" />
                        </div>

                    </div>
                </div>
                <div className=" smallbox shimmer" />
                <div className=" d-flex medium-container">
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                </div>

            </div>
        );
    }

    if (pageType === 'dimensionsPage') {
        return (
            <div className="dimensions-shimmer">
                <div className="dimensions-shimmer-top-bar">
                    <div className="inner-section">
                        <span className="dimensions-header shimmer-bg" />
                        <div className="dimensions-button-container">
                            <span className="dimensions-button shimmer-bg" />
                            <span className="dimensions-button shimmer-bg" />
                            <span className="dimensions-button shimmer-bg" />
                            <span className="dimensions-button shimmer-bg" />
                        </div>
                    </div>
                </div>
                <div className="m-dimension-button-container">
                    <span
                        className="dimensions-button shimmer-bg"
                        style={{ marginLeft: '15px' }}
                    />
                    <span className="dimensions-button shimmer-bg" />
                    <span
                        className="dimensions-button shimmer-bg"
                        style={{ marginRight: '15px' }}
                    />
                </div>
                <div className="dimensions-shimmer-content-box" />
            </div>
        );
    }
    if (pageType === 'listing') {
        return (
            <div className="dimensions-shimmer">
                <div className="dimensions-shimmer-top-bar">
                    <div className="inner-section">
                        <span className="dimensions-header shimmer-bg" />
                    </div>
                </div>
                <div className="m-dimension-button-container">
                    <span
                        className="dimensions-button shimmer-bg"
                        style={{ marginLeft: '15px' }}
                    />
                    <span className="dimensions-button shimmer-bg" />
                    <span
                        className="dimensions-button shimmer-bg"
                        style={{ marginRight: '15px' }}
                    />
                </div>
                <div className="dimensions-shimmer-content-box" />
            </div>
        );
    }

    if (pageType === 'searchPage') {
        return (
            <div className="search-shimmer">
                <div className="searchpage-bar" />
                <div className="searchpage-recent-search" />
                <div className="search-results" />
                <div className="content-wrapper">
                    <span className="content-header" />
                    <div className="content" />
                </div>
                <div className="content-wrapper content-wrapper-margin">
                    <span className="content-header" />
                    <div className="content" />
                </div>
            </div>
        );
    }
    if (pageType === 'artistPage') {
        return (
            <div className="artist-shimmer d-flex justify-content-between">
                <div className="artist-details">
                    <div className="artist-header" />
                    <div className="artist-desc">
                        {/* <img src="/static/bigbox-shimmer.png" /> */}
                    </div>
                </div>
                <div className="artist-image">
                    {/* <img src="/static/bigbox-shimmer.png" /> */}
                    {/* <BigBoxShimmer /> */}
                </div>
            </div>
        );
    }
    if (pageType === 'onehubWeb' ) {
        // un comment above code and move below code to relevant folder or file

        //onehub shimmer
        return (
            <div className='onehub-shimmer'>
                <div className='shimmer-title'></div>
                <div className='shimmer-body'>
                    <div className='body-line-slim'></div>
                    <div className='body-line-fat'></div>
                    <div className='body-line-extra-fat'></div>
                </div>
            </div>
        )
    }
    if (pageType === 'onehubLS') {
        return (
            <div className="big-onehub-shimmer">
                <div className="head-container d-flex">
                    <div className="head" />
                    <div className="head" />
                </div>
                <div className="head-title" />
                <div className="head-body-wrap d-flex">
                    <div className="head-body" />
                    <div className="head-body small" />

                </div>
            </div>
        );
    }

    if (pageType === 'onehubMobile') {
        return (
            <div className='onehub-shimmer'>
                <div className='onehub-shimmer-title'>
                    <div className='shimmer-title'></div>
                    <div className='shimmer-body'>
                        <div className='body-line-slim'></div>
                    </div>
                </div>
                <div className='shimmer-title'></div>
                <div className='shimmer-body'>
                    <div className='body-line-slim'></div>
                    <div className='body-line-fat'></div>
                    <div className='body-line-extra-fat'></div>
                </div>
            </div>
        );
    }

    // qr page shimmer
    if (pageType === 'qrPage') {
        return (
            <div className='qr-page-shimmer'>
                <div className='qr-head'></div>
                <div className='qr-body'></div>
                <div className='qr-foot'></div>
            </div>
        );
    }
    return (
        <div className="atl-loader atl-loader--in-component">
            <div className="atl-loader-wrapper">
                <div className="atl-loader__elements">
                    <div className="atl-loader__ring">
                        <div className="lds-ring">
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                    <div className="atl-loader__text">
                        {LANGUAGE.LOADING}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LoaderComponent = withStyles(styles)(LoaderComponentInstance);
