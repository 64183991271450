import { LOCATION_ACTIONS } from './LocationActions';

const initialState = JSON.parse(`{
    "navigationObj": {
        "prevSource": "",
        "prevPath": "",
        "currentSource": "",
        "currentPath": ""
    }
}`);


const LocationReducer = (state = initialState, action = {}) => {
    const { payload = {} } = action;
    switch (action.type) {
        case LOCATION_ACTIONS.UPDATE_LOCATION_INFO: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};

export default LocationReducer;
