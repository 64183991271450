import React, { useEffect, useState } from 'react';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { AuthenticationUtil, LanguageProviderUtil } from '@airtel-tv/utils';
import { ELEMENT_ID, LOCAL_STORE_KEYS } from '@airtel-tv/constants';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import { useNavigate } from 'react-router';
import { getSourceNameFromStorage, getTimeStamp } from '@airtel-tv/utils/GlobalUtil';

const ChannelGuideFavouritesComponent = ({
    ChannelIcon,
    FavouritesIcon = <></>,
    contentDetails = {},
    width = "",
    height = "",
    handleChangeEpgPageVisibility = () => {},
    FavouritesIconSelected = '',
    playerFunctions,
    epg = {},
    isChannelGuideVisible = true,
}) => {
    const [isContentAlreadyInFav, setisContentAlreadyInFav] = useState(false);
    useEffect(() => {
        setisContentAlreadyInFav(favouriteChannels.includes(contentDetails?.id));
    }, []);
    const { playableTitle = '', playableId = '', cpId = '' } = contentDetails;
    const [heartColor, handleHeartColor] = useState('#E8EAED');
    const uid = AuthenticationUtil?.getUid();
    const favouriteChannelsObj = browserStore.get(LOCAL_STORE_KEYS.FAVOURITE_CHANNELS) || {};
    const favouriteChannels = favouriteChannelsObj?.[uid] || [];
    const LANGUAGE = LanguageProviderUtil?.getLanguage() || {};
    const { CHANNEL_GUIDE_BUTTON = 'Channel Guide' } = LANGUAGE;
   
    const navigate = useNavigate();
    const currentProgramDetails = epg?.programGuide?.[playableId];
    const currentTime = getTimeStamp();
    const liveChannelDetails = currentProgramDetails.find(ele => currentTime >= ele.startTime && currentTime < ele.endTime);
    const { prevSource } = getSourceNameFromStorage() || {};
    return (
        <section className="d-flex align-items-center channel-guied-action">
            {isChannelGuideVisible ? <AnalyticsButtonComponent
                id={ELEMENT_ID.CHANNEL_GUIDE}
                className={`channel-guide-btn bg-transparent border-none d-flex align-items-center channel-guide-container nostyling ${FocusManagementClassNames.FOCUSABLE} ${FocusManagementClassNames.CAN_FOCUS}`}
                data-sn-right={`#${ELEMENT_ID.FAVOURITES}`}
                meta={{
                    action: 'channel_guide',
                    content_name: liveChannelDetails?.title || playableTitle || '',
                    source_name: prevSource,
                    content_id: liveChannelDetails?.id || playableId || '',
                    cp_name: cpId,
                    channel_id: playableId,
                    channel_name: playableTitle,
                    program_start_time: liveChannelDetails?.startTime || 0,
                    program_end_time: liveChannelDetails?.endTime || 0,
                    asset_name: CHANNEL_GUIDE_BUTTON,
                    source_page: prevSource,
                }}
                onClick={() => {
                    playerFunctions?.pause();
                    navigate({
                        hash: '#isEpgPageVisible'
                    }, {
                        replace: true,
                    });
                    // location.hash = 'isEpgPageVisible'
                }}
            >
                <ChannelIcon />
                <span className=" channel-guide-text text-16 line-height-24 d-none-ls">{CHANNEL_GUIDE_BUTTON}</span>
            </AnalyticsButtonComponent> : ''}
            <AnalyticsButtonComponent
                id={ELEMENT_ID.FAVOURITES}
                className={`channel-guide-btn bg-transparent border-none d-flex align-items-center favorites-btn nostyling ${FocusManagementClassNames.FOCUSABLE} ${FocusManagementClassNames.CAN_FOCUS}`}
                data-sn-left={`#${ELEMENT_ID.CHANNEL_GUIDE}`}
                meta={{
                    action: isContentAlreadyInFav ? 'remove-from-favourite' : 'add-to-favourite',
                    content_name: liveChannelDetails?.title || playableTitle || '',
                    source_name: 'distro_cdp',
                    content_id: liveChannelDetails?.id || playableId || '',
                    cp_name: cpId,
                    channel_id: playableId,
                    channel_name: playableTitle,
                    program_start_time: liveChannelDetails?.startTime || 0,
                    program_end_time: liveChannelDetails?.endTime || 0,
                    asset_name: 'favourites',
                }}
                onClick={() => {
                    if (!isContentAlreadyInFav) {
                        favouriteChannels.push(contentDetails?.id);
                        browserStore.set(LOCAL_STORE_KEYS.FAVOURITE_CHANNELS, {
                            ...favouriteChannelsObj,
                            [uid]: favouriteChannels,
                        });
                    }
                    else {
                        const excludedIds = favouriteChannels.filter(ele => ele !== contentDetails?.id);
                        browserStore.set(LOCAL_STORE_KEYS.FAVOURITE_CHANNELS, {
                            ...favouriteChannelsObj,
                            [uid]: excludedIds,
                        });
                    }
                    setisContentAlreadyInFav(prev => !prev)
                }}
            >
                {/* <FavoritesHeartSVGComponent width={30} height={30} /> */}
                {isContentAlreadyInFav ? <FavouritesIconSelected /> : <FavouritesIcon width={width} height={height} heartColor={heartColor} />}
            </AnalyticsButtonComponent>
        </section>
    )
}

export default (ChannelGuideFavouritesComponent);