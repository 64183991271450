import React, { useEffect, useRef } from 'react';
import {
    redirectToInNewTab, openUrlInSameTab,
    checkLGAppInstalled,
    isOnline,
    handleOpenApp,
} from '@airtel-tv/utils/WindowUtil';
import { appInAppRedirectionEvent } from '@airtel-tv/analytics/FunctionalEvents';
import {
    EVENT_SOURCE_NAME,
} from '@airtel-tv/constants';
import {
    MODAL_POPUP,
} from '@airtel-tv/constants/GlobalConst';

const useAppInAppRedirection = (props) => {
    const {
        appInAppMeta,
        deviceUtil,
        cpId,
        showModalComponentActionDispatch,
        hideModalComponentActionDispatch,
        appInAppHandlingDispatch,
        contentId,
        gtiId,
        inNewTab = true,
        appConfig,
        cpSubscriptionStatusMeta = {},
    } = props;
    const {
        cpDetailsById,
        LGAppId = {},
        AppInstallDescription = {
            title: 'Download SunNxt App to Watch',
            primaryCTA: 'Okay, Got it',
            ctaPretext: 'For further assistance, please refer to our Help & Support section',
            imageUrl: 'https://image.airtel.tv/grandslam/content/2024_8_1/Rectangle_24594.png',
            imageSettings: {
                320: 250,
                500: 250,
                1000: 450,
            },
            stepTitle: 'How to Watch?',
            steps: [
                'Go to App Store',
                'Download SunNxt App',
                'Come back on Xstream Play to watch',
            ],
            popupId: 'sunnxt_app_install',
        },
    } = appConfig;
    const isLGAppInstalled = useRef(false);
    const setLGAppStatus = ({ exist }) => {
        isLGAppInstalled.current = exist;
    };
    const showAppInstallPopUp = () => {
        const {
            title = '',
            primaryCTA = '',
            ctaPretext = '',
            imageSettings = {},
            stepTitle = '',
            steps = [],
            popupId = '',
        } = AppInstallDescription[cpId];
        const assetName = `install_${cpId}_app`;

        const { logoUrl: imageUrl = '' } = cpDetailsById?.[cpId] || {};
        const appModeOnline = isOnline();
        const popupMeta = {
            content_id: contentId,
            app_id: LGAppId[cpId],
            deeplink_url: '',
            app_installed: false,
            cp_name: cpId,
            app_mode_online: appModeOnline,
            source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
            action: assetName,
            ...cpSubscriptionStatusMeta,
        };
        appInAppRedirectionEvent(popupMeta);
        const description = (
            <div className="common-modal-description-container">
                <div className="common-description-title text-24 line-height-36 text-bold">{stepTitle}</div>
                {steps && steps.length ? (
                    <ul className="steps-container">
                        {
                            steps.map((step, index) => (
                                <li className="steps-wrapper text-24 text-normal line-height-36">
                                    <span className="steps-count text-22 text-semi-bold line-height-36">
                                        {`STEP ${index + 1}`}
                                    </span>
                                    {' '}
                                    {step}
                                </li>
                            ))
                        }
                    </ul>
                ) : null}
            </div>
        );

        showModalComponentActionDispatch({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.COMMON_MODAL,
            overrideCrossButtonValue: true,
            payload: {
                title,
                description,
                primaryCTA,
                ctaPretext,
                imageUrl,
                imageSettings,
                primaryAction: () => {
                    hideModalComponentActionDispatch();
                },
                popupId,
                popupVisibleMeta: {
                    asset_name: assetName,
                    ...popupMeta,
                },
                primaryActionMeta: popupMeta,
                containerClassName: 'sunnxt_app_install_popup',
            },
        });
    };
    const openLSAPP = () => {
        if (isLGAppInstalled.current) {
            appInAppHandlingDispatch({
                appInAppMeta,
                contentId,
                cpId,
                appInstalled: true,
                gtiId,
                lgAppId: LGAppId[cpId],
            });
        }
        else {
            showAppInstallPopUp();
        }
    };
    const openApp = () => {
        if (deviceUtil.isWeb()) {
            const {
                android_redirect_url: androidRedirectUrl = '',
                ios_redirect_url: iosRedirectUrl = '',
                browser_redirect_url: browserRedirectUrl = '',
                android_app_id: androidAppId = '',
                ios_app_id: iosAppId = '',
            } = appInAppMeta;
            let url = appInAppMeta.browser_redirect_url;
            let appId = '';
            if (deviceUtil.isMobile()) {
                url = deviceUtil.isIOS() ? iosRedirectUrl : androidRedirectUrl;
                if (deviceUtil.isIOS()) {
                    appId = iosAppId;
                }
                else {
                    appId = androidAppId ? `https://play.google.com/store/apps/details?id=${androidAppId}` : '';
                }
                handleOpenApp({
                    appPath: url,
                    storePath: appId,
                });
            }
            else {
                const fallBrowserDeeplink = androidRedirectUrl.startsWith('https') ? androidRedirectUrl : '';
                url = browserRedirectUrl || fallBrowserDeeplink;

                if (inNewTab) {
                    redirectToInNewTab(url);
                }
                else {
                    openUrlInSameTab(url);
                }
            }
        }
        else {
            openLSAPP();
        }
    };
    useEffect(() => {
        checkLGAppInstalled({
            appId: LGAppId[cpId],
            callBack: setLGAppStatus,
        });
    }, []);
    return openApp;
};

export default useAppInAppRedirection;
