//import ReactGA from 'react-ga';
import lodashGet from 'lodash/get';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { getAppConfig, getUserConfig } from '../../service/end-points/ConfigApiService';
import { authInitFromStorage, loginRequestSuccess, resetAuthConfig } from '../auth/AuthAction';
import { userConfigSuccessAction } from '../user/UserAction';
import { appConfigSuccess } from './actions/AppActions';
import { getTempLoginToken } from '../../service/end-points/AuthApiService';
import { clearAllBrowserStorage, getAppId } from '../../utilities/CommonUtil';
import { isOnline, getWindowLocation } from '../../utilities/WindowUtil';
import { setErrorHandlerDispatch } from '../error-handler/ErrorHandler';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import UserStorageUtil from '../user/UserStorageUtil';
import UserInfoUtil from '../../utilities/UserInfoUtil';
import { LocationUtil } from '@airtel-tv/utils';
import { planConfigInit } from '../plan/PlanActions';


export default async function MobilityInitApp(store, deviceUtilInstance) {
const {
        dispatch,
    } = store;
    // reset authconfig as ssr templogin can't used on client side due to different deviceid

    dispatch(resetAuthConfig());

    const location = getWindowLocation();
    const appId = AuthenticationUtil.getAppId();
    
    // error handler needs dispatch so that we can put error handler
    setErrorHandlerDispatch(dispatch);
    // ATTRIBUTES FETCHED FROM QUERY PARAMS
    LocationUtil.saveDataFromQuery(location);

    if (AuthenticationUtil.isAuthenticated()) {
        dispatch(authInitFromStorage());
    }

    const {
        authConfig,
        appConfig,
        userConfig,
    } = store.getState();

    const promises = [];

    try {
        // get user token if not there
        let loginResponse = {};
        // get user config and initialize it from SSR
        const tempLang = UserStorageUtil.getTempLang();
        let userConfigResponse = userConfig;
        // if true: user already logged in
        if (authConfig.isAuthenticated) {
            const result = await Promise.all([dispatch(planConfigInit()),getUserConfig(deviceUtilInstance)]);
            userConfigResponse = result[1];
            //Analytics.initialize(deviceUtilInstance);
        }
        else {
            // if true: temp login failed at SSR
            if (!authConfig.token) {
                loginResponse = await getTempLoginToken(deviceUtilInstance, getAppId());
                dispatch(loginRequestSuccess(loginResponse));
                userConfigResponse = loginResponse.userConfig;
                //Analytics.initialize(deviceUtilInstance, loginResponse.uid);
            }
            if (tempLang) {
                userConfigResponse = {
                    ...userConfigResponse,
                    userContentProperties: {
                        ...userConfigResponse.userContentProperties,
                        ln: tempLang.join(','),
                    },
                    userInfo: {
                        ...userConfigResponse.userInfo,
                        lang: tempLang,
                    },
                };
            }
            else {
                const defaultLanguage = lodashGet(userConfigResponse, 'userContentProperties.ln', '');
                userConfigResponse = {
                    ...userConfigResponse,
                    userInfo: {
                        ...userConfigResponse.userInfo,
                        lang: defaultLanguage.split(','),
                    },
                };
            }
        }
        //appStartEvent();


        // get app config
        if (appConfig && appConfig.pages.length < 1) {
            promises.push(getAppConfig(deviceUtilInstance).catch(error => ({
                error,
            })));
        }
        //promises.push(dispatch(planConfigInit()));

        //   promises.push(getLanguageFile({ languageCode: lg }).catch(error => ({ error })));

        // get result of all promises
        const responses = await RequestUtil.ALL(promises);
        // list down all errors
        const errors = [];
        responses.map((data) => {
            if (data.error) {
                errors.push(data.error);
            }
            return data;
        });

        // no error: dispatch data to store
        if (errors.length < 1) {
            // 0 index is for language request
            const lang = lodashGet(loginResponse, 'userConfig.userInfo.lang', []);
            const setLang = lang && lang.length > 0 ? lang : UserInfoUtil.getUserLanguage();


            UserInfoUtil.setUserLanguage(setLang);

            dispatch(userConfigSuccessAction({
                userConfig: userConfigResponse,
            }));
            if (appConfig && appConfig.pages.length < 1) {
                dispatch(appConfigSuccess(responses[0]));
            }

            // LanguageProvider(responses[1]);

            return Promise.resolve();
        }
        // erros : throw error

        if (isOnline()) {
            clearAllBrowserStorage();
        }
        console.error(errors);
        return Promise.reject(errors);
    }
    catch (error) {
        if (isOnline()) {
            clearAllBrowserStorage();
        }
        console.error('Error in InitApp:', ' line no. ', error.lineNumber, '\n stack:', error.stack, '\n Name: ', error.name, '\n Message: ', error.message);
        return Promise.reject(error);
    }
}
