import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { showModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import { MODAL_POPUP } from '@airtel-tv/constants';
import { LanguageProviderUtil } from '@airtel-tv/utils';
import { getSourceNameFromStorage } from '@airtel-tv/utils/GlobalUtil';

const useOnehubPlanDetailsPopup = (props = {}) => {
    const {
        popUpcustomClass = 'plan-detail-popup',
    } = props || {};
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const {
        PLAN_DESCRIPTION = 'Valid for {duration}',
        INDIAN_RUPEE_SYMBOL = '₹',
        PLAN_BENEFITS = `{planTitle} for ${INDIAN_RUPEE_SYMBOL}{price}`,
    } = LANGUAGE;
    const { currentSource, prevSource } = getSourceNameFromStorage() || {};
    const dispatch = useDispatch();

    const showPlanDetailsPopup = ({
        partners = [],
        preferredPartner = '',
        planDuration = '',
        planTitle = '',
        planPrice = '',
        planDescription = '',
        planBenefits = '',
        overrideCrossButtonValue = false,
        cpDetailsById,
        meta = {},
    }) => {
        const description = planDescription || (planDuration && PLAN_DESCRIPTION.replace('{duration}', planDuration)) || '';

        const benefits = planBenefits
          || (planTitle && planPrice && PLAN_BENEFITS.replace('{planTitle}', planTitle).replace('{price}', planPrice))
          || '';
        const preferredPartnerArr = partners.filter(item => item.cpId === preferredPartner);
        const otherPartnersArr = partners.filter(item => item.cpId !== preferredPartner);
        const partnersArr = [
            ...preferredPartnerArr,
            ...otherPartnersArr,
        ];
        const benefitsArr = [];
        benefits && benefitsArr.push(benefits);
        description && benefitsArr.push(description);
        const planDetailData = {
            benefits: benefitsArr,
            partners: partnersArr,
        };
        if (!partnersArr?.length && (!benefits || !description)) {
            return;
        }
        dispatch(showModalComponentAction({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.PLAN_DETAIL_POPUP,
            overrideCrossButtonValue,
            payload: {
                popUpcustomClass,
                removeInnerPopupClass: true,
                planDetailData,
                showBackButton: true,
                cpDetailsById,
                backdropClose: true,
                popupVisibleMeta: {
                    source_name: currentSource,
                    source_page: prevSource,
                    popupId: MODAL_POPUP.PLAN_DETAIL_POPUP,
                    ...meta,
                },
                closeClickedMeta: {
                    asset_name: 'cross',
                },
            },
        }));
    };

    return { showPlanDetailsPopup };
};
useOnehubPlanDetailsPopup.propTypes = {};

export default useOnehubPlanDetailsPopup;
