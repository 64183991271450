class ExperimentUtil {
    static store;

    static experimentDetails;

    static setStore(store) {
        this.store = store || null;
    }

    static getExperimentDetailsArray = () => {
        if (!this.store) {
            throw Error('store not set');
        }
        if (!this.experimentDetails) {
            const state = this.store?.getState();
            const { userConfig: { experimentDetails = '' } = {} } = state;
            this.experimentDetails = experimentDetails;
        }
        return this.experimentDetails?.split('|');
    };

    static getListFilteredByExperiment = (list) => {
        if (!list?.length) {
            return [];
        }
        return list.filter((item) => {
            if (!item?.experimentId) {
                return true;
            }
            return this.checkExperimentExist(item.experimentId);
        });
    };

    static checkExperimentExist = (experiment = '') => {
        const experimentNum = experiment?.split(':');
        if (Number(experimentNum[1]) === 0) {
            return false;
        }
        const experimentId = experimentNum[0];
        const experimentDetailsArray = this.getExperimentDetailsArray();
        const experimentRes = experimentDetailsArray.filter((item) => {
            const id = item?.split(':')[0];
            const experimentValue = item?.split(':')[1];
            if (Number(id) === Number(experimentId) && Number(experimentValue) !== 0) {
                return true;
            }
            return false;
        });
        return !!experimentRes?.length;
    };

    static getExperimentKeyValue = (experimentId) => {
        const experimentDetailsArray = this.getExperimentDetailsArray();
        const filteredExperiment = experimentDetailsArray.filter((item) => {
            const id = item?.split(':')[0];
            if (Number(id) === Number(experimentId)) {
                return true;
            }
            return false;
        });
        if (filteredExperiment?.length) {
            const expString = filteredExperiment[0];
            const splitExp = expString?.split(':');
            if (splitExp?.length > 1 && experimentId === splitExp[0]) {
                return {
                    experimentId: splitExp[0],
                    experimentValue: splitExp[1],
                };
            }
        }
        return {
            experimentId: '',
            experimentValue: '',
        };
    };
}

export default ExperimentUtil;
