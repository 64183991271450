import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import React, { forwardRef, useRef } from 'react';
import { getWindowLocation } from '@airtel-tv/utils';

const LinkComponent = (props, ref) => {
    const {
        children,
        staticContext,
        onClick,
        onMouseEnter,
        id,
        to,
        ...rest
    } = props;

    const linkClicked = (e) => {

        const location = getWindowLocation();

        if (onClick) {
            onClick(e);
        }

        if (location.pathname + location.search === to) {
            e.preventDefault();
        }
    };


    return (
        <Link
            href={to}
            ref={ref}
            {...rest}
            id={id}
            onClick={linkClicked}
            onMouseEnter={onMouseEnter}
            to={to}
            draggable="false"
        >
            {children}
        </Link>
    );
};

// LinkComponent.defaultProps = {
//     onClick: null,
//     staticContext: null,
//     children: null,
//     onMouseEnter: null,
//     id: null,
// };

// LinkComponent.propTypes = {
//     children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
//     staticContext: PropTypes.object, // eslint-disable-line react/forbid-prop-types
//     to: PropTypes.string.isRequired,
//     onClick: PropTypes.func,
//     onMouseEnter: PropTypes.func,
//     id: PropTypes.string,
// };
const linkComponent = withRouter(forwardRef((LinkComponent)));
export { linkComponent as LinkComponent };
