import { getDispatch } from '../../web/src/redux/Store';
// import { addToast } from '../modules/notify/NotifyActions';
import { addToast } from '../../web/src/modules/notify/NotifyActions';

export const showToastr = (message, buttonText) => {
    const dispatch = getDispatch();
    dispatch(addToast({
        toast: {
            message,
            buttonText,
        },
    }));
};

export default {
    showToastr,
};
