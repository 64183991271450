import React from 'react';
import { PropTypes } from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { LOCAL_TILE_TYPES, LOCAL_RAIL_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { LocationUtil } from '@airtel-tv/utils';
import { ImageFactory } from '../../../../../layout/factory/ImageFactory';

const NextEpisodeComponent = ({ epDetails, show, deviceUtil }) => {
    if (!epDetails || !show) {
        return null;
    }
    const {
        url,
        nextEpTitle,
        nextEpLinkTitle,
        details,
    } = epDetails;

    const imgUrl = ImageFactory({
        tileType: LOCAL_TILE_TYPES.NEXT_EPISODE_TILE,
        railType: LOCAL_RAIL_TYPES.NO_RAIL,
        images: details && details.images ? details.images : {},
    });

    return (
        <div
            className="next-episode-hover-control"
            id="atm_next-episode-hover-control"
            to={`${url}?${LocationUtil.objectToQueryParams({
                showPlayback: true,
                ...(deviceUtil?.isScopedWebview() ? { viewType: 'scoped_webview' } : {}),
            })}`}
            title={nextEpLinkTitle}
        >
            <div className="next-episode-preview ">

                <div className="next-episode-btn-img">
                    <ThumborImage
                        src={imgUrl}
                    />
                </div>
                <div className="next-episode-btn-desc-part">
                    { details && <p className="next-episode-title-next-btn">{details.name}</p> }
                    <p className="next-episode-sub-title-next-btn">{nextEpTitle}</p>
                    { details && (
                        <p className="next-episode-next-btn-description ">
                            {details.description}
                        </p>
                    ) }
                </div>


            </div>
        </div>
    );
};

NextEpisodeComponent.propTypes = {
    epDetails: PropTypes.shape({
        url: PropTypes.string,
        nextEpTitle: PropTypes.string,
        nextEpLinkTitle: PropTypes.string,
        details: PropTypes.object,
    }),
    show: PropTypes.bool.isRequired,
};

NextEpisodeComponent.defaultProps = {
    epDetails: null,
};

export default React.memo(NextEpisodeComponent);
