export const CDP_RAIL_MOCK = {
    id: '6305dff84359ea26e079ddfc',
    contents: [],
    name: 'Activate Channel Partner',
    format: {
        showAll: false,
        artwork: {},
        ty: 'X_CONTENT_REDIRECTION_RAIL',
        contentAction: {
            isExternalWebLink: false,
        },
        title: 'Already Have Prime Video Subscription',
    },
    zionDisplay: {
        title: 'Already Have Prime Video Subscription',
        deepLink: '',
    },
    showCatalogTag: false,
};

export const LAYOUT_MOCK_PAGES = {
    OneHubLayout: {
        railList: [
            {
                id: '66f425c7fe10461171ec49d9',
                contents: [],
                name: 'One Hub title',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PLAN_TITLE_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    t: 'My Plans',
                    st: 'One Stop For All Your Favourite OTT Subscriptions',
                    title: {},
                },
                zionDisplay: {
                    subTitle: 'One Stop For All Your Favourite OTT Subscriptions',
                    title: 'My Plans',
                    backgroundImageUrl: 'https://image.airtel.tv/grandslam/content/2024_10_5/cover_background.png',
                },
                showCatalogTag: false,
            },
            // {
            //     id: '66f425f8fe10461171ec49da',
            //     contents: [],
            //     name: 'plan coming soon',
            //     format: {
            //         showAll: false,
            //         artwork: {},
            //         ty: 'X_PLAN_COMING_SOON',
            //         contentAction: {
            //             isExternalWebLink: false,
            //         },
            //         t: '{target_cp} is currently not available for you',
            //         st: 'You can still buy the existing plans',
            //         title: {
            //             logo: 'https://image.airtel.tv/grandslam/content/2024_9_26/Info.png',
            //         },
            //     },
            //     zionDisplay: {
            //         titleImg: 'https://image.airtel.tv/grandslam/content/2024_9_26/Info.png',
            //         subTitle: 'You can still buy the existing plans',
            //         title: '{target_cp} is currently not available for you',
            //     },
            //     showCatalogTag: false,
            // },
            {
                id: '66c8500ca465db751a727fee',
                contents: [],
                name: 'active plan one hub',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_ACTIVE_PLAN',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    t: '',
                    title: {},
                },
                zionDisplay: {
                    planKey: 'activePlans',
                    title: '',
                },
                showCatalogTag: false,
            },
            {
                id: '66c726e0a465db751a727fde',
                contents: [],
                name: 'Premium Partner Activation Rail ',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PARTNER_ACTIVATION_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    title: {},
                },
                zionDisplay: {
                    titleTxt: 'Standalone Subscriptions',
                    planKey: 'premiumSubscriptionStatus',
                },
                showCatalogTag: false,
            },
            {
                id: '66f5288bfe10461171ec49df',
                contents: [],
                name: 'Non premium Partner Activation Rail ',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PARTNER_ACTIVATION_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    title: {},
                },
                zionDisplay: {
                    titleTxt: 'Your Subscription Benefits',
                    planKey: 'xppSubscriptionStatus',
                },
                showCatalogTag: false,
            },
            {
                id: '66c72636a465db751a727fdc',
                contents: [],
                name: 'Recommended - Plan Listing',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PLAN_LISTING_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    title: {},
                },
                zionDisplay: {
                    titleTxt: 'Choose a Plan:',
                    planKey: 'recommendedPlans',
                },
                showCatalogTag: false,
            },
            {
                id: '66fce446cfab60257e08575c',
                contents: [],
                name: 'Upgrade- Plan Listing',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PLAN_LISTING_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    st: 'Your current plan of {active_plan_amount} does not have {target_cp} subscription ',
                    title: {},
                },
                zionDisplay: {
                    titleTxt: 'Upgrade your plan:',
                    subTitle: 'Your current plan of {active_plan_amount} does not have {target_cp} subscription ',
                    planKey: 'recommendedPlans',
                },
                showCatalogTag: false,
            },
            {
                id: '66c726b0a465db751a727fdd',
                contents: [],
                name: 'Recharge Plans ',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PLAN_LISTING_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    st: '{PhoneNumber}',
                    title: {
                        logo: 'https://img.airtel.tv/unsafe/filters:quality(100)/https://s3.ap-south-1.amazonaws.com/discovery-prod-zion/zion/1677227357983-Group_625318.png',
                        tc: 'rgba(255, 255, 255, 1)',
                        stc: 'rgba(110, 116, 124, 1)',
                    },
                },
                zionDisplay: {
                    titleTxt: 'Other Mobile Recharge Plans:',
                    subTitleColor: 'rgba(110, 116, 124, 1)',
                    titleImg: 'https://img.airtel.tv/unsafe/filters:quality(100)/https://s3.ap-south-1.amazonaws.com/discovery-prod-zion/zion/1677227357983-Group_625318.png',
                    subTitle: '{PhoneNumber}',
                    titleColor: 'rgba(255, 255, 255, 1)',
                    planKey: 'rechargePlans',
                },
                showCatalogTag: false,
            },
            {
                id: '66c727aea465db751a727fe1',
                contents: [],
                name: 'D2C - Plan Listing',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PLAN_LISTING_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    title: {},
                },
                zionDisplay: {
                    titleTxt: 'Other Subscription plans:',
                    planKey: 'd2cPlans',
                },
                showCatalogTag: false,
            },
        ],
        total: 8,
        totalRails: 8,
        nextPage: false,
        rid: '0.31552058-1727943899266',
    },
    OneHubLayout_showRenewPlans: {
        railList: [
            {
                id: '66f425f8fe10461171ec49da',
                contents: [],
                name: 'plan coming soon',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PLAN_COMING_SOON',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    t: '{target_cp} is currently not available for you',
                    st: 'You can still buy the existing plans',
                    title: {
                        logo: 'https://image.airtel.tv/grandslam/content/2024_9_26/Info.png',
                    },
                },
                zionDisplay: {
                    titleImg: 'https://image.airtel.tv/grandslam/content/2024_9_26/Info.png',
                    subTitle: 'You can still buy the existing plans',
                    title: '{target_cp} is currently not available for you',
                },
                showCatalogTag: false,
            },
            {
                id: '66c8500ca465db751a727fee',
                contents: [],
                name: 'active plan one hub',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_ACTIVE_PLAN',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    t: '',
                    title: {},
                },
                zionDisplay: {
                    planKey: 'activePlans',
                    title: '',
                },
                showCatalogTag: false,
            },
            {
                id: '66c726e0a465db751a727fde',
                contents: [],
                name: 'Premium Partner Activation Rail ',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PARTNER_ACTIVATION_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    title: {},
                },
                zionDisplay: {
                    titleTxt: 'Standalone Subscription',
                    planKey: 'premiumSubscriptionStatus',
                },
                showCatalogTag: false,
            },
            {
                id: '66f5288bfe10461171ec49df',
                contents: [],
                name: 'Non premium Partner Activation Rail ',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PARTNER_ACTIVATION_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    title: {},
                },
                zionDisplay: {
                    titleTxt: 'Your Subscription Benefits',
                    planKey: 'xppSubscriptionStatus',
                },
                showCatalogTag: false,
            },
            {
                id: '66c72636a465db751a727fdc',
                contents: [],
                name: 'Recommended - Plan Listing',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PLAN_LISTING_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    title: {},
                },
                zionDisplay: {
                    titleTxt: 'Choose a plan',
                    planKey: 'recommendedPlans',
                },
                showCatalogTag: false,
            },
            {
                id: '66fce446cfab60257e08575c',
                contents: [],
                name: 'Upgrade- Plan Listing',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PLAN_LISTING_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    st: 'Your current plan of {active_plan_amount} does not have {target_cp} subscription ',
                    title: {},
                },
                zionDisplay: {
                    titleTxt: 'Upgrade your plan',
                    subTitle: 'Your current plan of {active_plan_amount} does not have {target_cp} subscription ',
                    planKey: 'recommendedPlans',
                },
                showCatalogTag: false,
            },
            {
                id: '66c726b0a465db751a727fdd',
                contents: [],
                name: 'Recharge Plans ',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PLAN_LISTING_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    st: '{PhoneNumber}',
                    title: {
                        logo: 'https://img.airtel.tv/unsafe/filters:quality(100)/https://s3.ap-south-1.amazonaws.com/discovery-prod-zion/zion/1677227357983-Group_625318.png',
                        tc: 'rgba(255, 255, 255, 1)',
                        stc: 'rgba(110, 116, 124, 1)',
                    },
                },
                zionDisplay: {
                    titleTxt: 'Other Mobile Recharge Plans',
                    subTitleColor: 'rgba(110, 116, 124, 1)',
                    titleImg: 'https://img.airtel.tv/unsafe/filters:quality(100)/https://s3.ap-south-1.amazonaws.com/discovery-prod-zion/zion/1677227357983-Group_625318.png',
                    subTitle: '{PhoneNumber}',
                    titleColor: 'rgba(255, 255, 255, 1)',
                    planKey: 'rechargePlans',
                },
                showCatalogTag: false,
            },
            {
                id: '66c727aea465db751a727fe1',
                contents: [],
                name: 'D2C - Plan Listing',
                format: {
                    showAll: false,
                    artwork: {},
                    ty: 'X_PLAN_LISTING_RAIL',
                    contentAction: {
                        isExternalWebLink: false,
                    },
                    title: {},
                },
                zionDisplay: {
                    titleTxt: 'Other Subscription plans',
                    planKey: 'd2cPlans',
                },
                showCatalogTag: false,
            },
        ],
        total: 8,
        totalRails: 8,
        nextPage: false,
        rid: '0.31552058-1727943899266',
    },

};
