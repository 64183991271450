import {
    takeEvery, call, put, getContext,
} from 'redux-saga/effects';
import { ThemeActions, themeConfigErrorAction, themeConfigSuccessAction } from './ThemeActions';
import { getThemeConfig } from '../../service/end-points/ConfigApiService';


function* themeConfigFetch() {
    const deviceUtil = yield getContext('deviceUtil');

    try {
        const themeConfig = yield call(getThemeConfig, { deviceUtil });

        yield put(themeConfigSuccessAction(themeConfig));
    }
    catch (error) {
        console.error(error);
        const payload = {
            error,
            fetching: false,
        };
        yield put(themeConfigErrorAction(payload));
    }
}


export default [
    takeEvery(ThemeActions.THEME_CONFIG_FETCH, themeConfigFetch),
];
