import React from 'react';

const FreeText = () => (
    <svg
        width="36"
        height="11"
        viewBox="0 0 36 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_i_1947_1085)">
            <path
                d="M4.57784 4.73374H8.49617L8.16649 6.72955H4.24816L3.68608 10.0092H1L2.51869 1H9.3663L9.01501 3.07991H4.85347L4.57243 4.73374H4.57784Z"
                fill="url(#paint0_linear_1947_1085)"
                stroke="url(#paint1_linear_1947_1085)"
                strokeWidth="0.192498"
            />
            <path
                d="M15.9542 6.87544L17.3486 10.0093H14.4031L13.3113 7.15575H12.2304L11.744 10.0093H9.06873L10.5874 1.00012H15.1597C16.8243 1.00012 18.1268 1.90833 18.1268 3.52292C18.1268 5.13751 17.3432 6.36527 15.9542 6.87544ZM14.0139 5.07584C14.8895 5.07584 15.4029 4.61053 15.4029 3.87611C15.4029 3.38276 15.1002 3.08003 14.3544 3.08003H12.9168L12.5763 5.07584H14.0139Z"
                fill="url(#paint2_linear_1947_1085)"
                stroke="url(#paint3_linear_1947_1085)"
                strokeWidth="0.192498"
            />
            <path
                d="M25.9093 4.4198L25.5689 6.41561H21.6505L21.4073 7.86762H25.5689L25.2067 10.0148H18.3591L19.8778 1.00562H26.7254L26.3741 3.08552H22.2126L21.991 4.42541H25.9093V4.4198Z"
                fill="url(#paint4_linear_1947_1085)"
                stroke="url(#paint5_linear_1947_1085)"
                strokeWidth="0.192498"
            />
            <path
                d="M34.1839 4.4198L33.8434 6.41561H29.9251L29.6819 7.86763H33.8434L33.4813 10.0148H26.6337L28.1524 1.00562H35L34.6487 3.08552H30.4871L30.2656 4.42541H34.1839V4.4198Z"
                fill="url(#paint6_linear_1947_1085)"
                stroke="url(#paint7_linear_1947_1085)"
                strokeWidth="0.192498"
            />
        </g>
        <defs>
            <filter
                id="filter0_i_1947_1085"
                x="0.886108"
                y="0.903748"
                width="34.2278"
                height="11.1323"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1.92498" />
                <feGaussianBlur stdDeviation="0.962491" />
                <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
                />
                <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_1947_1085"
                />
            </filter>
            <linearGradient
                id="paint0_linear_1947_1085"
                x1="5.18315"
                y1="1"
                x2="0.99807"
                y2="11.2412"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#26E2CB" />
                <stop
                    offset="1"
                    stopColor="#2EAD9E"
                />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1947_1085"
                x1="5.18315"
                y1="1"
                x2="5.18315"
                y2="10.0092"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B4FFF1" />
                <stop
                    offset="1"
                    stopColor="#D2D2D3"
                    stopOpacity="0"
                />
            </linearGradient>
            <linearGradient
                id="paint2_linear_1947_1085"
                x1="13.5978"
                y1="1.00012"
                x2="9.64931"
                y2="11.4613"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#26E2CB" />
                <stop
                    offset="1"
                    stopColor="#2EAD9E"
                />
            </linearGradient>
            <linearGradient
                id="paint3_linear_1947_1085"
                x1="13.5978"
                y1="1.00012"
                x2="13.5978"
                y2="10.0093"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B4FFF1" />
                <stop
                    offset="1"
                    stopColor="#D2D2D3"
                    stopOpacity="0"
                />
            </linearGradient>
            <linearGradient
                id="paint4_linear_1947_1085"
                x1="22.5423"
                y1="1.00562"
                x2="18.3572"
                y2="11.2469"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#26E2CB" />
                <stop
                    offset="1"
                    stopColor="#2EAD9E"
                />
            </linearGradient>
            <linearGradient
                id="paint5_linear_1947_1085"
                x1="22.5423"
                y1="1.00562"
                x2="22.5423"
                y2="10.0148"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B4FFF1" />
                <stop
                    offset="1"
                    stopColor="#D2D2D3"
                    stopOpacity="0"
                />
            </linearGradient>
            <linearGradient
                id="paint6_linear_1947_1085"
                x1="30.8168"
                y1="1.00562"
                x2="26.6317"
                y2="11.2469"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#26E2CB" />
                <stop
                    offset="1"
                    stopColor="#2EAD9E"
                />
            </linearGradient>
            <linearGradient
                id="paint7_linear_1947_1085"
                x1="30.8168"
                y1="1.00562"
                x2="30.8168"
                y2="10.0148"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B4FFF1" />
                <stop
                    offset="1"
                    stopColor="#D2D2D3"
                    stopOpacity="0"
                />
            </linearGradient>
        </defs>
    </svg>

);

export default FreeText;
