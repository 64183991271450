import { PlanActions } from './PlanActions';

const initialState = {
};

const PlanConfigReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case PlanActions.PLAN_CONFIG_SUCCESS:
            changes = {
                ...action,
                isSuccess: true,
            };
            break;

        case PlanActions.PLAN_CONFIG_FAILURE:
            changes = {
                ...action,
                isSuccess: false,
            };
            break;
        case PlanActions.PLAN_CONFIG_CLEAR_ALL:
            return {};
        default: return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default PlanConfigReducer;
