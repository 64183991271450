import {
    adsSDKEvent,
} from '@airtel-tv/analytics/FunctionalEvents';
import { AppEnv } from '@airtel-tv/utils/GetEnv';
import { DeviceTypeUtil } from './DeviceTypeUtil';
import { getAppVersion, getBuildNumber } from './GlobalUtil';
import { isTizenOSApp, isWebOSApp } from './WindowUtil';

const eventNameTransformMap = {
    // ad_playback_start: 'start_quartile',
    ad_skipped: 'Ad_skip',
    ad_error: 'app_ad_error',
};

export const AD_SOURCES = {
    AIRTEL: 'airtel',
    PARTNER: 'partner',
};


export const AD_TAG_TYPE = {
    VAST: 'vast',
    VMAP: 'vmap',
    XML: 'xml',
    DAI: 'dai',
};

export const webAdSdkInit = ({
    uid,
    did,
}) => {
    document.addEventListener('sdkEvent-AD_CONFIG', (data) => {
        const meta = data.detail.analyticsData;
        const newData = webAdSdkEventDataShaper(meta);
        adsSDKEvent({
            meta: newData,
            client_ad_source: AD_SOURCES.AIRTEL, // @NGTD: Change it when sony ads are powered by sdk
            client_ad_type: AD_TAG_TYPE.VAST,
        }, meta.event_name);
    });
    document.addEventListener('sdkEvent-AD_ERROR', (data) => {
        const meta = data.detail.analyticsData;
        const newData = webAdSdkEventDataShaper(meta);
        adsSDKEvent({
            meta: newData,
            client_ad_source: AD_SOURCES.AIRTEL, // @NGTD: Change it when sony ads are powered by sdk
            client_ad_type: AD_TAG_TYPE.VAST,
        }, eventNameTransformMap.ad_error);
    });

    // document.addEventListener('sdkEvent', (data) => {
    //     console.log(JSON.stringify(data.detail, 0, 2));
    // });
    window.adsWebSDK('init', {
        clientId: DeviceTypeUtil.isTV() ? 'XSTREAM_TV' : 'XSTREAM_APP',
        uid,
        did,
        clientBuildNumber: +getBuildNumber(),
        clientVersion: getAppVersion(),
        prefetchModules: {
            // optional  for prefetch of mention modules .
            // Else we will load these modules on demand..
            //   DFP_BANNER: dfpPreload,
            IMA_SDK_VIDEO: true,
            PAL: !!(isWebOSApp() || isTizenOSApp()),
        },
    });
};

export const webAdSdkEventDataShaper = (data) => {
    const eventNameTransformMap = {
        // ad_playback_start: 'start_quartile',
        ad_skipped: 'Ad_skip',
    };
    const {
        web_sdk_version, error_reason, skipOffset, ...restData
    } = data;
    const newData = {
        ...restData,
        event_name: eventNameTransformMap[data?.event_name] || data?.event_name,
        ad_error_reason: error_reason,
        adsdk_version_name: web_sdk_version,
        adsdk_version_code: web_sdk_version,
        skip_offset: skipOffset,

    };
    return newData;
};

export const checkSdkAdsAllowed = (contentDetails, adConfig = {}) => {
    if (!contentDetails?.adsAllowed) {
        return false;
    }
    const { cpId } = contentDetails;
    const { adConfigCpMap = {} } = adConfig || {};
    if (adConfigCpMap?.[cpId]) {
        return true;
    }

    return false;
};

export const checkPartnerAdsinPlayback = (playbackDetails) => {
    if (!DeviceTypeUtil.isWeb() && playbackDetails.ads && Object.keys(playbackDetails.ads).length > 0) {
        return true;
    }
    return false;
};

export const WEB_SDK_SCRIPT = 'web-sdk-script';

export const addSDKScript = () => {
    const env = AppEnv.getEnv();
    const { sdkURL } = env.externalJsReferences;
    const script = document.createElement('script');
    script.id = WEB_SDK_SCRIPT;
    script.innerHTML = `
      !(function (e, t, n, s) {
        e[s] =
          e[s] ||
          function (t, n) {
            (e[s].q = e[s].q || []), e[s].q.push({ eventKey: t, eventObj: n });
          };
        var r =
          "${sdkURL}";
        (j = t.createElement("script")),
          (j.async = !0),
          (j.id = r.split("/").pop()),
          (j.src = \`\${r}?refKey=\${s}\`);
        var a = t.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(j, a);
      })(window, document, 0, "adsWebSDK");
    `;

    document.head.appendChild(script);
};
