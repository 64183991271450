import { AppEnv } from "@airtel-tv/utils/GetEnv";

const env = AppEnv.getEnv();
const throttle = env.crashQueueSize;
const queue = [];
const CrashQueue = {
    add: (crash, callback) => {
        if (queue.length >= throttle - 1) {
            queue.push(crash);
            CrashQueue.flush(callback);
        }
        else {
            queue.push(crash);
        }
    },
    flush: (callback) => {
        if (queue.length > 0) {
            callback(queue.splice(0, throttle));
            return true;
        }

        return false;
    },
    addAndFlush: (crash, callback) => {
        queue.push(crash);
        CrashQueue.flush(callback);
    },
};

export default CrashQueue;
