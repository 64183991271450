import { MARKUP_TYPES, MARKUP_CONTEXT } from '../../../../constants/MarkupConsts';
import {RoutingUtil} from '@airtel-tv/utils/RoutingUtil';

import PotentialActionParser from './PotentialActionParser';
import { PROGRAM_TYPES } from '../../../../constants/AppConst';
import { PartofSeriesParser, actorDirectorParser, ContainsSeason } from '@airtel-tv/utils/ParserUtil';


export default function TvSeasonMarkupParser({
    contentDetails,
    episodeDetails,
    baseUrl,
    tvShowDetails,
}) {
    const {
        title, tvShowName, programType, description, id, credits, languages, images, seriesId,
    } = contentDetails;
    const { episodeSeasonNum } = episodeDetails;
    const canonicalUrlSeason = baseUrl + RoutingUtil.getTvSeasonUrlWeb({
        programType,
        title: tvShowName,
        seasonName: title,
        contentId: id,
    });

    const canonicalUrlTvshow = baseUrl + RoutingUtil.getContentPlaybackUrlWeb({
        category: PROGRAM_TYPES.TVSERIES,
        contentTitle: tvShowName,
        contentId: seriesId,
    });

    const partOfSeries = PartofSeriesParser(canonicalUrlTvshow, tvShowName);

    const potentialAction = PotentialActionParser({
        urlTemplate: canonicalUrlSeason,
        inLanguage: languages && languages[0],
    });

    const { actorsCatalogue } = actorDirectorParser(credits);
    let containsSeason = null;
    if (tvShowDetails && tvShowDetails.seriesTvSeasons) {
        containsSeason = ContainsSeason(tvShowDetails.seriesTvSeasons);
    }
    return {
        '@context': MARKUP_CONTEXT.URL,
        '@type': MARKUP_TYPES.TVSEASON,
        url: canonicalUrlSeason,
        name: title,
        seasonNumber: episodeSeasonNum,
        partOfSeries,
        ...potentialAction,
        description: description || title,
        actor: actorsCatalogue,
        image: images && Object.values(images),
        containsSeason,
    };
}
