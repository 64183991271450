export const ERROR_HANDLING_TYPES = {
    TOAST: 'TOAST',
    MODAL: 'MODAL',
    REDIRECTION: 'REDIRECTION',
    SHOWN_ERROR_PAGE: 'SHOWN_ERROR_PAGE',
    ERROR_PLAYER_COMPONENT: 'ERROR_PLAYER_COMPONENT',
    LOGIN: 'LOGIN',
    NETWORK_ISSUE: 'NETWORK_ISSUE',
    DO_NOTHING: 'DO_NOTHING',
    PAUSE_PLAYBACK: 'PAUSE_PLAYBACK',
};

export default {
    ERROR_HANDLING_TYPES,
};
