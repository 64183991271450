import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import {
    getBuildNumber, getDeviceType, getAppId, removeFalsy,
} from '@airtel-tv/utils/GlobalUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import env from '../../config';

let { base, paths, serverBase } = env.endpoints.userApi;
base = checkWindowExist() ? base : serverBase;

// eslint-disable-next-line import/prefer-default-export
export const getAllPlansConfig = async ({
    deviceUtil,
}) => {
    const appIdFromLocal = AuthenticationUtil.getAppId();
    const deviceTypeFromLocal = deviceUtil.getDeviceTypeFromLocal();
    const os = deviceUtil.getOsFromLocal();
    const buildNo = deviceUtil.getBuildNumberFromLocal();

    const queryParams = {
        appId: appIdFromLocal || getAppId(),
        dt: deviceTypeFromLocal || getDeviceType(),
        os: os || deviceUtil.getBrowserType(),
        bn: buildNo || getBuildNumber(),
    };
    const url = `${paths.allPlans}?${LocationUtil.objectToQueryParams(queryParams)}`;
    const method = 'GET';
    const utknData = {
        urlBody: `${method}/${url}`,
        token: AuthenticationUtil.getAuthToken(),
        uid: AuthenticationUtil.getUid(),
    };
    const utkn = CryptoUtil.getUtkn(utknData);

    const xtvDidFromLocal = deviceUtil.getXAtvDidFromLocal();
    const header = {
        'x-atv-did': xtvDidFromLocal || deviceUtil.getXAtvDid(),
        'x-atv-utkn': utkn,
    };
    return RequestUtil.GET({
        url: base + url,
        headers: removeFalsy(header),
    });
};
