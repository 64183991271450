export class StoreUtil {
    static store;
    static setStore(store) {
        this.store = store || null;
    }
    static getStore() {
        if(!this.store) {
            throw 'store not set';
        }
        return this.store;
    }
}