import { OffersCheckActions } from './OffersCheckActions';

const initialState = {
};

const OffersCheckReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case OffersCheckActions.OFFERS_FETCH_SUCCESS:
            changes = {
                ...action,
            };
            break;

        case OffersCheckActions.OFFERS_FETCH_FAILURE:
            changes = {
                ...action,
            };
            break;
        case OffersCheckActions.OFFERS_FETCH_CLEAR:
            return ({});
        default: return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default OffersCheckReducer;
