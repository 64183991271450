import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { decryptPartnerStatusString } from '@airtel-tv/utils/GlobalUtil';
import AuthAction from '../auth/AuthAction';
import UserAction from './UserAction';
import { getUserSubscriptionById } from './UserHelper';

const initialState = JSON.parse(`{
    "subscriptions": [],
    "eligibleOffers": [],
    "availablePlans": [],
    "userProperties": {},
    "userContentProperties": {},
    "userInfo": {
        "uid": "",
        "msisdn": "",
        "name": "",
        "email": "",
        "lang": [],
        "dob": 0,
        "gender": "",
        "currSeg": "",
        "new": true
    },
    "langInfo": [],
    "htoken": "",
    "customerType": "",
    "dfpMetaInfo": {},
    "xlucisveUser": false,
    "showEmailConfirmation": false,
    "updateEmailError": null,
    "updateLanguageError": null,
    "preferredPartnerPlans": [],
    "updatePreferredPartnerError": null,
    "activatePartnerSubscription": {},
    "userLocation": {},
    "experimentDetails": null,
    "xppClaimDto": {
        "serviceId": "",
        "notifyId": "",
        "status": "",
        "xppExpiry": ""
    },
    "prepaidBenefitDetails": {}
}`);

const UserReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case UserAction.USER_CONFIG_SUCCESS: {
            const {
                userConfig = {},
                userConfig: {
                    userContentProperties,
                    userContentProperties: {
                        ps = '',
                    } = {},
                } = {},
            } = action;
            const partnerStatus = decryptPartnerStatusString({ ps });
            const subscriptionDataById = getUserSubscriptionById({ userConfig });
            changes = {
                ...userConfig,
                userContentProperties: {
                    ...userContentProperties,
                    xEy: checkWindowExist() && localStorage.getItem('xEy') ? localStorage.getItem('xEy') : userConfig?.userContentProperties?.xEy,
                    prValidity: checkWindowExist() && localStorage.getItem('prValidity') ? localStorage.getItem('prValidity') : userConfig?.userContentProperties?.prValidity,
                    xpprbe: checkWindowExist() && localStorage.getItem('xpprbe') ? localStorage.getItem('xpprbe') : userConfig?.userContentProperties?.xpprbe,
                },
                subscriptionDataById,
                partnerStatus,
            };
            break;
        }
        case 'UPDATE_CONFIGURATION':
            changes = {
                xppClaimDto: {
                    ...state.xppClaimDto,
                    xppExpiry: '2176281293781',
                    status: 'SUCCESS',
                },
            };
            break;
        case UserAction.USER_LOCATION_SUCCESS: {
            const { userLocation } = action;
            changes = {
                userLocation,
            };
            break;
        }

        case UserAction.UPDATE_PREFERRED_PARTNER_ERROR:
            changes = {
                ...state,
                updatePreferredPartnerError: action.error,
            };

            break;

        case UserAction.SHOW_EMAIL_CONFIRMATION:
            changes = {
                ...state,
                showEmailConfirmation: true,
            };
            break;

        case UserAction.HIDE_EMAIL_CONFIRMATION:
            changes = {
                ...state,
                showEmailConfirmation: false,
            };
            break;

        case UserAction.UPDATE_USER_EMAIL:
            changes = {
                ...state,
                updateEmailError: null,
            };
            break;

        case UserAction.UPDATE_USER_EMAIL_ERROR:
            changes = {
                ...state,
                updateEmailError: action.error,
            };
            break;

        case UserAction.UPDATE_USER_EMAIL_SUCCESS:
            changes = action.userConfig;
            break;

        case UserAction.UPDATE_USER_LANGUAGE:
            changes = {
                updateLanguageError: null,
            };
            break;

        case UserAction.UPDATE_USER_LANGUAGE_ERROR:
            changes = {
                updateLanguageError: action.error,
            };
            break;

        case UserAction.UPDATE_TEMP_USER_LANGUAGE:
            changes = {
                userContentProperties: {
                    ...state.userContentProperties,
                    ln: action.ln ? action.ln.join(',') : '',
                    lg: action.ln ? action.ln.join(',') : '',
                },
                userInfo: {
                    ...state.userInfo,
                    lang: action.ln,
                },
            };
            break;
        case UserAction.ACTIVATE_PARTNER_SUBSCRIPTION_INIT:
            changes = {
                activatePartnerSubscription: { isFetching: true },
            };
            break;
        case UserAction.ACTIVATE_PARTNER_SUBSCRIPTION_SUCCESS:
            changes = {
                activatePartnerSubscription: {
                    isFetching: false,
                    success: true,
                    isFetched: true,
                },
            };
            break;
        case UserAction.ACTIVATE_PARTNER_SUBSCRIPTION_ERROR:
            changes = {
                activatePartnerSubscription: {
                    isFetching: false,
                    isFetched: true,
                    success: false,
                    errorData: action.errorData,
                },
            };
            break;
        case UserAction.ACTIVATE_PARTNER_SUBSCRIPTION_CLEAR:
            changes = { activatePartnerSubscription: {} };
            break;
        case AuthAction.LOGOUT_REQUEST:
            changes = {
                fmfConfig: {},
            };
            break;
        default:
            return state;
    }
    return {
        ...state,
        ...changes,
    };
};

export default UserReducer;
