import ReactGA from 'react-ga4';
import lodashGet from 'lodash/get';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import ApplicationUtil from '@airtel-tv/utils/ApplicationUtil';
import { appStartEvent, Analytics, appInitEvent } from '@airtel-tv/analytics';
import {
    LocationUtil,
    checkWindowExist,
} from '@airtel-tv/utils';
import { moengaeInit } from '@airtel-tv/utils/moengageUtil';
import { initializeFirebase } from '@airtel-tv/firebase';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import { autoLoginWithSSO } from '@airtel-feature/auth/AuthAction';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { getAppConfig, getUserConfig, getUserLocation } from '../../service/end-points/ConfigApiService';
import {
    authInitFromStorage, loginRequestSuccess, resetAuthConfig,
} from '../auth/AuthAction';
import { userConfigSuccessAction } from '../user/UserAction';
import { appConfigSuccess, fetchEpoch } from './actions/AppActions';
import { getTempLoginToken } from '../../service/end-points/AuthApiService';
import { APP_HOOK_VISIBLE_AFTER_VIS_COUNT } from '../../constants/AppConst';
import { clearAllBrowserStorage, getAppId } from '../../utilities/CommonUtil';
import { isOnline, getWindowLocation } from '../../utilities/WindowUtil';
import { setErrorHandlerDispatch } from '../error-handler/ErrorHandler';
import config from '../../config';
import UserStorageUtil from '../user/UserStorageUtil';
import UserInfoUtil from '../../utilities/UserInfoUtil';
import { removeChannelPartnerId } from '../channel-partner/ChannelPartnerActions';
import loginResponseFallBack from '../../../server/mockResponses/tempLogin.json';

export default async function initApp(store, deviceUtilInstance) {
    const deviceUtil = deviceUtilInstance;
    if (config.gaTrackingId) {
        ReactGA.initialize(config.gaTrackingId);
    }
    if (config.moengageId) {
        // initialize moengage on production
        moengaeInit({
            app_id: config.moengageId,
            debug_logs: 0,
        });
    }
    if (config.firebaseConfig) {
        initializeFirebase({
            config: config.firebaseConfig,
        });
    }

    // set visit count if app hook install is hidden
    if (ApplicationUtil.getAppHookBannerVisibility() === false) {
        const visCount = ApplicationUtil.getVisitCountAfterHidingAppHook() || 0;
        if (visCount < APP_HOOK_VISIBLE_AFTER_VIS_COUNT) {
            ApplicationUtil.setVisitCountAfterHidingAppHook(visCount + 1);
        }
        else {
            ApplicationUtil.setAppHookBannerVisibility(true);
            ApplicationUtil.setVisitCountAfterHidingAppHook(0);
        }
    }
    const {
        dispatch,
    } = store;

    const {
        authConfig: { autoLoginInProgress = false },
        appConfig: {
            utmPlatform = 'Thanks',
            isScopedWebview,
        },
    } = store.getState();

    if (AuthenticationUtil.isAuthenticated() && !autoLoginInProgress) {
        dispatch(authInitFromStorage());
    }
    if (autoLoginInProgress) {
        if (checkWindowExist()) {
            browserStore.remove(LOCAL_STORE_KEYS.TOKEN);
            browserStore.remove(LOCAL_STORE_KEYS.UID);
            browserStore.remove(LOCAL_STORE_KEYS.ACCESS_TOKEN);
        }
        const isIOS = deviceUtil.isIOS();
        LocationUtil.setSessionIdFromThanks({ isIOS });
    }
    // reset authconfig as ssr templogin can't used on client side due to different deviceid

    dispatch(resetAuthConfig());

    const location = getWindowLocation();
    const appId = AuthenticationUtil.getAppId();
    if (appId !== 'MOBILITY') {
        AuthenticationUtil.removeAppId();
        deviceUtil.removeDeviceAttributesFromLocal();
        dispatch(removeChannelPartnerId());
    }
    // error handler needs dispatch so that we can put error handler
    setErrorHandlerDispatch(dispatch);
    // ATTRIBUTES FETCHED FROM QUERY PARAMS
    LocationUtil.saveDataFromQuery(location);

    if (AuthenticationUtil.isAuthenticated() && !autoLoginInProgress) {
        dispatch(authInitFromStorage());
    }

    const {
        authConfig,
        appConfig,
        userConfig,
    } = store.getState();

    const promises = [];

    try {
        // get user token if not there
        let loginResponse = {};
        // get user config and initialize it from SSR
        const tempLang = UserStorageUtil.getTempLang();
        let userConfigResponse = userConfig;

        // let userLocationResponse = {};
        // if true: user already logged in
        // const n = authConfig.isAuthenticated ? async () => Promise.reject() : async () => getUserConfig(deviceUtilInstance);
        if (authConfig.isAuthenticated && !autoLoginInProgress) {
            // userLocationResponse = await getUserLocation(deviceUtilInstance);
            // if(userLocationResponse){
            //     dispatch(userLocationSuccessAction({ userLocation: userLocationResponse }));
            // }
            userConfigResponse = await getUserConfig(deviceUtilInstance).catch(async e => import('../../../server/mockResponses/tempLogin.json'),
                // throw e;
            );
            const { experimentDetails } = userConfigResponse;
            Analytics.initialize({
                deviceUtil: deviceUtilInstance,
                experimentDetails,
                campaignDetails: deviceUtilInstance.isScopedWebview() ? ScopedWebviewUtil.getUtmParams(appConfig) : {},
            });
        }
        else {
            // if true: temp login failed at SSR
            if (!authConfig.token) {
                console.log('temp login failed at SSR init app', authConfig);
                try {
                    loginResponse = await getTempLoginToken(deviceUtilInstance, getAppId());
                }
                catch (error) {
                    loginResponse = loginResponseFallBack;
                }
                dispatch(loginRequestSuccess(loginResponse));
                // userLocationResponse = await getUserLocation(deviceUtilInstance);
                // if(userLocationResponse){
                //     dispatch(userLocationSuccessAction({ userLocation: userLocationResponse }));
                // }
                userConfigResponse = loginResponse.userConfig;
                Analytics.initialize({
                    deviceUtil: deviceUtilInstance,
                    uid: loginResponse.uid,
                    experimentDetails: userConfigResponse?.experimentDetails,
                    campaignDetails: deviceUtilInstance.isScopedWebview() ? ScopedWebviewUtil.getUtmParams(appConfig) : {},
                });
            }
            if (tempLang) {
                userConfigResponse = {
                    ...userConfigResponse,
                    userContentProperties: {
                        ...userConfigResponse.userContentProperties,
                        ln: tempLang.join(','),
                    },
                    userInfo: {
                        ...userConfigResponse.userInfo,
                        lang: tempLang,
                    },
                };
            }
            else {
                const defaultLanguage = lodashGet(userConfigResponse, 'userContentProperties.ln', '');
                userConfigResponse = {
                    ...userConfigResponse,
                    userInfo: {
                        ...userConfigResponse.userInfo,
                        lang: defaultLanguage.split(','),
                    },
                };
            }
        }
        appInitEvent();
        if (autoLoginInProgress) {
            const { search = '' } = location;
            let ssoToken = '';
            const separator = 'sso_token=';

            if (search.includes(separator) && utmPlatform.toUpperCase() !== 'WYNK') {
                ssoToken = search.split(separator)[1];
            }
            else {
                const paramsFromUrl = LocationUtil.getQueryParams({ location });
                const { sso_token: ssoTokenFromParam = '' } = paramsFromUrl;
                ssoToken = ssoTokenFromParam;
            }
            browserStore.set(LOCAL_STORE_KEYS.SSO_TOKEN, ssoToken);

            dispatch(autoLoginWithSSO({
                ssoToken,
                utmSource: utmPlatform,
            }));
        }
        // get app config
        promises.push(getAppConfig(deviceUtilInstance).catch(error => ({
            error,
        })));


        //   promises.push(getLanguageFile({ languageCode: lg }).catch(error => ({ error })));

        // get result of all promises
        const responses = await RequestUtil.ALL(promises);
        // list down all errors
        const errors = [];
        responses.map((data) => {
            if (data.error) {
                errors.push(data.error);
            }
            return data;
        });

        // no error: dispatch data to store
        if (errors.length < 1) {
            // 0 index is for language request
            const lang = lodashGet(loginResponse, 'userConfig.userInfo.lang', []);
            const setLang = lang && lang.length > 0 ? lang : UserInfoUtil.getUserLanguage();


            UserInfoUtil.setUserLanguage(setLang);
            const {
                userInfo: {
                    msisdn = '',
                } = {},
            } = userConfigResponse;
            let msisdnNo = '';
            if (msisdn && authConfig.token) {
                msisdnNo = CryptoUtil.AESDecryptMsdin({
                    key: authConfig.token,
                    value: msisdn,
                });
            }
            dispatch(userConfigSuccessAction({
                userConfig: {
                    ...userConfigResponse,
                    msisdnNo,
                },
            }));

            dispatch(appConfigSuccess(responses[0]));

            appStartEvent({ userConfig: userConfigResponse });
            dispatch(fetchEpoch());

            // LocationUtil.updateViewTypeFromQuery(getWindowLocation());
            if (deviceUtil.isScopedWebview(isScopedWebview)) {
                ScopedWebviewUtil.initScopedWebview();
            }
            return Promise.resolve();
        }
        // erros : throw error

        if (isOnline()) {
            clearAllBrowserStorage();
        }
        console.error(errors);
        return Promise.reject(errors);
    }
    catch (error) {
        if (isOnline()) {
            clearAllBrowserStorage();
        }
        console.error('Error in InitApp:', ' line no. ', error.lineNumber, '\n stack:', error.stack, '\n Name: ', error.name, '\n Message: ', error.message);
        return Promise.reject(error);
    }
}
