import React, { lazy, Suspense } from 'react';

const AsyncCaretDown = lazy(() => import('./components/CaretDown'));
const AsyncHelpIcon = lazy(() => import('./components/HelpIcon'));
const AsyncYellowWarning = lazy(() => import('./components/YellowWarning'));
const AsyncFmfYellowWarning = lazy(() => import('./components/FmfYellowWarning'));
const AsyncXstreamFMFIcon = lazy(() => import('./components/XstreamFMFIcon'));
const AsyncXstreamFMFDividerIcon = lazy(() => import('./components/FMFDivider'));
const AsynscXstreamSiteIcon = lazy(() => import('./components/XstreamSiteIcon'));
// const AsyncDabIcon = lazy(() => import('./components/DabIcon'));
const AsyncFreeIcon = lazy(() => import('./components/FreeIcon'));
const AsyncInstantPayIcon = lazy(() => import('./components/InstantPayIcon'));
const AsyncMobileIcon = lazy(() => import('./components/MobileIcon'));
const AsyncSecurePayIcon = lazy(() => import('./components/SecurePayIcon'));
const AsyncSuccessIcon = lazy(() => import('./components/SuccessIcon'));
const AsyncSupportIcon = lazy(() => import('./components/SupportIcon'));
const AsyncSmsIcon = lazy(() => import('./components/SmsIcon'));
const AsyncUpiIcon = lazy(() => import('./components/UpiIcon'));
const AsycNoteIcon = lazy(() => import('./components/NoteIcon'));
const AsyncSuccessTickIcon = lazy(() => import('./components/SuccessTickIcon'));


export const ICON_MAP = {
    CARET_DOWN: 'CARET_DOWN',
    HELP_ICON: 'HELP_ICONs',
    YELLOW_WARNING: 'YELLOW_WARNING',
    FMF_YELLOW_WARNING: 'FMF_YELLOW_WARNING',
    DAB_ICON: 'DAB_ICON',
    XSTREAM_FMF_ICON: 'XSTREAM_FMF_ICON',
    XSTREAM_DIVIDER_ICON: 'XSTREAM_DIVIDER_ICON',
    FREE_ICON: 'FREE_ICON',
    INSTANT_PAY_ICON: 'INSTANT_PAY_ICON',
    MOBILE_ICON: 'MOBILE_ICON',
    SECURE_PAY_ICON: 'SECURE_PAY_ICON',
    SUCCESS_ICON: 'SUCCESS_ICON',
    SUPPORT_ICON: 'SUPPORT_ICON',
    SMS_ICON: 'SMS_ICON',
    UPI_ICON: 'UPI_ICON',
    ADD_TO_BILL: 'ADD_TO_BILL',
    XSTREAM_SITE_ICON: 'XSTREAM_SITE_ICON',
    SUCCESS_TICK_ICON: 'SUCCESS_TICK_ICON',
};

const LazyIconFatory = ({ iconName, iconProps = {} }) => {
    switch (iconName) {
        case ICON_MAP.CARET_DOWN:
            return (
                <Suspense>
                    <AsyncCaretDown {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.HELP_ICON:
            return (
                <Suspense>
                    <AsyncHelpIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.YELLOW_WARNING:
            return (
                <Suspense>
                    <AsyncYellowWarning {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.FMF_YELLOW_WARNING:
            return (
                <Suspense>
                    <AsyncFmfYellowWarning {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.XSTREAM_FMF_ICON:
            return (
                <Suspense>
                    <AsyncXstreamFMFIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.XSTREAM_DIVIDER_ICON:
            return (
                <Suspense>
                    <AsyncXstreamFMFDividerIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.XSTREAM_SITE_ICON:
            return (
                <Suspense>
                    <AsynscXstreamSiteIcon {...iconProps} />
                </Suspense>
            );
        // case ICON_MAP.DAB_ICON:
        //     return (
        //         <Suspense>
        //             <AsyncDabIcon {...iconProps}/>
        //         </Suspense>
        //     )
        case ICON_MAP.FREE_ICON:
            return (
                <Suspense>
                    <AsyncFreeIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.INSTANT_PAY_ICON:
            return (
                <Suspense>
                    <AsyncInstantPayIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.MOBILE_ICON:
            return (
                <Suspense>
                    <AsyncMobileIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.SECURE_PAY_ICON:
            return (
                <Suspense>
                    <AsyncSecurePayIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.SUCCESS_ICON:
            return (
                <Suspense>
                    <AsyncSuccessIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.SUPPORT_ICON:
            return (
                <Suspense>
                    <AsyncSupportIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.SMS_ICON:
            return (
                <Suspense>
                    <AsyncSmsIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.UPI_ICON:
            return (
                <Suspense>
                    <AsyncUpiIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.ADD_TO_BILL:
            return (
                <Suspense>
                    <AsycNoteIcon {...iconProps} />
                </Suspense>
            );
        case ICON_MAP.SUCCESS_TICK_ICON:
            return (
                <Suspense>
                    <AsyncSuccessTickIcon {...iconProps} />
                </Suspense>
            );
            // case ICON_MAP.DAB_ICON:
            //     return (
            //         <Suspense>
            //             <AsyncDabIcon {...iconProps}/>
            //         </Suspense>
            //     )
        default:
            return null;
    }
};

export default LazyIconFatory;
