import {
    LOCAL_PACKAGE_ID, LOCAL_PACKAGE_NAME, TILES_LIMIT, TILE_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
import { buildRail } from '@airtel-feature/layout/builders/RailBuilder.js';

const LOCAL_RAILS = [
    {
        id: LOCAL_PACKAGE_ID.CONTINUE_WATCHING,
        title: LOCAL_PACKAGE_NAME.CONTINUE_WATCHING,
        tileType: TILE_TYPES.CONTINUE_WATCHING,
    },
    {
        id: LOCAL_PACKAGE_ID.WATCHLIST,
        title: LOCAL_PACKAGE_NAME.WATCHLIST,
        tileType: TILE_TYPES.WATCHLIST,
    },
    {
        id: LOCAL_PACKAGE_ID.YOUR_CHANNELS,
        title: LOCAL_PACKAGE_NAME.YOUR_CHANNELS,
        tileType: TILE_TYPES.SUBSCRIBED_CP_RAIL,
    },
];
export default class LocalRailUtil {
    /**
     * In Layout Container every package is parsed and if the package details is empty Null is returned
     * So we are inserting default data for user's rails
    */
    static syncUserDataInPackage() {
        const payload = {};
        LOCAL_RAILS.forEach((rail) => {
            payload[rail.id] = {
                id: rail.id,
                title: rail.title,
                totalContentCount: 0,
                count: TILES_LIMIT,
            };
        });
        return payload;
    }

    /**
     * map local created package to rail coming from cms
     * @param {Object} layoutPackages - package list for a page,
     */
    static mapLocalPackageToRail(layoutPackages) {
        LOCAL_RAILS.forEach((rail) => {
            const localRailContent = [
                {
                    packageId: rail.id,
                    details: {
                        id: rail.id,
                        title: rail.title,
                        totalContentCount: 0,
                        count: TILES_LIMIT,
                    },
                },
            ];
            const localRail = layoutPackages.find(layout => layout?.format?.ty === rail.tileType);
            if (localRail && localRail.contents) {
                localRail.contents = localRailContent;
            }
        });
        return layoutPackages;
    }

    static filterAndBuildRail({
        railList = [],
        cpDetailsById,
        tilesToFilter = [],
        cdpTrailers,
        msisdnNo = '',
    }) {
        let bannerExist = false;
        let filteredRailList = railList.filter((tile) => {
            const {
                format = {},
                format: { ty = '' },
            } = tile;
            if (!format || tilesToFilter.includes(ty)
            || (ty === TILE_TYPES.BANNER && bannerExist)) {
                return false;
                // filter out for ls to make 1st railposition 0
            }
            bannerExist = true;
            return true;
        });
        filteredRailList = filteredRailList.map((rail) => {
            const content = rail?.contents?.[0]?.details?.content;
            const railPropsInitial = buildRail({
                layoutDetails: rail,
                items: content,
                cpDetailsById,
                cdpTrailers,
                msisdnNo,
            });
            return {
                ...rail,
                railPropsInitial,
            };
        });
        return filteredRailList;
    }
}
