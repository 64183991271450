import { DimensionPageActions } from '../actions/DimensionPageActions';

const initialState = JSON.parse('{}');

const DimensionPageReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case DimensionPageActions.FETCH_DIMENSION_DATA_SUCCESS: {
            changes = { ...action.payload };
            break;
        }
        case DimensionPageActions.RESET_PARTICULAR_DATA: {
            changes = { ...action.payload };
            break;
        }

        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default DimensionPageReducer;
