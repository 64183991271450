import SubscriptionAction from './SubscriptionAction';

const initialState = JSON.parse(`{
    "subscriptionError": null
}`);

const SubscriptionReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case SubscriptionAction.SUBSCRIPTION_ERROR:
            changes = {
                subscriptionError: action.payload,
            };
            break;
        default: return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default SubscriptionReducer;
