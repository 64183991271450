export const DistroAction = {
    FETCH_DISTRO_DETAILS: 'FETCH_DISTRO_DETAILS',
    FETCH_DISTRO_DETAILS_SUCCESS: 'FETCH_DISTRO_DETAILS_SUCCESS',
    FETCH_DISTRO_DETAILS_ERROR: 'FETCH_DISTRO_DETAILS_ERROR',
};

export const fetchDistroDetails = payload => ({
    type: DistroAction.FETCH_DISTRO_DETAILS,
    ...payload,
});

export const fetchDistroDetailsSuccess = payload => ({
    type: DistroAction.FETCH_DISTRO_DETAILS_SUCCESS,
    ...payload,
});

export const fetchDistroDetailsError = payload => ({
    type: DistroAction.FETCH_DISTRO_DETAILS_ERROR,
    ...payload,
});
