import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { playbackEvent } from '@airtel-tv/analytics/FunctionalEvents';
import EventType from '@airtel-tv/analytics/EventType';
import { secondsTohhmmss } from '@airtel-tv/utils/GlobalUtil';
import { PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';

const SkipIntroButtonComponent = (props) => {
    const {
        skipDur, onClickFn, ctaText, showSkipCredit, currentTime, getSkipDurationMeta,
    } = props;

    useEffect(() => {
        let meta = getSkipDurationMeta(PLAYER_EVENTS_LIST.OVERLAY_VISIBLE);

        if (showSkipCredit) {
            meta = {
                ...meta,
                seek_bar_time: secondsTohhmmss(currentTime),
                skip_credit_start_timestamp: currentTime,
                skip_credit_end_timestamp: skipDur,
            };
        }
        else {
            meta = {
                ...meta,
                seek_bar_time: secondsTohhmmss(currentTime),
                skip_intro_start_timestamp: currentTime,
                skip_intro_end_timestamp: skipDur,
            };
        }
        playbackEvent(meta, showSkipCredit ? EventType.SKIP_CREDIT_EVENT : EventType.SKIP_INTRO_EVENT);

        return () => {
            meta = getSkipDurationMeta(PLAYER_EVENTS_LIST.OVERLAY_INVISIBLE);
            if (showSkipCredit) {
                meta = {
                    ...meta,
                    skip_credit_start_timestamp: currentTime,
                    skip_credit_end_timestamp: skipDur,
                };
            }
            else {
                meta = {
                    ...meta,
                    skip_intro_start_timestamp: currentTime,
                    skip_intro_end_timestamp: skipDur,
                };
            }
            playbackEvent(meta, showSkipCredit ? EventType.SKIP_CREDIT_EVENT : EventType.SKIP_INTRO_EVENT);
        };
    }, []);

    return (
        <AnalyticsButtonComponent
            className="player-cta-btn btn-bg-color"
            type="button"
            onClick={() => {
                onClickFn(skipDur, showSkipCredit);
            }}
            aria-label={ctaText}
        >
            <p>{ctaText}</p>
        </AnalyticsButtonComponent>
    );
};

SkipIntroButtonComponent.propTypes = {
    skipDur: PropTypes.number.isRequired,
    onClickFn: PropTypes.func.isRequired,
    ctaText: PropTypes.string.isRequired,
    showSkipCredit: PropTypes.bool.isRequired,
    currentTime: PropTypes.string.isRequired,
    getSkipDurationMeta: PropTypes.func.isRequired,
};

export default React.memo(SkipIntroButtonComponent);
