import { ThemeActions } from './ThemeActions';

const initialState = {
    configs: {},
};

const ThemeReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case ThemeActions.THEME_CONFIG_SUCCESS:
            changes = {
                ...action.payload,
                fetching: false,
                error: null,
            };
            break;
        case ThemeActions.THEME_CONFIG_FETCH:
            changes = {
                fetching: true,
            };
            break;

        case ThemeActions.THEME_CONFIG_ERROR:
            changes = {
                ...action.payload,
            };
            break;

        default: return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default ThemeReducer;
