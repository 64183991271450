import {
    call, put, takeEvery, getContext,
} from 'redux-saga/effects';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';

import ArtistAction, { artistDetailFetchSuccess, artistDetailFetchError } from './ArtistAction';
import { getArtistDetails } from '../../service/end-points/PeopleApiService';
import { isOnline } from '../../utilities/WindowUtil';

function* fetchArtistDetails(action) {
    const { artistId } = action;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const artistDetails = yield call(getArtistDetails, {
            artistId,
            deviceUtil,
        });

        yield put(artistDetailFetchSuccess({
            artistData: {
                [artistId]: {
                    ...artistDetails,
                    errorCode: null,
                },
            },
        }));
    }
    catch (e) {
        console.error('people not found', e);
        const { data } = e;

        // let errorCode = data ? data.errorcode : e;
        if (!isOnline()) {
            data.errorCode = ERROR_CODES.LOCAL1002;
        }

        yield put(artistDetailFetchError(
            { artistData: { [artistId]: { data } } },
        ));
    }
}

export default [
    takeEvery(ArtistAction.ARTIST_DETAIL_FETCH, fetchArtistDetails),
];
