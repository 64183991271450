import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import 'lazysizes';
import { setCustomTimeout, clearCustomTimeout } from '@airtel-tv/utils/TimeoutUtil';
import { timeoutIds } from '@airtel-tv/utils/TimeoutUtil';

const LazyLoader = (props) => {
    const {
        pictureSettings,
        alt,
        defaultImageWidth,
        quality,
        transparent,
        showAltTag,
        className,
        src,
        getImageUrl,
        isBot,
        imageChanged,
        shouldLazyLoad,
        hideAltTag,
        onImageLoaded,
        imageStyle,
        fadeInEffect,
    } = props;
    const timerRef = useRef();
    const fadeInTransitionDuration = 100;
    const [
        imgSrc,
        setImgSrc,
    ] = useState();
    const [
        pictureSettingsState,
        setPictureSettingsState,
    ] = useState(pictureSettings);
    const [
        imageOpacity,
        setImageOpacity,
    ] = useState(0);
    const [
        imageError,
        setImageError,
    ] = useState(false);

    function onImageError() {
        setImageError(true);
        onImageLoaded && onImageLoaded(false);
        showAltTag();
    }

    function onImageLoad() {
        setImageError(false);
        onImageLoaded && onImageLoaded(true);
        hideAltTag();
        if (fadeInEffect) {
            timerRef.current = setTimeout(() => {
                setImageOpacity(1);
            }, fadeInTransitionDuration * 1.1);
        }
    }

    useEffect(() => {
        if (timerRef.current) {
            clearCustomTimeout(timeoutIds.IMAGE_LAZY_TIMER);
        }
        if (onImageLoaded) {
            onImageLoaded(false);
        }
    }, [
        imgSrc,
    ]);
    useEffect(() => {
        const img = getImageUrl(src, defaultImageWidth || 200, quality, transparent);

        if (fadeInEffect) {
            setImageOpacity(0);
            setCustomTimeout(timeoutIds.IMAGE_LAZY_TIMER, () => {
                setImgSrc(img);
                setPictureSettingsState(pictureSettings);
            }, fadeInTransitionDuration);
        }
        else {
            setImgSrc(img);
            setPictureSettingsState(pictureSettings);
        }
    }, [
        src,
    ]);

    if (isBot) {
        return (
            <picture
                onLoad={onImageLoad}
                onError={onImageError}
            >
                {pictureSettings}
                <img
                    src={imgSrc}
                    className={className}
                    alt={alt}
                    style={imageStyle}
                />
            </picture>
        );
    }
    return (
        <picture
            onLoad={onImageLoad}
            onError={onImageError}
            className="hidden-alt"
            draggable="false"
            style={{
                ...fadeInEffect
                    ? {
                        transitionProperty: 'opacity',
                        transitionTimingFunction: 'ease-in',
                        transitionDuration: `${fadeInTransitionDuration}ms`,
                        opacity: imageOpacity,
                    } : {},
                ...imageError ? { display: 'none' } : {},
            }}
        >
            {
                pictureSettingsState
            }
            {
                !imageChanged ? (
                    <img
                        {...(shouldLazyLoad ? { 'data-src': imgSrc } : { src: imgSrc })}
                        className={`${className} ${shouldLazyLoad ? 'lazyload' : ''}`}
                        draggable="false"
                        alt={alt}
                        {...(shouldLazyLoad && checkWindowExist() ? { loading: 'lazy' } : {})}
                        style={imageStyle}
                        draggable="false"
                    />
                )
                    : (
                        <img
                            src={imgSrc}
                            alt={alt}
                            draggable="false"
                            className={className}
                            {...(shouldLazyLoad && checkWindowExist() ? { loading: 'lazy' } : {})}
                            style={imageStyle}
                            draggable="false"
                        />
                    )

            }
        </picture>
    );
};

LazyLoader.defaultProps = {
    className: '',
    pictureSettings: null,
    quality: null,
    transparent: null,
    defaultImageWidth: null,
    shouldLazyLoad: true,
    onImageLoaded: null,
    imageStyle: {},
    fadeInEffect: false,
};

LazyLoader.propTypes = {
    pictureSettings: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    alt: PropTypes.string.isRequired,
    quality: PropTypes.number,
    transparent: PropTypes.bool,
    className: PropTypes.string,
    showAltTag: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    getImageUrl: PropTypes.func.isRequired,
    isBot: PropTypes.bool.isRequired,
    imageChanged: PropTypes.bool.isRequired,
    defaultImageWidth: PropTypes.number,
    shouldLazyLoad: PropTypes.bool,
    hideAltTag: PropTypes.func.isRequired,
    onImageLoaded: PropTypes.func,
    imageStyle: PropTypes.object,
    fadeInEffect: PropTypes.bool,
};


export default React.memo(LazyLoader);
