import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import SeekBarComponent from '../player-controls/SeekBarComponent';
import { secondsTohhmmss } from '@airtel-tv/utils/GlobalUtil';
import { CONTROL_BAR_EVENTS } from '@airtel-tv/constants/PlayerConsts';

class BottomToolbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seekBarVisibility: true,
        };
        this.progressBarRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let changes = {};

        const {
            duration,
            currentTime,
            bufferedTime,
            seekBarChange,
            fullScreen,
        } = nextProps;

        if (
            duration !== prevState.duration
            || currentTime !== prevState.currentTime
            || bufferedTime !== prevState.bufferedTime
            || seekBarChange !== prevState.seekBarChange
            || fullScreen !== prevState.fullScreen
        ) {
            changes = {
                duration,
                currentTime,
                bufferedTime,
                seekBarChange,
                fullScreen,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    componentDidMount() {
        this.setControlBarEvents();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }

    componentWillUnmount() {
        this.disposeControlBarEvents();
    }

    getProgressBarRef = () => this.progressBarRef

    setControlBarEvents = () => {
        const { controlBarEventEmitter } = this.props;
        controlBarEventEmitter.on(CONTROL_BAR_EVENTS.UPDATE_SEEK_BAR_VISIBILITY, this.setSeekBarVisibility);
    }

    disposeControlBarEvents = () => {
        const { controlBarEventEmitter } = this.props;
        controlBarEventEmitter.off(CONTROL_BAR_EVENTS.UPDATE_SEEK_BAR_VISIBILITY, this.setSeekBarVisibility);
    };

    setSeekBarVisibility = (event) => {
        this.setState({
            seekBarVisibility: event,
        });
    }

    render() {
        const {
            duration,
            currentTime,
            bufferedTime,
            seekBarChange,
            pause,
            play,
            isPaused,
            previewImageInfo,
            fullScreen,
            setSeekPauseStatus,
            adCuePoints,
        } = this.props;

        const { seekBarVisibility } = this.state;

        const hasDuration = duration && !Number.isNaN(duration);
        if (!hasDuration || !seekBarVisibility) {
            return null;
        }

        const seekBarElement = (
            <SeekBarComponent
                max={duration}
                currentTime={currentTime}
                progress={bufferedTime}
                onChange={seekBarChange}
                pause={pause}
                play={play}
                isPaused={isPaused}
                previewImageInfo={previewImageInfo}
                getProgressBarRef={this.getProgressBarRef}
                setSeekPauseStatus={setSeekPauseStatus}
                adCuePoints={adCuePoints}
            />
        );

        const videoDurationElement = <span>{secondsTohhmmss(duration)}</span>;

        const currentTimeElement = <span>{secondsTohhmmss(Math.floor(currentTime))}</span>;
        return (
            <div
                className={`vdo-player__bottom-controls d-flex justify-content-between align-items-center${!fullScreen ? ' vdo-mobile-seekbar-default' : ''}`}
            >
                <div
                    id="seekbar-current-time"
                    className="vdo-player__time text-left d-md-block"
                >
                    {currentTimeElement}
                </div>
                <div
                    className="vdo-player__progressbar"
                    ref={this.progressBarRef}
                >
                    <div className="slider">{seekBarElement}</div>
                </div>
                <div
                    id="seekbar-end-time"
                    className="vdo-player__time text-right d-md-block"
                >
                    {videoDurationElement}
                </div>
            </div>
        );
    }
}

export default BottomToolbarComponent;

BottomToolbarComponent.propTypes = {
    duration: PropTypes.number,
    currentTime: PropTypes.number,
    bufferedTime: PropTypes.number,
    seekBarChange: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    isPaused: PropTypes.func.isRequired,
    previewImageInfo: PropTypes.shape({
        imageUrl: PropTypes.string,
        imageInterval: PropTypes.string,
    }),
    controlBarEventEmitter: PropTypes.shape({
        on: PropTypes.func.isRequired,
        off: PropTypes.func.isRequired,
        emit: PropTypes.func.isRequired,
    }),
    fullScreen: PropTypes.bool.isRequired,
    setSeekPauseStatus: PropTypes.func,
    adCuePoints: PropTypes.array,
};


BottomToolbarComponent.defaultProps = {
    duration: 0,
    currentTime: 0,
    bufferedTime: 0,
    previewImageInfo: null,
    adCuePoints: [],
    setSeekPauseStatus: () => {},
    controlBarEventEmitter: {
        on: () => {},
        off: () => {},
        emit: () => {},
    },
};
