import lodashGet from 'lodash/get';
import { LANGUAGES_CODES } from '@airtel-tv/constants/GlobalConst';
import { getStore } from '../redux/Store';


export default function LanguageCodesProvider() {
    const store = getStore && getStore() ? getStore().getState() : null;

    if (store && lodashGet(store, 'appConfig.web_languages_codes')) {
        return store.appConfig.web_languages_codes;
    }
    return LANGUAGES_CODES;
}
