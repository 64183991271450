import React from 'react';
import PropTypes from 'prop-types';

const SmallLoaderComponent = React.forwardRef((props, ref) => {
    const {
        isHomePage = false, showRailSkeleton = false, style = {}, key = '',
    } = props;
    console.log(`SmallLoaderComponent render ${showRailSkeleton}`);
    if (isHomePage) {
        return (
            <div>
                <div className=" smallbox shimmer" />
                <div
                    className="d-flex medium-container"
                    id="loader"
                    {...props}
                    ref={ref}
                >
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                </div>
            </div>
        );
    }
    if (showRailSkeleton) {
        return (
            <div className="single-tile-rail small-loader">
                <div className="single-rail-title" />
                <div className="single-tile-box" />
                <div className="single-tile-box" />
                <div className="single-tile-box" />
                <div className="single-tile-box" />
                <div className="single-tile-box" />
                <div className="single-tile-box" />
                <div className="single-tile-box" />
            </div>
        );
    }
    return (
        <div
            className="loader"
            id="loader"
            style={style}
            key={key}
            ref={ref}
        />
    );
    // <div class="lds-ellipsis" id="loader" {...props} ref={ref}><div></div><div></div><div></div><div></div></div>
});

SmallLoaderComponent.propTypes = {
    isHomePage: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.string),
    key: PropTypes.string,
};

SmallLoaderComponent.defaultProps = {
    style: {},
    key: '',
    isHomePage: false,
};

export { SmallLoaderComponent };
