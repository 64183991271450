import React from 'react';
import PropTypes from 'prop-types';
import {
    addListenerToEvent, documentAddEventListener, removeListenerFromEvent, documentRemoveEventListener,
} from '@airtel-tv/utils/WindowUtil';
import { PREVIEW_IMAGE_GAP } from '@airtel-tv/constants/PlayerConsts';

// TODO: create sperate util for event handling
const FULL_SCREEN_EVENTS = [
    '',
    'webkit',
    'moz',
    'ms',
];

class SeekBarComponent extends React.Component {
    seeking = false;

    mobileSeeking = false;

    constructor(props) {
        super(props);

        this.hoverTime = React.createRef();

        this.state = {
            trackWidth: 0,
            seekHoverPosition: 0,
        };

        this.isPausedBeforeSeekStart = undefined;
        this.unmountStarted = false;

        this.previewImageRef = React.createRef();
    }

    componentDidMount() {
        this.setTrackWidthState();
        addListenerToEvent('resize', this.setTrackWidthState);
        FULL_SCREEN_EVENTS.forEach(
            prefix => documentAddEventListener(`${prefix}fullscreenchange`, this.setTrackWidthState, false),
        );

        addListenerToEvent('mousemove', this.handleSeeking);
        addListenerToEvent('mouseup', this.mouseSeekingHandler);
        addListenerToEvent('touchmove', this.handleTouchSeeking);
        addListenerToEvent('touchend', this.mobileTouchSeekingHandler);
    }

    componentDidUpdate(prevProps) {
        const { adCuePoints } = prevProps;
        if (this.props?.adCuePoints !== adCuePoints) {
            this.setTrackWidthState();
        }
    }

    componentWillUnmount() {
        removeListenerFromEvent('resize', this.setTrackWidthState);
        FULL_SCREEN_EVENTS.forEach(
            prefix => documentRemoveEventListener(`${prefix}fullscreenchange`, this.setTrackWidthState, false),
        );

        removeListenerFromEvent('mousemove', this.handleSeeking);
        removeListenerFromEvent('mouseup', this.mouseSeekingHandler);
        removeListenerFromEvent('touchmove', this.handleTouchSeeking);
        removeListenerFromEvent('touchend', this.mobileTouchSeekingHandler);
        this.unmountStarted = true;
    }

    setTrackWidthState = () => {
        if (!this.track) {
            return;
        }
        let { adCuePoints = [] } = this.props;
        let gradientString;
        if ((adCuePoints || []).length) {
            adCuePoints = adCuePoints.filter(item => !item.alreadyPlayed && item.start != -1).map(item => (+(item.start * 100 / this.props.max).toFixed(2)));
            const transparent = `rgba(255, 255, 255, 0.2)`;
            const markerColor = `rgb(255, 204, 0)`
            gradientString = `linear-gradient(to right, ${transparent} 0%`;
            adCuePoints.map((item) => {
                //end transparent at item%, start color from item%,countinue color till itrm + 0.5%, start transparent from item + 0.5%
                gradientString += `,${transparent} ${item}%, ${markerColor} ${item}%, ${markerColor} ${item + 0.5}%`;
                gradientString += `,${transparent} ${item + 0.5}%`;
            });
            gradientString += ',rgba(255, 255, 255, 0.2) 100%)';
        }

        if (gradientString) {
            document.getElementById('atm_main-seekbar').style.background = gradientString;
        }

        const { offsetWidth } = this.track;
        if (this.track && offsetWidth > 0) {
            if (!this.unmountStarted) {
                this.setState({
                    trackWidth: offsetWidth,
                });
            }
        }
        else {
            console.debug(offsetWidth, this.track.getBoundingClientRect());
        }
    };

    handleTrackHover = (clear, e) => {
        if (!this.track) {
            return;
        }

        let position = e.pageX - this.track.getBoundingClientRect().left;

        if (clear) {
            position = 0;
        }

        if (!this.unmountStarted) {
            this.setState({
                seekHoverPosition: position,
            });
        }
    };

    getPositionStyle = (time) => {
        const { max } = this.props;
        const position = time * 100 / max;

        return {
            transform: `scaleX(${position / 100})`,
        };
    };

    getThumbHandlerPosition = () => {
        const { max, currentTime } = this.props;
        const { trackWidth } = this.state;
        const position = trackWidth / (max / currentTime);

        return {
            transform: `translateX(${position}px)`,
        };
    };

    getSeekHoverPosition = () => {
        const { trackWidth, seekHoverPosition } = this.state;
        const position = seekHoverPosition * 100 / trackWidth;

        return {
            transform: `scaleX(${position / 100})`,
        };
    };

    getHoverTimePosition = () => {
        let position = 0;
        const { trackWidth, seekHoverPosition } = this.state;
        const { limitTimeTooltipBySides, getProgressBarRef } = this.props;
        const { offsetWidth } = this.hoverTime;
        if (this.hoverTime) {
            position = seekHoverPosition - offsetWidth / 2;

            if (limitTimeTooltipBySides) {
                if (position < 0) {
                    position = 0;
                }
                else if (position + offsetWidth > trackWidth) {
                    position = trackWidth - offsetWidth;
                }
            }

            // get progressBar elem from parent bottom toolbar component
            const progressBarRef = getProgressBarRef();
            if (progressBarRef && progressBarRef.current) {
                // get container element of seekBar and get left margin/padding value.
                // right offset is same.
                const { offsetLeft } = progressBarRef.current;
                // if: so that it does not gets out of screen from left side
                // else if: so that it does not gets out of screen from right side
                if (offsetLeft + position < 0) {
                    position = (-1 * offsetLeft);
                }
                else if (position + offsetWidth - offsetLeft > trackWidth) {
                    position = trackWidth - offsetWidth + offsetLeft;
                }
            }
        }

        return {
            transform: `translateX(${position}px)`,
        };
    };

    secondsToTime = (seconds) => {
        const { offset } = this.props;
        const secondsTemp = Math.round(seconds + offset);

        const hours = Math.floor(secondsTemp / 3600);
        const divirsForMinutes = secondsTemp % 3600;
        const minutes = Math.floor(divirsForMinutes / 60);
        const sec = Math.ceil(divirsForMinutes % 60);

        return {
            hh: hours.toString(),
            mm: minutes < 10 ? `0${minutes}` : minutes.toString(),
            ss: sec < 10 ? `0${sec}` : sec.toString(),
        };
    };

    getHoverTime = () => {
        const { seekHoverPosition, trackWidth } = this.state;
        const {
            secondsPrefix, minutesPrefix, offset, max,
        } = this.props;

        const percent = seekHoverPosition * 100 / trackWidth;
        const time = Math.floor(+(percent * (max / 100)));
        const times = this.secondsToTime(time);

        if ((max + offset) < 60) {
            return ({
                hoverTime: secondsPrefix + (times.ss),
                hoverTimeInSec: time,
            });
        }
        if ((max + offset) < 3600) {
            return ({
                hoverTime: `${minutesPrefix + times.mm}:${times.ss}`,
                hoverTimeInSec: time,
            });
        }
        return ({
            hoverTime: `${times.hh}:${times.mm}:${times.ss}`,
            hoverTimeInSec: time,
        });
    };

    handleSeeking = (event) => {
        if (this.seeking) {
            this.changeCurrentTimePosition(event.pageX);
        }
    };

    handleTouchSeeking = (event) => {
        let pageX = 0;

        const { changedTouches } = event;
        for (let i = 0; i < changedTouches.length; i++) {
            pageX = changedTouches[i].pageX; // eslint-disable-line prefer-destructuring
        }

        pageX = pageX < 0 ? 0 : pageX;

        if (this.mobileSeeking) {
            this.changeCurrentTimePosition(pageX);
        }
    };

    mouseSeekingHandler = (event) => {
        this.setSeeking(false, event);
    };

    setSeeking = (state, event) => {
        this.beforeSeekStart(state);

        event.preventDefault();
        const { seekHoverPosition } = this.state;
        this.handleSeeking(event);
        this.seeking = state;

        if (!this.unmountStarted) {
            this.setState({
                seekHoverPosition: !state ? 0 : seekHoverPosition,
            });
        }
    };

    mobileTouchSeekingHandler = () => {
        this.setMobileSeeking(false);
    };

    setMobileSeeking = (state) => {
        this.beforeSeekStart(state);

        const { seekHoverPosition } = this.state;
        this.mobileSeeking = state;

        if (!this.unmountStarted) {
            this.setState({
                seekHoverPosition: !state ? 0 : seekHoverPosition,
            });
        }
    };

    isThumbActive = () => {
        const { seekHoverPosition } = this.state;
        return seekHoverPosition > 0 || this.seeking;
    };

    changeCurrentTimePosition = (pageX) => {
        if (!this.track) {
            return;
        }

        const { max, onChange, offset } = this.props;
        const { trackWidth } = this.state;
        let position = pageX - this.track.getBoundingClientRect().left;

        position = position < 0 ? 0 : position;
        position = position > trackWidth ? trackWidth : position;

        if (!this.unmountStarted) {
            this.setState({
                seekHoverPosition: position,
            });
        }

        const percent = position * 100 / trackWidth;
        const time = +(percent * (max / 100)).toFixed(0);

        onChange(time, (time + offset));
    };

    previewImageCSS = (hoverTimeInSec, imageSize) => {
        const { previewImageInfo: { imageInterval, imageUrl }, max } = this.props;

        // background position and size depending on number of images and size of 1 image.
        const curImageIndex = Math.floor(hoverTimeInSec / imageInterval);

        // total images is recalculated because in case of catchup the media file gives incorrect
        // duration of media.
        const totalImages = Math.floor(max / imageInterval);
        const backgroundPositionX = -(imageSize + PREVIEW_IMAGE_GAP) * curImageIndex;
        const backgroundSizeX = (imageSize + PREVIEW_IMAGE_GAP) * totalImages;


        return {
            backgroundPositionX: `${backgroundPositionX}px`,
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: `${backgroundSizeX}px ${imageSize}px`,
        };
    }

    drawHoverTime = () => {
        const { hideHoverTime, previewImageInfo } = this.props;
        let imageContainerSize = 0;
        let hoverDefaultClass = '';
        let previewCss = {};


        // get width of the box containing the image
        if (this.previewImageRef && this.previewImageRef.current) {
            imageContainerSize = this.previewImageRef.current.offsetWidth;
        }

        if (!hideHoverTime) {
            const { hoverTime, hoverTimeInSec } = this.getHoverTime();

            // IF Preview Image available
            if (previewImageInfo) {
                previewCss = this.previewImageCSS(hoverTimeInSec, imageContainerSize);
                hoverDefaultClass = 'hover-time-with-preview ';
            }

            return (
                <div
                    id="atm_seekbar-hover-time"
                    className={this.isThumbActive() && hoverTime !== '0:00:00' ? `${hoverDefaultClass}hover-time active` : `${hoverDefaultClass}hover-time`}
                    style={this.getHoverTimePosition()}
                    ref={(ref) => {
                        this.hoverTime = ref;
                    }}
                >
                    <div
                        className="preview"
                        style={previewCss}
                        ref={this.previewImageRef}
                    />
                    <div className="preview-time">
                        {hoverTime}
                    </div>
                </div>
            );
        }
        return null;
    };

    beforeSeekStart = (seekState) => {
        const { pause, play, isPaused, setSeekPauseStatus } = this.props;

        if (seekState) {
            this.isPausedBeforeSeekStart = isPaused();
            setSeekPauseStatus(true);
            pause();
        }
        else if (this.isPausedBeforeSeekStart === false) {
            this.isPausedBeforeSeekStart = undefined;
            // seek over :: return to the play state if initally playing
            play();
        }
        else {
            this.isPausedBeforeSeekStart = undefined;
        }
    }

    render() {
        const { progress, currentTime } = this.props;
        return (
            <div className="ui-video-seek-slider">
                <div
                    className={this.isThumbActive() ? 'track active' : 'track'}
                    role="progressbar"
                    tabIndex={0}
                    ref={(ref) => {
                        this.track = ref;
                    }}
                    id="seekbar"
                    onMouseMove={e => this.handleTrackHover(false, e)}
                    onMouseLeave={e => this.handleTrackHover(true, e)}
                    onMouseDown={e => this.setSeeking(true, e)}
                    onTouchStart={() => this.setMobileSeeking(true)}
                >
                    <div
                        id="atm_main-seekbar"
                        className="main"
                    >
                        <div
                            id="atm_seekbar-buffered"
                            className="buffered atm_buffered-seekbar"
                            style={this.getPositionStyle(progress)}
                        />
                        <div

                            className="seek-hover"
                            style={this.getSeekHoverPosition()}
                        />
                        <div
                            className="connect"
                            style={this.getPositionStyle(currentTime)}
                        />
                    </div>
                </div>

                {this.drawHoverTime()}

                <div
                    id="atm_seekbar-hover-thumb"
                    className={`${this.isThumbActive() ? 'thumb active' : 'thumb'}`}
                    style={this.getThumbHandlerPosition()}
                >
                    <div className="handler" />
                </div>
            </div>
        );
    }
}

SeekBarComponent.propTypes = {
    max: PropTypes.number.isRequired,
    currentTime: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    hideHoverTime: PropTypes.bool,
    offset: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    secondsPrefix: PropTypes.string,
    minutesPrefix: PropTypes.string,
    limitTimeTooltipBySides: PropTypes.bool,
    pause: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    isPaused: PropTypes.func.isRequired,
    previewImageInfo: PropTypes.shape({
        imageUrl: PropTypes.string,
        imageInterval: PropTypes.string,
    }),
    getProgressBarRef: PropTypes.func.isRequired,
    setSeekPauseStatus: PropTypes.func,
    adCuePoints: PropTypes.array,
};

SeekBarComponent.defaultProps = {
    hideHoverTime: false,
    offset: 0,
    secondsPrefix: '00:00:',
    minutesPrefix: '00:',
    limitTimeTooltipBySides: false,
    previewImageInfo: null,
    setSeekPauseStatus: () => {},
    adCuePoints: [],
};

export default SeekBarComponent;
