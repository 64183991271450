export const SearchActions = {
    FETCH_SEARCH: 'FETCH_SEARCH',
    PAGINATED_FETCH_SEARCH: 'PAGINATED_FETCH_SEARCH',
    FETCH_SEARCH_SUCCESS: 'FETCH_SEARCH_SUCCESS',
    FETCH_SEARCH_ERROR: 'FETCH_SEARCH_ERROR',
    CLEAR_SEARCH: 'CLEAR_SEARCH',

    ADD_RECENT_SEARCHES: 'ADD_RECENT_SEARCHES',
    CLEAR_RECENT_SEARCHES: 'CLEAR_RECENT_SEARCHES',
    REFRESH_RECENT_SEARCHES: 'REFRESH_RECENT_SEARCHES',

    FETCH_SEARCH_SUGGESTIONS: 'FETCH_SEARCH_SUGGESTIONS',
    CLEAR_SEARCH_SUGGESTIONS: 'CLEAR_SEARCH_SUGGESTIONS',
    FETCH_SEARCH_SUGGESTIONS_SUCCESS: 'FETCH_SEARCH_SUGGESTIONS_SUCCESS',
};

export default SearchActions;

// SUGGESTIONS
export const getSearchSuggestions = payload => ({
    type: SearchActions.FETCH_SEARCH_SUGGESTIONS,
    ...payload,
});

export const getSearchSuggestionsSuccess = payload => ({
    type: SearchActions.FETCH_SEARCH_SUGGESTIONS_SUCCESS,
    ...payload,
});

export const clearSearchSuggestions = () => ({
    type: SearchActions.CLEAR_SEARCH_SUGGESTIONS,
});

// PAGINATED SEARCH
export const getSearchResult = payload => ({
    type: SearchActions.FETCH_SEARCH,
    ...payload,
});

export const getPaginatedSearchResultSuccess = payload => ({
    type: SearchActions.PAGINATED_FETCH_SEARCH,
    ...payload,
});

export const getSearchResultSuccess = payload => ({
    type: SearchActions.FETCH_SEARCH_SUCCESS,
    ...payload,
});

export const getSearchResultError = payload => ({
    type: SearchActions.FETCH_SEARCH_ERROR,
    ...payload,
});

export const addResentSearch = payload => ({
    type: SearchActions.ADD_RECENT_SEARCHES,
    ...payload,
});

export const removeResentSearch = () => ({
    type: SearchActions.CLEAR_RECENT_SEARCHES,
});

export const clearSearch = () => ({
    type: SearchActions.CLEAR_SEARCH,
});

export const refreshRecentSearch = () => ({
    type: SearchActions.REFRESH_RECENT_SEARCHES,
});
