import { MARKUP_TYPES } from '../../../../constants/MarkupConsts';

export default function ({
    name, url, baseUrl, position,
}) {
    const link = baseUrl + url;
    return {
        '@type': MARKUP_TYPES.LIST_ITEM,
        position,
        item:{
            "@id":link,
            name
        }
    }
    // return {
    //     '@type': MARKUP_TYPES.LIST_ITEM,
    //     position,
    //     name,
    //     item: {
    //         '@type': MARKUP_TYPES.THING,
    //         '@id': link,
    //     },
    // };
}
