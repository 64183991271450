import React, { Component } from 'react';
import lodashIsEqual from 'lodash/isEqual';
import { PropTypes } from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import VolumeBarControl from './VolumeBarControl';
import SeekButtonComponent from './SeekButtonComponent';
import { IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';

class LeftPlayerControlsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let changes = {};

        const {
            playing,
            play,
            seekBackward,
            seekForward,
            pause,
            waiting,
            playerFunctions,
            onPlayerFunctionsNotFound,
            enabled,
            showPreRollAds,
            adsManager,
            playingMidRollAds,
            videoEnded
        } = nextProps;

        if (
            playing !== prevState.playing
            || play !== prevState.play
            || seekBackward !== prevState.seekBackward
            || seekForward !== prevState.seekForward
            || pause !== prevState.pause
            || waiting !== prevState.waiting
            || playerFunctions !== prevState.playerFunctions
            || onPlayerFunctionsNotFound !== prevState.onPlayerFunctionsNotFound
            || enabled !== prevState.enabled
            || showPreRollAds !== prevState.showPreRollAds
            || adsManager !== prevState.adsManager
            || playingMidRollAds !== prevState.playingMidRollAds
            || videoEnded !== prevState.videoEnded
        ) {
            changes = {
                playing,
                play,
                seekBackward,
                seekForward,
                pause,
                waiting,
                playerFunctions,
                enabled,
                onPlayerFunctionsNotFound,
                showPreRollAds,
                adsManager,
                playingMidRollAds,
                videoEnded
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }

    onSeekForwardBtnClick = () => {
        const { seekForward, forwardSkipDuration } = this.props;
        if (forwardSkipDuration) {
            seekForward(forwardSkipDuration);
        }
    }

    onSeekBackwardBtnClick = () => {
        const { seekBackward, backwardSkipDuration } = this.props;
        if (backwardSkipDuration) {
            seekBackward(backwardSkipDuration);
        }
    }

    render() {
        const {
            play,
            pause,
            playing,
            playerFunctions,
            onPlayerFunctionsNotFound,
            enabled,
            backwardSkipDuration,
            forwardSkipDuration,
            controlBarEventEmitter,
            showPreRollAds,
            adsManager,
            playingMidRollAds,
            videoEnded
        } = this.props;
        const pausePlaySrc = playing ? IMAGE_PATHS.PAUSE : IMAGE_PATHS.PLAY;

        return (
            <div className="bottom-control-left">
                {videoEnded && 
                    <AnalyticsButtonComponent className="bottom-control-buttons scale-hover" onClick={play} aria-label="Replay"><ThumborImage
                    src={IMAGE_PATHS.REPLAY_BUTTON}
                    imageChanged
                /></AnalyticsButtonComponent>
                }
                {!videoEnded && !(showPreRollAds || playingMidRollAds) && (
                    <div
                        className="btm-control-space-manager"
                    >
                        <AnalyticsButtonComponent
                            id="player-play-btn"
                            type="button"
                            onClick={playing ? pause : play}
                            className="bottom-control-buttons scale-hover"
                            aria-label="Play Button"
                        >
                            <ThumborImage
                                src={pausePlaySrc}
                                imageChanged
                            />
                        </AnalyticsButtonComponent>
                    </div>
                )}
                {!(showPreRollAds || playingMidRollAds) && (
                      <SeekButtonComponent
                      id="player-backward-btn"
                      onButtonClick={this.onSeekBackwardBtnClick}
                      isClickable={!!backwardSkipDuration}
                      imageActiveSrc={IMAGE_PATHS.BACKWARD_SEEK}
                      imageDisabledSrc={IMAGE_PATHS.BACKWARD_SEEK}
                      key="backward-skip"
                  />
                )}
                {!(showPreRollAds || playingMidRollAds) && (

                    <SeekButtonComponent
                    id="player-forward-btn"
                    onButtonClick={this.onSeekForwardBtnClick}
                    isClickable={!!forwardSkipDuration}
                    imageActiveSrc={IMAGE_PATHS.FORWARD_SEEK}
                    imageDisabledSrc={IMAGE_PATHS.FORWARD_SEEK}
                    key="forward-skip"
                    />   
                   
                )}
                <VolumeBarControl
                    playerFunctions={playerFunctions}
                    onPlayerFunctionsNotFound={onPlayerFunctionsNotFound}
                    enabled={enabled}
                    adsManager={adsManager}
                    showPreRollAds={showPreRollAds}
                    playingMidRollAds={playingMidRollAds}
                    controlBarEventEmitter={controlBarEventEmitter}
                />

            </div>

        );
    }
}

LeftPlayerControlsContainer.propTypes = {
    play: PropTypes.func.isRequired,
    seekBackward: PropTypes.func.isRequired,
    seekForward: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    playing: PropTypes.bool.isRequired,
    waiting: PropTypes.bool.isRequired,
    isLiveStream: PropTypes.bool.isRequired,
    forwardSkipDuration: PropTypes.number.isRequired,
    backwardSkipDuration: PropTypes.number.isRequired,
    playbackAdsData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    playerFunctions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    enabled: PropTypes.bool.isRequired,
    adsManager: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showPreRollAds: PropTypes.bool.isRequired,
    playingMidRollAds: PropTypes.bool.isRequired,
    onPlayerFunctionsNotFound: PropTypes.func.isRequired,
    controlBarEventEmitter: PropTypes.shape({
        on: PropTypes.func.isRequired,
        off: PropTypes.func.isRequired,
        emit: PropTypes.func.isRequired,
    }),
};

LeftPlayerControlsContainer.defaultProps = {
    controlBarEventEmitter: {
        on: () => {},
        off: () => {},
        emit: () => {},
    },
};

export default LeftPlayerControlsContainer;
