import React from 'react';

function ReplayIconSVGComponent() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
        >
            <path
                d="M22.8086 17.5733L15.6087 12.0734C15.548 12.027 15.4786 12.0018 15.4075 12.0001C15.3365 11.9984 15.2664 12.0205 15.2044 12.0639C15.1424 12.1074 15.0908 12.1707 15.0549 12.2473C15.0189 12.324 15 12.4112 15 12.5001V23.4999C15 23.5888 15.0189 23.676 15.0549 23.7527C15.0908 23.8293 15.1424 23.8926 15.2044 23.9361C15.2664 23.9795 15.3365 24.0016 15.4075 23.9999C15.4786 23.9982 15.548 23.973 15.6087 23.9266L22.8086 18.4267C22.867 18.382 22.9153 18.3193 22.9489 18.2446C22.9824 18.1699 23 18.0857 23 18C23 17.9143 22.9824 17.8301 22.9489 17.7554C22.9153 17.6807 22.867 17.618 22.8086 17.5733Z"
                fill="#E8EAED"
            />
            <path
                d="M18 3C26.2708 3 33 9.72923 33 18C33 26.2708 26.2708 33 18 33C9.72923 33 3 26.2708 3 18H5.30769C5.30769 25.0223 10.9777 30.6923 18 30.6923C25.0223 30.6923 30.6923 25.0223 30.6923 18C30.6923 10.9777 25.0223 5.30769 18 5.30769C13.5288 5.30769 9.62077 7.59692 7.36269 11.0769H12.2308V13.3846H4.15385V5.30769H6.46154V8.445C9.21115 5.12769 13.3615 3 18 3Z"
                fill="#E8EAED"
            />
        </svg>
    );
}
export default ReplayIconSVGComponent;
