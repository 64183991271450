import React from 'react';
import { PropTypes } from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';
import { CENTER_ICONS } from '@airtel-tv/constants/PlayerConsts';

const CenterIconComponent = React.forwardRef((props, ref) => {
    const { centerIconRef } = ref;
    const { level, fullScreen, option } = props;
    let volumeSrc = '';
    if (level === 0) {
        volumeSrc = IMAGE_PATHS.VOLUME_ZERO_ICON;
    }
    else if (level > 0 && level < 0.5) {
        volumeSrc = IMAGE_PATHS.VOLUME_LOW_ICON;
    }
    else {
        volumeSrc = IMAGE_PATHS.VOLUME_HIGH_ICON;
    }
    let centerIconHTML = null;
    switch (option) {
        case CENTER_ICONS.VOLUME:
            centerIconHTML = (
                <>
                    <ThumborImage
                        src={volumeSrc}
                        alt=""
                        imageChanged
                    />
                    <div className="volume-text">
                        {`${Math.floor(level * 100)}%`}
                    </div>
                </>
            );
            break;
        case CENTER_ICONS.PAUSE:
            centerIconHTML = (
                <ThumborImage
                    src={IMAGE_PATHS.PLAY}
                    className="center-play-pause-icon"
                    imageChanged
                />
            );
            break;
        case CENTER_ICONS.PLAY:
            centerIconHTML = (
                <ThumborImage
                    src={IMAGE_PATHS.PAUSE}
                    className="center-play-pause-icon"
                    imageChanged
                />
            );
            break;
        default:
            centerIconHTML = null;
    }
    return (
        <div
            ref={centerIconRef}
            className={`volume-text-wrapper  d-none${fullScreen ? ' ' : ''} ${option !== CENTER_ICONS.VOLUME ? ' play-pause-center-control' : ''}`}
        >
            <div
                className="volume-background"
            >
                {centerIconHTML}
            </div>
        </div>
    );
});

CenterIconComponent.propTypes = {
    level: PropTypes.number.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    option: PropTypes.string.isRequired,
};

export default React.memo(CenterIconComponent);
