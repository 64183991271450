export const AppActions = {
    APP_CONFIG_SUCCESS: 'APP_CONFIG_SUCCESS',
    APP_CONFIG_INIT: 'APP_CONFIG_INIT',
    APP_INIT_COMPLETE: 'APP_INIT_COMPLETE',

    FAQ_FETCH: 'FAQ_FETCH',
    FAQ_FETCH_COMPLETED: 'FAQ_FETCH_COMPLETED',
    FAQ_FETCH_ERROR: 'FAQ_FETCH_ERROR',

    APP_START: 'APP_START',

    ADS_DATA_INIT: 'ADS_DATA_INIT',
    ADS_DATA_SUCCESS: 'ADS_DATA_SUCCESS',
    FETCH_EPOCH: 'FETCH_EPOCH',
    FETCH_EPOCH_SUCCESS: 'FETCH_EPOCH_SUCCESS',
    FETCH_EPOCH_FAILURE: 'FETCH_EPOCH_FAILURE',
    IS_SCOPED_WEBVIEW: 'IS_SCOPED_WEBVIEW',
};

// APP INIT
export const appConfigInit = () => ({
    type: AppActions.APP_CONFIG_INIT,
});

export const appConfigSuccess = payload => ({
    type: AppActions.APP_CONFIG_SUCCESS,
    ...payload,
});

// ADS DATA

export const adsDataInit = () => ({
    type: AppActions.ADS_DATA_INIT,
});

export const adsDataSuccess = payload => ({
    type: AppActions.ADS_DATA_SUCCESS,
    payload: { ...payload },
});

// FAQ INIT
export const fetchStaticInformationText = () => ({
    type: AppActions.FAQ_FETCH,
});

export const fetchStaticInformationTextSuccess = payload => ({
    type: AppActions.FAQ_FETCH_COMPLETED,
    ...payload,
});

export const fetchStaticInformationTextError = payload => ({
    type: AppActions.FAQ_FETCH_ERROR,
    ...payload,
});

export const appStartAction = () => ({
    type: AppActions.APP_START,
});


export const fetchEpoch = payload => ({
    type: AppActions.FETCH_EPOCH,
    ...payload,
});

export const fetchEpochSuccess = payload => ({
    type: AppActions.FETCH_EPOCH_SUCCESS,
    ...payload,
});

export const setIsScopedWebview = payload => ({
    type: AppActions.IS_SCOPED_WEBVIEW,
    ...payload,
});
