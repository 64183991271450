export const SYNC_ACTIONS = {
    SYNC_CONTENT: 'SYNC_CONTENT',
    SYNC_CONTENT_SUCCESS: 'SYNC_CONTENT_SUCCESS',
    SYNC_CONTENT_CLEAR: 'SYNC_CONTENT_CLEAR',
    SYNC_CONTENT_CLEARED: 'SYNC_CONTENT_CLEARED',
    SYNC_CONTENT_ADD: 'SYNC_CONTENT_ADD',
    WATCHLIST_CONTENT_ADD: 'WATCHLIST_CONTENT_ADDED',
    SYNC_CONTENT_REMOVE: 'SYNC_CONTENT_REMOVE',
    WATCHLIST_CONTENT_REMOVE: 'WATCHLIST_CONTENT_REMOVE',
    SYNC_CONTENT_CALCULATED_OUTPUT: 'SYNC_CONTENT_CALCULATED_OUTPUT',
    SYNC_FROM_LOCAL_STORAGE: 'SYNC_FROM_LOCAL_STORAGE',
    ADD_AND_SYNC_CONTENT: 'ADD_AND_SYNC_CONTENT',
    ADD_SYNC_LANGUAGE: 'ADD_SYNC_LANGUAGE',
    // PYW_WATCHLIST_CONTENT_ADD: 'PYW_WATCHLIST_CONTENT_ADD',
    // PYW_WATCHLIST_CONTENT_REMOVE: 'PYW_WATCHLIST_CONTENT_REMOVE',
};

export const syncContentInit = payload => ({
    type: SYNC_ACTIONS.SYNC_CONTENT,
    ...payload,
});

// export const syncContentSuccess = payload => ({
//     type: SYNC_ACTIONS.SYNC_CONTENT_SUCCESS,
//     payload,
// });

export const syncLanguagedetail = payload => ({
    type: SYNC_ACTIONS.ADD_SYNC_LANGUAGE,
    payload,
});

export const syncContentAdd = payload => ({
    type: SYNC_ACTIONS.SYNC_CONTENT_ADD,
    ...payload,
});

export const addAndSyncContent = payload => ({
    type: SYNC_ACTIONS.ADD_AND_SYNC_CONTENT,
    ...payload,
});

export const watchListContentAdd = payload => ({
    type: SYNC_ACTIONS.WATCHLIST_CONTENT_ADD,
    ...payload,
});

export const syncContentRemove = payload => ({
    type: SYNC_ACTIONS.SYNC_CONTENT_REMOVE,
    ...payload,
});

export const watchListContentRemove = payload => ({
    type: SYNC_ACTIONS.WATCHLIST_CONTENT_REMOVE,
    ...payload,
});

export const syncContentClear = payload => ({
    type: SYNC_ACTIONS.SYNC_CONTENT_CLEAR,
    ...payload,
});

export const syncContentCleared = () => ({
    type: SYNC_ACTIONS.SYNC_CONTENT_CLEARED,
});

export const syncFromLocalStore = payload => ({
    type: SYNC_ACTIONS.SYNC_FROM_LOCAL_STORAGE,
    payload,
});

export const syncContentCalculatedOutput = payload => ({
    type: SYNC_ACTIONS.SYNC_CONTENT_CALCULATED_OUTPUT,
    payload,
});

// export const pywWatchlistContentAddAction = payload => ({
//     type: SYNC_ACTIONS.PYW_WATCHLIST_CONTENT_ADD,
//     payload,
// });

// export const pywWatchlistContentRemoveAction = payload => ({
//     type: SYNC_ACTIONS.PYW_WATCHLIST_CONTENT_REMOVE,
//     payload,
// });

export default {
    SYNC_ACTIONS,
    syncContentInit,
    // syncContentSuccess,
    syncContentClear,
    syncLanguagedetail,
};
