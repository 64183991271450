import React, { Component } from 'react';
import lodashGet from 'lodash/get';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import CenterPlayComponent from '../player-controls/CenterPlayComponent';
import CenterPauseComponent from '../player-controls/CenterPauseComponent';
import PlayerLoaderComponent from '../player-controls/PlayerLoaderComponent';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';

class CenterToolbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        let changes = {};

        const {
            playing,
            play,
            backward,
            forward,
            pause,
            waiting,
            isMetaLoaded,
        } = nextProps;

        if (
            playing !== prevState.playing
            || play !== prevState.play
            || backward !== prevState.backward
            || forward !== prevState.forward
            || pause !== prevState.pause
            || waiting !== prevState.waiting
            || isMetaLoaded !== prevState.isMetaLoaded
        ) {
            changes = {
                playing,
                play,
                backward,
                forward,
                pause,
                waiting,
                isMetaLoaded,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps !== nextState;
    }


    render() {
        const {
            playing,
            play,
            backward,
            forward,
            pause,
            showPreRollAds,
            preRollAdsPlaying,
            waiting,
            forwardSkipDuration,
            backwardSkipDuration,
            isMetaLoaded,
        } = this.props;

        // if (waiting) {
        //     // return <PlayerLoaderComponent />;
        //     return null;
        // }
        if (waiting || !isMetaLoaded) {
            return <PlayerLoaderComponent pause={pause} />;
        }
        if (showPreRollAds) {
            const prerollflag = preRollAdsPlaying === null ? true : preRollAdsPlaying;
            if (prerollflag) {
                return (
                    <CenterPauseComponent
                        pause={pause}
                    />
                );
            }
            return (
                <CenterPlayComponent
                    play={play}
                    isMetaLoaded={isMetaLoaded}
                />
            );
        }
        if (!showPreRollAds) {
            if (!playing) {
                return (
                    <CenterPlayComponent
                        play={play}
                        isMetaLoaded={isMetaLoaded}
                    />
                );
            }
        }
        if (!showPreRollAds) {
            if (playing) {
                return (
                    <CenterPauseComponent
                        forwardSkipDuration={forwardSkipDuration}
                        backwardSkipDuration={backwardSkipDuration}
                        backward={backward}
                        pause={pause}
                        forward={forward}
                    />
                );
            }
        }

        return (null);
    }
}

CenterToolbarComponent.propTypes = {
    play: PropTypes.func.isRequired,
    backward: PropTypes.func.isRequired,
    forward: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    playing: PropTypes.bool.isRequired,
    waiting: PropTypes.bool.isRequired,
    showPreRollAds: PropTypes.bool.isRequired,
    preRollAdsPlaying: PropTypes.bool.isRequired,
    isMetaLoaded: PropTypes.bool.isRequired,
    forwardSkipDuration: PropTypes.number.isRequired,
    backwardSkipDuration: PropTypes.number.isRequired,
};
const mapStateToProps = (state) => {
    const {
        playbackDetails,
    } = state;
    const preRollAdsPlaying = lodashGet(playbackDetails, 'preRollAdsPlaying', null);
    return {
        preRollAdsPlaying,
    };
};

export default connect(mapStateToProps, null)(withDeviceUtil(CenterToolbarComponent));
