export const BROWSER_LIST = {
    MICROSOFT_EDGE_BROWSER: 'edge',
    INTERNET_EXPLORER: 'ie',
    CHROME: 'chrome',
    FIREFOX: 'firefox',
    SAFARI: 'safari',
    OPERA: 'opera',
    QIHU_360_BROWSER: '360',
    MAXTHON: 'maxthon',
    SOGOU: 'sogou',
    THEWORLD: 'theworld',
    GREENBROWSER: 'green',
    QQ_BROWSER: 'qq',
    TENCENTTRAVELER: 'tt',
    CHEETAH_MOBILE_INC_BROWSER: 'liebao',
    TAOBAO_BROWSER: 'tao',
    COOLNOVO: 'coolnovo',
    SAAYAA: 'saayaa',
    UC_BROWSER: 'uc',
    BUILD_IN_BROWSER_IN_XIAOMI: 'mi',
    BAIDU_BROWSER: 'baidu',
    BUILD_IN_BROWSER_IN_NOKIA: 'nokia',
    BLACKBERRY: 'blackberry',
    IOS_WEBVIEW: 'webview',
    YANDEX_YABROWSER: 'yandex',
    WECHAT_IN_BUILT_BROWSER: 'micromessenger',
    ALI_AP_BROWSER: 'ali-ap',
    ALI_AP_PD_BROWSER: 'ali-ap-pd',
    ALI_AM_BROWSER: 'ali-am',
    ALI_TB_BROWSER: 'ali-tb',
    ALI_TB_PD_BRWOSER: 'ali-tb-pd',
    ALI_TM_BROWSER: 'ali-tm',
    ALI_TM_PD_BROWSER: 'ali-tm-pd',
};

export const CRAWLER_BOTS = {
    googlebot: 'googlebot',
    baiduspider: 'baiduspider',
    'baiduspider-image': 'baiduspider-image',
    'baiduspider-video': 'baiduspider-video',
    'baiduspider-news': 'baiduspider-news',
    'baiduspider-favo': 'baiduspider-favo',
    'baiduspider-cpro': 'baiduspider-cpro',
    'baiduspider-ads': 'baiduspider-ads',
    baiduboxapp: 'baiduboxapp',
    bingbot: 'bingbot',
    msnbot: 'msnbot',
    nuhkbot: 'nuhkbot',
    alexabot: 'alexabot',
    curl: 'curl',
    // na: 'na',
};

export const IMAGE_TYPE = {
    WEBP: 'webp',
    JPG: 'jpg',
    PNG: 'png',
    SVG: 'svg',
    GIF: 'gif',
    JSON: 'json',
};

export const IMAGE_FORMAT = {
    [BROWSER_LIST.CHROME]: IMAGE_TYPE.WEBP,
    [BROWSER_LIST.FIREFOX]: IMAGE_TYPE.WEBP,
    [BROWSER_LIST.SAFARI]: IMAGE_TYPE.JPG,
};

export const DRM_LIST = {
    WIDEVINE: 'com.widevine.alpha',
    FAIRPLAY: 'com.apple.fps.1_0',
    NON_DRM: 'NON_DRM',
};

export const OS_LIST = {
    OSX: 'macosx',
    WINDOWS: 'windows',
    LINUX: 'linux',
    ANDROID: 'android',
    IOS: 'ios',
    CHROME_OS: 'chromeos',
    WINDOW_PHONE: 'wp',
    WINDOWS_CE__WINDOWS_MOBILE__SMARTPHONE_PPC: 'windowsce',
    SYMBIAN: 'symbian',
    BLACKBERRY: 'blackberry',
    ALIYUN_OS: 'yunos',
    NA: 'na',
};

export const DESKTOP_OS_LIST = [
    OS_LIST.OSX,
    OS_LIST.WINDOWS,
    OS_LIST.LINUX,
];

export const HARDWARE_LIST = {
    ALIYUN: 'aliyun',
    ANDROID: 'android',
    BLACKBERRY: 'blackberry',
    COOLPAD: 'coolpad',
    HTC: 'htc',
    HUAWEI: 'huawei',
    IPAD: 'ipad',
    IPHONE: 'iphone',
    IPOD: 'ipod',
    KONKA: 'konka',
    LENOVO: 'lenovo',
    LG: 'lg',
    MAC: 'mac',
    MEIZU: 'meizu',
    MI: 'mi',
    NEXUS: 'nexus',
    NOKIA: 'nokia',
    OPPO: 'oppo',
    PC: 'pc',
    SAMSUNG: 'samsung',
    SONYERICSSON: 'sonyericsson',
    VIVO: 'vivo',
    WP: 'wp',
    ZTE: 'zte',
};

export const PLAYBACKS_FORMATS = {
    DASH: 'DASH',
    HLS: 'HLS',
    MP4: 'MP4',
};

export const DRM_SUPPORT = {
    [DRM_LIST.WIDEVINE]: {
        [OS_LIST.OSX]: {
            [BROWSER_LIST.CHROME]: {
                leastSupportedVersion: '42',
            },
            [BROWSER_LIST.FIREFOX]: {
                leastSupportedVersion: '32',
            },
        },
        [OS_LIST.WINDOWS]: {
            [BROWSER_LIST.CHROME]: {
                leastSupportedVersion: '42',
            },
            [BROWSER_LIST.FIREFOX]: {
                leastSupportedVersion: '32',
            },
        },
        [OS_LIST.LINUX]: {
            [BROWSER_LIST.CHROME]: {
                leastSupportedVersion: '42',
            },
            [BROWSER_LIST.FIREFOX]: {
                leastSupportedVersion: '32',
            },
        },
        [OS_LIST.ANDROID]: {
            [BROWSER_LIST.CHROME]: {
                leastSupportedVersion: '70',
            },
            [BROWSER_LIST.FIREFOX]: {
                leastSupportedVersion: '63',
                settingsToggle: true, // to use widevine drm in firefox you have to set drm flag to true manually
            },
        },
        [OS_LIST.IOS]: {},
    },
    [DRM_LIST.FAIRPLAY]: {
        [OS_LIST.OSX]: {
            [BROWSER_LIST.SAFARI]: {
                leastSupportedVersion: '7',
            },
        },
        [OS_LIST.WINDOWS]: {},
        [OS_LIST.LINUX]: {},
        [OS_LIST.ANDROID]: {},
        [OS_LIST.IOS]: {
            [BROWSER_LIST.SAFARI]: {
                leastSupportedVersion: '12',
            },
        },
    },
};

export const STREAM_TYPES = {
    VOD: 'VOD',
    LIVE: 'LIVE',
    SHORT_STREAM: 'SHORT_STREAM',
};

export const BROWSER_PLAYBACK_TYPE = {
    [BROWSER_LIST.MICROSOFT_EDGE_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.INTERNET_EXPLORER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.CHROME]: PLAYBACKS_FORMATS.DASH,
    [BROWSER_LIST.FIREFOX]: PLAYBACKS_FORMATS.DASH,
    [BROWSER_LIST.SAFARI]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.OPERA]: PLAYBACKS_FORMATS.DASH,
    [BROWSER_LIST.QIHU_360_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.MAXTHON]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.SOGOU]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.THEWORLD]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.GREENBROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.QQ_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.TENCENTTRAVELER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.CHEETAH_MOBILE_INC_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.TAOBAO_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.COOLNOVO]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.SAAYAA]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.UC_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.BUILD_IN_BROWSER_IN_XIAOMI]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.BAIDU_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.BUILD_IN_BROWSER_IN_NOKIA]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.BLACKBERRY]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.IOS_WEBVIEW]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.YANDEX_YABROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.WECHAT_IN_BUILT_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.ALI_AP_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.ALI_AP_PD_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.ALI_AM_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.ALI_TB_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.ALI_TB_PD_BRWOSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.ALI_TM_BROWSER]: PLAYBACKS_FORMATS.HLS,
    [BROWSER_LIST.ALI_TM_PD_BROWSER]: PLAYBACKS_FORMATS.HLS,
};

export default {
    BROWSER_LIST,
    DRM_LIST,
    DRM_SUPPORT,
    BROWSER_PLAYBACK_TYPE,
    STREAM_TYPES,
    CRAWLER_BOTS,
    DESKTOP_OS_LIST,
};
