/* eslint-disable no-underscore-dangle */
import { EVENT_SOURCE_NAME, SOS_SOURCE_NAME } from './EventsConst';
import { DEVICES } from './DevicesConst';

export const ROUTE_PATHS_SETTINGS = {
    SETTINGS_HELP_SECTION: '/more/help',
    SETTINGS_HELP_FAQ_SECTION: '/more/help/faq',
    SETTINGS_HELP_TERMS_SECTION: '/more/help/terms',
    SETTINGS_HELP_PRIVACY_SECTION: '/more/help/privacy',
    SETTINGS_HELP_COMMON_SECTION: '/more/help/:redirectUrl',
};


export const _ROUTE_PATHS = {
    [DEVICES.BROWSER]: {
        ROOT: '/',
        LAYOUT: '/:pageTitle?',
        LANDING: '/Landing/page/:implicitPageId/:pageTitle',
        SPORTS_LAYOUT: '/sports/:key?/:type?',
        SETTINGS: '/more',
        SEARCH: '/search',
        NOT_FOUND: '/404',
        SETTINGS_LANGUAGE_SECTION: '/language',
        XSTREAM_PREMIUM_LANDING_PAGE: '/xstreampremium',
        CONTENT_DETAIL_LANDING_PAGE: '/cdp',
        BLOGS: '/blog',
        BLOG_DESCRIPTION: '/blog/:blogId',
        XSTREAM_PREMIUM_NEW_LANDING_PAGE: '/xpp/:planPrice',

        SETTINGS_PAGE: '/settings', // check it


        SUBSCRIPTION_PAGE: '/subscribe/plans',
        SUBSCRIPTION_PAGE_WEBVIEW: '/subscriptions/subscribe/plans',
        SUBSCRIPTION_MY_ACCOUNT_WEBVIEW: '/subscriptions/subscribe/my-account',
        SUBSCRIPTION_ONEHUB_PAYMENT_STATUS: '/subscriptions/subscribe/plans/payment/:status?',
        SUBSCRIPTION_ONEHUB: '/subscriptions/onehub/:ingressIntent?/:cpId?',
        SUBSCRIPTION_PLAN_ACTIVATION: '/subscriptions/plan/activate/:cpId',
        PROMOTION_PAGE_NEW: '/promotions/india-vs-bangladesh',
        XMP_CLAIM_PAGE: '/xmp-claim',
        XMP_MULTIPLE_CLAIM_PAGE: '/xmp-multiple-claim',
        SINGLE_CLAIM_SUCCESS_PAGE: '/single-claim-success',
        MULTIPLE_CLAIM_SUCCESS_PAGE: '/multiple-claim-success',
        CP_PLAN_DETAIL_PAGE: '/subscribe/:cpId',
        PLANS_AND_OFFERS: '/subscribe/plans-and-offers',
        PARTNER_LISTING: '/subscribe/partner-listing',
        MY_PLANS: '/subscribe/my-account',
        SUBSCRIPTION_SUCCESS: '/subscription/success',
        SUBSCRIPTION_FAILURE: '/subscription/failure',
        CONTENT_DETAILS: '/cdp',
        NON_AIRTEL_USER_POPUP: '/non-airtel-user',
        My_NO_PLANS: '/subscribe/my-account/noplan',
        NO_SUBSCRIPTION: '/no-subscription',
        ACTIVE_PLANS: '/active-plans',
        XMP_CLAIM_MODAL: '/xmp-claim-modal',
        SEARCH_PAGE: '/search/:title?',
        ACCOUNT_DELETION: '/account-deletion',
        ARTIST: '/artist/:artistName/:artistId',
        SEARCH_LISTING: '/search/list/:title?',
        LISTING: '/:pageTitle/:title/list/:id',
        LISTING_V2: '/:title/list/:id',
        DIMENSION_GENRE_LANG_PAGE: '/:pageTitle/:lang/:genre/collection',
        DIMENSION_GENRE_OR_LANG_PAGE: '/:pageTitle/:genreOrLang/collection',
        DIMENSION_PROGRAMTYPE_PAGE: '/:pageTitle/collection',
        DIMENSION_PAGE: '/:pageTitle/:category',
        PLAYBACK: '/:category/:contentTitle/:contentId',
        PLAYBACK_CONTAINER: '/playback/:category/:cpId/:contentTitle/:contentId',
        LIVETV_PLAYBACK: '/livetv-channels/:contentTitle/:contentId',
        TV_SEASON_PLAYBACK: '/season/:contentTitle/:seasonTitle/:contentId/:seasonId',
        TV_EPISODE_PLAYBACK: '/episode/:contentTitle/:episodeTitle/:contentId/:seasonId/:episodeId',
        LIVE_TV_EPISODE_PLAYBACK: '/:category/:contentTitle/:seasonTitle/:episodeTitle/:contentId/:episodeId', // contentId: tv show id
        PLAYER: '/play/:contentId',
        TV_SEASON_CONTENT_PLAYBACK: '/:category/:contentTitle/:seasonTitle/:contentId',
        TV_EPISODE_CONTENT_PLAYBACK: '/:category/:contentTitle/:seasonTitle/:episodeTitle/:contentId',

        SUNNXT_PLAYBACK: 'playback/inapp/:cpId/:contentId/',
        // DTH RECHARGE LOGIN
        DTH_CUSTOMER_LOGIN: '/myplan/:param',
        DTH_PAYMENT_PAGE: '/tv/:shortCode',

        SCHEDULE_PAGE: '/:pageTitle/:channelName/schedule/:channelId',
        EPG_SCHEDULE_PAGE: '/live-tv/channel',

        WATCHLIST_PAGE: '/you/watchlist',

        YOU_PAGE: '/you',

        CONTENT_LAYOUT_PAGES: '/:pageTitle/:contentTitle/:pageId/detail',

        PREFERRED_PARTNER_SELECTION: '/select/partner',

        HAPPYCODE: '/happycode/verify',
        HAPPY_CODE_PAGE: '/happycode',
        PROFILE_PAGE: '/you/profile',
        PARTNER_CLAIM: '/partner_claim/:partnerId/:activationId',
        HOICHOI_CAMPAIGN: '/hoichoi/campaign',

        DEEPLINK_LANDING_PAGE: '/subscription-deeplink',
        OFFERS_CHECK: '/offers',

        SITE_MAP: '/siteMap',
        RECHARGE_STATUS_PAGE: '/recharge/status',
        RECHARGE_ONLY: '/subscribe/recharge',
        DTH_SUBSCRIBE: '/subs/:planId',

        // PLANS AND OFFERS
        PLANS_OFFERS_PAGE: '/plans/offers',
        PLANS_PAYMENTS_PAGE: '/plans/payments',

        CP_SUBSCRIPTION_INFO_PAGE: '/plans/subscription',
        SUBSCRIPTION_OFFER: 'subscribe/landing/offers',
        USER_CHAT: '/help/chat',
        FAN_CODE: '/playback/fancode/f1/:contentId',
        QR_CODE_LOGIN: '/tvLogin/:qrId?',
        DIRECT_PLAYBACK_CONTAINER: '/player/:contentTitle/:contentId',
        DISTRO_CHANNELS_LIST: '/distro/:pageTitle',
        AVOD: '/free',
        THANKS_SSO: '/thanks/promotion',
        ONEHUB: '/onehub/:ingressIntent?/:cpId?',
        ONEHUB_PAYMENT_STATUS: '/onehub/payment/:status?',
        // ONEHUB_PLANS_ALL: '/onehub/plans/all',
        PLAN_ACTIVATION: '/plan/activate/:cpId',
        THANKS_SSO_REDIRECT: '/thanks/xstream/:redirectTemplate/:utmCampaign',
    },
    [DEVICES.TV]: {
        ROOT: '/',
        DIRECT_PLAYBACK_CONTAINER: '/player/:contentTitle/:contentId',
        LAYOUT: '/:pageTitle?',
        SPORTS_LAYOUT: '/sports/:key?/:type?',
        LANDING: '/Landing/page/:implicitPageId/:pageTitle',
        SETTINGS: '/more',
        SEARCH: '/search',
        NOT_FOUND: '/404',
        SETTINGS_LANGUAGE_SECTION: '/language',

        SETTINGS_PAGE: '/settings',

        SEARCH_PAGE: '/search/:title?',

        ARTIST: '/artist/:artistName/:artistId',
        SEARCH_LISTING: '/search/list/:title?',

        LISTING: '/:pageTitle/:title/list/:id',
        LISTING_V2: '/:title/list/:id',

        DIMENSION_GENRE_LANG_PAGE: '/:pageTitle/:lang/:genre/collection',
        DIMENSION_GENRE_OR_LANG_PAGE: '/:pageTitle/:genreOrLang/collection',
        DIMENSION_PROGRAMTYPE_PAGE: '/:pageTitle/collection',

        PLAYBACK: '/:category/:contentTitle/:contentId',
        PLAYBACK_CONTAINER: '/playback/:category/:cpId/:contentTitle/:contentId',
        // TV_SEASON_PLAYBACK: '/:category/:contentTitle/:seasonTitle/:contentId',
        // TV_EPISODE_PLAYBACK: '/:category/:contentTitle/:seasonTitle/:episodeTitle/:contentId',
        LIVE_TV_EPISODE_PLAYBACK: '/:category/:contentTitle/:seasonTitle/:episodeTitle/:contentId/:episodeId', // contentId: tv show id
        TV_SEASON_PLAYBACK: '/season/:contentTitle/:seasonTitle/:contentId/:seasonId',
        TV_EPISODE_PLAYBACK: '/episode/:contentTitle/:episodeTitle/:contentId/:seasonId/:episodeId',
        SUNNXT_PLAYBACK: 'playback/inapp/:cpId/:contentId',
        // DTH RECHARGE LOGIN
        DTH_CUSTOMER_LOGIN: '/myplan/:param',
        DTH_PAYMENT_PAGE: '/tv/:shortCode',

        SCHEDULE_PAGE: '/:pageTitle/:channelName/schedule/:channelId',
        EPG_SCHEDULE_PAGE: '/live-tv/channel',

        WATCHLIST_PAGE: '/you/watchlist',

        YOU_PAGE: '/you',

        CONTENT_LAYOUT_PAGES: '/:pageTitle/:contentTitle/:implicitPageId/detail',

        PREFERRED_PARTNER_SELECTION: '/select/partner',

        HAPPYCODE: '/happycode/verify',
        HAPPY_CODE_PAGE: '/happycode',

        // PLANS AND OFFERS
        SUBSCRIPTION_PAGE: '/subscribe/plans',
        PLANS_PAYMENTS_PAGE: '/plans/payments',
        PLANS_OFFERS_PAGE: '/plans/offers',

        CP_SUBSCRIPTION_INFO_PAGE: '/plans/subscription',
        XPP_CLAIM_PAGE: '/xpp/claim',
        ONEHUB: '/onehub/:ingressIntent?/:cpId?',
        ONEHUB_PAYMENT_STATUS: '/onehub/payment/:status?',
        // ONEHUB_PLANS_ALL: '/onehub/plans/all/:ingressIntent?',
        PLAN_ACTIVATION: '/plan/activate/:cpId',
    },
};

export const PAGE_TITLE_LIST = {
    CHANNEL: 'channel',
    PROMOTION: 'event',
    HOME: 'home',
};

export const APP_ROOT_ID = 'root';

export const _ROUTE_SOURCE_NAMES = {
    [DEVICES.BROWSER]: {
        [_ROUTE_PATHS[DEVICES.BROWSER].ROOT]: EVENT_SOURCE_NAME.ROOT,
        [_ROUTE_PATHS[DEVICES.BROWSER].LAYOUT]: EVENT_SOURCE_NAME.LAYOUT_PAGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].SETTINGS]: EVENT_SOURCE_NAME.MORE_PAGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].SEARCH]: EVENT_SOURCE_NAME.SEARCH_PAGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].NOT_FOUND]: EVENT_SOURCE_NAME.NOT_FOUND,
        [_ROUTE_PATHS[DEVICES.BROWSER].SETTINGS_LANGUAGE_SECTION]: EVENT_SOURCE_NAME.LANGUAGE_PAGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].SEARCH_PAGE]: EVENT_SOURCE_NAME.SEARCH_PAGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].SUBSCRIPTION_PAGE_WEBVIEW]: EVENT_SOURCE_NAME.ONEHUB,
        [_ROUTE_PATHS[DEVICES.BROWSER].SUBSCRIPTION_MY_ACCOUNT_WEBVIEW]: EVENT_SOURCE_NAME.ONEHUB,
        [_ROUTE_PATHS[DEVICES.BROWSER].SUBSCRIPTION_ONEHUB_PAYMENT_STATUS]: EVENT_SOURCE_NAME.ONEHUB,
        [_ROUTE_PATHS[DEVICES.BROWSER].SUBSCRIPTION_ONEHUB]: EVENT_SOURCE_NAME.ONEHUB,
        [_ROUTE_PATHS[DEVICES.BROWSER].SUBSCRIPTION_PLAN_ACTIVATION]: EVENT_SOURCE_NAME.ONEHUB,
        [_ROUTE_PATHS[DEVICES.BROWSER].PLAYBACK]: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
        [_ROUTE_PATHS[DEVICES.BROWSER].ARTIST]: EVENT_SOURCE_NAME.PEOPLE_PAGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].SEARCH_LISTING]: EVENT_SOURCE_NAME.SEARCH_RESULT,
        [_ROUTE_PATHS[DEVICES.BROWSER].LISTING]: EVENT_SOURCE_NAME.LISTING_PAGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].TV_EPISODE_PLAYBACK]: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
        [_ROUTE_PATHS[DEVICES.BROWSER].TV_SEASON_PLAYBACK]: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
        [_ROUTE_PATHS[DEVICES.BROWSER].LIVE_TV_EPISODE_PLAYBACK]: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
        [_ROUTE_PATHS[DEVICES.BROWSER].DTH_CUSTOMER_LOGIN]: EVENT_SOURCE_NAME.DTH_RECHARGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].SCHEDULE_PAGE]: EVENT_SOURCE_NAME.SCHEDULE_PAGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].PROMOTION_PAGE]: EVENT_SOURCE_NAME.PROMOTION_PAGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].DTH_PAYMENT_PAGE]: EVENT_SOURCE_NAME.DTH_RECHARGE,
        [_ROUTE_PATHS[DEVICES.BROWSER].HAPPYCODE]: EVENT_SOURCE_NAME.HAPPYCODE_PAGE,
    },
    [DEVICES.TV]: {
        [_ROUTE_PATHS[DEVICES.TV].ROOT]: EVENT_SOURCE_NAME.ROOT,
        [_ROUTE_PATHS[DEVICES.TV].LAYOUT]: EVENT_SOURCE_NAME.LAYOUT_PAGE,
        [_ROUTE_PATHS[DEVICES.TV].SETTINGS]: EVENT_SOURCE_NAME.MORE_PAGE,
        [_ROUTE_PATHS[DEVICES.TV].SEARCH]: EVENT_SOURCE_NAME.SEARCH_PAGE,
        [_ROUTE_PATHS[DEVICES.TV].NOT_FOUND]: EVENT_SOURCE_NAME.NOT_FOUND,
        [_ROUTE_PATHS[DEVICES.TV].SETTINGS_LANGUAGE_SECTION]: EVENT_SOURCE_NAME.LANGUAGE_PAGE,
        [_ROUTE_PATHS[DEVICES.TV].SEARCH_PAGE]: EVENT_SOURCE_NAME.SEARCH_PAGE,
        [_ROUTE_PATHS[DEVICES.TV].PLAYBACK]: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
        [_ROUTE_PATHS[DEVICES.TV].ARTIST]: EVENT_SOURCE_NAME.PEOPLE_PAGE,
        [_ROUTE_PATHS[DEVICES.TV].SEARCH_LISTING]: EVENT_SOURCE_NAME.SEARCH_RESULT,
        [_ROUTE_PATHS[DEVICES.TV].LISTING]: EVENT_SOURCE_NAME.LISTING_PAGE,
        [_ROUTE_PATHS[DEVICES.TV].TV_EPISODE_PLAYBACK]: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
        [_ROUTE_PATHS[DEVICES.TV].TV_SEASON_PLAYBACK]: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
        [_ROUTE_PATHS[DEVICES.TV].LIVE_TV_EPISODE_PLAYBACK]: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
        [_ROUTE_PATHS[DEVICES.TV].DTH_CUSTOMER_LOGIN]: EVENT_SOURCE_NAME.DTH_RECHARGE,
        [_ROUTE_PATHS[DEVICES.TV].SCHEDULE_PAGE]: EVENT_SOURCE_NAME.SCHEDULE_PAGE,
        [_ROUTE_PATHS[DEVICES.TV].PROMOTION_PAGE]: EVENT_SOURCE_NAME.PROMOTION_PAGE,
        [_ROUTE_PATHS[DEVICES.TV].DTH_PAYMENT_PAGE]: EVENT_SOURCE_NAME.DTH_RECHARGE,
        [_ROUTE_PATHS[DEVICES.TV].HAPPYCODE]: EVENT_SOURCE_NAME.HAPPYCODE_PAGE,
    },
};

export const SOS_ROUTE_NAMES = {
    [_ROUTE_PATHS[DEVICES.BROWSER].ROOT]: SOS_SOURCE_NAME.HOME,
    [_ROUTE_PATHS[DEVICES.BROWSER].LAYOUT]: SOS_SOURCE_NAME.CHANNELS,
    [_ROUTE_PATHS[DEVICES.BROWSER].SETTINGS]: SOS_SOURCE_NAME.MORE_PAGE,
    [_ROUTE_PATHS[DEVICES.BROWSER].NOT_FOUND]: SOS_SOURCE_NAME.NOT_FOUND,
    [_ROUTE_PATHS[DEVICES.BROWSER].SEARCH]: SOS_SOURCE_NAME.SEARCH_PAGE,
    [_ROUTE_PATHS[DEVICES.BROWSER].SEARCH_PAGE]: SOS_SOURCE_NAME.SEARCH_PAGE,
    [_ROUTE_PATHS[DEVICES.BROWSER].SEARCH_LISTING]: SOS_SOURCE_NAME.SEARCH_RESULT,
    [_ROUTE_PATHS[DEVICES.BROWSER].LISTING]: SOS_SOURCE_NAME.GRID_PAGE,
    [_ROUTE_PATHS[DEVICES.BROWSER].SUBSCRIPTION_PAGE_WEBVIEW]: SOS_SOURCE_NAME.ONEHUB,
    [_ROUTE_PATHS[DEVICES.BROWSER].SUBSCRIPTION_MY_ACCOUNT_WEBVIEW]: SOS_SOURCE_NAME.ONEHUB,
    [_ROUTE_PATHS[DEVICES.BROWSER].SUBSCRIPTION_ONEHUB_PAYMENT_STATUS]: SOS_SOURCE_NAME.ONEHUB,
    [_ROUTE_PATHS[DEVICES.BROWSER].SUBSCRIPTION_ONEHUB]: SOS_SOURCE_NAME.ONEHUB,
    [_ROUTE_PATHS[DEVICES.BROWSER].SUBSCRIPTION_PLAN_ACTIVATION]: SOS_SOURCE_NAME.ONEHUB,
    [_ROUTE_PATHS[DEVICES.BROWSER].PLAYBACK]: SOS_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    [_ROUTE_PATHS[DEVICES.BROWSER].TV_EPISODE_PLAYBACK]: SOS_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    [_ROUTE_PATHS[DEVICES.BROWSER].TV_SEASON_PLAYBACK]: SOS_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    [_ROUTE_PATHS[DEVICES.BROWSER].LIVE_TV_EPISODE_PLAYBACK]: SOS_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    [_ROUTE_PATHS[DEVICES.BROWSER].CONTENT_DETAIL_LANDING_PAGE]: SOS_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    [_ROUTE_PATHS[DEVICES.BROWSER].LIVETV_PLAYBACK]: SOS_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    [_ROUTE_PATHS[DEVICES.BROWSER].SETTINGS_LANGUAGE_SECTION]: SOS_SOURCE_NAME.LANGUAGE_PAGE,
    [_ROUTE_PATHS[DEVICES.BROWSER].LISTING_V2]: SOS_SOURCE_NAME.GRID_PAGE,
    [_ROUTE_PATHS[DEVICES.BROWSER].DIMENSION_GENRE_LANG_PAGE]: SOS_SOURCE_NAME.DIMENSION_GENRE_LANG_PAGE, // (not with android)
    [_ROUTE_PATHS[DEVICES.BROWSER].DIMENSION_GENRE_OR_LANG_PAGE]: SOS_SOURCE_NAME.DIMENSION_GENRE_OR_LANG_PAGE, // (not with android)
    [_ROUTE_PATHS[DEVICES.BROWSER].DIMENSION_PROGRAMTYPE_PAGE]: SOS_SOURCE_NAME.DIMENSION_PROGRAMTYPE_PAGE, // (not with android)
    [_ROUTE_PATHS[DEVICES.BROWSER].DIMENSION_PAGE]: SOS_SOURCE_NAME.DIMENSION_PAGE, // (not with android)
    [_ROUTE_PATHS[DEVICES.BROWSER].WATCHLIST_PAGE]: SOS_SOURCE_NAME.YOU, // 'you'
    [_ROUTE_PATHS[DEVICES.BROWSER].CONTENT_LAYOUT_PAGES]: SOS_SOURCE_NAME.CHANNEL_DETAIL_PAGE,
    [_ROUTE_PATHS[DEVICES.BROWSER].PROFILE_PAGE]: SOS_SOURCE_NAME.YOU,
    [_ROUTE_PATHS[DEVICES.BROWSER].BLOGS]: SOS_SOURCE_NAME.BLOGS,
    [_ROUTE_PATHS[DEVICES.BROWSER].BLOG_DESCRIPTION]: SOS_SOURCE_NAME.BLOG_DESCRIPTION,
    [_ROUTE_PATHS[DEVICES.BROWSER].OFFERS_CHECK]: SOS_SOURCE_NAME.OFFERS,
    [_ROUTE_PATHS[DEVICES.BROWSER].YOU_PAGE]: SOS_SOURCE_NAME.YOU,
    [_ROUTE_PATHS[DEVICES.BROWSER].QR_CODE_LOGIN]: SOS_SOURCE_NAME.TV_CODE_LOGIN,
    '/home': SOS_SOURCE_NAME.HOME,
    '/tv-shows': SOS_SOURCE_NAME.TV_SHOWS,
    '/movies': SOS_SOURCE_NAME.MOVIES,
    '/stress-test': SOS_SOURCE_NAME.STRESS_TEST,
    '/sports': 'sports',
    [_ROUTE_PATHS[DEVICES.BROWSER].DIRECT_PLAYBACK_CONTAINER]: SOS_SOURCE_NAME.DIRECT_PLAYBACK_CDP,
    '/channel': SOS_SOURCE_NAME.CHANNELS,
    [_ROUTE_PATHS[DEVICES.TV].PLANS_OFFERS_PAGE]: SOS_SOURCE_NAME.RECHARGES_PAGE,
    [_ROUTE_PATHS[DEVICES.TV].SUBSCRIPTION_PAGE]: SOS_SOURCE_NAME.RECHARGES_PAGE,
    [_ROUTE_PATHS[DEVICES.TV].PLANS_PAYMENTS_PAGE]: SOS_SOURCE_NAME.QR_CODE_PAGE,
};

export const ROUTE_STATIC_IDS = {
    MENU_MORE_NAVIGATION: 'SETTINGS_MORE_NAVIGATION',
    SETTINGS_LANGUAGE: 'SETTINGS_LANGUAGE',
    SEARCH_ICON: 'SEARCH_ICON',
    WATCHLIST_ICON: 'WATCHLIST_ICON',
    YOU_PAGE: 'YOU_PAGE',
    HOME: 'HOME',
    MENU_NAV: 'MENU_NAV',
    DTH_LOGIN: 'DTH_LOGIN',
    PROFILE_PAGE: 'PROFILE_PAGE',
    WATCHLIST_PAGE: 'WATCHLIST_PAGE',
    SETTINGS_PAGE: 'SETTINGS_PAGE',
    SEARCH_PAGE: 'SEARCH_PAGE',
    ONEHUB_PAGE: 'ONEHUB_PAGE',
};

export const TAB_TITLE_MAP = {
    LANGUAGE: 'language',
    SETTINGS: 'more',
};

export const ROUTE_ACTIONS = {
    PUSH: 'PUSH',
    POP: 'POP',
};

export const DEFAULT_NAV = {
    HOME: 'home',
};

export const WEBVIEW_PATHS = {
    FEEDBACK: 'https://webview.airtel.tv/download_feedback/help_feedback.html',
};

export const _CDP_ROUTES = [
    _ROUTE_PATHS[DEVICES.BROWSER].LIVE_TV_EPISODE_PLAYBACK,
    _ROUTE_PATHS[DEVICES.BROWSER].TV_EPISODE_PLAYBACK,
    _ROUTE_PATHS[DEVICES.BROWSER].TV_SEASON_PLAYBACK,
    _ROUTE_PATHS[DEVICES.BROWSER].LIVETV_PLAYBACK,
    _ROUTE_PATHS[DEVICES.BROWSER].PLAYBACK,
];

export default {
    _ROUTE_PATHS,
    ROUTE_STATIC_IDS,
    PAGE_TITLE_LIST,
    TAB_TITLE_MAP,
    ROUTE_ACTIONS,
    ROUTE_PATHS_SETTINGS,
    _ROUTE_SOURCE_NAMES,
    APP_ROOT_ID,
    WEBVIEW_PATHS,
    _CDP_ROUTES,
};
