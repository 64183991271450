import React, { forwardRef } from 'react';
import {
    matchPath, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { checkWindowExist } from '@airtel-tv/utils';
import { HistoryUtil } from '@airtel-tv/utils/HistoryUtil';

export default function withRouter(Component) {
    const ComponentWithRouterProp = forwardRef((props, ref) => {
        const location = useLocation();
        const params = useParams();
        const match = { params };
        const navigate = useNavigate();
        const history = checkWindowExist() ? HistoryUtil.getHistory() : {};
        const matchRoute = (routePath, actualPath) => matchPath({
            path: routePath,
            exact: true,
            strict: false,
        }, actualPath);
        if (history !== null) {
            // OVERRIDDEN HISTORY.PUSH METHOD
            history.push = ({ pathname = '', search = '', ...rest }) => {
                let path = pathname.split('?');
                const searchParams = path[1] ? path[1] : '';
                path = path[0] ? path[0] : '';
                if (path) {
                    navigate({
                        pathname: path,
                        search: search || searchParams,
                    },
                    {
                        ...rest,
                    });
                }
            };
            history.replace = ({ pathname, search, ...rest }) => {
                navigate({
                    pathname,
                    search,
                },
                {
                    replace: true,
                    ...rest,
                });
            };
        }

        return (
            <Component
                {...props}
                location={location}
                match={match}
                navigate={navigate}
                history={history}
                matchRoute={matchRoute}
                ref={ref}
            />
        );
    });

    return ComponentWithRouterProp;
}
