import lodashGet from 'lodash/get';
import { PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';


export const getParentContentId = (contentDetails) => {
    const audioMap = lodashGet(contentDetails, 'langToContentMap', null);
    if (audioMap) {
        const { parentLang, languageMap } = audioMap;
        const parentContentId = lodashGet(languageMap, `${parentLang}`, '');
        return parentContentId;
    }
    return contentDetails?.id || '';
};

const updateContinueWatchingDataUtil = ({
    continueWatchingData,
    apiSyncResponseList,
    toBeAddedFromRecent,
    toBeRemovedFromRecent,
    continueWatchingRail,
}) => {
    const addFromSyncToRecent = {};
    const removeFromSyncToRecent = [];
    const tempContentId = {};
    const tempRail = continueWatchingRail;

    // content to be added/updated
    apiSyncResponseList.forEach((content) => {
        const contentId = content.contentResponse.id;
        const parentId = getParentContentId(content.contentResponse);
        if ((!continueWatchingData[contentId]
            || lodashGet(content, 'lastUpdatedTimeStamp', 0) >= lodashGet(continueWatchingData[contentId], 'content.lastUpdatedTimeStamp', 0))
            && lodashGet(content, 'recent', false)) {
            addFromSyncToRecent[parentId] = {
                ...content,
            };

            // check if content is from a tv show
            if (lodashGet(content, 'contentResponse.programType', '') === PROGRAM_TYPES.EPISODE) {
                const tvShowId = lodashGet(content, 'contentResponse.seriesId', '');
                const seasonId = lodashGet(content, 'contentResponse.seasonId', '');
                if (lodashGet(content, 'lastUpdatedTimeStamp', 0) >= lodashGet(continueWatchingData[tvShowId], 'content.lastUpdatedTimeStamp', 0)) {
                    addFromSyncToRecent[tvShowId] = content;
                    tempContentId[tvShowId] = true;
                }
                if (seasonId && (lodashGet(content, 'lastUpdatedTimeStamp', 0) > lodashGet(continueWatchingData[seasonId], 'content.lastUpdatedTimeStamp', 0))) {
                    addFromSyncToRecent[seasonId] = content;
                    tempContentId[seasonId] = true;
                }
                tempRail[tvShowId] = true;
            }
            else if (!(lodashGet(content, 'contentResponse.programType', '') === PROGRAM_TYPES.SEASON || lodashGet(content, 'contentResponse.programType', '') === PROGRAM_TYPES.TVSHOW)) {
                tempRail[parentId] = true;
            }
        }

        tempContentId[contentId] = true;
    });

    // check the content to be removed
    Object.keys(continueWatchingData).forEach((localContentKey) => {
        if ((!(localContentKey in tempContentId) && !toBeAddedFromRecent[localContentKey])
            || toBeRemovedFromRecent[localContentKey]) {
            removeFromSyncToRecent.push(localContentKey);
            if (lodashGet(continueWatchingData[localContentKey], 'contentResponse.programType', '') === PROGRAM_TYPES.EPISODE) {
                const tvShowId = lodashGet(continueWatchingData[localContentKey], 'contentResponse.seriesId', '');
                const seasonId = lodashGet(continueWatchingData[localContentKey], 'contentResponse.seasonId', '');
                removeFromSyncToRecent.push(tvShowId);
                if (seasonId) {
                    removeFromSyncToRecent.push(seasonId);
                }
                delete tempRail[tvShowId];
            }
            else {
                delete tempRail[localContentKey];
            }
        }
    });

    return {
        addFromSyncToRecent,
        removeFromSyncToRecent,
    };
};

const updateWatchListDataUtil = ({ apiSyncResponseList }) => {
    let watchList = {};
    apiSyncResponseList.forEach((content) => {
        const { id: contentId } = { ...content.contentResponse };
        // Uncomment if only one language type can be added in watchlist
        // const contentId = getParentContentId(content.contentResponse);
        if (lodashGet(content, 'fav', false)) {
            watchList = {
                ...watchList,
                [contentId]: { ...content },
            };
        }
    });
    return watchList;
};

export const updateSyncContentUtil = ({
    continueWatchingData,
    apiSyncResponseList,
    toBeAddedFromRecent,
    toBeRemovedFromRecent,
    continueWatchingRail,
}) => {
    const { addFromSyncToRecent, removeFromSyncToRecent } = updateContinueWatchingDataUtil({
        continueWatchingData,
        apiSyncResponseList,
        toBeAddedFromRecent,
        toBeRemovedFromRecent,
        continueWatchingRail,
    });
    const watchList = updateWatchListDataUtil({
        apiSyncResponseList,
    });

    return {
        addFromSyncToRecent,
        removeFromSyncToRecent,
        watchList,
    };
};

export const removeAllEpisodesFromShow = ({
    tvShowId, continueWatchingData, manuallyRemovedFromCW,
}) => {
    let toBeRemovedFromRecentChanges = {};
    const syncContentAlias = continueWatchingData;
    const lastUpdatedTimeStamp = new Date().getTime();
    Object.keys(syncContentAlias).forEach((contentId) => {
        if (lodashGet(syncContentAlias[contentId], 'contentResponse.seriesId', '') === tvShowId) {
            if (!(contentId === tvShowId || lodashGet(syncContentAlias[contentId], 'contentResponse.seasonId', '') === contentId)) {
                toBeRemovedFromRecentChanges = {
                    ...toBeRemovedFromRecentChanges,
                    [contentId]: {
                        contentId,
                        lastWatchedPosition: continueWatchingData[contentId].lastWatchedPosition || 0,
                        lastUpdatedTimeStamp,
                        ...(manuallyRemovedFromCW && { manuallyRemovedFromCW: true }),
                    },
                };
            }
            delete syncContentAlias[contentId];
        }
    });
    return toBeRemovedFromRecentChanges;
};

// export const updateSyncDataUtil = (syncContent) => {
//     const toBeRemoved = [];
//     const toBeAdded = [];
//     // eslint-disable-next-line guard-for-in
//     for (const contentKey in syncContent) {
//         const { lastUpdatedTimeStamp, lastWatchedPosition } = syncContent[contentKey];
//         if (lodashGet(syncContent[contentKey], 'isRemoved', false)) {
//             toBeRemoved.push({
//                 contentId: contentKey,
//                 lastUpdatedTimeStamp,
//                 lastWatchedPosition,
//             });
//         }
//         else if (lodashGet(syncContent[contentKey], 'isAdded', false)) {
//             toBeAdded.push({
//                 contentId: contentKey,
//                 lastUpdatedTimeStamp,
//                 lastWatchedPosition,
//             });
//         }
//     }
//     return {
//         toBeAdded,
//         toBeRemoved,
//     };
// };
