import React from 'react';

const TickIcon = ({ iconProps = {} }) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...iconProps}
        className='tick'
    >
        <g clipPath="url(#clip0_4612_2619)">
            <path
                d="M1.64294 8.57105L5.88098 12.8091L14.3571 4.33301"
                stroke={iconProps.color ? iconProps.color : '#0C0F12'}
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_4612_2619">
                <rect
                    width="16"
                    height="16"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
);

export default TickIcon;
