import {
    call, getContext, put, takeEvery, fork
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { filterRechargePlans } from '@airtel-tv/utils/SubscriptionUtil';
import { getBillPaymentModes } from '@airtel-feature/addToBill/actions';
import { getAllPlansConfig } from '../../service/end-points/PlanApiService';
import { PlanActions, planConfigSuccess, planConfigFailure } from './PlanActions';
import ErrorHandler from '../error-handler/ErrorHandler';

function* callBillPaymentModes(planId) {
    yield put(getBillPaymentModes({ planId }));
}

function* onPlanConfigInit() {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const response = yield call(getAllPlansConfig, {
            deviceUtil,
        });
        filterRechargePlans({ allPlans: response });

        yield put(planConfigSuccess(response));
        //@NGTD: If we can have a condition over this
        const plan = (response?.xppPlans?.plans || []).find(item => item.bestValue);
        if (plan?.planId) {
            yield fork(callBillPaymentModes, plan.planId);
        }
    }
    catch (e) {
        console.error(e);
        const payload = lodashGet(e, 'data', e);
        ErrorHandler({
            code: payload.errorcode,
            errortitle: payload.errortitle,
        });
        yield put(planConfigFailure({ error: payload }));
    }
}

export default [
    takeEvery(PlanActions.PLAN_CONFIG_INIT, onPlanConfigInit),
];
