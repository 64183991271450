const openDeeplinkFromWebview = function (deeplink) {
    const url = `https://wynk.in/music/EXTERNALBROWSER/${deeplink}`;
    const win = window;
    if (win.webkit && win.webkit.messageHandlers && win.webkit.messageHandlers.deeplink) {
        win.webkit.messageHandlers.deeplink.postMessage({ deeplink: url });
    }
    else if (win.app && win.app.deeplink) {
        win.app.deeplink(JSON.stringify({ deeplink: url }));
    }
    // window.open(url, '_self');
};


export default openDeeplinkFromWebview;
