import React from 'react';

const PlusIcon = ({ iconProps = {} }) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...iconProps}
        className='plus-icon'
    >
        <path
            d="M2.22757 8.35352H14.4791"
            stroke={iconProps.color ? iconProps.color : '#0C0F12'}
            strokeWidth="1.67067"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.35333 2.22754V14.4791"
            stroke={iconProps.color ? iconProps.color : '#0C0F12'}
            strokeWidth="1.67067"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>

);

export default PlusIcon;
