import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { SubscriptionUtil, RoutingUtil } from '@airtel-tv/utils';
import { PROGRAM_TYPES, SUBSCRIPTION_SOURCE, BLOCK_CODES } from '@airtel-tv/constants';
import { subscriptionInit } from '../../../web/src/modules/subscription/SubscriptionAction';
import { DeviceTypeUtil } from '@airtel-tv/utils/DeviceTypeUtil';

function isContentLockedHOCWeb(WrappedTile) {
    class ContentLock extends PureComponent {
        constructor(props) {
            super(props);
            this.state = {};
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            const {
                isAuthenticated,
                contentDetails,
            } = nextProps;

            if (!prevState
                || prevState.contentDetails !== contentDetails
                || prevState.isAuthenticated !== isAuthenticated) {
                return {
                    contentDetails,
                    isAuthenticated,
                };
            }
            return null;
        }

        onClick = (e, contentDetails) => {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            const {
                history,
            } = this.props;


            history.push({
                pathname: RoutingUtil.getSubscriptionPage(),
                state: {
                    ...contentDetails,
                    origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                },
            });
        }

        render() {
            const {
                subscriptionDataById,
                cpDetailsById,
                contentDetails,
                isAuthenticated,
                byPassCode,
            } = this.props;

            const {
                cpId,
                channelId: liveTVChannelId,
                programType,
                free,
            } = contentDetails;
            const cpDetails = lodashGet(cpDetailsById, `[${cpId}]`, null);
            // for live tv liveTVChannelId or it is LIVETVCHANNEL
            // if user is not in logged so cannot be locked
            // if content is free then not locked
            let isLocked = !liveTVChannelId
                            && programType !== PROGRAM_TYPES.LIVETVCHANNEL
                            && !!cpDetails
                            && isAuthenticated
                            && !free;
            let { subsHierarchy } = contentDetails;

            if (programType === PROGRAM_TYPES.SEASON) {
                const { episode } = this.props;
                // prevent crash incase of episode is null
                if (episode) {
                    ({ subsHierarchy } = episode || {});
                }
            }

            if (isLocked) {
                isLocked = !byPassCode && SubscriptionUtil.isLocked({
                    cpDetails,
                    subscriptionList: subscriptionDataById,
                    subsHierarchy,
                });
            }
            return (
                <WrappedTile
                    {...this.props}
                    isLocked={isLocked}
                    onSubscribeButtonClick={this.onClick}
                />
            );
        }
    }
    function mapStateToProps(state) {
        const {
            appConfig: {
                cpDetailsById,
                blockFeature,
            },
            userConfig: {
                subscriptionDataById,
            },
            authConfig,
        } = state;

        const byPassCode = lodashGet(blockFeature, BLOCK_CODES.PARTNER_CHANNEL, false);

        return {
            cpDetailsById,
            subscriptionDataById,
            isAuthenticated: authConfig.isAuthenticated,
            byPassCode,
        };
    }

    ContentLock.defaultProps = {
        episode: null,
    };

    ContentLock.propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,
        subscriptionDataById: PropTypes.objectOf(PropTypes.object).isRequired,
        contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
        episode: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        subscriptionInitDispatch: PropTypes.func.isRequired,
        byPassCode: PropTypes.bool.isRequired,
    };
    return connect(mapStateToProps, {
        subscriptionInitDispatch: subscriptionInit,
    })(withRouter(ContentLock));
}

function isContentLockedHOCLS(WrappedTile) {
    class ContentLock extends PureComponent {
        constructor(props) {
            super(props);
            this.state = {};
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            const {
                isAuthenticated,
                contentDetails,
            } = nextProps;

            if (!prevState
                || prevState.contentDetails !== contentDetails
                || prevState.isAuthenticated !== isAuthenticated) {
                return {
                    contentDetails,
                    isAuthenticated,
                };
            }
            return null;
        }

        onClick = (e, contentDetails) => {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            const {
                cpDetailsById, subscriptionInitDispatch,
            } = this.props;

            const channelId = SubscriptionUtil.getChannelId({
                cpDetailsById,
                cpId: contentDetails.cpId,
            });
            // subscribe flow

            subscriptionInitDispatch({
                id: channelId,
                successRedirection: SUBSCRIPTION_SUCCESSFUL_PAGE_REDIRECTION.CURRENT,
                source: SUBSCRIPTION_SOURCE.TILE,
            });
        }

        render() {
            let {
                subscriptions,
                cpDetailsById,
                contentDetails = {},
                isAuthenticated,
                byPassCode,
                contentEpisodeDetails,
                episode,
                checkEligibleSubscription,
                subscriptionDataById,
            } = this.props;

            const {
                cpId,
                channelId: liveTVChannelId,
                programType,
                free,
            } = contentDetails;

            contentEpisodeDetails = episode || contentEpisodeDetails;
            const requiredSubscription = lodashGet(contentEpisodeDetails, 'requiredSubscriptions', lodashGet(contentDetails, 'requiredSubscriptions', []));

            const cpDetails = lodashGet(cpDetailsById, `[${cpId}]`, null);
            // for live tv liveTVChannelId or it is LIVETVCHANNEL
            // if user is not in logged so cannot be locked
            // if content is free then not locked
            let isLocked = !!cpDetails
                            && isAuthenticated
                            && !free;
            let { subsHierarchy } = contentDetails;

            if (programType === PROGRAM_TYPES.SEASON) {
                const { episode } = this.props;
                ({ subsHierarchy } = episode || {});
            }

            if (isLocked) {
                isLocked = !byPassCode && SubscriptionUtil.isLocked({
                    subscriptionList: subscriptionDataById,
                    requiredSubscription,
                    cpDetails,
                    checkEligibleSubscription,
                    subsHierarchy,
                });
            }
            return (
                <WrappedTile
                    {...this.props}
                    isLocked={isLocked}
                    onSubscribeButtonClick={this.onClick}
                />
            );
        }
    }
    function mapStateToProps(state) {
        const {
            appConfig: {
                cpDetailsById,
                blockFeature,
                checkEligibleSubscription,
            },
            userConfig: {
                subscriptions,
                subscriptionDataById,
            },
            authConfig,
        } = state;

        const byPassCode = lodashGet(blockFeature, BLOCK_CODES.PARTNER_CHANNEL, false);

        return {
            cpDetailsById,
            subscriptions,
            isAuthenticated: authConfig.isAuthenticated,
            byPassCode,
            checkEligibleSubscription,
            subscriptionDataById,
        };
    }

    ContentLock.defaultProps = {
        episode: null,
    };

    ContentLock.propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,
        subscriptions: PropTypes.objectOf(PropTypes.object).isRequired,
        subscriptionDataById: PropTypes.objectOf(PropTypes.object).isRequired,
        contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
        episode: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        subscriptionInitDispatch: PropTypes.func.isRequired,
        byPassCode: PropTypes.bool.isRequired,
    };
    return connect(mapStateToProps, {
        subscriptionInitDispatch: subscriptionInit,
    })(ContentLock);
}

const isContentLockedHOC =  DeviceTypeUtil.isWeb() ?  isContentLockedHOCWeb : isContentLockedHOCLS;


export { isContentLockedHOC };
