const CrashModel = {
    appid: '',
    av: '',
    bn: '',
    brand: '',
    did: '',
    dname: '',
    dt: '',
    meta: {
        error: '',
    },
    model: '',
    nq: '',
    nt: '',
    os: '',
    ov: '',
    ts: '',
    uid: '',
};

export default CrashModel;
