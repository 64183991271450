
import RoutingUtil from '@airtel-tv/utils/RoutingUtil';
import { TILE_CTA_LIST, RAIL_CTA_LIST, TILE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { deepLinkParser, trimCaptionsForUrl } from '@airtel-tv/utils/GlobalUtil';
import { getSeasonTitle } from '@airtel-tv/utils/ContentMetaUtil';

import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { DeviceTypeUtil } from '@airtel-tv/utils/DeviceTypeUtil';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';

export const getRailCtaUrl = ({
    cta = null,
    title,
    packageId,
    railId,
    category,
    query,
    pageUrlName,
    channelName,
    channelId,
    titleL2,
    preferredArtwork,
}) => {
    switch (cta) {
        case RAIL_CTA_LIST.SEARCH:
            return RoutingUtil.getSearchListingUrl({
                category,
                query,
                title,
            });
        case RAIL_CTA_LIST.SCHEDULE:
            return RoutingUtil.getSchedulePageUrl({
                channelName,
                channelId,
            });
        case RAIL_CTA_LIST.DISTRO_LIVETV_LISTING: {
            const trimmedTitle = trimCaptionsForUrl(title);
            return ROUTE_PATHS.DISTRO_CHANNELS_LIST.replace(':pageTitle', trimmedTitle);
        }
        default:
            if (!packageId) {
                return null;
            }
            return DeviceTypeUtil.isWeb() ? RoutingUtil.getPackageListingUrlV2({
                title,
                packageId,
                pageUrlName,
                railId,
                titleL2,
                preferredArtwork,
            }) : RoutingUtil.getPackageListingUrl({
                title,
                packageId,
                pageUrlName,
                railId,
            });
    }
};

export const getTileCtaUrl = ({
    cta = null,
    contentId,
    title,
    category,
    genre,
    lang,
    channelName,
    episodeNumber = '',
    airDate = '',
    tvShowId = '',
    episodeSeasonNum = 1,
    tvShowName = '',
    cpTitle = '',
    pageLink = '',
    cpId = '',
    showPlayback = false,
    deepLink = '',
    sourceTab = '',
    addtionalQueryParams = '',
    seriesId = '',
    seasonId = '',
    isArtist = false,
    isWeb = true,
    tileType = '',
}) => {
    const explicitCategory = category;
    if (tileType === TILE_TYPES.X_FREE_CHANNELS || (cpId === 'DISTROTV' && category !== PROGRAM_TYPES.PACKAGE)) {
        return RoutingUtil.getDirectPlaybackRouteUrl({
            contentId,
            title,
        });
    }
    if (isArtist) {
        return RoutingUtil.getArtistUrl({
            name: title,
            id: contentId,
            isWeb,
        });
    }
    if (deepLink) {
        let parsedDeeplink;
        let decodedDeeplink;
        let tileCtaUrl;
        try {
            parsedDeeplink = deepLinkParser(deepLink);
            decodedDeeplink = decodeURIComponent(parsedDeeplink);
            tileCtaUrl = decodedDeeplink.replace(/ /g, '-');
        }
        catch (e) {
            console.error(e, 'error deeplink', deepLink);
        }
        return tileCtaUrl;
    }
    if (TILE_CTA_LIST.SEARCH === cta) {
        return RoutingUtil.getSearchListingUrl({
            category,
            genre,
            lang,
            title,
        });
    }
    switch (category) {
        case PROGRAM_TYPES.LIVETVSHOW:
        case PROGRAM_TYPES.LIVETVMOVIE:
            return RoutingUtil.getLiveTvShowUrl({
                programType: category,
                channelName,
                showName: title,
                contentId,
            });

        case PROGRAM_TYPES.CATCHUP_EPISODE:
            return RoutingUtil.getLiveEpisodeShowUrl({
                channelName,
                showName: title,
                episodeReleaseDT: airDate,
                episodeNumber,
                contentId,
                tvShowId,
            });
        case PROGRAM_TYPES.PACKAGE:
            if (cta === TILE_CTA_LIST.PARTNER_CHANNEL || cpId) {
                return RoutingUtil.getChannelPartnerPageUrl({
                    programType: category,
                    channelPartnerName: cpTitle,
                    pageLink,
                });
            }
            return DeviceTypeUtil.isWeb() ? RoutingUtil.getPackageListingUrlV2({
                programType: category,
                title,
                packageId: contentId,
            }) : RoutingUtil.getPackageListingUrl({
                programType: category,
                title,
                packageId: contentId,
                sourceTab,
            });
        case PROGRAM_TYPES.EPISODE:
            if (showPlayback) {
                return `${RoutingUtil.getTvEpisodeUrlWeb({
                    programType: category,
                    title: tvShowName,
                    episodeNumber,
                    episodeName: title,
                    seasonName: getSeasonTitle(episodeSeasonNum),
                    contentId,
                    seasonId,
                    seriesId,
                })}?${LocationUtil.objectToQueryParams({ showPlayback: true })}`;
            }
            return RoutingUtil.getContentPlaybackUrlWeb({
                programType: category,
                contentId,
                category: explicitCategory,
                contentTitle: title,
                seasonId,
                seriesId,
                seasonName: getSeasonTitle(episodeSeasonNum),
            });

        case PROGRAM_TYPES.SEASON:
            if (DeviceTypeUtil.isWeb()) {
                return RoutingUtil.getContentPlaybackUrlWeb({
                    programType: category,
                    contentId,
                    category: explicitCategory,
                    contentTitle: tvShowName,
                    seasonName: title,
                    seriesId,
                });
            }
        default:
            return DeviceTypeUtil.isWeb() ? RoutingUtil.getContentPlaybackUrlWeb({
                contentId,
                category: explicitCategory,
                contentTitle: title,
            }) : RoutingUtil.getContentPlaybackUrlTv({
                contentId,
                category: explicitCategory,
                contentTitle: title,
                sourceTab,
                addtionalQueryParams,
            });
    }
};
