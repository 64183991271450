import { PropTypes } from 'prop-types';
import React from 'react';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';

const CenterPlayComponent = ({ play, isMetaLoaded }) => (
    <div className="vdo-player__play-options">
        <div className="d-flex justify-content-center align-items-center">
            <div className={`vdo-player__play-icon ${isMetaLoaded ? ' for-mobile-only' : ''}`}>
                <button
                    onClick={play}
                    className="button button-icon button-icon-pp"
                    type="button"
                >
                    {/* <i className="center-control-icon-size icon-player-play" /> */}
                    <ThumborImage
                        className="player-center-icon "
                        src={IMAGE_PATHS.PLAY}
                    />
                </button>
            </div>
        </div>
    </div>
);

CenterPlayComponent.propTypes = {
    play: PropTypes.func.isRequired,
    isMetaLoaded: PropTypes.bool.isRequired,
};

export default CenterPlayComponent;
